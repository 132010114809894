import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import s from './style.module.scss'

class GHomeKit extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.goose_add_part.getBoundingClientRect()
    if (clientHeight - 200 > textRect.top && this.refs.goose_add_part) {
      setTimeout(() => {
        this.refs.top_text.style.opacity = 1
        this.refs.top_text.style.top = '164px'
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div
        className={
          locale.indexOf('US') > -1
            ? s.goose_add_part_en
            : locale.indexOf('TW') > -1
            ? s.goose_add_part_tw
            : s.goose_add_part
        }
        ref="goose_add_part"
      >
        <div className={s.warp}>
          <div className={s.detail} ref="top_text">
            <div className={s.HomeKit_img}></div>
            <div className={s.small}>
              <FormattedMessage id="temp_rh_t" />
              &nbsp;/&nbsp;
              <FormattedMessage id="temp_rh_h" />
            </div>
            <div className={s.large}>
              <FormattedMessage id="siri_support" />
            </div>
            <div className={s.normal}>
              <FormattedMessage id="hey_siri" />
            </div>
            <div className={s.small}>
              * <FormattedMessage id="check_remote_2" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const GHomekit = connect(mapStateToProps)(GHomeKit)

export default GHomekit
