import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Sfifth.module.scss'

class Sfifth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Sfifth.getBoundingClientRect()
    if (this.refs.Sfifth && clientHeight >= textRect.bottom - 100) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    return (
      <div className={s.Sfifth} ref="Sfifth">
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <div className={s.title}>
              <FormattedMessage id="parrot_four_title" values={{br: <br />}} />
            </div>
            <div className={s.desc}>
              <FormattedMessage id="parrot_four_desc" values={{br: <br />}} />
            </div>
          </div>
          <div className={s.imgBox}>
            <div className={s.pic}></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SFifth = connect(mapStateToProps)(Sfifth)

export default SFifth
