import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import s from './Sfourth.module.scss'

class Sfourth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Sfourth.getBoundingClientRect()
    if (clientHeight >= textRect.bottom && this.refs.text) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '152px'
      }, 500)
    }
  }
  render() {
    return (
      <div ref="Sfourth">
        <div className={s.Sfourth}>
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              <div className={s.title}>
                <FormattedMessage id="dany_three_title" />
              </div>
              <div className={s.subtitle}>
                <FormattedMessage id="dany_three_desc" values={{br: <br />}} />
              </div>
            </div>
            <div className={s.pic}>
              <div className={s.item1}>
                <div className={s.little}>
                  <FormattedMessage id="dany_three_item1_title" />
                </div>
                <div className={s.normal}>
                  <FormattedMessage id="dany_three_item1_desc" values={{br: <br />}} />
                </div>
              </div>
              <div className={s.item2}>
                <div className={s.little}>
                  <FormattedMessage id="dany_three_item2_title" />
                </div>
                <div className={s.normal}>
                  <FormattedMessage id="dany_three_item2_desc" values={{br: <br />}} />
                </div>
              </div>
              <div className={s.item3}>
                <div className={s.little}>
                  <FormattedMessage id="dany_three_item3_title" />
                </div>
                <div className={s.normal}>
                  <FormattedMessage id="dany_three_item3_desc" values={{br: <br />}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Sfourth
