import React, { Component } from 'react';

import s from './Dtenth.module.scss';

class Dtenth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1,
            battery: false
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
        clearInterval(this.state.interval);
    }
    battery() {
        if (this.refs.battery) {
            const battery = this.refs.battery;
            const clientHeight = document.documentElement.clientHeight;
            const textRect = this.refs.Dtenth.getBoundingClientRect();
            if (clientHeight >= (textRect.bottom + 200) && this.refs.battery && this.state.index <= 5) {
                this.setState({
                    interval: setInterval(() => {
                        for (let i = 0; i <= battery.children.length - 1; i++) {
                            // 当前显示
                            if (i === this.state.index) {
                                battery.children[i].style.opacity = 1;
                            } else {
                                battery.children[i].style.opacity = 0;
                            }
                        }
                        this.setState({
                            index: this.state.index + 1
                        });
                        if (this.refs.battery && this.state.index >= 6) {
                            if (this.state.opacity === 1) {
                                this.setState({
                                    opacity: 0
                                });
                            } else {
                                this.setState({
                                    opacity: 1
                                });
                            }
                            this.refs.ten_0.style.opacity = this.state.opacity;
                        }
                    }, 1000)
                });
            }
        }
        this.setState({
            battery: true
        });
    }
    handleScroll() {
        // clearInterval(this.state.interval);
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Dtenth.getBoundingClientRect();
        if (clientHeight >= (textRect.bottom + 200)) {
            if (!this.state.battery) {
                this.battery();
            }
        }
    }
    render() {
        return (
            <div className={s.Dtenth} ref="Dtenth">
                <div className={s.desc}>屏幕持续显示剩余电量</div>
                <div className={s.text}>方便及时更换电池</div>
                <ul className={s.battery} ref="battery">
                    <li className={s.ten_5} ref="ten_5" />
                    <li className={s.ten_4} ref="ten_4" />
                    <li className={s.ten_3} ref="ten_3" />
                    <li className={s.ten_2} ref="ten_2" />
                    <li className={s.ten_1} ref="ten_1" />
                    <li className={s.ten_0} ref="ten_0" />
                </ul>
            </div>
        );
    }
}

export default Dtenth;

