import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Sthird.module.scss'

class Sthird extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setState({
      init: null,
    })
  }
  render() {
    const { locale } = this.props
    return (
      <div ref="Sthird">
        <div className={s.Sthird}>
          <div className={s.wrap}>
            <div
              className={locale.indexOf('US') > -1 ? s.text_en : s.text}
              ref="text"
            >
              <div className={s.large}>
                <FormattedMessage
                  id="hodor_second_title"
                  values={{ br: <br /> }}
                />
              </div>
              <div className={s.normal}>
                <FormattedMessage id="hodor_second_desc" />
              </div>
              <div className={s.imgBox}>
                <div className={s.magnet}>
                  <i className={s.magnet_icon}></i>
                  <div className={s.magnet_desc}>
                    <FormattedMessage id="hodor_second_magnet" />
                  </div>
                </div>
                <div className={s.main_body}>
                  <i className={s.body_icon}></i>
                  <FormattedMessage id="hodor_second_main" />
                </div>
              </div>
            </div>
            <div className={s.rightPic}></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SThird = connect(mapStateToProps)(Sthird)

export default SThird
