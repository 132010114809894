import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../header';
import Footer from '../footer';
import AirContent from '../air_content';
import s from './air.module.scss';
import common from '../../../../locale/common'
class air extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      position: false
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['cg_app_' + local]
    
    let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US';
        break;
      case 'zh':
        browserLanguage = 'zh-CN';
        break;
      default:
        browserLanguage = 'en-US';
    }
    this.setState({
      language: browserLanguage
    });
    window.addEventListener('scroll', this._handleScroll);

    if (this.isMac()) {
      console.log('Mac')
      window.location.href = "https://itunes.apple.com/cn/app/%E9%9D%92%E8%90%8D%E7%A9%BA%E6%B0%94/id1344636968?mt=8"
    } else {
      console.log('Windows')
      window.location.href = "https://qing.cleargrass.com/download/plus"
    }

  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }

  /** * 是否为mac系统（包含iphone手机） * */ 
  isMac() { 
    return /macintosh|mac os x/i.test(navigator.userAgent); 
  }
  
  
  /** * 是否为windows系统 * */
  isWindows() { 
      return /windows|win32/i.test(navigator.userAgent);
  }

  handleScroll () {
    const textRect = this.refs.air.getBoundingClientRect();
    // console.log(textRect.top);
    if (textRect.top < -50 && this.refs.air) {
      this.setState({
        position: true
      });
    } else {
      this.setState({
        position: false
      });
    }
  }
  render () {
    const { location } = this.props;
    const path = this.props.match.path;
    return (
      <div className={s.air} ref="air">
        <Header location={location} language={this.state.language} navPosition={this.state.position} path={path} />
        <AirContent />
        <Footer location={location} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let Air = connect(mapStateToProps)(air);

export default Air;
