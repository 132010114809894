const actions = {
    changeLanguage(val) {
      // console.log(val);
      return (dispatch, state) => {
        dispatch({
          type: 'CHANGE_LANGUAGE',
          val
        });
      };
    }
  };
  
  export default actions;