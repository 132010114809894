import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './../../header';
import Param from './../param';
import Footer from './../../footer';

import s from './Pspecifications.module.scss';
import common from '../../../../../locale/common'
class Pspecifications extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      position: false,
      hideGooseChoice:true
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['pheasant_' + local] + ' - ' + common['specifications_' + local]
    
    let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US';
        break;
      case 'zh':
        browserLanguage = 'zh-CN';
        break;
      default:
        browserLanguage = 'en-US';
    }
    this.setState({
      language: browserLanguage
    });
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }

  handleScroll () {
    const textRect = this.refs.Pspecifications.getBoundingClientRect();
    console.log(textRect.top);
    if (textRect.top < -50 && this.refs.Pspecifications) {
      this.setState({
        position: true
      });
    } else {
      this.setState({
        position: false
      });
    }
  }
  render () {
    const { hideGooseChoice } = this.state
    const { location } = this.props;
    const path = this.props.match.path;
    return (
      <div className={s.Pspecifications} ref="Pspecifications"
      onMouseOver={(e) => {
        e.persist()
        this.hideGooseChoiceFun(true, e)
      }}
      >
        <Header 
        location={location} 
        language={this.state.language} 
        path={path} 
        navPosition={this.state.position}
        hideGooseChoice={hideGooseChoice}
        hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
        />
        <Param />
        <Footer location={location} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let PSpecifications = connect(mapStateToProps)(Pspecifications);

export default PSpecifications;
