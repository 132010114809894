import React, { Component } from 'react';

import s from './Privacy.module.scss';

class PlGprivacy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { // init top hide
      display: false
    };
  }
  render () {

    return (
        <div className={s.box}>
            <div className={s.privacy}>
                <p><b style={{display: 'block', textAlign: 'center', margin: 20}}>隐私政策</b></p>
                <p><b>更新！</b></p>
                <p>我们的隐私政策已于 2021 年 12 月 20 日更新。我们对隐私政策进行了修订，从该日期开始，这一隐私政策能够提供有关我们如何管理您在使用青萍空气检测仪 Lite 产品和服务时透露的个人信息的隐私详情。 请花一些时间熟悉我们的隐私权惯例，如果您有任何问题，请告诉我们。</p>

                <p><b>我们向您承诺</b></p>

                <p>本隐私政策规定了青萍科技（北京）有限公司及关联公司（下文简称"青萍"或"我们";）如何收集、使用、披露、处理和保护您在使用我们的产品和服务时提供给我们的信息；若我们要求您提供某些信息，以便在使用青萍产品和服务时验证您的身份，我们将严格遵守本隐私政策和/或我们的用户条款来使用这些信息。</p>

                <p>本隐私政策在制定时充分考虑到您的需求；您应全面了解我们的个人资料收集和使用惯例而且确信自己最终能控制提供给青萍的个人信息，这一点至关重要。</p>

                <p>在这项隐私政策中，"个人信息"指信息本身或与青萍能够访问的个人的其他信息联系后能够识别特定个人的数据。</p>

                <p>通过使用青萍空气检测仪 Lite 产品和服务，即表示您已阅读、认可并接受本隐私政策中所述之所有条款，包括我们随时作出的任何变更。我们承诺遵照适用法律（包括您所在地的数据保护法律）来保护您的个人信息的隐私、机密和安全。同时我们承诺我们的全体员工和代理商履行这些义务。</p>

                <p>最后，我们所希望的就是为我们的用户带来最好的体验。如果您对这一隐私政策中概括的数据处理惯例有任何疑问，请通过 <a
                        href="mailto:privacy@qingping.co">privacy@qingping.co</a> 联系我们的数据保护官，以便我们处理您的需求。</p>

                <p><b>收集哪些信息以及如何使用信息？</b></p>
                <p><b>收集的信息类别</b></p>

                <p>为了向您提供我们的服务，我们会要求您提供向您提供服务所必需的个人信息。如果您不提供个人信息，我们可能无法向您提供我们的产品或服务。我们收集以下各类信息（无论其是否为个人信息）：</p>

                <ul>
                    <li style={{listStyleType: 'desc'}}><b>账号信息</b>：我们可能收集与您的小米账号相关的信息，包括小米账号、小米ID号和电子邮箱。</li>

                    <li style={{listStyleType: 'desc'}}><b>设备信息</b>: ：与您的设备相关的信息。包括设备的名称、设备安装位置（如客厅）、固件版本号、温湿度、PM2.5、PM10、二氧化碳、MAC地址等。</li>

                    <li style={{listStyleType: 'desc'}}><b>您提供的反馈信息</b>：在您使用用户反馈功能时，我们可能收集问题日志、您主动提供的联系方式、反馈内容。</li>
                </ul>

                <p><b>这些个人信息将会被如何使用</b></p>

                <p>收集个人信息的目的在于向您提供产品和/或服务，并且保证我们遵守适用法律。您特此同意我们出于本隐私政策规定的目的处理个人信息，并向我们的关联公司（涉及通信、社交媒体、技术和云计算）、第三方服务供应商（定义如下）披露个人信息。</p>
                <p>我们可能会将您的个人信息用于下列目的：</p>
                <ul>
                    <li style={{listStyleType: 'desc', marginLeft: 15}}>提供、处理、维护、改善、开发我们的商品和/或提供给您的服务，包括售后服务和客户支持，以及通过设备或网站提供的服务。</li>

                    <li style={{listStyleType: 'desc', marginLeft: 15}}>与您就您的设备、服务或其他日常问题（例如软件更新、客户咨询支持、我们活动的相关信息、通知）等进行交流。</li>
                </ul>
                <p>关于我们如何使用您的信息（其中可能包含个人信息），下面提供了更多详细信息：</p>
                <ul>
                    <li style={{listStyleType: 'desc', marginLeft: 15}}><b>固件升级</b>：您可以将您的设备升级至最新的系统版本，以享受各种新的特性。为此，我们会收集设备的固件版本号。</li>
                    <li style={{listStyleType: 'desc', marginLeft: 15}}><b>收集用户反馈</b>：您提供的反馈对帮助我们改进服务而言极为珍贵。为了跟踪您选择提供的反馈，青萍能会使用您所提供的个人信息与您联系，并保留记录。</li>
                </ul>

                <p><b>直接推广</b></p>
                <p>我们可能会使用您的电子邮箱地址、小米账号向您提供青萍公司及其合作伙伴的产品和服务相关的推广资料。我们严格遵守您所在地的数据保护法规，要求独立的明确许可，所以我们只会在征得您的同意或您表示不反对时使用您的个人信息。您有权终止我们使用计划用于直接推广的个人数据。如果您不再希望接收某些类别的电子邮件，您可以通过发送邮件到 <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>来进行取消。我们不会将您的个人信息传送给我们的商业伙伴用于直接推广。</p>
                <p><b>Cookie 和其他技术（只在浏览 www.qingping.co 时）</b></p>
                <p><b>收集哪些信息以及如何使用这些信息？</b></p>
                <ul>
                    <li style={{listStyleType: 'desc', marginLeft: 15}}><b>青萍和第三方服务供应商使用 cookie、标签和脚本等技术。这些技术用于分析趋势、管理网站、追踪用户的网站活动并收集关于整个用户群的人口统计信息。我们会收到这些公司基于使用上述技术的单独和汇总报告。</b></li>
                    <li style={{listStyleType: 'desc', marginLeft: 15}}><b>日志文件</b>：和大部分网站一样，我们收集特定信息并保存在日志文件中。此类信息可能包括互联网协议 (IP) 地址、浏览器类型、互联网服务供应商 (ISP)、引用/退出页面、操作系统、日期/时间戳和点击流数据。 我们不会将自动收集的数据链接到我们收集的关于您的其他信息之中。</li>

                    <li style={{listStyleType: 'desc', marginLeft: 15}}><b>广告</b>：我们与第三方服务供应商合作，在我们的网站上展示广告或管理我们在其他网站上的广告。我们的第三方服务供应商可能会使用诸如 cookie 等技术来收集您在其网站或其他网站上的活动信息，以根据您的浏览活动和兴趣为您提供广告。</li>

                    <li style={{listStyleType: 'desc', marginLeft: 15}}><b>移动分析</b>：在某些移动软件中，我们使用分析技术，以更好地了解我们的软件的使用情况。此类软件可能记录您使用它们的频率、软件内发生的事件、累计使用、性能数据及应用程序崩溃发生的位置。</li>

                    <li style={{listStyleType: 'desc', marginLeft: 15}}><b>本地存储 - HTML5/Flash</b>：我们使用本地存储对象 (LSO)，例如 HTML5 或 Flash 存储内容和偏好。根据您的网页浏览行为，与我们合作在网站上提供某些功能或投放广告的第三方也会通过 HTML5 或 Flash cookie 来收集和存储相关信息。各种浏览器提供了各自的管理工具来删除 HTML5 本地存储对象。</li>
                </ul>

                <p>我们与谁共享您的信息？</p>
                <p>我们不会将任何个人信息出售给第三方。</p>
                <p>我们有时可能会向第三方（定义见下文）披露您的个人信息，以便提供您要求的产品或服务。</p>
                <p>我们可能会向本部分下文列出的第三方服务供应商和关联公司作出披露。在本部分所述的各种情况下，您可以放心，青萍仅会根据您的授权共享您的个人信息。您应当了解，在下文描述的任何情况下，当青萍与第三方服务供应商共享您的个人信息时，青萍会通过合同规定第三方的实践和义务，遵守适用的地方数据保护法。青萍会通过合同保证第三方服务供应商遵守您所属司法管辖区中适用于他们的隐私权标准。</p>

                <p>与我们集团和第三方服务供应商共享信息</p>
                <p>为了顺利地从事商业经营，为您提供产品和服务的全部功能，我们可能向关联公司或第三方服务供应商（在线商店、送货服务供应商、电信公司、数据中心、数据存储设施、客户服务供应商、广告和推广服务供应商、代表青萍的代理，统称为&quot;第三方服务供应商&quot;）披露您的个人信息。此类第三方服务供应商可能代表青萍或出于上述的一项或多项目的处理您的个人信息。如果您不再希望允许我们共享这些信息，请发送电子邮件到 <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> 联系我们。
                </p>

                <p>与其他人共享信息</p>
                <p>在适用法律要求时，青萍可能会不经过进一步同意而披露您的个人信息。</p>

                <p className={s.pt12}>不需要同意的信息</p>
                <ul>
                    <li>
                        我们可能以汇总的形式与第三方（例如我们网站上的广告商）共享匿名信息，用于商业目的；我们可能与其共享我们服务的一般使用趋势，例如在购买某些产品或从事某些交易的特定人群中的客户数量。
                    </li>
                    <li>
                        为免存疑，青萍可能在地方数据保护法明确允许的情况下和范围内（例如为了遵循传票）不经您的同意而收集、使用或披露您的个人信息，并且当我们可能会出于诚意相信为了保护我们的权利、保护您的安全或他人安全、为调查欺诈行为或对政府要求作出回应而必需披露时，不经您的同意而披露您的信息。
                    </li>
                </ul>
                <p className={s.pt135}>安全保障</p>
                <p className={s.pt12}>青萍的安全措施</p>
                <p>
                    我们承诺保证您的个人信息安全。为了防止未经授权的访问、披露或其他类似风险，我们落实了合理的物理、电子和管理措施流程，保护我们从您的产品和青萍网站上收集的信息。我们将采取所有合理的措施保护您的个人信息。
                </p>
                <p>
                    您的个人信息全都被储存在安全的服务器上，并在受控设施中受到保护。我们依据重要性和敏感性对您的数据进行分类，并且保证您的个人信息具有最高的安全等级。我们保证通过访问这些信息来帮助向您提供产品和服务的员工和第三方服务供应商具有严格的合同保密义务，如果未能履行这些义务，其将会受到纪律处分或被终止合作。总而言之，我们定期审查信息收集、储存和处理实践，包括物理安全措施，以防止任何未经授权的访问和使用。
                </p>
                <p>
                    我们将采取所有可行的措施保护您的个人信息。但是，您应当意识到互联网的使用并不总是安全的，因此，我们不能保证在通过互联网双向传输时任何个人信息的安全性或完整性。
                </p>
                <p className={s.pt12}>您能做什么！</p>
                <ul>
                    <li>
                        您可以通过不向任何人（除非此人经您正式授权）披露您的登录密码或账户信息，为保护您个人信息的安全发挥作用。
                    </li>
                    <li>
                        青萍不对因您未能保持个人信息的私密性而导致第三方访问您的个人信息进而造成的安全疏漏承担责任。尽管有上述规定，如果发生互联网其他任何用户未经授权使用您账户的情况或其他任何安全漏洞，您必须立即通知我们。
                    </li>
                    <li>您的协助将有助于我们保护您个人信息的私密性。</li>
                </ul>
                <p className={s.pt135}>保留政策</p>
                <p>
                    如有必要实现收集信息的目的，或者遵守适用法律要求或允许，我们将一直保留个人信息。只要能够合理推断保留个人信息不能实现收集个人信息的目的，并且出于法律、解决纠纷、强制实施我们的协议或其他商业目的不再需要保留信息时，我们将不再保留个人信息，或者会消除将个人信息和特定个人关联起来的途径。
                </p>
                <p className={s.pt135}>访问您的设备上的其他功能</p>
                <p>
                    我们的应用程序可能会访问您设备上的某些功能，例如Wi-Fi
                    网络状态，以及其他功能。这些信息用于允许这些应用程序在您的设备上运行，并且允许您与其互动。在任何时候，您可以通过在设备水平上关闭应用程序或者在设备的系统中关闭对应权限来撤销许可
                </p>
                <p className={s.pt135}>您可以控制您的信息！</p>
                <p className={s.pt12}>控制设置</p>
                <p>
                    青萍承认每个人对隐私权的关注各不相同。因此，我们提供了一些示例，说明XX提供的各种方式，供您选择，以限制收集、使用、披露或处理您的个人信息，并控制您的隐私权设置：
                </p>
                <ul>
                    <li>登入或登出青萍账户；</li>
                    <li>绑定或解绑设备；</li>
                </ul>
                <p>
                    如果您之前因为上述目的同意我们使用您的个人信息，您可以随时通过书面或者向{' '}
                    <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>{' '}
                    发送邮件的方式联系我们来改变您的决定。
                </p>
                <p className={s.pt12}>访问、更新或更正您的个人信息</p>
                <ul>
                    <li>
                        您有权要求访问和/或更正我们持有的与您有关的任何个人信息。当您更新个人信息时，在我们处理您的请求前，会要求验证您的身份。一旦我们获得充分信息，可处理您的请求以访问或更正您的个人信息时，我们将在适用数据保护法规定的时间内对您的请求做出回应。
                    </li>
                    <li>
                        我们通常免费提供这些服务，但是保留对您的数据访问请求收取合理费用的权利。
                    </li>
                    <li>
                        如果您希望请求访问我们持有的个人数据或者如果您认为我们持有的关于您的任何信息是不正确或不完整的，请尽快致信或者向下方提供的电子邮箱地址发送电子邮件联系我们。电子邮箱：
                        <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>。
                    </li>
                </ul>
                <p className={s.pt12}>撤销同意</p>
                <ul>
                    <li>
                        您可以通过提交请求，撤销同意收集、使用和/或披露我们掌握或控制的您的个人信息，注销账号。
                        <a href="mailto:privacy@qingping.co">
                            这可以向privacy@qingping.co
                        </a>
                        发送电子邮件给我们。我们将会在您做出请求后的10
                        个工作日内处理您的请求，并且会根据您的请求，在此后不再收集、使用和/或披露您的个人信息。
                    </li>
                    <li>
                        请注意，您撤销同意会导致某些法律后果。根据您撤销同意让我们处理您的个人信息的范围，这可能表示您不能享受青萍的产品和服务。
                    </li>
                </ul>
                <p className={s.pt12}>注销账号</p>
                <p>如果您希望注销青萍账号，可以在青萍+ app 的账号管理中进行注销，或者你也可以向 <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> 发送电子邮件给我们申请注销。由于注销账号的操作，会使您当前账号下的所有设备信息被删除且无法恢复，请您谨慎操作。</p>

                <p className={s.pt135}>将个人信息转移到您所属的司法管辖区之外</p>
                <p>
                    如果我们需要将个人信息转移到您所属的司法管辖区之外，无论是转移给我们的关联公司或第三方服务供应商时，我们将按照适用法律进行。特别是，我们通过落实合适的安全保障措施，保证所有这类转移满足适用的地方数据保护法的要求。
                </p>
                <p className={s.pt135}>其他规定</p>
                <p className={s.pt12}>未成年人</p>
                <ul>
                    <li>
                        我们认为监督孩子使用我们的产品和服务是父母的责任。但是，我们的政策不要求获得未成年人的个人信息，或者向这类人群发送任何促销资料。
                    </li>
                    <li>
                        青萍不会寻求或试图寻求接收来自未成年人的任何个人信息。如果家长或监护人有理由相信未成年人未经他们事先同意而向青萍提交了个人信息，请联系我们以确保删除此类个人信息，并保证未成年人取消订阅任何适用的青萍服务。
                    </li>
                </ul>
                <p className={s.pt12}>优先顺序</p>
                <p>
                    如果您同意了适用的用户协议，并且此类用户协议和本隐私政策之间存在不一致，将以此类用户协议为准。
                </p>
                <p className={s.pt12}>隐私政策的更新</p>
                <p>
                    我们会对隐私政策进行定期审核，为反映我们信息惯例的变更，我们可能会更新本隐私政策。如果我们对本隐私政策进行重大变更，我们将通过（向您账户指定的邮箱地址发送）电子邮件或在所有青萍网站公布或通过移动设备通知您，这样您可以了解我们收集的信息以及我们如何使用这些信息。此类隐私政策变化将从通知或网站规定的生效日期开始适用。我们建议您定期查阅本网页获取我们隐私权实践的最新信息。您继续使用产品和网站、手机和/或其他任何设备上的服务，将被视为接受更新的隐私政策。在我们向您收集更多的个人信息或我们希望因为新的目的使用或披露您的个人信息时，我们会再次征得您的同意。
                </p>
                <p className={s.pt135}>我是否必须同意任何第三方条款与条件？</p>
                <p>
                    我们的隐私政策不适用于第三方提供的产品和服务。青萍产品和服务可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。因此，我们强烈建议您花时间阅读该第三方的隐私政策，就像阅读我们的政策一样。我们不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。我们的隐私政策不适用通过我们的服务链接的其他网站。
                </p>
                <br />
                <p>青萍科技（北京）有限公司</p>
                <p>地址：北京市朝阳区望京北路 9 号叶青大厦 C 座 309 室</p>
                <p>邮编：100102</p>
                <p>
                    电子邮箱：
                    <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>
                </p>

                <p>感谢您花时间了解我们的隐私政策！</p>
            </div>
        </div>
    );
  }
}

export default PlGprivacy;
