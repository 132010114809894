import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import s from './Snineth.module.scss'

class Snineth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Snineth.getBoundingClientRect()
    if (this.refs.Snineth && clientHeight >= textRect.bottom - 200) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div ref="Snineth">
        <div className={s.Snineth}>
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              <div className={s.title}>
                <FormattedMessage id="parrot_fifteen_title" />
              </div>
              {locale.indexOf('US') > -1 ? (
                <div className={s.method_en}>
                  <div className={s.method_item1}>
                    <div className={s.method_title}>
                      <FormattedMessage id="parrot_fifteen_item1" />
                    </div>
                    <div className={s.method_desc}>
                      <FormattedMessage id="parrot_fifteen_item1_desc" />
                    </div>
                    <div className={s.method_img1}></div>
                    <div className={s.method_content}>
                      <span className={s.main_body}>
                        <FormattedMessage id="parrot_fifteen_main" />
                      </span>
                      <span className={s.base}>
                        <FormattedMessage id="parrot_fifteen_base" />
                      </span>
                    </div>
                  </div>
                  <div className={s.method_item2}>
                    <div className={s.method_title}>
                      <FormattedMessage id="parrot_fifteen_item2" />
                    </div>
                    <div className={s.method_desc}>
                      <FormattedMessage id="parrot_fifteen_item2_desc" />
                    </div>
                    <div className={s.method_img2}></div>
                    <div className={s.method_content}>
                      <span className={s.main_body}>
                        <FormattedMessage id="parrot_fifteen_main" />
                      </span>
                      <span className={s.tape}>
                        <FormattedMessage
                          id="parrot_fifteen_tape"
                          values={{ br: ' ' }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={s.method}>
                  <div className={s.method_item1}>
                    <div className={s.method_title}>
                      <FormattedMessage id="parrot_fifteen_item1" />
                    </div>
                    <div className={s.method_desc}>
                      <FormattedMessage id="parrot_fifteen_item1_desc" />
                    </div>
                    <div className={s.method_img1}></div>
                    <div className={s.method_content}>
                      <span className={s.main_body}>
                        <FormattedMessage id="parrot_fifteen_main" />
                      </span>
                      <span className={s.base}>
                        <FormattedMessage id="parrot_fifteen_base" />
                      </span>
                    </div>
                  </div>
                  <div className={s.method_item2}>
                    <div className={s.method_title}>
                      <FormattedMessage id="parrot_fifteen_item2" />
                    </div>
                    <div className={s.method_desc}>
                      <FormattedMessage id="parrot_fifteen_item2_desc" />
                    </div>
                    <div className={s.method_img2}></div>
                    <div className={s.method_content}>
                      <span className={s.main_body}>
                        <FormattedMessage id="parrot_fifteen_main" />
                      </span>
                      <span className={s.tape}>
                        <FormattedMessage
                          id="parrot_fifteen_tape"
                          values={{ br: ' ' }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SNineth = connect(mapStateToProps)(Snineth)

export default SNineth
