import PcRoutes from './routes/client_pc';
import MobileRoutes from './routes/client_mobile';

//环境变量
let Env

//路由
let Routers
const ua = navigator.userAgent
let path = window.location.pathname
if (
  (/AppleWebKit.*Mobile/i.test(ua) ||
    /MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(
      ua
    )) &&
  !/iPad/.test(ua)
) {
  Env = 'mobile'
  Routers = MobileRoutes
  if (window.location.pathname === '/air') {
    path = '/plus'
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      path
  } else if (window.location.pathname === '/bluetooth_alarm_clock/apps') {
    path = '/bluetooth-alarm-clock/apps'
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      path
  } else if (
    window.location.pathname === '/mi_multifunction_air_monitor/privacy'
  ) {
    path = '/mi-multifunction-air-monitor/privacy'
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      path
  } else if (window.location.pathname === '/mi_temp_rh_monitor/privacy') {
    path = '/mi-temp-rh-monitor/privacy'
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      path
  }
} else {
  Env = 'pc'
  Routers = PcRoutes
  if (window.location.pathname === '/air') {
    path = '/plus'
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      path
  } else if (window.location.pathname === '/bluetooth_alarm_clock/apps') {
    path = '/bluetooth-alarm-clock/apps'
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      path
  } else if (
    window.location.pathname === '/mi_multifunction_air_monitor/privacy'
  ) {
    path = '/mi-multifunction-air-monitor/privacy'
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      path
  } else if (window.location.pathname === '/mi_temp_rh_monitor/privacy') {
    path = '/mi-temp-rh-monitor/privacy'
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      path
  }
}

export { Env, Routers }