import React from 'react';

import s from './Dthird.module.scss';

const Dthird = () =>
    <div className={s.Dthird}>
        <div className={s.title}>最小分度值 0.1，测量更准</div>
        <div className={s.desc}>内置先进的 Sensirion 温湿度传感器</div>
        <div className={s.text}>
            精准且灵敏的 Sensirion 温湿度传感器，精确感知 0.1℃ 室温变化和 0.1% 相对湿度变化，每秒刷新 1 次，测量更准!
        </div>
    </div>;

export default Dthird;

