import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
// import { Link } from 'react-router-dom'

import s from './Seighth.module.scss'

class Seighth extends React.PureComponent {
  static handleClick() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Seighth.getBoundingClientRect()
    if (this.refs.Seighth && clientHeight >= textRect.bottom - 200) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div
        className={s.Seighth}
        ref="Seighth"
      >
        <div className={s.wrap} ref="text">

            <div className={s.pic}></div>
            
            <div className={s.text}>

              <div className={s.title}>
                <FormattedMessage id="chicken_seventh_title" />
              </div>
              <div className={s.desc}>
                <FormattedMessage id="chicken_seventh_desc" values={{br: <br />}} />
              </div>

              <div className={locale.indexOf('US') > -1 ? s.img_en : (locale.indexOf('TW') > -1 ? s.img_tw : s.img)} style={{marginBottom: locale.indexOf('US') > -1 ? -90 : -135}}></div>

            </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SEighth = connect(mapStateToProps)(Seighth)

export default SEighth
