import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl'

import s from './airContent.module.scss';

class airContent extends React.PureComponent {
  static preHandler (e) {
    e.preventDefault();
  }
  constructor(props) {
    super(props);
    this.state = {
      ios: true,
      downloadHtml: null,
      toBrowser: s.toBrowser,
      isIos: false
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    const ua = navigator.userAgent;
    const appVersion = navigator.appVersion;
    if (/Android/.test(appVersion)) {
      if (/MicroMessenger/gi.test(ua)) {
        this.setState({
          downloadHtml: <span className={s.download} onClick={() => this.handleShowPhone()}><FormattedMessage id="download_imd" /></span>,
          ios: false
        });
      } else {
        this.setState({
          downloadHtml: <a className={s.download} href="https://qing.cleargrass.com/download/magpie" rel="noopener noreferrer"><span><FormattedMessage id="download_imd" /></span></a>,
          ios: false
        });
      }
    } else {
      this.setState({
        downloadHtml: <a className={s.download} href="https://apps.apple.com/cn/app/id1481163925" target="_blank" rel="noopener noreferrer"><span><FormattedMessage id="download_imd" /></span></a>,
        isIos: true
      });
    }
    this.setOverflow();
    if (this.refs.air_one) {
      const clientHeight = document.documentElement.clientHeight;
      this.refs.air_one.style.height = `${clientHeight}px`;
      this.refs.air_two.style.height = `${clientHeight}px`;
      // this.refs.air_three.style.height = `${clientHeight / 2}px`;
    }
    window.addEventListener('scroll', this._handleScroll);
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  setOverflow () {
    if (this.state.display) {
      if (this.state.ios) {
        document.getElementsByTagName('html')[0].style.position = 'fixed';
      }
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      document.getElementsByTagName('html')[0].style.height = '100%';
      document.addEventListener('touchmove', airContent.preHandler, false);
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
      document.getElementsByTagName('html')[0].style.position = 'initial';
      document.getElementsByTagName('html')[0].style.height = 'auto';
      document.removeEventListener('touchmove', airContent.preHandler, false);
    }
  }
  handleShowPhone () {
    this.setState({
      toBrowser: s.toBrowserShow
    });
    this.props.headerNoDisplay && this.props.headerNoDisplay(false)
  }
  handleScroll () {
    const airBox = this.refs.airBox.getBoundingClientRect();
    const clientHeight = document.documentElement.clientHeight / 2;
    this.refs.airBg_1.style.opacity = -airBox.top * 2 / clientHeight;

    if (-airBox.top >= clientHeight * 2) {
      // this.refs.air_two_img.style.opacity = 0.3;
      // this.refs.air_two_img.style.position = 'fixed';
    } else {
      this.refs.air_two_img.style.opacity = 1;
      this.refs.air_two_img.style.position = 'relative';
    }
  }
  render () {
    const { locale } = this.props
    const { isIos } = this.state
    return (
      <div className={s.airContent}>
        <div className={s.airBg} />
        <div className={s.airBg_1} ref="airBg_1" />
        <ul ref="airBox">
          <li className={s.air_one} ref="air_one">
            <div className={s.air_one_text}>
              <i className={s.logo} />
              <span className={s.large}><FormattedMessage id="cg_magpie" /><br />
                <span className={s.slgon}><FormattedMessage id="magpie_prd" /></span>
              </span>
              <ul className={locale.indexOf('US') > -1 ? s.ul_box_en : s.ul_box}>
                <li>
                  <span className={s.normal}><FormattedMessage id="plus_device" /></span>
                </li>
                <li>
                  <span className={s.normal}><FormattedMessage id="plus_historical" /></span>
                </li>
                <li>
                  <span className={s.normal}><FormattedMessage id="plus_remote" /></span>
                </li>
                <li>
                  <span className={s.normal}><FormattedMessage id="plus_notification" /></span>
                </li>
              </ul>
              {/* <p className={s.normal_p}><FormattedMessage id="plus_support" /></p> */}
            </div>
            <div className={s.btn}>
              {this.state.downloadHtml}
            </div>
            {/* { !isIos ? <div className={s.download}><a href="https://qing.cleargrass.com/download/magpie" rel="noopener noreferrer"><FormattedMessage id="download_apk" /></a><div className={s.handline}></div></div> : null } */}
          </li>
          <li className={s.air_two} ref="air_two">
            <div className={locale.indexOf('US') > -1 ? s.air_two_img_en : (locale.indexOf('TW') > -1 ? s.air_two_img_tw : s.air_two_img)} ref="air_two_img" />
          </li>
          {/* <li className={s.air_three} ref="air_three">
                        <div className={s.air_three_text}>
                            <span className={s.large}>连接青萍智能设备</span>
                            <ul>
                                <li>
                                    <span className={s.normal}>青萍空气 app 支持青萍蓝牙温湿度计、青萍空气检测仪， 连接后您可查看室内多种环境数值、历史数据、解读，与亲友共享设备。</span>
                                </li>
                            </ul>
                        </div>
                    </li> */}
        </ul>
        <div className={this.state.toBrowser}>
          <div className={s.arrow} />
          <div className={s.toBrowserText}>1. 点击右上角&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />2. 在菜单中选择 <span>“浏览器中打开”</span></div>
          <div className={s.toBrowserPhone} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

const AirContent = connect(mapStateToProps)(airContent)

export default AirContent;
