import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import s from './Sfirst.module.scss'

class Sfirst extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
      isPlay: false,
    }
    this._close = this.close.bind(this)
  }
  componentDidMount() {
    if (this.refs.video) {
      this.refs.video.style.height = '100%'
      this.refs.video.addEventListener('ended', this._close)
    }
    if (this.refs.text) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  componentWillUnmount() {}
  play() {
    const element = this.refs.video
    element.style.zIndex = 5
    element.style.display = 'block'
    this.refs.close.style.display = 'block'
    this.refs.img.style.display = 'block'
    // if (!this.state.isPlay) {
    element.play()
    // } else {
    //     element.pause();
    // }
    this.setState({
      isPlay: !this.state.isPlay,
    })
  }
  close() {
    this.refs.video.style.display = 'none'
    this.refs.video.style.zIndex = 1
    this.refs.video.load()
    this.refs.close.style.display = 'none'
    this.refs.img.style.display = 'none'
  }
  render() {
    const { locale } = this.props
    return (
      <div className={locale.indexOf('US') > -1 ? s.Sfirst_en : s.Sfirst} ref="Sfirst">
        <div className={s.text} ref="text">
          <div className={s.large}>
            <span>
              <FormattedMessage id="chicken_first_title" values={{br: <br />}} />
            </span>
          </div>
          <div className={s.normal}>
            <FormattedMessage id="chicken_first_desc1" />
            <span className={s.line}>·</span>
            <FormattedMessage id="chicken_first_desc2" />
            <span className={s.line}>·</span>
            <FormattedMessage id="chicken_first_desc3" />
            <span className={s.line}>·</span>
            <FormattedMessage id="chicken_first_desc4" />
          </div>
        </div>
        <div className={s.img} ref="img" />
        <div className={s.closeBox}>
          <div className={s.close} ref="close" onClick={() => this.close()} />
        </div>
        <div
          className={
            locale.indexOf('US') > -1 || locale.indexOf('TW') > -1
              ? s.IF_en
              : s.IF
          }
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SFirst = connect(mapStateToProps)(Sfirst)

export default SFirst
