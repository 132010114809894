import React, { Component } from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import { connect } from 'react-redux';
import { Env } from '../../env.config'

import enUS from '../../locale/en_US'
import zhCN from '../../locale/zh_CN'
import zhTW from '../../locale/zh_TW'

import en from 'react-intl/locale-data/en'
import zh from 'react-intl/locale-data/zh'

addLocaleData([...zh, ...en])

const messages = {}
messages['en-US'] = enUS
messages['zh-CN'] = zhCN
messages['zh-TW'] = zhTW

class Inter extends React.PureComponent {
    render() {
        console.log(this.props, Env, messages,'props in Intl')
      let { locale, localeMessage, children } = this.props;
      // console.log(this.props, messages[localeMessage], 'this.props')
      return (
        <IntlProvider key={locale} locale={locale} messages={messages[localeMessage]}>
          {children}
        </IntlProvider>
      )
    }
};

function GetQueryString(name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = window.location.search.substr(1).match(reg)
    if (r !== null) return unescape(r[2])
    return null
  }

function chooseLocale(val) {
    //需注释部分
    // return 'zh-CN'
    if (val) {
        return val
    } else {
        let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language
        console.log(browserLanguage, 'browerLanguage in App')
            if (browserLanguage.indexOf('zh') > -1) {
                if (browserLanguage.indexOf('TW') > -1) {
                    browserLanguage = 'zh-TW'
                } else {
                    browserLanguage = 'zh-CN'
                }
            } else {
                browserLanguage = 'en-US'
            }
    
            const lang = GetQueryString('locale') || browserLanguage
    
            return lang
    }
    
  }

  const mapStateToProps = (state, ownProps) => ({
    locale: state.language,
    localeMessage: chooseLocale(state.language)
  });
  
  let Intl = connect(mapStateToProps)(Inter);
  
  export default Intl;

