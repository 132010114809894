import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './Stwelfth.module.scss'

class Stwelfth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Stwelfth.getBoundingClientRect();
        if (clientHeight >= textRect.bottom - 200 && this.refs.Stwelfth) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 500);
        }
    }
    render() {
        const { locale } = this.props
        return (
            <div ref="Stwelfth">
                <div className={s.Stwelfth}>
                    <div className={s.wrap}>
                        <div className={locale.indexOf('US') > -1 ? s.img_en : (locale.indexOf('TW') > -1 ? s.img_tw : s.img)} />
                        <div className={s.text} ref="text">
                            <div className={s.title}>
                                <div className={(locale.indexOf('US') > -1 || locale.indexOf('TW') > -1) ? s.mi_en : s.mi}></div>
                                {/* <FormattedMessage id="s_twelfth_title_1" /> */}
                                <FormattedMessage id="s_twelfth_title_2" />
                            </div>
                            <div className={s.Sfourth_desc}>
                            
                                <FormattedMessage id="s_twelfth_desc" values={{br: ''}} />
                                <br></br>
                                <div >* <FormattedMessage id="support_mi_A"></FormattedMessage></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  const STwelfth = connect(mapStateToProps)(Stwelfth)

export default STwelfth;

