import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './Seleventh.module.scss'

class Seleventh extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Seleventh.getBoundingClientRect();
        if (this.refs.Seleventh && clientHeight >= textRect.bottom) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 500);
        }
    }
    render() {
        const { locale } = this.props
        return (
            <div ref="Seleventh">
                <div className={ locale.indexOf('US') > -1 ? s.Seleventh_en : (locale.indexOf('TW') > -1 ? s.Seleventh_tw : s.Seleventh)}>
                    <div className={s.wrap}>
                        <div className={s.text} ref="text">
                            <div className={s.title}>
                                <FormattedMessage id="s_eleventh_title" values={{ br: <br /> }} />
                            </div>
                            <div className={s.sub_title}>
                                <FormattedMessage id="s_eleventh_sub_title" values={{ spot: <span className={s.line}>·</span> }} />
                            </div>
                            <div className={s.desc}>
                                <FormattedMessage id="s_eleventh_desc" />
                            </div>
                        </div>
                        <div className={s.img} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  const SEleventh = connect(mapStateToProps)(Seleventh)

export default SEleventh;

