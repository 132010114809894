import React, { Component } from 'react';

import Header from './../../header';
import Footer from './../../footer';
import Dfirst from './../Dfirst';
import Dsecond from './../Dsecond';
import Dthird from './../Dthird';
import Dfourth from './../Dfourth';
import Dfifth from './../Dfifth';
import Dsixth from './../Dsixth';
import Dseventh from './../Dseventh';
import Deighth from './../Deighth';
import Dnineth from './../Dnineth';
import Dtenth from './../Dtenth';
import Deleventh from './../Deleventh';

import s from './Dstyle.module.scss';

class Dstyle extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            language: null,
            position: false
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
        switch (browserLanguage) {
            case 'en':
                browserLanguage = 'en-US';
                break;
            case 'zh':
                browserLanguage = 'zh-CN';
                break;
            default:
                browserLanguage = 'en-US';
        }
        this.setState({
            language: browserLanguage
        });
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const textRect = this.refs.box.getBoundingClientRect();
        if (textRect.top < -50 && this.refs.box) {
            this.setState({
                position: true
            });
        } else {
            this.setState({
                position: false
            });
        }
    }

    render() {
        const { location } = this.props;
        const path = this.props.match.path;
        return (
            <div className={s.box} ref="box">
                <div className={s.Dstyle}>
                    <Header location={location} language={this.state.language} path={path} navPosition={this.state.position} />
                    <Dfirst />
                    <Dsecond />
                    <Dthird />
                    <Dfourth />
                    <Dfifth />
                    <Dsixth />
                    <Dseventh />
                    <Deighth />
                    <Dnineth />
                    <Dtenth />
                    <Deleventh />
                    <Footer location={location} />
                </div>
            </div>
        );
    }
}

export default Dstyle;
