import React from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Param.module.scss';

const Param = () =>
    <div className={s.box}>
        <div className={s.param}>
            <div className={s.row}>
                <div className={s.name}><FormattedMessage id="exterior" /></div>
                <div className={s.cell}>
                    <div className={s.sizeImg} />
                    <ul>
                        <li><span className={s.type}>产品尺寸</span><span>60.8 × 60.8 × 22.5 毫米</span></li>
                        <li><span className={s.type}>产品颜色</span><span>白色</span></li>
                    </ul>
                </div>
            </div>
            <div className={s.row}>
                <div className={s.name}><FormattedMessage id="range" /></div>
                <div className={s.cell}>
                    <ul>
                        <li><span className={s.type}>温度量程</span><span>-9.9℃ ~ 60℃</span></li>
                        <li><span className={s.type}>湿度量程</span><span>0 ~ 99.9%RH（无凝露环境）</span></li>
                    </ul>
                </div>
            </div>
            <div className={s.row}>
                <div className={s.name}><FormattedMessage id="p_specifications" /></div>
                <div className={s.cell}>
                    <ul>
                        <li><span className={s.type}>产品名称</span><span>小米米家蓝牙温湿度计</span></li>
                        <li><span className={s.type}>产品型号</span><span>LYWSDCGQ/01ZM</span></li>
                        <li><span className={s.type}>产品重量</span><span>43 克</span></li>
                        <li><span className={s.type}>屏幕尺寸</span><span>1.78 英寸</span></li>
                        <li><span className={s.type}>额定功率</span><span>0.18 mW</span></li>
                        <li><span className={s.type}>电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;池</span><span>7 号电池（AAA） × 1</span></li>
                    </ul>
                </div>
            </div>
            <div className={s.lastRow}>
                <div className={s.name}><FormattedMessage id="p_pack" /></div>
                <div className={s.cell}>
                    <div className={s.pack}>
                        <div>
                            <i className={s.pack_1} />
                            <FormattedMessage id="duck" />
                        </div>
                        <div>
                            <i className={s.pack_2} />
                            <FormattedMessage id="wall" />
                        </div>
                        <div>
                            <i className={s.pack_3} />
                            <FormattedMessage id="seven_battery" />
                        </div>
                        <div>
                            <i className={s.pack_4} />
                            <FormattedMessage id="p_instructions" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;

export default Param;

