import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './../../header';
import Footer from './../../footer';
import Design from './../design';
import Product from './../product';
import s from './Home.module.scss'
import common from '../../../../../locale/common'
class Index extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      position: false
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['qingping_' + local]
    
    let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US';
        break;
      case 'zh':
        browserLanguage = 'zh-CN';
        break;
      default:
        browserLanguage = 'en-US';
    }
    this.setState({
      language: browserLanguage
    });
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  handleScroll () {
    const textRect = this.refs.box.getBoundingClientRect();
    // console.log(textRect.top);
    if (textRect.top < 0 && this.refs.box) {
      this.setState({
        position: true
      });
    } else {
      this.setState({
        position: false
      });
    }
  }

  render () {
    const { location } = this.props;
    const path = this.props.match.path;
    return (
      <div className={s.box} ref="box">
        <Header location={location} language={this.state.language} position={this.state.position} path={path} />
        <Design />
        <Product />
        <Footer location={location} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let index = connect(mapStateToProps)(Index);

export default index;

