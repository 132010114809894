import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Header from './../header';
import Footer from './../footer';
import s from './store.module.scss';

class Store extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            language: null,
            position: false
        };
    }

    render() {
        const { location } = this.props;
        const path = this.props.match.path;
        return (
            <div className={s.box} ref="box">
                <Header location={location} language={this.state.language} position={this.state.position} path={path} />
                <div className={s.store}>
                    <ul>
                        <li className={s.snow}>
                            <div className={s.img} />
                            <div className={s.title}><FormattedMessage id="snow" /></div>
                            <div className={s.price}>799<span>元</span></div>
                            <div className={s.btu}><a href="https://www.xiaomiyoupin.com/detail?gid=104822" target="_blank" rel="noopener noreferrer">购买</a></div>
                        </li>
                        <li className={s.bran}>
                            <div className={s.img} />
                            <div className={s.title}><FormattedMessage id="bran" /></div>
                            <div className={s.price}>399<span>元</span></div>
                            <div className={s.btu}><a href="https://item.mi.com/1184500007.html?cfrom=search" target="_blank" rel="noopener noreferrer">购买</a></div>
                        </li>
                        <li className={s.goose}>
                            <div className={s.img} />
                            <div className={s.title}><FormattedMessage id="goose" /></div>
                            <div className={s.price}>99<span>元</span></div>
                            <div className={s.btu}><a href="https://store.cleargrass.com/products/temp-rh-monitor" target="_blank" rel="noopener noreferrer">购买</a></div>
                        </li>
                        <li className={s.lora}>
                            <div className={s.img} />
                            <div className={s.title}><FormattedMessage id="lora" /></div>
                            <div className={s.price}>89<span>元</span></div>
                            <div className={s.btu}><a href="https://store.cleargrass.com/products/temp-rh-monitor-nb" target="_blank" rel="noopener noreferrer">购买</a></div>
                        </li>
                        <li className={s.duck}>
                            <div className={s.img} />
                            <div className={s.title}><FormattedMessage id="duck" /></div>
                            <div className={s.price}>69<span>元</span></div>
                            <div className={s.btu}><a href="https://item.mi.com/product/7303.html" target="_blank" rel="noopener noreferrer">购买</a></div>
                        </li>
                        <li className={s.pheasant}>
                            <div className={s.img} />
                            <div className={s.title}><FormattedMessage id="pheasant" /></div>
                            <div className={s.price}>129<span>元</span></div>
                            <div className={s.btuNoBuy}><span target="_blank">购买</span></div>
                        </li>
                        <li className={s.lipstick}>
                            <div className={s.title}><FormattedMessage id="lipstick" /></div>
                            <div className={s.price}>129<span>元</span></div>
                            <div className={s.btu}><a href="https://store.cleargrass.com/products/lipstick" target="_blank" rel="noopener noreferrer">购买</a></div>
                        </li>
                    </ul>
                </div>
                <Footer location={location} />
            </div>
        );
    }
}

export default Store;

