import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Sthirteenth.module.scss'

class Sthirteenth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Sthirteenth.getBoundingClientRect();
        if (clientHeight >= textRect.bottom - 200 && this.refs.Sthirteenth) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 500);
        }
    }
    render() {
        return (
            <div ref="Sthirteenth">
                <div className={s.Sthirteenth}>
                    <div className={s.wrap}>
                        <div className={s.text} ref="text">
                            <div className={s.title}>
                                <FormattedMessage id="s_thirteenth_title" />
                            </div>
                            <div className={s.Sfourth_desc}>
                                <FormattedMessage id="s_thirteenth_desc_1" />
                                <FormattedMessage id="s_thirteenth_desc_2" />
                            </div>
                        </div>
                        <div className={s.img} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Sthirteenth;

