import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import s from './style.module.scss'

class GaddPart extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.goose_add_part.getBoundingClientRect()
    if (clientHeight - 200 > textRect.top && this.refs.goose_add_part) {
      setTimeout(() => {
        this.refs.top_text.style.opacity = 1
        this.refs.top_text.style.top = '100px'
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div className={s.goose_add_part} ref="goose_add_part">
        <div className={s.anchor} id="mi"></div>
        <div className={s.top_text} ref="top_text">
          <h1>
            <FormattedMessage id="m_support" />
          </h1>
          <div
            className={
              locale.indexOf('US') > -1 || locale.indexOf('TW') > -1
                ? s.mi_icon_en
                : s.mi_icon
            }
          ></div>
          <p>
          * <FormattedMessage id="support_mi_B"></FormattedMessage>
            <FormattedMessage id="with_gateway_1" />
            <a
              rel="noopener noreferrer"
              href="https://home.mi.com/baike/index.html#/label/ability/688967186000000001"
              target="_blank"
            >
              <FormattedMessage id="with_gateway_2" />
            </a>
            <FormattedMessage id="with_gateway_3" />
            <Link
              rel="noopener noreferrer"
              target="_blank"
              className={s.goose_to_sparrow}
              to="/bluetooth-gateway/overview"
            >
              <FormattedMessage id="with_gateway_4" />
            </Link>
            <FormattedMessage id="with_gateway_5" />
          </p>
        </div>
        <div
          className={s.goose_content}
          style={{ paddingTop: locale.indexOf('US') > -1 ? 348 : 295 }}
        >
          <div
            className={
              locale.indexOf('US') > -1
                ? s.content_right_en
                : locale.indexOf('TW') > -1
                ? s.content_right_tw
                : s.content_right
            }
          ></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const GAddPart = connect(mapStateToProps)(GaddPart)

export default GAddPart
