import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Seighth.module.scss'

class Seighth extends React.PureComponent {
  static handleClick () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  constructor(props) {
    super(props);
    this.state = { // 初始state
      index: 0,
      opacity: 1
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  handleScroll () {
    const clientHeight = document.documentElement.clientHeight;
    const textRect = this.refs.Seighth.getBoundingClientRect();
    if (this.refs.Seighth && clientHeight >= textRect.bottom - 500) {
      setTimeout(() => {
        this.refs.text1.style.opacity = 1;
        this.refs.text1.style.left = '0';

        this.refs.text2.style.opacity = 1;
        this.refs.text2.style.right = '0';
      }, 300);
    }
  }
  render () {
    return (
      <div className={s.Seighth} ref="Seighth">
          <div className={s.wrap}>

            <div className={s.item} style={{marginBottom: 20}}>
              <div className={s.desc}>
                <div className={s.text1} ref="text1">
                  <div className={s.desc_title}>
                  <FormattedMessage id="mp_sensor" /> <br/>
                  <FormattedMessage id="mp_sensitive" />
                  </div>
                  <div className={s.desc_detail}>
                  <FormattedMessage id="mp_art" values={{br: <br />}} />
                  </div>
                </div>
              </div>
              <div className={s.pic_1}></div>
            </div>

            <div className={s.item}>
              <div className={s.pic_2}></div>
              <div className={s.desc}>
                <div className={s.text2} ref="text2">
                  <div className={s.desc_title}>
                    <FormattedMessage id="mp_low_consumption" values={{br: <br />}} />
                  </div>
                  <div className={s.desc_detail}>
                    <FormattedMessage id="mp_desc_2" values={{br: <br />}} />
                  </div>
                  <div className={s.memo}>
                    <FormattedMessage id="mp_desc_3" />
                  </div>
                </div>
              </div>
            </div>

          </div>
      </div>
    );
  }
}

export default Seighth;
