import React, { Component } from 'react';

import Nav from './../nav';
import s from './Header.module.scss'

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { // 初始state
      index: 0,
      opacity: 1
    };
  }
  componentDidMount () {
    // 添加文字显示动画，可添加任何样式 如：opacity, left
    const position = this.props.position;
    if (position) {
      this.refs.header.style.position = 'fixed';
    } else {
      this.refs.header.style.position = 'relative';
    }
  }
  render () {
    let headName = s.header;
    if (this.props.position) {
      headName = s.headerPosition;
    }

    return (
      <div className={headName} ref="header">
        <div className={s.top}>
          <Nav path={this.props.location.pathname} navPosition={this.props.navPosition} search={this.props.location.search} hideGooseChoice={this.props.hideGooseChoice} hideGooseChoiceFun={this.props.hideGooseChoiceFun} />
        </div>
      </div>
    );
  }
}

export default Header;
