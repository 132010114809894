import React, { Component } from 'react'
// import { FormattedMessage } from 'react-intl';

import s from './preset_apps.module.scss'
class goose_h_eu_doc extends React.PureComponent {
  componentDidMount() {
    window.location.href =
      'https://qingping.cleargrass.com/static/media/goose_h_eu_doc.pdf'
  }
  render() {
    return <div className={s.box} ref="box"></div>
  }
}

export default goose_h_eu_doc
