import React from 'react';

import s from './Dnineth.module.scss';

const Dnineth = () =>
    <div className={s.Dnineth}>
        <div className={s.title}>只需一节 7 号电池，即可默默守护一整年</div>
        <div className={s.desc}>屏幕功耗仅为 0.18mW，使用一节 7 号电池，续航长达一年。</div>
        <div className={s.text}>* 使用紫 7 电池测试。</div>
    </div>;

export default Dnineth;

