import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Sfourth.module.scss'

class Sfourth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Sfourth.getBoundingClientRect()
    if (clientHeight >= textRect.bottom && this.refs.text) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '152px'
      }, 500)
    }
  }
  render() {
    return (
      <div ref="Sfourth">
        <div className={s.Sfourth}>
          <div className={s.wrap}>
            <div className={s.text} ref="text" id="topnote-2">
              <div className={s.title}>
                <FormattedMessage
                  id="parrot_three_title"
                  values={{ br: <br /> }}
                />
              </div>
              <div className={s.numBox}>
                <div className={s.num_item}>
                  <div className={s.item_title}>
                    <FormattedMessage id="parrot_three_range" />
                  </div>
                  <div className={s.item_num}>
                    <FormattedMessage id="parrot_three_num" />
                    <div className={s.unit}>
                      &nbsp;
                      <FormattedMessage id="parrot_three_unit" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.imgBox}>
              <div
                className={s.pic}
              ></div>
            </div>
            <div className={s.memo} id="topnote-1">
              <FormattedMessage
                id="parrot_three_memo"
                values={{ br: <br /> }}
              />
              <sup className={s.footnote}>
                <a href="#footnote-1" aria-label="脚注1">
                  1
                </a>
              </sup>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SFourth = connect(mapStateToProps)(Sfourth)

export default SFourth
