import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './../../header';
import Param from './../Gparam';
import Footer from './../../footer';

import s from './Gspecifications.module.scss'
import common from '../../../../../locale/common'
class Gspecifications extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      position: false
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['goose_' + local] + ' - ' + common['specifications_' + local]
    
    let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US';
        break;
      case 'zh':
        browserLanguage = 'zh-CN';
        break;
      default:
        browserLanguage = 'en-US';
    }
    this.setState({
      language: browserLanguage
    });
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  handleScroll () {
    const textRect = this.refs.Gspecifications.getBoundingClientRect();
    if (textRect.top < -50 && this.refs.Gspecifications) {
      this.setState({
        position: true
      });
    } else {
      this.setState({
        position: false
      });
    }
  }
  //隐藏Goose购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    this.setState({
      hideGooseChoice: isHide
    })
  }
  render () {
    const { hideGooseChoice } = this.state
    const { location } = this.props;
    const path = this.props.match.path;
    return (
      <div className={s.Gspecifications} ref="Gspecifications" onMouseOver={e => { e.persist(); this.hideGooseChoiceFun(true, e)} }>
        <Header location={location} language={this.state.language} path={path} navPosition={this.state.position} hideGooseChoice={hideGooseChoice} hideGooseChoiceFun={isHide => this.hideGooseChoiceFun(isHide)} />
        <Param />
        <Footer location={location} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let GSpecifications = connect(mapStateToProps)(Gspecifications);

export default GSpecifications;
