import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Seleventh.module.scss'

class Seleventh extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        // window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        // window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Seleventh.getBoundingClientRect();
        if (this.refs.Seleventh && clientHeight >= textRect.bottom) {
            setTimeout(() => {
                this.refs.text.style.opacity = 0.9;
                this.refs.text.style.top = '0';
            }, 300);
        }
    }
    render() {
        return (
            <div ref="Seleventh">
                <div className={s.Seleventh}>
                    <div className={s.wrap}>
                        <div className={s.title} ref="text">
                            <FormattedMessage id="mp_package" />
                        </div>
                        <div className={s.img_wrap}>
                            <div className={s.img}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Seleventh;

