import React, { Component } from 'react';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';

import s from './Gsecond.module.scss'

class Gsecond extends React.PureComponent {
    constructor(props) {
        super(props);
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Gsecond.getBoundingClientRect();
        if ((clientHeight - 200) > textRect.top && this.refs.img) {
            setTimeout(() => {
                this.refs.img.style.opacity = 1;
                this.refs.img.style.bottom = '30px';
            }, 500);
        }
    }
    render() {
        const { locale } = this.props
        return (
            <div ref="Gsecond">
                <LazyLoad height={400}>
                    <div className={s.Gsecond}>
                        <div className={s.wrap}>
                            <div className={locale.indexOf('US') > -1 ? s.img_en : (locale.indexOf('TW') > -1 ? s.img_tw : s.img)} ref="img" />
                        </div>
                    </div>
                </LazyLoad>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  const GSecond = connect(mapStateToProps)(Gsecond)

export default GSecond;

