import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import s from './Snineth.module.scss'

class Snineth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Snineth.getBoundingClientRect()
    if (this.refs.Snineth && clientHeight >= textRect.bottom - 200) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    return (
      <div ref="Snineth">
        <div className={s.Snineth}>
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              <div className={s.title}>
                <FormattedMessage
                  id="hodor_eight_title"
                  values={{ br: <br /> }}
                />
              </div>
              <div className={s.desc}>
                <FormattedMessage id="hodor_eight_desc" values={{ br: <br /> }} />
              </div>
              <div className={s.pic}>
                <div className={s.item}>
                <div className={s.img1}></div>
                <div className={s.right}></div>
                </div>
                <div className={s.item}>
                <div className={s.img2}></div>
                <div className={s.right}></div>
                </div>
                <div className={s.item}>
                <div className={s.img3}></div>
                <div className={s.wrong}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SNineth = connect(mapStateToProps)(Snineth)

export default SNineth
