import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './Sfirst.module.scss'

class Sfirst extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { // 初始state
      index: 0,
      opacity: 1,
      isPlay: false
    };
    this._close = this.close.bind(this);
  }
  componentDidMount () {
    if (this.refs.video) {
      this.refs.video.style.height = '100%';
      this.refs.video.addEventListener('ended', this._close);
    }
    if (this.refs.text) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1;
        this.refs.text.style.top = '0';
      }, 500);
    }
  }
  componentWillUnmount () {
  }
  play () {
    const element = this.refs.video;
    element.style.zIndex = 5;
    element.style.display = 'block';
    this.refs.close.style.display = 'block';
    this.refs.img.style.display = 'block';
    // if (!this.state.isPlay) {
    element.play();
    // } else {
    //     element.pause();
    // }
    this.setState({
      isPlay: !this.state.isPlay
    });
  }
  close () {
    this.refs.video.style.display = 'none';
    this.refs.video.style.zIndex = 1;
    this.refs.video.load();
    this.refs.close.style.display = 'none';
    this.refs.img.style.display = 'none';
  }
  render () {
    const { locale } = this.props
    // console.log(this.state.isFull);
    return (
      <div className={ locale.indexOf('US') > -1 ? s.Sfirst_en : (locale.indexOf('TW') > -1 ? s.Sfirst_tw : s.Sfirst)} ref="Sfirst">
        <div className={s.text} ref="text">
          <div className={s.large}><span><FormattedMessage id="s_first_title" /></span></div>
          <div className={s.normal}>
            <FormattedMessage id="s_first_desc_1" />
            <span className={s.line}>·</span>
            <FormattedMessage id="s_first_desc_2" />
            <span className={s.line}>·</span>
            <FormattedMessage id="s_first_desc_3" />
            <span className={s.line}>·</span>
            <FormattedMessage id="s_first_desc_4" />
          </div>
        </div>
        <div className={s.s_play} onClick={() => this.play()} />
        <div className={s.img} ref="img" />
        <video ref="video" className={s.video} src="https://web-site-1252188037.cos.ap-beijing.myqcloud.com/Snow_transform.mp4" />
        <div className={s.closeBox}>
          <div className={s.close} ref="close" onClick={() => this.close()} />
        </div>
        <div className={locale.indexOf('US') > -1 ? s.intoMi_en : s.intoMi} />
        <div className={s.hotdot} />
        <div className={s.IF} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

const SFirst = connect(mapStateToProps)(Sfirst)

export default SFirst;

