import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './Sseventh.module.scss'

class Sseventh extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Sseventh.getBoundingClientRect();
        if (this.refs.Sseventh && clientHeight >= textRect.bottom - 300) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 500);
        }
    }
    render() {
        const { locale } = this.props
        return (
            <div className={locale.indexOf('US') > -1 ? s.Sseventh_en : (locale.indexOf('TW') > -1 ? s.Sseventh_tw : s.Sseventh)} ref="Sseventh">
                <div className={s.wrap}>
                    <div className={s.text} ref="text">
                        <div className={s.title}>
                            <FormattedMessage id="s_seventh_title" />
                        </div>
                        <div className={s.desc}>
                            <FormattedMessage id="s_seventh_desc_1" values={{ spot: <span className={s.line}>·</span> }} />
                        </div>
                        <div className={s.desc_2}>
                            <FormattedMessage id="s_seventh_desc_2" />
                        </div>
                    </div>
                    <div className={s.img} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  const SSeventh = connect(mapStateToProps)(Sseventh)

export default SSeventh;
