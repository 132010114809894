import React, { Component } from 'react'

import s from './airPrivacy.module.scss'

class AirPrivacy extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            // init top hide
            display: false,
        }
    }
    componentDidMount() {
        document.getElementsByTagName('html')[0].style.fontSize = 41.4 + 'px'
    }
    render() {
        return (
            <div className={s.box}>
                <div className={s.Bprivacy}>
                    <p>
                        <b className={s.pt18}>隱私政策</b>
                    </p>
                    <p>
                        <b className={s.pt12}>更新！ </b>
                    </p>
                    <p>
                        我們的隱私政策已於 2021 年 11 月 30 日更新。從該日期開始，這一隱私政策能夠提供有關我們如何管理您在使用所有李吉他溫濕度產品和服務時透露的個人信息的隱私詳情。請花一些時間熟悉我們的隱私權慣例。如果您有任何問題，請告訴我們。
                    </p>
                    <p>我們向您承諾：</p>
                    <p>
                        本隱私政策規定了青萍科技（北京）有限公司及關聯公司（下文簡稱“青萍”或“我們”）如何收集、使用、披露、處理和保護您在使用我們的產品和服務時提供給我們的信息；若我們要求您提供某些信息，以便在使用青萍產品和服務時驗證您的身份，我們將嚴格遵守本隱私政策和/或我們的用戶條款來使用這些信息。 </p>
                    <p>
                        本隱私政策在製定時充分考慮到您的需求；您應全面了解我們的個人資料收集和使用慣例而且確信自己最終能控制提供給青萍的個人信息，這一點至關重要。 </p>
                    <p>
                        在這項隱私政策中，“個人信息”指信息本身或與青萍能夠訪問的個人的其他信息聯繫後能夠識別特定個人的數據。 </p>
                    <p>
                        通過使用李吉他溫濕度產品和服務，即表示您已閱讀、認可並接受本隱私政策中所述之所有條款，包括我們隨時作出的任何變更。我們承諾遵照適用法律（包括您所在地的數據保護法律）來保護您的個人信息的隱私、機密和安全。同時我們承諾我們的全體員工和代理商履行這些義務。
                    </p>
                    <p>
                        最後，我們所希望的就是為我們的用戶帶來最好的體驗。如果您對這一隱私政策中概括的數據處理慣例有任何疑問，請通過 <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> 聯繫我們的數據保護官，以便我們處理您的需求。
                    </p>
                    <p className={s.pt135}>收集哪些信息以及如何使用信息</p>
                    <p className={s.pt12}>收集的信息類別</p>
                    <p>為了向您提供我們的服務，我們會要求您提供向您提供服務所必需的個人信息。如果您不提供個人信息，我們可能無法向您提供我們的產品或服務。我們收集以下各類信息（無論其是否為個人信息）：</p>
                    <ul>
                        <li>
                            <b>您提供給我們或上傳的信息</b>
                            （包括您的聯繫方式）：我們可能收集您提供給我們的任一或所有個人信息，例如手機號碼、IMEI、Android ID、軟件安裝列表、MAC地址、電子郵箱地址、第三方登錄信息、反饋以及您提供給我們的任何其他信息。
                        </li>
                        <li>
                            <b>設備相關信息</b>：與您的設備相關的信息。例如設備的傳感器讀數、設備狀態、配置信息、序列號等。
                        </li>
                        <li>
                            <b>位置信息（僅適用於特定服務/功能）</b>：您的位置的經緯度信息。
                        </li>
                        <li>
                            <b>第三方SDK（小米推送、華為推送、OPPO推送、騰訊Bugly、Facebook SDK、微信SDK等）</b>：以從 app 發送設備的狀態信息。
                        </li>
                        <li>
                            <b>登錄信息</b>：與您使用某些功能、應用和網站相關的信息。例如 cookie 和其他匿名標識符技術、IP 地址、臨時消息歷史和標準系統日誌等。
                        </li>
                        <li>
                            <b>您提供的反饋信息</b>：在您使用用戶反饋功能時，我們可能收集問題日誌、您主動提供的聯繫方式、反饋內容。
                        </li>
                    </ul>
                    <p className={s.pt12}>這些個人信息將會被如何使用</p>
                    <p>
                        收集個人信息的目的在於向您提供產品和/或服務，並且保證我們遵守適用法律。您特此同意我們出於本隱私政策規定的目的處理個人信息，並向我們的關聯公司（涉及通信、社交媒體、技術和雲計算）、第三方服務供應商（定義如下）披露個人信息。
                    </p>
                    <p>我們可能會將您的個人信息用於下列目的：</p>
                    <ul>
                        <li>提供、處理、維護、改善、開發我們的商品和/或提供給您的服務，包括售後服務和客戶支持，以及通過設備或網站提供的服務。 </li>
                        <li>與您就您的設備、服務或其他日常問題（例如軟件更新、客戶諮詢支持、我們活動的相關信息、通知）等進行交流。 </li>
                        <li>分析和開發與我們產品及服務的使用相關的統計信息，以更好地改進我們的產品和服務。 </li>
                        <li>儲存並維護與您相關的信息，用於我們運營業務或履行法律義務。 </li>
                    </ul>
                    <p>
                        關於我們如何使用您的信息（其中可能包含個人信息），下面提供了更多詳細信息：
                    </p>
                    <ul>
                        <li>
                            <b>展示設備信息。 </b>添加設備後，在“李吉他溫濕度” app內顯示相關設備的讀數信息。
                        </li>
                        <li>
                            <b>提供推送服務。 </b>“李吉他溫濕度” app 可能向您發送您的青萍設備的狀態信息。您可隨時通過更改設置來關閉相關功能。
                        </li>
                        <li>
                            <b>收集用戶反饋。 </b>您提供的反饋對幫助我們改進服務而言極為珍貴。為了跟踪您選擇提供的反饋，青萍能會使用您所提供的個人信息與您聯繫，並保留記錄。
                        </li>
                        <li>
                            <b>進行促銷活動。 </b>如果您通過青萍網站或軟件或社交媒體平台參與了抽獎、比賽或類似的促銷，我們可能會使用您提供的個人信息來管理這些項目。
                        </li>
                        <li>
                            <b>直接推廣。 </b>我們可能會使用您的電子郵箱地址、手機號向您提供青萍公司及其合作夥伴的產品和服務相關的推廣資料。我們嚴格遵守您所在地的數據保護法規，要求獨立的明確許可，所以我們只會在徵得您的同意或您表示不反對時使用您的個人信息。您有權終止我們使用計劃用於直接推廣的個人數據。如果您不再希望接收某些類別的電子郵件，您可以通過每封郵件底部的取消訂閱鏈接來取消訂閱。我們不會將您的個人信息傳送給我們的商業夥伴用於直接推廣。
                        </li>
                    </ul>
                    <p className={s.pt12}>
                        Cookie 和其他技術（只在瀏覽 www.qingping.co 時）
                    </p>
                    <ul>
                        <li>
                            <b>收集哪些信息以及如何使用這些信息？ </b>
                            青萍和第三方服務供應商使用 cookie、標籤和腳本等技術。這些技術用於分析趨勢、管理網站、追踪用戶的網站活動並收集關於整個用戶群的人口統計信息。我們會收到這些公司基於使用上述技術的單獨和匯總報告。
                        </li>
                        <li>
                            <b>日誌文件：</b>
                            和大部分網站一樣，我們收集特定信息並保存在日誌文件中。此類信息可能包括互聯網協議 (IP) 地址、瀏覽器類型、互聯網服務供應商 (ISP)、引用/退出頁面、操作系統、日期/時間戳和點擊流數據。我們不會將自動收集的數據鏈接到我們收集的關於您的其他信息之中。
                        </li>
                        <li>
                            <b>廣告：</b>
                            我們與第三方服務供應商合作，在我們的網站上展示廣告或管理我們在其他網站上的廣告。我們的第三方服務供應商可能會使用諸如 cookie 等技術來收集您在其網站或其他網站上的活動信息，以根據您的瀏覽活動和興趣為您提供廣告。
                        </li>
                        <li>
                            <b>移動分析：</b>
                            在某些移動軟件中，我們使用分析技術，以更好地了解我們的軟件的使用情況。此類軟件可能記錄您使用它們的頻率、軟件內發生的事件、累計使用、性能數據及應用程序崩潰發生的位置。
                        </li>
                        <li>
                            <b>本地存儲 - HTML5/Flash：</b>我們使用本地存儲對象 (LSO)，例如 HTML5  或 Flash 存儲內容和偏好。根據您的網頁瀏覽行為，與我們合作在網站上提供某些功能或投放廣告的第三方也會通過 HTML5 或 Flash cookie 來收集和存儲相關信息。各種瀏覽器提供了各自的管理工具來刪除 HTML5 本地存儲對象。
                        </li>
                    </ul>
                    <p className={s.pt135}>我們與誰共享您的信息？ </p>
                    <p>我們不會將任何個人信息出售給第三方。 </p>
                    <p>
                        我們有時可能會向第三方（定義見下文）披露您的個人信息，以便提供您要求的產品或服務。
                    </p>
                    <p>
                        我們可能會向本部分下文列出的第三方服務供應商和關聯公司作出披露。在本部分所述的各種情況下，您可以放心，青萍僅會根據您的授權共享您的個人信息。您應當了解，在下文描述的任何情況下，當青萍與第三方服務供應商共享您的個人信息時，青萍會通過合同規定第三方的實踐和義務，遵守適用的地方數據保護法。青萍會通過合同保證第三方服務供應商遵守您所屬司法管轄區中適用於他們的隱私權標準。
                    </p>
                    <p>
                        <b>與我們集團和第三方服務供應商共享信息</b>
                        為了順利地從事商業經營，為您提供產品和服務的全部功能，我們可能向關聯公司或第三方服務供應商（在線商店、送貨服務供應商、電信公司、數據中心、數據存儲設施、客戶服務供應商、廣告和推廣服務供應商、代表青萍的代理，統稱為“第三方服務供應商”）披露您的個人信息。此類第三方服務供應商可能代表青萍或出於上述的一項或多項目的處理您的個人信息。如果您不再希望允許我們共享這些信息，請發送電子郵件到
                        <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> 聯繫我們。
                    </p>
                    <p>
                        <b>與其他人共享信息：</b>在適用法律要求時，青萍可能會不經過進一步同意而披露您的個人信息。
                    </p>
                    <p className={s.pt135}>不需要同意的信息</p>
                    <ul>
                        <li>
                            我們可能以匯總的形式與第三方（例如我們網站上的廣告商）共享匿名信息，用於商業目的；我們可能與其共享我們服務的一般使用趨勢，例如在購買某些產品或從事某些交易的特定人群中的客戶數量。
                        </li>
                        <li>
                            為避免存疑，青萍可能在地方數據保護法明確允許的情況下和範圍內（例如為了遵循傳票）不經您的同意而收集、使用或披露您的個人信息。我們可能會為了保護您或他人的安全、協助調查欺詐行為，或對政府要求作出回應而必須披露時，不經您的同意而披露您的信息。 </li>
                    </ul>
                    <p className={s.pt135}>安全保障</p>
                    {/*<p className={s.pt12}>青萍的安全措施</p>*/}
                    <p>
                        我們承諾保證您的個人信息安全。為了防止未經授權的訪問、披露或其他類似風險，我們落實了合理的物理、電子和管理措施流程，保護我們從您的產品和青萍網站或軟件內收集的信息。我們將採取所有合理的措施保護您的個人信息。 </p>
                    <p>
                        您的個人信息全都被儲存在安全的服務器上，並在受控設施中受到保護。我們依據重要性和敏感性對您的數據進行分類，並且保證您的個人信息具有最高的安全等級。我們保證通過訪問這些信息來幫助向您提供產品和服務的員工和第三方服務供應商具有嚴格的合同保密義務，如果未能履行這些義務，其將會受到紀律處分或被終止合作。總而言之，我們定期審查信息收集、儲存和處理實踐，包括物理安全措施，以防止任何未經授權的訪問和使用。 </p>
                    <p>
                        我們將採取所有可行的措施保護您的個人信息。但是，您應當意識到互聯網的使用並不總是安全的，因此，我們不能保證在通過互聯網雙向傳輸時任何個人信息的安全性或完整性。
                    </p>
                    <p className={s.pt12}>您能做什麼？ </p>
                    <ul>
                        <li>您可以通過不向任何人（除非此人經您正式授權）披露您的登錄密碼或賬戶信息，為保護您個人信息的安全發揮作用。 </li>
                        <li>
                            青萍不對因您未能保持個人信息的私密性而導致第三方訪問您的個人信息進而造成的安全疏漏承擔責任。儘管有上述規定，如果發生互聯網其他任何用戶未經授權使用您賬戶的情況或其他任何安全漏洞，您必須立即通知我們。
                        </li>
                        <li>您的協助將有助於我們保護您個人信息的私密性。 </li>
                    </ul>
                    <p className={s.pt135}>保留政策</p>
                    <p>
                        如有必要實現收集信息的目的，或者遵守適用法律要求或允許，我們將一直保留個人信息。只要能夠合理推斷保留個人信息不能實現收集個人信息的目的，並且出於法律、解決糾紛、強制實施我們的協議或其他商業目的不再需要保留信息時，我們將不再保留個人信息，或者會消除將個人信息和特定個人關聯起來的途徑。
                    </p>
                    <p className={s.pt135}>訪問您的設備上的其他功能</p>
                    <p>
                        我們的應用程序可能會訪問您設備上的某些功能，例如Wi-Fi
                        網絡狀態，以及其他功能。這些信息用於允許這些應用程序在您的設備上運行，並且允許您與其互動。在任何時候，您可以通過在設備水平上關閉應用程序或者在設備的系統中關閉對應權限來撤銷許可
                    </p>
                    <p className={s.pt135}>您可以控制您的信息！ </p>
                    <p className={s.pt12}>控制設置</p>
                    <p>
                        青萍承認每個人對隱私權的關注各不相同。因此，我們提供了一些示例，說明XX提供的各種方式，供您選擇，以限制收集、使用、披露或處理您的個人信息，並控制您的隱私權設置：
                    </p>
                    <ul>
                        <li>登入或登出青萍賬戶；</li>
                        <li>綁定或解綁設備；</li>
                    </ul>
                    <p>
                        如果您之前因為上述目的同意我們使用您的個人信息，您可以隨時通過書面或者向 <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> 發送郵件的方式聯繫我們來改變您的決定。
                    </p>
                    <p className={s.pt12}>訪問、更新或更正您的個人信息</p>
                    <ul>
                        <li>
                            您有權要求訪問和/或更正我們持有的與您有關的任何個人信息。當您更新個人信息時，在我們處理您的請求前，會要求驗證您的身份。一旦我們獲得充分信息，可處理您的請求以訪問或更正您的個人信息時，我們將在適用數據保護法規定的時間內對您的請求做出回應。
                        </li>
                        <li>
                            我們通常免費提供這些服務，但是保留對您的數據訪問請求收取合理費用的權利。
                        </li>
                        <li>
                            如果您希望請求訪問我們持有的個人數據或者如果您認為我們持有的關於您的任何信息是不正確或不完整的，請盡快致信或者向下方提供的電子郵箱地址發送電子郵件聯繫我們。電子郵箱：
                            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>。
                        </li>
                    </ul>
                    <p className={s.pt12}>撤銷同意</p>
                    <ul>
                        <li>
                            您可以通過提交請求，撤銷同意收集、使用和/或披露我們掌握或控制的您的個人信息，註銷賬號。這可以向 <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> 發送電子郵件給我們。我們將會在您做出請求後的 10 個工作日內處理您的請求，並且會根據您的請求，在此後不再收集、使用和/或披露您的個人信息。
                        </li>
                        <li>
                            請注意，您撤銷同意會導致某些法律後果。根據您撤銷同意讓我們處理您的個人信息的範圍，這可能表示您不能享受青萍的產品和服務。
                        </li>
                    </ul>
                    <p className={s.pt135}>將個人信息轉移到您所屬的司法管轄區之外</p>
                    <p>
                        如果我們需要將個人信息轉移到您所屬的司法管轄區之外，無論是轉移給我們的關聯公司或第三方服務供應商時，我們將按照適用法律進行。特別是，我們通過落實合適的安全保障措施，保證所有這類轉移滿足適用的地方數據保護法的要求。
                    </p>
                    <p className={s.pt135}>其他規定</p>
                    <p className={s.pt12}>未成年人</p>
                    <ul>
                        <li>
                            我們認為監督孩子使用我們的產品和服務是父母的責任。但是，我們的政策不要求獲得未成年人的個人信息，或者向這類人群發送任何促銷資料。
                        </li>
                        <li>
                            青萍不會尋求或試圖尋求接收來自未成年人的任何個人信息。如果家長或監護人有理由相信未成年人未經他們事先同意而向青萍提交了個人信息，請聯繫我們以確保刪除此類個人信息，並保證未成年人取消訂閱任何適用的青萍服務。
                        </li>
                    </ul>
                    <p className={s.pt12}>優先順序</p>
                    <p>
                        如果您同意了適用的用戶協議，並且此類用戶協議和本隱私政策之間存在不一致，將以此類用戶協議為準。
                    </p>
                    <p className={s.pt12}>隱私政策的更新</p>
                    <p>
                        我們會對隱私政策進行定期審核，為反映我們信息慣例的變更，我們可能會更新本隱私政策。如果我們對本隱私政策進行重大變更，我們將通過（向您賬戶指定的郵箱地址發送）電子郵件或在所有青萍網站公佈或通過移動設備通知您，這樣您可以了解我們收集的信息以及我們如何使用這些信息。此類隱私政策變化將從通知或網站規定的生效日期開始適用。我們建議您定期查閱本網頁獲取我們隱私權實踐的最新信息。您繼續使用產品和網站、手機和/或其他任何設備上的服務，將被視為接受更新的隱私政策。在我們向您收集更多的個人信息或我們希望因為新的目的使用或披露您的個人信息時，我們會再次徵得您的同意。
                    </p>
                    <p className={s.pt135}>我是否必須同意任何第三方條款與條件？ </p>
                    <p>
                        我們的隱私政策不適用於第三方提供的產品和服務。青萍產品和服務可能包括第三方的產品和服務，以及第三方網站的鏈接。當您使用這些產品或服務時，也可能收集您的信息。因此，我們強烈建議您花時間閱讀該第三方的隱私政策，就像閱讀我們的政策一樣。我們不對第三方如何使用他們向您收集的個人信息負責，也不能控制其使用。我們的隱私政策不適用通過我們的服務鏈接的其他網站。
                    </p>
                    <br />
                    <p>青萍科技（北京）有限公司</p>
                    <p>地址：北京市朝陽區望京北路 9 號葉青大廈 C 座 309 室</p>
                    <p>郵編：100102</p>
                    <p>
                        電子郵箱：<a href="mailto:privacy@qingping.co">privacy@qingping.co</a>
                    </p>
                    <p>感謝您花時間了解我們的隱私政策！ </p>
                </div>
            </div>
        )
    }
}

export default AirPrivacy
