import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Sfourth.module.scss'

class Sfourth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        // this._handleScroll = this.handleScroll.bind(this);
    }
    // componentDidMount() {
    //     window.addEventListener('scroll', this._handleScroll);
    // }
    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this._handleScroll);
    // }
    // handleScroll() {
    //     const clientHeight = document.documentElement.clientHeight;
    //     const textRect = this.refs.Dfourth.getBoundingClientRect();
    //     if (clientHeight >= textRect.bottom && this.refs.img) {
    //         setTimeout(() => {
    //             this.refs.text.style.opacity = 1;
    //             this.refs.text.style.top = '152px';
    //             this.refs.img.style.opacity = 1;
    //             this.refs.img.style.bottom = '50px';
    //         }, 500);
    //     }
    // }
    render() {
        return (
            <div ref="Sfourth">
                <div className={s.Sfourth}>
                    <div className={s.wrap}>
                        <div className={s.text} ref="text">
                            <div className={s.title}>
                                <FormattedMessage id="s_fourth_title" />
                                <FormattedMessage id="s_fourth_sub_title" />
                            </div>
                            <div className={s.Sfourth_desc}>
                                <FormattedMessage id="s_fourth_desc_1" values={{ br: <br /> }} />
                            </div>
                            <div className={s.Sfourth_desc}>
                                <FormattedMessage id="s_fourth_desc_2" values={{ br: <br /> }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sfourth;

