import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import s from './Pfourth.module.scss'

class Pfourth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
    }
  }
  componentDidMount() {}
  componentWillUnmount() {}

  render() {
    const { locale } = this.props
    return (
      <div className={s.Pfourth} ref="Pfourth">
        <div className={s.box}>
          <div className={s.leftImg}></div>
          <div
            className={s.content}
            style={{
              padding: locale.indexOf('US') > -1 ? '0 80px' : '0 150px',
            }}
          >
            <span>
              <FormattedMessage id="ph_co2_four_desc1" />
            </span>
          </div>
        </div>
        <div className={s.box}>
          <div className={s.rightImg}></div>
          <div
            className={s.content}
            style={{
              padding: locale.indexOf('US') > -1 ? '0 50px' : '0 70px',
            }}
          >
            <span>
              <FormattedMessage id="ph_co2_four_desc2" />
            </span>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PFourth = connect(mapStateToProps)(Pfourth)

export default PFourth
