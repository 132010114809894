import React from 'react';

import s from './Deleventh.module.scss';

const Deleventh = () =>
    <div className={s.Deleventh}>
        <div className={s.desc}>轻松连接 App</div>
        <div className={s.box}>
            <div className={s.item}>
                <i className={s.img_1} />
                <span className={s.text}>
                    打开“米家”app，添加设备，选择“添加蓝
                    牙设备”，或在设备列表中选择“米家蓝牙温
                    湿度计”，进入连接界面。
                </span>
            </div>
            <div className={s.item}>
                <i className={s.img_2} />
                <span className={s.text}>
                    按照连接引导，长按温湿度计背面按钮 2 秒，
                    待屏幕上的蓝牙图标开始闪烁，表示设备进入
                    连接状态。
                </span>
            </div>
        </div>
        <div className={s.text}>* 以上所有测试数据均来自青萍实验室，实际使用过程中根据客观环境的变化可能会有误差。</div>
    </div>;

export default Deleventh;

