import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import s from './style.module.scss'
import Header from './../../header'
import Footer from './../../footer'
import { Link } from 'react-router-dom'
import pic_sleep from './../images/01-icon-sleep.png'
import pic_bell from './../images/01-icon-bell.png'
import pic_humility from './../images/01-icon-humility.png'
import pic_illumination from './../images/01-icon-illumination.png'
import pic_three from './../images/03-pic.png'
import pic_three_tw from './../images/03-pic_tw.png'
import pic_three_en from './../images/03-pic_en.png'
import pic_four from './../images/04-pic.png'
import pic_ten from './../images/10-pic.jpg'
import pic_ten_en from './../images/10-pic_en.png'
import pic_eleven from './../images/11-pic.jpg'
import pic_eleven_en from './../images/11-pic_en.png'
import pic_fourteen_a from './../images/14-pic-a.png'
import pic_fourteen_a_en from './../images/14-pic-a_en.jpg'
import pic_fourteen_b from './../images/14-pic-b.png'
import pic_fourteen_b_en from './../images/14-pic-b_en.jpg'
import pic_fourteen_c from './../images/14-pic-c.png'
import pic_fourteen_c_en from './../images/14-pic-c_en.jpg'
import common from '../../../../../locale/common'
const pages = [
  'firstPage',
  'secondPage',
  'thirdPage',
  'fourthPage',
  'fifthPage',
  'sixthPage',
  'seventhPage',
  'eighthPage',
  'ninethPage',
  'tenthPage',
  'eleventhPage',
  'twelfthPage',
  'thirtythPage',
  'moreInfo',
]

class DoveOverview extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
      sleepVideoStatus: false,
      replayStatus: false,
      position: false,
      hideGooseChoice: true,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['dove_' + local]

    window.addEventListener('scroll', this._handleScroll)

    const video03 = this.refs.video03
    video03.play()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }

  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    // const scrollTop = document.documentElement.scrollTop;
    for (let i = 0; i < pages.length; i++) {
      const page = this.refs[pages[i] + ''].getBoundingClientRect()
      if (page.top < clientHeight - 200) {
        this.setState({ [pages[i]]: true })
      } else {
        this.setState({ [pages[i]]: false })
      }
    }
  }

  playVideo() {
    const video = this.refs.video
    video.style.zIndex = 10
    video.style.display = 'block'
    this.refs.close.style.display = 'block'
    video.play()
  }

  closeVideo() {
    this.refs.video.style.display = 'none'
    this.refs.video.style.zIndex = 1
    this.refs.video.load()
    this.refs.close.style.display = 'none'
  }

  playSleepVideo() {
    const video = this.refs.video02
    const _this = this
    video.style.zIndex = 10
    video.style.display = 'block'
    video.play()
    this.setState({ sleepVideoStatus: true })
    video.addEventListener('pause', function () {
      console.log('11112222=====>')
      _this.setState({ replayStatus: true, sleepVideoStatus: false })
      video.style.display = 'none'
      video.style.zIndex = 1
    })
  }

  //隐藏购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }

  render() {
    const {
      firstPage,
      secondPage,
      thirdPage,
      fourthPage,
      fifthPage,
      sixthPage,
      seventhPage,
      eighthPage,
      ninethPage,
      tenthPage,
      eleventhPage,
      twelfthPage,
      thirtythPage,
      moreInfo,
      hideGooseChoice,
    } = this.state
    const { sleepVideoStatus, replayStatus } = this.state
    const { location, locale } = this.props

    const mp4 =
      locale.indexOf('US') > -1
        ? 'https://qingping.cleargrass.com/static/media/light_en.mp4'
        : locale.indexOf('TW') > -1
        ? 'https://qingping.cleargrass.com/static/media/light_tw.mp4'
        : 'https://qingping.cleargrass.com/static/media/light.mp4'

    return (
      <div
        className={s.doveBox}
        ref="box"
        onMouseOver={(e) => {
          e.persist()
          this.hideGooseChoiceFun(true, e)
        }}
      >
        <Header
          location={location}
          language={this.state.language}
          path={this.state.path}
          navPosition={this.state.position}
          hideGooseChoice={hideGooseChoice}
          hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
        />
        <div className={s.doveOverview}>
          <div
            className={firstPage ? s.firstPage + ' ' + s.active : s.firstPage}
            ref="firstPage"
          >
            <div
              className={
                locale.indexOf('US') > -1 ? s.firstWrap_en : s.firstWrap
              }
            >
              <div className={s.left}></div>
              <div className={[s.right]} ref="text">
                <h3 style={{ fontSize: 45, fontWeight: 'bold' }}>
                  <FormattedMessage id="do_slogan" />
                </h3>
                <h4 style={{ fontWeight: 'normal' }}>
                  <FormattedMessage id="do_desc" values={{ br: '' }} />
                </h4>
                <div className={s.iconList}>
                  <ul>
                    <li id="xxx">
                      <i>
                        <img src={pic_sleep} width="71" height="71" alt="" />
                      </i>
                      <div>
                        <FormattedMessage id="do_snooze" />
                      </div>
                    </li>
                    <li>
                      <i>
                        <img src={pic_bell} width="71" height="71" alt="" />
                      </i>
                      <div>
                        <FormattedMessage id="do_ringtones" />
                      </div>
                    </li>
                    <li>
                      <i>
                        <img src={pic_humility} width="71" height="71" alt="" />
                      </i>
                      <div>
                        <FormattedMessage id="temp_rh_mon" />
                      </div>
                    </li>
                    <li>
                      <i>
                        <img
                          src={pic_illumination}
                          width="71"
                          height="71"
                          alt=""
                        />
                      </i>
                      <div>
                        <FormattedMessage id="do_backlight" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={s.IF} />
              <div className={s.intoMi} />
              {/*<span className={s.play} onClick={() => this.playVideo()}></span>*/}
            </div>
            {/*<div>*/}
            {/*    <video ref="video" className={s.videoBox}*/}
            {/*           src="https://web-site-1252188037.cos.ap-beijing.myqcloud.com/index.mp4" height="670"/>*/}
            {/*    <div className={s.close} ref="close" onClick={() => this.closeVideo()}/>*/}
            {/*</div>*/}
          </div>
          <div
            className={
              secondPage
                ? (locale.indexOf('US') > -1 ? s.secondPage_en : s.secondPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.secondPage_en
                : s.secondPage
            }
            ref="secondPage"
          >
            <div className={s.wrap}>
              <h2>
                <FormattedMessage id="do_simple" />
              </h2>
              <h3>
                <FormattedMessage id="do_desc_2" values={{ br: <br /> }} />
              </h3>
            </div>
          </div>
          <div
            className={thirdPage ? s.thirdPage + ' ' + s.active : s.thirdPage}
            ref="thirdPage"
          >
            <div className={s.wrap}>
              <div className={s.left}>
                <img
                  src={
                    locale.indexOf('US') > -1
                      ? pic_three_en
                      : locale.indexOf('TW') > -1
                      ? pic_three_tw
                      : pic_three
                  }
                  width="276"
                  height="419"
                  alt=""
                />
              </div>
              <div className={locale.indexOf('US') > -1 ? s.right_en : s.right}>
                <h2>
                  <FormattedMessage id="do_customize" />
                </h2>
                <h3>
                  <FormattedMessage id="do_desc_3" />
                </h3>
                <h4>
                  <FormattedMessage id="do_sync_time_1" />
                  <Link target="_blank" className={s.dove_to_plus} to="/plus">
                    <FormattedMessage id="cg_app" />
                  </Link>
                  &nbsp;
                  <FormattedMessage id="do_sync_time_2" />
                </h4>
                <h4>
                  <FormattedMessage id="do_shot_off" />
                </h4>
              </div>
            </div>
          </div>
          <div
            className={
              fourthPage ? s.fourthPage + ' ' + s.active : s.fourthPage
            }
            ref="fourthPage"
          >
            <div className={s.wrap}>
              <h2>
                <FormattedMessage id="do_joy" values={{ br: '' }} />
              </h2>
              <h3>
                <FormattedMessage id="do_desc_4" values={{ br: <br /> }} />
              </h3>
              <div className={s.video}>
                <div
                  className={
                    replayStatus || !sleepVideoStatus
                      ? s.subTitle
                      : s.subTitle + ' ' + s.hide
                  }
                >
                  <FormattedMessage id="do_one_more" />
                  <br />
                  <FormattedMessage id="do_two_step" />
                </div>
                <span
                  className={
                    !sleepVideoStatus ? s.play : s.play + ' ' + s.pause
                  }
                  onClick={() => this.playSleepVideo()}
                ></span>
                <span
                  className={
                    replayStatus ? s.replay : s.replay + ' ' + s.replayHide
                  }
                  onClick={() => this.playSleepVideo()}
                ></span>
                <img src={pic_four} width="800" height="450" alt="" />
                <video
                  ref="video02"
                  className={s.videoBox02}
                  src="https://web-site-1252188037.cos.ap-beijing.myqcloud.com/press.mp4"
                  width="800"
                  height="450"
                />
              </div>
            </div>
          </div>
          <div
            className={
              fifthPage
                ? (locale.indexOf('US') > -1 ? s.fifthPage_en : s.fifthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.fifthPage_en
                : s.fifthPage
            }
            ref="fifthPage"
          >
            <div className={s.wrap}>
              <h2>
                {/* <FormattedMessage id="do_monitor" /> */}
                {/* <br /> */}
                <FormattedMessage id="do_guard" />
              </h2>
              <h3>
                <FormattedMessage id="do_sensor_care" />
              </h3>
            </div>
          </div>
          <div
            className={
              sixthPage
                ? (locale.indexOf('US') > -1 ? s.sixthPage_en : s.sixthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.sixthPage_en
                : s.sixthPage
            }
            ref="sixthPage"
          >
            <div className={s.wrap}>
              <h2>
                <FormattedMessage id="do_pat" values={{ br: '' }} />
              </h2>
              <h3>
                <FormattedMessage id="do_press" />
              </h3>
            </div>
          </div>

          <div
            className={
              seventhPage ? s.seventhPage + ' ' + s.active : s.seventhPage
            }
            ref="seventhPage"
          >
            {/*<div className={s.phone + ' ' + s[this.state.bgClass]}></div>*/}
            <div className={s.wrap}>
              <h2>
                <FormattedMessage id="do_adjust" />
              </h2>
              <h3>
                <FormattedMessage id="do_desc_5" values={{ br: <br /> }} />
              </h3>
              <h4>
                <FormattedMessage id="do_available" />
                <Link target="_blank" className={s.dove_to_plus} to="/plus">
                  <FormattedMessage id="cg_app" />
                </Link>
                &nbsp;
                <FormattedMessage id="do_available_2" />
              </h4>
              <div className={s.videoBox03}>
                <video
                  ref="video03"
                  preload="true"
                  loop="loop"
                  muted="muted"
                  src={mp4}
                  width="800"
                  height="450"
                />
              </div>
            </div>
          </div>
          <div
            className={
              eighthPage ? s.eighthPage + ' ' + s.active : s.eighthPage
            }
            ref="eighthPage"
          >
            <div className={s.wrap}>
              <h2>
                <FormattedMessage id="do_smart" />
              </h2>
              <h3>
                <FormattedMessage id="do_desc_6" />
              </h3>
              <h4>
                <FormattedMessage id="do_desc_7" />
                <a
                  className={s.dove_to_plus}
                  rel="noopener noreferrer"
                  href="https://home.mi.com/baike/index.html#/label/ability/688967186000000001"
                  target="_blank"
                >
                  <FormattedMessage id="do_desc_7_2" />
                </a>
                <FormattedMessage id="do_desc_7_3" />
                <Link
                  target="_blank"
                  className={s.dove_to_plus}
                  to="/bluetooth-gateway/overview"
                >
                  <FormattedMessage id="do_desc_7_4" />
                </Link>
                <FormattedMessage id="do_desc_7_5" />
              </h4>
            </div>
          </div>
          <div
            className={
              ninethPage
                ? (locale.indexOf('US') > -1 ? s.ninethPage_en : s.ninethPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.ninethPage_en
                : s.ninethPage
            }
            ref="ninethPage"
          >
            <div className={s.wrap}>
              <h2>
                <FormattedMessage id="do_concentrated" />
              </h2>
              <h3>
                <FormattedMessage id="do_desc_8" values={{ br: <br /> }} />
              </h3>
            </div>
          </div>
          <div
            className={tenthPage ? s.tenthPage + ' ' + s.active : s.tenthPage}
            ref="tenthPage"
          >
            <div className={s.wrap}>
              <div className={s.left}>
                <img
                  src={locale.indexOf('US') > -1 ? pic_ten_en : pic_ten}
                  width="625"
                  height="550"
                  alt=""
                />
              </div>
              <div className={s.right}>
                <h2>
                  <FormattedMessage id="do_routine" values={{ br: <br /> }} />
                </h2>
                <h4>
                  <FormattedMessage id="do_desc_9" />
                  <Link target="_blank" className={s.dove_to_plus} to="/plus">
                    <FormattedMessage id="cg_app" />
                  </Link>
                  &nbsp;
                  <FormattedMessage id="do_desc_9_2" />
                </h4>
                <h4>
                  <FormattedMessage id="do_desc_10" />
                </h4>
              </div>
            </div>
          </div>
          <div
            className={
              eleventhPage ? s.eleventhPage + ' ' + s.active : s.eleventhPage
            }
            ref="eleventhPage"
          >
            <div className={s.wrap}>
              <div className={s.left}>
                <h2>
                  <FormattedMessage id="do_kids" />
                </h2>
                <h3>
                  <FormattedMessage id="do_habit" />
                </h3>
              </div>
              <div className={s.right}>
                <img
                  src={locale.indexOf('US') > -1 ? pic_eleven_en : pic_eleven}
                  width="625"
                  height="550"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            className={
              twelfthPage
                ? (locale.indexOf('US') > -1
                    ? s.twelfthPage_en
                    : s.twelfthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.twelfthPage_en
                : s.twelfthPage
            }
            ref="twelfthPage"
          >
            <div className={s.wrap}>
              <div>
                <h2>
                  <FormattedMessage id="do_silence" />
                </h2>
                <h3>
                  <FormattedMessage id="do_sleep" />
                </h3>
              </div>
            </div>
          </div>
          <div
            className={
              thirtythPage ? s.thirtythPage + ' ' + s.active : s.thirtythPage
            }
            ref="thirtythPage"
          >
            <div className={s.wrap}>
              <div className={locale.indexOf('US') > -1 ? s.pic_en : s.pic}>
                <h2 style={{ color: '#fff' }}>
                  <FormattedMessage id="do_colorful" />
                </h2>
                <div className={s.textList}>
                  <ul>
                    <li>
                      <FormattedMessage id="do_white" />
                    </li>
                    <li>
                      <FormattedMessage id="do_green" />
                    </li>
                    <li>
                      <FormattedMessage id="do_blue" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className={moreInfo ? s.moreInfo + ' ' + s.active : s.moreInfo}
            ref="moreInfo"
          >
            <div className={s.wrap}>
              <div className={s.left}>
                <div className={s.card}>
                  <img
                    src={
                      locale.indexOf('US') > -1
                        ? pic_fourteen_a_en
                        : pic_fourteen_a
                    }
                    alt=""
                    width="403"
                    height="332"
                  />
                  <div className={s.text01}>
                    <FormattedMessage
                      id="do_lcd_superview"
                      values={{ br: <br /> }}
                    />
                  </div>
                </div>
              </div>
              <div className={s.middle}>
                <h2>
                  <FormattedMessage id="do_more" />
                </h2>
                <div className={s.card} style={{ height: '501px' }}>
                  <div className={s.text02}>
                    <FormattedMessage id="do_plastic" values={{ br: <br /> }} />
                  </div>
                  <img
                    src={
                      locale.indexOf('US') > -1
                        ? pic_fourteen_b_en
                        : pic_fourteen_b
                    }
                    alt=""
                    width="403"
                    height="auto"
                  />
                </div>
              </div>
              <div className={s.right}>
                <div className={s.card}>
                  <img
                    src={
                      locale.indexOf('US') > -1
                        ? pic_fourteen_c_en
                        : pic_fourteen_c
                    }
                    alt=""
                    width="403"
                    height="332"
                  />
                  <div className={s.text03}>
                    <FormattedMessage id="do_tpe" values={{ br: <br /> }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer location={location} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const DoveOverView = connect(mapStateToProps)(DoveOverview)

export default DoveOverView
