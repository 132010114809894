import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import s from './Pfirst.module.scss'

class Pfirst extends React.PureComponent {
  render() {
    const { locale } = this.props
    const column0 = locale.indexOf('US') > -1 ? s.column0_en : s.column0
    const column1 = locale.indexOf('US') > -1 ? s.column1_en : s.column1
    const column2 = locale.indexOf('US') > -1 ? s.column2_en : s.column2
    return (
      <div className={locale.indexOf('US') > -1 ? s.Pfirst_en : s.Pfirst}>
        <div className={s.left}>
          <div className={s.leftImg}></div>
        </div>
        <div className={s.text}>
          {locale.indexOf('US') > -1 ? (
            <ul className={s.product_en}>
              <li>
                <FormattedMessage id="ph_first_text1" values={{ br: '' }} />
              </li>
              <li>
                <FormattedMessage id="ph_first_text2" values={{ br: '' }} />
              </li>
            </ul>
          ) : (
            <div className={s.product}>
              <FormattedMessage id="ph_first_text1" values={{ br: '' }} />
              &nbsp;·&nbsp;
              <FormattedMessage id="ph_first_text2" values={{ br: '' }} />
            </div>
          )}
          <div className={s.multiple}>
            <FormattedMessage id="ph_first_text3" />
          </div>
          <div className={s.every}>
            <FormattedMessage id="ph_first_text4" />
          </div>
          <div className={s.table}>
            <div className={s.table_row}>
              <div style={{ opacity: 0 }} className={column0}>
                <FormattedMessage id="ph_first_text5" />
              </div>
              <div className={column1} style={{ opacity: 1, fontWeight: 'bold' }}>
                <FormattedMessage id="ph_first_text1" values={{ br: '' }} />
              </div>
              <div className={column2} style={{ opacity: 1, fontWeight: 'bold' }}>
                <FormattedMessage id="ph_first_text2" values={{ br: '' }} />
              </div>
            </div>
            <div className={s.table_row}>
              <div className={column0} style={{ opacity: 1, fontWeight: 'bold' }}>
                <FormattedMessage id="ph_first_text5" />
              </div>
              <div className={column1}>
                <FormattedMessage id="ph_first_text8" />
              </div>
              <div className={column2}>
                <FormattedMessage id="ph_first_text9" />
              </div>
            </div>
            <div className={s.table_row}>
              <div className={column0} style={{ opacity: 1, fontWeight: 'bold' }}>
                <FormattedMessage id="ph_first_text6" />
              </div>
              <div className={column1}>
                <FormattedMessage id="ph_first_text10" />
              </div>
              <div className={column2}>
                <FormattedMessage id="ph_first_text11" />
              </div>
            </div>
            <div className={s.table_row}>
              <div className={column0} style={{ opacity: 1, fontWeight: 'bold' }}>
                <FormattedMessage id="ph_first_text7" />
              </div>
              <div className={column1}>
                <FormattedMessage id="ph_first_text12" />
              </div>
              <div className={column2}>
                <FormattedMessage id="ph_first_text13" />
              </div>
            </div>
          </div>
          <Link to="/temp-rh-monitor-pro-s/specifications">
            <div className={s.spec}>
              <FormattedMessage id="ph_first_text14" />
              <div className={s.triangle}></div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PFirst = connect(mapStateToProps)(Pfirst)

export default PFirst
