import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../../header'
import Footer from '../../footer'
import Pfirst from '../Pfirst'
import Psecond from '../Psecond'
import Pthird from '../Pthird'
import Pfourth from '../Pfourth'
import Psixth from '../Psixth'
import Pseventh from '../Pseventh'
import Peighth from '../Peighth'
import Pnineth from '../Pnineth'
import Peleven from '../Peleven'
import Ptwelve from '../Ptwelve'
import Pthirteen from '../Pthirteen'
import Pfourteen from '../Pfourteen'
import Ptimecalculator from '../Ptimecalculator'

import s from './Pstyle.module.scss'
import common from '../../../../../locale/common'
class Pstyle extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      language: null,
      position: false,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['frog_' + local]

    let browserLanguage = navigator.browserLanguage
      ? navigator.browserLanguage
      : navigator.language
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US'
        break
      case 'zh':
        browserLanguage = 'zh-CN'
        break
      default:
        browserLanguage = 'en-US'
    }
    this.setState({
      language: browserLanguage,
    })
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const textRect = this.refs.box.getBoundingClientRect()
    if (textRect.top < -50 && this.refs.box) {
      if(this.state.position) return
      this.setState({
        position: true,
      })
    } else {
      if(!this.state.position) return
      this.setState({
        position: false,
      })
    }
  }

  render() {
    const { location, locale } = this.props
    const path = this.props.match.path
    return (
      <div className={s.box} ref="box">
        <div className={s.Pstyle}>
          <Header
            location={location}
            language={this.state.language}
            path={path}
            navPosition={this.state.position}
          />
          <Pfirst />
          <Peleven />
          <Ptwelve />
          <Pthirteen />
          <Ptimecalculator locale={locale} />
          <Psecond />
          <Pfourteen />
          <Pfourth />
          <Psixth />
          <Peighth />
          <Pthird />
          <Pseventh />
          <Pnineth />
          <Footer location={location} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PStyle = connect(mapStateToProps)(Pstyle)

export default PStyle
