import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import LazyLoad from 'react-lazyload'

import s from './Design.module.scss'

let picNumber = 4
class Design extends React.PureComponent {
  toTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  constructor(props) {
    super(props)
    this.state = {
      num: 2, // 自动播放默认第二个切换
      arr: [
        // init spot
        <li
          className={s.cur}
          onClick={() => this.handleTab(1, true)}
          key="1"
        />,
        <li
          className={s.spot}
          onClick={() => this.handleTab(2, true)}
          key="2"
        />,
        <li
          className={s.spot}
          onClick={() => this.handleTab(3, true)}
          key="3"
        />,
        <li
          className={s.spot}
          onClick={() => this.handleTab(4, true)}
          key="4"
        />,
      ],
      tab: null,
    }
    this._handleResize = this.handleResize.bind(this)
  }
  componentDidMount() {
    // 添加文字显示动画，可添加任何样式 如：opacity, left
    if (this.refs.design) {
      const clientHeight = document.documentElement.clientHeight
      this.refs.design.style.height = `${clientHeight - 50}px`
    }
    this.setIntervalTab()
    window.addEventListener('resize', this._handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize)
  }
  componentWillUnmount(){
    clearInterval(this.state.tab)
  }
  // 绑定定时器，10秒播放一次
  setIntervalTab() {
    const tab = setInterval(() => {
      this.handleTab(this.state.num)
    }, 10000)
    this.setState({
      tab: tab,
    })
  }
  // 绑定切换，支持自动播放及点击切换 clear：ture为点击后
  handleTab(n, clear) {
    let Bwidth = null
    if (this.refs.img) {
      Bwidth = this.refs.img.offsetWidth
    }
    let num = n
    const temp = []
    // 更新按钮，i减1为按钮个数
    for (let i = 0; i <= picNumber - 1; i++) {
      if (i === num - 1) {
        temp.push(
          <li
            className={s.cur}
            onClick={() => this.handleTab(i + 1, true)}
            key={i + 1}
          />
        )
      } else {
        temp.push(
          <li
            className={s.spot}
            onClick={() => this.handleTab(i + 1, true)}
            key={i + 1}
          />
        )
      }
    }
    if (this.refs.bannerItem) {
      this.refs.bannerItem.style.left = `${-(num - 1) * Bwidth}px`
    }
    // picNumber为图片个数，自动循环播放
    if (num === picNumber) {
      num = 1
    } else {
      num++
    }
    this.setState({
      arr: temp,
      num: num,
    })
    // 点击后重新切换计时 clear：ture为点击后
    if (clear) {
      clearInterval(this.state.tab)
      this.setIntervalTab()
    }
  }
  handleResize() {
    if (this.state.num === 1) {
      const bannerItem = this.refs.bannerItem
      const Bwidth = this.refs.img.offsetWidth
      bannerItem.style.left = `${-this.state.num * Bwidth}px`
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div className={s.design} ref="design">
        <div className={s.img} ref="img">
          <ul ref="bannerItem">
          <LazyLoad height={0}>
              <li
                className={
                  locale.indexOf('US') > -1
                    ? s.two_img_en
                    : locale.indexOf('TW') > -1
                    ? s.two_img_tw
                    : s.two_img
                }
              >
                <Link to="/air-monitor-2/overview" onClick={() => this.toTop()}>
                  <div className={s.text_snow}>
                    <span className={s.small}>
                      <FormattedMessage id="snow2" />
                    </span>
                    <span className={s.large}>
                      <FormattedMessage id="s_first_title" />
                    </span>
                    <span
                      to="/air-monitor-2/overview"
                      onClick={() => this.toTop()}
                      className={s.more}
                    >
                      <FormattedMessage id="more" />
                    </span>
                    {/* <div className={s.hotdot} />
                    <div className={s.IF} /> */}
                  </div>
                </Link>
              </li>
            </LazyLoad>
            <LazyLoad height={0}>
              <li className={s.third_img}>
                <Link
                  to="/temp-rh-monitor/overview"
                  onClick={() => this.toTop()}
                >
                  <div className={s.goose_text}>
                    <span className={s.small}>
                      <FormattedMessage id="goose_lite" />
                    </span>
                    <span className={s.large} style={{ marginBottom: 5 }}>
                      <span className={s.version}>
                        <FormattedMessage id="t_version" />
                      </span>
                      &nbsp;/&nbsp;
                      <span className={s.version}>
                        <FormattedMessage id="h_version" />
                      </span>
                      <span className={s.homekit}></span>
                    </span>
                    <span className={s.large}>
                      <span className={s.version}>
                        <FormattedMessage id="m_version" />
                      </span>
                      <span
                        className={locale.indexOf('US') > -1 ? s.mi_en : s.mi}
                      ></span>
                    </span>
                    <span
                      to="/temp-rh-monitor/overview"
                      onClick={() => this.toTop()}
                      className={s.goose_more}
                    >
                      <FormattedMessage id="more" />
                    </span>
                    {/* <div className={s.logo}></div> */}
                  </div>
                </Link>
              </li>
            </LazyLoad>
            <LazyLoad height={0}>
              <li className={s.one_img}>
                <Link
                  to="/co2-temp-rh-monitor/overview"
                  onClick={() => this.toTop()}
                >
                  <div className={s.pheasant_text}>
                    <span className={s.small}>
                      <FormattedMessage id="pheasant_co2_name" />
                    </span>
                    <span className={s.large} style={{ lineHeight: 1.3 }}>
                      <FormattedMessage
                        id="pheasant_co2_index_desc"
                        values={{ br: <br /> }}
                      />
                    </span>
                    <span
                      to="/co2-temp-rh-monitor/overview"
                      onClick={() => this.toTop()}
                      className={s.more}
                    >
                      <FormattedMessage id="more" />
                    </span>
                  </div>
                </Link>
              </li>
            </LazyLoad>
            <LazyLoad height={0}>
              <li className={s.four_img}>
                <Link
                  to="/lee-guitars-thermo-hygrometer/overview"
                  onClick={() => this.toTop()}
                >
                  <div className={s.text_magpie}>
                    <span className={s.small}>
                      <span>
                        <FormattedMessage id="magpie" values={{ br: '' }} />
                      </span>
                    </span>
                    <span className={s.large} style={{ lineHeight: 1.3 }}>
                      <span>
                        <FormattedMessage id="grasp_guitar" />
                        {/* <FormattedMessage id="cute_smart" /> */}
                      </span>
                    </span>
                    <span
                      to="/lee-guitars-thermo-hygrometerr/overview"
                      onClick={() => this.toTop()}
                      className={s.more}
                    >
                      <FormattedMessage id="more" />
                    </span>
                  </div>
                </Link>
              </li>
            </LazyLoad>
          </ul>
        </div>
        <div className={s.btn}>
          <ul className={s.li_wrap}>{this.state.arr}</ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const design = connect(mapStateToProps)(Design)

export default design
