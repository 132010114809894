import React, { Component } from 'react';
// import { FormattedMessage } from 'react-intl';

import s from './preset_apps.module.scss';
class presetApps extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            language: null,
            position: false
        };
    }
    componentDidMount() {
        document.title = '预置应用';
    }
    render() {
        return (
            <div className={s.box} ref="box">
                <div className={s.warp}>
                    <div className={s.home_title}>预置应用</div>
                    <table cellPadding="0" cellSpacing="0">
                        <tr>
                            <th width="50">序号</th>
                            <th width="70">应用</th>
                            <th width="70">功能</th>
                            <th width="60">开发者</th>
                            <th width="100">权限列表</th>
                            <th width="100">能否卸载</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>翻译机</td>
                            <td>提供拾音、翻译功能</td>
                            <td>青萍</td>
                            <td>录音、存储、网路通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>米家翻译机</td>
                            <td>提供翻译功能</td>
                            <td>青萍</td>
                            <td>录音、存储、网路通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>对话翻译</td>
                            <td>提供对话翻译功能</td>
                            <td>青萍</td>
                            <td>录音、存储、网路通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>媒体存储设备</td>
                            <td>媒体存储</td>
                            <td>MTK</td>
                            <td>读取已安装应用列表、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>短信</td>
                            <td>短信</td>
                            <td>MTK</td>
                            <td>读取已安装应用列表、短信、网络通信、电话、通讯录</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>相机</td>
                            <td>用于拍照翻译</td>
                            <td>MTK</td>
                            <td>相机、录音、存储、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>电话和短信存储</td>
                            <td>电话和短信存储</td>
                            <td>谷歌</td>
                            <td>信息、通讯录、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>用户字典</td>
                            <td>用户字典</td>
                            <td>谷歌</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>系统界面</td>
                            <td>系统界面</td>
                            <td>MTK</td>
                            <td>位置信息、存储、电话、相机、通讯录</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>系统更新</td>
                            <td>用于检测新版本以及下载、安装更新</td>
                            <td>广升</td>
                            <td>网络通信、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>软件包安装程序</td>
                            <td>软件包安装程序</td>
                            <td>MTK</td>
                            <td>读取已安装应用列表、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>软件包权限帮助程序</td>
                            <td>软件包权限帮助程序</td>
                            <td>谷歌</td>
                            <td>读取已安装应用列表</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>输入设备</td>
                            <td>输入设备</td>
                            <td>谷歌</td>
                            <td>读取已安装应用列表</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>14</td>
                            <td>谷歌拼音输入法</td>
                            <td>提供键盘输入功能</td>
                            <td>谷歌</td>
                            <td>通讯录</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td>无卡上网</td>
                            <td>提供连网功能</td>
                            <td>小米</td>
                            <td>位置信息、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>16</td>
                            <td>证书安装程序</td>
                            <td>证书安装</td>
                            <td>谷歌</td>
                            <td>读取已安装应用列表、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>17</td>
                            <td>设置存储</td>
                            <td>设置存储</td>
                            <td>MTK</td>
                            <td>存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>18</td>
                            <td>设置向导</td>
                            <td>开机引导功能</td>
                            <td>麦穗</td>
                            <td>位置信息、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>19</td>
                            <td>设置</td>
                            <td>设置</td>
                            <td>MTK</td>
                            <td>读取已安装应用列表、位置信息、通话、录音、通讯录、存储、账号、蓝牙</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>20</td>
                            <td>蓝牙除错工具</td>
                            <td>蓝牙除错工具</td>
                            <td>MTK</td>
                            <td>蓝牙、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>21</td>
                            <td>蓝牙</td>
                            <td>蓝牙功能</td>
                            <td>MTK</td>
                            <td>读取已安装应用列表、位置信息、电话、通讯录、录音、信息、存储、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>22</td>
                            <td>权限监控</td>
                            <td>权限监控</td>
                            <td>MTK</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>23</td>
                            <td>工厂模式</td>
                            <td>工厂测试程序</td>
                            <td>麦穗</td>
                            <td>位置信息、录音、拍照、存储、蓝牙、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>24</td>
                            <td>工作资料设置</td>
                            <td>工作资料设置</td>
                            <td>谷歌</td>
                            <td>存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>25</td>
                            <td>密钥链</td>
                            <td>密钥链</td>
                            <td>谷歌</td>
                            <td>读取已安装应用列表</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>26</td>
                            <td>存储空间管理器</td>
                            <td>存储空间管理功能</td>
                            <td>谷歌</td>
                            <td>存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>27</td>
                            <td>外部存储设备</td>
                            <td>外部存储功能</td>
                            <td>谷歌</td>
                            <td>存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>28</td>
                            <td>压力测试</td>
                            <td>工厂测试功能</td>
                            <td>麦穗</td>
                            <td>录音、拍照、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>29</td>
                            <td>主屏幕提示</td>
                            <td>主屏提示功能</td>
                            <td>谷歌</td>
                            <td>存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>30</td>
                            <td>下载管理程序</td>
                            <td>下载管理</td>
                            <td>MTK</td>
                            <td>存储、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>31</td>
                            <td>下载</td>
                            <td>下载</td>
                            <td>MTK</td>
                            <td>存储、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>32</td>
                            <td>一体化位置信息</td>
                            <td>系统服务</td>
                            <td>谷歌</td>
                            <td>位置信息</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>33</td>
                            <td>WAPI证书</td>
                            <td>WAPI证书</td>
                            <td>MTK</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>34</td>
                            <td>Babel音频采集服务程序</td>
                            <td>用于音频采集</td>
                            <td>青萍</td>
                            <td>录音、存储、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>35</td>
                            <td>DRM 保护内容存储</td>
                            <td>DRM内容访问</td>
                            <td>MTK</td>
                            <td>存储、网络通信、电话、短信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>36</td>
                            <td>HTML 查看程序</td>
                            <td>文本、HTML查看</td>
                            <td>谷歌</td>
                            <td>存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>37</td>
                            <td>MTP 主机</td>
                            <td>MTP服务</td>
                            <td>谷歌</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>38</td>
                            <td>Android Services Library</td>
                            <td>Android Services Library</td>
                            <td>谷歌</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>39</td>
                            <td>Android Shared Library</td>
                            <td>Android Shared Library</td>
                            <td>谷歌</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>40</td>
                            <td>Android System WebView</td>
                            <td>WebView</td>
                            <td>谷歌</td>
                            <td>网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>41</td>
                            <td>android.auto_generated_rro__</td>
                            <td>资源包</td>
                            <td>MTK</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>42</td>
                            <td>Bluetooth MIDI Service</td>
                            <td>蓝牙音乐服务</td>
                            <td>谷歌</td>
                            <td>蓝牙</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>43</td>
                            <td>CaptivePortalLogin</td>
                            <td>网络认证</td>
                            <td>谷歌</td>
                            <td>网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>44</td>
                            <td>com.android.backupconfirm</td>
                            <td>备份个恢复功能</td>
                            <td>谷歌</td>
                            <td>读取已安装应用列表、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>45</td>
                            <td>com.android.carrierconfig</td>
                            <td>运营商设置</td>
                            <td>MTK</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>46</td>
                            <td>com.android.cts.ctsshim</td>
                            <td>CTS</td>
                            <td>谷歌</td>
                            <td>读取已安装应用列表</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>47</td>
                            <td>com.android.cts.priv.ctsshim</td>
                            <td>CTS</td>
                            <td>谷歌</td>
                            <td>读取已安装应用列表</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>48</td>
                            <td>com.android.documentsui.auto_generate d_rro__</td>
                            <td>documentsui</td>
                            <td>谷歌</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>49</td>
                            <td>com.android.provision</td>
                            <td>系统引导程序</td>
                            <td>谷歌</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>50</td>
                            <td>com.android.sharedstoragebackup</td>
                            <td>数据备份</td>
                            <td>谷歌</td>
                            <td>读取已安装应用列表、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>51</td>
                            <td>com.android.smspush</td>
                            <td>SMS PUSH</td>
                            <td>MTK</td>
                            <td>短信、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>52</td>
                            <td>com.android.systemui.auto_generated_r ro__</td>
                            <td>SystemUI</td>
                            <td>MTK</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>53</td>
                            <td>com.cleargrass.app.babel.tools</td>
                            <td>提供工具型功能</td>
                            <td>青萍</td>
                            <td>存储、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>54</td>
                            <td>com.mediatek</td>
                            <td>资源包</td>
                            <td>MTK</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>55</td>
                            <td>com.mediatek.atci.service</td>
                            <td>流媒体服务</td>
                            <td>MTK</td>
                            <td>位置信息、蓝牙、电话</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>56</td>
                            <td>com.mediatek.batterywarning</td>
                            <td>电池警告</td>
                            <td>MTK</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>57</td>
                            <td>com.mediatek.ims</td>
                            <td>IMS服务</td>
                            <td>MTK</td>
                            <td>位置信息</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>58</td>
                            <td>Common Data Service</td>
                            <td>Data Service</td>
                            <td>MTK</td>
                            <td>位置信息、存储、电话</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>59</td>
                            <td>Companion Device Manager</td>
                            <td>Device Manager</td>
                            <td>谷歌</td>
                            <td>位置信息、蓝牙</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>60</td>
                            <td>EngineerMode</td>
                            <td>工程模式</td>
                            <td>MTK</td>
                            <td>位置信息、存储、电话、相机、通讯录、网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>61</td>
                            <td>FotaProvider</td>
                            <td>OTA功能</td>
                            <td>广升</td>
                            <td>网络通信、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>62</td>
                            <td>GBA Service</td>
                            <td>GBA服务</td>
                            <td>MTK</td>
                            <td>网络通信、电话、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>63</td>
                            <td>Intent Filter Verification Service</td>
                            <td>Intent Filter Verification Service</td>
                            <td>谷歌</td>
                            <td>网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>64</td>
                            <td>MDMConfig</td>
                            <td>Modem Config</td>
                            <td>MTK</td>
                            <td>无</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>65</td>
                            <td>MDMLSample</td>
                            <td>服务组件</td>
                            <td>MTK</td>
                            <td>存储空间</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>66</td>
                            <td>MmsService</td>
                            <td>短信服务</td>
                            <td>MTK</td>
                            <td>短信、网络通信、电话、通讯录</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>67</td>
                            <td>Mtk Nlp</td>
                            <td>网络定位服务</td>
                            <td>MTK</td>
                            <td>位置信息</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>68</td>
                            <td>MMTK Thermal Manager</td>
                            <td>温度管理</td>
                            <td>MTK</td>
                            <td>存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>69</td>
                            <td>MTKLogger</td>
                            <td>LOG日志记录</td>
                            <td>MTK</td>
                            <td>存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>70</td>
                            <td>MTKLoggerProxy</td>
                            <td>LOG代理</td>
                            <td>MTK</td>
                            <td>网络通信、存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>71</td>
                            <td>Omacp</td>
                            <td>OMACP功能</td>
                            <td>MTK</td>
                            <td>电话、短信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>72</td>
                            <td>PacProcessor</td>
                            <td>代理服务</td>
                            <td>谷歌</td>
                            <td>网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>73</td>
                            <td>Pico TTS</td>
                            <td>TTS服务</td>
                            <td>谷歌</td>
                            <td>存储</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>74</td>
                            <td>ProxyHandler</td>
                            <td>数据代理服务</td>
                            <td>谷歌</td>
                            <td>网络通信</td>
                            <td>否</td>
                        </tr>
                        <tr>
                            <td>75</td>
                            <td>Shell</td>
                            <td>提供adb shell的手机侧功能</td>
                            <td>谷歌</td>
                            <td>读取已安装应用列表、位置信息、通话、信息、通讯录、存储、账号、蓝牙</td>
                            <td>否</td>
                        </tr>
                    </table>
                </div>
                <div className={s.foot} id="foot">
                    <a href="https://www.mi.com/">小米米家翻译机</a>
                </div>
            </div>
        );
    }
}

export default presetApps;

