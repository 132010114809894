import React, { Component } from 'react'
// import { FormattedMessage } from 'react-intl';
// import { Link } from 'react-router';
import { connect } from 'react-redux'
import Header from './../header'
import Footer from './../footer'
import s from './clock.module.scss'
import common from '../../../../locale/common'
import { FormattedMessage } from 'react-intl'
class Clock extends React.PureComponent {
  static handleClick() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.setState(
      {
        // 修改state
        display: !this.state.display,
      },
      () => this.setOverflow()
    )
  }
  constructor(props) {
    super(props)
    this.state = {
      language: null,
      position: false,
      hideGooseChoice: true,
    }
  }
  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['dove_' + local] + common['apps']
  }

  //隐藏购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }

  render() {
    const { hideGooseChoice } = this.state
    const { location } = this.props
    const path = this.props.match.path
    return (
      <div
        className={s.box}
        ref="box"
        onMouseOver={(e) => {
          e.persist()
          this.hideGooseChoiceFun(true, e)
        }}
      >
        <Header
          ref="header"
          location={location}
          language={this.state.language}
          path={path}
          navPosition={this.state.position}
          hideGooseChoice={hideGooseChoice}
          hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
        />
        <div ref="clock_all" className={s.clock_all}>
          <div className={s.clock}>
            <div className={s.clock_img} />
            <div className={s.clock_text_1}>
              <FormattedMessage id="clock_text_1" />
            </div>
            <div className={s.clock_text_2}>
              <FormattedMessage id="clock_text_2" />
            </div>
            <div className={s.clock_card_all}>
              <div className={s.clock_card}>
                <div className={s.mijia_icon} />
                <div className={s.clock_name}>
                  <FormattedMessage id="mijia" />
                </div>
                <div className={s.clock_desc}>
                  <FormattedMessage id="mijia_desc" />
                </div>
              </div>
              <div className={s.clock_card}>
                <div className={s.app_plus_icon} />
                <div className={s.clock_name}>
                  <FormattedMessage id="app_plus" />
                </div>
                <div className={s.clock_desc}>
                  <FormattedMessage id="app_plus_desc" />
                </div>
              </div>
            </div>
            <div className={s.bottom_2020q}>
              <FormattedMessage id="company_name" />
            </div>
          </div>
        </div>

        <Footer location={location} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let clock = connect(mapStateToProps)(Clock)

export default clock
