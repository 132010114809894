import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './../../header';
import Footer from './../../footer';
import AirContent from './../../magpie_content';
import s from './Home.module.scss';
import common from '../../../../../locale/common'

class air extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      position: false,
      headerShow: true,
      hideModal: true,
      hideSecondSub: true
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['magpie_' + local]

    let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US';
        break;
      case 'zh':
        browserLanguage = 'zh-CN';
        break;
      default:
        browserLanguage = 'en-US';
    }
    this.setState({
      language: browserLanguage
    });
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  handleScroll () {
    const textRect = this.refs.air.getBoundingClientRect();
    const clientHeight = document.documentElement.clientHeight;
    if (-textRect.top > clientHeight && this.refs.air) {
      this.setState({
        show: true
      });
    } else {
      this.setState({
        show: false
      });
    }
  }

  // /air || /plus header don't show
  headerNoDisplay(value) {
    this.setState({
      headerShow: value
    })
  }

  hideModalFunc(isHide, e, isTouch) {
    if (e.target.className === 'language_choice' || (isTouch && (e.target.innerText === 'English' || e.target.innerText === '简体中文' || e.target.innerText === '繁體中文'))) {
      return
    }
    this.setState({
      hideModal: isHide
    })
  }
  hideSecondSubFunc(isHide, e, isTouch) {
    if ((isTouch && ((e.target.attributes.name && e.target.attributes.name.value === 'secondSub') || (e.target.parentNode.attributes.name && e.target.parentNode.attributes.name.value === 'secondSub')))) {
      return
    }
    this.setState({
      hideSecondSub: isHide
    })
  }

  render () {
    const { hideModal, hideSecondSub } = this.state
    const { location } = this.props;
    const path = this.props.match.path;
    return (
      <div className={s.air} ref="air" onClick={e => {e.persist(); this.hideModalFunc(true, e); this.hideSecondSubFunc(true, e)}} onTouchStart={e => {e.persist(); this.hideModalFunc(true, e, true); this.hideSecondSubFunc(true, e, true)}}>
        <Header headerShow={this.state.headerShow} location={location} language={this.state.language} path={path} downloadShow={this.state.show} hideSecondSub={hideSecondSub} hideSecondSubFunc={(isHide, e) => {this.hideSecondSubFunc(isHide, e)}} />
        <AirContent headerNoDisplay={this.headerNoDisplay.bind(this)} />
        <Footer location={location} hideModal={hideModal} hideModalFunc={(isShow, e) => {this.hideModalFunc(isShow, e)}} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let Air = connect(mapStateToProps)(air);

export default Air;
