import React from 'react'
import { FormattedMessage } from 'react-intl'

import s from './Param.module.scss'

const Param = () => (
  <div className={s.box}>
    <div className={s.param}>
      <div className={s.title_row}>
        <div className={s.title_name} style={{ width: 400 }}>
          <FormattedMessage id="frog" values={{ br: <br /> }} />
        </div>
      </div>
      <div className={s.row}>
        <div className={s.name}>
          <FormattedMessage id="exterior" />
        </div>
        <div className={s.cell}>
          <div className={s.sizeImg}>
            <div className={s.top_side}>
              <div className={s.left_left}>
                <div className={s.left_img}></div>
                <div className={s.left_bottom}></div>
                <div className={s.bottom_num}>
                  <FormattedMessage id="frog_spec_long" />
                </div>
              </div>
            </div>
            <div className={s.top_top}>
              <div className={s.top_img}></div>
              <div className={s.top_bottom}></div>
              <div className={s.bottom_num}>
                <FormattedMessage id="frog_spec_short" />
              </div>
              <div className={s.left_right}></div>
              <div className={s.right_num}>
                <FormattedMessage id="frog_spec_height" />
              </div>
            </div>
          </div>
          {/* <div className={s.P_sizeImg} /> */}
          <ul>
            <li>
              <span className={s.type}>
                <FormattedMessage id="p_size" />
              </span>
              <FormattedMessage id="frog_spec_size" />
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="p_color" />
              </span>
              <FormattedMessage id="frog_color" />
            </li>
          </ul>
        </div>
      </div>
      <div className={s.row}>
        <div className={s.name}>
          <FormattedMessage id="range" />
        </div>
        <div className={s.cell}>
          <ul>
            <li>
              <span className={s.type}>
                <FormattedMessage id="p_temp" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage
                  id="frog_temperature"
                  values={{ br: <br /> }}
                />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="p_hum" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog_humidity" values={{ br: <br /> }} />
              </div>
            </li>
          </ul>
          <p>
            <FormattedMessage id="under_ninty" />
          </p>
        </div>
      </div>
      <div className={s.row}>
        <div className={s.name}>
          <FormattedMessage id="p_specifications" />
        </div>
        <div className={s.cell}>
          <ul>
            <li>
              <span className={s.type}>
                <FormattedMessage id="ph_name" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog" values={{ br: '' }} />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="p_weight" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog_weight" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="screen_size" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog_screen_size" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="p_wifi" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog_wifi" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="only_bluetooth" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog_ble" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="usb_port" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog_power" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="rated_input" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog_inout" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="frog_battery_support" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog_battery" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="frog_first_level" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog_protect" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={s.pack}>
        <div className={s.name}>
          <FormattedMessage id="p_pack" />
        </div>
        <div className={s.cell}>
          <ul>
            <li>
              <i className={s.pack_1} />
              <FormattedMessage id="frog" values={{ br: <br /> }} />
            </li>
            <li>
              <i className={s.pack_2} />
              <FormattedMessage
                id="frog_package_battery"
                values={{ br: <br /> }}
              />
            </li>
            <li>
              <i className={s.pack_3} />
              <FormattedMessage id="p_instructions" />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className={s.seperate_line}></div>

    <div className={s.param}>
      <div className={s.title_row}>
        <div className={s.title_name} style={{ width: 400 }}>
          <FormattedMessage id="frog_needle" />
        </div>
      </div>
      <div className={s.row}>
        <div className={s.name}>
          <FormattedMessage id="exterior" />
        </div>
        <div className={s.cell}>
          <div className={s.sizeImg} style={{ marginBottom: 40, height: 333 }}>
            <div className={s.top_side}>
              <div className={s.left_left}>
                <div className={s.left_img_needle}></div>
                {/* <div className={s.left_bottom}></div>
                <div className={s.bottom_num}>
                  <FormattedMessage id="frog_spec_long" />
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className={s.P_sizeImg} /> */}
          <ul>
            <li>
              <span className={s.type}>
                <FormattedMessage id="p_size" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="needle_size" values={{ br: <br /> }} />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={s.row}>
        <div className={s.name}>
          <FormattedMessage id="range" />
        </div>
        <div className={s.cell}>
          <ul>
            <li>
              <span className={s.type}>
                <FormattedMessage id="p_temp" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage
                  id="needle_temperature"
                  values={{ br: <br /> }}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={s.row}>
        <div className={s.name}>
          <FormattedMessage id="p_specifications" />
        </div>
        <div className={s.cell}>
          <ul>
            <li>
              <span className={s.type}>
                <FormattedMessage id="ph_name" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="frog_needle" values={{ br: '' }} />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="p_weight" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="needle_weight" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="needle_connect_way" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="needle_connect" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="frog_first_level" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="needle_protect" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={s.row}>
        <div className={s.name}>
          <FormattedMessage id="needle_material_left" />
        </div>
        <div className={s.cell}>
          <ul>
            <li>
              <span className={s.type}>
                <FormattedMessage id="sensor_detect" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="needle_material" values={{ br: '' }} />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage id="sensor_body" />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="needle_body" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage
                  id="needle_package_bottom"
                  values={{ br: ' ' }}
                />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="needle_paste" />
              </div>
            </li>
            <li>
              <span className={s.type}>
                <FormattedMessage
                  id="needle_package_with"
                  values={{ br: ' ' }}
                />
              </span>
              <div className={s.second_part}>
                <FormattedMessage id="needle_with" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={s.pack}>
        <div className={s.name}>
          <FormattedMessage id="p_pack" />
        </div>
        <div className={s.cell}>
          <ul>
            <li>
              <i className={s.pack_7} />
              <FormattedMessage id="frog_needle" />
            </li>
            <li>
              <i className={s.pack_8} />
              <FormattedMessage
                id="needle_package_bottom"
                values={{ br: '' }}
              />
            </li>
            <li>
              <i className={s.pack_9} />
              <FormattedMessage id="needle_package_with" values={{ br: '' }} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default Param
