import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import s from './Stwelfth.module.scss'

class Stwelfth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Stwelfth.getBoundingClientRect()
    if (clientHeight >= textRect.bottom - 200 && this.refs.Stwelfth) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    return (
      <div ref="Stwelfth">
        <div className={s.Stwelfth}>
          <div className={s.wrap}>
            <div className={s.text} ref="text">

              <div className={s.title}>
                <FormattedMessage id="chicken_twelfth_title" values={{br: ''}} />
              </div>

              <div className={s.imgBox}>

                <div className={s.img1}>
                  <div className={s.desc}>
                    <FormattedMessage id="chicken_twelfth_desc1" values={{ br: '' }} />
                  </div>
                </div>
                <div className={s.img2}>
                  <div className={s.desc}>
                    <FormattedMessage id="chicken_twelfth_desc2" values={{ br: <br /> }} />
                  </div>
                </div>

              </div>
              
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const STwelfth = connect(mapStateToProps)(Stwelfth)

export default STwelfth
