import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Sparam.module.scss'

class Sparam extends React.PureComponent {
  render() {
    const { locale } = this.props
    return (
      <div className={s.box}>
        <div className={s.Sparam}>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="snow2_exterior" />
            </div>
            <div className={s.cell}>
              <div className={s.sizeImg}>
                <div className={s.top_side}>
                  <div className={s.left_left}>
                    <div className={s.left_img}></div>
                    <div className={s.bottom_num}>
                      <FormattedMessage id="sw2_size_value_long" />
                    </div>
                  </div>
                  <div className={s.right_num}>
                    <FormattedMessage id="sw2_size_value_short" />
                  </div>
                </div>
                <div className={s.top_top}>
                  <div className={s.top_img}></div>
                  {/* <div className={s.top_bottom}></div> */}
                  <div className={s.bottom_num}>
                    <FormattedMessage id="sw2_size_value_height" />
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_size" />
                  </span>
                  <FormattedMessage id="sw2_size_value" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_color" />
                  </span>
                  <FormattedMessage id="sw2_color" />
                </li>
              </ul>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="snow2_range" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_temp" />
                  </span>
                  <FormattedMessage id="temp_range_snow2" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_hum" />
                  </span>
                  <FormattedMessage id="hum_range_snow2" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="co2" />
                  </span>
                  <FormattedMessage id="co2_range_snow2" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="pm25_pm10" />
                  </span>
                  <FormattedMessage id="pm25_pm10_range_snow2" />
                </li>

                <li>
                  <span className={s.type}>
                    <FormattedMessage id="etvoc" />
                  </span>
                  <FormattedMessage id="etvoc_range_snow2" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="noise" />
                  </span>
                  <FormattedMessage id="noise_range_snow2" />
                </li>
              </ul>
              <p className={s.snow_annotation}>
                <FormattedMessage id="under_ninty" />
              </p>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="snow2_spec" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_weight" />
                  </span>
                  <FormattedMessage id="sw2_weight_value" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="screen_size" />
                  </span>
                  <FormattedMessage id="sw2_screen_size_value" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="screen_resolution" />
                  </span>
                  <FormattedMessage id="screen_resolution_value" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="only_wifi" />
                  </span>
                  <FormattedMessage id="sw2_wifi_value" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_battery" />
                  </span>
                  <FormattedMessage id="sw2_battery_type_value" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="sw2_battery_size" />
                  </span>
                  <FormattedMessage id="sw2_battery_size_value" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="usb_port" />
                  </span>
                  <FormattedMessage id="usb_port_value" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="rated_input" />
                  </span>
                  <FormattedMessage id="rated_input_value" />
                </li>
              </ul>
            </div>
          </div>
          <div className={s.pack}>
            <div className={s.name}>
              <FormattedMessage id="p_pack" />
            </div>
            <div className={s.cell} style={{ paddingLeft: 0 }}>
              <ul className={s.packWrap}>
                <li>
                  <i
                    className={
                      locale.indexOf('US') > -1 ? s.pack_1_en : s.pack_1
                    }
                  />
                  <FormattedMessage id="snow2" />
                </li>
                <li>
                  <i className={s.pack_2} />
                  <FormattedMessage id="sw2_usb_charge" />
                </li>
                <li>
                  <i className={s.pack_3} />
                  <FormattedMessage id="p_instructions" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SParam = connect(mapStateToProps)(Sparam)

export default SParam
