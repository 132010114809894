import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './Sfifth.module.scss'

class Sfifth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Sfifth.getBoundingClientRect();
        if (this.refs.Sfifth && clientHeight >= textRect.bottom - 600) {
            setTimeout(() => {
                this.refs.text1.style.opacity = .9;
                this.refs.text1.style.top = '0';
                
                this.refs.text2.style.opacity = .6;
                this.refs.text2.style.top = '0';

                this.refs.text3.style.opacity = 1;
                this.refs.text3.style.top = '0';
            }, 300);
        }
    }
    render() {
        const { locale } = this.props
        return (
            <div className={s.Sfifth} ref="Sfifth">
                <div className={s.wrap}>
                    <div className={s.text}>
                        <div className={s.title} ref="text1">
                            <FormattedMessage id="mp_e_link" />
                            <br/>
                            <FormattedMessage id="mp_clear" />
                        </div>
                        <div className={s.Sfourth_desc} ref="text2">
                        <FormattedMessage id="mp_goods_2" values={{br: <br />}} />
                        </div>
                        <div className={s.iconList} ref="text3">
                            <ul>
                                <li className={locale.indexOf('US') > -1 ? s.icon_detail_en : s.icon_detail}>
                                    <i className={s.SfifthA7} />
                                    <FormattedMessage id="mp_superview" />
                                </li>
                                <li className={locale.indexOf('US') > -1 ? s.icon_detail_en : s.icon_detail}>
                                    <i className={s.SfifthCalculate} />
                                    <FormattedMessage id="mp_high_contrast" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  const SFifth = connect(mapStateToProps)(Sfifth)

export default SFifth;

