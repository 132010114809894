import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Ptenth.module.scss'

class Ptenth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
      battery: false,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Ptenth.getBoundingClientRect()
    if (this.refs.Ptenth && clientHeight >= textRect.bottom - 200) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 0)
    }
  }
  render() {
    const { locale } = this.props
    const column0 = locale.indexOf('US') > -1 ? s.column0_en : s.column0
    const column1 = locale.indexOf('US') > -1 ? s.column1_en : s.column1
    const column2 = locale.indexOf('US') > -1 ? s.column2_en : s.column2
    return (
      <div className={s.Ptenth} ref="Ptenth">
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <div className={s.title}>
              <FormattedMessage id="frog_eight_title" values={{ br: <br /> }} />
            </div>
          </div>
          <div className={s.pic}>
            <div className={s.item1}>
              <div className={locale.indexOf('US') > -1 ? s.table_en : s.table}>
                <div
                  className={s.table_row}
                  style={{
                    backgroundColor: '#4a5055',
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    fontSize: 20,
                  }}
                >
                  {/* <div style={{ opacity: 0 }} className={column0}>
                    <FormattedMessage id="ph_first_text5" />
                  </div> */}
                  <div
                    className={column1}
                    style={{
                      opacity: 1,
                      fontWeight: 'bold',
                    }}
                  >
                    <FormattedMessage id="frog" values={{ br: '' }} />
                  </div>
                  {/* <div
                    className={column2}
                    style={{ opacity: 0, fontWeight: 'bold' }}
                  >
                    <FormattedMessage id="frog_needle" values={{ br: '' }} />
                  </div> */}
                </div>
                <div className={s.table_row}>
                  <div
                    className={column0}
                    style={{ opacity: 0, fontWeight: 'bold' }}
                  >
                    {/* <FormattedMessage id="ph_first_text5" /> */}
                  </div>
                  <div className={column1}>
                    <FormattedMessage id="temperature" />
                  </div>
                  <div className={column2}>
                    <FormattedMessage id="hum" />
                  </div>
                </div>
                <div className={s.table_row}>
                  <div
                    className={column0}
                    style={{ opacity: 1, fontWeight: 'bold' }}
                  >
                    <FormattedMessage id="frog_eight_left_1" />
                  </div>
                  <div className={column1}>
                    <FormattedMessage
                      id="frog_eight_left_4"
                      values={{ br: <br /> }}
                    />
                  </div>
                  <div className={column2}>
                    <FormattedMessage
                      id="frog_eight_left_5"
                      values={{ br: <br /> }}
                    />
                  </div>
                </div>
                <div className={s.table_row}>
                  <div
                    className={column0}
                    style={{ opacity: 1, fontWeight: 'bold' }}
                  >
                    <FormattedMessage id="frog_eight_left_2" />
                  </div>
                  <div className={column1}>
                    <FormattedMessage id="frog_eight_left_6" />
                  </div>
                  <div className={column2}>
                    <FormattedMessage id="frog_eight_left_7" />
                  </div>
                </div>
                <div
                  className={s.table_row}
                  style={{
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    borderBottom: 0,
                  }}
                >
                  <div
                    className={column0}
                    style={{ opacity: 1, fontWeight: 'bold' }}
                  >
                    <FormattedMessage id="frog_eight_left_3" />
                  </div>
                  <div className={column1}>
                    <FormattedMessage id="frog_eight_left_8" />
                  </div>
                  <div className={column2}>
                    <FormattedMessage id="frog_eight_left_9" />
                  </div>
                </div>
              </div>
            </div>
            <div className={s.item2}>
              <div className={locale.indexOf('US') > -1 ? s.table_en : s.table}>
                <div
                  className={s.table_row_2}
                  style={{
                    backgroundColor: '#7f8182',
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    fontSize: 20,
                  }}
                >
                  <div
                    className={column1}
                    style={{ opacity: 1, fontWeight: 'bold' }}
                  >
                    <FormattedMessage id="frog_needle" values={{ br: '' }} />
                  </div>
                </div>
                <div className={s.table_row_2}>
                  <div
                    className={column0}
                    style={{ opacity: 0, fontWeight: 'bold' }}
                  >
                    <FormattedMessage id="ph_first_text5" />
                  </div>
                  <div className={column1}>
                    <FormattedMessage id="hum" />
                  </div>
                </div>
                <div className={s.table_row_2}>
                  <div
                    className={column0}
                    style={{ opacity: 1, fontWeight: 'bold' }}
                  >
                    <FormattedMessage id="frog_eight_left_1" />
                  </div>
                  <div className={column1}>
                    <FormattedMessage
                      id="frog_eight_left_4"
                      values={{ br: <br /> }}
                    />
                  </div>
                </div>
                <div className={s.table_row_2}>
                  <div
                    className={column0}
                    style={{ opacity: 1, fontWeight: 'bold' }}
                  >
                    <FormattedMessage id="frog_eight_left_2" />
                  </div>
                  <div className={column1}>
                    <FormattedMessage id="frog_eight_left_6" />
                  </div>
                </div>
                <div
                  className={s.table_row_2}
                  style={{
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    borderBottom: 0,
                  }}
                >
                  <div
                    className={column0}
                    style={{ opacity: 1, fontWeight: 'bold' }}
                  >
                    <FormattedMessage id="frog_eight_left_3" />
                  </div>
                  <div className={column1}>
                    <FormattedMessage id="frog_eight_right_1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PTenth = connect(mapStateToProps)(Ptenth)

export default PTenth
