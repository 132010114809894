import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Ssecond.module.scss'

class Ssecond extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Ssecond.getBoundingClientRect();
        if (this.refs.Ssecond && clientHeight >= textRect.bottom-500) {
            setTimeout(() => {
                this.refs.text1.style.opacity = .9;
                this.refs.text1.style.top = '0';

                this.refs.text2.style.opacity = .6;
                this.refs.text2.style.top = '0';
                
                this.refs.pic.style.opacity = 1;
                this.refs.pic.style.right = '0';
            }, 300);
        }
    }
    render() {
        return (
            <div className={s.Ssecond} ref="Ssecond">
                <div className={s.wrap}>
                    <div className={s.content}>
                        <div className={s.title} ref="text1">
                            <FormattedMessage id="mp_story" />
                        </div>
                        <div className={s.sector1} ref="text2">
                            <FormattedMessage id="mp_story_1" />
                            <br/><br/>
                            <FormattedMessage id="mp_story_2" />
                        </div>
                    </div>
                    <div className={s.rightPic} ref="pic">
                        <div className={s.img}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Ssecond;

