import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Sseventh.module.scss'

class Sseventh extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Sseventh.getBoundingClientRect();
        if (this.refs.Sseventh && clientHeight >= textRect.bottom - 300) {
            setTimeout(() => {
                this.refs.text1.style.opacity = .9;
                this.refs.text1.style.top = '0';

                this.refs.text2.style.opacity = .5;
                this.refs.text2.style.top = '0';

                this.refs.text3.style.opacity = .5;
                this.refs.text3.style.top = '0';
            }, 300);
        }
    }
    render() {
        return (
            <div className={s.Sseventh} ref="Sseventh">
                <div className={s.text}>
                        <div className={s.title} ref="text1">
                            <FormattedMessage id="mp_love_music" />
                            <br/>
                            <FormattedMessage id="mp_guardian" />
                        </div>
                        <div className={s.desc} ref="text2">
                        <FormattedMessage id="mp_suitable" values={{br: <br />}} />
                        </div>
                        <div className={s.desc_2} ref="text3">
                            <FormattedMessage id="mp_purchase" />
                        </div>
                </div>
            </div>
        );
    }
}

export default Sseventh;
