import React, { Component } from 'react'

import EnSPrivacy from './en'
import ZhPrivacy from './zh'
import ZhHantPrivacy from './zh-Hant'

class Pprivacy extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
      language: 'zh',
    }
  }
  componentDidMount() {
    console.log(this.props, 'this.props of index.jsx')
    if (this.props.location.search.indexOf('en') > -1) {
      this.setState({
        language: 'en',
      })
    } else if (this.props.location.search.indexOf('ant') > -1) {
      this.setState({
        language: 'zh-Hant',
      })
    } else {
      this.setState({
        language: 'zh',
      })
    }
  }

  render() {
    const { language } = this.state

    return (
      <div>
        {language === 'zh-Hant' ? (
          <ZhHantPrivacy />
        ) : language === 'en' ? (
          <EnSPrivacy />
        ) : (
          <ZhPrivacy />
        )}
      </div>
    )
  }
}

export default Pprivacy
