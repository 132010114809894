import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Env, Routers } from './env.config'
import configureStore from './store/store'
import Intl from './components/common/intl'
import './App.css'

if (typeof window !== 'undefined') {
  if (Env === 'mobile') {
    require('./scss/mobile/client.module.scss')
  } else {
    require('./scss/pc/client.module.scss')
  }
}

const store = configureStore()
function App() {
  return (
    <Provider store={store}>
      <Intl>
        <BrowserRouter>
          <Routers />
        </BrowserRouter>
      </Intl>
    </Provider>
  )
}

export default App;
