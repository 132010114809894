import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import s from './Product.module.scss'

class Product extends React.PureComponent {
  toTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  constructor(props) {
    super(props)
    this.state = {
      language: null,
    }
  }

  render() {
    const { locale } = this.props
    return (
      <div className={s.product}>
        <div>
          <ul className={locale.indexOf('US') > -1 ? s.ul_en : s.ul_cn}>
            <li className={s.goose}>
              <Link to="/temp-rh-monitor/overview" onClick={() => this.toTop()}>
                <div className={s.title}>
                  <FormattedMessage id="goose_lite" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="goose_screen" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="support" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="wall" />
                  <br />
                  <FormattedMessage id="user" />
                </div>
              </Link>
            </li>
            <li className={s.duck2}>
              <Link
                to="/temp-rh-monitor-lite/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="duck2" />
                </div>
                <div className={s.desc} style={{ paddingRight: 0 }}>
                  <FormattedMessage id="support" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="wall" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="duck2_support" />
                  <br />
                  <FormattedMessage id="user" />
                </div>
              </Link>
            </li>
            <li className={s.magpie}>
              <Link
                to="/lee-guitars-thermo-hygrometer/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="magpie" values={{ br: '' }} />
                </div>
                <div className={s.desc} style={{ paddingRight: 35 }}>
                  <FormattedMessage id="grasp_guitar_sip" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="e_link" />
                  <br />
                  {/* <span className={s.line}>·</span> */}
                  <FormattedMessage id="record_history_data" />
                </div>
              </Link>
            </li>
            <li className={s.hodor}>
              <Link
                to="/door-window-sensor/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="hodor" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="hodor_monitor" />
                  <br />
                  <FormattedMessage id="automation" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="p_wifi_value" />
                </div>
              </Link>
            </li>
            <li className={s.parrot}>
              <Link
                to="/motion-light-sensor/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="parrot_title" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="parrot_des1" />
                  <br />
                  <FormattedMessage id="parrot_des2" />
                </div>
              </Link>
            </li>
            <li className={s.dove}>
              <Link
                to="/bluetooth-alarm-clock/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="dove" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="button_less" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="temp_rh_mon" />
                  <br />
                  <FormattedMessage id="soft_backlight" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="sixteen_ala" />
                </div>
              </Link>
            </li>
            <li className={s.chicken}>
              <Link to="/bluetooth-clock/overview" onClick={() => this.toTop()}>
                <div className={s.title}>
                  <FormattedMessage id="chicken_title" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="chicken_des1" />
                  <br />
                  <FormattedMessage id="chicken_des2" />
                </div>
              </Link>
            </li>
            <li className={s.sparrow}>
              <Link
                to="/bluetooth-gateway/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="sparrow" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="connect_mi" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="enable_remote" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="automation" />
                </div>
              </Link>
            </li>
            <li className={s.lora}>
              <Link
                to="/temp-rh-monitor-pro-s/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="pheasant_s" values={{ br: '' }} />
                </div>
                <div className={s.desc} style={{ paddingRight: 0 }}>
                  <FormattedMessage id="l_support" />
                  <br />
                  <FormattedMessage id="l_battery" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="l_user" />
                </div>
              </Link>
            </li>
            <li className={s.frog}>
              <Link
                to="/temp-rh-monitor-pro-e/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="frog" values={{ br: '' }} />
                </div>
                <div className={s.desc} style={{ paddingRight: 0 }}>
                  <FormattedMessage id="frog_support_1" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="frog_support_2" />
                  <br />
                  <FormattedMessage id="frog_support_3_1" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="frog_support_4" />
                </div>
              </Link>
            </li>
            <li className={s.pheasant_co2}>
              <Link
                to="/co2-temp-rh-monitor/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="pheasant_co2_title" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="pheasant_co2_des1" />
                  <br />
                  <FormattedMessage id="pheasant_co2_des2" />
                </div>
              </Link>
            </li>
            <li className={s.snow}>
              <Link to="/air-monitor-2/overview" onClick={() => this.toTop()}>
                <div className={s.title_snow2}>
                  <FormattedMessage id="snow2" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="pm25" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="pm10" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="noise" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="co2" />
                  {locale.indexOf('US')>-1?
                  <span className={s.line}>·</span>:<br />
                }
                  <FormattedMessage id="etvoc" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="temperature" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="hum" />
                  <br />
                  <FormattedMessage id="snow2_sensor" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="snow_screen" />
                </div>
              </Link>
            </li>
            <li className={s.dany}>
              <Link
                to="/air-monitor-lite/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title_dany}>
                  <FormattedMessage id="dany" />
                </div>
                <div className={s.desc_dany}>
                  <FormattedMessage id="pm25" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="pm10" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="co2" />
                  {/* <span className={s.line}>·</span>
                    <FormattedMessage id="tvoc_s" /> */}
                  <span className={s.line}>·</span>
                  <FormattedMessage id="temperature" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="hum" />
                  <br />
                  <FormattedMessage id="touchbar" />
                  <span className={s.line}>·</span>
                  <FormattedMessage
                    id="support_all_pc"
                    values={{ br: <br /> }}
                  />
                </div>
              </Link>
            </li>
            <li className={s.app}>
              <Link to="/plus" onClick={() => this.toTop()}>
                <div className={s.title}>
                  <FormattedMessage id="cg_app" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="device_management" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="data_insights" />
                  <br />
                  <FormattedMessage id="historical_data" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="access_remote" />
                </div>
              </Link>
            </li>
            <li className={s.qingpingiot}>
              <a
                href="https://qingpingiot.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={s.title}>
                  <FormattedMessage id="qing_ping_iot" />
                </div>
                <div className={s.desc}>
                  {/* 企业级设备管理 · 多人共享 · 数据呈现 · 多途径实时警报 */}
                  <FormattedMessage id="team_management" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="data_collection" />
                  <br />
                  <FormattedMessage id="device_sharing" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="alarm_notification" />
                </div>
              </a>
            </li>
            <li className={s.developer}>
              <a
                href="https://developer.qingping.co"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={s.title}>
                  <FormattedMessage id="index_developer" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="customize_device" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="own_platform" />
                </div>
              </a>
            </li>
          </ul>
        </div>
        {/* ) } */}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const product = connect(mapStateToProps)(Product)

export default product
