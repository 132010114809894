import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../../header'
import Footer from '../../footer'
import Sfirst from '../Sfirst'
import Ssecond from '../Ssecond'
import Sthird from './../Sthird'
import Sfourth from '../Sfourth'
import Sfifth from '../Sfifth'
import Ssixth from '../Ssixth'
import Sseventh from '../Sseventh'
import Seighth from '../Seighth'
import Snineth from '../Snineth'
import Stenth from '../Stenth'
import Seleventh from '../Seleventh'
import Stwelfth from '../Stwelfth'
import Sthirteenth from '../Sthirteenth'
// import Sfourteenth from '../Sfourteenth';
import Sfifteenth from '../Sfifteen'
// import Ssixteenth from '../Ssixteenth';
// import Sseventeenth from '../Sseventeenth';

import s from './Sstyle.module.scss'
import common from '../../../../../locale/common'
class Sstyle extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      language: null,
      position: false,
      hideGooseChoice: true,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['chicken_' + local]

    let browserLanguage = navigator.browserLanguage
      ? navigator.browserLanguage
      : navigator.language
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US'
        break
      case 'zh':
        browserLanguage = 'zh-CN'
        break
      default:
        browserLanguage = 'en-US'
    }
    this.setState({
      language: browserLanguage,
    })
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const textRect = this.refs.box.getBoundingClientRect()
    if (textRect.top < -50 && this.refs.box) {
      if(this.state.position) return
      this.setState({
        position: true,
      })
    } else {
      if(!this.state.position) return
      this.setState({
        position: false,
      })
    }
  }

  //隐藏购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }

  render() {
    const { hideGooseChoice } = this.state
    const { location } = this.props
    const path = this.props.match.path
    return (
      <div
        className={s.box}
        ref="box"
        onMouseOver={(e) => {
          e.persist()
          this.hideGooseChoiceFun(true, e)
        }}
      >
        <div className={s.Sstyle}>
          <Header
            location={location}
            language={this.state.language}
            path={path}
            navPosition={this.state.position}
            hideGooseChoice={hideGooseChoice}
            hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
          />
          <Sfirst />
          <Sthird />
          <Sfourth />
          <Sfifth />
          <Ssixth />
          <Sseventh />
          <Seighth />
          <Snineth />
          <Stenth />
          <Seleventh />
          <Sfifteenth />
          <Stwelfth />
          <Sthirteenth />
          {/* <Sfourteenth /> */}
          {/* <Ssixteenth /> */}
          {/* <Sseventeenth /> */}
          <Ssecond />
          <Footer location={location} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SStyle = connect(mapStateToProps)(Sstyle)

export default SStyle
