import React, { Component } from 'react';

import Header from './../header';
import Footer from './../footer';
import s from './Privacy.module.scss';

class Privacy extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            language: null,
            position: false
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
        switch (browserLanguage) {
            case 'en':
                browserLanguage = 'en-US';
                break;
            case 'zh':
                browserLanguage = 'zh-CN';
                break;
            default:
                browserLanguage = 'en-US';
        }
        this.setState({
            language: browserLanguage
        });
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const textRect = this.refs.privacy.getBoundingClientRect();
        // console.log(textRect.top);
        if (textRect.top < -50 && this.refs.privacy) {
            this.setState({
                position: true
            });
        } else {
            this.setState({
                position: false
            });
        }
    }
    render() {
        const { location } = this.props;
        const path = this.props.match.path;
        return (
            <div className={s.box}>
                <Header location={location} language={this.state.language} path={path} navPosition={this.state.position} />
                <div className={s.privacy} ref="privacy">
                    <p><b>PRIVACY POLICY</b></p>
                    <p><b>Update!</b></p>
                    <p>{'Our Privacy Policy was updated on September 30, 2018. We have revamped the Privacy Policy front and back so that from this date onwards, this Privacy Policy can provide privacy details on how we manage your personal information for the "Mi Temperature and Humidity Monitor" software and services provided by Qingping Technology (Beijing) Co., Ltd. (hereinafter referred to as "Qingping ")'}</p>
                    <p>Please take a moment to familiarize yourself with our privacy practices and let us know if you have any questions.</p>
                    <p>OUR COMMITMENT TO YOU</p>
                    <p>{'This Privacy Policy sets out how Qingping and its affiliated companies ("we", "our" or "us") collect, use, disclose, process and protect any information that you give us when you use "Mi Temperature and Humidity Monitor" software and services. Should we ask you to provide certain information by which you can be identified when using "Mi Temperature and Humidity Monitor" software and services, it will only be used in accordance with this Privacy Policy and/or our terms and conditions for users.'}</p>
                    <p>The Privacy Policy is designed with you in mind, and it is important that you have a comprehensive understanding of our personal information collection and usage practices, as well as full confidence that ultimately, you have control of any personal information provided to Qingping.</p>
                    <p>{'In this Privacy Policy, "personal information" means information that can be used to identify an individual, either from that information alone or from that information combined with other information Qingping has access about that individual.'}</p>
                    <p>{'By using"Mi Temperature and Humidity Monitor" software and services, you are deemed to have read, acknowledged and accepted all the provisions stated here in the Privacy Policy, including any changes we may make from time to time. In order to comply with applicable laws, including local data protection legislation (e.g. General Data Protection Regulation in Europe Union), we will specifically seek prior explicit consent to the particular processing (e.g. automated individual decision-making) of special categories of personal data. We are committed to protecting the privacy, confidentiality and security of your personal information by complying with applicable laws, including your local data protection legislation. We are equally committed to ensuring that all our employees and agents uphold these obligations.'}</p>
                    <p>Ultimately, what we want is the best for all our users. Should you have any concerns with our data handling practice as summarised in this Privacy Policy, please contact our Data Protection Officer at <a href="mailto:privacy@cleargrass.com">privacy@cleargrass.com</a> to address your specific concerns. We will be happy to address them directly.</p>
                    <p>WHAT INFORMATION IS COLLECTED AND HOW DO WE USE IT?</p>
                    <p>TYPES OF INFORMATION COLLECTED</p>
                    <p>In order to provide our services to you, we will ask you to provide personal information that is necessary to provide those services to you. If you do not provide your personal information, we may not be able to provide you with our products or services.</p>
                    <p>We will only collect the information that is necessary for its specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes. We may collect the following types of information (which may or may not be personal information):</p>
                    <p><b>Temperature and Relative Humidity information the Device measured</b>: We may collect temperature and relative humidity information. These information will be displayed within the Mi Home app for you to access. These information will only be retained for 1 year.</p>
                    <p><b>Device information</b>: information related to your device, including MAC address, and device ID.</p>
                    <p>Such information is collected in order to improve the services we provide to you. The type and amount of information collected will depend on how you use, opt-in or participate in our products and/or services.</p>
                    <p>HOW THE PERSONAL INFORMATION IS USED</p>
                    <p>Personal information is collected for providing services and / or products to you, and legal compliance on our part under applicable laws. You hereby consent that we may process and disclose personal information to our affiliated companies (which are in the communications, social media, technology and cloud businesses), Third Party Service Providers (defined below) for the purposes stated in this Privacy Policy.</p>
                    <p>We may use your personal information for the following purposes:</p>
                    <ul>
                        <li>Providing, processing, maintaining, improving and developing our goods and/or services to you, including after-sales and customer support and for services on your device or through our websites.</li>
                        <li>Communicating with you about your device, service or any general queries, such as updates, customer inquiry support, information about our events, notices.</li>
                        <li>Displaying the temperature and relative humidity 
                        information within the Mi Home app after you logged in to it. These information will only be retained for 1 year.</li>
                        <li>Sending notices. From time to time, we may use your personal information to send important notices which related to Mi Temperature and Humidity Monitor. If you don’t need these information, you can choose not to receive notifications in Mi Home app.</li>
                        <li>Verifying the validity of the device.</li>
                    </ul>
                    <p>DIRECT MARKETING</p>
                    <p>We may use your email address and Mi Account ID to provide marketing materials to you relating to goods and services of Qingping and our business partners which offer network, mobile applications and cloud products and services. We will only so use your personal data after we have obtained your consent and involve a clear affirmative action or indication of no objection in accordance with local data protection laws, which may require separate explicit consent. You have the right to opt out of our proposed use of your personal data for direct marketing. If you no longer wish to receive certain types of email communications, you may send email to <a href="mailto:privacy@cleargrass.com">privacy@cleargrass.com</a> . We will not transfer your personal data to our business partners for use by our business partners in direct marketing.</p>
                    <p>WITH WHOM WE SHARE YOUR INFORMATION</p>
                    <p>We do not sell any personal information to third parties.</p>
                    <p>We may disclose your personal information on occasion to third parties (as described below) in order to provide the products or services that you have requested.</p>
                    <p>Disclosure may be made to Third Party Service Providers and affiliated companies listed in this section below. In each case described in this section, you can be assured that Qingping will only share your personal information in accordance with your consent. Your consent to Qingping will engage sub-processors for the processing of your personal information. You should know that when Qingping shares your personal information with a Third Party Service Provider under any circumstance described in this section, Qingping will contractually specify that the third party is subject to practices and obligations to comply with applicable local data protection laws. Qingping will contractually ensure compliance by any Third Party Service Providers with the privacy standards that apply to them in your home jurisdiction.</p>
                    <p>SHARING WITH OUR GROUP AND THIRD PARTY SERVICE PROVIDERS</p>
                    <p>In order to conduct business operations smoothly in providing you with the full capabilities of our products and services, we may disclose your personal information from time to time to other Qingping affiliated companies, or our third party service providers which are our mailing houses, delivery service providers, telecommunications companies, data centres, data storage facilities, customer service providers, advertising and marketing service providers, and Qingping’s representatives. Such Third Party Service Providers would be processing your personal information on Qingping’s behalf or for one or more of the purposes listed above. If you no longer wish to allow us to share this information, please contact us at <a href="mailto:privacy@cleargrass.com">privacy@cleargrass.com</a>.</p>
                    <p>SHARING WITH OUR GROUP’S ECOSYSTEM COMPANIES</p>
                    <p>Xiaomi works together with a cool group of companies, which together form the Mi Ecosystem. The Mi Ecosystem companies are independent entities, invested and incubated by Xiaomi, and are experts in their fields. Qingping may disclose your personal data to the Xiaomi or other Mi Ecosystem companies so as to provide you with and improve the exciting products and services (both hardware and software) from the Mi Ecosystem. Some of these products and services will still be under Xiaomi / Mijia / Mitu brands, while others may use our own brand. Qingping will take appropriate organizational and technical measures to ensure the security of personal data during the process of sharing of information, including but not limited to the encryption of your personal data. If Qingping is involved in a merger, acquisition or asset sale of all or a portion of our assets, you will be notified via email and/or a prominent notice on our website, of any changes in ownership, uses of your personal information, and choices you may have regarding your personal information.</p>
                    <p>SHARING WITH OTHERS</p>
                    <p>Qingping may disclose your personal information without further consent when required under applicable law.</p>
                    <p>INFORMATION NOT REQUIRING CONSENT</p>
                    <p>We may share anonymized information and statistics in aggregate form with third parties for business purposes, for example with advertisers on our website, we may share them trends about the general use of our services, such as the number of customers in certain demographic groups who purchased certain products or who carried out certain transactions.<br />
                    For the avoidance of doubt, Qingping may collect, use or disclose your personal information without your consent if it is and only to the extent it is allowed explicitly under local data protection laws. Such disclosure may be brought about by the necessity to protect our rights, ensure the safety of you and other people, and comply with the requirements of the local government to facilitate the investigations of illegal activities.</p>
                    <p>SECURITY SAFEGUARDS</p>
                    <p>QINGPING’S SECURITY MEASURES</p>
                    <p>We are committed to ensuring that your personal information is secure. In order to prevent unauthorised access, disclosure or other similar risks, we have put in place reasonable physical, electronic and managerial procedures to safeguard and secure the information we collect from your using of Qingping products and services and on Qingping websites. We will use all reasonable efforts to safeguard your personal information.</p>
                    <p>{'For example, when you access your Mi Account, you can choose to use our two-step verification process for better security. When you send or receive data from your Qingping device to our servers, we make sure they are encrypted using Secure Sockets Layer ("SSL") and other algorithms.'}</p>
                    <p>All your personal information is stored on secure servers that are protected in controlled facilities. We classify your data based on importance and sensitivity, and ensure that your personal information has the highest security level. We make sure that our employees and Third Party Service Providers who access the information to help provide you with our products and services are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet such obligations. We have special access controls for cloud based data storage as well. All in all, we regularly review our information collection, storage and processing practices, including physical security measures, to guard against any unauthorized access and use.</p>
                    <p>We will take all practicable steps to safeguard your personal information. However, you should be aware that the use of the Internet is not entirely secure, and for this reason we cannot guarantee the security or integrity of any personal information which is transferred from you or to you via the Internet.</p>
                    <p>WHAT YOU CAN DO</p>
                    <ul>
                        <li>{"You can play your part in safeguarding your personal information by not disclosing your login password or account information to anybody unless such person is duly authorised by you. Whenever you log in as a Mi Account user on Qingping websites, particularly on somebody else's computer or on public Internet terminals, you should always log out at the end of your session."}</li>
                        <li>Qingping cannot be held responsible for lapses in security caused by third party accesses to your personal information as a result of your failure to keep your personal information private. Notwithstanding the foregoing, you must notify us immediately if there is any unauthorized use of your account by any other Internet user or any other breach of security.</li>
                        <li>Your assistance will help us protect the privacy of your personal information.</li>
                    </ul>
                    <p>RETENTION POLICY</p>
                    <p>Personal information will be held for as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws. We shall cease to retain personal information, or remove the means by which the personal information can be associated with particular individuals, as soon as it is reasonable to assume that the purpose for which that personal information was collected is no longer being served by retention of the personal information, If further processing is for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes according to the applicable laws, the data can be further retained by Qingping even if the further processing is incompatible with original purposes.</p>
                    <p>ACCESSING OTHER FEATURES ON YOUR DEVICE</p>
                    <p>Our applications may need access to certain features on your device such as Wi-Fi network status. This information is used to allow the applications to run on your device and allow you to interact with the applications. At any time you may revoke your permissions by turning these off at the device level or contacting us at <a href="mailto:privacy@cleargrass.com">privacy@cleargrass.com</a></p>
                    <p>YOU HAVE CONTROL OVER YOUR INFORMATION!</p>
                    <p>CONTROLLING SETTINGS</p>
                    <p>Qingping recognizes that privacy concerns differ from person to person. Therefore, we provide examples of ways Qingping makes available for you to choose to restrict the collection, use, disclosure or processing of your personal information and control your privacy settings:</p>
                    <ul>
                        <li>Delete the device from the device list in the Mi Home app.</li>
                    </ul>
                    <p>If you have previously agreed to us using your personal information for the abovementioned purposes, you may change your mind at any time by writing or emailing us at <a href="mailto:privacy@cleargrass.com">privacy@cleargrass.com</a>.</p>
                    <p>ACCESS, UPDATING, CORRECTING OR ERASURE YOUR PERSONAL INFORMATION</p>
                    <ul>
                        <li>You have the right to request access to and/or correction of any other personal information that we hold about you. When you update your personal information, you will be asked to verify your identity before we proceed with your request. Once we obtain sufficient information to accommodate your request for access to or correction of your personal information, we shall proceed to respond to your request within any timeframe set out under your applicable data protection laws.</li>
                        <li>A copy of your personal data collected and processed by us will be provided to you upon your request free of charge. For any extra request of the same information, we may charge a reasonable fee based on actual administrative costs according to the applicable laws.</li>
                        <li>If you would like to request access to your personal data held by us or if you believe any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible at the email address below. Email: <a href="mailto:privacy@cleargrass.com">privacy@cleargrass.com</a>.</li>
                        <li>If you are Europe Union user under GDPR, you have the right to obtain from us the restriction of processing your personal information. We shall consider the grounds regarding your restriction request. If the grounds apply to GDPR, we shall only process your personal information under applicable circumstances in GDPR and inform you before the restriction of processing is lifted.</li>
                        <li>If you are Europe Union user under GDPR, you have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you.</li>
                        <li>If you are Europe Union user under GDPR, you have the right to receive your personal information in a structured, commonly used format and transmit the information to another data controller.</li>
                    </ul>
                    <p>WITHDRAWAL OF CONSENT</p>
                    <ul>
                        <li>You may withdraw your consent for the collection, use and/or disclosure of your personal information in our possession or control by submitting a request. This may be done by sending request to <a href="mailto:privacy@cleargrass.com">privacy@cleargrass.com</a>. We will process your request within a reasonable time from when the request was made, and thereafter not collect, use and/or disclose your personal information as per your request.</li>
                        <li>{'Please recognize that your withdrawal of consent could result in certain legal consequences. Depending on the extent of your withdrawal of consent for us to process your personal information, it may mean that you will not be able to enjoy "Mi Temperature and Humidity Monitor" software and services.'}</li>
                    </ul>
                    <p>TRANSFER OF PERSONAL INFORMATION OUTSIDE OF YOUR JURISDICTION</p>
                    <p>To the extent that we may need to transfer personal information outside of your jurisdiction, whether to our affiliated companies or Third Party Service Providers, we shall do so in accordance with the applicable laws. In particular, we will ensure that all transfers will be in accordance with requirements under your applicable local data protection laws by putting in place appropriate safeguards. You will have the right to be informed of the appropriate safeguards taken by Qingping for this transfer of your personal information</p>
                    <p>Qingping may use overseas facilities operated and controlled by Qingping to process or back up your personal information. Currently, Qingping has data centres in Beijing, United States, Singapore, Russia and Germany. These overseas jurisdictions may or may not have in place data protection laws which are substantially similar to that in your home jurisdiction. We may transfer to and store your personal information at our overseas facilities. However, this does not change any of our commitments to safeguard your personal information in accordance with this Privacy Policy.</p>
                    <p>MISCELLANEOUS</p>
                    <p>MINORS</p>
                    <p>We consider it the responsibility of parents to monitor their children’s use of our products and services. Nevertheless, it is our policy not to require personal information from minors or offer to send any promotional materials to persons in that category.</p>
                    <p>Qingping does not seek or intend to seek to receive any personal information from minors. Should a parent or guardian have reasons to believe that a minor has provided Qingping with personal information without their prior consent, please contact us to ensure that the personal information is removed and the minor unsubscribes from any of the applicable Qingping services.</p>
                    <p>ORDER OF PRECEDENCE</p>
                    <p>If you have agreed to our applicable User Agreements, in the event of inconsistency between such User Agreements and this Privacy Policy, such User Agreements shall prevail.</p>
                    <p>UPDATES TO THE PRIVACY POLICY</p>
                    <p>We keep our Privacy Policy under regular review and may update this privacy policy to reflect changes to our information practices. If we make material changes to our Privacy Policy, we will notify you by email (sent to the e-mail address specified in your account) or post the changes on all the Qingping websites or through our software, so that you may be aware of the information we collect and how we use it. Such changes to our Privacy Policy shall apply from the effective date as set out in the notice or on the website. We encourage you to periodically review this page for the latest information on our privacy practices. Your continued use of products and services on the websites, mobile phones and/or any other device will be taken as acceptance of the updated Privacy Policy. We will seek your fresh consent before we collect more personal information from you or when we wish to use or disclose your personal information for new purposes.</p>
                    <p>DO I HAVE TO AGREE TO ANY THIRD PARTY TERMS AND CONDITIONS?</p>
                    <p>Our Privacy Policy does not apply to products and services offered by a third party. Qingping products and services may include third parties’ products, services and links to third parties’ websites. When you use such products or services, they may collect your information too. For this reason, we strongly suggest that you read the third party’s privacy policy as you have taken time to read ours. We are not responsible for and cannot control how third parties use personal information which they collect from you. Our Privacy Policy does not apply to other sites linked from our services.</p>
                    <p><b>OUR APPROACH TO MANAGE YOUR PERSONAL INFORMATION UNDER GDPR</b></p>
                    <p>If you are Europe Union user under GDPR, Qingping will provide systematic approach to manage personal data deeply engages our people, management processes and information systems by applying a risk management methodology. According to the GDPR, for instance, (1) Qingping set up a Data Protection Officer (DPO) in charge the data protection, and the contact of DPO is <a href="mailto:dpo@cleargrass.com">dpo@cleargrass.com</a>; (2) Qingping designate a representative in Europe, and the contact of representative is <a href="mailto:representative@cleargrass.com">representative@cleargrass.com</a>;（3）procedure like data protection impact assessment (DPIA).</p>
                    <p>CONTACT US</p>
                    <p>{'If you have any comments or questions about this Privacy Policy or any questions relating to Qingping’s collection, use or disclosure of your personal information, please contact our Data Protection Officer at the address below referencing "Privacy Policy":'}</p>
                    <p>Qingping Technology (Beijing) Co., Ltd.</p>
                    <p>Room 401, Block B, Fangheng Times Square, No.10 Wangjing Street, Chaoyang District, Beijing 100102</p>
                    <p>China</p>
                    <p>Email: <a href="mailto:privacy@cleargrass.com">privacy@cleargrass.com</a></p>

                    <p>Thank you for taking the time to understand our Privacy Policy!</p>
                </div>
                <Footer location={location} />
            </div>
        );
    }
}

export default Privacy;
