import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Sthird.module.scss'

class Sthird extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setState({
      init: null,
    })
  }
  render() {
    const { locale } = this.props
    return (
      <div ref="Sthird">
        <div className={s.Sthird}>
          <div className={s.wrap}>
            <div
              className={locale.indexOf('US') > -1 ? s.text_en : s.text}
              ref="text"
            >
              <div className={s.large}>
                <FormattedMessage
                  id="chicken_second_title"
                  values={{ br: '' }}
                />
              </div>

              <div className={s.normal}>
                <FormattedMessage id="chicken_second_desc" />
              </div>

              <ul className={s.functions}>
                <li className={s.function_item}>
                  <i className={s.icon1}></i>
                  <FormattedMessage id="chicken_second_fun1" />
                </li>
                <li className={s.function_item}>
                  <i className={s.icon2}></i>
                  <FormattedMessage id="chicken_second_fun2" />
                </li>
                <li className={s.function_item}>
                  <i className={s.icon3}></i>
                  <FormattedMessage id="chicken_second_fun3" />
                </li>
                <li className={s.function_item}>
                  <i className={s.icon4}></i>
                  <FormattedMessage id="chicken_second_fun4" />
                </li>
                <li className={s.function_item}>
                  <i className={s.icon5}></i>
                  <FormattedMessage id="chicken_second_fun5" />
                </li>
                <li className={s.function_item}>
                  <i className={s.icon6}></i>
                  <FormattedMessage id="chicken_second_fun6" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SThird = connect(mapStateToProps)(Sthird)

export default SThird
