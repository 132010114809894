import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import s from './Psecond.module.scss'

class Psecond extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
    // this.refs.Pfifth.style.backgroundSize = '1920px';
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Psecond.getBoundingClientRect()
    if (this.refs.Psecond && clientHeight >= textRect.bottom) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 0)
    }
    if (this.refs.Psecond && clientHeight >= textRect.bottom) {
      setTimeout(() => {
        this.refs.iconList.style.opacity = 1
        this.refs.iconList.style.top = '0'
      }, 0)
    }
  }
  render() {
    return (
      <div className={s.Psecond} ref="Psecond">
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <span className={s.large}>
              <FormattedMessage id="frog_sixth_title" values={{ br: '' }} />
            </span>
            <span className={s.little}>
              <FormattedMessage id="frog_sixth_desc" />
            </span>
          </div>
          <div className={s.iconList} ref="iconList">
            <ul>
              <li>
                <i className={s.second_wifi} />
                <FormattedMessage
                  id="frog_sixth_wifi"
                  values={{ br: <br /> }}
                />
              </li>
              <li>
                <i className={s.second_lora} />
                <FormattedMessage
                  id="frog_sixth_lora"
                  values={{ br: <br /> }}
                />
              </li>
              <li>
                <i className={s.second_nbiot} />
                <div className={s.bottomText}>
                  <FormattedMessage id="frog_sixth_nb" />
                  <sup className={s.footnote}>
                    <a href="#footnote-4" aria-label="脚注4">
                      4
                    </a>
                  </sup>
                  <FormattedMessage
                    id="frog_sixth_nb_2"
                    values={{ br: <br /> }}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Psecond
