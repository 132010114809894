import React, { Component } from 'react';

import s from './airPrivacy.module.scss'

class iotPrivacy extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // init top hide
            display: false
        };
    }
    componentDidMount() {
        document.getElementsByTagName('html')[0].style.fontSize = 41.4 + 'px'
    }
    render() {
        return (
            <div className={s.iotPrivacy}>
                <div>
                    <p><b className={s.pt18}>PRIVACY POLICY</b></p>
                    <p><b className={s.pt12}>Updated! </b></p>
                    <p>Our Privacy Policy was updated on august 7, 2021. We have revised the Privacy Policy so that from this date onwards, this Privacy Policy can provide privacy details on how we manage your personal information for Qingping Iot products and services.</p>
                    <p>Please take a moment to familiarize yourself with our privacy practices and let us know if you have any questions. </p>
                    <p>OUR COMMITMENT TO YOU</p>
                    <p>This Privacy Policy sets out how Qingping Technology (Beijing) Co., Ltd. and its affiliated companies ("Qingping Technology", "we", "our" or "us") collect, use, disclose, process and protect any information that you give us when you use Qingping Technology products and services. Should we ask you to provide certain information by which you can be identified when using Qingping Technology products and services, it will only be used in accordance with this Privacy Policy and/or our terms and conditions for users.</p>
                    <p>The Privacy Policy is designed with you in mind, and it is important that you have a comprehensive understanding of our personal information collection and usage practices, as well as full confidence that ultimately, you have control of any personal information provided to Qingping Technology. </p>
                    <p>In this Privacy Policy, "personal information" means information that can be used to identify an individual, either from that information alone or from that information combined with other information Qingping Technology has access about that individual. </p>
                    <p>By using Qingping Iot products and services, you are deemed to have read, acknowledged and accepted all the provisions stated here in the Privacy Policy, including any changes we may make from time to time. We pledge to protect the privacy, confidentiality, and safety of your personal information by following applicable laws (including your local data protection laws). At the same time, we also promise that our staff and agents will fulfill these obligations. </p>
                    <p>Lastly, we would like to give our users the best experience possible. If you have any questions about the data processing practices outlined in this Privacy Policy, please contact our Data Protection Officer at <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> so that we can best see to your needs. We would be happy to address them directly. </p>
                    <p className={s.pt135}>WHAT INFORMATION IS COLLECTED AND HOW DO WE USE IT?</p>
                    <p className={s.pt12}>TYPES OF INFORMATION COLLECTED</p>
                    <p>We will ask you for personal information that is necessary for us to successfully provide you with our services. If you do not provide us with this personal information, we may not be able to provide our products or services to you. </p>
                    <p>We may collect the following types of information (which may or may not be personal information): </p>

                    <ul>
                        <li><b>Information you provided to us or uploaded.</b>(including your contact information): We may collect any or all personal information you provided to us, such as mobile phone number, IMEI, Android ID, software installation list, MAC address, Location, clipboard content, email address, third-party login information, feedback and any other information you provided to us.</li>
                        <li><b>Device information</b>: We may collect information about your device, including the name, firmware version and MAC address of your device.</li>
                        <li><b>Third-party push SDK (Xiaomi Push, Huawei Push, OPPO Push, Tencent Bugly, Facebook SDK, WeChat SDK).</b>: to send status information of the device from the app.</li>
                        <li><b>Third-party payment SDK (Alipay, WeChat Payment).</b>: To provide payment services in the app.</li>
                        <li><b>Login information</b>: Information about your use of certain features, applications and websites. Examples include cookie and other anonymous identifier technologies, IP addresses, temporary message history and standard system logs.</li>
                        <li><b>Feedback information</b>: When you use the feedback feature, we may collect the error log, and the contact details and feedback you provide.</li>
                    </ul>
                    <p className={s.pt12}>HOW THE PERSONAL INFORMATION IS USED</p>
                    <p>Personal information is collected in order to provide you with products and/or services as well as to ensure legal compliance on our part with applicable laws. You hereby consent that we may process and disclose personal information to our affiliated companies (which are in the communications, social media, technology, and cloud businesses), Third Party Service Providers (defined below) for the purposes stated in this Privacy Policy. </p>
                    <p>We may use your personal information for the following purposes: </p>
                    <ul>
                        <li>Providing, processing, maintaining, improving and developing our goods and/or services to you, including after-sales and customer support and for services on your device or through our websites.</li>
                        <li>Communicating with you regarding your device, services, or any other common queries (e.g., updates, customer support, information related to our activities, and notifications). </li>
                    </ul>
                    <p>Here are more details on how we use your information (which may include personal information).</p>
                    <ul>
                        <li><b>Show equipment information. </b>After adding equipment, the reading information of related equipment is displayed in "Qingping Iot"app .</li>
                        <li><b>Provide push service. </b>The device you added will be used to provide Qingping's push service to send the status information of the device from app. You can turn this feature off at any time by changing the notification options under Settings.</li>
                        <li><b>Provice payment service. </b>The value-added product information you selected in the app will be used to provide third-party payment services, so that you can complete the purchase of value-added products from the app.</li>
                        <li><b>Mobile phone user feedback. </b>The feedback you choose to provide is extremely valuable to help Qingping improve our service. In order to track the feedback you choose to provide, Qingping will use the personal information you provide to contact you and keep records.</li>
                        <li><b>Carry out promotional activities. </b>If you participate in the lucky draw, contest or similar promotion through Qingping's official website or other social media platforms, we may use the personal information provided by you to manage these projects. </li>
                    </ul>
                    <p className={s.pt12}>DIRECT MARKETING</p>
                    <ul>
                        <li>We may use your email address and mobile phone number to provide you with promotional materials related to the products and services of Qingping Company and its partners. We strictly abide by the data protection law of your place and require independent and explicit permission, so we will only use your personal information with your consent or when you express no objection. You have the right to terminate our use of personal data planned for direct promotion. If you no longer want to receive certain types of emails, you can unsubscribe from the unsubscribe link at the bottom of each email. We will not send your personal information to our business partners for direct promotion. </li>
                    </ul>
                    <p className={s.pt12}>COOKIES AND OTHER TECHNOLOGIES (ONLY WHEN BROWSING HTTPS://QINGPING.CO/)</p>
                    <ul>
                        <li><b>What type of information is collected and how is it used?</b>Technologies such as cookies, tags, and scripts are used by Qingping Technology and our Third Party Service Providers. These technologies are used in analyzing trends, administering the site, tracking users' movements around the website and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis. </li>
                        <li><b>Log Files: </b>Like most websites, we collect specific information and store it in the log files. This information may include Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data. We do not link this automatically collected data to other information we gather about you. </li>
                        <li><b>Advertising: </b>We partner with our Third Party Service Providers to either display advertising on our website or to manage our advertising on other sites. Our Third Party Service Provider may use technologies such as cookies to gather information about your activities on this site and other sites in order to provide you advertising based upon your browsing activities and interests. </li>
                        <li><b>Mobile Analytics: </b>Within some of our mobile applications we use mobile analytics software to allow us to better understand the functionality of our Mobile Software on your phone. This software may record information such as how often you use the application, the events that occur within the application, aggregated usage, performance data, and where crashes occur within the application. We do not link the information we store within the analytics software to any personal information you submit within the mobile application. </li>
                        <li><b>Local Storage – HTML5/Flash: </b>We use Local Storage Objects (LSOs) such as HTML5 or Flash to store content and preferences. Third parties with whom we partner to provide certain features on our Sites or to display advertising based upon your web browsing activity also use HTML5 or Flash cookies to collect and store information. Various browsers may offer their own management tool for removing HTML5 LSOs. </li>
                    </ul>
                    <p className={s.pt135}>WHO DO WE SHARE YOUR INFORMATION WITH?</p>
                    <p>We do not sell any personal information to third parties. </p>
                    <p>We may disclose your personal information on occasion to third parties (as described below) in order to provide the products or services that you have requested. </p>
                    <p>We may disclose your personal information to third-party service providers and affiliated companies described below. In each case described in this section, you can be assured that Qingping Technology will only share your personal information in accordance with your consent. You should know that when Qingping Technology shares your personal information with a Third Party Service Provider under any circumstance described in this section, Qingping Technology will contractually specify that the third party is subject to practices and obligations to comply with applicable local data protection laws. Qingping Technology will contractually ensure compliance by any Third Party Service Providers with the privacy standards that apply to them in your home jurisdiction.</p>
                    <p className={s.pt12}>SHARING WITH OUR GROUP AND THIRD PARTY SERVICE PROVIDERS</p>
                    <p>In order to operate successfully and provide you with the full functionality of our products and services, we may occasionally disclose your personal information to other Qingping Technology affiliates or with our third-party service providers (our mailing centers, delivery services providers, telecommunications companies, data centers, data storage facilities, customer service providers, advertising and promotional service providers, and agents representing Qingping Technology). Such Third Party Service Providers would be processing your personal information on Qingping Technology's behalf or for one or more of the purposes listed above. If you no longer wish to allow us to share this information, please contact us at <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> </p>
                    <p className={s.pt12}>SHARING WITH OTHERS</p>
                    <p>Qingping Technology may disclose your personal information without further consent when required under applicable law.</p>
                    <p className={s.pt12}>INFORMATION NOT REQUIRING CONSENT</p>
                    <ul>
                        <li>We may share anonymized information and statistics in aggregate form with third parties for business purposes, for example with advertisers on our website, we may share them trends about the general use of our services, such as the number of customers in certain demographic groups who purchased certain products or who carried out certain transactions. </li>
                        <li>In order to avoid any suspicion, Qingping Technology may collect, use, or disclose your personal information without your consent when expressly permitted by local data protection laws (to, for example, comply with a subpoena). We may also disclose your information without your consent if we believe in good faith that it must be disclosed in order to protect our rights, your safety, or the safety of others; investigate fraud; or respond to government requests. </li>
                    </ul>
                    <p className={s.pt135}>SECURITY SAFEGUARDS</p>
                    <p className={s.pt12}>QINGPING TECHNOLOGY'S SECURITY MEASURES</p>
                    <p>We are committed to keeping your personal information secure. In order to prevent unauthorized access, disclosure or other similar risks, we have put in place reasonable physical, electronic and managerial procedures to safeguard and secure the information we collect from your product. We will take all reasonable measures to protect your personal information. </p>
                    <p>Your personal information is all stored on safe servers and is protected in controlled facilities. We will classify your data based on importance and sensitivity and ensure that your personal data is protected at the highest level of security. We will ensure that staff and third-party service providers who access such information to provide you with services and products shoulder the strict contractual obligation of maintaining confidentiality. If they fail to fulfill such obligations, then we will impose disciplinary actions or terminate cooperation. In short, we will periodically review our information collection, storage, and handing practices, including physical security measures, to prevent any unauthorized access and use. </p>
                    <p>We will take all possible measures to protect your personal information. You should, however, be aware that the Internet is not always secure. Because of this, we cannot guarantee the security or integrity of any personal information when it is being transmitted online in either direction. </p>
                    <p className={s.pt12}>WHAT YOU CAN DO </p>
                    <ul>
                        <li>You can play your part in safeguarding your personal information by not disclosing your login password or account information to anybody unless such person is duly authorized by you. </li>
                        <li>Qingping Technology cannot be held responsible for lapses in security caused by third party accesses to your personal information as a result of your failure to keep your personal information private. Notwithstanding the foregoing, you must notify us immediately if there is any unauthorized use of your account by any other Internet user or any other breach of security. </li>
                        <li>Your assistance will help us protect the privacy of your personal information.</li>
                    </ul>
                    <p className={s.pt135}>RETENTION POLICY</p>
                    <p>We will retain personal information (if necessary) for fulfilling the purpose for which it was collected or for complying with applicable legal requirements or permissions. As long as we can reasonably infer that a retention of personal information cannot fulfill the purpose for which it was collected, and we no longer need to retain the information for legal reasons, dispute resolution, enforcement of our agreements, or any other business-related purpose, we will cease to retain such personal information, or we will eliminate any ways in which such personal information may be associated with specific individuals. </p>
                    <p className={s.pt135}>ACCESSING OTHER FEATURES ON YOUR DEVICE</p>
                    <p>Our applications may access some features on your devices, such as Wi-Fi network status, and other features. Such information is used to allow running of those applications on your devices and your interactions with our applications. You can turn off such applications at the device level or contact us at privacy@cleargrass.com to revoke permissions at any time.</p>
                    <p className={s.pt135}>You have control over your information!</p>
                    <p className={s.pt12}>CONTROLLING SETTINGS</p>
                    <p>Qingping Technology recognizes that privacy concerns differ from person to person. Therefore, we provide examples of ways Qingping Technology makes available for you to choose to restrict the collection, use, disclosure or processing of your personal information and control your privacy settings:</p>
                    <ul>
                        <li>Log into or log out your Qingping account;</li>
                        <li>Register and unregister a device;</li>
                    </ul>
                    <p>If you have previously agreed to our using of your personal information for the aforementioned purposes, you may change your mind at any time by writing to or emailing us at <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> </p>
                    <p className={s.pt12}>ACCESSING, UPDATING, OR CORRECTING YOUR PERSONAL INFORMATION</p>
                    <ul>
                        <li>You have the right to request access to and/or correction of any other personal information that we hold about you. When you update your personal information, you will be asked to verify your identity before your request is processed. Once we have a sufficient amount of information, we will be able to process your request to either access or make corrections to personal information. We will respond to your request within the period specified by applicable data protection laws.</li>
                        <li>We normally provide such services for free but reserve the right to charge a reasonable fee for your data access request.</li>
                        <li>If you wish to submit a request to access personal data we have about you, or if you believe that any information we have about you is incorrect or incomplete, please write us a letter or send an email to the address provided below as soon as possible. The email address is <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>。</li>
                    </ul>
                    <p className={s.pt12}>WITHDRAWAL OF CONSENT</p>
                    <ul>
                        <li>You may withdraw your consent for the collection, use and/or disclosure of your personal information in our possession or control by submitting a request. <a href="mailto:privacy@qingping.co">You can send us an email at privacy@qingping.co.</a> We will process your request within a reasonable time frame from the time the request was made, and thereafter not collect, use and/or disclose your personal information as per your request. </li>
                        <li>Please recognize that your withdrawal of consent could result in certain legal consequences. Depending on the extent of your withdrawal of consent for us to process your personal information, it may mean that you will not be able to enjoy Qingping Technology's products and services. </li>
                    </ul>
                    <p className={s.pt135}>TRANSFER OF PERSONAL INFORMATION OUTSIDE OF YOUR JURISDICTION</p>
                    <p>To the extent that we may need to transfer personal information outside of your jurisdiction, whether to our affiliated companies or Third Party Service Providers, we shall provide you with sufficient notification and obtain your authorization in accordance with applicable laws prior to doing so. We will implement appropriate security safeguards in order to ensure that all such transfers comply with applicable local data protection laws, and this will not change any of our commitments to safeguarding your personal information in accordance with this Privacy Policy. </p>
                    <p className={s.pt135}>MISCELLANEOUS</p>
                    <p className={s.pt12}>MINORS</p>
                    <ul>
                        <li>We believe that it is the responsibility of parents to supervise their children when using our products and services. Nevertheless, it is our policy not to require personal information from minors or offer to send any promotional materials to persons in that category.</li>
                        <li>Qingping Technology does not seek or intend to seek to receive any personal information from minors. Should a parent or guardian have reasons to believe that a minor has provided Qingping Technology with personal information without their prior consent, please contact us to ensure that the personal information is removed and the minor unsubscribes from any of the applicable Qingping Technology services.</li>
                    </ul>
                    <p className={s.pt12}>ORDER OF PRECEDENCE</p>
                    <p>If you have agreed to our applicable User Agreements, in the event of inconsistency between such User Agreements and this Privacy Policy, such User Agreements shall prevail.</p>
                    <p className={s.pt12}>UPDATES TO THE PRIVACY POLICY</p>
                    <p>We keep our Privacy Policy under regular review and may update this privacy policy to reflect changes to our information practices. If we make material changes to our Privacy Policy, we will notify you by email (sent to the email address specified in your account) or post the changes on all the Qingping Technology websites or notify you through your mobile device, so that you may be aware of the information we collect and how we use it. Such changes to our Privacy Policy shall apply from the effective date as set out in the notice or on the website. We encourage you to periodically review this page for the latest information on our privacy practices. Your continued use of products and services on the websites, mobile phones and/or any other device will be taken as acceptance of the updated Privacy Policy. We will seek consent from you again before we collect more personal information from you or when we wish to use or disclose your personal information for new purposes. </p>
                    <p className={s.pt135}>DO I HAVE TO AGREE TO ANY THIRD PARTY TERMS AND CONDITIONS? </p>
                    <p>Our Privacy Policy does not apply to products and services offered by a third party. Qingping Technology products and services may include third parties' products, services and links to third parties' websites. When you use such products or services, they may collect your information too. For this reason, we strongly suggest that you read the third party's privacy policy just as you have taken the time to read ours. We are not responsible for and cannot control how third parties use personal information that they collect from you. Our Privacy Policy does not apply to other websites that are linked via our services.</p>
                    <br />
                    <p>Qingping Technology (Beijing) Co., Ltd.</p>
                    <p>Address: 309, Block C, Yeqing Plaza, No.9 Wangjing North Street, Chaoyang District, Beijing</p>
                    <p>Postal Code: 100102</p>
                    <p>Email: <a href="mailto:privacy@qingping.co">privacy@qingping.co</a></p>

                    <p>Thank you for taking the time to familiarize yourself with our Privacy Policy! </p>
                </div>
            </div>
        );
    }
}

export default iotPrivacy;
