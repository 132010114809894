import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import s from './style.module.scss'
import Header from './../../header'
import Footer from './../../footer'
import common from '../../../../../locale/common'
const pages = [
  'firstPage',
  'secondPage',
  'thirdPage',
  'fourthPage',
  'fifthPage',
  'sixthPage',
  'seventhPage',
  'eighthPage',
  'ninethPage',
  'tenthPage',
  'eleventhPage',
  'twelfthPage',
  'thirtythPage',
  'fourteenthPage',
  'fifteenthPage',
  'bottomQuotes',
]

class GeckoOverviews extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
      sleepVideoStatus: false,
      replayStatus: false,
      position: false,
      hideGooseChoice: true,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    console.log('local', local)
    document.title = common['gecko_' + local]

    this.setState({ firstPage: true })
    window.addEventListener('scroll', this._handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }

  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    // const scrollTop = document.documentElement.scrollTop;
    for (let i = 0; i < pages.length; i++) {
      const page = this.refs[pages[i] + ''].getBoundingClientRect()
      if (page.top < clientHeight - 200) {
        this.setState({ [pages[i]]: true })
      } else {
        this.setState({ [pages[i]]: false })
      }
    }
  }

  //隐藏购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }

  render() {
    const {
      firstPage,
      secondPage,
      thirdPage,
      fourthPage,
      fifthPage,
      sixthPage,
      seventhPage,
      eighthPage,
      ninethPage,
      tenthPage,
      eleventhPage,
      twelfthPage,
      thirtythPage,
      fourteenthPage,
      fifteenthPage,
      bottomQuotes,
      hideGooseChoice,
    } = this.state
    const { sleepVideoStatus, replayStatus } = this.state
    const { location, locale } = this.props

    return (
      <div
        className={s.geckoBox}
        ref="box"
        onMouseOver={(e) => {
          e.persist()
          this.hideGooseChoiceFun(true, e)
        }}
      >
        <Header
          location={location}
          language={this.state.language}
          path={this.state.path}
          navPosition={this.state.position}
          hideGooseChoice={hideGooseChoice}
          hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
        />
        <div className={s.geckoOverview}>
          <div
            className={firstPage ? s.firstPage + ' ' + s.active : s.firstPage}
            ref="firstPage"
          >
            <div className={locale.indexOf('US') > -1 ? s.Pfirst_en : s.Pfirst}>
              <div className={s.text} ref="text">
                <div className={s.large}>
                  <FormattedMessage id="gecko_name" />
                </div>
                <div className={s.normal}>
                  <FormattedMessage id="gecko_desc" />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              secondPage
                ? (locale.indexOf('US') > -1 ? s.secondPage : s.secondPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.secondPage
                : s.secondPage
            }
            ref="secondPage"
          >
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <div className={s.large}>
                  <FormattedMessage id="gecko_second_title" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage
                    id="gecko_second_desc"
                    values={{ br: '' }}
                  />
                </div>
              </div>
              <div className={s.iconList} ref="iconList">
                <ul>
                  <li>
                    <i className={s.house} />
                    <FormattedMessage id="gecko_second_house" />
                  </li>
                  <li>
                    <i className={s.mall} />
                    <FormattedMessage id="gecko_second_mall" />
                  </li>
                  <li>
                    <i className={s.hotel} />
                    <FormattedMessage id="gecko_second_hotel" />
                  </li>
                </ul>
                <ul>
                  <li>
                    <i className={s.hospital} />
                    <FormattedMessage id="gecko_second_hospital" />
                  </li>
                  <li>
                    <i className={s.warehouse} />
                    <FormattedMessage id="gecko_second_warehouse" />
                  </li>
                  <li>
                    <i className={s.factory} />
                    <FormattedMessage id="gecko_second_factory" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={thirdPage ? s.thirdPage + ' ' + s.active : s.thirdPage}
            ref="thirdPage"
          >
            <div className={s.wrap} id="topnote-1">
              <div className={s.text} ref="text">
                <div className={s.large}>
                  <FormattedMessage id="gecko_third_title" />
                </div>
                <div className={s.thirdDesc}>
                  <FormattedMessage
                    id="gecko_third_desc"
                    values={{
                      br: (
                        <sup className={s.footnote}>
                          <a href="#footnote-1" aria-label="脚注1">
                            1
                          </a>
                        </sup>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              fourthPage
                ? (locale.indexOf('US') > -1 ? s.fourthPage_en : s.fourthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.fourthPage_en
                : s.fourthPage
            }
            ref="fourthPage"
          >
            <div className={s.wrap}>
              <div className={s.left}>
                <h2>
                  <FormattedMessage
                    id="gecko_fourth_title"
                    values={{ br: '' }}
                  />
                </h2>
                <h3>
                  <FormattedMessage id="gecko_fourth_desc" />
                </h3>
              </div>
            </div>
          </div>
          <div
            className={
              fifthPage
                ? (locale.indexOf('US') > -1 ? s.fifthPage_en : s.fifthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.fifthPage_en
                : s.fifthPage
            }
            ref="fifthPage"
          >
            <div className={s.wrap}>
              <h2>
                <FormattedMessage id="gecko_fifth_title" />
              </h2>
              <h3>
                <FormattedMessage id="gecko_fifth_desc" />
              </h3>
              <div className={s.table}>
                <ul>
                  <li className={s.subtitle}>
                    <FormattedMessage id="gecko_table_title" />
                  </li>
                </ul>
                <ul>
                  <li>
                    <div className={s.column1}>
                      <FormattedMessage id="gecko_table_a" />
                      {/* <sup>
                        <a href="#footnote-2" aria-label="脚注2">
                          2
                        </a>
                      </sup> */}
                    </div>
                    <div className={s.column2}>
                      <FormattedMessage
                        id="gecko_table_b"
                        values={{ br: <br /> }}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={s.column1} style={{ height: '20px' }}>
                      <FormattedMessage id="gecko_table_c" />
                    </div>
                    <div className={s.column2} style={{ height: '20px' }}>
                      <FormattedMessage id="gecko_table_d" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={s.column1}
                      style={{ height: '20px', borderBottom: 'none' }}
                    >
                      <FormattedMessage id="gecko_table_e" />
                    </div>
                    <div
                      className={s.column2}
                      style={{ height: '20px', borderBottom: 'none' }}
                    >
                      <FormattedMessage id="gecko_table_f" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={
              sixthPage
                ? (locale.indexOf('US') > -1 ? s.sixthPage : s.sixthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.sixthPage
                : s.sixthPage
            }
            ref="sixthPage"
          >
            <div className={s.wrap} id="topnote-2">
              <h2>
                <FormattedMessage id="gecko_sixth_title" />
              </h2>
              <div className={s.nb_iot}>
                <div className={s.nb_iot_icon}>&nbsp;</div>
                <div className={s.text}>
                  <FormattedMessage
                    id="gecko_sixth_desc"
                    values={{
                      br: (
                        <sup>
                          <a href="#footnote-2" aria-label="脚注2">
                            2
                          </a>
                        </sup>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              seventhPage
                ? (locale.indexOf('US') > -1
                    ? s.seventhPage_en
                    : s.seventhPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.seventhPage_en
                : s.seventhPage
            }
            ref="seventhPage"
          >
            <div className={s.wrap}>
              <div className={s.iot_logo}></div>
              <h2>
                <FormattedMessage
                  id="gecko_seventh_title"
                  values={{ br: '' }}
                />
              </h2>
              <h3>
                <FormattedMessage
                  id="gecko_seventh_desc"
                  values={{ br: <br /> }}
                />
              </h3>
              <h4>
                <FormattedMessage id="gecko_seventh_app_a" />
                <a
                  href="https://qingpingiot.com/app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="gecko_seventh_app_b" />
                </a>
                <FormattedMessage id="gecko_seventh_app_c" />
                <a
                  href="https://qingpingiot.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="gecko_seventh_app_d" />
                </a>
                <FormattedMessage id="gecko_seventh_app_e" />
              </h4>
            </div>
          </div>
          <div
            className={
              eighthPage
                ? (locale.indexOf('US') > -1 ? s.eighthPage_en : s.eighthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.eighthPage_en
                : s.eighthPage
            }
            ref="eighthPage"
          >
            <div className={s.wrap}>
              <h2>
                <FormattedMessage id="gecko_eighth_title" />
              </h2>
              <h3>
                <FormattedMessage id="gecko_eighth_desc" />
              </h3>
              <div className={s.notification_types}>
                <ul>
                  <li>
                    <div className={s.push}></div>
                    <p>
                      <FormattedMessage id="gecko_eighth_t1" />
                    </p>
                  </li>
                  <li>
                    <div className={s.phone}></div>
                    <p>
                      <FormattedMessage id="gecko_eighth_t2" />
                    </p>
                  </li>
                  <li>
                    <div className={s.text}></div>
                    <p>
                      <FormattedMessage id="gecko_eighth_t3" />
                    </p>
                  </li>
                  <li style={{ marginRight: 0 }}>
                    <div className={s.email}></div>
                    <p>
                      <FormattedMessage id="gecko_eighth_t4" />
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={
              ninethPage
                ? (locale.indexOf('US') > -1 ? s.ninethPage_en : s.ninethPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.ninethPage_en
                : s.ninethPage
            }
            ref="ninethPage"
          >
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <span className={s.large}>
                  <FormattedMessage id="gecko_nineth_title" />
                </span>
              </div>
              <div
                className={s.imgList}
                style={{
                  marginTop: locale.indexOf('US') > -1 ? '30px' : '70px',
                }}
                ref="imgList"
              >
                <ul>
                  <li>
                    <FormattedMessage id="p_seventh_img_1" />
                    <i
                      className={
                        locale.indexOf('US') > -1
                          ? s.nineth_img_1_en
                          : locale.indexOf('TW') > -1
                          ? s.nineth_img_1_tw
                          : s.nineth_img_1
                      }
                    />
                  </li>
                  <li>
                    <FormattedMessage id="ph_co2_eleven_desc" />
                    <i
                      className={
                        locale.indexOf('US') > -1
                          ? s.nineth_img_2_en
                          : locale.indexOf('TW') > -1
                          ? s.nineth_img_2_tw
                          : s.nineth_img_2
                      }
                    />
                  </li>
                </ul>
              </div>
              <div
                className={locale.indexOf('US') > -1 ? s.normal_en : s.normal}
              >
                <FormattedMessage id="gecko_nineth_desc" values={{ br: '' }} />
              </div>
            </div>
          </div>
          <div
            className={
              tenthPage
                ? (locale.indexOf('US') > -1 ? s.tenthPage_en : s.tenthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.tenthPage_en
                : s.tenthPage
            }
            ref="tenthPage"
          >
            <div className={s.wrap}>
              <div className={s.left}>
                <h2>
                  <FormattedMessage id="gecko_tenth_title" />
                </h2>
                <h3>
                  <FormattedMessage id="gecko_tenth_desc" />
                </h3>
              </div>
            </div>
          </div>
          <div
            className={
              eleventhPage
                ? (locale.indexOf('US') > -1
                    ? s.eleventhPage_en
                    : s.eleventhPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.eleventhPage_en
                : s.eleventhPage
            }
            ref="eleventhPage"
          >
            <div className={s.wrap}>
              <div className={s.right}>
                <h2>
                  <FormattedMessage id="gecko_eleventh_title" />
                </h2>
                <h3>
                  <FormattedMessage id="gecko_eleventh_desc" />
                </h3>
              </div>
            </div>
          </div>

          <div
            className={
              twelfthPage
                ? (locale.indexOf('US') > -1
                    ? s.twelfthPage_en
                    : s.twelfthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.twelfthPage_en
                : s.twelfthPage
            }
            ref="twelfthPage"
          >
            <div className={s.wrap} id="topnote-3">
              <h2>
                <FormattedMessage id="gecko_twelfth_title" />
              </h2>
              <h3>
                <FormattedMessage
                  id="gecko_twelfth_desc"
                  values={{
                    br: (
                      <sup>
                        <a href="#footnote-3" aria-label="脚注3">
                          3
                        </a>
                      </sup>
                    ),
                  }}
                />
              </h3>
            </div>
          </div>
          <div
            className={
              thirtythPage
                ? (locale.indexOf('US') > -1
                    ? s.thirtythPage_en
                    : s.thirtythPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.thirtythPage_en
                : s.thirtythPage
            }
            ref="thirtythPage"
          >
            <div className={s.wrap}>
              <h2>
                <FormattedMessage
                  id="gecko_thirtyth_title"
                  values={{ br: <br /> }}
                />
              </h2>
              <h3>
                <FormattedMessage
                  id="gecko_thirtyth_desc"
                  values={{ link: '', br: '' }}
                />
                <a
                  href="https://developer.qingping.co"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  developer.qingping.co
                </a>
              </h3>
            </div>
          </div>
          <div
            className={
              fourteenthPage
                ? (locale.indexOf('US') > -1
                    ? s.fourteenthPage_en
                    : s.fourteenthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.fourteenthPage_en
                : s.fourteenthPage
            }
            ref="fourteenthPage"
          >
            <div className={s.wrap}>
              <h2>
                <FormattedMessage id="gecko_fourteenth_title" />
              </h2>
              <h3>
                <FormattedMessage id="gecko_fourteenth_desc" />
              </h3>
            </div>
          </div>
          <div
            className={
              fifteenthPage
                ? (locale.indexOf('US') > -1
                    ? s.fifteenthPage_en
                    : s.fifteenthPage) +
                  ' ' +
                  s.active
                : locale.indexOf('US') > -1
                ? s.fifteenthPage_en
                : s.fifteenthPage
            }
            ref="fifteenthPage"
          >
            <div className={s.wrap}>
              <h2>
                <FormattedMessage
                  id="gecko_fifteenth_title"
                  values={{ br: '' }}
                />
              </h2>
              <h3>
                <FormattedMessage id="gecko_fifteenth_desc" />
              </h3>
            </div>
          </div>
          <div
            className={
              bottomQuotes ? s.bottomQuotes + ' ' + s.active : s.bottomQuotes
            }
            ref="bottomQuotes"
          >
            <div className={s.quotes_list}>
              <ol>
                <li id="footnote-1">
                  <a href="#topnote-1" style={{display:'none'}}></a>
                  <FormattedMessage id="gecko_explain_01" />
                </li>
                <li id="footnote-2">
                  <a href="#topnote-2" style={{display:'none'}}></a>
                  <FormattedMessage id="gecko_explain_03" />
                </li>
                <li id="footnote-3">
                  <a href="#topnote-3" style={{display:'none'}}></a>
                  <FormattedMessage id="gecko_explain_04" />
                </li>
              </ol>
            </div>
          </div>
        </div>

        <Footer location={location} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const GeckoOverview = connect(mapStateToProps)(GeckoOverviews)

export default GeckoOverview
