import React, { Component } from 'react'

import s from './Privacy.module.scss'

class EnGprivacy extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
      display: false,
    }
  }
  render() {
    return (
      <div className={s.box}>
        <div className={s.privacy}>
          <p>
            <b style={{ display: 'block', textAlign: 'center', margin: 20 }}>
              PRIVACY POLICY
            </b>
          </p>
          <p>
            <b>Updated!</b>
          </p>

          <p>
            Our Privacy Policy was updated on November 20, 2020. We have
            revamped the Privacy Policy front and back so that from this date
            onwards, this Privacy Policy can provide privacy details on how we
            manage your personal information for the "Qingping Motion & Light
            Sensor" software and services provided by Qingping Technology
            (Beijing) Co., Ltd. (hereinafter referred to as "Qingping ")
          </p>

          <p>
            Please take a moment to familiarize yourself with our privacy
            practices and let us know if you have any questions.
          </p>

          <p>OUR COMMITMENT TO YOU</p>

          <p>
            This Privacy Policy sets out how Qingping and its affiliated
            companies ("we", "our" or "us") collect, use, disclose, process and
            protect any information that you give us when you use "Qingping
            Motion & Light Sensor" software and services. Should we ask you to
            provide certain information by which you can be identified when
            using "Qingping Motion & Light Sensor" software and services, it
            will only be used in accordance with this Privacy Policy and/or our
            terms and conditions for users.
          </p>

          <p>
            The Privacy Policy is designed with you in mind, and it is important
            that you have a comprehensive understanding of our personal
            information collection and usage practices, as well as full
            confidence that ultimately, you have control of any personal
            information provided to Qingping.
          </p>

          <p>
            In this Privacy Policy, "personal information" means information
            that can be used to identify an individual, either from that
            information alone or from that information combined with other
            information Qingping has access about that individual.
          </p>

          <p>
            By using"Qingping Motion & Light Sensor" software and services, you
            are deemed to have read, acknowledged and accepted all the
            provisions stated here in the Privacy Policy, including any changes
            we may make from time to time. In order to comply with applicable
            laws, including local data protection legislation (e.g. General Data
            Protection Regulation in Europe Union), we will specifically seek
            prior explicit consent to the particular processing (e.g. automated
            individual decision-making) of special categories of personal data.
            We are committed to protecting the privacy, confidentiality and
            security of your personal information by complying with applicable
            laws, including your local data protection legislation. We are
            equally committed to ensuring that all our employees and agents
            uphold these obligations.
          </p>

          <p>
            Ultimately, what we want is the best for all our users. Should you
            have any concerns with our data handling practice as summarised in
            this Privacy Policy, please contact our Data Protection Officer at{' '}
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> to
            address your specific concerns. We will be happy to address them
            directly.
          </p>

          <p>WHAT INFORMATION IS COLLECTED AND HOW DO WE USE IT?</p>
          <p>TYPES OF INFORMATION COLLECTED</p>

          <p>
            In order to provide our services to you, we will ask you to provide
            personal information that is necessary to provide those services to
            you. If you do not provide your personal information, we may not be
            able to provide you with our products or services.
          </p>

          <p>
            We will only collect the information that is necessary for its
            specified, explicit and legitimate purposes and not further
            processed in a manner that is incompatible with those purposes. We
            may collect the following types of information (which may or may not
            be personal information):
          </p>

          <ul>
            <li style={{ listStyleType: 'desc' }}>
              <b>Account information</b>: We may collect information about your
              Mi Account, including Mi ID, nickname, and aforementioned
              information the Mi Accounts the device is shared with.
            </li>

            <li style={{ listStyleType: 'desc' }}>
              <b>Device information</b>: We may collect information about your
              device, including the name, model, ID, ID, serial number, firmware
              version number, hardware version number, installation location
              (e.g., living room), firmware version and MAC address of your
              device.
            </li>

            <li style={{ listStyleType: 'desc' }}>
              <b>Device usage information</b>: We may collect motion and ambient
              light detected by the sensor, and on/off status of the device.
            </li>

            <li style={{ listStyleType: 'desc' }}>
              <b>Network information</b>: We may collect network-related
              information, including signal strength of BLE, MAC address,
              current and previous status of the device in the network.
            </li>

            <li style={{ listStyleType: 'desc' }}>
              <b>Information about Bluetooth device</b>: information about other
              devices (e.g. Qingping Temp & RH Monitor Lite) that are connected
              to the network via the device, including the model, DID, name,
              status, Bluetooth signal strength, MAC address of Bluetooth
              devices.
            </li>

            <li style={{ listStyleType: 'desc' }}>
              <b>Feedback related information</b>: When you use the feedback
              feature, we may collect the error log, and the contact details and
              feedback descriptions you provide.
            </li>
          </ul>

          <p>HOW THE PERSONAL INFORMATION IS USED</p>

          <p>
            Personal information is collected for providing services and / or
            products to you, and legal compliance on our part under applicable
            laws. You hereby consent that we may process and disclose personal
            information to our affiliated companies (which are in the
            communications, social media, technology and cloud businesses),
            Third Party Service Providers (defined below) for the purposes
            stated in this Privacy Policy.
          </p>
          <p>
            We may use your personal information for the following purposes:
          </p>
          <ul>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              Providing, processing, maintaining, improving and developing our
              goods and/or services to you, including after-sales and customer
              support and for services on your device or through our websites.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              Communicating with you regarding your device, services, or any
              other common queries (e.g., updates, customer support, information
              related to our activities, and notifications).
            </li>

            <li style={{ marginLeft: 15 }}>
              Here are more details on how we use your information (which may
              include personal information):
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              <b>To access gateway</b>: When Bluetooth gateway is enabled, using
              Mi Beacon protocol, nearby Mi Bluetooth devices in this account
              can connect to the Internet via the device. For this purpose, we
              will collect information about your Mi account and Bluetooth
              devices. To connect Bluetooth devices to the Internet, we need to
              connect your gateway device to the Internet first. For this
              purpose, we will collect information about your device and
              network.
            </li>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              <b>To upgrade firmware</b>: You can upgrade your device firmware
              to the latest version to enjoy various new features. For this
              purpose, we will collect your device's firmware version number
            </li>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              <b>To collect user feedback</b>: The feedback you provide is
              extremely valuable in helping us improve our services. In order to
              track your feedback, Qingping Technology may use the personal
              information you provide in order to contact you and to keep
              records
            </li>
          </ul>

          <p>DIRECT MARKETING</p>
          <p>
            We may use your email address and Mi Account ID to provide marketing
            materials to you relating to goods and services of Qingping and our
            business partners which offer network, mobile applications and cloud
            products and services. We will only so use your personal data after
            we have obtained your consent and involve a clear affirmative action
            or indication of no objection in accordance with local data
            protection laws, which may require separate explicit consent. You
            have the right to opt out of our proposed use of your personal data
            for direct marketing. If you no longer wish to receive certain types
            of email communications, you may send email to
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> . We
            will not transfer your personal data to our business partners for
            use by our business partners in direct marketing.
          </p>

          <p>
            COOKIES AND OTHER TECHNOLOGIES(ONLY WHEN BROWSING
            HTTPS://QINGPING.CO/)
          </p>
          <ul>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              <b>What type of information is collected and how is it used?</b>{' '}
              Technologies such as cookies, tags, and scripts are used by
              Qingping Technology and our Third Party Service Providers. These
              technologies are used in analyzing trends, administering the site,
              tracking users' movements around the website and to gather
              demographic information about our user base as a whole. We may
              receive reports based on the use of these technologies by these
              companies on an individual as well as aggregated basis.
            </li>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              <b>Log Files</b>: Like most websites, we collect specific
              information and store it in the log files. This information may
              include Internet protocol (IP) addresses, browser type, Internet
              service provider (ISP), referring/exit pages, operating system,
              date/time stamp, and/or clickstream data. We do not link this
              automatically collected data to other information we gather about
              you.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              <b>Advertising</b>: We partner with our Third Party Service
              Providers to either display advertising on our website or to
              manage our advertising on other sites. Our Third Party Service
              Provider may use technologies such as cookies to gather
              information about your activities on this site and other sites in
              order to provide you advertising based upon your browsing
              activities and interests. If you wish to not have this information
              used for the purpose of serving you interest-based ads, you may
              send messages to privacy@qingping.co to unsubscribe this.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              <b>Mobile Analytics</b>: Within some of our mobile applications we
              use mobile analytics software to allow us to better understand the
              functionality of our Mobile Software on your phone. This software
              may record information such as how often you use the application,
              the events that occur within the application, aggregated usage,
              performance data, and where crashes occur within the application.
              We do not link the information we store within the analytics
              software to any personal information you submit within the mobile
              application.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              <b>Local Storage – HTML5/Flash</b>: We use Local Storage Objects
              (LSOs) such as HTML5 or Flash to store content and preferences.
              Third parties with whom we partner to provide certain features on
              our Sites or to display advertising based upon your web browsing
              activity also use HTML5 or Flash cookies to collect and store
              information. Various browsers may offer their own management tool
              for removing HTML5 LSOs.
            </li>
          </ul>

          <p>WITH WHOM WE SHARE YOUR INFORMATION</p>
          <p>We do not sell any personal information to third parties.</p>
          <p>
            We may disclose your personal information on occasion to third
            parties (as described below) in order to provide the products or
            services that you have requested.
          </p>
          <p>
            Disclosure may be made to Third Party Service Providers and
            affiliated companies listed in this section below. In each case
            described in this section, you can be assured that Qingping will
            only share your personal information in accordance with your
            consent. Your consent to Qingping will engage sub-processors for the
            processing of your personal information. You should know that when
            Qingping shares your personal information with a Third Party Service
            Provider under any circumstance described in this section, Qingping
            will contractually specify that the third party is subject to
            practices and obligations to comply with applicable local data
            protection laws. Qingping will contractually ensure compliance by
            any Third Party Service Providers with the privacy standards that
            apply to them in your home jurisdiction.
          </p>

          <p>SHARING WITH OUR GROUP AND THIRD PARTY SERVICE PROVIDERS</p>
          <p>
            In order to conduct business operations smoothly in providing you
            with the full capabilities of our products and services, we may
            disclose your personal information from time to time to other
            Qingping affiliated companies, or our third party service providers
            which are our mailing houses, delivery service providers,
            telecommunications companies, data centres, data storage facilities,
            customer service providers, advertising and marketing service
            providers, and Qingping’s representatives. Such Third Party Service
            Providers would be processing your personal information on
            Qingping’s behalf or for one or more of the purposes listed above.
            If you no longer wish to allow us to share this information, please
            contact us at{' '}
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>.
          </p>

          <p>SHARING WITH OTHERS</p>
          <p>
            Qingping may disclose your personal information without further
            consent when required under applicable law.
          </p>

          <p>INFORMATION NOT REQUIRING CONSENT</p>
          <ul>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              We may share anonymized information and statistics in aggregate
              form with third parties for business purposes, for example with
              advertisers on our website, we may share them trends about the
              general use of our services, such as the number of customers in
              certain demographic groups who purchased certain products or who
              carried out certain transactions.
            </li>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              In order to avoid any suspicion, Qingping may collect, use, or
              disclose your personal information without your consent when
              expressly permitted by local data protection laws (to, for
              example, comply with a subpoena). We may also disclose your
              information without your consent if we believe in good faith that
              it must be disclosed in order to protect our rights, your safety,
              or the safety of others; investigate fraud; or respond to
              government requests.
            </li>
          </ul>

          <p>SECURITY SAFEGUARDS</p>
          <p>QINGPING’S SECURITY MEASURES</p>
          <p>
            We are committed to ensuring that your personal information is
            secure. In order to prevent unauthorised access, disclosure or other
            similar risks, we have put in place reasonable physical, electronic
            and managerial procedures to safeguard and secure the information we
            collect from your using of Qingping products and services and on
            Qingping websites. We will use all reasonable efforts to safeguard
            your personal information.
          </p>

          <p>
            All your personal information is stored on secure servers that are
            protected in controlled facilities. We classify your data based on
            importance and sensitivity, and ensure that your personal
            information has the highest security level. We make sure that our
            employees and Third Party Service Providers who access the
            information to help provide you with our products and services are
            subject to strict contractual confidentiality obligations and may be
            disciplined or terminated if they fail to meet such obligations. We
            have special access controls for cloud based data storage as well.
            All in all, we regularly review our information collection, storage
            and processing practices, including physical security measures, to
            guard against any unauthorized access and use.
          </p>

          <p>
            We will take all practicable steps to safeguard your personal
            information. However, you should be aware that the use of the
            Internet is not entirely secure, and for this reason we cannot
            guarantee the security or integrity of any personal information
            which is transferred from you or to you via the Internet.
          </p>
          <p>WHAT YOU CAN DO</p>
          <ul>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              You can play your part in safeguarding your personal information
              by not disclosing your login password or account information to
              anybody unless such person is duly authorised by you. Whenever you
              log in as a Mi Account user on Qingping websites, particularly on
              somebody else's computer or on public Internet terminals, you
              should always log out at the end of your session.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              Qingping cannot be held responsible for lapses in security caused
              by third party accesses to your personal information as a result
              of your failure to keep your personal information private.
              Notwithstanding the foregoing, you must notify us immediately if
              there is any unauthorized use of your account by any other
              Internet user or any other breach of security.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              Your assistance will help us protect the privacy of your personal
              information.
            </li>
          </ul>
          <p>RETENTION POLICY</p>
          <p>
            We will retain personal information (if necessary) for fulfilling
            the purpose for which it was collected or for complying with
            applicable legal requirements or permissions. As long as we can
            reasonably infer that a retention of personal information cannot
            fulfill the purpose for which it was collected, and we no longer
            need to retain the information for legal reasons, dispute
            resolution, enforcement of our agreements, or any other
            business-related purpose, we will cease to retain such personal
            information, or we will eliminate any ways in which such personal
            information may be associated with specific individuals.
          </p>

          <p>
            All the personal information on the device will be erased when you
            reset your device to factory default settings. To implement a
            hard-reset function, after the device is turned on, press and hold
            the button until the orange light flashes, that is, the reset is
            successful. Beware that hard-reset can’t be undo, so you have to
            make sure that you don’t need the information any more.
          </p>

          <p>ACCESSING OTHER FEATURES ON YOUR DEVICE</p>
          <p>
            Our applications may need access to certain features on your device
            such as Wi-Fi network status. This information is used to allow the
            applications to run on your device and allow you to interact with
            the applications. At any time you may revoke your permissions by
            turning these off at the device level or contacting us at{' '}
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>
          </p>

          <p>YOU HAVE CONTROL OVER YOUR INFORMATION!</p>
          <p>CONTROLLING SETTINGS</p>
          <p>
            Qingping recognizes that privacy concerns differ from person to
            person. Therefore, we provide examples of ways Qingping makes
            available for you to choose to restrict the collection, use,
            disclosure or processing of your personal information and control
            your privacy settings:
          </p>
          <ul>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              Bind/unbind equipment
            </li>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              Log in or log out of MI account
            </li>
          </ul>

          <p>
            If you have previously agreed to us using your personal information
            for the abovementioned purposes, you may change your mind at any
            time by writing or emailing us at{' '}
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>.
          </p>

          <p>
            ACCESS, UPDATING, CORRECTING OR ERASURE YOUR PERSONAL INFORMATION
          </p>
          <ul>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              You have the right to request access to and/or correction of any
              other personal information that we hold about you. When you update
              your personal information, you will be asked to verify your
              identity before we proceed with your request. Once we obtain
              sufficient information to accommodate your request for access to
              or correction of your personal information, we shall proceed to
              respond to your request within any timeframe set out under your
              applicable data protection laws.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              A copy of your personal data collected and processed by us will be
              provided to you upon your request free of charge. For any extra
              request of the same information, we may charge a reasonable fee
              based on actual administrative costs according to the applicable
              laws.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              If you would like to request access to your personal data held by
              us or if you believe any information we are holding on you is
              incorrect or incomplete, please write to or email us as soon as
              possible at the email address below. Email:{' '}
              <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              If you are Europe Union user under General Data Protection
              Regulation (GDPR), you have the right to obtain from us the
              erasure of your personal information. We shall consider the
              grounds regarding your erasure request and take reasonable steps,
              including technical measures, if the grounds apply to GDPR.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              If you are Europe Union user under GDPR, you have the right to
              obtain from us the restriction of processing your personal
              information. We shall consider the grounds regarding your
              restriction request. If the grounds apply to GDPR, we shall only
              process your personal information under applicable circumstances
              in GDPR and inform you before the restriction of processing is
              lifted.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              If you are Europe Union user under GDPR, you have the right not to
              be subject to a decision based solely on automated processing,
              including profiling, which produces legal effects concerning you
              or similarly significantly affects you.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              If you are Europe Union user under GDPR, you have the right to
              receive your personal information in a structured, commonly used
              format and transmit the information to another data controller.
            </li>
          </ul>

          <p>WITHDRAWAL OF CONSENT</p>
          <ul>
            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              You may withdraw your consent for the collection, use and/or
              disclosure of your personal information in our possession or
              control by submitting a request. This may be done by sending
              request to{' '}
              <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>. We
              will process your request within a reasonable time from when the
              request was made, and thereafter not collect, use and/or disclose
              your personal information as per your request.
            </li>

            <li style={{ listStyleType: 'desc', marginLeft: 15 }}>
              Please recognize that your withdrawal of consent could result in
              certain legal consequences. Depending on the extent of your
              withdrawal of consent for us to process your personal information,
              it may mean that you will not be able to enjoy "Qingping Motion &
              Light Sensor" software and services.
            </li>
          </ul>

          <p>TRANSFER OF PERSONAL INFORMATION OUTSIDE OF YOUR JURISDICTION</p>
          <p>
            To the extent that we may need to transfer personal information
            outside of your jurisdiction, whether to our affiliated companies or
            Third Party Service Providers, we shall do so in accordance with the
            applicable laws. In particular, we will ensure that all transfers
            will be in accordance with requirements under your applicable local
            data protection laws by putting in place appropriate safeguards. You
            will have the right to be informed of the appropriate safeguards
            taken by Qingping for this transfer of your personal information
          </p>

          <p>
            Qingping may use overseas facilities operated and controlled by
            Qingping to process or back up your personal information. Currently,
            Qingping has data centres in Beijing, United States, Singapore and
            Germany. If you are Europe Union user under GDPR, your personal
            information will be stored in the German server. These overseas
            jurisdictions may or may not have in place data protection laws
            which are substantially similar to that in your home jurisdiction.
            We may transfer to and store your personal information at our
            overseas facilities. However, this does not change any of our
            commitments to safeguard your personal information in accordance
            with this Privacy Policy.
          </p>

          <p>MISCELLANEOUS MINORS</p>
          <p>
            We consider it the responsibility of parents to monitor their
            children’s use of our products and services. Nevertheless, it is our
            policy not to require personal information from minors or offer to
            send any promotional materials to persons in that category.
          </p>
          <p>
            Qingping does not seek or intend to seek to receive any personal
            information from minors. Should a parent or guardian have reasons to
            believe that a minor has provided Qingping with personal information
            without their prior consent, please contact us to ensure that the
            personal information is removed and the minor unsubscribes from any
            of the applicable Qingping services.
          </p>

          <p>ORDER OF PRECEDENCE</p>
          <p>
            If you have agreed to our applicable User Agreements, in the event
            of inconsistency between such User Agreements and this Privacy
            Policy, such User Agreements shall prevail.
          </p>

          <p>UPDATES TO THE PRIVACY POLICY</p>
          <p>
            We keep our Privacy Policy under regular review and may update this
            privacy policy to reflect changes to our information practices. If
            we make material changes to our Privacy Policy, we will notify you
            by email (sent to the e-mail address specified in your account) or
            post the changes on all the Qingping websites or through our
            software, so that you may be aware of the information we collect and
            how we use it. Such changes to our Privacy Policy shall apply from
            the effective date as set out in the notice or on the website. We
            encourage you to periodically review this page for the latest
            information on our privacy practices. Your continued use of products
            and services on the websites, mobile phones and/or any other device
            will be taken as acceptance of the updated Privacy Policy. We will
            seek your fresh consent before we collect more personal information
            from you or when we wish to use or disclose your personal
            information for new purposes.
          </p>

          <p>DO I HAVE TO AGREE TO ANY THIRD PARTY TERMS AND CONDITIONS?</p>
          <p>
            Our Privacy Policy does not apply to products and services offered
            by a third party. Qingping products and services may include third
            parties’ products, services and links to third parties’ websites.
            When you use such products or services, they may collect your
            information too. For this reason, we strongly suggest that you read
            the third party’s privacy policy as you have taken time to read
            ours. We are not responsible for and cannot control how third
            parties use personal information which they collect from you. Our
            Privacy Policy does not apply to other sites linked from our
            services.
          </p>

          <p>
            <b>OUR APPROACH TO MANAGE YOUR PERSONAL INFORMATION UNDER GDPR</b>
          </p>
          <p>
            If you are Europe Union user under GDPR, Qingping will provide
            systematic approach to manage personal data deeply engages our
            people, management processes and information systems by applying a
            risk management methodology. According to the GDPR, for instance,
            (1) Qingping set up a Data Protection Officer (DPO) in charge the
            data protection, and the contact of DPO is{' '}
            <a href="mailto:dpo@qingping.co">dpo@qingping.co</a>; (2) Qingping
            designate a representative in Europe, and the contact of
            representative is{' '}
            <a href="mailto:representative@qingping.co">
              representative@qingping.co
            </a>
            ;（3）procedure like data protection impact assessment (DPIA).
          </p>

          <p>CONTACT US</p>
          <p>
            If you have any comments or questions about this Privacy Policy or
            any questions relating to Qingping’s collection, use or disclosure
            of your personal information, please contact our Data Protection
            Officer at the address below referencing "Privacy Policy":
          </p>
          <p>
            Qingping Technology (Beijing) Co., Ltd.
            <br />
            309, Block C, Yeqing Plaza, No.9 Wangjing North Street, Chaoyang
            District, Beijing
            <br />
            China
            <br />
            Email: <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>
            <br />
          </p>
          <p>Thank you for taking the time to understand our Privacy Policy!</p>
        </div>
      </div>
    )
  }
}

export default EnGprivacy
