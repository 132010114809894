import React, { Component } from 'react';
import { connect } from 'react-redux';
import s from './qing_ping_iot.module.scss';
import common from '../../../../locale/common'
class qingPingIot extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      position: false
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    window.location = 'https://www.qingpingiot.com';
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['qing_ping_iot_' + local]
    
    let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US';
        break;
      case 'zh':
        browserLanguage = 'zh-CN';
        break;
      default:
        browserLanguage = 'en-US';
    }
    this.setState({
      language: browserLanguage
    });
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  handleScroll () {
    const textRect = this.refs.air.getBoundingClientRect();
    if (textRect.top < -50 && this.refs.air) {
      this.setState({
        position: true
      });
    } else {
      this.setState({
        position: false
      });
    }
  }
  render () {
    return (
      <div className={s.iot} ref="air">
        {/* <Header location={location} language={this.state.language} navPosition={this.state.position} path={path} />
        <IotContent />
        <Footer location={location} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let qingPingiot = connect(mapStateToProps)(qingPingIot);

export default qingPingiot;
