import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../header';
import Footer from '../footer';
import s from './aboutUs.module.scss'
import common from '../../../../locale/common'
import { FormattedMessage } from 'react-intl'

class AboutUs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      position: false
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['about_us_' + local]
    let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US';
        break;
      case 'zh':
        browserLanguage = 'zh-CN';
        break;
      default:
        browserLanguage = 'en-US';
    }
    this.setState({
      language: browserLanguage
    });
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  handleScroll () {
    const textRect = this.refs.aboutUs.getBoundingClientRect();
    // console.log(textRect.top);
    if (textRect.top < -50 && this.refs.aboutUs) {
      this.setState({
        position: true
      });
    } else {
      this.setState({
        position: false
      });
    }
  }
  render () {
    const { location } = this.props;
    const path = this.props.match.path;
    return (
      <div className={s.box}>
        <Header location={location} language={this.state.language} path={path} navPosition={this.state.position} />
        <div className={s.AboutUs} ref="aboutUs">
          <p className={s.title}><b><FormattedMessage id="about_title" /></b></p>
          <p><FormattedMessage id="about_one" /></p>
          <p><FormattedMessage id="about_two" /></p>
          <p><FormattedMessage id="about_three" /></p>
          <p><FormattedMessage id="about_four" /></p>
          <p><FormattedMessage id="about_five" /></p>
          <p><FormattedMessage id="about_six" /></p>
        </div>
        <Footer location={location} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let Aboutus = connect(mapStateToProps)(AboutUs);

export default Aboutus;
