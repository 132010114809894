import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import s from './Ssecond.module.scss'

const issafariBrowser =
  /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
class Ssecond extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Ssecond.getBoundingClientRect()
    if (this.refs.Ssecond && clientHeight >= textRect.bottom) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div className={s.Ssecond} ref="Ssecond">
        <div className={s.wrap}>
          <div
            className={locale.indexOf('US') > -1 ? s.text_en : s.text}
            ref="text"
          >
            <ol className={issafariBrowser ? s.ol_safari : s.ol}>
              <li id="footnote-1">
                <FormattedMessage id="frog_bottom_1" />
              </li>
              <li id="footnote-2">
                <FormattedMessage id="frog_bottom_2" />
              </li>
              <li id="footnote-3">
                <FormattedMessage id="frog_bottom_3" />
              </li>
              <li id="footnote-4">
                <FormattedMessage id="frog_bottom_4" />
              </li>
              <li id="footnote-5">
                <FormattedMessage id="frog_bottom_5" />
              </li>
            </ol>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SSecond = connect(mapStateToProps)(Ssecond)

export default SSecond
