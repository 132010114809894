import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Peighth.module.scss'

class Peighth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
    // this.refs.Peighth.style.backgroundSize = '1920px';
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Peighth.getBoundingClientRect()
    if (this.refs.Peighth && clientHeight >= textRect.bottom - 300) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 0)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div
        className={locale.indexOf('US') > -1 ? s.Peighth_en : s.Peighth}
        ref="Peighth"
      >
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <span className={s.large}>
              <FormattedMessage id="ph_co2_thirteen_title" />
            </span>
            <span className={s.little}>
              <FormattedMessage id="ph_co2_thirteen_subtitle" />
            </span>
            <span className={s.normal}>
              <FormattedMessage id="ph_co2_thirteen_desc" />
            </span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PEighth = connect(mapStateToProps)(Peighth)

export default PEighth
