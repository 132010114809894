/**
 * 计算待机时间
 * @param {string} battery 电池类型2
 * @param {number} product 产品类型
 * @param {string} netType 网络类型1
 * @param {string} reportInterval 报告间隔4
 * @param {string} temperature 温度5
 * @param {string} signal 信号强度3
 * @returns {string} 待机时间
 */

function calculateTimeFrog(
  battery,
  product = 1,
  netType,
  reportInterval,
  temperature,
  signal
) {
  // console.log(
  //   "传递来的params",
  //   battery,
  //   product,
  //   netType,
  //   reportInterval,
  //   temperature,
  //   signal,
  //   "calculateTimeFrog"
  // );
  //待机功耗
  const standbyArr = {
    lora: 0.045,
    nbiot: 0.047,
    wifi: 0.06,
  };
  //上报时长
  const reportTimeArr = {
    lora: 60,
    nbiot: 45,
    wifi: 30,
  };
  //上报功耗
  const reportPowerArr = {
    lora: 10,
    nbiot: 36,
    wifi: 70,
  };
  const standby = standbyArr[netType] || standbyArr["wifi"];
  const reportTime = reportTimeArr[netType] || reportTimeArr["wifi"];
  const reportPower = reportPowerArr[netType] || reportPowerArr["wifi"];
  //电池容量
  const batteryCapacity = battery === "li" ? 2800 : 1588;

  //电池容量比例
  let batterPercent;
  // 上报间隔
  let intervals = Number(reportInterval) * 3600;

  const temperatureMap = {
    "-10": { wifi: 0.8, nbiot: 0.8, lora: 0.7 },
    "-20": { wifi: 0.82, nbiot: 0.82, lora: 0.72 },
    0: { wifi: 0.88, nbiot: 0.88, lora: 0.78 },
    10: { wifi: 0.89, nbiot: 0.89, lora: 0.79 },
    20: { wifi: 0.9, nbiot: 0.9, lora: 0.8 },
    30: { wifi: 0.87, nbiot: 0.87, lora: 0.77 },
    40: { wifi: 0.86, nbiot: 0.86, lora: 0.76 },
    50: { wifi: 0.85, nbiot: 0.85, lora: 0.75 },
  };

  batterPercent =
    temperatureMap[temperature]?.[netType] ||
    temperatureMap[temperature]?.lora ||
    85;

  if (signal !== "normal") {
    batterPercent -= 0.05;
  }

  // console.log('待机功耗',standby,'--上报时长', reportTime , '----上报间隔',intervals,'----上报功耗' ,reportPower);
  const oneHourCost =
    (standby * (3600 - (reportTime * 3600) / intervals) +
      (reportPower * reportTime * 3600) / intervals) /
    3600;
  // console.log('每小时',oneHourCost);
  let allStandbyTime = (
    (batteryCapacity * batterPercent) /
    oneHourCost /
    24
  ).toFixed(0);

  allStandbyTime = Math.min(allStandbyTime, 900);

  return allStandbyTime;
}
export default {
  calculateTimeFrog,
};
