import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import s from './Pfirst.module.scss'

class Pfirst extends React.PureComponent {
  render() {
    const { locale } = this.props
    return (
      <div className={locale.indexOf('US') > -1 ? s.Pfirst_en : s.Pfirst}>
        <div className={s.text}>
          <div className={s.large}>
            <FormattedMessage id="ph_co2_first_title" values={{ br: '' }} />
          </div>
          <div className={s.normal}>
            <FormattedMessage
              id="ph_co2_first_desc"
              values={{ br: <br></br> }}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PFirst = connect(mapStateToProps)(Pfirst)

export default PFirst
