import React from 'react';

import s from './Dfifth.module.scss';

const Dfifth = () =>
    <div className={s.Dfifth}>
        <div className={s.title}>联动智能设备，改善室内环境舒适度</div>
        <div className={s.desc}>家庭智能硬件的好帮手</div>
        <div className={s.text}>通过米家蓝牙网关，米家温湿度传感器还可以联动其他电器，帮助改善家中舒适度。</div>
        <div className={s.small}>
            <p>* 需房间内有米家蓝牙网关设备，才能联动其它米家设备。</p>
            <p>目前带有蓝牙网关功能的米家产品有：米家床头灯、米家智能摄像机 1080P 等。</p>
            <p>更多支持米家蓝牙网关功能的设备，请咨询官方客服。</p>
        </div>
    </div>;

export default Dfifth;

