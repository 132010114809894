import React, { Component } from 'react'
import Util from '../../../../common/util'
import { FormattedMessage } from 'react-intl'

import s from './Ptimecalculator.module.scss'

import { Select, Radio } from 'antd'

const { Option } = Select

class Ptimecalculator extends React.PureComponent {
  static handleClick() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
      product: 1,
      netType: 'wifi',
      signal: 'normal',
      intervals: '1',
      temperature: '20',
      co2Collect: '30',
      standby: Util.calculateTimePhCo2('wifi', '1', '20', 'normal', 30),
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Ptimecalculator.getBoundingClientRect()
    if (this.refs.Ptimecalculator && clientHeight >= textRect.bottom - 100) {
      setTimeout(() => {
        this.refs.text.style.opacity = 0.9
        this.refs.text.style.top = '0'
      }, 0)
    }
  }

  handleChange(value, chooseItem) {
    const { netType, signal, intervals, temperature, co2Collect } = this.state
    console.log(
      netType,
      signal,
      intervals,
      temperature,
      co2Collect,
      value,
      `selected ${value}`
    )

    if (chooseItem === 'netType') {
      this.setState({
        netType: value,
        standby: Util.calculateTimePhCo2(
          value,
          intervals,
          temperature,
          signal,
          co2Collect
        ),
      })
    } else if (chooseItem === 'intervals') {
      this.setState({
        intervals: value,
        standby: Util.calculateTimePhCo2(
          netType,
          value,
          temperature,
          signal,
          co2Collect
        ),
      })
    } else if (chooseItem === 'temperature') {
      this.setState({
        temperature: value,
        standby: Util.calculateTimePhCo2(
          netType,
          intervals,
          value,
          signal,
          co2Collect
        ),
      })
    } else if (chooseItem === 'signal') {
      this.setState({
        signal: value,
        standby: Util.calculateTimePhCo2(
          netType,
          intervals,
          temperature,
          value,
          co2Collect
        ),
      })
    } else if (chooseItem === 'co2Collect') {
      this.setState({
        co2Collect: value,
        standby: Util.calculateTimePhCo2(
          netType,
          intervals,
          temperature,
          signal,
          value
        ),
      })
    }
  }

  render() {
    const {
      product,
      netType,
      signal,
      intervals,
      temperature,
      co2Collect,
      standby,
    } = this.state
    // console.log(
    //   product,
    //   netType,
    //   signal,
    //   intervals,
    //   temperature,
    //   co2Collect,
    //   standby,
    //   'standby'
    // )
    return (
      <div className={s.Ptimecalculator} ref="Ptimecalculator">
        <div className={s.title} ref="text">
          <FormattedMessage id="p_calculator_title" />
          <div className={s.subtitle}>
            <FormattedMessage id="p_calculator_subtitle" />
          </div>
        </div>
        <div className={s.chooseType}>
          <div className={s.product} style={{ marginBottom: '40px' }}>
            <div className={s.no_air_selected}></div>
            <div className={s.item1}>
              <FormattedMessage id="ph_co2_name_value" values={{ br: '' }} />
            </div>
          </div>
        </div>

        <div className={s.content}>
          <div className={s.items}>
            <div className={s.item}>
              <div className={s.item_title}>
                <FormattedMessage id="p_network_type" />
              </div>
              <Select
                defaultValue={netType}
                onChange={(value) => this.handleChange(value, 'netType')}
              >
                <Option value="wifi">Wi-Fi</Option>
                <Option value="nbiot">NB-IoT</Option>
                <Option value="lora">LoRa</Option>
              </Select>
            </div>

            <div className={s.item}>
              <div className={s.item_title}>
                <FormattedMessage id="p_network_signal" />
              </div>
              <Select
                defaultValue={signal}
                onChange={(value) => this.handleChange(value, 'signal')}
              >
                {/* <Option value="good">
                  <FormattedMessage id="p_good" />
                </Option> */}
                <Option value="normal">
                  <FormattedMessage id="p_normal" />
                </Option>
                <Option value="bad">
                  <FormattedMessage id="p_bad" />
                </Option>
              </Select>
            </div>

            <div className={s.item}>
              <div className={s.item_title}>
                <FormattedMessage id="ph_co2_eight_interval" />
              </div>
              <Select
                defaultValue={co2Collect}
                onChange={(value) => this.handleChange(value, 'co2Collect')}
              >
                <Option value="0">
                  <FormattedMessage id="ph_co2_realtime" />
                </Option>
                <Option value="10">
                  10 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="20">
                  20 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="30">
                  30 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="40">
                  40 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="50">
                  50 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="60">
                  1 <FormattedMessage id="p_hour" />
                </Option>
              </Select>
            </div>

            <div className={s.item}>
              <div className={s.item_title}>
                <FormattedMessage id="p_net_interval" />
              </div>
              <Select
                defaultValue={intervals}
                value={intervals}
                onChange={(value) => this.handleChange(value, 'intervals')}
              >
                {netType !== 'nbiot' ? (
                  <Option value="0.1666">
                    10 <FormattedMessage id="p_minutes" />
                  </Option>
                ) : null}
                {netType !== 'nbiot' ? (
                  <Option value="0.33">
                    20 <FormattedMessage id="p_minutes" />
                  </Option>
                ) : null}
                {netType !== 'nbiot' ? (
                  <Option value="0.5">
                    30 <FormattedMessage id="p_minutes" />
                  </Option>
                ) : null}
                <Option value="1">
                  1 <FormattedMessage id="p_hour" />
                </Option>
                <Option value="2">
                  2 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="3">
                  3 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="4">
                  4 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="5">
                  5 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="6">
                  6 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="7">
                  7 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="8">
                  8 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="9">
                  9 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="10">
                  10 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="11">
                  11 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="12">
                  12 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="13">
                  13 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="14">
                  14 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="15">
                  15 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="16">
                  16 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="17">
                  17 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="18">
                  18 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="19">
                  19 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="20">
                  20 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="21">
                  21 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="22">
                  22 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="23">
                  23 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="24">
                  24 <FormattedMessage id="p_hours" />
                </Option>
              </Select>
            </div>

            <div className={s.item}>
              <div className={s.item_title}>
                <FormattedMessage id="p_environment" />
              </div>
              <Select
                defaultValue={temperature}
                onChange={(value) => this.handleChange(value, 'temperature')}
              >
                <Option value="-30">-30°C</Option>
                <Option value="-20">-20°C</Option>
                <Option value="-10">-10°C</Option>
                <Option value="0">0°C</Option>
                <Option value="10">10°C</Option>
                <Option value="20">20°C</Option>
                <Option value="30">30°C</Option>
                <Option value="40">40°C</Option>
                <Option value="50">50°C</Option>
              </Select>
            </div>
          </div>

          <div className={s.cal_content}>
            <div className={s.cal_title}>
              <FormattedMessage id="p_around" />
            </div>
            <div className={s.cal_result}>
              <FormattedMessage id="p_about" />
              <span className={s.cal_num}> {standby} </span>
              <FormattedMessage id="p_day" />
            </div>
            <div className={s.desc}>
              <FormattedMessage id="p_tips" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Ptimecalculator
