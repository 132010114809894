import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../../header'
import Footer from '../../footer'
import Lfirst from '../Lfirst'
import Lsecond from '../Lsecond'
import Lthird from '../Lthird'
import Lfourth from '../Lfourth'
import Lfifth from '../Lfifth'
import Lguitar from '../Lguitar'
import Lsixth from '../Lsixth'
import Lseventh from '../Lseventh'
import Leighth from '../Leighth'
import Lnineth from '../Lnineth'
import Ltenth from '../Ltenth'
import Leleventh from '../Leleventh'

import s from './Sstyle.module.scss'
import common from '../../../../../locale/common'
class Sstyle extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      language: null,
      position: false,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['magpie_' + local]

    let browserLanguage = navigator.browserLanguage
      ? navigator.browserLanguage
      : navigator.language
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US'
        break
      case 'zh':
        browserLanguage = 'zh-CN'
        break
      default:
        browserLanguage = 'en-US'
    }
    this.setState({
      language: browserLanguage,
    })
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const textRect = this.refs.box.getBoundingClientRect()
    if (textRect.top < -50 && this.refs.box) {
      if(this.state.position) return
      this.setState({
        position: true,
      })
    } else {
      if(!this.state.position) return
      this.setState({
        position: false,
      })
    }
  }
  //隐藏Goose购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }
  render() {
    const { hideGooseChoice } = this.state
    const { location } = this.props
    const path = this.props.match.path
    return (
      <div
        className={s.box}
        ref="box"
        onMouseOver={(e) => {
          e.persist()
          this.hideGooseChoiceFun(true, e)
        }}
      >
        <div className={s.Sstyle}>
          <Header
            location={location}
            language={this.state.language}
            path={path}
            navPosition={this.state.position}
            hideGooseChoice={hideGooseChoice}
            hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
          />
          <Lfirst />
          <Lsecond />
          <Lthird />
          <Lfourth />
          <Lguitar />
          <Lfifth />
          <Lsixth />
          <Lseventh />
          <Leighth />
          <Lnineth />
          <Ltenth />
          <Leleventh />
          <Footer location={location} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SStyle = connect(mapStateToProps)(Sstyle)

export default SStyle
