import React, { Component } from 'react';

import EnSPrivacy from './en'
import EnCoSPrivacy from './en_common'
import DeSPrivacy from './de'
import RuSPrivacy from './ru'

class Pprivacy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { // init top hide
      language: 'en'
    };
  }
  componentDidMount() {
    console.log(this.props, 'this.props of index.jsx')
    if (this.props.location.search.indexOf('co') > -1){
      this.setState({
        language: 'en_co'
      })
    } else if (this.props.location.search.indexOf('en') > -1) {
      this.setState({
        language: 'en'
      })
    } else if (this.props.location.search.indexOf('ru') > -1) {
      this.setState({
        language: 'ru'
      })
    } else {
      this.setState({
        language: 'de'
      })
    }
  }

  render () {
    const { language } = this.state

    return (
      <div>
        {
          language === 'de' ? <DeSPrivacy /> : (language === 'ru' ? <RuSPrivacy /> : (language === 'en_co' ? <EnCoSPrivacy /> : <EnSPrivacy />))
        }
      </div>
    );
  }
}

export default Pprivacy;
