let browserLanguage = navigator.browserLanguage
  ? navigator.browserLanguage
  : navigator.language

if (browserLanguage.indexOf('zh') > -1) {
  if (browserLanguage.indexOf('TW') > -1) {
    browserLanguage = 'zh-TW'
  } else {
    browserLanguage = 'zh-CN'
  }
} else {
  browserLanguage = 'en-US'
}
//需注释部分
// browserLanguage = 'zh-CN'
let localLanguage = localStorage.getItem('language')

if (localLanguage === 'zh') {
  localLanguage = 'zh-CN'
} else if (localLanguage === 'en') {
  localLanguage = 'en-US'
}
if (localLanguage) {
  browserLanguage = localLanguage
}
const initialState = {
  language: browserLanguage,
}
console.log(initialState, 'initialLanguage')
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return { language: action.val }
    default:
      return state
  }
}

export default rootReducer
