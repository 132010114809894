import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Ssixth.module.scss'

class Ssixth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Ssixth.getBoundingClientRect()
    if (this.refs.Ssixth && clientHeight >= textRect.bottom - 100) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    return (
      <div ref="Ssixth">
        <div className={s.Ssixth}>
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              <div className={s.title}>
                <FormattedMessage id="parrot_five_title" />
              </div>
              <div className={s.desc}>
                <FormattedMessage id="parrot_five_desc" />
              </div>
            </div>
            <div className={s.imgBox}>
              <div className={s.pic}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SSixth = connect(mapStateToProps)(Ssixth)

export default SSixth
