
import React, { useEffect, useRef, useState } from "react";
import style from './BetterAir.module.scss';
import { FormattedMessage } from 'react-intl'


export default function Section({ locale }) {


    const items = [
        {
            classNameEn: style.one_img_en,
            classNameTw: style.one_img_tw,
            classNameDefault: style.one_img,
            messageId: "sw2_third_desc_1",

        },
        {
            classNameEn: style.two_img_en,
            classNameTw: style.two_img_tw,
            classNameDefault: style.two_img,
            messageId: "sw2_third_desc_2",

        },
        {
            classNameEn: style.third_img_en,
            classNameTw: style.third_img,
            classNameDefault: style.third_img,
            messageId: "sw2_third_desc_3",

        }
    ];

    const getClassNameByLocale = (classNameEn, classNameTw, classNameDefault) => {
        if (locale.indexOf('US') > -1) {
            return classNameEn;
        } else if (locale.indexOf('TW') > -1) {
            return classNameTw;
        } else {
            return classNameDefault;
        }
    };


    return (

        <>
            <div className={style.container}>
                <ul>

                    {/* <li>
                        <div className={getClassNameByLocale(items[0].classNameEn, items[0].classNameTw, items[0].classNameDefault)}></div>
                        <div className={style.text}><FormattedMessage id={items[0].messageId} /></div>
                    </li>
                    <li>
                        <div className={style.text} ><FormattedMessage id={items[1].messageId} /></div>
                        <div className={getClassNameByLocale(items[1].classNameEn, items[1].classNameTw, items[1].classNameDefault)}></div>

                    </li>
                    <li>
                        <div className={getClassNameByLocale(items[2].classNameEn, items[2].classNameTw, items[2].classNameDefault)}></div>
                        <div className={style.text} ><FormattedMessage id={items[2].messageId} /></div>
                    </li> */}
                    {items.map((item, index) => (
                        <li key={index}>
                            {/* 第二个 li 元素调整元素顺序 */}
                            {index === 1 ? (
                                <>
                                    <div className={style.text}><FormattedMessage id={item.messageId} /></div>
                                    <div className={getClassNameByLocale(item.classNameEn, item.classNameTw, item.classNameDefault)}></div>
                                </>
                            ) : (
                                <>
                                    <div className={getClassNameByLocale(item.classNameEn, item.classNameTw, item.classNameDefault)}></div>
                                    <div className={style.text}><FormattedMessage id={item.messageId} /></div>
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
}
