import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Sthirteenth.module.scss'

class Sthirteenth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Sthirteenth.getBoundingClientRect();
        if (clientHeight >= textRect.bottom - 200 && this.refs.Sthirteenth) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 500);
        }
    }
    render() {
        return (
            <div ref="Sthirteenth">
                <div className={s.Sthirteenth}>
                    <div className={s.wrap}>
                        <div className={s.text} ref="text">
                            <div className={s.title}>
                                <FormattedMessage id="dany_twelve_title" values={{br: <br />}} />
                            </div>
                            <div className={s.desc}>
                                <FormattedMessage id="dany_twelve_desc"
                                values={{br: <br />}} />
                            </div>
                        </div>
                        <div className={s.pics}>
                            <div className={s.item1}>
                                <div className={s.mask}></div>
                                <div className={s.pm}>
                                <FormattedMessage id="dany_twelve_item1" />
                                </div>
                            </div>
                            <div className={s.item2}>
                                <div className={s.mask}></div>
                                <div className={s.temp}>
                                <FormattedMessage id="dany_twelve_item2" />
                                </div>
                            </div>
                        </div>
                        <div className={s.memo}>
                        * <FormattedMessage id="support_mi_A"></FormattedMessage>
                        <FormattedMessage id="dany_twelve_memo" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sthirteenth;

