import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router'

import Lee from './../components/pages/mobile/index/lee'
import Home from './../components/pages/mobile/index/index'
// import DNprivacy from "../components/pages/m2p/DanyPrivacy";

const Dstyle = lazy(() =>
  import('./../components/pages/mobile/mi_temp_rh_monitor/Dstyle')
)
const Dspecifications = lazy(() =>
  import('./../components/pages/mobile/mi_temp_rh_monitor/Dspecifications')
)
const Gstyle = lazy(() =>
  import('./../components/pages/mobile/cg_temp_rh_monitor/Gstyle')
)
const Gspecifications = lazy(() =>
  import('./../components/pages/mobile/cg_temp_rh_monitor/Gspecifications')
)
const Lstyle = lazy(() =>
  import('./../components/pages/mobile/lee_temp_rh_monitor/Lstyle')
)
const Lspecifications = lazy(() =>
  import('./../components/pages/mobile/lee_temp_rh_monitor/Lspecifications')
)
const Air = lazy(() => import('./../components/pages/mobile/air'))
const QingpingPlus = lazy(() =>
  import('./../components/pages/mobile/qingping_plus')
)
const Sstyle = lazy(() => import('./../components/pages/mobile/snow/Sstyle'))
const Sspecifications = lazy(() =>
  import('./../components/pages/mobile/snow/Sspecifications')
)
const SnowProstyle = lazy(() =>
  import('./../components/pages/mobile/snowpro/overview')
)

const SnowProspecifications = lazy(() =>
  import('./../components/pages/mobile/snowpro/specifications')
)


const Danystyle = lazy(() => import('./../components/pages/mobile/dany/Sstyle'))
const Danyspecifications = lazy(() =>
  import('./../components/pages/mobile/dany/Sspecifications')
)
const Dk2style = lazy(() => import('./../components/pages/mobile/duck2/Sstyle'))
const Dk2specifications = lazy(() =>
  import('./../components/pages/mobile/duck2/Sspecifications')
)
const Hodorstyle = lazy(() =>
  import('./../components/pages/mobile/hodor/Sstyle')
)
const Hodorspecifications = lazy(() =>
  import('./../components/pages/mobile/hodor/Sspecifications')
)
const Parrotstyle = lazy(() =>
  import('./../components/pages/mobile/parrot/Sstyle')
)
const Parrotspecifications = lazy(() =>
  import('./../components/pages/mobile/parrot/Sspecifications')
)
const Chickenstyle = lazy(() =>
  import('./../components/pages/mobile/chicken/Sstyle')
)
const Chickenspecifications = lazy(() =>
  import('./../components/pages/mobile/chicken/Sspecifications')
)
const Eprivacy = lazy(() => import('./../components/pages/mobile/privacy'))
const Gprivacy = lazy(() => import('./../components/pages/mobile/Gprivacy'))
const Bprivacy = lazy(() => import('./../components/pages/mobile/Bprivacy'))
const Sprivacy = lazy(() => import('./../components/pages/mobile/Sprivacy'))
const Hprivacy = lazy(() => import('./../components/pages/mobile/Hprivacy'))
const Pprivacy = lazy(() => import('./../components/pages/mobile/Pprivacy'))
const Dprivacy = lazy(() => import('./../components/pages/mobile/Dprivacy'))
const DNprivacy = lazy(() => import('./../components/pages/m2p/DanyPrivacy'))

const Doveprivacy = lazy(() =>
  import('./../components/pages/mobile/DovePrivacy')
)
const Snowprivacy = lazy(() =>
  import('./../components/pages/mobile/SnowPrivacy')
)
const Chickenprivacy = lazy(() =>
  import('./../components/pages/mobile/ChickenPrivacy')
)
const Share = lazy(() => import('./../components/pages/mobile/share'))
const ContactUs = lazy(() => import('./../components/pages/mobile/contact'))
const Store = lazy(() => import('./../components/pages/mobile/store'))
const Pstyle = lazy(() =>
  import('./../components/pages/mobile/lora_temp_rh_monitor/Pstyle')
)
const Pspecifications = lazy(() =>
  import('./../components/pages/mobile/lora_temp_rh_monitor/Pspecifications')
)
const Cstyle = lazy(() =>
  import('./../components/pages/mobile/co2_temp_rh_monitor/Pstyle')
)
const Cspecifications = lazy(() =>
  import('./../components/pages/mobile/co2_temp_rh_monitor/Pspecifications')
)
const Wstyle = lazy(() =>
  import('./../components/pages/mobile/wall-socket-thermometer/Pstyle')
)
const Wspecifications = lazy(() =>
  import('./../components/pages/mobile/wall-socket-thermometer/Pspecifications')
)
const Fstyle = lazy(() => import('./../components/pages/mobile/frog/Pstyle'))
const Fspecifications = lazy(() =>
  import('./../components/pages/mobile/frog/Pspecifications')
)
const AirPrivacy = lazy(() =>
  import('./../components/pages/mobile/air_privacy')
)
const LeeGuitarsPrivacy = lazy(() =>
  import('./../components/pages/mobile/lee_guitars_privacy')
)
const iotPrivacy = lazy(() =>
  import('./../components/pages/mobile/iot_privacy')
)
const AirAgreement = lazy(() =>
  import('./../components/pages/mobile/air_agreement')
)
const AllPrivacy = lazy(() =>
  import('./../components/pages/mobile/all_privacy')
)
const AboutUs = lazy(() => import('./../components/pages/mobile/about_us'))
const Iot = lazy(() => import('./../components/pages/mobile/iot'))
const PresetApps = lazy(() =>
  import('./../components/pages/mobile/preset_apps')
)
const Clock = lazy(() => import('./../components/pages/mobile/clock'))
const Spstyle = lazy(() =>
  import('./../components/pages/mobile/sparrow/Overview')
)
const Spspecifications = lazy(() =>
  import('./../components/pages/mobile/sparrow/Specifications')
)
const DoveOverview = lazy(() =>
  import('./../components/pages/mobile/dove/overview')
)
const DoveSpecifications = lazy(() =>
  import('./../components/pages/mobile/dove/specifications')
)
const MiTranslator = lazy(() =>
  import('./../components/pages/pc/mi_translator')
)

const EuDoc = lazy(() => import('./../components/pages/mobile/eu_doc'))

const MagpieEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/magpie')
)
const DanyEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/dany')
)
const ChickenEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/chicken')
)
const DoveEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/dove')
)
const SparrowEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/sparrow')
)
const HodorEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/hodor')
)
const ParrotEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/parrot')
)
const GooseHEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/goose_h')
)
const Duck2EuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/duck2')
)
const Duck2CEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/duck2_c')
)
const GooseMEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/goose_m')
)
const FrogEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/frog')
)
const SnowEuDoc = lazy(() =>
  import('./../components/pages/pc/eu_doc_pages/snow')
)

const Routers = () => (
  <Suspense fallback={<div></div>}>
    <Switch>
      <Route path="/lee-guitars/app" exact component={Lee} />
      <Route path="/" exact component={Home} />
      <Route
        path="/mi-temp-rh-monitor"
        exact
        render={() => <Redirect to="/mi-temp-rh-monitor/overview" />}
      />
      <Route path="/mi-temp-rh-monitor/overview" exact component={Dstyle} />
      <Route
        path="/mi-temp-rh-monitor/specifications"
        exact
        component={Dspecifications}
      />
      <Route
        path="/temp-rh-monitor"
        exact
        render={() => <Redirect to="/temp-rh-monitor/overview" />}
      />
      <Route path="/temp-rh-monitor/overview" exact component={Gstyle} />
      <Route
        path="/temp-rh-monitor/specifications"
        exact
        component={Gspecifications}
      />
      <Route
        path="/lee-guitars-thermo-hygrometer"
        exact
        render={() => <Redirect to="/lee-guitars-thermo-hygrometer/overview" />}
      />
      <Route
        path="/lee-guitars-thermo-hygrometer/overview"
        exact
        component={Lstyle}
      />
      <Route
        path="/lee-guitars-thermo-hygrometer/specifications"
        exact
        component={Lspecifications}
      />
      <Route path="/mi-temp-rh-monitor/privacy" exact component={Eprivacy} />
      <Route path="/temp-rh-monitor/privacy" exact component={Gprivacy} />
      <Route
        path="/mi-multifunction-air-monitor"
        exact
        render={() => <Redirect to="/mi-multifunction-air-monitor/privacy" />}
      />
      <Route
        path="/mi-multifunction-air-monitor/privacy"
        exact
        component={Bprivacy}
      />
      <Route path="/plus" exact component={Air} />
      <Route
        path="/apps"
        exact
        render={() => <Redirect to="/apps/qingpingplus" />}
      />
      <Route path="/apps/qingpingplus" exact component={QingpingPlus} />
      <Route path="/air" exact component={Air} />
      <Route
        path="/temp-rh-monitor-pro-s"
        exact
        render={() => <Redirect to="/temp-rh-monitor-pro-s/overview" />}
      />
      <Route path="/temp-rh-monitor-pro-s/overview" exact component={Pstyle} />
      <Route
        path="/temp-rh-monitor-pro-s/specifications"
        exact
        component={Pspecifications}
      />
      <Route path="/co2-temp-rh-monitor/overview" exact component={Cstyle} />
      <Route
        path="/co2-temp-rh-monitor/specifications"
        exact
        component={Cspecifications}
      />
      <Route
        path="/wall-socket-thermometer/overview"
        exact
        component={Wstyle}
      />
      <Route
        path="/wall-socket-thermometer/specifications"
        exact
        component={Wspecifications}
      />

      <Route
        path="/temp-rh-monitor-pro-e"
        exact
        render={() => <Redirect to="/temp-rh-monitor-pro-e/overview" />}
      />
      <Route path="/temp-rh-monitor-pro-e/overview" exact component={Fstyle} />
      <Route
        path="/temp-rh-monitor-pro-e/specifications"
        exact
        component={Fspecifications}
      />
      <Route
        path="/air-monitor"
        exact
        render={() => <Redirect to="/air-monitor/overview" />}
      />
      <Route path="/air-monitor/overview" exact component={Sstyle} />
      <Route
        path="/air-monitor/specifications"
        exact
        component={Sspecifications}
      />
      <Route
        path="/air-monitor-2"
        exact
        render={() => <Redirect to="/air-monitor-2/overview" />}
      />
      <Route path="/air-monitor-2/overview" exact component={SnowProstyle} />
      <Route
        path="/air-monitor-2/specifications"
        exact
        component={SnowProspecifications}
      />
      <Route
        path="/air-monitor-lite"
        exact
        render={() => <Redirect to="/air-monitor-lite/overview" />}
      />
      <Route path="/air-monitor-lite/overview" exact component={Danystyle} />
      <Route
        path="/air-monitor-lite/specifications"
        exact
        component={Danyspecifications}
      />
      <Route path="/temp-rh-monitor-lite/overview" exact component={Dk2style} />
      <Route
        path="/temp-rh-monitor-lite/specifications"
        exact
        component={Dk2specifications}
      />
      <Route path="/door-window-sensor/overview" exact component={Hodorstyle} />
      <Route
        path="/door-window-sensor/specifications"
        exact
        component={Hodorspecifications}
      />
      <Route
        path="/motion-light-sensor/overview"
        exact
        component={Parrotstyle}
      />
      <Route
        path="/motion-light-sensor/specifications"
        exact
        component={Parrotspecifications}
      />
      <Route path="/bluetooth-clock/overview" exact component={Chickenstyle} />
      <Route
        path="/bluetooth-clock/specifications"
        exact
        component={Chickenspecifications}
      />
      <Route path="/air-app-and-air-monitor" exact component={Share} />
      <Route path="/air-app-and-temp-rh-monitor" exact component={Share} />
      <Route path="/air-app-and-temp-rh-barometer" exact component={Share} />
      <Route path="/contact" exact component={ContactUs} />
      <Route path="/store" exact component={Store} />
      <Route path="/air/privacy" exact component={AirPrivacy} />
      <Route path="/lee-guitars/privacy" exact component={LeeGuitarsPrivacy} />
      <Route path="/iot/privacy" exact component={iotPrivacy} />
      <Route path="/plus/privacy" exact component={AirPrivacy} />
      <Route path="/air/agreement" exact component={AirAgreement} />
      <Route path="/privacy" exact component={AllPrivacy} />
      <Route path="/about-us" exact component={AboutUs} />
      <Route path="/iot" exact component={Iot} />
      <Route path="/qingpingiot" exact component={Iot} />
      <Route
        path="/mi-translator"
        exact
        render={() => <Redirect to="/mi-translator/overview" />}
      />
      <Route path="/mi-translator/preset-apps" exact component={PresetApps} />
      <Route
        path="/bluetooth-gateway"
        exact
        render={() => <Redirect to="/bluetooth-gateway/overview" />}
      />
      <Route path="/bluetooth-gateway/overview" exact component={Spstyle} />
      <Route
        path="/bluetooth-gateway/specifications"
        exact
        component={Spspecifications}
      />
      <Route path="/bluetooth-gateway/privacy" exact component={Sprivacy} />
      <Route path="/door-window-sensor/privacy" exact component={Hprivacy} />
      <Route path="/motion-light-sensor/privacy" exact component={Pprivacy} />
      <Route path="/temp-rh-monitor-lite/privacy" exact component={Dprivacy} />
      <Route path="/air-monitor-lite/privacy" exact component={DNprivacy} />
      <Route path="/alarm-clock/privacy" exact component={Doveprivacy} />
      <Route path="/air-monitor/privacy" exact component={Snowprivacy} />
      <Route path="/bluetooth-clock/privacy" exact component={Chickenprivacy} />
      <Route
        path="/bluetooth-alarm-clock"
        exact
        render={() => <Redirect to="/bluetooth-alarm-clock/overview" />}
      />
      <Route path="/bluetooth-alarm-clock/apps" exact component={Clock} />
      <Route
        path="/bluetooth-alarm-clock/overview"
        exact
        component={DoveOverview}
      />
      <Route
        path="/bluetooth-alarm-clock/specifications"
        exact
        component={DoveSpecifications}
      />
      <Route path="/mi_translator/preset_apps" exact component={MiTranslator} />
    </Switch>

    <Route path="/eu-declarations" exact component={EuDoc}></Route>

    <Route
      path="/lee-guitars-thermo-hygrometer/eu-declaration"
      exact
      component={MagpieEuDoc}
    ></Route>
    <Route
      path="/air-monitor-lite/eu-declaration"
      exact
      component={DanyEuDoc}
    ></Route>
    <Route
      path="/bluetooth-clock/eu-declaration"
      exact
      component={ChickenEuDoc}
    ></Route>
    <Route
      path="/bluetooth-alarm-clock/eu-declaration"
      exact
      component={DoveEuDoc}
    ></Route>
    <Route
      path="/bluetooth-gateway/eu-declaration"
      exact
      component={SparrowEuDoc}
    ></Route>
    <Route
      path="/door-window-sensor/eu-declaration"
      exact
      component={HodorEuDoc}
    ></Route>
    <Route
      path="/motion-light-sensor/eu-declaration"
      exact
      component={ParrotEuDoc}
    ></Route>
    <Route
      path="/temp-rh-monitor-h/eu-declaration"
      exact
      component={GooseHEuDoc}
    ></Route>
    <Route
      path="/temp-rh-monitor-lite/eu-declaration"
      exact
      component={Duck2EuDoc}
    ></Route>
    <Route
      path="/temp-rh-monitor-lite-c/eu-declaration"
      exact
      component={Duck2CEuDoc}
    ></Route>
    <Route
      path="/temp-rh-monitor/eu-declaration"
      exact
      component={GooseMEuDoc}
    ></Route>
    <Route
      path="/temp-rh-monitor-pro-e/eu-declaration"
      exact
      component={FrogEuDoc}
    ></Route>
    <Route
      path="/air-monitor/eu-declaration"
      exact
      component={SnowEuDoc}
    ></Route>
  </Suspense>
)

export default Routers
