import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import s from './Dnav.module.scss'

class Dnav extends React.PureComponent {
  handleClick() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    let path = window.location.pathname
    if (this.props.path === path) {
      this.props.clickCallback && this.props.clickCallback()
      return
    }
    this.setState({
      // 修改state
      display: !this.state.display,
    })
  }
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
      display: false,
      THsub: false,
      AIRsub: false,
    }
    this.handleClick = this.handleClick.bind(this)
  }
  // handleClickSub(type) {
  //     if (type === 'TH') {
  //         this.setState({ // 修改state
  //             THsub: !this.state.THsub,
  //             AIRsub: false
  //         });
  //     } else {
  //         this.setState({ // 修改state
  //             THsub: false,
  //             AIRsub: !this.state.AIRsub
  //         });
  //     }
  // }
  render() {
    const { locale } = this.props
    // const pathArr = this.props.path.split('/');
    return (
      <div className={s.nav}>
        <ul>
          <li className={s.title}>
            <span>
              <FormattedMessage id="temp_rh_press" />
            </span>
          </li>
          <li className={s.sub}>
            <Link
              to="/temp-rh-monitor/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_goose} />
              <FormattedMessage id="goose_lite" />
            </Link>
          </li>
          <li className={s.sub}>
            <Link
              to="/temp-rh-monitor-lite/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_dk2} />
              <FormattedMessage id="duck2" />
            </Link>
          </li>
          <li className={s.sub}>
            <Link
              to="/temp-rh-monitor-pro-s/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_lora} />
              <FormattedMessage id="pheasant" />
            </Link>
          </li>
          <li className={s.sub}>
            <Link
              to="/temp-rh-monitor-pro-e/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_frog} />
              <FormattedMessage id="frog" values={{ br: '' }} />
            </Link>
          </li>
          <li className={s.sub}>
            <Link
              to="/wall-socket-thermometer/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_gecko} />
              <FormattedMessage id="gecko_name" />
            </Link>
          </li>
          <li className={s.line_s}>
            <Link
              to="/lee-guitars-thermo-hygrometer/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_magpie} />
              <FormattedMessage id="magpie" values={{ br: '' }} />
            </Link>
          </li>
          <li className={s.title}>
            <span>
              <FormattedMessage id="clock" />
            </span>
          </li>
          <li className={s.sub}>
            <Link
              to="/bluetooth-alarm-clock/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_dove} />
              <FormattedMessage id="dove" />
            </Link>
          </li>
          <li className={s.line_s}>
            <Link
              to="/bluetooth-clock/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_chicken} />
              <FormattedMessage id="chicken" />
            </Link>
          </li>
          <li className={s.title}>
            <span>
              <FormattedMessage id="air_name" />
            </span>
          </li>
          <li className={s.sub}>
            <Link to="/air-monitor/overview" onClick={() => this.handleClick()}>
              <i className={s.nav_snow} />
              <FormattedMessage id="snow" />
            </Link>
          </li>
          <li className={s.sub}>
            <Link
              to="/air-monitor-2/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_snow2} />
              <FormattedMessage id="sw2" />
            </Link>
          </li>
          <li className={s.sub}>
            <Link
              to="/air-monitor-lite/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_dany} />
              <FormattedMessage id="dany_name" />
            </Link>
          </li>
          <li className={s.sub}>
            <Link
              to="/co2-temp-rh-monitor/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_lora} />
              <FormattedMessage id="ph_co2_name_value" />
            </Link>
          </li>

          <li className={s.title}>
            <span>
              <FormattedMessage id="sensor_name" />
            </span>
          </li>
          <li className={s.sub}>
            <Link
              to="/door-window-sensor/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_hodor} />
              <FormattedMessage id="hodor_name" values={{ br: '' }} />
            </Link>
          </li>
          <li className={s.sub}>
            <Link
              to="/motion-light-sensor/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_parrot} />
              <FormattedMessage id="parrot_name" values={{ br: '' }} />
            </Link>
          </li>

          {/* <li className={s.line_s}>
                        <a
                            href="https://item.mi.com/product/9046.html"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className={s.nav_bran} />小米米家空气检测仪
                        </a>
                    </li> */}

          <li className={s.title}>
            <span>
              <FormattedMessage id="gateway" />
            </span>
          </li>
          <li className={s.line_s}>
            <Link
              to="/bluetooth-gateway/overview"
              onClick={() => this.handleClick()}
            >
              <i className={s.nav_sparrow} />
              <FormattedMessage id="sparrow" />
            </Link>
          </li>
          <li>
            <Link to="/plus" onClick={() => this.handleClick()}>
              <i className={s.nav_app} />
              <FormattedMessage id="cg_app" />
            </Link>
          </li>
          <li className={s.line_s}>
            <a
              href="https://qingpingiot.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={s.nav_iot} />
              <FormattedMessage id="qing_ping_iot" />
            </a>
            {/* <Link to="/iot" onClick={() => this.handleClick()}>
              <i className={s.nav_iot} />
              <FormattedMessage id="qing_ping_iot" />
            </Link> */}
          </li>
          <li className={s.line_s}>
            <a
              href="https://developer.qingping.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={s.nav_developer} />
              <FormattedMessage id="developer" />
            </a>
          </li>
          <li className={s.line_s}>
            <a
              href="https://cleargrass.tmall.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={s.nav_store} />
              <FormattedMessage id="store" />
            </a>
          </li>
          <li className={s.sub}>
            <Link to="/contact" onClick={() => this.handleClick()}>
              <i className={s.nav_phone} />
              <FormattedMessage id="contact_us" />
            </Link>
          </li>
        </ul>
        <div className={s.copy}>
          <span>
            <FormattedMessage id="company_name" values={{ br: '' }} />
          </span>
          {locale.indexOf('US') > -1 || locale.indexOf('TW') > -1 ? null : (
            <span>
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noopener noreferrer"
              >
                粤ICP备19156805号-1
              </a>
            </span>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let dnav = connect(mapStateToProps)(Dnav)

export default dnav
