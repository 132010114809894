import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from './../header'
import Footer from './../footer'
import s from './allPrivacy.module.scss'
import common from '../../../../locale/common'
class AllPrivacy extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      language: null,
      local: 'cn',
      position: false,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    this.setState({ local: local })
    document.title = common['privacy_' + local]

    let browserLanguage = navigator.browserLanguage
      ? navigator.browserLanguage
      : navigator.language
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US'
        break
      case 'zh':
        browserLanguage = 'zh-CN'
        break
      default:
        browserLanguage = 'en-US'
    }
    this.setState({
      language: browserLanguage,
    })
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const textRect = this.refs.privacy.getBoundingClientRect()
    // console.log(textRect.top);
    if (textRect.top < -50 && this.refs.privacy) {
      if(this.state.position) return
      this.setState({
        position: true,
      })
    } else {
      if(!this.state.position) return
      this.setState({
        position: false,
      })
    }
  }
  render() {
    const { location } = this.props
    const { local } = this.state
    const path = this.props.match.path
    const cnHtml = (
      <div>
        <p className={s.title}>
          <b>隐私政策</b>
        </p>
        <p className={s.px20}>更新！</p>
        <p>
          我们的隐私政策已于 2022 年 5 月 25
          日更新。从该日期开始，这一隐私政策能够提供有关我们如何管理您在使用所有青萍产品和服务时透露的个人信息的隐私详情。请花一些时间熟悉我们的隐私权惯例。如果您有任何问题，请告诉我们。
        </p>
        <p className={s.px20}>我们向您承诺：</p>
        <p>
          本隐私政策规定了青萍科技（北京）有限公司及关联公司（下文简称“青萍”或“我们”）如何收集、使用、披露、处理和保护您在使用我们的产品和服务时提供给我们的信息；若我们要求您提供某些信息，以便在使用青萍产品和服务时验证您的身份，我们将严格遵守本隐私政策和/或我们的用户条款来使用这些信息。
        </p>
        <p>
          本隐私政策在制定时充分考虑到您的需求；您应全面了解我们的个人资料收集和使用惯例而且确信自己最终能控制提供给青萍的个人信息，这一点至关重要。
        </p>
        <p>
          在这项隐私政策中，“个人信息”指信息本身或与青萍能够访问的个人的其他信息联系后能够识别特定个人的数据。
        </p>
        <p>
          通过使用青萍产品和服务，即表示您已阅读、认可并接受本隐私政策中所述之所有条款，包括我们随时作出的任何变更。我们承诺遵照适用法律（包括您所在地的数据保护法律）来保护您的个人信息的隐私、机密和安全。同时我们承诺我们的全体员工和代理商履行这些义务。
        </p>
        <p>
          最后，我们所希望的就是为我们的用户带来最好的体验。如果您对这一隐私政策中概括的数据处理惯例有任何疑问，请通过{' '}
          <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>{' '}
          联系我们的数据保护官，以便我们处理您的需求。
        </p>
        <p className={s.px20}>收集哪些信息以及如何使用信息</p>
        <p className={s.px18}>收集的信息类别</p>
        <p>
          为了向您提供我们的服务，我们会要求您提供向您提供服务所必需的个人信息。如果您不提供个人信息，我们可能无法向您提供我们的产品或服务。我们收集以下各类信息（无论其是否为个人信息）：
        </p>
        <ul>
          <li>
            <b>您提供给我们或上传的信息</b>
            （包括您的联系方式）：我们可能收集您提供给我们的任一或所有个人信息，例如手机号码、电子邮箱地址、第三方登录信息、反馈以及您提供给我们的任何其他信息。
          </li>
          <li>
            <b>设备相关信息</b>
            ：与您的设备相关的信息。例如手机或电脑的操作系统信息、青萍产品的传感器读数、状态、配置信息、序列号或
            MAC。
          </li>
          <li>
            <b>位置信息（仅适用于特定服务/功能）</b>：您的设备的地理位置信息。
          </li>
          <li>
            <b>登录信息</b>：与您使用某些功能、应用和网站相关的信息。例如 cookie
            和其他匿名标识符技术、IP 地址、临时消息历史和标准系统日志等。
          </li>
          <li>
            <b>您提供的反馈信息</b>
            ：在您使用用户反馈功能时，我们可能收集问题日志、您主动提供的联系方式、反馈内容。
          </li>
        </ul>
        <p className={s.px18}>这些个人信息将会被如何使用</p>
        <p>
          收集个人信息的目的在于向您提供产品和/或服务，并且保证我们遵守适用法律。您特此同意我们出于本隐私政策规定的目的处理个人信息，并向我们的关联公司（涉及通信、社交媒体、技术和云计算）、第三方服务供应商（定义如下）披露个人信息。
        </p>
        <p className={s.px18}>我们可能会将您的个人信息用于下列目的：</p>
        <ul>
          <li>
            提供、处理、维护、改善、开发我们的商品和/或提供给您的服务，包括售后服务和客户支持，以及通过设备或网站提供的服务。
          </li>
          <li>
            与您就您的设备、服务或其他日常问题（例如软件更新、客户咨询支持、我们活动的相关信息、通知）等进行交流。
          </li>
          <li>
            分析与我们产品及服务的使用相关的统计信息，以改进我们的产品和服务。
          </li>
          <li>储存并维护与您相关的信息，用于我们运营业务或履行法律义务。</li>
        </ul>
        <p>
          关于我们如何使用您的信息（其中可能包含个人信息），下面提供了更多详细信息：
        </p>
        <ul>
          <li>
            <b>展示设备信息。</b>
            在青萍软件或网站内显示您的青萍设备的读数和状态、配置等信息。
          </li>
          <li>
            <b>展示城市信息。</b>
            在青萍软件或网站内显示您指定的城市或所在城市的天气或空气质量等信息。
          </li>
          <li>
            <b>提供通知服务。</b>
            青萍软件或网站可能向您发送您的青萍设备或您指定的城市或所在城市的状态通知。您可随时通过更改设置来关闭相关功能。
          </li>
          <li>
            <b>收集用户反馈。</b>
            您提供的反馈对帮助我们改进服务而言极为珍贵。为了跟踪您选择提供的反馈，青萍能会使用您所提供的个人信息与您联系，并保留记录。
          </li>
          <li>
            <b>进行促销活动。</b>
            如果您通过青萍网站或软件或社交媒体平台参与了抽奖、比赛或类似的促销，我们可能会使用您提供的个人信息来管理这些项目。
          </li>
          <li>
            <b>直接推广。</b>
            我们可能会使用您的电子邮箱地址、手机号向您提供青萍公司及其合作伙伴的产品和服务相关的推广资料。我们严格遵守您所在地的数据保护法规，要求独立的明确许可，所以我们只会在征得您的同意或您表示不反对时使用您的个人信息。您有权终止我们使用计划用于直接推广的个人数据。如果您不再希望接收某些类别的电子邮件，您可以通过每封邮件底部的取消订阅链接来取消订阅。我们不会将您的个人信息传送给我们的商业伙伴用于直接推广。
          </li>
        </ul>
        <p className={s.px20}>Cookie 和其他技术（只在浏览青萍网站时）</p>
        <p className={s.px18}>收集哪些信息以及如何使用这些信息</p>
        <p>
          青萍和第三方服务供应商使用
          cookie、标签和脚本等技术。这些技术用于分析趋势、管理网站、追踪用户的网站活动并收集关于整个用户群的人口统计信息。我们会收到这些公司基于使用上述技术的单独和汇总报告。{' '}
        </p>
        <ul>
          <li>
            <b>日志文件：</b>
            和大部分网站一样，我们收集特定信息并保存在日志文件中。此类信息可能包括互联网协议
            (IP) 地址、浏览器类型、互联网服务供应商
            (ISP)、引用/退出页面、操作系统、日期/时间戳和点击流数据。
            我们不会将自动收集的数据链接到我们收集的关于您的其他信息之中。
          </li>
          <li>
            <b>移动分析：</b>
            在某些移动软件中，我们使用分析技术，以更好地了解我们的软件的使用情况。此类软件可能记录您使用它们的频率、软件内发生的事件、累计使用、性能数据及应用程序崩溃发生的位置。
          </li>
          <li>
            <b>本地存储 - HTML5：</b>
            我们使用本地存储对象 (LSO)，例如 HTML5
            存储内容和偏好。各种浏览器提供了各自的管理工具来删除 HTML5
            本地存储对象。
          </li>
        </ul>
        <p className={s.px18}>我们与谁共享您的信息？</p>
        <p>我们不会将任何个人信息出售给第三方。</p>
        <p>
          我们有时可能会向第三方（定义见下文）披露您的个人信息，以便提供您要求的产品或服务。
        </p>
        <p>
          我们可能会向本部分下文列出的第三方服务供应商和关联公司作出披露。在本部分所述的各种情况下，您可以放心，青萍仅会根据您的授权共享您的个人信息。您应当了解，在下文描述的任何情况下，当青萍与第三方服务供应商共享您的个人信息时，青萍会通过合同规定第三方的实践和义务，遵守适用的地方数据保护法。青萍会通过合同保证第三方服务供应商遵守您所属司法管辖区中适用于他们的隐私权标准。
        </p>
        <ul>
          <li>
            <b>与我们集团和第三方服务供应商共享信息：</b>
            为了顺利地从事商业经营，为您提供产品和服务的全部功能，我们可能向关联公司或第三方服务供应商（在线商店、送货服务供应商、电信公司、数据中心、数据存储设施、客户服务供应商、广告和推广服务供应商、代表青萍的代理，统称为“第三方服务供应商”）披露您的个人信息。此类第三方服务供应商可能代表青萍或出于上述的一项或多项目的处理您的个人信息。如果您不再希望允许我们共享这些信息，请发送电子邮件到{' '}
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>{' '}
            联系我们。
          </li>
          <li>
            <b>与其他人共享信息：</b>
            在适用法律要求时，青萍可能会不经过进一步同意而披露您的个人信息。
          </li>
        </ul>
        <p className={s.px20}>不需要同意的信息</p>
        <p>
          我们可能以汇总的形式与第三方共享匿名信息，用于商业目的；我们可能与其共享我们服务的一般使用趋势，例如在购买某些产品或从事某些交易的特定人群中的客户数量。
        </p>
        <p>
          青萍可能在地方数据保护法明确允许的情况下和范围内（例如为了遵循传票）不经您的同意而收集、使用或披露您的个人信息。我们可能会为了保护您或他人的安全、协助调查欺诈行为，或对政府要求作出回应，不经您的同意而披露您的信息。
        </p>
        <p className={s.px20}>安全保障</p>
        <p>
          我们承诺保证您的个人信息安全。为了防止未经授权的访问、披露或其他类似风险，我们落实了合理的物理、电子和管理措施流程，保护我们从您的产品和青萍网站或软件内收集的信息。我们将采取所有合理的措施保护您的个人信息。
        </p>
        <p>
          您的个人信息全都被储存在安全的服务器上，并在受控设施中受到保护。我们依据重要性和敏感性对您的数据进行分类，并且保证您的个人信息具有最高的安全等级。我们保证通过访问这些信息来帮助向您提供产品和服务的员工和第三方服务供应商具有严格的合同保密义务，如果未能履行这些义务，其将会受到纪律处分或被终止合作。总而言之，我们定期审查信息收集、储存和处理实践，包括物理安全措施，以防止任何未经授权的访问和使用。
        </p>
        <p>
          我们将采取所有可行的措施保护您的个人信息。但是，您应当意识到互联网的使用并不总是安全的，因此，我们不能保证在通过互联网双向传输时任何个人信息的安全性或完整性。
        </p>
        <p className={s.px20}>您能做什么？</p>
        <p>
          您可以通过不向任何人（除非此人经您正式授权）披露您的登录密码或账户信息，为保护您个人信息的安全发挥作用。
        </p>
        <p>
          青萍不对因您未能保持个人信息的私密性而导致第三方访问您的个人信息进而造成的安全疏漏承担责任。尽管有上述规定，如果发生互联网其他任何用户未经授权使用您账户的情况或其他任何安全漏洞，您必须立即通知我们。
        </p>
        <p>您的协助将有助于我们保护您个人信息的私密性。</p>
        <p className={s.px20}>保留政策</p>
        <p>
          当为了提供产品或服务，或为遵守法规要求而需要保留个人信息时，我们将保留您的个人信息。当保留个人信息已非必要时，我们将不再保留个人信息，或消除将个人信息和特定个人关联起来的途径。
        </p>
        <p className={s.px20}>访问您的设备上的其他功能</p>
        <p>
          我们的应用程序可能会访问您设备上的某些功能，例如 Wi-Fi
          网络状态，以及其他功能。这些信息用于允许这些应用程序在您的设备上运行，并且允许您与其互动。在任何时候，您都可以通过在设备的系统设置中关闭对应的权限来撤销许可。
        </p>
        <p>您可以控制您的信息！</p>
        <p className={s.px20}>控制设置</p>
        <p>
          青萍承认每个人对隐私权的关注各不相同，因此我们提供了一些示例供您选择，以限制青萍收集、使用、披露或处理您的个人信息，并控制您的隐私权设置：
        </p>
        <ul>
          <li>登入或登出青萍账户；</li>
          <li>绑定或解绑设备；</li>
          <li>添加或删除指定的城市；</li>
          <li>允许或禁止青萍软件或网站对您的设备进行定位。</li>
        </ul>
        <p>
          如果您之前因为上述目的同意我们使用您的个人信息，您可以随时通过书面或者向{' '}
          <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>{' '}
          发送电子邮件的方式联系我们来改变您的决定。
        </p>
        <p className={s.px20}>访问、更新或更正您的个人信息</p>
        <ul>
          <p>
            您有权要求访问和/或更正我们持有的与您有关的任何个人信息。当您更新个人信息时，在我们处理您的请求前，会要求验证您的身份。一旦我们获得充分信息，可处理您的请求以访问或更正您的个人信息时，我们将在适用数据保护法规定的时间内对您的请求做出回应。
          </p>
          <p>
            我们通常免费提供这些服务，但是保留对您的数据访问请求收取合理费用的权利。
          </p>
          <p>
            如果您希望请求访问我们持有的个人数据或者如果您认为我们持有的关于您的任何信息是不正确或不完整的，请尽快致信或给{' '}
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>{' '}
            发送电子邮件联系我们。
          </p>
        </ul>
        <p className={s.px20}>撤销同意</p>
        <ul>
          <li>
            您可以通过向
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>
            发送电子邮件来提交撤销同意收集、使用和/或披露我们掌握或控制的您的个人信息的请求。我们将会在您做出请求后的合理时间内处理您的请求，并且会根据您的请求，在此后不再收集、使用和/或披露您的个人信息。
          </li>
          <li>
            请注意，撤销同意可能会导致某些法律后果。根据您撤销同意让我们处理的您的个人信息的范围，您可能会无法享受青萍的某些或全部产品和服务。
          </li>
        </ul>
        <p className={s.px20}>将个人信息转移到您所属的司法管辖区之外</p>
        <p>
          如果我们需要将个人信息转移到您所属的司法管辖区之外，无论是转移给我们的关联公司或第三方服务供应商，我们都将按照适用法律进行。我们通过落实合适的安全保障措施，保证所有这类转移满足适用的地方数据保护法的要求。
        </p>
        <p className={s.px20}>其他规定</p>
        <p className={s.px18}>未成年人</p>
        <ul>
          <p>
            我们认为监督孩子使用我们的产品和服务是父母的责任。但是，我们的政策不要求获得未成年人的个人信息，或者向这类人群发送任何促销资料。
          </p>
          <p>
            青萍不会寻求或试图寻求接收来自未成年人的任何个人信息。如果家长或监护人有理由相信未成年人未经他们事先同意而向青萍提交了个人信息，请联系我们以确保删除此类个人信息，并保证未成年人取消订阅任何适用的青萍服务。
          </p>
        </ul>
        <p className={s.px18}>优先顺序</p>
        <p>
          如果您同意了适用的用户协议，并且此类用户协议和本隐私政策之间存在不一致，将以此类用户协议为准。
        </p>
        <p className={s.px18}>隐私政策的更新</p>
        <p>
          我们会对隐私政策进行定期审核，为反映我们处理信息的惯例的变更，我们可能会更新本隐私政策。如果我们对本隐私政策进行重大变更，我们将通过（向您账户指定的邮箱地址发送）电子邮件或在所有青萍网站公布或通过移动设备通知您，这样您可以了解我们收集的信息以及我们如何使用这些信息。此类隐私政策变化将从通知或网站规定的生效日期开始适用。我们建议您定期查阅本网页获取我们隐私权实践的最新信息。您继续使用产品和网站、手机和/或其他任何设备上的服务，将被视为接受更新的隐私政策。在我们向您收集更多的个人信息或我们希望因为新的目的使用或披露您的个人信息时，我们会再次征得您的同意。
        </p>
        <p className={s.px20}>我是否必须同意任何第三方条款与条件？</p>
        <p>
          我们的隐私政策不适用于第三方提供的产品和服务。青萍产品和服务可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。因此，我们强烈建议您花时间阅读该第三方的隐私政策，就像阅读我们的政策一样。我们不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。我们的隐私政策不适用通过我们的服务链接的其他网站。
        </p>
        <br />
        <p>
          <span>青萍科技（北京）有限公司</span>
          <br />
          <span>地址：北京市朝阳区望京北路 9 号叶青大厦 C 座 309 室</span>
          <br />
          <span>邮编：100102</span>
          <br />
          <span>
            电子邮箱：
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>
          </span>
        </p>

        <p>感谢您花时间了解我们的隐私政策！</p>
      </div>
    )
    const enHtml = (
      <div>
        <p className={s.title}>
          <b>PRIVACY POLICY</b>
        </p>
        <p className={s.px20}>Update!</p>
        <p>
          Our privacy policy was updated on May 25, 2021. From this date on,
          this privacy policy can provide privacy details about how we manage
          the personal information you disclose when using Qingping products and
          services. Please take some time to familiarize yourself with our
          privacy practices. If you have any questions, please let us know.
        </p>
        <p className={s.px20}>We promise:</p>
        <p>
          This privacy policy stipulates how Qingping Technology (Beijing) Co.,
          Ltd. and its affiliated companies (hereinafter referred to as
          "Qingping" or "we") collect, use, disclose, process and protect the
          information you provide to us when using our products and services; If
          we ask you to provide some information to verify your identity when
          using Qingping products and services, we will strictly abide by this
          privacy policy and/or our user terms and conditions to use the
          information.
        </p>
        <p>
          This privacy policy is formulated with your needs in mind; It is very
          important for you to fully understand our personal data collection and
          use practices and be sure that you can finally control the personal
          information provided to Qingping.
        </p>
        <p>
          In this privacy policy, "personal information" refers to the
          information itself or data that can identify a specific individual
          after being linked with other information of individuals that Qingping
          can access.
        </p>
        <p>
          By using Qingping products and services, you mean that you have read,
          approved and accepted all the terms stated in this privacy policy,
          including any changes we make at any time. We promise to protect the
          privacy, confidentiality and security of your personal information in
          accordance with applicable laws (including the data protection laws of
          your location). At the same time, we promise all our employees and
          agents to fulfill these obligations.
        </p>
        <p>
          Finally, what we want is to bring the best experience to our users. If
          you have any questions about the data processing practices outlined in
          this privacy policy, please contact our data protection officer
          through <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>{' '}
          so that we can handle your needs.
        </p>
        <p className={s.px20}>
          What information is collected and how will it be used
        </p>
        <p className={s.px18}>Types of information collected</p>
        <p>
          To provide our services to you, we will ask you to provide necessary
          personal information. If you do not provide personal information, we
          may not be able to provide you with our products or services. We
          collect the following types of information (whether it is personal or
          not):
        </p>
        <ul>
          <li>
            <b>Information you provided to us or uploaded</b>
            (including your contact information): We may collect any or all
            personal information you provided to us, such as phone number, email
            address, third-party login information, feedback and any other
            information you provided to us. \{' '}
          </li>
          <li>
            <b>Device related information</b>: Information related to your
            device. E.g., operating system information of your mobile phone or
            computer; Your Qingping product's serial number or MAC, readings and
            status, configuration information.
          </li>
          <li>
            <b>Location information</b> (only applicable to specific
            services/functions): Geographic location information of your device.
          </li>
          <li>
            <b>Login information</b>: Information related to your use of certain
            functions, applications and websites. E.g., cookie and other
            anonymous identifier technologies, IP addresses, temporary history,
            standard system logs, etc.
          </li>
          <li>
            <b>Feedback provided by you</b>: When you use the user feedback
            function, we may collect logs, contact information provided by you
            voluntarily, and the feedback content.
          </li>
        </ul>
        <p className={s.px18}>How will this personal information be used</p>
        <p>
          The purpose of collecting personal information is to provide you with
          products and/or services and to ensure that we comply with applicable
          laws. You hereby agree that we will handle personal information for
          the purposes specified in this privacy policy and disclose personal
          information to our affiliated companies (which involve communication,
          social media, technology and cloud computing) and third-party service
          providers (as defined below).
        </p>
        <p className={s.px18}>
          We may use your personal information for the following purposes:
        </p>
        <ul>
          <li>
            Provide, process, maintain, improve and develop our products and/or
            services to you, including after-sale service and customer support,
            as well as services provided through devices or websites.
          </li>
          <li>
            Communicate with you about your devices, services or other usual
            questions (such as software updates, customer consultation and
            support, information about our activities, and notifications).
          </li>
          <li>
            Analyze statistical information related to the use of our products
            and services to improve our products and services.
          </li>
          <li>
            Store and maintain information related to you for our business
            operations or legal obligations.
          </li>
        </ul>
        <p>
          More details about how we use your information (which may include
          personal information) are below:
        </p>
        <ul>
          <li>
            <b>Display device information. </b>
            Display the readings, status, configuration and other information of
            your Qingping products in Qingping apps or websites.
          </li>
          <li>
            <b>Show city information.</b>
            Display the weather or air quality information of the city you
            located in or designated in Qingping apps or websites.
          </li>
          <li>
            <b>Provide notification service. </b>
            Qingping software or website may send you notifications of your
            Qingping products or the city you designated or located in. You can
            turn off some or all of the notifications at any time by changing
            the settings.
          </li>
          <li>
            <b>Collect user feedback. </b>
            Your feedback is invaluable in helping us improve our services. To
            follow up on your feedback, we will use the personal information you
            provide to contact you and keep records.
          </li>
          <li>
            <b>Carry out promotional activities. </b>
            If you participate in a lottery, contest, or similar promotion
            through a Qingping website or app, or other platform, we may use the
            personal information you provided to manage the project.
          </li>
          <li>
            <b>Direct promotion. </b>
            We may use your email address and mobile phone number to provide you
            promotional materials related to the products and services of
            Qingping or its partners. We strictly abide by the data protection
            laws and regulations in your region and require independent and
            explicit permission, so we will only use your personal information
            with your consent or when you express no objection. You have the
            right to terminate our use of personal data planned for direct
            promotion. If you no longer want to receive certain types of emails,
            you can unsubscribe from the unsubscribe link at the bottom of each
            email. We will not send your personal information to our business
            partners for direct promotion.
          </li>
        </ul>
        <p className={s.px20}>
          Cookie and other technologies (only when browsing Qingping website)
        </p>
        <p className={s.px18}>
          What information is collected and how it will be used
        </p>
        <p>
          Qingping and third-party service providers use cookies, tags and
          scripts. These technologies are used to analyze trends, manage
          websites, track users' activities on the websites and collect
          demographic information about the entire user base. We will receive
          separate and summary reports from the providers based on the use of
          the above technologies.
        </p>
        <ul>
          <li>
            <b>Log file: </b>
            Like most websites, we collect specific information and save it in
            log files. Such information may include Internet Protocol (IP)
            address, browser type, Internet Service Provider (ISP),
            reference/exit page, operating system, date/timestamp and
            clickstream data. We will not link automatically-collected data to
            other information we collect about you.
          </li>
          <li>
            <b>Mobile analysis:</b>
            In some mobile apps, we use analytics technologies to better
            understand the app's usage. Such apps may record how often you use
            them, the events that occur within the apps, cumulative usage,
            performance data, and crash logs.
          </li>
          <li>
            <b>Local storage - HTML5: </b>
            We use local storage objects (LSOs), such as HTML5, to store content
            and preferences. Browsers normally provide management tools to
            delete HTML5 locally stored objects.
          </li>
        </ul>
        <p className={s.px18}>Who do we share your information with?</p>
        <p>We will not sell any personal information to third parties.</p>
        <p>
          We may disclose your personal information to a third party (as defined
          below) to provide the products or services you require.
        </p>
        <p>
          We may disclose to the third-party service providers and affiliated
          companies listed below in this section. In all the situations
          described in this section, you can rest assured that Qingping will
          only share your personal information according to your authorization.
          You should understand that under any circumstances described below,
          when Qingping shares your personal information with a third-party
          service provider, Qingping will stipulate the practices and
          obligations of the third party through the contract and abide by the
          applicable local data protection laws. Qingping will ensure that the
          third-party service providers comply with the privacy standards
          applicable to them in your jurisdiction by contract.
        </p>
        <ul>
          <li>
            <b>
              Sharing information with Qingping affiliates and third-party
              service providers:{' '}
            </b>
            To successfully engage in business operations and provide you with
            all functions of products and services, we may provide information
            to affiliated companies or third-party service providers (such as
            online stores, delivery service providers, telecommunications
            companies, data centers, data storage facilities, customer service
            providers, advertising and promotion service providers, agents
            representing Qingping, collectively referred to as "third-party
            service providers"), they may handle your personal information on
            behalf of Qingping or for one or more of the above purposes. If you
            no longer want to share this information, please send an email to{' '}
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> to
            contact us.
          </li>
          <li>
            <b>Sharing information with others:</b>
            When required by law, Qingping may disclose your personal
            information without further consent.
          </li>
        </ul>
        <p className={s.px20}>Information that does not require consent</p>
        <p>
          We may share anonymous information with third parties in the form of a
          summary for commercial purposes; We may share the general usage trends
          of our services, such as the number of customers among certain people
          who buy certain products or engage in certain transactions.
        </p>
        <p>
          Qingping may collect, use or disclose your personal information
          without your consent under the circumstances and within the scope
          explicitly permitted by local data protection laws (for example, to
          comply with subpoenas). We may disclose your information without your
          consent to protect the safety of yours or others, assist in the
          investigation of fraud, or respond to government requests.
        </p>
        <p className={s.px20}>Safety protection</p>
        <p>
          We promise to ensure the safety of your personal information. To
          prevent unauthorized access, disclosure or other similar risks, we
          have implemented reasonable physical, electronic and management
          measures to protect the data we collect from your products and
          Qingping websites or software. We will take all appropriate measures
          to protect your personal information.
        </p>
        <p>
          All your personal information is stored on secure servers and
          protected in controlled facilities. We classify your data according to
          its importance and sensitivity, and ensure that your personal
          information has the highest security level. We guarantee that our
          employees and third-party service providers who provide products and
          services to you by accessing this information have strict contractual
          confidentiality obligations. If they fail to fulfill these
          obligations, they will be subject to disciplinary action or be
          terminated from cooperation. To sum up, we regularly review
          information collection, storage and processing practices, including
          physical security measures, to prevent unauthorized access and use.
        </p>
        <p>
          We will take all feasible measures to protect your personal
          information. However, you should be aware that the use of the Internet
          is not always safe, so we cannot guarantee the security or integrity
          of any personal information during the two-way transmission through
          the Internet.
        </p>
        <p className={s.px20}>What can you do?</p>
        <p>
          You can protect the security of your personal information by not
          disclosing your login password or account information to anyone
          (unless you officially authorize the person).
        </p>
        <p>
          Qingping is not responsible for the security omission caused by the
          third party's access to your personal information due to your failure
          to keep the privacy of your personal information. Notwithstanding the
          above, if any other user accesses your account without authorization
          or any other security breach occurs, you must inform us immediately.
        </p>
        <p>
          Your assistance will help us protect the privacy of your personal
          information.
        </p>
        <p className={s.px20}>Policy of keeping personal information</p>
        <p>
          When it is necessary to keep personal information to provide products
          or services or comply with regulatory requirements, we will keep your
          personal information. When it is no longer necessary, we will no
          longer keep your personal information, or eliminate the ways to
          associate personal information with specific individuals.
        </p>
        <p className={s.px20}>Access other features on your device</p>
        <p>
          Our apps may access certain functions of your device, such as Wi-Fi
          network status and other features. This information allows the apps to
          run on your device and enables you to interact with them. At any time,
          you can revoke the permissions in the system settings of the device.
        </p>
        <p>You can control your information!</p>
        <p className={s.px20}>Control your settings</p>
        <p>
          Qingping admits that everyone pays different attention to privacy, so
          we provide some examples for you to choose from to restrict Qingping
          from collecting, using, disclosing or processing your personal
          information and control your privacy settings:
        </p>
        <ul>
          <li>Log in or out of Qingping account; </li>
          <li>Add or delete Qingping devices; </li>
          <li>Add or delete the specific city;</li>
          <li>
            Allow or prohibit Qingping apps or websites to locate your device.
          </li>
        </ul>
        <p>
          If you have previously agreed to use your personal information for the
          above purposes, you can contact us in writing or by sending an email
          to <a href="mailto:privacy@qingping.co">privacy@qingping.co</a> at any
          time to change your decision.
        </p>
        <p className={s.px20}>
          Access, update or correct your personal information
        </p>
        <ul>
          <p>
            You have the right to request access to or correction of any
            personal information we hold about you. When you update your
            personal information, you will be asked to verify your identity
            before processing your request. Once we have sufficient information
            to process your request to access or correct your personal
            information, we will respond to your request within the time
            stipulated by the applicable data protection law.
          </p>
          <p>
            We usually provide these services free of charge, but reserve the
            right to charge a reasonable fee for your data access request.
          </p>
          <p>
            If you wish to request access to the personal data we hold or think
            that any information we hold about you is incorrect or incomplete,
            please send an email to{' '}
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>.
          </p>
        </ul>
        <p className={s.px20}>Withdraw consent</p>
        <ul>
          <li>
            You can submit a request for revoking consent to collect, use and/or
            disclose your personal information in our possession or control by
            sending an email to
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>. We
            will handle your request within a reasonable time after you make it
            and not collect, use, and/or disclose your personal information
            after your request.
          </li>
          <li>
            - Please note that revocation of consent may lead to certain legal
            consequences. Depending on the scope of your personal information
            that you have withdrawn your consent to let us handle, you may not
            be able to use some or all of Qingping's products and services.{' '}
          </li>
        </ul>
        <p className={s.px20}>
          Transfer personal information outside your jurisdiction
        </p>
        <p>
          If we need to transfer personal information outside your jurisdiction,
          whether it is to our affiliated companies or third-party service
          providers, we will do so following applicable laws. By implementing
          appropriate security measures, we will ensure that all such transfers
          meet the requirements of applicable local data protection laws.
        </p>
        <p className={s.px20}>Other provisions</p>
        <p className={s.px18}>Juveniles</p>
        <ul>
          <p>
            We believe it is the responsibility of parents to supervise their
            children's use of our products and services. However, our policy
            does not require obtaining personal information of juveniles or
            sending any promotional materials to such people.
          </p>
          <p>
            Qingping will not seek or attempt to seek to receive any personal
            information from juveniles. If the parents or guardians have reason
            to believe that a juvenile submitted personal information to
            Qingping without their prior consent, please contact us to ensure
            that such personal information is deleted and that the juvenile
            cancels any applicable Qingping service.
          </p>
        </ul>
        <p className={s.px18}>Order of priority</p>
        <p>
          If you agreed to applicable user agreements and any inconsistency
          between such user agreements and this privacy policy, such user
          agreements shall prevail.
        </p>
        <p className={s.px18}>Update of privacy policy</p>
        <p>
          We will regularly review our privacy policy, and we may update this
          privacy policy to reflect changes in our practices of handling
          information. If we make major changes to this privacy policy, we will
          notify you by email (sent to the email address specified in your
          account), or published on all Qingping websites or through mobile
          devices, so that you can know the information we collect and how we
          use it. Such privacy policy changes will apply from the effective date
          specified in the notice or website. We recommend that you check this
          website regularly for the latest information on our privacy practices.
          Your continued use of products and services on websites, mobile phones
          and/or any other devices will be deemed as acceptance of the updated
          privacy policy. When we collect more personal information from you or
          we want to use or disclose your personal information for new purposes,
          we will ask your permission again.
        </p>
        <p className={s.px20}>
          Do you have to agree to any third-party terms or conditions?
        </p>
        <p>
          Our privacy policy does not apply to products and services provided by
          third parties. Qingping products and services may include third-party
          products and services, as well as links to third-party websites. When
          you use these products or services, they may also collect your
          information. Therefore, we strongly recommend that you take the time
          to read the third party's privacy policy, just like reading our
          policy. We are not responsible for how third parties use the personal
          information they collect from you, nor can we control its use. Our
          privacy policy does not apply to other websites linked through our
          services.
        </p>
        <br />
        <p>
          <span>Qingping Technology (Beijing) Co., Ltd.</span>
          <br />
          <span>
            Address: 309, Block C, Yeqing Plaza, No.9 Wangjing North Street,
            Chaoyang District, Beijing
          </span>
          <br />
          <span>Zip code: 100102</span>
          <br />
          <span>
            Email: <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>
          </span>
        </p>

        <p>Thank you for taking the time to understand our privacy policy!</p>
      </div>
    )
    const twHtml = (
      <div>
        <p className={s.title}>
          <b>隱私政策</b>
        </p>
        <p className={s.px20}>更新！ </p>
        <p>
          我們的隱私政策已於 2022 年 5 月 25
          日更新。從該日期開始，這一隱私政策能夠提供有關我們如何管理您在使用所有青萍產品和服務時透露的個人信息的隱私詳情。請花一些時間熟悉我們的隱私權慣例。如果您有任何問題，請告訴我們。
        </p>
        <p className={s.px20}>我們向您承諾：</p>
        <p>
          本隱私政策規定了青萍科技（北京）有限公司及關聯公司（下文簡稱“青萍”或“我們”）如何收集、使用、披露、處理和保護您在使用我們的產品和服務時提供給我們的信息；若我們要求您提供某些信息，以便在使用青萍產品和服務時驗證您的身份，我們將嚴格遵守本隱私政策和/或我們的用戶條款來使用這些信息。{' '}
        </p>
        <p>
          本隱私政策在製定時充分考慮到您的需求；您應全面了解我們的個人資料收集和使用慣例而且確信自己最終能控制提供給青萍的個人信息，這一點至關重要。{' '}
        </p>
        <p>
          在這項隱私政策中，“個人信息”指信息本身或與青萍能夠訪問的個人的其他信息聯繫後能夠識別特定個人的數據。{' '}
        </p>
        <p>
          通過使用青萍產品和服務，即表示您已閱讀、認可並接受本隱私政策中所述之所有條款，包括我們隨時作出的任何變更。我們承諾遵照適用法律（包括您所在地的數據保護法律）來保護您的個人信息的隱私、機密和安全。同時我們承諾我們的全體員工和代理商履行這些義務。{' '}
        </p>
        <p>
          最後，我們所希望的就是為我們的用戶帶來最好的體驗。如果您對這一隱私政策中概括的數據處理慣例有任何疑問，請通過{' '}
          <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>{' '}
          聯繫我們的數據保護官，以便我們處理您的需求。
        </p>
        <p className={s.px20}>收集哪些信息以及如何使用信息</p>
        <p className={s.px18}>收集的信息類別</p>
        <p>
          為了向您提供我們的服務，我們會要求您提供向您提供服務所必需的個人信息。如果您不提供個人信息，我們可能無法向您提供我們的產品或服務。我們收集以下各類信息（無論其是否為個人信息）：
        </p>
        <ul>
          <li>
            <b>您提供給我們或上傳的信息</b>
            （包括您的聯繫方式）：我們可能收集您提供給我們的任一或所有個人信息，例如手機號碼、電子郵箱地址、第三方登錄信息、反饋以及您提供給我們的任何其他信息。
          </li>
          <li>
            <b>設備相關信息</b>
            ：與您的設備相關的信息。例如手機或電腦的操作系統信息、青萍產品的傳感器讀數、狀態、配置信息、序列號或
            MAC。
          </li>
          <li>
            <b>位置信息（僅適用於特定服務/功能）</b>：您的設備的地理位置信息。
          </li>
          <li>
            <b>登錄信息</b>：與您使用某些功能、應用和網站相關的信息。例如 cookie
            和其他匿名標識符技術、IP 地址、臨時消息歷史和標準系統日誌等。
          </li>
          <li>
            <b>您提供的反饋信息</b>
            ：在您使用用戶反饋功能時，我們可能收集問題日誌、您主動提供的聯繫方式、反饋內容。
          </li>
        </ul>
        <p className={s.px18}>這些個人信息將會被如何使用</p>
        <p>
          收集個人信息的目的在於向您提供產品和/或服務，並且保證我們遵守適用法律。您特此同意我們出於本隱私政策規定的目的處理個人信息，並向我們的關聯公司（涉及通信、社交媒體、技術和雲計算）、第三方服務供應商（定義如下）披露個人信息。{' '}
        </p>
        <p className={s.px18}>我們可能會將您的個人信息用於下列目的：</p>
        <ul>
          <li>
            提供、處理、維護、改善、開發我們的商品和/或提供給您的服務，包括售後服務和客戶支持，以及通過設備或網站提供的服務。{' '}
          </li>
          <li>
            與您就您的設備、服務或其他日常問題（例如軟件更新、客戶諮詢支持、我們活動的相關信息、通知）等進行交流。{' '}
          </li>
          <li>
            分析與我們產品及服務的使用相關的統計信息，以改進我們的產品和服務。{' '}
          </li>
          <li>儲存並維護與您相關的信息，用於我們運營業務或履行法律義務。 </li>
        </ul>
        <p>
          關於我們如何使用您的信息（其中可能包含個人信息），下面提供了更多詳細信息：
        </p>
        <ul>
          <li>
            <b>展示設備信息。 </b>
            在青萍軟件或網站內顯示您的青萍設備的讀數和狀態、配置等信息。
          </li>
          <li>
            <b>展示城市信息。 </b>
            在青萍軟件或網站內顯示您指定的城市或所在城市的天氣或空氣質量等信息。
          </li>
          <li>
            <b>提供通知服務。 </b>
            青萍軟件或網站可能向您發送您的青萍設備或您指定的城市或所在城市的狀態通知。您可隨時通過更改設置來關閉相關功能。
          </li>
          <li>
            <b>收集用戶反饋。 </b>
            您提供的反饋對幫助我們改進服務而言極為珍貴。為了跟踪您選擇提供的反饋，青萍能會使用您所提供的個人信息與您聯繫，並保留記錄。
          </li>
          <li>
            <b>進行促銷活動。 </b>
            如果您通過青萍網站或軟件或社交媒體平台參與了抽獎、比賽或類似的促銷，我們可能會使用您提供的個人信息來管理這些項目。
          </li>
          <li>
            <b>直接推廣。 </b>
            我們可能會使用您的電子郵箱地址、手機號向您提供青萍公司及其合作夥伴的產品和服務相關的推廣資料。我們嚴格遵守您所在地的數據保護法規，要求獨立的明確許可，所以我們只會在徵得您的同意或您表示不反對時使用您的個人信息。您有權終止我們使用計劃用於直接推廣的個人數據。如果您不再希望接收某些類別的電子郵件，您可以通過每封郵件底部的取消訂閱鏈接來取消訂閱。我們不會將您的個人信息傳送給我們的商業夥伴用於直接推廣。
          </li>
        </ul>
        <p className={s.px20}>Cookie 和其他技術（只在瀏覽青萍網站時）</p>
        <p className={s.px18}>收集哪些信息以及如何使用這些信息</p>
        <p>
          青萍和第三方服務供應商使用
          cookie、標籤和腳本等技術。這些技術用於分析趨勢、管理網站、追踪用戶的網站活動並收集關於整個用戶群的人口統計信息。我們會收到這些公司基於使用上述技術的單獨和匯總報告。{' '}
        </p>
        <ul>
          <li>
            <b>日誌文件：</b>
            和大部分網站一樣，我們收集特定信息並保存在日誌文件中。此類信息可能包括互聯網協議
            (IP) 地址、瀏覽器類型、互聯網服務供應商
            (ISP)、引用/退出頁面、操作系統、日期/時間戳和點擊流數據。我們不會將自動收集的數據鏈接到我們收集的關於您的其他信息之中。
          </li>
          <li>
            <b>移動分析：</b>
            在某些移動軟件中，我們使用分析技術，以更好地了解我們的軟件的使用情況。此類軟件可能記錄您使用它們的頻率、軟件內發生的事件、累計使用、性能數據及應用程序崩潰發生的位置。
          </li>
          <li>
            <b>本地存儲 - HTML5：</b>
            我們使用本地存儲對象 (LSO)，例如 HTML5
            存儲內容和偏好。各種瀏覽器提供了各自的管理工具來刪除 HTML5
            本地存儲對象。
          </li>
        </ul>
        <p className={s.px18}>我們與誰共享您的信息？ </p>
        <p>我們不會將任何個人信息出售給第三方。 </p>
        <p>
          我們有時可能會向第三方（定義見下文）披露您的個人信息，以便提供您要求的產品或服務。
        </p>
        <p>
          我們可能會向本部分下文列出的第三方服務供應商和關聯公司作出披露。在本部分所述的各種情況下，您可以放心，青萍僅會根據您的授權共享您的個人信息。您應當了解，在下文描述的任何情況下，當青萍與第三方服務供應商共享您的個人信息時，青萍會通過合同規定第三方的實踐和義務，遵守適用的地方數據保護法。青萍會通過合同保證第三方服務供應商遵守您所屬司法管轄區中適用於他們的隱私權標準。
        </p>
        <ul>
          <li>
            <b>與我們集團和第三方服務供應商共享信息：</b>
            為了順利地從事商業經營，為您提供產品和服務的全部功能，我們可能向關聯公司或第三方服務供應商（在線商店、送貨服務供應商、電信公司、數據中心、數據存儲設施、客戶服務供應商、廣告和推廣服務供應商、代表青萍的代理，統稱為“第三方服務供應商”）披露您的個人信息。此類第三方服務供應商可能代表青萍或出於上述的一項或多項目的處理您的個人信息。如果您不再希望允許我們共享這些信息，請發送電子郵件到{' '}
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>{' '}
            聯繫我們。
          </li>
          <li>
            <b>與其他人共享信息：</b>
            在適用法律要求時，青萍可能會不經過進一步同意而披露您的個人信息。
          </li>
        </ul>
        <p className={s.px20}>不需要同意的信息</p>
        <p>
          我們可能以匯總的形式與第三方共享匿名信息，用於商業目的；我們可能與其共享我們服務的一般使用趨勢，例如在購買某些產品或從事某些交易的特定人群中的客戶數量。{' '}
        </p>
        <p>
          青萍可能在地方數據保護法明確允許的情況下和範圍內（例如為了遵循傳票）不經您的同意而收集、使用或披露您的個人信息。我們可能會為了保護您或他人的安全、協助調查欺詐行為，或對政府要求作出回應，不經您的同意而披露您的信息。{' '}
        </p>
        <p className={s.px20}>安全保障</p>
        <p>
          我們承諾保證您的個人信息安全。為了防止未經授權的訪問、披露或其他類似風險，我們落實了合理的物理、電子和管理措施流程，保護我們從您的產品和青萍網站或軟件內收集的信息。我們將採取所有合理的措施保護您的個人信息。
        </p>
        <p>
          您的個人信息全都被儲存在安全的服務器上，並在受控設施中受到保護。我們依據重要性和敏感性對您的數據進行分類，並且保證您的個人信息具有最高的安全等級。我們保證通過訪問這些信息來幫助向您提供產品和服務的員工和第三方服務供應商具有嚴格的合同保密義務，如果未能履行這些義務，其將會受到紀律處分或被終止合作。總而言之，我們定期審查信息收集、儲存和處理實踐，包括物理安全措施，以防止任何未經授權的訪問和使用。{' '}
        </p>
        <p>
          我們將採取所有可行的措施保護您的個人信息。但是，您應當意識到互聯網的使用並不總是安全的，因此，我們不能保證在通過互聯網雙向傳輸時任何個人信息的安全性或完整性。{' '}
        </p>
        <p className={s.px20}>您能做什麼？ </p>
        <p>
          您可以通過不向任何人（除非此人經您正式授權）披露您的登錄密碼或賬戶信息，為保護您個人信息的安全發揮作用。
        </p>
        <p>
          青萍不對因您未能保持個人信息的私密性而導致第三方訪問您的個人信息進而造成的安全疏漏承擔責任。儘管有上述規定，如果發生互聯網其他任何用戶未經授權使用您賬戶的情況或其他任何安全漏洞，您必須立即通知我們。{' '}
        </p>
        <p>您的協助將有助於我們保護您個人信息的私密性。 </p>
        <p className={s.px20}>保留政策</p>
        <p>
          當為了提供產品或服務，或為遵守法規要求而需要保留個人信息時，我們將保留您的個人信息。當保留個人信息已非必要時，我們將不再保留個人信息，或消除將個人信息和特定個人關聯起來的途徑。{' '}
        </p>
        <p className={s.px20}>訪問您的設備上的其他功能</p>
        <p>
          我們的應用程序可能會訪問您設備上的某些功能，例如 Wi-Fi
          網絡狀態，以及其他功能。這些信息用於允許這些應用程序在您的設備上運行，並且允許您與其互動。在任何時候，您都可以通過在設備的系統設置中關閉對應的權限來撤銷許可。{' '}
        </p>
        <p>您可以控制您的信息！ </p>
        <p className={s.px20}>控制設置</p>
        <p>
          青萍承認每個人對隱私權的關注各不相同，因此我們提供了一些示例供您選擇，以限制青萍收集、使用、披露或處理您的個人信息，並控制您的隱私權設置：
        </p>
        <ul>
          <li>登入或登出青萍賬戶；</li>
          <li>綁定或解綁設備；</li>
          <li>添加或刪除指定的城市；</li>
          <li>允許或禁止青萍軟件或網站對您的設備進行定位。 </li>
        </ul>
        <p>
          如果您之前因為上述目的同意我們使用您的個人信息，您可以隨時通過書面或者向{' '}
          <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>{' '}
          發送電子郵件的方式聯繫我們來改變您的決定。
        </p>
        <p className={s.px20}>訪問、更新或更正您的個人信息</p>
        <ul>
          <p>
            您有權要求訪問和/或更正我們持有的與您有關的任何個人信息。當您更新個人信息時，在我們處理您的請求前，會要求驗證您的身份。一旦我們獲得充分信息，可處理您的請求以訪問或更正您的個人信息時，我們將在適用數據保護法規定的時間內對您的請求做出回應。{' '}
          </p>
          <p>
            我們通常免費提供這些服務，但是保留對您的數據訪問請求收取合理費用的權利。{' '}
          </p>
          <p>
            如果您希望請求訪問我們持有的個人數據或者如果您認為我們持有的關於您的任何信息是不正確或不完整的，請盡快致信或給{' '}
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>{' '}
            發送電子郵件聯繫我們。
          </p>
        </ul>
        <p className={s.px20}>撤銷同意</p>
        <ul>
          <li>
            您可以通過向
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>
            發送電子郵件來提交撤銷同意收集、使用和/或披露我們掌握或控制的您的個人信息的請求。我們將會在您做出請求後的合理時間內處理您的請求，並且會根據您的請求，在此後不再收集、使用和/或披露您的個人信息。
          </li>
          <li>
            請注意，撤銷同意可能會導致某些法律後果。根據您撤銷同意讓我們處理的您的個人信息的範圍，您可能會無法享受青萍的某些或全部產品和服務。
          </li>
        </ul>
        <p className={s.px20}>將個人信息轉移到您所屬的司法管轄區之外</p>
        <p>
          如果我們需要將個人信息轉移到您所屬的司法管轄區之外，無論是轉移給我們的關聯公司或第三方服務供應商，我們都將按照適用法律進行。我們通過落實合適的安全保障措施，保證所有這類轉移滿足適用的地方數據保護法的要求。
        </p>
        <p className={s.px20}>其他規定</p>
        <p className={s.px18}>未成年人</p>
        <ul>
          <p>
            我們認為監督孩子使用我們的產品和服務是父母的責任。但是，我們的政策不要求獲得未成年人的個人信息，或者向這類人群發送任何促銷資料。{' '}
          </p>
          <p>
            青萍不會尋求或試圖尋求接收來自未成年人的任何個人信息。如果家長或監護人有理由相信未成年人未經他們事先同意而向青萍提交了個人信息，請聯繫我們以確保刪除此類個人信息，並保證未成年人取消訂閱任何適用的青萍服務。{' '}
          </p>
        </ul>
        <p className={s.px18}>優先順序</p>
        <p>
          如果您同意了適用的用戶協議，並且此類用戶協議和本隱私政策之間存在不一致，將以此類用戶協議為準。
        </p>
        <p className={s.px18}>隱私政策的更新</p>
        <p>
          我們會對隱私政策進行定期審核，為反映我們處理信息的慣例的變更，我們可能會更新本隱私政策。如果我們對本隱私政策進行重大變更，我們將通過（向您賬戶指定的郵箱地址發送）電子郵件或在所有青萍網站公佈或通過移動設備通知您，這樣您可以了解我們收集的信息以及我們如何使用這些信息。此類隱私政策變化將從通知或網站規定的生效日期開始適用。我們建議您定期查閱本網頁獲取我們隱私權實踐的最新信息。您繼續使用產品和網站、手機和/或其他任何設備上的服務，將被視為接受更新的隱私政策。在我們向您收集更多的個人信息或我們希望因為新的目的使用或披露您的個人信息時，我們會再次徵得您的同意。{' '}
        </p>
        <p className={s.px20}>我是否必須同意任何第三方條款與條件？ </p>
        <p>
          我們的隱私政策不適用於第三方提供的產品和服務。青萍產品和服務可能包括第三方的產品和服務，以及第三方網站的鏈接。當您使用這些產品或服務時，也可能收集您的信息。因此，我們強烈建議您花時間閱讀該第三方的隱私政策，就像閱讀我們的政策一樣。我們不對第三方如何使用他們向您收集的個人信息負責，也不能控制其使用。我們的隱私政策不適用通過我們的服務鏈接的其他網站。{' '}
        </p>
        <br />
        <p>
          <span>青萍科技（北京）有限公司</span>
          <br />
          <span>地址：北京市朝陽區望京北路 9 號葉青大廈 C 座 309 室</span>
          <br />
          <span>郵編：100102</span>
          <br />
          <span>
            電子郵箱：
            <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>
          </span>
        </p>

        <p>感謝您花時間了解我們的隱私政策！ </p>
      </div>
    )
    return (
      <div className={s.box}>
        <Header
          location={location}
          language={this.state.language}
          path={path}
          navPosition={this.state.position}
        />

        <div className={s.AllPrivacy} ref="privacy">
          {local === 'cn' ? cnHtml : local === 'us' ? enHtml : twHtml}
        </div>

        <Footer location={location} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let Allprivacy = connect(mapStateToProps)(AllPrivacy)

export default Allprivacy
