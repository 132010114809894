import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import s from './Snineth.module.scss'

class Snineth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Snineth.getBoundingClientRect()
    if (this.refs.Snineth && clientHeight >= textRect.bottom - 200) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div ref="Snineth" id="mi">
        <div
          className={
            locale.indexOf('US') > -1
              ? s.Snineth_en
              : locale.indexOf('TW') > -1
              ? s.Snineth_tw
              : s.Snineth
          }
        >
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              <div className={s.title}>
                <FormattedMessage id="m_support" values={{ br: <br /> }} />
              </div>
              <div className={s.normal}>
                <FormattedMessage
                  id="parrot_eight_title"
                  values={{ br: <br /> }}
                />
              </div>
              <div className={s.small}>
              * <FormattedMessage id="support_mi_C" ></FormattedMessage>
                <FormattedMessage
                  id="parrot_eight_desc"
                  values={{ br: <br /> }}
                />
                <Link to="/bluetooth-gateway" target="_blank">
                  <FormattedMessage id="sparrow" values={{ br: <br /> }} />
                </Link>
                <FormattedMessage id="end" values={{ br: <br /> }} />
              </div>
              <div
                className={
                  locale.indexOf('US') > -1 || locale.indexOf('TW') > -1
                    ? s.mi_icon_en
                    : s.mi_icon
                }
              ></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SNineth = connect(mapStateToProps)(Snineth)

export default SNineth
