import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Pthird.module.scss';

class Pthird extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
        // this.refs.Pthird.style.backgroundSize = '1920px';
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const textRect = this.refs.Pthird.getBoundingClientRect();
        if (this.refs.Pthird && textRect.top < 500) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 0);
        }
        if (this.refs.Pthird && textRect.top < -20) {
            setTimeout(() => {
                this.refs.iconList.style.opacity = 1;
                this.refs.iconList.style.bottom = '100px';
            }, 0);
        }
    }
    render() {
        return (
            <div className={s.Pthird} ref="Pthird">
                <div className={s.wrap}>
                    <div className={s.text} ref="text">
                        <span className={s.large}>
                            <FormattedMessage id="p_third_title" />
                        </span>
                    </div>
                    <div className={s.iconList} ref="iconList">
                        <ul>
                            <li>
                                <i className={s.third_factory} />
                                <FormattedMessage id="p_third_factory" />
                            </li>
                            <li>
                                <i className={s.third_warehouse} />
                                <FormattedMessage id="p_third_warehouse" />
                            </li>
                            <li>
                                <i className={s.third_market} />
                                <FormattedMessage id="p_third_market" />
                            </li>
                            <li>
                                <i className={s.third_supermarket} />
                                <FormattedMessage id="p_third_supermarket" />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <i className={s.third_hospital} />
                                <FormattedMessage id="p_third_hospital" />
                            </li>
                            <li>
                                <i className={s.third_pharmacy} />
                                <FormattedMessage id="p_third_pharmacy" />
                            </li>
                            <li>
                                <i className={s.third_winecellar} />
                                <FormattedMessage id="p_third_winecellar" />
                            </li>
                            <li>
                                <i className={s.third_polytunnel} />
                                <FormattedMessage id="p_third_polytunnel" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pthird;

