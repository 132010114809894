import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Peighth.module.scss'

class Peighth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
    // this.refs.Peighth.style.backgroundSize = '1920px';
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Peighth.getBoundingClientRect()
    if (this.refs.Peighth && clientHeight >= textRect.bottom - 300) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 0)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div className={s.Peighth} ref="Peighth">
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <span className={s.large}>
              <FormattedMessage id="ph_co2_twelve_title" />
            </span>
            <span
              className={s.normal}
              style={{
                maxWidth: locale.indexOf('US') > -1 ? '700px' : '770px',
              }}
            >
              <FormattedMessage id="ph_co2_twelve_desc" />
            </span>
          </div>
          <div className={s.bottomContent}>
            <div className={s.boxLeft}>
              <div className={s.leftTop}>
                <div className={s.leftIcon}></div>
                <div className={s.leftText}>
                  <FormattedMessage id="ph_co2_twelve_icon1" />
                </div>
              </div>
              <div className={s.iconMiddle}></div>
              <div className={s.leftBottom}>
                <FormattedMessage id="ph_co2_twelve_icon1_desc" />
              </div>
            </div>
            <div
              className={
                locale.indexOf('US') > -1
                  ? s.boxRight_en
                  : locale.indexOf('TW') > -1
                  ? s.boxRight_tw
                  : s.boxRight
              }
            >
              <div className={s.rightIcons}>
                <div className={s.rightItem}>
                  <div className={s.rightIcon1}></div>
                  <div className={s.rightText}>
                    <FormattedMessage id="ph_co2_twelve_icon2" />
                  </div>
                </div>
                <div className={s.rightItem}>
                  <div className={s.rightIcon2}></div>
                  <div className={s.rightText}>
                    <FormattedMessage id="ph_co2_twelve_icon3" />
                  </div>
                </div>
                <div className={s.rightItem}>
                  <div className={s.rightIcon3}></div>
                  <div className={s.rightText}>
                    <FormattedMessage id="ph_co2_twelve_icon4" />
                  </div>
                </div>
                <div className={s.rightItem}>
                  <div className={s.rightIcon4}></div>
                  <div className={s.rightText}>
                    <FormattedMessage id="ph_co2_twelve_icon5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PEighth = connect(mapStateToProps)(Peighth)

export default PEighth
