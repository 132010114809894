import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import s from './Pfirst.module.scss'

class Pfirst extends React.PureComponent {
  render() {
    const { locale } = this.props
    const column0 = locale.indexOf('US') > -1 ? s.column0_en : s.column0
    const column1 = locale.indexOf('US') > -1 ? s.column1_en : s.column1
    const column2 = locale.indexOf('US') > -1 ? s.column2_en : s.column2
    return (
      <div className={locale.indexOf('US') > -1 ? s.Pfirst_en : s.Pfirst}>
        <div className={s.text}>
          <div className={s.multiple}>
            <FormattedMessage id="ph_co2_third_title" />
          </div>
          <div className={s.every}>
            <FormattedMessage id="ph_co2_third_desc" />
          </div>
          <div className={s.table}>
            <div className={s.table_row} style={{ backgroundColor: '#84919c' }}>
              <div className={column0}>
                <FormattedMessage id="ph_co2_third_table_color" />
              </div>
              <div
                className={column1}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="ph_co2_third_table_range" />
              </div>
              <div
                className={column2}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="ph_co2_third_table_rate" />
              </div>
            </div>
            <div className={s.table_row} style={{ paddingTop: '10px' }}>
              <div
                className={column0}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <div
                  className={s.circle}
                  style={{ backgroundColor: '#86bf46' }}
                ></div>
              </div>
              <div className={column1}>
                <FormattedMessage id="ph_co2_third_range_normal" />
              </div>
              <div className={column2}>
                <FormattedMessage id="ph_co2_third_rate_normal" />
              </div>
            </div>
            <div className={s.table_row}>
              <div className={column0}>
                <div
                  className={s.circle}
                  style={{ backgroundColor: '#e1d259' }}
                ></div>
              </div>
              <div className={column1}>
                <FormattedMessage id="ph_co2_third_range_high" />
              </div>
              <div className={column2}>
                <FormattedMessage id="ph_co2_third_rate_high" />
              </div>
            </div>
            <div className={s.table_row} style={{ paddingBottom: '10px' }}>
              <div className={column0}>
                <div
                  className={s.circle}
                  style={{ backgroundColor: '#d83a48' }}
                ></div>
              </div>
              <div className={column1}>
                <FormattedMessage id="ph_co2_third_range_pretty_high" />
              </div>
              <div className={column2}>
                <FormattedMessage id="ph_co2_third_rate_pretty_high" />
              </div>
            </div>
          </div>
          <div className={s.spec}>
            <FormattedMessage id="ph_co2_third_memo" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PFirst = connect(mapStateToProps)(Pfirst)

export default PFirst
