import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './style.module.scss'

class GHomeKit extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
    if (this.refs.goose_add_part) {
      // const clientHeight = document.documentElement.clientHeight
      // this.refs.goose_add_part.style.height = `${clientHeight}px`
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.goose_add_part.getBoundingClientRect()
    if (clientHeight - 200 > textRect.top && this.refs.goose_add_part) {
      setTimeout(() => {
        this.refs.top_text.style.opacity = 1
        this.refs.top_text.style.top = '50%'
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div className={s.goose_add_part} ref="goose_add_part">
        <div className={s.anchor} id="homekit"></div>
        <div className={s.bgImg} ref="bgImg"></div>
        <div
          className={s.detail}
          style={{ marginTop: locale.indexOf('US') > -1 ? -171 : -123 }}
          ref="top_text"
        >
          <span className={s.large}>
            <FormattedMessage id="h_support" values={{ br: <br></br> }} />
          </span>
          <div className={s.HomeKit_img}></div>
          <span className={s.normal}>
            <FormattedMessage id="check_anytime" values={{ br: <br></br> }} />
          </span>
          <span className={s.small}>
            <FormattedMessage id="check_remote" />
            <br />
            <br />
            <span style={{ paddingLeft: 9, display: 'block' }}>
              <FormattedMessage id="check_thread" values={{ br: <br /> }} />
            </span>
          </span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const Ghomekit = connect(mapStateToProps)(GHomeKit)

export default Ghomekit
