import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import s from './Pfirst.module.scss'

class Pfirst extends React.PureComponent {
  render() {
    const { locale } = this.props
    const column0 = locale.indexOf('US') > -1 ? s.column0_en : s.column0
    const column1 = locale.indexOf('US') > -1 ? s.column1_en : s.column1
    const column2 = locale.indexOf('US') > -1 ? s.column2_en : s.column2
    return (
      <div className={locale.indexOf('US') > -1 ? s.Pfirst_en : s.Pfirst}>
        <div className={s.text}>
          <div className={s.product}>
            <FormattedMessage id="frog" values={{ br: '' }} />
            &nbsp;·&nbsp;
            <FormattedMessage id="frog_needle" values={{ br: '' }} />
          </div>
          {/* {locale.indexOf('US') > -1 ? (
            <ul className={s.product_en}>
              <li>
                <FormattedMessage id="frog" values={{ br: '' }} />
              </li>
              <li>
                <FormattedMessage id="frog_needle" values={{ br: '' }} />
              </li>
            </ul>
          ) : (
            <div className={s.product}>
              <FormattedMessage id="frog" values={{ br: '' }} />
              &nbsp;·&nbsp;
              <FormattedMessage id="frog_needle" values={{ br: '' }} />
            </div>
          )} */}
          <div className={s.multiple}>
            <FormattedMessage id="frog_first_title" />
          </div>
          <div className={s.table}>
            <div
              className={s.table_row}
              style={{
                backgroundColor: '#0003',
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
              }}
            >
              <div style={{ opacity: 0 }} className={column0}>
                <FormattedMessage id="ph_first_text5" />
              </div>
              <div
                className={column1}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="frog" values={{ br: '' }} />
              </div>
              <div
                className={column2}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="frog_needle" values={{ br: '' }} />
              </div>
            </div>
            <div className={s.table_row}>
              <div
                className={column0}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="ph_first_text5" />
              </div>
              <div className={column1}>
                <FormattedMessage id="frog_first_range1" />
              </div>
              <div className={column2}>
                <FormattedMessage id="frog_first_range2" />
              </div>
            </div>
            <div className={s.table_row}>
              <div
                className={column0}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="frog_first_humi_range" />
              </div>
              <div className={column1}>
                <FormattedMessage id="frog_first_range3" />
              </div>
              <div className={column2}>
                <FormattedMessage id="frog_first_range4" />
              </div>
            </div>
            <div className={s.table_row}>
              <div
                className={column0}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="frog_first_level_title" />
              </div>
              <div className={column1}>
                <FormattedMessage id="frog_first_range5" />
              </div>
              <div className={column2}>
                <FormattedMessage id="frog_first_range6" />
              </div>
            </div>
            <div
              className={s.table_row}
              style={{
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                borderBottom: 0,
              }}
            >
              <div
                className={column0}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="frog_first_special" />
              </div>
              <div className={column1}>
                <FormattedMessage id="frog_first_range7" />
              </div>
              <div className={column2}>
                <FormattedMessage id="frog_first_range8" />
              </div>
            </div>
          </div>
          <Link to="/temp-rh-monitor-pro-e/specifications">
            <div className={s.spec}>
              <FormattedMessage id="ph_first_text14" />
              <div className={s.triangle}></div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PFirst = connect(mapStateToProps)(Pfirst)

export default PFirst
