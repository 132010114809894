import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Sfourth.module.scss'

class Sfourth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Sfourth.getBoundingClientRect()
    if (clientHeight >= textRect.bottom && this.refs.text) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '152px'
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div ref="Sfourth">
        <div className={s.Sfourth}>
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              {locale.indexOf('US') > -1 ? <div className={s.title}>
                <FormattedMessage id="chicken_third_title_en" values={{br: <br />}} />
                <sup className={s.footnote}>
                  <a href="#footnote-1" aria-label="脚注1">
                    1
                  </a>
                </sup>
              </div> : <div className={s.title}>
                <FormattedMessage id="chicken_third_title" values={{br: <br />}} />
                <sup className={s.footnote}>
                  <a href="#footnote-1" aria-label="脚注1">
                    1
                  </a>
                </sup>
                <FormattedMessage id="chicken_third_title1" values={{br: <br />}} />
              </div>}
              {locale.indexOf('US') > -1 ? <div className={s.subtitle}>
                <FormattedMessage id="chicken_third_desc_en" values={{ br: '' }} />
                <sup className={s.footnote}>
                  <a href="#footnote-2" aria-label="脚注2">
                    2
                  </a>
                </sup>
                <FormattedMessage id="end" />
              </div> : <div className={s.subtitle}>
                <FormattedMessage id="chicken_third_desc" values={{br: <br />}} />
                <sup className={s.footnote}>
                  <a href="#footnote-2" aria-label="脚注2">
                    2
                  </a>
                </sup>
                <FormattedMessage id="chicken_third_desc1" values={{br: <br />}} />
                <sup className={s.footnote}>
                  <a href="#footnote-2" aria-label="脚注2">
                    2
                  </a>
                </sup>
                <FormattedMessage id="chicken_third_desc2" values={{br: <br />}} />
              </div>}
            </div>
            <div className={s.imgBox}>
              <div className={locale.indexOf('US') > -1 ? s.pic_en : s.pic}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let SFourth = connect(mapStateToProps)(Sfourth);

export default SFourth
