import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import s from './Sparam.module.scss'

class Sparam extends React.PureComponent {
  render() {
    const { locale } = this.props
    return (
      <div className={s.box}>
        <div className={s.Sparam}>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="exterior" />
            </div>
            <div className={locale.indexOf('US') > -1 ? s.cell_en : s.cell}>
              <div className={s.sizeImg}>
                <div className={s.top_side}>
                  <div className={s.left_left}>
                    <div className={s.left_img}></div>
                    <div className={s.left_bottom}></div>
                    <div className={s.bottom_num}>
                      <FormattedMessage id="parrot_bottom" />
                    </div>
                  </div>
                  <div className={s.left_right}></div>
                  <div className={s.right_num}>
                    <FormattedMessage id="parrot_height" />
                  </div>
                </div>
                <div className={s.top_top}>
                  <div className={s.left_left}>
                    <div className={s.top_img}></div>
                    <div className={s.top_bottom}></div>
                    <div className={s.bottom_num}>
                      <FormattedMessage id="parrot_bottom_bottom" />
                    </div>
                  </div>
                  <div className={s.left_right}></div>
                  <div className={s.right_num}>
                    <FormattedMessage id="parrot_bottom_height" />
                  </div>
                </div>
              </div>
              <ul>
                <li className={s.special_li}>
                  <span className={s.type}>
                    <FormattedMessage id="p_size" />
                  </span>
                  <FormattedMessage id="parrot_size" values={{ br: ' ' }} />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_color" />
                  </span>
                  <FormattedMessage id="parrot_color" />
                </li>
              </ul>
            </div>
          </div>

          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="range" />
            </div>
            <div className={locale.indexOf('US') > -1 ? s.cell_en : s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="detect_distance" />
                  </span>
                  <FormattedMessage id="parrot_distance" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="detect_angle" />
                  </span>
                  <FormattedMessage id="parrot_angle" />
                </li>
                <li className={s.special_li}>
                  <span className={s.type}>
                    <FormattedMessage id="light_range" />
                  </span>
                  <FormattedMessage id="parrot_range" />
                </li>
              </ul>
            </div>
          </div>

          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="other_specs" />
            </div>
            <div className={locale.indexOf('US') > -1 ? s.cell_en : s.cell}>
              <ul>
                <li className={s.special_li}>
                  <span className={s.type}>
                    <FormattedMessage id="p_weight" />
                  </span>
                  <FormattedMessage id="parrot_weight" values={{ br: ' ' }} />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="parrot_work_temp" />
                  </span>
                  <FormattedMessage id="parrot_temp" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="parrot_work_humi" />
                  </span>
                  <FormattedMessage id="parrot_humi" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_battery" />
                  </span>
                  <FormattedMessage id="parrot_battery" />
                </li>
              </ul>
            </div>
          </div>

          <div className={s.pack}>
            <div className={s.name}>
              <FormattedMessage id="p_pack" />
            </div>
            <div className={locale.indexOf('US') > -1 ? s.cell_en : s.cell}>
              <ul className={s.packWrap}>
                <li>
                  <i className={s.pack_1} />
                  <FormattedMessage
                    id="parrot_pack_body"
                    values={{ br: <br /> }}
                  />
                </li>
                <li>
                  <i className={s.pack_2} />
                  <FormattedMessage id="parrot_pack_bottom" />
                </li>
                <li>
                  <i className={s.pack_3} />
                  <FormattedMessage id="parrot_pack_stick" />
                </li>
                <li>
                  <i className={s.pack_4} />
                  <FormattedMessage id="p_instructions" />
                </li>
              </ul>
            </div>
          </div>

          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="t_version" />
            </div>
            <div className={locale.indexOf('US') > -1 ? s.cell_en : s.cell}>
              <ul>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_name" />
                  </span>
                  <FormattedMessage id="parrot_t_name" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_model" />
                  </span>
                  <FormattedMessage id="parrot_t_model" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_wireless" />
                  </span>
                  <FormattedMessage id="thread" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_support_platform" />
                  </span>
                  <div className={s.homekit}></div>
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="support_operation" />
                  </span>
                  <FormattedMessage id="iOS" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="support_app" />
                  </span>
                  <div className={s.support}>
                    <div className={s.item}>
                      <div className={s.home_logo}></div>
                      <div className={s.logo_title}>
                        <FormattedMessage id="family" />
                      </div>
                    </div>
                    <Link
                      to="/plus"
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      <div className={s.item}>
                        <div className={s.plus_logo}></div>
                        <div className={s.logo_title}>
                          <FormattedMessage id="cg_app" />
                        </div>
                      </div>
                    </Link>
                  </div>
                </li>
                <li
                  style={{
                    marginBottom: 0,
                    alignItems: 'flex-start',
                  }}
                >
                  <span className={s.type_new}>
                    <FormattedMessage id="remote_h" />
                  </span>
                  <span style={{ width: 470 }}>
                    <FormattedMessage
                      id="need_homepod_tv"
                      values={{ br: '' }}
                    />
                    <br />
                    <span style={{ marginTop: 8, display: 'block' }}>
                      <FormattedMessage
                        id="need_thread"
                        values={{ br: <br /> }}
                      />
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className={s.row}>
            <div className={s.name} style={{ borderBottom: 'none' }}>
              <FormattedMessage id="m_version" />
            </div>
            <div
              className={locale.indexOf('US') > -1 ? s.cell_en : s.cell}
              style={{ borderBottom: 'none' }}
            >
              <ul>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_name" />
                  </span>
                  <FormattedMessage id="parrot_m_name" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_model" />
                  </span>
                  <FormattedMessage id="parrot_model" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_wireless" />
                  </span>
                  <FormattedMessage id="only_bluetooth" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_support_platform" />
                  </span>
                  <div
                    className={
                      locale.indexOf('US') > -1 ? s.mihome_en : s.mihome
                    }
                  ></div>
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="support_operation" />
                  </span>
                  <FormattedMessage id="iOS_Android" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="support_app" />
                  </span>
                  <div className={s.support}>
                    <div className={s.item}>
                      <div className={s.mi_logo}></div>
                      <div className={s.logo_title}>
                        <FormattedMessage id="mi_home" />
                      </div>
                    </div>
                    <Link
                      to="/plus"
                      style={{
                        color: '#000',
                        textDecoration: 'none',
                      }}
                    >
                      <div className={s.item}>
                        <div className={s.plus_logo}></div>
                        <div className={s.logo_title}>
                          <FormattedMessage id="cg_app" />
                        </div>
                      </div>
                    </Link>
                  </div>
                </li>
                <li style={{ alignItems: 'flex-start', marginBottom: 0 }}>
                  <span className={s.type_new}>
                    <FormattedMessage id="remote_access" values={{ br: '' }} />
                  </span>
                  <span>
                    <FormattedMessage
                      id="mi_app_need_1"
                      values={{ br: <br /> }}
                    />
                    <Link
                      to="/bluetooth-gateway"
                      style={{ color: '#000', textDecoration: 'underline' }}
                    >
                      <FormattedMessage id="sparrow" />
                    </Link>
                    <FormattedMessage id="end" />
                    <br />
                    <span style={{ marginTop: 8, display: 'block' }}>
                      <FormattedMessage id="mi_app_need_2" />
                      <Link
                        to="/plus"
                        style={{ color: '#000', textDecoration: 'underline' }}
                      >
                        <FormattedMessage id="cg_app" />
                      </Link>
                      <FormattedMessage
                        id="mi_app_need_3"
                        values={{ br: <br /> }}
                      />
                      <Link
                        to="/bluetooth-gateway"
                        style={{ color: '#000', textDecoration: 'underline' }}
                      >
                        <FormattedMessage id="sparrow" />
                      </Link>
                      <FormattedMessage id="end" />
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SParam = connect(mapStateToProps)(Sparam)

export default SParam
