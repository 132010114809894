import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import s from './Snineth.module.scss'

class Snineth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Snineth.getBoundingClientRect();
        if (this.refs.Snineth && clientHeight >= textRect.bottom - 400) {
            setTimeout(() => {
                this.refs.text1.style.opacity = .6;
                this.refs.text1.style.top = '0';
                this.refs.text2.style.opacity = .6;
                this.refs.text2.style.top = '0';
                this.refs.text3.style.opacity = .6;
                this.refs.text3.style.top = '0';
            }, 300);
        }
    }
    render() {
        const { locale } = this.props
        return (
            <div ref="Snineth">
                <div className={s.Snineth}>
                    <div className={s.wrap}>
                        <div className={s.one}>
                            <ul className={locale.indexOf('US') > -1 ? s.one_desc_en : s.one_desc} ref="text1">
                                <li>
                                    <FormattedMessage id="mp_thin" />
                                </li>
                                <li>
                                    <FormattedMessage id="mp_design" values={{br: ''}} />
                                </li>
                                <li>
                                    <FormattedMessage id="mp_beauty" />
                                </li>
                            </ul>
                        </div>
                        <div className={s.two}>
                            <div className={s.two_title}>
                            <FormattedMessage id="mp_more" />
                            </div>
                            <div className={s.two_img}>
                                <ul className={locale.indexOf('US') > -1 ? s.two_desc_en : s.two_desc} ref="text2">
                                    <li>
                                        <FormattedMessage id="mp_tpu_2" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="mp_bend_time" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={s.three}>
                            <ul className={locale.indexOf('US') > -1 ? s.three_desc_en : s.three_desc} ref="text3">
                                <li>
                                    <FormattedMessage id="mp_rotary" />
                                </li>
                                <li>
                                    <FormattedMessage id="mp_change_battery" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  let SNineth = connect(mapStateToProps)(Snineth);

export default SNineth;

