import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import s from './Ptenth.module.scss'

class Ptenth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
      battery: false,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Ptenth.getBoundingClientRect()
    if (this.refs.Ptenth && clientHeight >= textRect.bottom - 200) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 0)
    }
  }
  render() {
    return (
      <div className={s.Ptenth} ref="Ptenth">
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <span className={s.large}>
              <FormattedMessage id="frog_second_title_1" />
              <sup className={s.footnote}>
                <a href="#footnote-1" aria-label="脚注1">
                  1
                </a>
              </sup>
              <FormattedMessage id="frog_second_title_2" />
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default Ptenth
