import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './Sfourteenth.module.scss'

class Sfourteenth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Sfourteenth.getBoundingClientRect();
        if (this.refs.Sfourteenth && clientHeight >= textRect.bottom - 100) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 500);
        }
    }
    render() {
        const { locale } = this.props
        return (
            <div ref="Sfourteenth">
                <div className={locale.indexOf('US') > -1 ? s.Sfourteenth_en : (locale.indexOf('TW') > -1 ? s.Sfourteenth_tw : s.Sfourteenth)}>
                    <div className={s.wrap}>
                        <div className={s.text} ref="text">
                            <div className={s.title}>
                                <FormattedMessage id="s_fourteenth_title" />
                            </div>
                            <div className={s.desc}>
                                <FormattedMessage id="s_fourteenth_desc" />
                            </div>
                        </div>
                        <div className={s.img} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  const SFourteenth = connect(mapStateToProps)(Sfourteenth)

export default SFourteenth;

