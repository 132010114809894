import React, { Component } from 'react';
// import { FormattedMessage } from 'react-intl';

import s from './Seleventh.module.scss'

class Seleventh extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Seleventh.getBoundingClientRect();
        const clientWidth = document.documentElement.clientWidth;
            this.refs.row_1.style.width = `${clientWidth + 200}px`;
            this.refs.img_1.style.width = `${(clientWidth + 170) / 3}px`;
            this.refs.img_2.style.width = `${(clientWidth + 170) / 3}px`;
            this.refs.img_3.style.width = `${(clientWidth + 170) / 3}px`;
            if ((clientHeight + 695) >= textRect.bottom) {
                this.refs.row_1.style.left = `${(((clientHeight + 495) - textRect.bottom) / 5) - 200}px`;
            }
    }
    render() {
        return (
            <div ref="Seleventh">
                <div className={s.Seleventh}>
                    <div className={s.row_1} ref="row_1">
                        <i className={s.img_1} ref="img_1" />
                        <i className={s.img_2} ref="img_2" />
                        <i className={s.img_3} ref="img_3" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Seleventh;

