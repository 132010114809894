import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Psecond.module.scss'

class Psecond extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
  }
  componentDidMount() {}
  componentWillUnmount() {}
  render() {
    const { locale } = this.props
    return (
      <div className={s.Psecond} ref="Psecond">
        <div className={s.wrap}>
          <div
            className={s.text}
            style={{
              top:
                locale.indexOf('US') > -1
                  ? 'calc(50% - 180px)'
                  : 'calc(50% - 120px)',
            }}
            ref="text"
          >
            <span className={s.large}>
              <FormattedMessage id="ph_co2_second_title" />
            </span>
            <span className={s.normal}>
              <FormattedMessage
                id="ph_co2_second_desc"
                values={{
                  br: <br></br>,
                }}
              />
            </span>
            <span className={s.normal}>
              <FormattedMessage id="ph_co2_second_desc_2" />
            </span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PSecond = connect(mapStateToProps)(Psecond)

export default PSecond
