import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './Sfifth.module.scss'

class Sfifth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Sfifth.getBoundingClientRect();
        if (this.refs.Sfifth && clientHeight >= textRect.bottom - 300) {
            setTimeout(() => {
                this.refs.text1.style.opacity = .9;
                this.refs.text1.style.top = '0';
                
                this.refs.text2.style.opacity = .6;
                this.refs.text2.style.top = '0';

            }, 300);
        }
    }
    render() {
        const { locale } = this.props
        return (
            <div className={s.Sfifth} ref="Sfifth">
                <div className={s.wrap}>
                    <div className={s.text}>
                        <div className={s.title} ref="text1">
                            <FormattedMessage id="mp_convient" />
                        </div>
                        <div className={s.subTitle} ref="text2">
                            <FormattedMessage id="mp_goods_1" values={{br: <br />}} />
                        </div>
                        <div className={s.desc}>
                            <div className={locale.indexOf('US') > -1 ? s.screen_en : s.screen}>
                                <div className={s.screen_title}>
                                    <FormattedMessage id="mp_screen" />
                                </div>
                                <div className={s.screen_desc}>
                                    <FormattedMessage id="mp_magnet" />
                                    <br/>
                                    <FormattedMessage id="mp_attract" />
                                    </div>
                            </div>
                            <div className={locale.indexOf('US') > -1 ? s.button_en : s.button}>
                                <div className={s.button_title}>
                                    <FormattedMessage id="mp_button" />
                                </div>
                                <div className={s.button_desc}>
                                    <FormattedMessage id="mp_switch" />
                                    <br/>
                                    <FormattedMessage id="mp_cp" values={{br: ''}} />
                                    </div>
                            </div>
                            <div className={locale.indexOf('US') > -1 ? s.material_en : s.material}>
                                <div className={s.material_title}>
                                    <FormattedMessage id="mp_tpu" />
                                </div>
                                <div className={s.material_desc}>
                                    <FormattedMessage id="mp_bend" />
                                </div>
                            </div>
                            <div className={locale.indexOf('US') > -1 ? s.sensor_en : s.sensor}>
                                <div className={s.sensor_title}>
                                    <FormattedMessage id="mp_sensor_end" />
                                </div>
                                <div className={s.sensor_desc}>
                                    <FormattedMessage id="mp_built_in" />
                                    <br/>
                                    <FormattedMessage id="mp_cavity" values={{br: ''}} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  const SFifth = connect(mapStateToProps)(Sfifth)

export default SFifth;

