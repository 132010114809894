import React, { Component } from 'react';

import EnSPrivacy from './en'
import DeSPrivacy from './de'

class Pprivacy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { // init top hide
      language: 'en'
    };
  }
  componentDidMount() {
    console.log(this.props, 'this.props of index.jsx')
    if (this.props.location.search.indexOf('en') > -1) {
      this.setState({
        language: 'en'
      })
    } else {
      this.setState({
        language: 'de'
      })
    }
  }

  render () {
    const { language } = this.state

    return (
      <div>
        {
          language === 'de' ? <DeSPrivacy></DeSPrivacy> : <EnSPrivacy></EnSPrivacy>
        }
      </div>
    );
  }
}

export default Pprivacy;
