import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import LazyLoad from 'react-lazyload'
import actions from '../../../../store/actions'
import Utils from '../../../common/util'

import s from './Footer.module.scss'

class Footer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      placeholder: 'name@domain.com',
      language: null,
      isHide: true,
      uid: undefined,
    }
    // this._handleKeypress = this.handleKeypress.bind(this);
  }
  componentDidMount() {
    this.initUidAndDrawDot()
    let browserLanguage = navigator.browserLanguage
      ? navigator.browserLanguage
      : navigator.language
    // console.log('browserLanguage', navigator, browserLanguage, browserLanguage.indexOf('zh'));
    if (browserLanguage.indexOf('zh') !== -1) {
      browserLanguage = 'zh-CN'
    } else {
      browserLanguage = 'en-US'
    }
    this.setState({
      language: browserLanguage,
    })
    // document.onkeydown = this._handleKeypress;
  }

  initUidAndDrawDot() {
    let uid = localStorage.getItem('uid') || undefined
    if (!uid) {
      uid = Math.random()
      localStorage.setItem('uid', uid)
    }
    this.setState({
      uid: uid,
    })
  }

  handleClick(Keypress, e) {
    e.stopPropagation()
    if (Keypress) {
      this.refs.qrcode.style.paddingBottom = '0px'
      this.refs.qrcode.style.paddingRight = '0px'
    } else {
      this.refs.qrcode.style.paddingBottom = '200px'
      this.refs.qrcode.style.paddingRight = '200px'
    }
    this.setState({
      isHide: !this.state.isHide,
    })
  }
  handleKeypress(e) {
    if (!this.state.isHide) {
      if (e.keyCode === 32) {
        this.handleClick(true)
      }
    }
  }

  changeLanguage(lang) {
    let locale
    switch (lang) {
      case 'language_value_1':
        locale = 'zh-CN'
        break
      case 'language_value_2':
        locale = 'zh-TW'
        break
      case 'language_value_3':
        locale = 'en-US'
        break
      default:
        locale = 'en-US'
        break
    }
    console.log(locale, 'locale of changeLanguage')
    localStorage.setItem('language', locale)
    this.props.changeLanguage(locale)
  }

  render() {
    const { uid } = this.state
    const { location, locale } = this.props

    const language_value = 'language_value_' + Utils.getIndexOfLanguage(locale)
    const allLanguages = [
      'language_value_1',
      'language_value_2',
      'language_value_3',
    ]
    allLanguages.splice(allLanguages.indexOf(language_value), 1)

    const pathArr = location.pathname.split('/')
    let logoName = locale.indexOf('US') > -1 ? s.logo_en : s.logo
    let bgName = s.hide
    let privacyName = s.hide
    let allPrivacyName = s.hide

    if (!this.state.isHide) {
      bgName = s.bg
    }

    if (pathArr[1] !== 'mi_temp_rh_monitor') {
      allPrivacyName = null
    }
    return (
      <div className={s.footerBox}>
        <LazyLoad height={325}>
          <div className={s.footer}>
            <div className={s.foot_top}>
              <div className={logoName}>
                <Link to="/">
                  <i />
                </Link>
              </div>
              <div className={s.foot_icon}>
                {locale.indexOf('US') > -1 ? (
                  <a
                    href="https://facebook.com/qingping.co"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={s.facebook} />
                  </a>
                ) : null}
                {locale.indexOf('US') > -1 ? (
                  <a
                    href="https://instagram.com/accounts/login/?next=/qingping.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={s.instagram} />
                  </a>
                ) : null}

                <div
                  className={s.wechat}
                  onClick={(e) => this.handleClick(false, e)}
                >
                  <div
                    className={bgName}
                    onClick={(e) => this.handleClick(true, e)}
                  />
                  <div
                    className={s.qrcode}
                    ref="qrcode"
                    onClick={(e) => this.handleClick(false, e)}
                  />
                </div>
                <a
                  href="https://weibo.com/qingpingkeji"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={s.sina} />
                </a>
                {locale.indexOf('zh') > -1 ? (
                  <a
                    href="https://facebook.com/qingping.co"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={s.facebook} />
                  </a>
                ) : null}
                {locale.indexOf('zh') > -1 ? (
                  <a
                    href="https://instagram.com/accounts/login/?next=/qingping.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={s.instagram} />
                  </a>
                ) : null}
              </div>
            </div>
            <div className={s.foot_info}>
              <ul>
                <li>
                  <span className={s.title}>
                    <FormattedMessage id="cooperate" />
                  </span>
                  {locale.indexOf('US') > -1 ||
                  locale.indexOf('TW') > -1 ? null : (
                    <i className={s.tel} />
                  )}
                  {locale.indexOf('US') > -1 ||
                  locale.indexOf('TW') > -1 ? null : (
                    <span>（010）8476 6899</span>
                  )}

                  <i className={s.email} />
                  <a
                    href="mailto: bd@qingping.co"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    bd@qingping.co
                  </a>
                </li>
                <li>
                  <span className={s.title}>
                    <FormattedMessage id="serve" />
                  </span>
                  {locale.indexOf('US') > -1 ||
                  locale.indexOf('TW') > -1 ? null : (
                    <i className={s.tel} />
                  )}
                  {locale.indexOf('US') > -1 ||
                  locale.indexOf('TW') > -1 ? null : (
                    <span>&nbsp;&nbsp;400 697 2778</span>
                  )}

                  <i className={s.email} />
                  {locale.indexOf('US') > -1 ? (
                    <a
                      href="mailto:support@qingping.co"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      support@qingping.co
                    </a>
                  ) : (
                    <a
                      href="mailto:kefu@qingping.co"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      kefu@qingping.co
                    </a>
                  )}
                </li>
              </ul>
            </div>
            <div className={s.text}>
              <div className={s.name}>
                <span style={{ opacity: 0.4 }}>
                  <FormattedMessage id="company_name" />
                </span>
                <Link
                  to="/mi-temp-rh-monitor/privacy"
                  className={privacyName}
                  style={{ opacity: 0.4 }}
                >
                  <FormattedMessage id="privacy" />
                </Link>
                <a
                  href="/about-us"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ opacity: 0.4 }}
                  className={allPrivacyName}
                >
                  <FormattedMessage id="about_us" />
                </a>
                <a
                  href="/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ opacity: 0.4 }}
                  className={allPrivacyName}
                >
                  <FormattedMessage id="privacy" />
                </a>
                {locale.indexOf('US') > -1 ? (
                  <a
                    href="/eu-declarations"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ opacity: 0.4 }}
                    className={allPrivacyName}
                  >
                    EU DoC
                  </a>
                ) : null}
                {/* 需注释部分 */}
                <span className={s.language} style={{ position: 'relative' }}>
                  <span style={{ opacity: 0.4 }}>Language:&nbsp;</span>
                  <span style={{ opacity: 0.4 }}>
                    <FormattedMessage id={language_value} />
                  </span>
                  <span className={s.top_arrows}></span>
                  <div className={s.wrapBuy} name="wrapBuy">
                    <div className={s.buyBox}>
                      {allLanguages.map((item) => {
                        return (
                          <div
                            className={s.buyItem}
                            key={item}
                            onClick={() => this.changeLanguage(item)}
                          >
                            <FormattedMessage id={item} />
                          </div>
                        )
                      })}
                    </div>
                    <div style={{ height: 16 }}></div>
                  </div>
                </span>
              </div>
              {locale.indexOf('US') > -1 || locale.indexOf('TW') > -1 ? null : (
                <div className={s.icp}>
                  <a
                    className={s.icp_a}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://beian.miit.gov.cn/"
                  >
                    <FormattedMessage id="icp" />
                  </a>
                  <span className={s.records}>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502004977"
                      className={s.records_a}
                    >
                      <span className={s.records_p}>
                        粤公网安备44030502004977号
                      </span>
                    </a>
                  </span>
                </div>
              )}
            </div>
          </div>
        </LazyLoad>
        {uid ? (
          <img
            src={`https://qing.cleargrass.com/common/bi?client=web&uid=` + uid}
            alt=""
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLanguage: (val) => dispatch(actions.changeLanguage(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
