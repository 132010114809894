import React from 'react';

import s from './Dsecond.module.scss';

const Dsecond = () =>
    <div className={s.Dsecond}>
        <div className={s.title}>实时监测室内温湿度变化</div>
        <div className={s.desc}>家中温湿度一目了然</div>
        <div className={s.text}>
            <p>温湿度与人体舒适度和健康状况息息相关，北方的冬季湿度很低，空气干燥，而南方梅雨季，室内湿度过高。</p>
            <p>米家蓝牙温湿度计实时监测家中温湿度变化，简洁的段码 LCD 屏，</p>
            <p>字体清晰，读数轻松。 </p>
        </div>
    </div>;

export default Dsecond;

