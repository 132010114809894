import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Sfourth.module.scss'

class Sfourth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Sfourth.getBoundingClientRect();
        if (this.refs.text && clientHeight >= textRect.bottom-200) {
            setTimeout(() => {
                this.refs.text1.style.opacity = .9;
                this.refs.text1.style.top = '0';

                this.refs.text2.style.opacity = .6;
                this.refs.text2.style.top = '0';
            }, 300);
        }
    }
    render() {
        return (
            <div ref="Sfourth">
                <div className={s.Sfourth}>
                    <div className={s.wrap}>
                        <div className={s.text} ref="text">
                            <div className={s.title} ref="text1">
                                <FormattedMessage id="mp_why" />
                            </div>
                            <div className={s.Sfourth_desc} ref="text2">
                                <FormattedMessage id="mp_reason" values={{br: <br />}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sfourth;

