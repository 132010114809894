import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Sthirteenth.module.scss'

class Sthirteenth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Sthirteenth.getBoundingClientRect()
    if (clientHeight >= textRect.bottom - 200 && this.refs.Sthirteenth) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
      const { locale } = this.props
    return (
      <div ref="Sthirteenth" style={{backgroundColor: '#fff', paddingBottom: 20}}>
        <div className={locale.indexOf('US') > -1 ? s.Sthirteenth_en : s.Sthirteenth}>
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              <div className={s.title}>
                <FormattedMessage
                  id="chicken_thirty_title"
                  values={{ br: <br /> }}
                />
              </div>
               <div className={s.desc}>
                <FormattedMessage
                  id="chicken_thirty_desc1"
                  values={{ br: <br /> }}
                />
                <span className={s.line}>·</span>
                <FormattedMessage
                  id="chicken_thirty_desc2"
                  values={{ br: <br /> }}
                />
              </div>
            </div>
            <div className={s.pics}></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  let SThirteenth = connect(mapStateToProps)(Sthirteenth);

export default SThirteenth
