import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Header from './../header';
import Footer from './../footer';
import s from './bran.module.scss';

class bran extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            language: null,
            position: false
        };
    }
    componentDidMount() {
        if (this.refs.bran) {
            const clientHeight = document.documentElement.clientHeight;
            this.refs.bran.style.height = `${clientHeight * 0.75}px`;
        }
    }
    render() {
        const { location } = this.props;
        const path = this.props.match.path;
        return (
            <div className={s.box} ref="box">
                <Header location={location} language={this.state.language} position={this.state.position} path={path} />
                <div className={s.bran} ref="bran">
                    <a href="https://item.mi.com/product/9046.html" target="_blank" rel="noopener noreferrer">
                        <div className={s.text}>
                            <span className={s.large}>小米米家空气检测仪</span>
                            <span className={s.little}>全面检测空气质量，守护呼吸健康</span>
                            <span className={s.normal}>更多检测项目<span className={s.line}>·</span>高精度传感器<span className={s.line}>·</span>高清触摸屏<span className={s.line}>·</span>智能联动</span>
                            <span className={s.buy}>
                                <FormattedMessage id="buy" />
                            </span>
                        </div>
                    </a>
                </div>
                <Footer location={location} />
            </div>
        );
    }
}

export default bran;

