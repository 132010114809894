import React, { Component } from 'react'

// import Email from './../email';
import Dnav from '../Dnav'
import s from './Meun.module.scss'

class Meun extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
      display: false,
    }
  }
  render() {
    const that = this
    let cName = s.hide
    const path = that.props.path
    if (that.props.display) {
      // true: show
      cName = s.show
    } else {
      cName = s.hide
    }
    return (
      <div className={s.meun}>
        <div className={cName}>
          <div className={s.full_logo}>
            <a href="/">
              <i />
            </a>
          </div>
          <div className={s.line} />
          <Dnav
            path={path}
            clickCallback={() => {
              this.props.callback && this.props.callback()
            }}
          />
        </div>
      </div>
    )
  }
}

export default Meun
