import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './Ssecond.module.scss'

class Ssecond extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Ssecond.getBoundingClientRect();
        if (this.refs.Ssecond && clientHeight >= textRect.bottom) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 500);
        }
    }
    render() {
        const { locale } = this.props
        return (
            <div className={s.Ssecond} ref="Ssecond">
                <div className={locale.indexOf('US') > -1 ? s.wrap_en : (locale.indexOf('TW') > -1 ? s.wrap_tw : s.wrap)}>
                    <div className={s.text} ref="text">
                        <span className={s.large}>
                            <FormattedMessage id="s_thirdth_title" />
                        </span>
                        <span className={s.normal}>
                            <FormattedMessage id="s_thirdth_desc_1" />
                            <FormattedMessage id="s_thirdth_desc_2" />
                        </span>
                        <div className={s.iconList}>
                            <ul>
                                <li>
                                    <i className={s.SsecondPm25} />
                                    <FormattedMessage id="pm25" />
                                </li>
                                <li>
                                    <i className={s.SsecondTvoc} />
                                    <FormattedMessage id="tvoc_s" />
                                </li>
                                <li>
                                    <i className={s.SsecondCo2} />
                                    <FormattedMessage id="co2" />
                                </li>
                                <li>
                                    <i className={s.SsecondTemp} />
                                    <FormattedMessage id="temp" />
                                </li>
                                <li>
                                    <i className={s.SsecondHum} />
                                    <FormattedMessage id="hum" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });

  const SSecond = connect(mapStateToProps)(Ssecond)

export default SSecond;

