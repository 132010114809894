import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import s from './style.module.scss'
import Header from './../../header'
import Footer from './../../footer'
import common from '../../../../../locale/common'
const pages = [
  'firstPage',
  'secondPage',
  'thirdPage',
  'fourthPage',
  'fifthPage',
  'sixthPage',
  'seventhPage',
  'eighthPage',
  'ninethPage',
]

class Overview extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
      position: false,
      hideGooseChoice: true,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['sparrow_' + local]

    window.addEventListener('scroll', this._handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }

  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    // const scrollTop = document.documentElement.scrollTop;
    for (let i = 0; i < pages.length; i++) {
      const page = this.refs[pages[i] + ''].getBoundingClientRect()
      if (page.top < clientHeight - 200) {
        this.setState({ [pages[i]]: true })
      } else {
        this.setState({ [pages[i]]: false })
      }
    }
  }

  //隐藏购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }

  render() {
    const {
      secondPage,
      thirdPage,
      fourthPage,
      fifthPage,
      sixthPage,
      seventhPage,
      eighthPage,
      ninethPage,
      hideGooseChoice,
    } = this.state
    const { location, locale } = this.props
    const path = this.props.match.path
    return (
      <div
        className={s.sparrow}
        ref="box"
        onMouseOver={(e) => {
          e.persist()
          this.hideGooseChoiceFun(true, e)
        }}
      >
        <Header
          location={location}
          language={this.state.language}
          path={path}
          navPosition={this.state.position}
          hideGooseChoice={hideGooseChoice}
          hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
        />
        <div className={true ? s.one + ' ' + s.active : s.one} ref="firstPage">
          <div className={s.text}>
            {/* <h1>青萍蓝牙网关</h1> */}
            <h2 style={{ fontSize: 45, fontWeight: 'bold' }}>
              <FormattedMessage id="sp_pure" />
            </h2>
            <p>
              <FormattedMessage id="sp_automation" values={{ br: '' }} />
            </p>
            <div></div>
          </div>
          <div
            className={
              locale.indexOf('US') > -1 || locale.indexOf('TW') > -1
                ? s.icon_en
                : s.icon
            }
          ></div>
        </div>
        <div
          className={secondPage ? s.two + ' ' + s.active : s.two}
          ref="secondPage"
        >
          <div className={s.text}>
            <p className={s.top}>
              <FormattedMessage id="sp_wifi" values={{ br: '' }} />
            </p>
            <div>
              <p className={s.middle}>
                <FormattedMessage id="sp_remote_1" values={{ br: <br /> }} />
              </p>
            </div>
            <p className={s.two_bottom}>
              <FormattedMessage id="sp_desc_1" values={{ br: <br /> }} />
            </p>
          </div>
          <div className={s.img}>
            <div className={s.two_left_img}></div>
            <div
              className={
                locale.indexOf('US') > -1
                  ? s.two_right_img_en
                  : locale.indexOf('TW') > -1
                  ? s.two_right_img_tw
                  : s.two_right_img
              }
            ></div>
          </div>
        </div>
        <div
          className={thirdPage ? s.three + ' ' + s.active : s.three}
          ref="thirdPage"
        >
          <div className={s.text}>
            <p className={s.top}>
              <FormattedMessage id="sp_check" />
            </p>
            <div>
              <p className={s.middle}>
                <FormattedMessage id="sp_desc_2" values={{ br: <br /> }} />
              </p>
            </div>
            <p className={s.three_bottom}>
              <FormattedMessage id="sp_desc_3" values={{ br: <br /> }} />
            </p>
            <p className={s.three_bottom}>
              <FormattedMessage id="sp_desc_3_2" values={{ br: '' }} />
            </p>
          </div>
          <div className={s.img}>
            <div
              className={
                locale.indexOf('US') > -1
                  ? s.two_left_img_en
                  : locale.indexOf('TW') > -1
                  ? s.two_left_img_tw
                  : s.two_left_img
              }
            ></div>
            <div className={s.two_right_img}></div>
          </div>
        </div>

        <div
          className={fourthPage ? s.four + ' ' + s.active : s.four}
          ref="fourthPage"
        >
          <div
            className={
              locale.indexOf('US') > -1
                ? s.img_en
                : locale.indexOf('TW') > -1
                ? s.img_tw
                : s.img
            }
          >
            {/* <div className={s.four_img_top}>
              <span>
                <FormattedMessage id="sp_temp_rh" />
              </span>
              <span className={s.center}>
                <FormattedMessage id="sp_gateway" />
              </span>
              <span>
                <FormattedMessage id="sp_smart_lock" />
              </span>
            </div>
            <div className={s.four_img_center}>
              <div>
                <p>
                  <FormattedMessage id="sp_mihome" />
                </p>
                <p className={s.small}>
                <FormattedMessage id="sp_access" />
                </p>
                <p className={s.small}>
                <FormattedMessage id="sp_access_2" />
                </p>
              </div>
              <span>
              <FormattedMessage id="sp_server" />
              </span>
            </div>
            <div className={s.four_img_bottom}>
              <span>
              <FormattedMessage id="sp_aircondition" />
              </span>
              <span className={s.center}>
              <FormattedMessage id="sp_humidifier" />
              </span>
              <span>
              <FormattedMessage id="sp_heater" />
              </span>
            </div> */}
          </div>
          <div className={s.text}>
            <div>
              <h1>
                <FormattedMessage id="sp_smart" />
              </h1>
              <h1>
                <FormattedMessage id="sp_smart_2" />
              </h1>
            </div>
            <p>
            * <FormattedMessage id="support_mi_B"></FormattedMessage>
              <FormattedMessage id="sp_desc_4" values={{ br: <br /> }} />
              <a
                className={s.sparrow_a}
                href="https://home.mi.com/baike/index.html#/label/ability/753081211000000001"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="sp_desc_4_2" />
              </a>
              {/* <FormattedMessage id="end" /> */}
              <FormattedMessage id="sp_desc_4_3" values={{ br: <br /> }} />
            </p>
            {/* <p>青萍蓝牙网关能持续接收米家蓝牙网关</p>
            <p>子设备广播的蓝牙信号，并将收到的内容</p>
            <p>通过 Wi-Fi 上传到米家服务器，您可用米家</p>
            <p>app 远程查看蓝牙子设备的数据、配置它们</p>
            <p>与其他设备的智能联动策略。</p> */}
          </div>
        </div>
        <div
          className={fifthPage ? s.five + ' ' + s.active : s.five}
          ref="fifthPage"
        >
          <div className={locale.indexOf('US') > -1 ? s.content_en : s.content}>
            <h1>
              <FormattedMessage id="sp_baby_room" values={{ br: '' }} />
            </h1>
            <p>
              <FormattedMessage id="sp_desc_5" values={{ br: '' }} />
            </p>
            <div className={s.character}>
              <div className={s.character_item1}>
                <div className={s.character_icon}></div>
                <FormattedMessage id="sp_gateway_1" />
              </div>
              <div className={s.character_icon_1}></div>
              <div className={s.character_item2}>
                <div className={s.character_icon_2}></div>
                <FormattedMessage id="sp_temp_rh" />
              </div>
            </div>
            <p className={s.smalltext}>
              <FormattedMessage id="sp_desc_6" />
            </p>
            <p className={s.smalltext}>
              <FormattedMessage id="sp_desc_6_2" />
            </p>
          </div>
        </div>
        <div
          className={sixthPage ? s.six + ' ' + s.active : s.six}
          ref="sixthPage"
        >
          <div className={locale.indexOf('US') > -1 ? s.content_en : s.content}>
            <h1>
              <FormattedMessage id="sp_welcome_home" />
            </h1>
            <p>
              <FormattedMessage id="sp_desc_7" values={{ br: <br /> }} />
            </p>
            <div className={s.character}>
              <div className={s.character_item1}>
                <div className={s.character_icon}></div>
                <FormattedMessage id="sp_gateway_1" />
              </div>
              <div className={s.character_icon_1}></div>
              <div className={s.character_item2}>
                <div className={s.character_icon_2}></div>
                <FormattedMessage id="sp_smart_lock" />
              </div>
            </div>
            <p className={s.smalltext}>
              <FormattedMessage id="sp_desc_8" values={{ br: '' }} />
            </p>
          </div>
        </div>
        <div
          className={seventhPage ? s.seven + ' ' + s.active : s.seven}
          ref="seventhPage"
        >
          <div className={s.content}>
            <h1>
              <FormattedMessage id="sp_work_with" values={{ br: '' }} />
            </h1>
            <p>
              <FormattedMessage id="sp_desc_9" values={{ br: '' }} />
              <a
                className={s.sparrow_a}
                rel="noopener noreferrer"
                href="https://home.mi.com/baike/index.html#/label/ability/753081211000000001"
                target="_blank"
              >
                <FormattedMessage id="sp_desc_9_2" />
              </a>
              <FormattedMessage id="end" />
              <FormattedMessage id="sp_desc_9_3" />
            </p>
            <p>
              <FormattedMessage id="sp_desc_9_4" />
            </p>
            <p>
              <FormattedMessage id="sp_desc_9_5" />
            </p>
            <p className={s.seven_bottom}>
              <FormattedMessage id="sp_desc_9_6" />
            </p>
            <div></div>
            <p className={s.smalltext}>
              <FormattedMessage id="sp_accessories" />
              <a
                className={s.sparrow_a}
                rel="noopener noreferrer"
                href="https://home.mi.com/baike/index.html#/label/ability/753081211000000001"
                target="_blank"
              >
                <FormattedMessage id="sp_accessories_2" />
              </a>
              <FormattedMessage id="sp_accessories_3" />
            </p>
          </div>
        </div>
        <div
          className={eighthPage ? s.eight + ' ' + s.active : s.eight}
          ref="eighthPage"
        >
          <div ref="text">
            <h1>
              <FormattedMessage id="sp_small" />
            </h1>
            <p>
              <FormattedMessage id="sp_plugged" />
            </p>
          </div>
        </div>
        <div
          className={ninethPage ? s.nine + ' ' + s.active : s.nine}
          ref="ninethPage"
        >
          <div className={s.text}>
            <h1>
              <FormattedMessage id="sp_craftsmanship" />
            </h1>
            <p>
              <FormattedMessage id="sp_no_wasted" values={{ br: '' }} />
            </p>
          </div>
          <div className={s.img}></div>
        </div>
        <div className={s.ten}></div>
        <Footer location={location} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const OverView = connect(mapStateToProps)(Overview)

export default OverView
