import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Ssixth.module.scss'

class Ssixth extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { // 初始state
      index: 0,
      opacity: 1
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  handleScroll () {
    const clientHeight = document.documentElement.clientHeight;
    const textRect = this.refs.Ssixth.getBoundingClientRect();
    if (this.refs.Ssixth && clientHeight >= textRect.bottom - 100) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1;
        this.refs.text.style.top = '0';
      }, 500);
    }
  }
  render () {
    return (
      <div ref="Ssixth">
        <div className={s.Ssixth}>
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              <div className={s.title}>
                <FormattedMessage id="s_sixth_title" />
              </div>
              <div className={s.desc_1}>
                <FormattedMessage id="s_sixth_desc_1" values={{ spot: <span className={s.line}>·</span> }} />
              </div>
              <div className={s.desc_2}>
                <FormattedMessage id="s_sixth_desc_2" />
              </div>
            </div>
            <div className={s.img} ref="img">
              <video width="1000" height="500" src="https://web-site-1252188037.cos.ap-beijing.myqcloud.com/181011Snow_8HoiD8Aa7B.mp4" autoPlay loop muted />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ssixth;

