import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import Meun from '../meun'
import s from './Header.module.scss'
// import Dnav from './../Dnav';

class Header extends React.PureComponent {
  
  handleClick() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.setState(
      {
        // 修改state
        display: !this.state.display,
      },
      () => this.setOverflow()
    )
  }

  static preHandler(e) {
    e.preventDefault()
  }

  constructor(props) {
    super(props)
    this.state = {
      // init top hide
      language: null,
      display: false,
      ios: true,
      downloadHtml: null,
      toBrowser: s.toBrowser,
      purchaseHide: true,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    const ua = navigator.userAgent
    const appVersion = navigator.appVersion
    let browserLanguage = navigator.browserLanguage
      ? navigator.browserLanguage
      : navigator.language
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US'
        break
      case 'zh':
        browserLanguage = 'zh-CN'
        break
      default:
        browserLanguage = 'en-US'
    }
    if (/Android/.test(appVersion)) {
      if (/MicroMessenger/gi.test(ua)) {
        this.setState({
          downloadHtml: (
            <span className={s.download} onClick={() => this.handleShowPhone()}>
              <FormattedMessage id="download" />
            </span>
          ),
          ios: false,
        })
      } else {
        this.setState({
          downloadHtml: (
            <a
              className={s.download}
              href="market://details?id=com.cleargrass.app.air"
              rel="noopener noreferrer"
            >
              <span>
                <FormattedMessage id="download" />
              </span>
            </a>
          ),
          ios: false,
        })
      }
    } else {
      this.setState({
        downloadHtml: (
          <a
            className={s.download}
            href="https://itunes.apple.com/cn/app/%E9%9D%92%E8%90%8D%E7%A9%BA%E6%B0%94/id1344636968?mt=8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FormattedMessage id="download" />
            </span>
          </a>
        ),
      })
    }
    this.setState({
      language: browserLanguage,
    })
    this.setOverflow()
  }

  setOverflow() {
    // console.log(this.state.display);
    if (this.state.display) {
      if (this.state.ios) {
        document.getElementsByTagName('html')[0].style.position = 'fixed'
      }
      document.getElementsByTagName('html')[0].style.overflow = 'hidden'
      document.getElementsByTagName('html')[0].style.height = '100%'
      document.getElementsByTagName('html')[0].style.width = '100%'
      document.addEventListener('touchmove', Meun.preHandler, false)
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'auto'
      document.getElementsByTagName('html')[0].style.position = 'initial'
      document.getElementsByTagName('html')[0].style.height = 'auto'
      document.removeEventListener('touchmove', Meun.preHandler, false)
    }
  }

  handleShow(e, level) {
    e.stopPropagation()
    e.persist()
    const that = this
    if (level === 3) {
      that.props.hideSecondSubFunc(!this.props.hideSecondSub, e)
    } else {
      that.setState(
        {
          // 修改state
          display: !this.state.display,
        },
        () => that.setOverflow()
      )
    }
  }

  handleShowPhone() {
    this.setState({
      toBrowser: s.toBrowserShow,
    })
  }

  purchaseClick(e) {
    e.stopPropagation()
    this.setState({
      purchaseHide: !this.state.purchaseHide,
    })
  }

  showBuyChoice(e) {
    e.stopPropagation()
    e.persist()
    this.props.hideGooseChoice
      ? this.props.hideGooseChoiceFun(false, e)
      : this.props.hideGooseChoiceFun(true, e)
    // this.props.hideGooseChoiceFun(false, e)
  }

  render() {
    const locale = this.props.locale
    const location = this.props.location
    // const locale = this.props.location.search || `?locale=${this.state.language}`;
    const path = this.props.path

    const pathArr = this.props.path.split('/')
    let title = null
    let sName = s.hide
    let buyName =
      (pathArr[1] === 'temp-rh-monitor' ||
        pathArr[1] === 'motion-light-sensor') &&
      !this.props.hideGooseChoice
        ? s.gooseSecondBuy
        : s.secondBuy
    let butOrDownload = (
      <div className={s.buy_a} onClick={(e) => this.showBuyChoice(e)}>
        <span
          name="gooseBuy"
          style={{
            display: 'block',
            width: '100%',
            textAlign: 'center',
            paddingLeft: 0.5,
            paddingTop: 1,
          }}
        >
          {locale.indexOf('US') > -1
            ? 'Buy'
            : locale.indexOf('TW') > -1
            ? '購買'
            : '购买'}
        </span>
      </div>
    )
    const thirdCur = [null, null]
    // const secondCur = [null, null, null];
    let url = 'temp-rh-monitor'
    // let buyUrl = 'https://store.cleargrass.com/products/temp-rh-monitor';
    let secondSubName = s.secondSub
    let meunName = s.Icon
    let arrowName = s.arrow
    let purchaseName = s.purchaseHide
    switch (pathArr[1]) {
      case 'wall-socket-thermometer':
        title = <FormattedMessage id="gecko_name" />
        break
      case 'co2-temp-rh-monitor':
        title = <FormattedMessage id="ph_co2_name_value" />
        break
      case 'temp-rh-monitor-lite':
        title = <FormattedMessage id="duck2" />
        break
      case 'lee-guitars-thermo-hygrometer':
        title = <FormattedMessage id="magpie" values={{ br: '' }} />
        break
      case 'temp-rh-monitor-pro-s':
        title = <FormattedMessage id="pheasant" />
        break
      case 'temp-rh-monitor-pro-e':
        title = <FormattedMessage id="frog" values={{ br: '' }} />
        break
      case 'mi-temp-rh-monitor':
        title = '小米米家蓝牙温湿度计'
        break
      case 'air-monitor':
        title = <FormattedMessage id="snow" />
        break
      case 'air-monitor-2':
        title = <FormattedMessage id="sw2" />
        break
      case 'air-monitor-lite':
        title = <FormattedMessage id="dany_name" />
        break
      case 'door-window-sensor':
        title = <FormattedMessage id="hodor_name" values={{ br: '' }} />
        break
      case 'motion-light-sensor':
        title = <FormattedMessage id="parrot_name" values={{ br: '' }} />
        break
      case 'bluetooth-clock':
        title = <FormattedMessage id="chicken" values={{ br: '' }} />
        break
      case 'plus':
        title = <FormattedMessage id="cg_app" />
        break
      case 'lee-guitars':
        title = <FormattedMessage id="cg_magpie" />
        break
      case 'air':
        title = <FormattedMessage id="cg_app" />
        break
      case 'iot':
        title = <FormattedMessage id="qing_ping_iot_m" />
        break
      case 'bluetooth-gateway':
        title = <FormattedMessage id="sparrow" />
        break
      case 'bluetooth-alarm-clock':
        title = <FormattedMessage id="dove" />
        break
      default:
        title = <FormattedMessage id="goose_lite" />
    }
    if (!this.props.hideSecondSub) {
      sName = s.show
      arrowName = s.arrowCur
    } else {
      sName = s.hide
      arrowName = s.arrow
    }
    if (
      path === '/temp-rh-monitor-pro-s' ||
      path === '/temp-rh-monitor-pro-e'
    ) {
      sName = s.noDisplay
      buyName = s.noDisplay
      arrowName = s.noDisplay
    } else if (
      path === '/plus' ||
      path === '/lee-guitars/app' ||
      path === '/iot' ||
      path === '/air' ||
      path === '/apps/qingpingplus'
    ) {
      sName = s.noDisplay
      arrowName = s.noDisplay
      butOrDownload = this.state.downloadHtml
      // if (!this.props.downloadShow) {
      buyName = s.noDisplay
      // }
    } else if (
      path === '/' ||
      path === '/contact' ||
      path === '/store' ||
      path === '/privacy' ||
      path === '/about-us' ||
      path === '/eu-declarations'
    ) {
      secondSubName = s.noDisplay
      arrowName = s.noDisplay
    } else if (path === '/bluetooth-alarm-clock/apps') {
      secondSubName = s.noDisplay
      arrowName = s.noDisplay
      meunName = s.noDisplay
    }
    if (pathArr[1] === 'mi-temp-rh-monitor') {
      // secondCur[2] = s.active;
      // url = 'mi-temp-rh-monitor';
      // butOrDownload = <a href="https://item.mi.com/product/7303.html" target="_blank"
      //   rel="noopener noreferrer"><FormattedMessage id="buy" /></a>;
      secondSubName = s.noDisplay
    } else if (pathArr[1] === 'mi-multifunction-air-monitor') {
      secondSubName = s.noDisplay
    } else if (pathArr[1] === 'lee-guitars-thermo-hygrometer') {
      url = 'lee-guitars-thermo-hygrometer'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=627234761816"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'temp-rh-monitor-lite') {
      url = 'temp-rh-monitor-lite'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=632003208384"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'temp-rh-monitor-pro-s') {
      url = 'temp-rh-monitor-pro-s'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=601534027348"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'co2-temp-rh-monitor') {
      url = 'co2-temp-rh-monitor'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=719646205355"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'wall-socket-thermometer') {
      url = 'wall-socket-thermometer'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=731211875493"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'temp-rh-monitor-pro-e') {
      url = 'temp-rh-monitor-pro-e'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=651176963505"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'air-monitor') {
      url = 'air-monitor'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=592463435043"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'air-monitor-2') {
      url = 'air-monitor-2'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=592463435043"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'air-monitor-lite') {
      url = 'air-monitor-lite'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=635133749492"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'door-window-sensor') {
      url = 'door-window-sensor'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=636588241018"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'motion-light-sensor') {
      url = 'motion-light-sensor'
      // buyName = s.parrotSecondBuy
      // butOrDownload = (
      //   <a
      //     className={s.buy_a}
      //     href="https://detail.tmall.com/item.htm?id=642300517425"
      //     target="_blank"
      //     rel="noopener noreferrer"
      //   >
      //     <FormattedMessage id="buy" />
      //   </a>
      // )
    } else if (pathArr[1] === 'bluetooth-gateway') {
      url = 'bluetooth-gateway'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=606216176161"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'bluetooth-alarm-clock') {
      url = 'bluetooth-alarm-clock'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://www.xiaomiyoupin.com/detail?gid=119407"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    } else if (pathArr[1] === 'bluetooth-clock') {
      url = 'bluetooth-clock'
      butOrDownload = (
        <a
          className={s.buy_a}
          href="https://detail.tmall.com/item.htm?id=644288144909"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="buy" />
        </a>
      )
    }
    if (pathArr[2] === 'specifications') {
      thirdCur[1] = s.active
    } else {
      thirdCur[0] = s.active
    }
    if (this.state.display) {
      meunName = s.IconCur
    }
    if (!this.state.purchaseHide) {
      purchaseName = s.purchaseShow
    }

    let headerStyle = s.header

    if (this.props.headerShow === false) {
      headerStyle = s.noDisplay
    }

    return (
      <div className={headerStyle}>
        <div className={meunName} onClick={(e) => this.handleShow(e, 2)}>
          <i className={s.meun_1} />
          <i className={s.meun_2} />
          <i className={s.meun_1} />
        </div>
        <div className={s.logo}>
          <a href="/">
            <i />
          </a>
        </div>
        <div className={s.nav}>
          <Meun
            path={path}
            location={location}
            display={this.state.display}
            callback={() => {
              this.setState({
                display: false,
              })
            }}
          />
          <div className={secondSubName}>
            <div className={s.title}>
              {title}
              <div
                name="secondSub"
                className={arrowName}
                onClick={(e) => this.handleShow(e, 3)}
              />
            </div>
            <ul className={sName}>
              <li>
                <Link
                  name="secondSub"
                  to={`/${url}/overview`}
                  onClick={(e) => {
                    e.preventDefault()
                    this.handleShow(e, 3)
                  }}
                >
                  {title}
                </Link>
              </li>
              <li className={thirdCur[0]}>
                <Link
                  name="secondSub"
                  onClick={(e) => this.handleShow(e, 3)}
                  to={`/${url}/overview`}
                >
                  <FormattedMessage id="overview" />
                </Link>
              </li>
              <li className={thirdCur[1]}>
                <Link
                  name="secondSub"
                  onClick={(e) => this.handleShow(e, 3)}
                  to={`/${url}/specifications`}
                >
                  <FormattedMessage id="specifications" />
                </Link>
              </li>
            </ul>
            {/* <div name="secondSub" className={arrowName} onClick={(e) => this.handleShow(e, 3)} /> */}
            <div className={buyName}>{butOrDownload}</div>
            {pathArr[1] === 'temp-rh-monitor' && !this.props.hideGooseChoice ? (
              <div className={s.buyBox}>
                <div className={s.buyItem}>
                  <a
                    href="https://detail.tmall.com/item.htm?id=695482721170&skuId=5110652835001"
                    target="_blank"
                    rel="noopener noreferrer"
                    name="goose_tmall"
                  >
                    {locale.indexOf('US') > -1
                      ? 'T Version'
                      : locale.indexOf('TW') > -1
                      ? '青萍溫濕度計 T 版'
                      : '青萍温湿度计 T 版'}
                  </a>
                </div>
                {/* <div className={s.buyItem}>
                  <a
                    href="https://detail.tmall.com/item.htm?id=624676310772"
                    target="_blank"
                    rel="noopener noreferrer"
                    name="goose_tmall"
                  >
                    {locale.indexOf('US') > -1
                      ? 'H Version'
                      : locale.indexOf('TW') > -1
                      ? '青萍藍牙溫濕度計 H 版'
                      : '青萍蓝牙温湿度计 H 版'}
                  </a>
                </div> */}
                <div className={s.buyItem}>
                  <a
                    href="https://detail.tmall.com/item.htm?id=591748428692"
                    target="_blank"
                    rel="noopener noreferrer"
                    name="goose_tmall"
                  >
                    {locale.indexOf('US') > -1
                      ? 'M Version'
                      : locale.indexOf('TW') > -1
                      ? '青萍藍牙溫濕度計 M 版'
                      : '青萍蓝牙温湿度计 M 版'}
                  </a>
                </div>
              </div>
            ) : null}

            {pathArr[1] === 'motion-light-sensor' &&
            !this.props.hideGooseChoice ? (
              <div
                className={
                  locale.indexOf('US') > -1 ? s.buyBox : s.parrotBuyBox
                }
              >
                <div className={s.buyItem}>
                  <a
                    href="https://detail.tmall.com/item.htm?id=717364573346"
                    target="_blank"
                    rel="noopener noreferrer"
                    name="goose_tmall"
                  >
                    {locale.indexOf('US') > -1
                      ? 'T Version'
                      : locale.indexOf('TW') > -1
                      ? '青萍動作和環境光傳感器 T 版'
                      : '青萍动作和环境光传感器 T 版'}
                  </a>
                </div>
                <div className={s.buyItem}>
                  <a
                    href="https://detail.tmall.com/item.htm?id=642300517425"
                    target="_blank"
                    rel="noopener noreferrer"
                    name="goose_tmall"
                  >
                    {locale.indexOf('US') > -1
                      ? 'M Version'
                      : locale.indexOf('TW') > -1
                      ? '青萍動作和環境光傳感器 M 版'
                      : '青萍动作和环境光传感器 M 版'}
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={this.state.toBrowser}>
          <div className={s.arrow} />
          <div className={s.toBrowserText}>
            1.
            点击右上角&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br />
            2. 在菜单中选择 <span>“浏览器中打开”</span>
          </div>
          <div className={s.toBrowserPhone} />
        </div>
        <div className={purchaseName}>
          <span>
            请发送邮件至{' '}
            <a
              href="mailto:bd@qingping.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              bd@qingping.co
            </a>
            ，<br />
            或致电李先生 <a href="tel:18911877866">189 1187 7866</a> 获取报价。
          </span>
          <i onClick={(e) => this.purchaseClick(e)} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const header = connect(mapStateToProps)(Header)

export default header
