import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LazyLoad from 'react-lazyload';

import s from './Geighth.module.scss'

class Geighth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Geighth.getBoundingClientRect();
        if (clientHeight >= textRect.bottom && this.refs.img) {
            setTimeout(() => {
                this.refs.img.style.opacity = 1;
            }, 200);
        }
    }
    render() {
        return (
            <div className={s.Geighth} ref="Geighth">
                <div className={s.text}>
                    <span className={s.large}>
                        <FormattedMessage id="eighth_title" />
                    </span>
                    <span className={s.small}>
                        <FormattedMessage id="eighth_desc" />
                    </span>
                </div>
                <LazyLoad height={305}>
                    <div className={s.img} ref="img" />
                </LazyLoad>
            </div>
        );
    }
}

export default Geighth;
