import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Psecond.module.scss'

class Psecond extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
  }
  componentDidMount() {}
  componentWillUnmount() {}

  render() {
    const { locale } = this.props
    return (
      <div
        className={locale.indexOf('US') > -1 ? s.Psecond_en : s.Psecond}
        ref="Psecond"
      >
        <div className={s.wrap}>
          <div
            className={s.text}
            style={{ top: locale.indexOf('US') > -1 ? '-50px' : 0 }}
            ref="text"
          >
            <span className={s.large}>
              <FormattedMessage id="ph_co2_nine_title" values={{ br: '' }} />
            </span>
            <span className={s.little}>
              <FormattedMessage id="ph_co2_nine_desc" />
            </span>
          </div>
          <ul className={s.iconList} ref="iconList">
            <li>
              <i className={s.second_wifi} />
              <FormattedMessage id="frog_sixth_wifi" values={{ br: <br /> }} />
            </li>
            <li>
              <i className={s.second_lora} />
              <FormattedMessage id="ph_co2_nine_lora" />
            </li>
            <li>
              <i className={s.second_nbiot} />
              <FormattedMessage id="ph_co2_nine_nb" values={{ br: <br /> }} />
            </li>
          </ul>
          <div className={s.memo}>
            <FormattedMessage id="ph_co2_nine_memo" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PSecond = connect(mapStateToProps)(Psecond)

export default PSecond
