import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import s from './Product.module.scss'

class Product extends React.PureComponent {
  toTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  constructor(props) {
    super(props)
    this.state = {
      language: null,
    }
  }

  render() {
    const { locale } = this.props
    return (
      <div className={s.product}>
        {locale.indexOf('US') > -1 ? (
          <ul className={s.ul_en}>
            <li className={s.goose}>
              <Link to="/temp-rh-monitor/overview" onClick={() => this.toTop()}>
                <div className={s.title}>
                  <FormattedMessage id="goose_lite" />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="goose_screen" />
                  </li>
                  <li>
                    <FormattedMessage id="support" />
                  </li>
                  <li>
                    <FormattedMessage id="wall" />
                  </li>
                  <li>
                    <FormattedMessage id="user" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.duck2}>
              <Link
                to="/temp-rh-monitor-lite/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="duck2" />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="support" />
                  </li>
                  <li>
                    <FormattedMessage id="wall" />
                  </li>
                  <li>
                    <FormattedMessage id="duck2_support" />
                  </li>
                  <li>
                    <FormattedMessage id="user" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.magpie}>
              <Link
                to="/lee-guitars-thermo-hygrometer/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="magpie" values={{ br: <br /> }} />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="grasp_guitar_sip" />
                  </li>
                  <li>
                    <FormattedMessage id="e_link" />
                  </li>
                  <li>
                    <FormattedMessage id="record_history_data" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.hodor}>
              <Link
                to="/door-window-sensor/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="hodor" />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="hodor_monitor" />
                  </li>
                  <li>
                    <FormattedMessage id="automation" />
                  </li>
                  <li>
                    <FormattedMessage id="p_wifi_value" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.parrot}>
              <Link
                to="/motion-light-sensor/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="parrot_title" />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="parrot_des1_1" />
                  </li>
                  <li>
                    <FormattedMessage id="parrot_des1_2" />
                  </li>
                  <li>
                    <FormattedMessage id="parrot_des2" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.dove}>
              <Link
                to="/bluetooth-alarm-clock/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="dove" />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="button_less" />
                  </li>
                  <li>
                    <FormattedMessage id="temp_rh_mon" />
                  </li>
                  <li>
                    <FormattedMessage id="soft_backlight" />
                  </li>
                  <li>
                    <FormattedMessage id="sixteen_ala" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.chicken}>
              <Link to="/bluetooth-clock/overview" onClick={() => this.toTop()}>
                <div className={s.title}>
                  <FormattedMessage id="chicken_title" />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="chicken_des1_1" />
                  </li>
                  <li>
                    <FormattedMessage id="chicken_des1_2" />
                  </li>
                  <li>
                    <FormattedMessage id="chicken_des2" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.sparrow}>
              <Link
                to="/bluetooth-gateway/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="sparrow" />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="connect_mi_en_1" />
                  </li>
                  {/* <li>
                    <FormattedMessage id="connect_mi_en_2" />
                  </li> */}
                  <li>
                    <FormattedMessage id="enable_remote" />
                  </li>
                  <li>
                    <FormattedMessage id="automation" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.lora}>
              <Link
                to="/temp-rh-monitor-pro-s/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="pheasant_s" values={{ br: <br /> }} />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="l_support" />
                  </li>
                  <li>
                    <FormattedMessage id="l_battery" />
                  </li>
                  <li>
                    <FormattedMessage id="l_user" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.frog}>
              <Link
                to="/temp-rh-monitor-pro-e/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="frog" values={{ br: <br /> }} />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="frog_support_1" />
                  </li>
                  <li>
                    <FormattedMessage id="frog_support_2" />
                  </li>
                  <li>
                    <FormattedMessage id="frog_support_3" />
                  </li>
                  <li>
                    <FormattedMessage id="frog_support_4" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.pheasant_co2}>
              <Link
                to="/co2-temp-rh-monitor/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title} style={{ paddingRight: '45%' }}>
                  <FormattedMessage id="pheasant_co2_title" />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="pheasant_co2_des1_1" />
                  </li>
                  <li>
                    <FormattedMessage id="pheasant_co2_des1_2" />
                  </li>
                  <li>
                    <FormattedMessage id="pheasant_co2_des2" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.snow}>
              <Link to="/air-monitor-2/overview" onClick={() => this.toTop()}>
                <div className={s.title}>
                  <FormattedMessage id="snow2" />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="pm25" />
                    <span>·&nbsp;</span>
                    <FormattedMessage id="pm10" />
                    <span>·&nbsp;</span>
                    <FormattedMessage id="noise" />
                    <span>·&nbsp;</span>
                    <FormattedMessage id="co2" />
                    <br />
                    {/* <span>·&nbsp;</span> */}
                    <FormattedMessage id="etvoc" />
                    <span>·&nbsp;</span>
                    <FormattedMessage id="temp_m" />
                    <br />
                    {locale.indexOf('US') > -1 ? null : (
                      <FormattedMessage id="hum" />
                    )}
                  </li>
                  <li>
                    <FormattedMessage id="snow2_sensor" />
                  </li>
                  <li>
                    <FormattedMessage id="snow_screen" />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.dany}>
              <Link
                to="/air-monitor-lite/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="dany" />
                </div>
                <ul className={s.ul}>
                  <li>
                    <FormattedMessage id="pm25" />
                    <span>,&nbsp;</span>
                    <FormattedMessage id="co2" />
                    {/* <span>,&nbsp;</span>
                    <FormattedMessage id="tvoc_s" /> */}
                    <span>,&nbsp;</span>
                    <FormattedMessage id="temp_m" />
                    <br />
                    {locale.indexOf('US') > -1 ? null : (
                      <FormattedMessage id="hum" />
                    )}
                  </li>
                  <li>
                    <FormattedMessage id="touchbar" />
                  </li>
                  <li>
                    <FormattedMessage
                      id="support_all"
                      values={{ br: <br /> }}
                    />
                  </li>
                </ul>
              </Link>
            </li>

            <li className={s.qingpingiot}>
              <Link to="/plus" onClick={() => this.toTop()}>
                <div className={s.qingpingiot_content}>
                  <div className={s.plus_left}></div>
                  <div className={s.qingpingiot_right}>
                    <div className={s.title}>
                      <FormattedMessage id="cg_app" />
                    </div>
                    <ul className={s.ul}>
                      <li>
                        <FormattedMessage id="device_management" />
                      </li>
                      <li>
                        <FormattedMessage id="data_insights" />
                      </li>
                      <li>
                        <FormattedMessage id="historical_data" />
                      </li>
                      <li>
                        <FormattedMessage id="access_remote" />
                      </li>
                    </ul>
                  </div>
                </div>
              </Link>
            </li>
            <li className={s.qingpingiot}>
              <a
                href="https://qingpingiot.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={s.qingpingiot_content}>
                  <div className={s.qingpingiot_left}></div>
                  <div className={s.qingpingiot_right}>
                    <div className={s.title}>
                      <FormattedMessage id="qing_ping_iot" />
                    </div>
                    <ul className={s.ul}>
                      <li>
                        <FormattedMessage id="team_management" />
                      </li>
                      <li>
                        <FormattedMessage id="data_collection" />
                      </li>
                      <li>
                        <FormattedMessage id="device_sharing" />
                      </li>
                      <li>
                        <FormattedMessage id="alarm_notification" />
                      </li>
                    </ul>
                  </div>
                </div>
              </a>
            </li>
            <li className={s.qingpingiot}>
              <a
                href="https://developer.qingping.co"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={s.qingpingiot_content}>
                  <div className={s.developer_left}></div>
                  <div className={s.qingpingiot_right}>
                    <div className={s.title}>
                      <FormattedMessage id="index_developer" />
                    </div>
                    <ul className={s.ul}>
                      <li>
                        <FormattedMessage id="customize_device" />
                      </li>
                      <li>
                        <FormattedMessage id="own_platform_m_1" />
                      </li>
                      <li>
                        <FormattedMessage id="own_platform_m_2" />
                      </li>
                    </ul>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        ) : (
          <ul className={s.ul_cn}>
            <li className={s.goose}>
              <Link to="/temp-rh-monitor/overview" onClick={() => this.toTop()}>
                <div className={s.title}>
                  <FormattedMessage id="goose_lite" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="goose_screen" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="support" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="wall" />
                  <br />
                  <FormattedMessage id="user" />
                </div>
              </Link>
            </li>
            <li className={s.duck2}>
              <Link
                to="/temp-rh-monitor-lite/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="duck2" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="support" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="wall" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="duck2_support" />
                  <br />
                  <FormattedMessage id="user" />
                </div>
              </Link>
            </li>
            <li className={s.magpie}>
              <Link
                to="/lee-guitars-thermo-hygrometer/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="magpie" values={{ br: '' }} />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="grasp_guitar_sip" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="e_link" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="record_history_data" />
                </div>
              </Link>
            </li>
            <li className={s.hodor}>
              <Link
                to="/door-window-sensor/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="hodor" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="hodor_monitor" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="automation" />
                  <br />
                  <FormattedMessage id="p_wifi_value" />
                </div>
              </Link>
            </li>
            <li className={s.parrot}>
              <Link
                to="/motion-light-sensor/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="parrot_title" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="parrot_des1" />
                  <br />
                  <FormattedMessage id="parrot_des2" />
                </div>
              </Link>
            </li>
            <li className={s.dove}>
              <Link
                to="/bluetooth-alarm-clock/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="dove" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="button_less" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="temp_rh_mon" />
                  <br />
                  <FormattedMessage id="soft_backlight" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="sixteen_ala" />
                </div>
              </Link>
            </li>
            <li className={s.chicken}>
              <Link to="/bluetooth-clock/overview" onClick={() => this.toTop()}>
                <div className={s.title}>
                  <FormattedMessage id="chicken_title" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="chicken_des1" />
                  <br />
                  <FormattedMessage id="chicken_des2" />
                </div>
              </Link>
            </li>
            <li className={s.sparrow}>
              <Link
                to="/bluetooth-gateway/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="sparrow" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="connect_mi" />
                  <br />
                  <FormattedMessage id="enable_remote" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="automation" />
                </div>
              </Link>
            </li>
            <li className={s.lora}>
              <Link
                to="/temp-rh-monitor-pro-s/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="pheasant_s" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="l_support" />
                  <br />
                  <FormattedMessage id="l_battery" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="l_user" />
                </div>
              </Link>
            </li>
            <li className={s.frog}>
              <Link
                to="/temp-rh-monitor-pro-e/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="frog" values={{ br: <br /> }} />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="frog_support_1" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="frog_support_2" />
                  <br />
                  <FormattedMessage id="frog_support_3" />
                  <br />
                  <FormattedMessage id="frog_support_4" />
                </div>
              </Link>
            </li>
            <li className={s.pheasant_co2}>
              <Link
                to="/co2-temp-rh-monitor/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="pheasant_co2_title" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="pheasant_co2_des1" />
                  <br />
                  <FormattedMessage id="pheasant_co2_des2" />
                </div>
              </Link>
            </li>
            <li className={s.snow}>
              <Link to="/air-monitor-2/overview" onClick={() => this.toTop()}>
                <div className={s.title}>
                  <FormattedMessage id="snow2" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="pm25" />
                  <span>&nbsp;·&nbsp;</span>
                  <FormattedMessage id="pm10" />
                  <span>&nbsp;·&nbsp;</span>
                  <FormattedMessage id="noise" />
                  <span>&nbsp;·&nbsp;</span>
                  <FormattedMessage id="co2" />
                  {/* <span>&nbsp;·&nbsp;</span> */}
                  <br />
                  <FormattedMessage id="etvoc" />
                  <span>&nbsp;·&nbsp;</span>
                  <FormattedMessage id="temp" />
                  <br />
                  <FormattedMessage id="hum" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="snow2_sensor" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="snow_screen" />
                </div>
              </Link>
            </li>
            <li className={s.dany}>
              <Link
                to="/air-monitor-lite/overview"
                onClick={() => this.toTop()}
              >
                <div className={s.title}>
                  <FormattedMessage id="dany" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage id="pm25" />
                  <span>&nbsp;·&nbsp;</span>
                  <FormattedMessage id="co2" />
                  {/* <span>&nbsp;·&nbsp;</span>
                  <FormattedMessage id="tvoc_s" /> */}
                  <span>&nbsp;·&nbsp;</span>
                  <FormattedMessage id="temp" />
                  <br />
                  <FormattedMessage id="hum" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="touchbar" />
                  <span className={s.line}>·</span>
                  <FormattedMessage id="support_all" values={{ br: <br /> }} />
                </div>
              </Link>
            </li>

            <li className={s.qingpingiot}>
              <Link to="/plus" onClick={() => this.toTop()}>
                <div className={s.qingpingiot_content}>
                  <div className={s.plus_left}></div>
                  <div className={s.qingpingiot_right}>
                    <div className={s.title}>
                      <FormattedMessage id="cg_app" />
                    </div>
                    <div className={s.desc}>
                      <FormattedMessage id="device_management" />
                      <span className={s.line}>·</span>
                      <FormattedMessage id="data_insights" />
                      <br />
                      <FormattedMessage id="historical_data" />
                      <span className={s.line}>·</span>
                      <FormattedMessage id="access_remote" />
                    </div>
                  </div>
                </div>
              </Link>
            </li>
            <li className={s.qingpingiot}>
              <Link to="/iot" onClick={() => this.toTop()}>
                <div className={s.qingpingiot_content}>
                  <div className={s.qingpingiot_left}></div>
                  <div className={s.qingpingiot_right}>
                    {' '}
                    <div className={s.title}>
                      <FormattedMessage id="qing_ping_iot" />
                    </div>
                    <div className={s.desc}>
                      <FormattedMessage id="team_management" />
                      <span className={s.line}>·</span>
                      <FormattedMessage id="data_collection" />
                      <br />
                      <FormattedMessage id="device_sharing" />
                      <span className={s.line}>·</span>
                      <FormattedMessage id="alarm_notification" />
                    </div>
                  </div>
                </div>
              </Link>
            </li>
            <li className={s.qingpingiot}>
              <a href="https://developer.qingping.co">
                <div className={s.qingpingiot_content}>
                  <div className={s.developer_left}></div>
                  <div className={s.qingpingiot_right}>
                    {' '}
                    <div className={s.title}>
                      <FormattedMessage id="index_developer" />
                    </div>
                    <div className={s.desc}>
                      <FormattedMessage id="customize_device" />
                      <span className={s.line}>·</span>
                      <FormattedMessage id="own_platform_m_1" />
                      <br />
                      <FormattedMessage id="own_platform_m_2" />
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let product = connect(mapStateToProps)(Product)

export default product
