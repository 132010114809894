import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import s from './Pthird.module.scss'

class Pthird extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    // window.addEventListener('scroll', this._handleScroll)
    // this.refs.Pthird.style.backgroundSize = '1920px';
  }
  componentWillUnmount() {
    // window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const textRect = this.refs.Pthird.getBoundingClientRect()
    if (this.refs.Pthird && textRect.top < 500) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 0)
    }
    if (this.refs.Pthird && textRect.top < -20) {
      setTimeout(() => {
        this.refs.iconList.style.opacity = 1
        this.refs.iconList.style.bottom = '100px'
      }, 0)
    }
  }
  render() {
    return (
      <div className={s.Pthird} ref="Pthird">
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <span className={s.large}>
              <FormattedMessage id="frog_eleven_title" />
            </span>
            <span className={s.normal}>
              <FormattedMessage id="frog_eleven_desc" />
            </span>
          </div>
          <div className={s.iconList} ref="iconList">
            <div className={s.container}>
              <span>
                <FormattedMessage id="frog_eleven_pharmacy" />
              </span>
              <span style={{ width: 230 }}>
                <FormattedMessage id="frog_eleven_garbage" />
              </span>
              <span style={{ width: 230 }}>
                <FormattedMessage id="frog_eleven_farm" />
              </span>
              <span style={{ width: 230 }}>
                <FormattedMessage id="frog_eleven_greenhouse" />
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Pthird
