import React, { Component } from 'react';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';

import s from './Sfifteen.module.scss'

class Sfifteen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            isShow: false
        };
        this._handleScroll = this.handleScroll.bind(this);
        this._handleKeypress1 = this.handleKeypress1.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
        // window.addEventListener('keypress', this._handleKeypress);
        document.onkeydown = this._handleKeypress1; 
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Sfifteen.getBoundingClientRect();
        const clientWidth = document.documentElement.clientWidth;
        if (this.refs.img_5) {
            this.refs.row_1.style.width = `${clientWidth + 200}px`;
            this.refs.row_2.style.width = `${clientWidth + 200}px`;
            this.refs.img_1.style.width = `${(clientWidth + 170) / 3}px`;
            this.refs.img_2.style.width = `${(clientWidth + 170) / 3}px`;
            this.refs.img_3.style.width = `${(clientWidth + 170) / 3}px`;
            this.refs.img_4.style.width = `${(clientWidth + 185) / 2}px`;
            this.refs.img_5.style.width = `${(clientWidth + 185) / 2}px`;
            if ((clientHeight + 695) >= textRect.bottom && this.refs.row_2) {
                this.refs.row_1.style.left = `${(((clientHeight + 495) - textRect.bottom) / 5) - 200}px`;
                this.refs.row_2.style.left = `${-(((clientHeight + 495) - textRect.bottom) / 5)}px`;
            }
        }
    }
    handleKeypress1(e) {
        console.log(e);
        if (this.state.isShow) {
            if (e.keyCode === 37) {
                this.tab('left');
            }
            if (e.keyCode === 39) {
                this.tab('right');
            }
            if (e.keyCode === 27) {
                this.setState({
                    isShow: false
                });
            }
        }
    }
    tab(type) {
        let index = this.state.index;
        if (type === 'right') {
            if (index === 4) {
                index = 0;
            } else {
                index++;
            }
        }
        if (type === 'left') {
            if (index === 0) {
                index = 4;
            } else {
                index--;
            }
        }
        this.refs.items.style.left = `${-960 * index}px`;
        this.setState({
            index: index
        });
    }
    showPic(index) {
        if (index || index === 0) {
            this.setState({
                index: index,
                isShow: !this.state.isShow
            });
            this.refs.items.style.left = `${-960 * index}px`;
        } else {
            this.setState({
                isShow: !this.state.isShow
            });
        }
    }
    render() {
        const { locale } = this.props
        let showOrhide = s.hide;
        if (this.state.isShow) {
            showOrhide = s.show;
        }
        console.log(locale, 'locale of render')
        return (
            <div ref="Sfifteen">
                <LazyLoad height={870}>
                    <div className={s.Sfifteen}>
                        <div className={s.row_1} ref="row_1">
                            <i className={locale.indexOf('US') > -1 ? s.img_1_en : (locale.indexOf('TW') > -1 ? s.img_1_tw : s.img_1)} ref="img_1" onClick={() => this.showPic(0)} />
                            <i className={s.img_2} ref="img_2" onClick={() => this.showPic(1)} />
                            <i className={locale.indexOf('US') > -1 ? s.img_3_en : (locale.indexOf('TW') > -1 ? s.img_3_tw : s.img_3)} ref="img_3" onClick={() => this.showPic(2)} />
                        </div>
                        <div className={s.row_2} ref="row_2">
                            <i className={s.img_4} ref="img_4" onClick={() => this.showPic(3)} />
                            <i className={locale.indexOf('US') > -1 ? s.img_5_en : (locale.indexOf('TW') > -1 ? s.img_5_tw : s.img_5)} ref="img_5" onClick={() => this.showPic(4)} />
                        </div>
                        <div className={showOrhide}>
                            <div className={s.bg} onClick={() => this.showPic()} />
                            <div className={s.pic}>
                                <div className={s.left} onClick={() => this.tab('left')} />
                                <div className={s.items}>
                                    <ul ref="items">
                                        <li className={locale.indexOf('US') > -1 ? s.pic_1_en : (locale.indexOf('TW') > -1 ? s.pic_1_tw : s.pic_1)} />
                                        <li className={s.pic_2} />
                                        <li className={locale.indexOf('US') > -1 ? s.pic_3_en : (locale.indexOf('TW') > -1 ? s.pic_3_tw : s.pic_3)} />
                                        <li className={s.pic_4} />
                                        <li className={locale.indexOf('US') > -1 ? s.pic_5_en : (locale.indexOf('TW') > -1 ? s.pic_5_tw : s.pic_5)} />
                                    </ul>
                                </div>
                                <div className={s.right} onClick={() => this.tab('right')} />
                            </div>
                        </div>
                    </div>
                </LazyLoad>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  const SFifteen = connect(mapStateToProps)(Sfifteen)

export default SFifteen;

