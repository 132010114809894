import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import s from './airAgreement.module.scss'
import Dnav from './../../mobile/Dnav'

class AirAgreement extends React.PureComponent {
    static handleClick() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.setState({ // 修改state
            display: !this.state.display
        }, () => this.setOverflow());
    }
    constructor(props) {
        super(props);
        this.state = { // init top hide
            display: false
        };
    }
    componentDidMount() {
        document.getElementsByTagName('html')[0].style.fontSize = 41.4 + 'px'
    }
    render() {
        return (
            <div className={s.box}>
                <div className={s.Bprivacy}>
                    <p><b className={s.pt18}>软件许可及服务协议</b></p>
                    <p><b>【重要须知】</b></p>
                    <p className={s.pt135}>【青萍科技（北京）有限公司】（如下简称“青萍”）在此特别提醒用户认真阅读、充分理解本《软件许可及服务协议》（下称“本协议”）。<b>用户应认真阅读、充分理解本协议中各条款，特别涉及免除或者限制青萍责任、争议解决和法律适用的条款。免除或者限制责任的条款将以粗体标识，您需要重点阅读。请您审慎阅读并选择接受或不接受本协议（未成年人应在法定监护人陪同下阅读）。您的下载、安装、使用本软件以及账号获取和登录等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</b></p>

                    <p><b>青萍有权修订本协议，更新后的协议条款将公布于官网或软件，自公布之日起生效。</b>用户可重新下载安装本软件或网站查阅最新版协议条款。<b>在青萍修改本协议条款后，如果用户不接受修改后的条款，请立即停止使用青萍提供的“青萍空气”软件和服务，用户继续使用青萍提供的“青萍空气”软件和服务将被视为已接受了修改后的协议。</b></p>

                    <p className={s.pt12}><b>一、总则</b><br />
                    1.1. 本协议是您（如下也称“用户”）与青萍及其运营合作单位（如下简称“合作单位”）之间关于用户下载、安装、使用青萍“青萍空气”软件（下称“本软件”）以及使用青萍相关服务所订立的协议。<br />
                    1.2. 本软件及服务的所有权和运营权均归青萍所有。<br /></p>

                    <p className={s.pt12}><b>二、软件授权范围</b><br />
                    2.1. 青萍就本软件给予用户一项个人的、不可转让、不可转授权以及非独占性的许可。<br />
                    2.2. 用户可以为非商业目的在单一台移动终端设备上安装、使用、显示、运行本软件。但用户不得为商业运营目的安装、使用、运行本软件，不可以对本软件或者本软件运行过程中释放到任何终端设备内存中的数据及本软件运行过程中客户端与服务器端的交互数据进行复制、更改、修改、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入本软件和相关系统。如果需要进行商业性的销售、复制和散发，例如软件预装和捆绑，必须获得青萍的书面授权和许可。<br />
                    2.3. 用户不得未经青萍许可，将本软件安装在未经青萍明示许可的其他终端设备上，包括但不限于机顶盒、游戏机、电视机、DVD机等。<br />
                    2.4. 用户可以为使用本软件及服务的目的复制本软件的一个副本，仅用作备份。备份副本必须包含原软件中含有的所有著作权信息。<br />
                    2.5. 除本《协议》明示授权外，青萍未授权给用户其他权利，若用户使用其他权利时须另外取得青萍的书面同意。</p>

                    <p className={s.pt12}><b>三、软件的获取、安装、升级</b><br />
                    3.1. 用户应当按照青萍的指定网站或指定方式下载安装本软件产品。谨防在非指定网站下载本软件，以免移动终端设备感染能破坏用户数据和获取用户隐私信息的恶意程序。如果用户从未经青萍授权的第三方获取本软件或与本软件名称相同的安装程序，青萍无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。<br />
                    3.2. 用户必须选择与所安装终端设备相匹配的本软件版本，否则，由于软件与设备型号不相匹配所导致的任何软件问题、设备问题或损害，均由用户自行承担。<br />
                    3.3. 为了改善用户体验、完善服务内容，青萍有权不时地为您提供本软件替换、修改、升级版本，也有权为替换、修改或升级收取费用，但将收费提前征得您的同意。本软件为用户默认开通“升级提示”功能，视用户使用的软件版本差异，青萍提供给用户自行选择是否需要开通此功能。软件新版本发布后，青萍不保证旧版本软件的继续可用。</p>

                    <p className={s.pt12}>
                        <b>四、使用规范</b><br />
                        <b>4.1. 用户在遵守法律及本《协议》的前提下可依本《协议》使用本软件及服务，用户不得实施如下行为：</b><br />
                        4.1.1. 删除本软件及其他副本上一切关于版权的信息，以及修改、删除或避开本软件为保护知识产权而设置的技术措施；<br />
                        4.1.2. 对本软件进行反向工程，如反汇编、反编译或者其他试图获得本软件的源代码；<br />
                        4.1.3. 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；<br />
                        4.1.4. 使用本软件进行任何危害网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众网络或者他人操作系统并删除、修改、增加存储信息；未经许可企图探查、扫描、测试本软件的系统或网络的弱点或其它实施破坏网络安全的行为； 企图干涉、破坏本软件系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；<br />
                        4.1.5. 用户通过非青萍公司开发、授权或认可的第三方兼容软件、系统登录或使用本软件及服务，或制作、发布、传播上述工具；<br />
                        4.1.6. 未经青萍书面同意，用户对软件及其中的信息擅自实施包括但不限于下列行为：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助本软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等；<br />
                        4.1.7. 利用本软件发表、传送、传播、储存违反您当地法律的内容；若您是中华人民共和国大陆地区用户时，利用本软件发表、传送、传播、储存违反国家法律、危害国家安全、祖国统一、社会稳定、公序良俗的内容，或任何不当的、侮辱诽谤的、淫秽的、暴力的及任何违反国家法律法规政策的内容；<br />
                        4.1.8. 利用本软件发表、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；<br />
                        4.1.9. 利用本软件批量发表、传送、传播广告信息及垃圾信息；<br />
                        4.1.10. 其他以任何不合法的方式、为任何不合法的目的、或以任何与本协议许可使用不一致的方式使用本软件和青萍提供的其他服务；<br />
                        <b>4.2. 信息发布规范</b><br />
                        4.2.1．您可使用本软件发表属于您原创或您有权发表的观点看法、数据、文字、信息、用户名、图片、照片、个人信息、音频、视频文件、链接等信息内容。您必须保证，您拥有您所上传信息内容的知识产权或已获得合法授权，您使用本软件及服务的任何行为未侵犯任何第三方之合法权益。<br />
                        4.2.2．您在使用本软件时需遵守当地法律法规，若您是中华人民共和国大陆地区用户时，尤其应当遵守中国法律法规、社会主义制度、国家利益、公民合法权益、社会公共秩序、道德风尚及信息真实性等“七条底线”要求。<br />
                        4.2.3．您在使用本软件时不得利用本软件从事以下行为，包括但不限于：<br />
                        4.2.3.1.制作、复制、发布、传播、储存违反当地国家法律法规的内容，若您是中华人民共和国大陆地区用户时，从事以下（1）-（11）所述的行为：<br />
                        <ul>
                            <li className={s.list}>（1）反对宪法所确定的基本原则的；</li>
                            <li className={s.list}>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>
                            <li className={s.list}>（3）损害国家荣誉和利益的；</li>
                            <li className={s.list}>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</li>
                            <li className={s.list}>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
                            <li className={s.list}>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
                            <li className={s.list}>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
                            <li className={s.list}>（8）侮辱或者诽谤他人，侵害他人合法权益的；</li>
                            <li className={s.list}>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</li>
                            <li className={s.list}>（10）以非法民间组织名义活动的；</li>
                            <li className={s.list}>（11）含有法律、行政法规禁止的其他内容的。</li>
                        </ul>
                        4.2.3.2.发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；<br />
                        4.2.3.3.虚构事实、隐瞒真相以误导、欺骗他人；<br />
                        4.2.3.4.发表、传送、传播广告信息及垃圾信息；<br />
                        4.2.3.5.从事其他违反当地法律法规的其他行为。<br />
                        4.2.4. 未经青萍许可，您不得在本软件中进行任何诸如发布广告、销售商品的商业行为。<br />
                        <b>4.3 您理解并同意：</b><br />
                        <b>4.3.1. 青萍会对用户是否涉嫌违反上述使用规范做出认定，并根据认定结果中止、终止对您的使用许可或采取其他依本约定可采取的限制措施；</b><br />
                        <b>4.3.2. 对于用户使用许可软件时发布的涉嫌违法或涉嫌侵犯他人合法权利或违反本协议的信息，青萍会直接删除；</b><br />
                        <b>4.3.3. 对于用户违反上述使用规范的行为对第三方造成损害的，您需要以自己的名义独立承担法律责任，并应确保青萍免于因此产生损失或增加费用；</b><br />
                        <b>4.3.4．若用户违反有关法律规定或协议约定，使青萍遭受损失，或受到第三方的索赔，或受到行政管理机关的处罚，用户应当赔偿青萍因此造成的损失和（或）发生的费用，包括合理的律师费、调查取证费用。</b><br />
                    </p>

                    <p className={s.pt12}>
                        <b>五、隐私政策与个人信息保护</b><br />
                        5.1. 保护您的个人信息对青萍很重要。我们将按照我们的隐私政策对您的数据进行收集、使用与保护。您可以在以下网站上查隐私政策的相关信息[<Link onClick={() => Dnav.handleClick()} to="/air/privacy">https://m.cleargrass.com/air/privacy]</Link><br />
                        5.2.<b>我们可能不时与小米共享与小米/米家/米兔品牌的产品和服务相关的数据，以提供和改善来自小米和小米生态链的令人兴奋的产品和服务（包括软件、硬件），带来更好的功能和用户体验。</b><br />
                    </p>

                    <p className={s.pt12}>
                        <b>六、服务风险及免责声明</b><br />
                        6.1. 用户必须自行配备移动终端设备上网和使用电信增值业务所需的设备，自行负担个人移动终端设备上网或第三方（包括但不限于电信或移动通信提供商）收取的通讯费、信息费等有关费用。如涉及电信增值服务的，我们建议您与您的电信增值服务提供商确认相关的费用问题。<br />
                        <b>6.2. 用户因第三方如通讯线路故障、技术问题、网络、移动终端设备故障、系统不稳定性及其他各种不可抗力原因而遭受的一切损失，青萍及合作单位不承担责任。</b><br />
                        <b>6.3. 本软件同大多数互联网软件一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的终端设备信息和数据的安全，继而影响本软件的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</b><br />
                        <b>6.4. 因用户使用本软件或要求青萍提供特定服务时，本软件可能会调用第三方系统或第三方软件支持用户的使用或访问，使用或访问的结果由该第三方提供，青萍不保证通过第三方系统或第三方软件支持实现的结果的安全性、准确性、有效性及其他不确定的风险，由此若引发的任何争议及损害，青萍不承担任何责任。</b><br />
                        <b>6.5. 青萍特别提请用户注意，青萍为了保障公司业务发展和调整的自主权，青萍公司拥有随时修改或中断服务而不需通知用户的权利，青萍行使修改或中断服务的权利不需对用户或任何第三方负责。</b><br />
                        <b>6.6. 除法律法规有明确规定外，我们将尽最大努力确保软件及其所涉及的技术及信息安全、有效、准确、可靠，但受限于现有技术，用户理解青萍不能对此进行担保。</b><br />
                        <b>6.7. 由于用户因下述任一情况所引起或与此有关的人身伤害或附带的、间接的经济损害赔偿，包括但不限于利润损失、资料损失、业务中断的损害赔偿或其他商业损害赔偿或损失，需由用户自行承担：（1）使用或未能使用许可软件；（2）第三方未经许可的使用软件或更改用户的数据；（3）用户使用软件进行的行为产生的费用及损失；（4）用户对软件的误解；（5）非因青萍的原因引起的与软件有关的其他损失。</b><br />
                        <b>6.8. 用户与其他使用软件的用户之间通过软件进行的行为，因您受误导或欺骗而导致或可能导致的任何人身或经济上的伤害或损失，均由过错方依法承担所有责任。</b>
                    </p>

                    <p className={s.pt12}>
                        <b>七、知识产权声明</b><br />
                        7.1. 青萍是本软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音 频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受当地法律法规和相应的国际条约保护，除小米/米家/米兔商标外，青萍享有上述知识产权。<br />
                        7.2 未经青萍书面同意，用户不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权，青萍保留追究上述行为法律责任的权利。
                    </p>

                    <p className={s.pt12}>
                        <b>八、协议变更</b><br />
                        <b>8.1. 青萍公司有权在必要时修改本协议条款，协议条款一旦发生变动，将会在相关页面上公布修改后的协议条款。如果不同意所改动的内容，用户应主动取消此项服务。如果用户继续使用服务，则视为接受协议条款的变动。</b><br />
                        <b>8.2. 青萍公司和合作公司有权按需要修改或变更所提供的收费服务、收费标准、收费方式、服务费及服务条款。青萍在提供服务时，可能现在或日后对部分服务的用户开始收取一定的费用如用户拒绝支付该等费用，则不能在收费开始后继续使用相关的服务。</b> 青萍和合作公司将尽最大努力通过电邮或其他方式通知用户有关的修改或变更。 
                    </p>

                    <p className={s.pt12}>
                        <b>九、适用法律及争议解决</b><br />
                        9.1. 本协议条款之效力和解释均适用中华人民共和国大陆地区的法律。如无相关法律规定的，则参照使用国际商业惯例和/或商业惯例。<br />
                        9.2. 本协议的签订地是北京市朝阳。<br />
                        <b>9.3．用户和青萍一致同意凡因本服务所产生的纠纷双方应协商解决，协商不成任何一方可提交本协议签订地有管辖权的法院诉讼解决。</b>
                    </p>

                    <p className={s.pt12}>
                        <b>十、其他</b><br />
                        10.1. 用户在使用本软件某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），您在使用该项服务前请阅读并同意相关的单独协议。<br />
                        10.2. 本协议生效时间为2019年9月6日。<br />
                        10.3. 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。<br />
                        10.4. 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
                    </p>
                    <p style={{ textAlign: 'right' }}>青萍科技</p>
                </div>
            </div>
        );
    }
}

export default AirAgreement;
