import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Pnineth.module.scss';

class Pnineth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
        this.refs.Pnineth.style.backgroundSize = '1920px';
        const clientWidth = this.refs.Pnineth.clientWidth
        if (clientWidth > 1920) {
            this.refs.Pnineth.style.marginTop = '60px'
            this.refs.Pnineth.style.marginBottom = '60px'
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Pnineth.getBoundingClientRect();
        if (this.refs.Pnineth && clientHeight >= textRect.bottom) {
            // setTimeout(() => {
            //     this.refs.line_top.style.width = `${213 / 2}px`;
            // }, 0);
            setTimeout(() => {
                this.refs.line_top.style.opacity = 1;
            }, 0);
            setTimeout(() => {
                this.refs.p_nineth_text_top.style.opacity = 1;
            }, 0);
            // setTimeout(() => {
            //     this.refs.line_down.style.width = `${213 / 2}px`;
            // }, 1500);
            setTimeout(() => {
                this.refs.line_down.style.opacity = 1;
            }, 500);
            setTimeout(() => {
                this.refs.p_nineth_text_down.style.opacity = 1;
            }, 500);
        }
    }
    render() {
        return (
            <div className={s.Pnineth} ref="Pnineth">
                <div className={s.wrap}>
                    <div className={s.line_top} ref="line_top" />
                    <div className={s.line_down} ref="line_down" />
                    <div className={s.p_nineth_text_top} ref="p_nineth_text_top">
                        <div className={s.p_nineth_title} style={{padding: 4}}>
                            <FormattedMessage id="p_nineth_title_1" /><br/>
                        </div>
                        <div className={s.nineth_subtitle}>
                            <FormattedMessage id="p_nineth_title_1_m" />
                        </div>
                        <ul>
                            <li>
                                <p>
                                    <FormattedMessage id="resolution" />
                                </p>
                                <p>
                                    <FormattedMessage id="p_nineth_resolution_value" />
                                </p>
                            </li>
                            <li>
                                <p>
                                    <FormattedMessage id="typical_accuracy" />
                                </p>
                                <p>
                                    <FormattedMessage id="p_nineth_typical_accuracy_value" />
                                    <FormattedMessage id="p_nineth_typical_accuracy_value_desc" />
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className={s.p_nineth_text_down} ref="p_nineth_text_down">
                        <div className={s.p_nineth_title}>
                            <FormattedMessage id="p_nineth_title_2" />
                        </div>
                        <ul>
                            <li>
                                <p>
                                    <FormattedMessage id="space" />
                                </p>
                                <p>
                                    <FormattedMessage id="p_nineth_temp" />
                                </p>
                                <p>
                                    <FormattedMessage id="p_nineth_humi" />
                                </p>
                            </li>
                            <li>
                                <p>
                                    <FormattedMessage id="resolution" />
                                </p>
                                <p>
                                    <FormattedMessage id="p_nineth_temp_value_1" />
                                </p>
                                <p>
                                    <FormattedMessage id="p_nineth_hum_value_1" />
                                </p>
                            </li>
                            <li>
                                <p>
                                    <FormattedMessage id="typical_accuracy" />
                                </p>
                                <p>
                                    <FormattedMessage id="p_nineth_temp_value_2" />
                                    <FormattedMessage id="p_nineth_temp_value_2_desc" />
                                </p>
                                <p>
                                    <FormattedMessage id="p_nineth_hum_value_2" />
                                    <FormattedMessage id="p_nineth_hum_value_2_desc" />
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pnineth;

