import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import s from './Pthird.module.scss'

class Pthird extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
  }
  componentDidMount() {}
  componentWillUnmount() {}

  render() {
    return (
      <div className={s.Pthird} ref="Pthird">
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <span className={s.large}>
              <FormattedMessage id="ph_co2_five_title" />
            </span>
            <span className={s.normal}>
              <FormattedMessage id="ph_co2_five_desc" />
            </span>
          </div>
          <div className={s.iconList} ref="iconList">
            <ul>
              <li>
                <i className={s.third_factory} />
                <FormattedMessage id="p_third_factory" />
              </li>
              <li>
                <i className={s.third_warehouse} />
                <FormattedMessage id="p_third_warehouse" />
              </li>
              <li>
                <i className={s.third_market} />
                <span style={{ marginLeft: 25, opacity: 1 }}>
                  <FormattedMessage id="p_third_market" />
                </span>
              </li>
              <li>
                <i className={s.third_supermarket} />
                <span style={{ marginLeft: 28, opacity: 1 }}>
                  <FormattedMessage id="p_third_supermarket" />
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <i className={s.third_hospital} />
                <FormattedMessage id="p_third_hospital" />
              </li>
              <li>
                <i className={s.third_pharmacy} />
                <FormattedMessage id="p_third_pharmacy" />
              </li>
              <li>
                <i className={s.third_winecellar} />
                <span style={{ marginLeft: 25, opacity: 1 }}>
                  <FormattedMessage id="p_third_winecellar" />
                </span>
              </li>
              <li>
                <i className={s.third_polytunnel} />
                <span style={{ marginLeft: 28, opacity: 1 }}>
                  <FormattedMessage id="p_third_polytunnel" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Pthird
