import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl'

import s from './airContent.module.scss';

const pages = ['air_one_text', 'two_img_1', 'two_img_2', 'two_img_3', 'two_img_4', 'two_img_5']
class airContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount () {
    window.addEventListener('scroll', this._handleScroll);

  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  handleScroll () {
    const clientHeight = document.documentElement.clientHeight;
    // const scrollTop = document.documentElement.scrollTop;
    const airBox = this.refs.airBox.getBoundingClientRect();
    this.refs.airBg_1.style.opacity = -airBox.top * 1.4 / clientHeight;
    for (let i = 0; i < pages.length; i++) {
      const page = this.refs[pages[i] + ''].getBoundingClientRect();
      if (page.top < clientHeight - 200) {
        this.setState({ [pages[i]]: true });
      } else {
        this.setState({ [pages[i]]: false });
      }
    }
  }
  render () {
    const { locale } = this.props
    const { air_one_text, two_img_1, two_img_2, two_img_3, two_img_4, two_img_5 } = this.state
    return (
      <div className={locale.indexOf('US') > -1 ? s.airContent_en : (locale.indexOf('TW') > -1 ? s.airContent_tw : s.airContent)}>
        <div className={locale.indexOf('US') > -1 ? s.airBg_1_en : (locale.indexOf('TW') > -1 ? s.airBg_1_tw : s.airBg_1)} ref="airBg_1" />
        <ul ref="airBox">
          <li className={s.air_one}>
            <div className={air_one_text ? s.air_one_text + ' ' + s.active : s.air_one_text} ref='air_one_text'>
              <div className={s.plus_title}>
                <i></i>
                <span>
                <FormattedMessage id="cg_app" />
                </span>
              </div>
              <span className={s.plus_dec}>
              <FormattedMessage id="plus_prd" />
              </span>
              <ul>
                <li><span className={s.normal}> 
                <FormattedMessage id="plus_device" />
                </span></li>
                <li><span className={s.normal}>
                <FormattedMessage id="plus_insights" />
                  </span></li>
                <li><span className={s.normal}>
                  <FormattedMessage id="plus_historical" />
                  </span></li>
                <li><span className={s.normal}>
                <FormattedMessage id="plus_sharing" />
                  </span></li>
                <li><span className={s.normal}>
                <FormattedMessage id="plus_remote" /></span></li>
                <li><span className={s.normal}>
                <FormattedMessage id="plus_notification" /></span></li>
              </ul>
              {/* <p className={s.plus_bottom_p}><span>
              <FormattedMessage id="plus_support" />
                </span></p> */}
            </div>
          </li>
          <li className={s.air_two} ref="air_two">
            <div className={s.air_two_img}>
              <ul>
                <li className={two_img_1 ? (locale.indexOf('US') > -1 ? s.two_img_1_en : (locale.indexOf('TW') > -1 ? s.two_img_1_tw : s.two_img_1)) + ' ' + s.active : (locale.indexOf('US') > -1 ? s.two_img_1_en : (locale.indexOf('TW') > -1 ? s.two_img_1_tw : s.two_img_1))} ref="two_img_1" />
                <li className={two_img_2 ? (locale.indexOf('US') > -1 ? s.two_img_2_en : (locale.indexOf('TW') > -1 ? s.two_img_2_tw : s.two_img_2)) + ' ' + s.active : (locale.indexOf('US') > -1 ? s.two_img_2_en : (locale.indexOf('TW') > -1 ? s.two_img_2_tw : s.two_img_2))} ref="two_img_2" />
                <li className={two_img_3 ? (locale.indexOf('US') > -1 ? s.two_img_3_en : (locale.indexOf('TW') > -1 ? s.two_img_3_tw : s.two_img_3)) + ' ' + s.active : (locale.indexOf('US') > -1 ? s.two_img_3_en : (locale.indexOf('TW') > -1 ? s.two_img_3_tw : s.two_img_3))} ref="two_img_3" />
                <li className={two_img_4 ? (locale.indexOf('US') > -1 ? s.two_img_4_en : (locale.indexOf('TW') > -1 ? s.two_img_4_tw : s.two_img_4)) + ' ' + s.active : (locale.indexOf('US') > -1 ? s.two_img_4_en : (locale.indexOf('TW') > -1 ? s.two_img_4_tw : s.two_img_4))} ref="two_img_4" />
                <li className={two_img_5 ? (locale.indexOf('US') > -1 ? s.two_img_5_en : (locale.indexOf('TW') > -1 ? s.two_img_5_tw : s.two_img_5)) + ' ' + s.active : (locale.indexOf('US') > -1 ? s.two_img_5_en : (locale.indexOf('TW') > -1 ? s.two_img_5_tw : s.two_img_5))} ref="two_img_5" />
              </ul>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

const AirContent = connect(mapStateToProps)(airContent)

export default AirContent;
