import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import actions from '../../../../../store/actions'
import s from './style.module.scss'
import { Link } from 'react-router-dom'

class Gchoose extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.goose_add_part.getBoundingClientRect()
    if (clientHeight - 200 > textRect.top && this.refs.goose_add_part) {
      setTimeout(() => {
        // this.refs.top_text.style.opacity = 1;
        // this.refs.top_text.style.top = '100px';
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    const ifUs = locale.indexOf('US') > -1
    const ifTwOrUs = locale.indexOf('US') > -1 || locale.indexOf('TW') > -1
    const TMalls = [
      {
        name: 'mall_value_1',
        link: ifTwOrUs
          ? 'https://www.amazon.com/dp/B0BQ6235KH'
          : 'https://detail.tmall.com/item.htm?id=695482721170&skuId=5110652835001',
      },
      {
        name: 'mall_value_2',
        link: ifTwOrUs
          ? 'https://www.amazon.de/dp/B0BQ6235KH'
          : 'https://www.amazon.com/dp/B0BQ6235KH',
      },
      {
        name: 'mall_value_3',
        link: ifTwOrUs
          ? 'https://detail.tmall.com/item.htm?id=695482721170&skuId=5110652835001'
          : 'https://www.amazon.de/dp/B0BQ6235KH',
      },
    ]
    const HMalls = [
      {
        name: 'mall_value_1',
        link: ifTwOrUs
          ? 'https://www.amazon.com/dp/B08N64HC6B/'
          : 'https://detail.tmall.com/item.htm?id=624676310772',
      },
      {
        name: ifTwOrUs ? 'mall_value_3' : 'mall_value_2',
        link: ifTwOrUs
          ? 'https://detail.tmall.com/item.htm?id=624676310772'
          : 'https://www.amazon.com/dp/B08N64HC6B/',
      },
    ]
    const MMalls = [
      {
        name: 'mall_value_1',
        link: ifTwOrUs
          ? 'https://www.amazon.com/dp/B08N6BL434/'
          : 'https://detail.tmall.com/item.htm?id=591748428692',
      },
      {
        name: 'mall_value_2',
        link: ifTwOrUs
          ? 'https://www.amazon.de/dp/B099ZK3XYW'
          : 'https://www.amazon.com/dp/B08N6BL434/',
      },
      {
        name: 'mall_value_3',
        link: ifTwOrUs
          ? 'https://detail.tmall.com/item.htm?id=591748428692'
          : 'https://www.amazon.de/dp/B099ZK3XYW',
      },
    ]
    return (
      <div className={s.goose_choose} ref="goose_add_part">
        <div className={s.table}>
          <div className={s.row}>
            <div className={s.column1} style={{ height: 360 }}></div>
            <div
              className={s.column2}
              style={{ height: 360, justifyContent: 'space-around' }}
            >
              <div className={s.title}>
                <FormattedMessage id="t_version" />
              </div>
              <i className={s.icon_t}></i>
              <div className={s.buy}>
                <div className={s.shopArrow}>
                  {/* <a
                    href="https://detail.tmall.com/item.htm?id=695482721170&skuId=5110652835001"
                    target="_blank"
                    ref="noopener noreferrer"
                  >
                    <FormattedMessage id="buy" />
                  </a> */}
                  <div>
                    <div className={s.arrow}></div>
                    <FormattedMessage id="buy" />
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      height: 20,
                      width: 130,
                      top: 20,
                      left: -40,
                    }}
                  ></div>
                  <div className={s.wrapMall} name="wrapMall">
                    <div className={s.mallBox}>
                      {TMalls.map((item, index) => {
                        return (
                          <a
                            className={s.mallItem}
                            key={item.name}
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FormattedMessage id={item.name} />
                          </a>
                        )
                      })}
                    </div>
                    <div style={{ height: 16 }}></div>
                  </div>
                </div>
              </div>
              <i className={s.icon_homekit}></i>
            </div>
            <div
              className={s.column2}
              style={{ height: 360, justifyContent: 'space-around' }}
            >
              <div className={s.title}>
                <FormattedMessage id="h_version" />
              </div>
              <i className={s.icon_hm}></i>
              <div className={s.buy} style={{ marginRight: 15 }}>
      
                <div className={`${s.shopArrow} ${s.hideShop}`} >
                  <div>
                    {/* <div className={s.arrow}></div> */}
                    <FormattedMessage id="buy" />
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      height: 20,
                      width: 130,
                      top: 20,
                      left: -40,
                    }}
                  ></div>
                  {/* <div className={s.wrapMall} name="wrapMall">
                    <div className={s.mallBox}>
                      {HMalls.map((item, index) => {
                        return (
                          <a
                            className={s.mallItem}
                            key={item.name}
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FormattedMessage id={item.name} />
                          </a>
                        )
                      })}
                    </div>
                    <div style={{ height: 16 }}></div>
                  </div> */}
                </div>
              </div>
              <i className={s.icon_homekit}></i>
            </div>
            <div
              className={s.column2}
              style={{ height: 360, justifyContent: 'space-around' }}
            >
              <div className={s.title}>
                <FormattedMessage id="m_version" />
              </div>
              <i className={s.icon_hm}></i>
              <div className={s.buy} style={{ marginRight: 15 }}>
                <div className={s.shopArrow}>
                  <div>
                    <div className={s.arrow}></div>
                    <FormattedMessage id="buy" />
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      height: 20,
                      width: 130,
                      top: 20,
                      left: -40,
                    }}
                  ></div>
                  <div className={s.wrapMall} name="wrapMall">
                    <div className={s.mallBox}>
                      {MMalls.map((item, index) => {
                        return (
                          <a
                            className={s.mallItem}
                            key={item.name}
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FormattedMessage id={item.name} />
                          </a>
                        )
                      })}
                    </div>
                    <div style={{ height: 16 }}></div>
                  </div>
                </div>
              </div>
              <i
                className={locale.indexOf('US') > -1 ? s.icon_mi_en : s.icon_mi}
              ></i>
            </div>
          </div>

          <div className={s.row}>
            <div className={s.column1}>
              <FormattedMessage id="wireless" />
            </div>
            <div className={s.column2}>
              <FormattedMessage id="thread" />
            </div>
            <div className={s.column3}>
              <FormattedMessage id="bluetooth" />
            </div>
          </div>

          <div className={s.row}>
            <div className={s.column1}>
              <FormattedMessage id="support_operation" />
            </div>
            <div className={s.column3}>
              <FormattedMessage id="iOS" />
            </div>
            <div className={s.column2}>
              <FormattedMessage id="iOS_Android" />
            </div>
          </div>

          <div className={s.row}>
            <div className={s.column1} style={{ height: 120 }}>
              <FormattedMessage id="support_app" />
            </div>
            <div className={s.column3}>
              <div className={s.home}>
                <i className={s.icon_home}></i>
                <FormattedMessage id="family" />
              </div>
              <div className={s.plus}>
                <i className={s.icon_plus}></i>
                <FormattedMessage id="plus_plus" />
              </div>
            </div>
            <div className={s.column4}>
              <i className={s.icon_mihome}></i>
              <FormattedMessage id="mi_home" />
            </div>
            <div className={s.column4}>
              <i className={s.icon_plus}></i>
              <FormattedMessage id="plus_plus" />
            </div>
          </div>
          <div className={s.row} style={{ height: ifUs ? 350 : 280 }}>
            <div className={s.column1}>
              <FormattedMessage id="remote_access" values={{ br: <br /> }} />
            </div>
            <div
              className={s.column2}
              style={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                paddingLeft: ifUs ? 20 : 30,
                lineHeight: '24px',
              }}
            >
              <FormattedMessage id="need_homepod_tv" values={{ br: <br /> }} />
              <br />
              <FormattedMessage id="need_thread" values={{ br: '' }} />
            </div>
            <div
              className={s.column2}
              style={{
                paddingLeft: ifUs ? 20 : 30,
              }}
            >
              <FormattedMessage id="need_homepod_tv" values={{ br: <br /> }} />
            </div>
            <div
              className={s.column4}
              style={{
                paddingLeft: ifUs ? 20 : 20,
                paddingRight: ifUs ? 20 : 20,
                display: 'inline',
              }}
            >
              <FormattedMessage
                id="need_mi_qingping_gateway"
                values={{ br: <br /> }}
              />
              <Link
                target="_blank"
                to="/bluetooth-gateway"
                style={{ color: '#000' }}
              >
                <FormattedMessage id="sparrow" />
              </Link>
              <FormattedMessage id="end" />
            </div>
            <div
              className={s.column4}
              style={{
                paddingLeft: ifUs ? 20 : 20,
                paddingRight: ifUs ? 20 : 20,
                display: 'inline',
              }}
            >
              <FormattedMessage
                id="need_qingping_gateway"
                values={{ br: <br /> }}
              />
              <Link
                target="_blank"
                to="/bluetooth-gateway"
                style={{ color: '#000' }}
              >
                <FormattedMessage id="sparrow" />
              </Link>
              <FormattedMessage id="end" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLanguage: (val) => dispatch(actions.changeLanguage(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Gchoose)
