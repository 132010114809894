import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'

import s from './Gnineth.module.scss'

class Gnineteenth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      isHide: true,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
    // if (this.refs.Gnineth) {
    //   const clientHeight = document.documentElement.clientHeight
    //   this.refs.Gnineth.style.height = `${clientHeight}px`
    // }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Gnineth.getBoundingClientRect()
    if (clientHeight + 200 >= textRect.bottom && this.refs.text) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '100px'
      }, 500)
    }
  }
  handleClick(Keypress, e) {
    e.stopPropagation()
    if (Keypress) {
      this.refs.qrcode.style.paddingBottom = '0px'
      this.refs.qrcode.style.paddingLeft = '0px'
    } else {
      this.refs.qrcode.style.paddingBottom = '200px'
      this.refs.qrcode.style.paddingLeft = '200px'
    }
    this.setState({
      isHide: !this.state.isHide,
    })
  }
  render() {
    const { locale } = this.props
    // let bgName = s.hide;
    // if (!this.state.isHide) {
    //   bgName = s.bg;
    // }
    return (
      <div ref="Gnineth" className={s.outwrap}>
        <LazyLoad height={700}>
          <div
            className={
              locale.indexOf('US') > -1
                ? s.Gnineth_en
                : locale.indexOf('TW') > -1
                ? s.Gnineth_tw
                : s.Gnineth
            }
          >
            {/* <div className={s.mask}></div> */}
            <div className={s.wrap}>
              <div className={s.goose_qingpingiot}>
                <Link className={s.goose_a} target="_blank" to="/plus">
                  <div className={s.info}>
                    <div className={s.logo}>
                      <i />
                      <span className={s.large}>
                        <FormattedMessage id="cg_app" />
                      </span>
                    </div>
                  </div>
                </Link>
                <div className={s.text} ref="text">
                  <div className={s.normal}>
                    <FormattedMessage
                      id="parrot_nineteen_desc"
                      values={{ br: <br /> }}
                    />
                  </div>
                  <div className={s.small}>
                    * <FormattedMessage id="parrot_nineteen_memo" />
                    <Link target="_blank" to="/bluetooth-gateway">
                      <FormattedMessage id="sparrow" values={{ br: <br /> }} />
                    </Link>
                    <FormattedMessage id="end" values={{ br: <br /> }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LazyLoad>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const GNineteenth = connect(mapStateToProps)(Gnineteenth)

export default GNineteenth
