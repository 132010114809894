import supportMi from './zh-CN/support-mi'
const zhCN = {
  qingping: '青萍',
  temp_rh: '温湿度计',
  temp_rh_press: '温湿度气压计',
  clock: '时钟',
  air_name: '空气检测仪',
  gateway: '网关',
  cg_app: '青萍+',
  qing_ping_iot: '青萍物联',
  qing_ping_iot_m: '青萍物联 App',
  developer: '开发者平台',
  index_developer: '开发者平台',
  store: '商店',
  download: '下载',

  magpie: '李吉他蓝牙温湿度计',
  magpie_simple: '李吉他温湿度',
  magpie_enter: '李吉他{br}蓝牙温湿度计',
  grasp_guitar: '巧妙吸附在吉他上',
  cute_smart: '可爱又好用',
  mp_battery_value: 'CR2032 纽扣电池',

  cg_magpie: '李吉他温湿度',
  magpie_prd: '配合李吉他蓝牙温湿度计使用',

  more: '了解更多',

  goose: '青萍蓝牙温湿度计',
  goose_lite: '青萍温湿度计',
  h_version: 'H 版',
  m_version: 'M 版',
  t_version: 'T 版',
  t_version_new: 'T 版',

  pheasant: '青萍商用温湿度气压计',
  pheasant_s: '青萍商用温湿度气压计 S',
  s_first_title: '呼吸，事关重大',

  goose_screen: '电子墨水屏',
  support: '桌面支架',
  wall: '墙贴',
  user: '机身储存 30 天历史数据',

  duck2_support: '支持小米米家',
  hodor_monitor: '监控门窗开合动作',

  frog_support_1: 'IP55 级防护',
  frog_support_2: '超长待机',
  frog_support_3: '可选外置探头',
  frog_support_3_1: '外置探头',
  frog_support_4: 'Wi-Fi/NB-IoT/LoRa',

  l_support: '支持 Wi-Fi 或 NB-IoT 或 LoRa',
  l_battery: '可充电锂电池',
  l_user: '超长待机',

  dove: '青萍蓝牙闹钟',
  button_less: '"无按键"设计',
  temp_rh_mon: '温湿度监测',
  soft_backlight: '柔和背光',
  sixteen_ala: '16组闹钟',

  snow: '青萍空气检测仪',
  // snow2: '青萍空气检测仪 2',
  pm25: 'PM2.5',
  pm10: 'PM10',
  co2: 'CO₂',
  tvoc_s: 'tVOC',
  temp: '温度',
  temp_m: '温度',
  temperature: '温度',
  hum: '湿度',
  // etvoc: 'eTVOC',
  // noise: '噪音',
  snow_screen: '超清触摸屏',
  shell: '铝合金外壳',
  snow2_sensor: '可更换 PM 传感器',

  dany: '青萍空气检测仪 Lite',
  touchbar: '触摸条',
  support_all: '支持 Apple Home{br} 和小米米家',
  support_all_pc: '支持 Apple Home{br} 和小米米家',

  sparrow: '青萍蓝牙网关',
  connect_mi: '让米家蓝牙网关子设备自动联网',
  enable_remote: '远程查看数据',
  automation: '智能联动',

  grasp_guitar_sip: '巧妙磁吸',
  e_link: '电子墨水屏',
  record_history_data: '自动记录历史数据',

  pheasant_co2_name: '青萍二氧化碳和温湿度检测仪',
  pheasant_co2_index_desc: '无线通信，超长待机',

  pheasant_co2_title: '青萍二氧化碳和温湿度检测仪',
  pheasant_co2_des1: '高精度传感器 • 超长待机',
  pheasant_co2_des2: 'Wi-Fi/NB-loT/LoRa',

  chicken_title: '青萍蓝牙小钟',
  chicken_des1: '可爱小闹钟 • 温湿度传感器',
  chicken_des2: '半透明屏幕',

  parrot_title: '青萍动作和环境光传感器',
  parrot_des1: '灵敏识人 • 精准感知光线变化',
  parrot_des2: '动作和照度结合联动',

  device_management: '青萍设备管理',
  data_insights: '读数解读',
  historical_data: '历史数据',
  access_remote: '远程访问',
  air_quality: '城市空气质量',
  forecast: '天气预报',

  team_management: '企业级设备管理',
  data_collection: '数据收集',
  device_sharing: '设备共享',
  alarm_notification: '实时报警服务',

  customize_device: '深度定制青萍产品',
  own_platform: '让青萍硬件完美接入您的平台',
  own_platform_m_1: '让青萍',
  own_platform_m_2: '硬件完美接入您的平台',

  cooperate: '商务合作',
  serve: '售后服务',
  company_name: '© 青萍科技（北京）有限公司深圳分公司',

  about_us: '关于我们',
  privacy: '隐私政策',
  contact_us: '联系我们',

  specifications: '规格',
  buy: '购买',

  home_text: '冷暖⼲湿，⼀览分明',
  sensor: '瑞士传感器',
  wall_model: '墙面模式',
  thirdth_title: '桌面模式',
  temp_rh_t: '青萍温湿度计 T 版',
  temp_rh_h: '青萍蓝牙温湿度计 H 版',
  temp_rh_m: '青萍蓝牙温湿度计 M 版',
  temp_rh_t_full: '青萍温湿度计 T 版',
  temp_rh_h_full: '青萍蓝牙温湿度计 H 版',
  temp_rh_m_full: '青萍蓝牙温湿度计 M 版',
  comfort_zone: '体感舒适区',
  goose_desc:
    '冬季，温度在20℃~24℃、相对湿度在30%~60%时，人体感觉较为舒适；在夏季，温度在23℃~26℃、相对湿度在25%~60%时，人体感觉较为舒适。',
  goose_desc_m:
    '冬季，温度在20℃~24℃、相对湿度在30%~60%{br}时，人体感觉较为舒适；在夏季，温度在23℃~26{br}℃、相对湿度在25%~60%时，人体感觉较为舒适。',
  thirdth_desc: '打开内置支架，就能稳稳地放在桌面上。',
  fourth_title: '墙面模式',
  fourth_desc: '上墙共分3步：',
  fourth_desc_1: '将墙贴粘在墙上；',
  fourth_desc_2: '将青萍温湿度计吸附在墙贴上；',
  fourth_desc_3: '放歪也不用担心，可随意旋转调整。',
  fourth_desc_4: '* 青萍温湿度计内置磁铁，墙贴内置铁片。',
  fifth_title: '电子墨水屏，白“纸”黑字，清清楚楚',
  sixth_title: '超宽可视角度，几近 180°',
  sixth_desc: '更易看清房间温湿度，哪怕角度刁钻',
  seventh_title: '瑞士 Sensirion 高精度工业级传感器',
  seventh_title_1: '瑞士 Sensirion 高精度',
  seventh_title_2: '工业级传感器',
  seventh_desc: '响应快{spot}测量准{spot}不漂移',
  eighth_title: '1 颗纽扣电池，续航 8 ~ 12 个月',
  eighth_title_m: '1 颗纽扣电池{br}续航 8 ~ 12 个月',
  eighth_desc:
    '* 本产品采用 CR2430 电池，在常见室温下可使用 8 ~ 12 个月。数据来自青萍实验室，实际续航时间可能依环境和电池的差异而有所不同。',
  eighth_desc_1:
    '* 本产品采用 CR2430 电池，在常见室温下可使用 8 ~ 12 个{br}月。数据来自青萍实验室，实际续航时间可能依环境和电池的{br}差异而有所不同。',
  h_support: 'T 版和 H 版支持 Apple Home',
  t_support: 'T 版支持 Apple Home',
  check_anytime: '随时随地查看家中温湿度，联动其他设备',
  check_remote:
    '* 远程查看和设置自动化需配备家居中枢设备，如 HomePod 或 Apple TV。',
  check_thread:
    '如需使用 Thread 连接，则需配备支持 Thread 的家居中枢设备，例如 HomePod（第二代）、{br}HomePod mini 或 Apple TV 4K（第二代或带有网线接口的第三代），或第三方 Thread 边界路由器。',
  check_remote_1:
    '* 远程查看需配备 HomeKit 中枢{br}设备，如 HomePod 或 Apple TV。',
  check_thread_1:
    '如需使用 Thread 连接，则需配备支持 Thread{br} 的家居中枢设备，例如 HomePod（第二代）、HomePod{br} mini 或 Apple TV 4K（第二代或带有网线接口的第三代），或{br}第三方 Thread 边界路由器。',
  check_remote_2: '需配置 HomeKit 中枢设备，如 HomePod 或 Apple TV',
  check_remote_3: '需配备 HomePod 或{br} Apple TV。',
  siri_support: '支持 Siri',
  hey_siri: '嘿 Siri, 现在家里几度？',
  home: '家庭',
  now_humi: '客厅当前湿度为 53%。',
  now_temp: '客厅现在 24.8˚。',
  detail: '详细信息',
  common_around: '常用配件',
  meetting_room: '客厅',
  short_desc: '青萍蓝牙温...',
  two_sensor: '2 个感应器',
  m_support: 'M 版支持米家',
  with_gateway_1: '配备',
  with_gateway_2: '米家蓝牙网关设备',
  with_gateway_3: '（如',
  with_gateway_4: '青萍蓝牙网关',
  with_gateway_5:
    '）后，您可通过米家 app 远程访问青萍蓝牙温湿度计 M 版、设置与米家智能设备的联动、从云端获取历史数据。',
  humidifier: '加湿器',
  air_conditioner: '空调',
  mi_home: '米家',
  qingping_bluetooth_gateway: '青萍蓝牙温湿度计',
  electric_heater: '电暖气',
  auto_record_thirty: '自动记录温湿度历史数据，{br}长达 30 天',
  nineth_title_1: '自动记录温湿度历史数据',
  nineth_title_2: '长达 30 天',
  need_plus: '* 需连接“青萍+”手机 app。',
  choose_version: '不同版本如何选择?',
  wireless: '无线通信',
  thread: 'Thread / 蓝牙',
  bluetooth: '蓝牙',
  support_operation: '支持的操作系统',
  iOS: 'iOS',
  iOS_Android: 'iOS 和 Android',
  support_app: '支持的 App',
  family: '家庭',
  remote_h: '远程查看',
  remote_access: '远程查看和{br}智能联动',
  need_thread:
    '如需使用 Thread 连接，则需配备支持 Thread 的家居中枢设备，{br}例如 HomePod（第二代）、HomePod mini 或 Apple TV 4K（第二代或带有网线接口的第三代），或第三方 Thread 边界路由器。',
  need_homepod_tv: '需配备 HomePod 或 Apple TV 作为家居中枢。',
  need_mi_qingping_gateway: '需配备{br}米家蓝牙网关设备，如{br}',
  need_qingping_gateway: '需配备{br}',
  need_mi_qingping_gateway_m: '米家 app 需配备米家蓝牙网关{br}设备，如',
  need_qingping_gateway_m: ' app 需配备',
  break: '，',
  end: '。',

  p_size_long: '74 毫米',
  p_size_short: '14 毫米',
  exterior: '产品外观',
  p_size: '尺寸',
  p_size_value: '74 × 74 × 14 毫米',
  p_color: '颜色',
  p_color_value: '白色',
  range: '产品量程',
  p_temp: '温度',
  p_temp_value: '0 ~ 50℃ ',
  p_hum: '湿度',
  p_hum_value: '0 ~ 99.9%RH（无凝露环境）',
  under_ninty: '* 请勿长时间在湿度超过 90% 的环境下使用。',
  p_specifications: '产品规格',
  other_specs: '其他规格',
  p_name: '产品名称',
  p_name_value: '青萍蓝牙温湿度计',
  p_model: '产品型号',
  p_model_value_t: 'CGG1T',
  p_model_value_h: 'CGG1H',
  p_model_value_m: 'CGG1',
  p_support_platform: '支持的平台',
  p_weight: '产品重量',
  p_weight_value: '59 克',
  p_weight_value_t: '54 克',
  p_wireless: '无线通信',
  p_wireless_value_t: '蓝牙、Thread',
  screen_size: '屏幕尺寸',
  screen_size_value: '55 x 55 毫米',
  p_wifi: '无线网络',
  p_wifi_value: '蓝牙 5.0',
  p_battery: '电池类型',
  p_battery_value: 'CR2430 纽扣电池',
  p_pack: '包装清单',
  p_main: '主体',
  p_cr: 'CR2430',
  p_battery_1: '纽扣电池',
  p_instructions: '说明书',
  mi_app_need_1: '米家 app 需配备米家蓝牙网关设备，如',
  mi_app_need_2: ' ',
  mi_app_need_3: ' app 需配备',

  ph_first_title: '青萍温湿度气压计',
  ph_first_text:
    'LoRa/NB-IoT/Wi-Fi 长距离无线通信{br}大容量可充电锂电池 · 超低功耗超长待机',
  ph_first_text1: '青萍商用{br}温湿度气压计 S',
  ph_first_text1_m: '青萍商用{br}温湿度气压计 S',
  ph_first_text2: '青萍商用{br}温湿度计 S',
  ph_first_text2_m: '青萍商用{br}温湿度计 S',
  ph_first_text3: '多场景专业温湿度监控方案',
  ph_first_text4: '针对严酷和日常环境，各有一套。',
  ph_first_text5: '温度量程',
  ph_first_text6: '气压量程',
  ph_first_text7: '电池容量',
  ph_first_text8: '-30 ~ 55℃',
  ph_first_text9: '-20 ~ 50℃',
  ph_first_text10: '30~125 kPa',
  ph_first_text11: '不支持',
  ph_first_text12: '2450mAh',
  ph_first_text13: '2600mAh',
  ph_first_text14: '查看完整规格',
  p_second_title: '长距离无线通信，多种可选方案',
  p_second_desc: '可在 3 种方案中选择一种。',
  p_second_lora: '需配备 LoRaWAN 网关或基站。',
  p_second_nbiot: 'NB-IoT 版包含 6 年运营商资费。{br}（ 限 50MB 流量/年 ）',
  p_second_wifi: '支持定时上报数据、{br}条件触发上报数据，不保持长连接。',
  p_third_title: '多场景专业应用',
  p_third_factory: '工厂',
  p_third_warehouse: '仓库',
  p_third_market: '商场',
  p_third_supermarket: '超市',
  p_third_hospital: '医院',
  p_third_pharmacy: '药店',
  p_third_winecellar: '酒窖',
  p_third_polytunnel: '大棚',
  p_fourth_title: '大容量可充电锂电池，{br}USB-C 接口',
  p_fourth_text1: '电池待机时间超长，支持长期连接 USB 电源。',
  p_fourth_text2: '电池容量',
  p_fourth_text3: '电源接口',
  p_fourth_text4: '额定输入',
  p_fourth_text5: 'USB-C',
  p_fourth_text6: '5V = 1A',
  p_fourth_desc_1: '容量高达 3350mAh',
  p_fourth_desc_2: 'USB-C 充电接口',
  p_fourth_desc_3: '支持 USB 电源持续供电',
  p_fifth_title: '超低功耗，超长待机',
  p_fifth_note:
    '* 以上数据基于青萍公司实验结果。实际使用中，环境温度和网络信号强度、上传频率、上传数据量都将影响待机时长。',
  p_calculator_title: '待机时间计算器',
  p_calculator_subtitle: '待机有多久，算算更明了。',
  p_network_type: '网络类型',
  p_network_signal: '网络信号',
  p_local_interval: '本地记录时间间隔',
  p_net_interval: '联网上报时间间隔',
  p_environment: '环境温度',
  p_around: '预估待机时长',
  p_about: '约',
  p_month: '个月',
  p_day: '天',
  p_tips: '* 预估待机时长可能与实际使用中有所不同。',
  p_minute: '分钟',
  p_minutes: '分钟',
  p_hour: '小时',
  p_hours: '小时',
  p_good: '优秀',
  p_normal: '正常',
  p_bad: '较差',

  wulian_desc:
    '青萍物联是为青萍商用智能硬件产品提供设备管理、数据收集、数据查询{br}以及实时报警服务的物联网平台。',
  wulian_desc_m:
    '青萍物联是为青萍商用智能硬件产品{br}提供设备管理、数据收集、数据查询{br}以及实时报警服务的物联网平台。',
  p_seventh_title: '灵活的上报和通知策略，可远程配置',
  p_seventh_img_1: '自定义上报间隔',
  p_seventh_img_2: '自定义数据粒度',
  p_seventh_img_3: '自定义超限通知',
  p_eighth_title: '完善的第三方开发文档和 API，支持二次开发',
  p_eighth_email: '开发者平台：{link}',
  p_nineth_title_1: 'Bosch 或 ROHM 气压传感器',
  p_nineth_title_1_1: '* 仅适用于青萍商用温湿度气压计 S。',
  p_nineth_title_1_m: '* 仅适用于青萍商用温湿度气压计 S。',
  p_nineth_title_2: '瑞士 Sensirion 高精度温湿度传感器',
  resolution: '分辨力',
  space: ' ',
  p_nineth_temp: '温度',
  p_nineth_humi: '湿度',
  typical_accuracy: '典型准确度',
  p_tenth_title: '方便、低成本的悬挂方案',
  p_tenth_desc_1: '粘贴式挂钩',
  p_tenth_desc_2: '背部开孔',
  ph_size_long: '77.2 毫米',
  ph_size_height: '27.8 毫米',
  ph_size_value: '77.2 × 77.2 × 27.8 毫米',
  ph_color_value: '白色',
  ph_pressure: '气压量程',
  ph_pressure_value: '30 ~ 125 kPa',
  ph_pressure_value_2: 'N/A',
  ph_temp_value: '-30 ~ 55℃ ',
  ph_temp_value_2: '-20 ~ 50℃ ',
  ph_hum_value: '0 ~ 99.9%RH（无凝露环境）',
  ph_name_value: '青萍商用温{br}湿度气压计 S',
  ph_name_value_2: '青萍商用{br}温湿度计 S',
  ph_weight_value: '129 克',
  ph_screen_value: '61.21 × 48.72 毫米',
  ph_wifi_value: 'Wi-Fi 或 NB-IoT 或 LoRa',
  ph_bluetooth_value: '蓝牙 5.0',
  ph_bluetooth: '蓝牙类型',
  ph_battery_value: '锂离子电池',
  ph_battery_size_value: '2450mAh',
  ph_battery_size_value_2: '2600mAh',
  ph_usb_value: 'USB-C',
  ph_input_value: '5V⎓1A',
  ph_sticker: '粘贴式挂钩',
  ph_product: '产品',

  coming: '即将上市',
  coming_soon: '即将推出',
  mp_package: '产品包装',
  mp_measure: '精准测温湿',
  mp_guard: '木质乐器守护者',
  mp_desc_1: '灵敏精准 · 便捷磁吸 · 智能 App',
  mp_desc_1_m: '灵敏精准 · 便捷磁吸 · 智能 App',
  mp_story: '产品故事',
  mp_story_1:
    '李吉他 LEE GUITARS 是李宗盛创立的吉他品牌。作为资深音乐人和吉他制琴师，李宗盛先生深知木吉他日常保管环境温湿度的重要性。',
  mp_story_2:
    '李吉他蓝牙温湿度计由李宗盛先生主导、青萍蓝牙温湿度计团队精心设计打造，可巧妙吸附在吉他音孔处，是乐手监测吉他保存环境温湿度的理想选择。',
  mp_why: '为什么木质乐器需要温湿度计？',
  mp_reason:
    '修理面板开裂或变形的吉他或提琴代价昂贵，即使修好，音色也会发生变化。保存木质乐器的适宜温度为 20 ~ 25°C，{br}湿度为 40% ~ 55%。尽量让木质乐器处于适宜的温湿度环境，可预防乐器变形、开裂、开胶。',
  mp_reason_m:
    '修理面板开裂或变形的吉他或提琴代价昂贵，即使修好，音色也会发生变化。保存木质乐器的适宜温度为 20 ~ 25°C，湿度为 40% ~ 55%。尽量让木质乐器处于适宜的温湿度环境，可预防乐器变形、开裂、开胶。',
  mp_convient: '便捷磁吸',
  mp_goods_1:
    '传感器端与显示屏端都内置磁铁，中间连接部位为 TPU 软胶材质，可在弯折后夹住吉他面板{br}吸附固定，测量吉他内部温湿度。',
  mp_screen: '显示屏端',
  mp_magnet: '内置磁铁',
  mp_attract: '方便吸附传感器端',
  mp_button: '按钮',
  mp_switch: '华氏度、摄氏度切换',
  mp_cp: '长按蓝牙配对',
  mp_tpu: '软质 TPU',
  mp_bend: '轻松弯折',
  mp_sensor_end: '温湿度传感器端',
  mp_built_in: '内置磁铁',
  mp_cavity: '可深入音腔',
  mp_e_link: '电子墨水屏',
  mp_clear: '白“纸”黑字，清清楚楚',
  mp_goods_2:
    '对环境光线要求很低，无论阳光明媚还是华灯初上，都能为您提供纸张般舒适的查看体验，{br}视角宽广、清晰易读。',
  mp_superview: '超宽可视角度',
  mp_high_contrast: '超高对比度',
  only_bluetooth: '蓝牙',
  mp_bluetooth: '内置蓝牙',
  only_wifi: 'Wi-Fi',
  mp_wireless: '不仅仅是无线',
  mp_ble:
    '· 蓝牙 5.0 传输{br}· App 查看实时温湿度、历史数据{br}· 接收温湿度超限提醒',
  mp_ble_1: '蓝牙 5.0 传输',
  mp_ble_2: 'App 查看实时温湿度、历史数据',
  mp_ble_3: '接收温湿度超限提醒',
  mp_upload: '* 机身存储的历史数据为每 15 分钟一条，会在蓝牙连接{br} ',
  mp_upload_magpie: '“李吉他温湿度”',
  mp_upload_or: '或 ',
  mp_upload_plus: '“青萍+”',
  mp_upload_end: 'app 后自动上传。',
  mp_check: '* 使用手机 app 远程查看数据或接收提醒，需配备',
  mp_check_bluetooth: '蓝牙网关。',
  mp_create_automation:
    '* 通过米家 app 接收温湿度超限提醒，需先手动创建“智能”。',
  mp_love_music: '热爱音乐的你',
  mp_guardian: '需要的私人乐器管家',
  mp_suitable:
    '配合蓝牙网关使用，可实现温湿度远程监测、{br}超限及时提醒等功能。无需打开琴箱，无论身{br}在何处都能及时了解乐器状态。',
  mp_suitable_m:
    '配合蓝牙网关使用，可实现温湿度远程监测、超限及时提醒等功能。无需打开琴箱，无论身在何处都能及时了解乐器状态。',
  mp_purchase: '* 蓝牙网关设备需单独购买。',
  mp_sensor: '瑞士 Sensirion 传感器',
  mp_sensitive: '精度高，反应灵敏',
  mp_art:
    '李吉他蓝牙温湿度计采用在工业和消费市场广受{br}赞誉的瑞士 Sensirion 温湿度传感器，辅以精妙{br}的结构设计、ART 算法，可灵敏检测吉他音腔内{br}部或乐器存放环境的温湿度。',
  mp_low_consumption: '超低功耗{br}续航长达 8 个月',
  mp_desc_2:
    '李吉他蓝牙温湿度计采用超低功耗传感器和芯片，搭配{br} CR2032 型纽扣电池，在常见室温环境下可持续{br}使用 8 个月。',
  mp_desc_2_m:
    '李吉他蓝牙温湿度计采用超低功耗传感器和芯片，搭配 CR2032 型纽扣电池，在常见室温环境下可持续使用 8 个月。',
  mp_desc_3: '* 续航时间会因实际使用环境不同而存在差异，仅供参考。',
  mp_little_thing: '一手掌握的精致派',
  mp_weight: '重量仅 30 克，可固定在乐器上，或放置于琴盒、琴箱内，便携轻巧。',
  mp_more: '更多细节',
  mp_thin: '超薄机身',
  mp_design: '圆润而又层次分明的设计',
  mp_beauty: '美观大方',
  mp_tpu_2: 'TPU 软胶材质',
  mp_bend_time: '可弯折 5000 次以上',
  mp_rotary: '旋转式电池舱盖',
  mp_change_battery: '换电池更轻松',
  mp_size_long: '131 毫米',
  mp_size_short: '37 毫米',
  mp_size_border: '12.4 毫米',
  mp_size: '131 ✕ 37 ✕ 12.4 毫米',
  mp_color: '白色',
  mp_model: 'CGM1',
  mp_real_weight: '30 克',
  mp_screen_size: '直径 26 毫米',
  mp_screen_size_value: '26 × 26 毫米',
  mp_temp_range_label: '温度',
  mp_humi_range_label: '湿度量程',
  mp_ble_type: '无线连接',

  do_slogan: '活力唤醒每一天',
  do_desc: '“无按键”设计 · 温湿度监测 · 16 组闹钟',
  do_snooze: '贪睡功能',
  do_ringtones: '8 种铃声',
  do_backlight: '背光可调',
  do_simple: '无按键设计，简约百搭',
  do_desc_2:
    '产品可被整体按下，操作简便有趣；软胶底座配合精巧的内部设计，{br}确保按压手感舒适；简约外观，轻松融入各种家居风格。',
  do_customize: '定制你的专属闹钟',
  do_desc_3:
    '每次蓝牙连接手机 app，都会自动同步时间，轻松实现精准对时；可设置 16 组闹钟，每组可单独设置“贪睡”功能；8 款铃声可选，愉悦唤醒每个清晨；闹铃音量可调。',
  do_sync_time_1: '* 对时和调整设置，需通过',
  do_sync_time_2: '或米家手机 app 进行。',
  do_shot_off: '* 长按产品 6 秒关闭所有闹铃，再次长按 6 秒恢复所有闹铃。',
  do_alarm: '闹钟',
  do_year: '年/月/日',
  do_week: '星期',
  do_temp_humi: '湿度/温度',
  do_time: '时间',
  do_joy: '简单, 好玩的交互方式, {br}唤醒美好清晨',
  do_desc_4:
    '铃声响起后，轻拍闹钟，即可进入贪睡模式，10 分钟之后将再次响起。用轻缓的方式唤醒清晨，{br}元气满满，告别起床气。',
  do_one_more: '拍一下，多睡一会（10 分钟之后再响）',
  do_two_step: '拍两下，起床了（停止闹钟）',
  do_monitor: '实时掌握家中温湿度',
  do_guard: '守护舒适生活',
  do_sensor_care:
    '采用瑞士 Sensirion 传感器，灵敏感知温湿度变化，细心呵护家人健康。',
  do_pat: '轻拍点亮背光，{br}柔光轻伴不摸黑',
  do_press:
    '按压闹钟，背光亮起。慵懒的清晨或深夜，随手轻拍查看时间，随时给你安心陪伴。',
  do_adjust: '随心调节背光',
  do_desc_5:
    '背光强弱分时段可调，例如晚 10 点至早 7 点亮度自动降为 50%；背光时长也可调，从完全不亮到持续点亮 30 秒。{br}夜晚不刺眼，白天看得清。',
  do_available: '* 此功能需通过',
  do_available_2: '或米家手机 app 进行。',
  do_smart: '房间舒适度的智能小管家',
  do_desc_6:
    '联动其他米家设备，实现室内温湿度的自动调节。例如：温度低于 20℃ 时，自动打开电暖器。',
  do_desc_7: '* 需搭配',
  do_desc_7_2: '米家蓝牙网关设备',
  do_desc_7_3: '（如',
  do_desc_7_4: '青萍蓝牙网关',
  do_desc_7_5: '），通过米家手机 app 创建联动。',
  do_concentrated: '小小闹钟，守护你的专注时光',
  do_desc_8:
    '适时放下手机，阅读 10 页书、睡前冥想 5分钟、高效工作 1 小时……从手机中分离出“闹钟”功能，{br}守护你的注意力，远离干扰。',
  do_routine: '重复规则自定义工作{br}休息自由切换',
  do_desc_9: '* 使用青萍+',
  do_desc_9_2:
    '或米家手机app，可设置闹铃周一至周日哪天重复。单双休、倒休，都能轻松调整。',
  do_desc_10:
    '* 添加闹钟、修改铃声等操作，手机和青萍蓝牙闹钟须在蓝牙传输距离范围内。',
  do_kids: '孩子的起居学习小伙伴',
  do_habit: '养成良好时间管理习惯',
  do_silence: '静静陪伴不打扰',
  do_sleep: '夜晚完全静音，伴你好眠。',
  do_colorful: '多样色彩',
  do_white: '米色',
  do_green: '绿色',
  do_blue: '蓝色',
  do_more: '更多细节',
  do_lcd_superview: 'LCD 显示屏，覆偏光膜，{br}更大可视角度，显示清晰。',
  do_plastic: '哑光塑胶外壳，{br}手感舒适',
  do_tpe: 'TPE 底座，{br}防滑稳固。',
  do_size: '80.3 × 41 × 83 毫米',
  do_size_long: '80.3 毫米',
  do_size_short: '83 毫米',
  do_size_height: '41 毫米',
  do_color: '米色、绿色、蓝色',
  do_temp_range: '-9.9℃ ~ 49.9℃',
  do_model: 'CGD1',
  do_weight: '99 克（不含电池）',
  do_screen_size: '61 × 61 毫米',
  do_battery_type: '2 节 5 号电池',

  sn_serious: '呼吸，事关重大',
  sn_automation: '全面监测室内空气 · 高精度传感器 · 超清触摸屏 · 智能联动',
  s_thirdth_title: '对空气，穷根究底',
  s_thirdth_desc_m:
    '室内环境下，很多因素影响着人们的体感舒适度和健康，除了我们熟知的温度、湿度、PM2.5 之外，还有 tVOC 和 CO₂。',
  s_thirdth_desc_1:
    '室内环境下，很多因素影响着人们的体感舒适度和健康，除了我们',
  s_thirdth_desc_2: '熟知的温度、湿度、PM2.5 之外，还有 tVOC 和 CO₂。',
  s_fourth_title: '小科普：',
  s_fourth_sub_title: 'tVOC 和 CO₂',
  s_fourth_desc_1:
    'tVOC 即总挥发性有机物，包含上千种化合物，其中部分物质可能有刺激性气味、部分物质可能对{br}⼈体健康有害。简单地说，当 tVOC 较高时，建议开窗或开启新风机通风。',
  s_fourth_desc_2:
    'CO₂ 即二氧化碳，是空气中常见的化合物，在封闭的室内，人的呼吸会导致 CO₂ 浓度不断上升，{br}当浓度达到一定程度时，人会感到困倦。开窗通风或打开新风机可快速降低室内 CO₂ 浓度。',
  s_tenth_title: '室外空气情况{br}也能一目了然',
  s_tenth_desc_1:
    '时间{spot}天气预报{spot}室外空气质量（AQI）{br}紫外线指数{spot}限行车牌号',
  s_tenth_desc_1_m:
    '时间{spot}天气预报{spot}室外空气质量（AQI）{br}紫外线指数{spot}限行车牌号',
  s_eleventh_title: '双色设计{br}铝镁合金机身',
  s_eleventh_title_m: '双色设计，铝镁合金机身',
  s_eleventh_sub_title: '白色{spot}黑色',
  s_eleventh_desc: '质感超群，百搭各种空间风格。',
  s_seventh_title: '“视网膜级”超清 IPS 屏',
  s_seventh_desc_1: '3.1 英寸{spot}PPI 328{spot}电容式触摸屏{spot}亮度自动调节',
  s_seventh_desc_1_m:
    '3.1 英寸{spot}PPI 328{spot}电容式触摸屏{spot}亮度自动调节',
  s_seventh_desc_2: '细腻度媲美高端手机，可视角度大，对比度高。',
  s_nineth_title: '手机式触摸操作',
  s_nineth_desc: '支持点触和滑动，操作很直观。',
  s_fifth_title: '性能超群',
  s_fifth_sub_title: '响应快{spot}测量准{spot}长期使用不漂移',
  s_fifth_icon_text_1: '1.2GHz 四核 A7 处理器',
  s_fifth_icon_text_2: '多模式补偿算法',
  s_sixth_title: '创新的通风孔设计，传感器工作更高效',
  s_sixth_desc_1: '风道更科学{spot}测量更准确{spot}工作更安静',
  s_sixth_desc_2: '充分接触空气, 日常使用不易遮挡。',
  s_twelfth_title_1: '支持“米家”',
  s_twelfth_title_2: '智能联动空气改善设备',
  s_twelfth_desc:
    '青萍空气检测仪是小米米家空气改善类设备的上佳拍档。通过米家 app，可设置青萍空气检测仪联动多种新风机、空气净化器、空调、电风扇、加湿器，一旦温度或湿度、PM2.5、tVOC 或 CO₂ 到达设定值，自动开启或关闭相关设备。',
  sn_fresh_air: '新风机',
  sn_pulifier: '空气净化器',
  sn_eighth_title: '支持“青萍+”App',
  s_eighth_desc: '随时随地地查看各项读数、行动建议。',
  s_thirteenth_title: '独立的检测仪才能客观评价空气情况',
  s_thirteenth_desc_1:
    '空调、空气净化器、新风机等设备自带的传感器只能检测机身周围小范围内的情况，不尽客观。',
  s_thirteenth_desc_2:
    '独立的空气检测仪可放在您更关心的位置，给出更客观的检测结果。',
  s_fourteenth_title: '基于 Linux 开发，功能可不断升级演进',
  s_fourteenth_desc:
    '和智能手机一样，可通过 OTA 升级来解决 bug、优化功能，甚至增加新功能。',
  pm25_range: '0 ~ 999 μg/m³',
  temp_range: '-10 ~ 50℃',
  hum_range: '0 ~ 100%RH（无凝露环境）',
  tvoc_range: '0.005 ~ 9.999 mg/m³',
  co2_range: '400 ~ 9999 ppm',
  s_size: '尺寸',
  s_color: '白色、黑色',
  s_size_value_long: '68.4 毫米',
  s_size_value_short: '86.3 毫米',
  s_size_value_height: '85 毫米',
  s_size_value: '85 × 68.4 × 86.3 毫米',
  s_weight: '重量',
  s_weight_value: '217 克',
  screen_resolution: '屏幕分辨率',
  screen_resolution_value: '720 × 720',
  s_battery_size: '电池容量',
  s_battery_size_value: '1800mAh/3.7V',
  usb_port: '电源接口',
  usb_port_value: 'USB-C',
  rated_input: '额定输入',
  rated_input_value: '5V⎓1A',
  s_usb_charge: 'USB 充电线',

  sp_pure: '一枚纯粹的米家蓝牙网关',
  sp_automation: '让米家蓝牙网关子设备自动联网{br} · 远程查看数据 · 智能联动',
  sp_wifi: '让你的智能门锁 “连上” Wi-Fi',
  sp_remote_1:
    '配备青萍蓝牙网关后，您可通过米家 app 远程接收智能门锁的开{br}锁通知、查看开锁记录等安全事件、查看剩余电量。',
  sp_desc_1:
    '* 青萍蓝牙网关须已正确插入电源，并和智能门锁添加到同一米家 app 账号下，且二者在蓝牙传输距离范围内。',
  sp_check: '随时查看家中温湿度',
  sp_desc_2:
    '支持米家蓝牙温湿度计、青萍蓝牙温湿度计 M 版等多种设备，{br}随时远程查看家中温湿度是否宜人，还可查看历史数据。',
  sp_desc_3:
    '* 青萍蓝牙网关须已正确插入电源，并和蓝牙温湿度计添加到同一米家 app 账号下，',
  sp_desc_3_2: '且二者在蓝牙传输距离范围内。',
  sp_desc_m:
    '* 青萍蓝牙网关须已正确插入电源，并和蓝牙温湿度计添加到同一米家 app 账号下，且二者在蓝牙传输距离范围内。',
  sp_smart: '智能联动',
  sp_smart_2: '开启更多新玩法',
  sp_smart_m: '智能联动，开启更多新玩法',
  sp_desc_4: '青萍蓝牙网关持续接收',
  sp_desc_4_2: '米家蓝牙网关子设备',
  sp_desc_4_3:
    '广播的蓝牙信号，并将收到的内容通过 Wi-Fi 上传到米家服务器，您可用米家app 远程查看蓝牙子设备的数据、配置它们与其他设备的智能联动策略。',
  sp_temp_rh: '蓝牙温湿度计',
  sp_gateway: '青萍蓝牙网关',
  sp_smart_lock: '智能门锁',
  sp_mihome: '米家 app',
  sp_access: '远程查看',
  sp_access_2: '配置智能联动',
  sp_server: '米家服务器',
  sp_aircondition: '空调',
  sp_humidifier: '加湿器',
  sp_heater: '电暖气',
  sp_baby_room: '让宝宝的房间更舒适',
  sp_desc_5:
    '配备青萍蓝牙网关后，可联动其他电器，如设置“温度高于 28℃，打开空调”，自动改善家中舒适度，宝宝和老人舒适安心、宠物怡然自得。',
  sp_gateway_1: '网关',
  sp_desc_6: '* 青萍蓝牙网关须已正确插入电源，并和蓝牙温湿度计、空调',
  sp_desc_6_2: '等设备添加到同一米家 app 账号下。',
  sp_welcome_home: '开锁亮灯 “欢迎回家”',
  sp_desc_7:
    '智能门锁配合蓝牙网关使用，可设置开门自动打开客厅灯、小爱同学播报“欢迎回家”等多种智能联动策略，为生活增添小惊喜。',
  sp_desc_8: '* 青萍蓝牙网关须已正确插入电源，并和智能门锁添加到同一米家',
  sp_desc_8_2: 'app 账号下，且二者在蓝牙传输距离范围内。',
  sp_work_with: '米家蓝牙网关子设备的好搭档',
  sp_desc_9: '青萍蓝牙网关支持所有的',
  sp_desc_9_2: '米家蓝牙网关子设备',
  sp_desc_9_3: '将它们添加至同',
  sp_desc_9_4: '一米家账号下，只要处于蓝牙信号传输范围内，就会自动连接，',
  sp_desc_9_5: '进而允许您从米家手机 app 端远程查看相关蓝牙子设备的状态、历',
  sp_desc_9_6: '史数据或事件通知、设置与其他设备的智能联动等操作。',
  sp_desc_9_m:
    '将它们添加至同一米家账号下，只要处于蓝牙信号传输范围内，就会自动连接，进而允许您从米家手机 app 端远程查看相关蓝牙子设备的状态、历史数据或事件通知、设置与其他设备的智能联动等操作。',
  sp_accessories: '* 在米家 app “产品百科”中可查看米家蓝牙网关子设备',
  sp_accessories_2: '完整列表',
  sp_accessories_3: '。米家蓝牙网关子设备需单独购买。',
  sp_small: '小巧、简单、纯粹',
  sp_plugged: '青萍蓝牙网关体积近似手机电源适配器，随处可插，部署方便。',
  sp_craftsmanship: '精工之作',
  sp_no_wasted: '一只当关，“五脏”俱全，{br}空间不浪费，性能不妥协。',
  sp_size_long: '62.16 毫米',
  sp_size_short: '42 毫米',
  sp_size_height: '31.5 毫米',
  sp_size: '62.16 × 42 × 31.5 毫米',
  sp_color: '白色',
  sp_temp_range: '-10 ~ 40℃',
  sp_humi_range: '0 ~ 90%RH，无冷凝',
  sp_weight: '46 克',
  sp_wifi_type: 'IEEE 802.11b/g/n 2.4GHz',
  sp_ble: '蓝牙 4.2',
  sp_power: '100–240V~, 50/60Hz, 0.2A',
  sp_env: '工作环境',
  sp_temp: '工作温度',
  sp_humi: '工作湿度',
  sp_power_in: '电源输入',

  plus_prd: '青萍家用产品小助手',
  plus_life: '生活更美好',
  plus_app: 'App Store 下载',
  plus_android: 'Android 下载',
  plus_plus: '青萍+',
  plus_home: '青萍家用产品小助手',
  plus_device: ' · 设备管理',
  plus_insights: ' · 读数解读',
  plus_historical: ' · 历史数据',
  plus_sharing: ' · 设备共享',
  plus_remote: ' · 远程访问',
  plus_notification: ' · 消息通知',
  plus_support: '支持 Android 和 iOS',
  download_apk: '下载安装文件 APK',

  language: '语言：',
  language_value_1: '简体中文',
  language_value_2: '繁體中文',
  language_value_3: 'English',

  mall_value_temporary: '天猫',
  mall_value_1: '天猫',
  mall_value_2: '亚马逊（美国）',
  mall_value_3: '亚马逊（欧洲）',
  mall_value_1_T: 'T 版 - 天猫',
  mall_value_2_T: 'T 版 - 亚马逊（美国）',
  mall_value_3_T: 'T 版 - 亚马逊（欧洲）',
  mall_value_1_H: 'H 版 - 天猫',
  mall_value_2_H: 'H 版 - 亚马逊（美国）',
  mall_value_3_H: 'H 版 - 亚马逊（欧洲）',
  mall_value_1_M: 'M 版 - 天猫',
  mall_value_2_M: 'M 版 - 亚马逊（美国）',
  mall_value_3_M: 'M 版 - 亚马逊（欧洲）',

  bran: '小米米家空气检测仪',
  duck: '小米米家蓝牙温湿度计',
  lora: '青萍温湿度计（无蓝牙）',
  lipstick: 'FOXIO Lipstick 移动电源',
  overview: '概述',
  icp: '粤ICP备19156805号-1',
  co2_cn: '二氧化碳',
  tvoc: 'TVOC',
  bran_screen: '高清触摸屏',
  duck_sensor: '高灵敏度传感器',
  duck_screen: '超低功耗 LCD',
  duck_wall: '磁吸墙贴',
  low: '超低功耗',
  thirdth_text_1: '支架未打开',
  thirdth_text_2: '支架已打开',
  fourth_text_1: '墙贴',
  fifth_desc_1: '电子墨水段码屏',
  fifth_desc_2: '普通低功耗段码屏',
  nineth_title: '自动记录温湿度历史{br}数据，长达 30 天',
  ninth_desc: '* 需连接“青萍+”手机 app。',
  cleargass_app: '青萍+',
  p_param: '产品参数',
  s_first_desc_1: '全面监测室内空气',
  s_first_desc_2: '高精度传感器',
  s_first_desc_3: '超清触摸屏',
  s_first_desc_4: '智能联动',
  s_fifth_desc: '温湿度、tVOC 和 CO₂ 传感器来自瑞士 Sensirion。',
  s_eighth_sub_title: '15° 仰角设计，查看更方便',
  s_tenth_desc_2:
    '* 本产品采用 CR2430 电池，在常见室温下可使用 8 ~ 12 个月。{br}数据来自青萍实验室，实际续航时间可能依环境和电池的差异而有所不同。',
  s_wifi_value: 'IEEE 802.11b/g/n 2.4GHz',
  s_battery_type_value: '锂离子电池',
  s_screen_size_value: '3.1 英寸',
  seven_battery: '7 号电池',
  p_fifth_title_wifi: 'Wi-Fi 版',
  p_fifth_title_nbiot: 'NB-IoT 版',
  p_fifth_title_lora: 'LoRa 版',
  p_fifth_sub_title: '待机时长',
  p_fifth_sub_desc_1: '（每 8 小时传一次，数据粒度为 15 分钟一组）',
  p_fifth_sub_desc_2: '（每小时传一次，数据粒度为 15 分钟一组）',
  p_fifth_temp: '{n}° 环境',
  p_fifth_day: '{n} 天',
  p_sixth_img_1: '远程查看数据',
  p_sixth_img_2: '接收通知',
  p_sixth_img_3: '导出数据',
  p_nineth_resolution_value: '0.01 kPa',
  p_nineth_typical_accuracy_value: '±0.05 kPa',
  p_nineth_typical_accuracy_value_desc: '@-20~50℃, 30~125 kPa',
  p_nineth_temp_value_1: '0.1℃',
  p_nineth_temp_value_2: '±0.2℃',
  p_nineth_temp_value_2_desc: '@0~50℃',
  p_nineth_hum_value_1: '0.1%',
  p_nineth_hum_value_2: '±2%',
  p_nineth_hum_value_2_desc: '@10~90%',
  ph_name: '产品名称',
  purchase: '购买',
  ph_home_title: '联网多面手，待机小超人',

  download_imd: '立即下载',
  download_imd_ios: 'App Store',
  download_imd_android: '软件商店',
  qrcode_text: '微信扫码关注{br}或搜索“青萍科技”',
  care: '关注我们',
  wechat: '微信',
  sina: '微博',
  facebook: 'Facebook',
  instagram: 'Instagram',

  dany_first_title: '一眼 “看清” 空气',
  dany_first_desc_1: '五项检测',
  dany_first_desc_2: '高精度传感器',
  dany_first_desc_3: '蓝牙网关',
  dany_first_desc_4: '小巧精致',
  dany_co2: 'CO₂',
  dany_pm25: 'PM2.5',
  dany_pm10: 'PM10',
  dany_temp: '温度',
  dany_humi: '湿度',
  dany_second_title: '每天呼吸几万次{br}是否都是好空气',
  dany_second_desc:
    '室内空气的洁净与新鲜程度需要高度关注。CO₂ 是衡量室内空气新鲜程度的重要指标，浓度太高时会让人昏昏欲睡。而空气中的颗粒物，如 PM2.5 和 PM10，粒径小、活性强，易附着有害物质，被人吸入后，可能影响呼吸系统，甚至进入血液循环，对儿童和老人的负面影响尤为显著。',
  dany_three_title: '五合一空气检测仪',
  dany_three_desc: '功能全面，提供 PM2.5、PM10、CO₂、温湿度共五项数据的检测。',
  dany_three_item1_title: 'PM2.5、PM10',
  dany_three_item1_desc: '监测颗粒污染物，及时开启空气净化器',
  dany_three_item1_desc_m: '监测颗粒污染物{br}及时开启空气净化器',
  dany_three_item2_title: 'CO₂',
  dany_three_item2_desc: '监测空气新鲜度标志性气体 CO₂，适时通风',
  dany_three_item2_desc_m: '监测空气新鲜度标志性{br}气体 CO₂，适时通风',
  dany_three_item3_title: '温度、湿度',
  dany_three_item3_desc: '监测温湿度，联动加湿器、空调、电暖器等环境电器',
  dany_three_item3_desc_m: '监测温湿度，联动加湿器{br}空调、电暖器等环境电器',
  dany_four_title: '读懂空气，健康呼吸',
  dany_four_item1_title: '检测办公室 CO₂，适时通风，提升工作效率',
  dany_four_item1_desc:
    '* 人的呼吸会导致室内 CO₂ 浓度不断上升、氧气含量降低，进而使人困倦。',
  dany_four_item2_title:
    '检测婴儿房温湿度，智能联动环境电器，{br}让宝宝睡得舒适香甜',
  dany_five_title: '多彩指示灯，一目了然',
  dany_five_desc: '通过颜色分级指示空气质量和温湿度水平，远远一看就知道。',
  dany_five_example: '以 CO₂ 为例：',
  dany_five_table_title1: '颜色',
  dany_five_table_title2: '范围',
  dany_five_table_title3: '评价',
  dany_five_table_range1: '400 ~ 1000 ppm',
  dany_five_table_range2: '1000 ~ 2000 ppm',
  dany_five_table_range3: '2000 ~ 3000 ppm',
  dany_five_table_range4: '3000+ ppm',
  dany_five_table_desc1: '正常',
  dany_five_table_desc2: '浓度略高',
  dany_five_table_desc3: '浓度较高',
  dany_five_table_desc4: '浓度超高',
  dany_five_memo: '* PM2.5、PM10、CO₂、温度、湿度的分级和颜色各不相同。',
  dany_six_title: '点击/滑动操作，{br}简单直观',
  dany_six_desc: '顶部电容触摸条，支持滑动、点击切换读数，{br}体验媲美触摸屏。',
  dany_seven_title: '高精度传感器，灵敏检测',
  dany_seven_desc: 'PM2.5、PM10、CO₂、温度、湿度 5 项数据检测，精准可靠。',
  dany_seven_item1_num: '1',
  dany_seven_item1_time: '秒',
  dany_seven_item1_desc: '五项数据每秒更新',
  dany_seven_item2_num: '0.3',
  dany_seven_item2_time: '微米',
  dany_seven_item2_desc: '可检测最小颗粒物',
  dany_eight_title: '格栅设计，传感器工作更高效',
  dany_eight_desc: '机身背面的格栅式通风孔设计，能让各个传感器都充分接触空气。',
  dany_nine_title: '支持两大智能家居平台',
  dany_nine_desc:
    '支持小米“米家”和苹果“家庭” app，可设置与各种产品的联动，定制你的智能家居场景。',
  dany_ten_title: 'App 远程查看，随时提醒',
  dany_ten_desc:
    '通过小米 “米家*”、“青萍+” 或苹果 “家庭” app* 查看青萍空气检测仪 Lite，随时随地，家中空气情况一目了然。',
  dany_ten_memo:
    '通过苹果“家庭” app 远程查看，需配备 HomeKit 中枢设备，如 HomePod 或 Apple TV。',
  dany_eleven_title: '它还是个米家蓝牙网关',
  dany_eleven_desc: '它能持续接收',
  dany_eleven_desc_2: '米家蓝牙网关子设备',
  dany_eleven_desc_3:
    '广播的蓝牙信号，并将收到的内容通过 Wi-Fi 上传到米家服务器。您可用小米“米家” app 远程查看蓝牙子设备的数据、配置它们与其他设备的智能联动策略。',
  dany_twelve_title: '空气差了，自动开启净化器',
  dany_twelve_desc:
    '可设置青萍空气检测仪 Lite 联动新风机、空气净化器、空调、电风扇、加湿器等设备，当{br}温度或湿度、PM2.5 或 PM10、二氧化碳达到设定值，自动开启或关闭相关设备。',
  dany_twelve_item1: 'PM2.5 超过 100，打开空气净化器*',
  dany_twelve_item2: '湿度低于 30%，打开加湿器*',
  dany_twelve_memo: '需在米家 app 设置联动策略实现。',
  dany_thirteen: '支持 Siri / 小爱同学',
  dany_thirteen_desc: ' ',
  dany_thirteen_memo: ' ',
  dany_fourteen_title: 'USB-C 口，可充电锂电池',
  dany_fourteen_desc:
    '支持 USB 持续供电。内置 2000mAh 锂电池，续航时间长达 7 小时。',
  dany_fifteen_title: '掌心大小，精致小巧',
  dany_fifteen_desc: '仅重 143 克，与一颗小苹果相当。',
  dany_sixteen_title: '简约外观{br}优雅融入各种家居风格',
  dany_sixteen_desc: '极简设计，白色外框搭配圆润的屏幕，温和百搭。',
  dany_seventeen_item1:
    '像素风 UI 设计，赋予产品更多个性和趣味；OLED 屏幕，显示更清晰，可视角度大。',
  dany_seventeen_item2: '硅胶底座，稳固摆放。',
  dany_seventeen_item3: '背面墨绿色格栅，美观优雅。',

  dany_long: '63.6 毫米',
  dany_short: '46 毫米',
  dany_height: '54.6 毫米',
  dany_size: '63.6 × 46 × 54.6 毫米',
  dany_color: '白色',
  dany_temp_range: '0 ~ 40°C',
  dany_humi_range: '0 ~ 95%RH（无凝露环境）',
  dany_co2_range: '400 ~ 9999 ppm',
  dany_pm25_range: '0 ~ 500 μg/m³',
  dany_pm10_range: '0 ~ 500 μg/m³',
  dany_memo: '* 请勿长时间在湿度超过 90% 的环境下使用。',
  dany_name: '青萍空气检测仪 Lite',
  dany_model: 'CGDN1',
  dany_weight: '143 克',
  dany_screen: '59.9 × 49.9 毫米',
  dany_ble: '蓝牙 5.0',
  dany_wifi: 'IEEE 802.11b/g/n 2.4GHz',
  dany_battery: '2000mAh',

  duck2: '青萍蓝牙温湿度计 Lite',
  dk2_first_title: '青萍蓝牙温湿度计 Lite',
  dk2_first_desc_1: '高精度传感器',
  dk2_first_desc_2: '高屏占比',
  dk2_first_desc_3: '三种摆放方式',
  dk2_first_desc_4: '小巧精致',
  dk2_second_title: '关注温湿度变化{br}营造健康舒适环境',
  dk2_second_desc:
    '婴儿在温湿度适宜的环境中，睡得香，醒来次数少。青萍蓝牙温湿度计 Lite，冷暖干湿一目了然，守护家人健康。',
  dk2_three_title: '高屏占比，美观易读',
  dk2_three_desc: '屏占比高，简约纯粹，赏心悦目。',
  dk2_four_title: '瑞士 Sensirion 传感器{br}精度高，反应灵敏',
  dk2_four_desc:
    '青萍蓝牙温湿度计 Lite 采用在工业和消费市场广受赞誉的瑞士 Sensirion 温湿度传感器，{br}辅以精妙的结构设计、ART算法，准确性、响应速度俱佳。',
  dk2_five_title: '内置蓝牙，支持米家',
  dk2_five_desc:
    '搭配米家蓝牙网关设备，青萍蓝牙温湿度计 Lite 可联动智能设备，改善室内温湿度环境，让家更舒适',
  dk2_five_left_title: '室内温度高时，自动开启空调',
  dk2_five_left_item1: '青萍蓝牙温湿度计 Lite',
  dk2_five_left_item2: '青萍蓝牙网关',
  dk2_five_left_item3: '智能空调',
  dk2_five_right_title: '室内空气干燥时，自动开启加湿器',
  dk2_five_right_item4: '加湿器',
  dk2_six_title: '超低功耗，续航长达 8 个月',
  dk2_six_desc:
    '青萍蓝牙温湿度计 Lite 采用超低功耗段码 LCD 屏幕，搭配纽扣电池，在常见室温环境下可持续使用 8 个月。',
  dk2_six_memo: '* 实际待机时间受环境和电池影响。',
  dk2_seven_title: '三种摆放方式{br}随心选择',
  dk2_seven_title_m: '三种摆放方式，{br}随心选择',
  dk2_seven_item1_title: '01.墙面模式',
  dk2_seven_item1_desc:
    '包装内有墙贴，将它粘在墙上，青萍蓝牙温湿度计 Lite 就能吸附在墙面上了。',
  dk2_seven_item2_title: '02.桌面模式',
  dk2_seven_item2_desc:
    '包装内有桌面支架，能让青萍蓝牙温湿度计 Lite 秒变桌面摆件，巧妙又稳当',
  dk2_seven_item3_title: '03.磁吸模式',
  dk2_seven_item3_desc:
    '青萍蓝牙温湿度计 Lite 内置磁铁，可吸附在冰箱或其他铁制器物上',
  dk2_eight_title: '随时随地查看温湿度、历史数据',
  dk2_eight_desc:
    '产品内置存储能力，可存储每 15 分钟一条、长达 30 天的历史数据。',
  dk2_nine_title: '小巧精致，轻盈又百搭',
  dk2_nine_desc: '直径 60.5 毫米，厚度 13.7 毫米。',
  dk2_ten_desc1:
    '冬季，温度在 20°C ~ 24°C、相对湿度在 30% ~ 60% 时，人体感觉较为舒适；在夏季，温度在 23°C ~ 26°C、相对湿度在 25% ~ 60% 时，人体感觉较为舒适。',
  dk2_ten_desc2:
    '随产品附赠 1 枚电池。电池耗尽时，用户需自行购买替换的电池，各大电商和超市均有售。',
  dk2_ten_desc3:
    '您可通过青萍+ app 读取青萍蓝牙温湿度计本地存储的历史数据，并可选择导出  CSV 文件。',
  dk2_ten_list2:
    '、智能空调、加湿器设备均需另行购买。联动动作和生效时间段需用户自行设置。',
  dk2_ten_list3: '支持米家的智能空调产品。',
  dk2_ten_list4: '支持米家的加湿器产品。',

  dk2_long: '60.5 毫米',
  dk2_height: '13.7 毫米',
  dk2_size: '60.5 × 60.5 × 13.7 毫米',
  dk2_color: '白色',
  dk2_temp_range: '-9.9 ~ 50°C',
  dk2_humi_range: '0 ~ 99.9%RH（无凝露环境）',
  dk2_memo: '* 请勿长时间在湿度超过 90% 的环境下使用。',
  dk2_name: '青萍蓝牙温湿度计 Lite',
  dk2_model: 'CGDK2',
  dk2_weight: '38 克（不含支架、墙贴、电池）',
  dk2_screen: '直径 49.9 毫米',
  dk2_ble: '蓝牙 5.0',
  dk2_battery: 'CR2430 型纽扣电池',
  dk2_pack_wall: '墙贴',
  dk2_pack_desk: '桌面支架',
  dk2_pack_battery: 'CR2430 纽扣电池',

  hodor: '青萍门窗开合传感器',
  hodor_first_title: '青萍门窗开合传感器',
  hodor_first_desc_1: '感知门窗开关状态',
  hodor_first_desc_2: '智能联动',
  hodor_first_desc_3: '蓝牙 5.0',
  hodor_second_title: '感应门窗开合',
  hodor_second_desc: '通过主体与磁铁之间的距离感应，判断门窗开合',
  hodor_second_magnet: '磁铁',
  hodor_second_main: '主体',
  hodor_three_title: '远程推送通知',
  hodor_three_desc: '检测到门窗开合，app 远程自动推送提醒，时刻守护家中安全。',
  hodor_four_title_1: '接入米家',
  hodor_four_title_2: '，智能联动',
  hodor_four_desc: '搭配米家其他智能产品，设置联动策略，实现多种智能场景。',
  hodor_five_left_title: '开窗后自动关闭空气净化{br}器，减少滤芯消耗。',
  hodor_five_item1: '青萍门窗开合{br}传感器',
  hodor_five_item2: '青萍蓝牙网关',
  hodor_five_item3: '空气净化器',
  hodor_five_right_title: '推开门，自动开灯，{br}无论多晚，总有一盏为你守候。',
  hodor_five_item4: '智能灯',
  hodor_six_title: '蓝牙 5.0',
  hodor_six_desc: '采用低功耗蓝牙 5.0，续航长达 8 个月',
  hodor_seven_title: '身材小巧，随处可贴',
  hodor_seven_desc: '体积小，颜值高，适用场景丰富。即贴即用，无需安装工具。',
  hodor_seven_item1: '衣柜',
  hodor_seven_item2: '抽屉',
  hodor_seven_item3: '门窗',
  hodor_eight_title: '安装说明',
  hodor_eight_desc:
    '* 安装时请让主体和磁铁中线成顶部对齐，并确保它们的间距 < 15 毫米。',
  hodor_ten_desc1: '本产品需搭配具有米家蓝牙网关功能的设备使用，如',
  hodor_ten_desc1_2: ' ',
  hodor_ten_desc2: '、空气净化器、智能灯等设备需另行购买。',
  hodor_ten_desc3: '联动动作和生效时间段需用户自行设置。',
  hodor_ten_list1: '需在米家 app 设置联动策略实现。',
  hodor_ten_list2: '支持米家的空气净化器产品。',
  hodor_ten_list3: '支持米家的智能灯具产品。',
  hodor_ten_list4:
    '续航时长可达 8 个月，是在平均 1 小时开或关门  1 次情况下测得，实际续航时间可能依使用频率不同而有所不同。',


  hodor_front_bottom: '21.5 毫米',
  hodor_front_right: '44 毫米',
  hodor_side_bottom: '13 毫米',
  magnet_front_bottom: '14 毫米',
  magnet_front_right: '29 毫米',
  magnet_side_bottom: '7 毫米',
  hodor_height: '13.7 毫米',
  hodor_size: '主体 44 × 21.5 × 13 毫米{br}磁铁 29 × 14 × 7 毫米',
  hodor_color: '白色',
  hodor_name: '青萍门窗开合传感器',
  hodor_model: 'CGH1',
  hodor_weight: '主体 6 克（不含电池），磁铁 7 克',
  hodor_ble: '蓝牙 5.0',
  hodor_battery: 'CR1632 型纽扣电池',
  hodor_magnet: '磁铁',

  sensor_name: '传感器',

  parrot: '青萍动作和环境光传感器',
  parrot_first_title: '青萍动作和环境光传感器',
  parrot_first_desc_1: '灵敏识人',
  parrot_first_desc_2: '精准感知光线变化',
  parrot_first_desc_3: '动作和照度结合联动',
  parrot_second_title: '有人经过，灵敏感知',
  parrot_second_desc: '内置 PIR',
  parrot_second_desc_1:
    '，精准识别人的移动。摆放角度灵活可调，让识别范围更广。您可按需设置“有人移动”或“无人移动”触发的自动化。',
  parrot_three_title: '精准检测光线，灵活设置联动',
  parrot_three_title_1: '，而非笼统的 “光线强” 或 “光线弱”',
  parrot_three_desc: '天色变暗就自动开灯，还是入夜才开，随您设置，精准把握。',
  parrot_three_range: '光照强度量程：',
  parrot_three_num: '0 ~ 83,000',
  parrot_three_sensor: '灵敏度可达：',
  parrot_three_value: '2',
  parrot_three_unit: 'lux',
  parrot_three_memo: '动作和光照强度结合，创建丰富的{br}智能联动场景',
  parrot_four_title: '天色渐暗，自动点亮灯光',
  parrot_four_desc:
    '人们专注读书的时候，往往忽略了室内光线正在变暗。可以设置光照强度低于 400 lux，自动开灯，让阅读更轻松，同时保护视力。',
  parrot_five_title: '夜里起床，开启轻柔夜灯',
  parrot_five_desc:
    '可设置当检测到卧室有人起床，且光照强度低于 20 lux 时，自动开启智能床头灯，或让洗手间的智能灯亮起，夜里起床不摸黑。',
  parrot_six_title: '匆忙出门，它能帮你节能',
  parrot_six_desc:
    '可设置未检测到人的移动，自动关灯、关闭空调或电暖器等家电，节能又安全。',
  parrot_seven_title: '异常闯入，即时提醒',
  parrot_seven_desc:
    '外出时，当家中检测到有人移动，手机 app 会即时收到提醒，守护家中安全。',
  parrot_check_remote: '远程查看数据，设置智能联动。',
  parrot_eight_title:
    '随时了解光照强度、查看历史数据，设置与米家智能设备的联动。',
  parrot_eight_desc: '远程查看和设置智能联动需配备米家蓝牙网关设备，如',
  parrot_nineteen_desc:
    '自动记录历史数据：光照强度数据长达 30 天；动作感应数据多达 3000 条。',
  parrot_nineteen_memo:
    '使用青萍+ app 查看和下载历史数据，T 版需配备家居中枢，如 HomePod 或 Apple TV；M 版需配备',
  parrot_nine_title: '自带磁吸底座，灵活调整探测方向',
  parrot_nine_desc: '产品可在半球形磁吸底座上自由转动，停在任意适合的角度。',
  parrot_ten_title: '三种摆放方式可选',
  parrot_ten_item1: '粘贴（墙面）',
  parrot_ten_item2: '平放（桌面）',
  parrot_ten_item3: '磁吸（铁器表面）',
  parrot_eleven_title: '多区多段菲涅尔透镜，灵敏度高',
  parrot_eleven_desc:
    '内置多区多段菲涅尔透镜，充分利用每个光学单元，体积更小巧，反应更灵敏。',
  parrot_twelve_title: '用料考究，工艺精湛',
  parrot_twelve_desc:
    '采用 TI（德州仪器）精密光照传感器，灵敏检测光照强度。外壳采用抗 UV 材质，长期使用不易褪色。',
  parrot_thirteen_title: '续航长达 2 年',
  parrot_fourteen_title: '简约设计，百搭各种家居风格',
  parrot_fifteen_title: '便携安装',
  parrot_fifteen_item1: '方法一：',
  parrot_fifteen_item1_desc:
    '撕下底座上的双面胶覆膜，将底座粘在选好的位置，并将产品主体吸附到底座上。',
  parrot_fifteen_item2: '方法二：',
  parrot_fifteen_item2_desc:
    '撕下底座上的双面胶覆膜，将底座粘在选好的位置，并将产品主体吸附到底座上。',
  parrot_fifteen_main: '主体',
  parrot_fifteen_base: '底座',
  parrot_fifteen_tape: '双面胶',
  parrot_sixteen_title: '人体动作检测区域',
  parrot_ten_list1:
    '要设置智能联动，青萍动作和环境光传感器 T 版需配备家居中枢，如 HomePod 或 Apple TV，使用 Apple Home app 设置；M 版需配备米家蓝牙网关设备，如',
  parrot_ten_list1_1: '，使用米家 app 设置。',
  parrot_ten_list2:
    '在环境温度为 25°C、平均每天探测到 120 次人体移动的条件下测得。',
  parrot_ten_list3:
    '检测区域仅为示意图，测试数据来自青萍合作实验室，在常温 (25°C) 环境、产品安装在高 2.2 米处且向下倾斜 20° 的条件下测得。',

  parrot_bottom: '38 毫米',
  parrot_height: '35.9 毫米',
  parrot_bottom_bottom: '36 毫米',
  parrot_bottom_height: '13.7 毫米',
  parrot_size: '主体：38 × 38 × 35.9 毫米，{br}底座：36 × 36 × 13.7 毫米',
  parrot_size_sensor: '主体：38 × 38 × 35.9 毫米',
  parrot_size_base: '底座：36 × 36 × 13.7 毫米',
  parrot_color: '白色',
  detect_distance: '动作检测距离',
  parrot_distance: '7 米',
  detect_angle: '动作检测角度',
  parrot_angle: '15°（7 米）~ 120°（2 米以内）',
  light_range: '光照强度量程',
  parrot_range: '0 ~ 83,000 lux',
  parrot_name: '青萍动作和环境光传感器',
  parrot_t_name: '青萍动作和环境光传感器 T 版',
  parrot_m_name: '青萍动作和环境光传感器 M 版',
  parrot_t_model: 'CGPR1T',
  parrot_m_model: 'CGPR1M',
  parrot_model: 'CGPR1',
  parrot_weight: '主体：33.8 克，底座：6.6 克',
  parrot_ble: '蓝牙 5.0',
  parrot_work_temp: '工作温度',
  parrot_temp: '-10 ~ 45°C',
  parrot_work_humi: '工作湿度',
  parrot_humi: '0 ~ 90%RH',
  parrot_battery: '2 颗 CR2450 纽扣电池',
  parrot_pack_body: '传感器主体',
  parrot_pack_bottom: '传感器底座',
  parrot_pack_stick: '双面胶',

  //chicken
  chicken: '青萍蓝牙小钟',
  chicken_first_title: '有条不紊，用心生活',
  chicken_first_desc1: '循环计时',
  chicken_first_desc2: '16 组闹钟',
  chicken_first_desc3: '正计时',
  chicken_first_desc4: '温湿度监测',
  chicken_second_title: '计时、定时都精通，时间管理小助手',
  chicken_second_desc: '6 种功能，一物多用。',
  chicken_second_fun1: '闹钟',
  chicken_second_fun2: '循环计时',
  chicken_second_fun3: '正计时',
  chicken_second_fun4: '时钟',
  chicken_second_fun5: '温度计',
  chicken_second_fun6: '湿度计',
  chicken_third_title: '开启循环计时',
  chicken_third_title1: '，保持专注',
  chicken_third_title_en: 'Boost Your Productivity with the Tomato Timer',
  chicken_third_desc: '工作时段',
  chicken_third_desc1: '保持专注，休息时段',
  chicken_third_desc2: '尽情放松，张弛有度更高效。',
  chicken_third_desc_en:
    'Immerse yourself during working time, and have fun during breaks',
  chicken_fourth_title: '随心设置 16 组闹钟{br}生活、学习更规律',
  chicken_fourth_desc:
    '可设置 16 组闹钟，应对日常事项提醒。每组闹钟可单独设置稍后提醒',
  chicken_fourth_desc1: '功能和重复规则。',
  chicken_fifth_title: '优雅计时',
  chicken_fifth_title1: '，每天一点小坚持',
  chicken_fifth_desc: '运动、冥想、读书…...你的专注时光，{br}有它安静陪伴。',
  chicken_sixth_title: '精准测温湿',
  chicken_sixth_title1: '3 种表情提示',
  chicken_sixth_desc:
    '内置瑞士 Sensirion 温湿度传感器，精度高，{br}反应灵敏。3 种表情符号，一眼看懂房间舒适度。',
  chicken_sixth_level1: '舒适',
  chicken_sixth_level2: '一般',
  chicken_sixth_level3: '不舒适',
  chicken_seventh_title: '远程设置闹钟、循环计时',
  chicken_seventh_desc:
    '搭配青萍蓝牙网关，您可通过青萍+ app 远程查看和设置{br}闹钟、番茄钟。',
  chicken_eighth_title: '智能联动，让房间更舒适',
  chicken_eighth_title1: ' ',
  chicken_eighth_desc: '联动米家智能设备',
  chicken_eighth_desc1:
    '，自动改善家中舒适度。{br}例如：温度高于 26°C 时，自动打开空调。',
  chicken_ninth_title: '轻松对时，保持精准',
  chicken_ninth_desc:
    '传统时钟，走时很容易出现误差，对时麻烦。青萍蓝牙小钟会在连接手机{br} app 时与手机自动对时。如将青萍蓝牙小钟和青萍蓝牙网关一起添加到青{br}萍+ app，青萍蓝牙小钟会通过网关每天自动对时，保持精准。',
  chicken_tenth_title1: '纤薄屏幕，微微透光',
  chicken_tenth_title2: '贴心倾角，查看更方便',
  chicken_eleventh_title: '风格百搭，放哪里都好看',
  chicken_twelfth_title: '更多细节',
  chicken_twelfth_desc1: '5 毫米超薄机身',
  chicken_twelfth_desc2: '一体式按钮',
  chicken_thirty_title: '两色可选',
  chicken_thirty_desc1: '粉色',
  chicken_thirty_desc2: '米色',
  chicken_fourteen_desc1: '需在米家或',
  chicken_fourteen_desc1_1: ' app 中设置，双击底座上的按键开启循环计时。',
  chicken_fourteen_desc2:
    '工作时段：默认 25 分钟，可在 5 ~ 100 分钟之间选择; {br}休息时段：默认 5 分钟，可在 1 ~ 100 分钟之间选择。',
  chicken_fourteen_desc3:
    '闹铃响起后，单击底座上的按键，进入稍后提醒模式，10 分钟之后将再次响起；双击可取消稍后提醒。',
  chicken_fourteen_desc4: '双击底座上的按键开启正计时功能。',
  chicken_fourteen_desc5: ' ',
  chicken_fourteen_desc5_1:
    '使用米家 app ，搭配米家蓝牙网关设备，可远程查看温湿度；使用',
  chicken_fourteen_desc5_2:
    ' app，搭配，还可查看历史数据。网关设备需额外购买。',
  chicken_fourteen_desc6:
    '实现联动场景或远程查看温湿度，需搭配米家蓝牙网关设备，如',
  chicken_fourteen_desc6_1: '、小爱音箱等。米家智能设备需额外购买。',

  chicken_bottom: '90.27 毫米',
  chicken_height: '93.27 毫米',
  chicken_width: '40 毫米',
  chicken_size: '90.27 × 93.27 × 40 毫米',
  chicken_color: '米色、粉色',
  chicken_name: '青萍蓝牙小钟',
  chicken_model: 'CGC1',
  chicken_weight: '53.1 克（不含电池）',
  chicken_ble: '蓝牙 5.0',
  work_temp: '工作温度',
  chicken_temp: '-9°C ~ 50°C',
  work_humi: '工作湿度',
  chicken_humi: '0 ~ 99%RH（无凝露环境）',
  chicken_battery: 'CR2430 纽扣电池',

  frog: '青萍商用温湿度计 E',
  frog_index_desc: 'IP55 级防护{br}超长待机',
  frog_needle: '青萍外接温度探头',
  frog_first_title: '防尘防水，专业温湿度监控方案',
  frog_first_temp_range: '温度量程',
  frog_first_humi_range: '湿度量程',
  frog_first_level: '防护等级',
  frog_first_level_title: '防护等级',
  frog_first_special: '特殊材料',
  frog_first_range1: '-30 ~ 60°C',
  frog_first_range2: '-40 ~ 125°C',
  frog_first_range3: '0 ~ 100%RH',
  frog_first_range4: '-',
  frog_first_range5: 'IP55',
  frog_first_range6: 'IP68',
  frog_first_range7: '-',
  frog_first_range8: '食品级材料',
  frog_first_complete: '查看完整规格',
  frog_second_title_1: '整机 IP55',
  frog_second_title_2: ' 级防护，防尘防水',
  frog_third_title: '支持外接温度探头',
  frog_third_desc: '可监测水温、信号被隔绝的空间的温度。能持续浸水使用。',
  frog_third_left_1: 'IP68',
  frog_third_left_2: ' 级防护',
  frog_third_right: '食品级材料',
  frog_fourth_title: '多种供电方式可选',
  frog_fourth_left: '方式一：5 号电池供电',
  frog_fourth_right: '方式二：Micro-USB 接口供电',
  frog_fourth_right_desc: '可长期连接 Micro-USB 电源。不支持充电。',
  frog_fifth_title: '待机时间计算器',
  frog_fifth_desc: '待机有多久，算算更明了。',
  frog_sixth_title: '长距离无线通信，多种可选方案',
  frog_sixth_desc: '可在 3 种方案中选择一种。',
  frog_sixth_wifi: '使用环境需有稳定的 Wi-Fi 信号。',
  frog_sixth_lora: '需配备 LoRa 网关使用。',
  frog_sixth_nb: '需当地中国移动提供 NB-IoT',
  frog_sixth_nb_2: ' 网络服务。',
  frog_seventh_title: '外置天线，信号更佳',
  frog_seventh_signal: '信号强',
  frog_seventh_cover: '覆盖广',
  frog_seventh_stable: '传输稳定',
  frog_eight_title: '高精度传感器',
  frog_eight_left_1: '典型精确度',
  frog_eight_left_2: '分辨力',
  frog_eight_left_3: '量程',
  frog_eight_left_4: '±0.5°C{br}（@0 ~ 50°C）',
  frog_eight_left_5: '±5%RH{br}（@10% ~ 90%RH）',
  frog_eight_left_6: '0.1°C',
  frog_eight_left_7: '1%RH',
  frog_eight_left_8: '-30 ~ 60°C',
  frog_eight_left_9: '0 ~ 100%RH',
  frog_eight_right_1: '-40 ~ 125°C',
  frog_ninth_title: '青萍物联',
  frog_ninth_desc:
    '青萍物联是为青萍智能硬件产品提供设备管理、数据收集、数据查询以及实时警报服务的物联网平台。',
  frog_ten_title: '完善的第三方开发文档和 API，支持二次开发',
  frog_ten_developer: '开发者平台',
  frog_ten_dev_add: 'developer.qingping.co',
  frog_eleven_title: '多场景专业应用',
  frog_eleven_desc:
    '如车间、仓库、机房、冷库、实验室、药店、酒窖、大棚、养殖场等。',
  frog_eleven_pharmacy: '药店',
  frog_eleven_garbage: '仓库',
  frog_eleven_farm: '养殖场',
  frog_eleven_greenhouse: '大棚',
  frog_twelfth_title: '多种安装方式',
  frog_twelfth_left_1: 'DIN 导轨',
  frog_twelfth_left_2: ' 固定',
  frog_twelfth_right: '磁吸',
  frog_bottom_1:
    '青萍商用温湿度计 E 可防尘防水。经实验室测试，在 IEC60529 标准下，其防 护效果达到 IP55 级别（使用喷头内径为 6.3 毫米的喷嘴，从 2.5 ~ 3 米左右的距离以 12.5 升/分钟的供水强度持续喷洒 3 分钟）。 本品不可浸泡于水中或长期处在扬尘环境中，防水、防尘功能并非永久有效，防护性能可能会因日常使用而下降。',
  frog_bottom_2:
    '青萍外接温度探头可防尘，能持续浸水使用。经实验室测试，在 IEC60529 标准下，其防护效果达到 IP68 级别（浸泡于 1.5 米深的水中，持续时间 1 小时）。防水、防尘功能并非永久有效，防护性能可能会因日常使用而下降。',
  frog_bottom_3:
    '经实验室测试，在 GB4086 标准下，青萍外接温度探头的材料（包括不锈钢探头和硅胶线）符合食品级要求。',
  frog_bottom_4:
    '设备出厂内置 SIM 卡，含 8 年中国移动 NB-IoT 流量资费（限 50 MB/年）。不同批次所包含的资费可能不同。',
  frog_bottom_5:
    '本品可被安装在 35 毫米 DIN 导轨上，DIN 导轨及固定夹需另行购买。',
  frog_spec_long: '87.6 毫米',
  frog_spec_short: '33 毫米',
  frog_spec_height: '156.8 毫米',
  frog_spec_size: '87.6 × 156.8 × 33 毫米（含天线）',
  frog_color: '深灰色',
  frog_temperature:
    '-30 ~ 60°C （使用锂铁 5 号电池）{br}-10 ~ 50°C （使用普通 5 号电池）{br}0 ~ 40°C （使用 USB 接电）',
  frog_humidity:
    '0 ~ 100%RH（使用锂铁 5 号电池或普通 5 号电池，无凝露环境）{br}0 ~ 90%RH （使用 USB 接电，无凝露环境）',
  frog_weight: '118 克',
  frog_screen_size: '64.6 × 44.6 毫米',
  frog_wifi: 'Wi-Fi 或 NB-IoT 或 LoRa',
  frog_ble: '蓝牙 5.0',
  frog_power: 'Micro-USB',
  frog_power_in: '电源接口',
  frog_inout: '5V⎓1A',
  frog_battery_support: '电池供电',
  frog_battery: '可使用 2 节 5 号锂铁电池或普通电池',
  frog_protect: 'IP55',
  frog_package_battery: '2 节 5号{br}锂铁电池',
  needle_size:
    '传感器探头：直径 6 毫米，长度 50 毫米{br}线身：直径 3.5 毫米，长度 1500 毫米{br}吸盘式固定支架：直径 35 毫米，高 20 毫米',
  needle_temperature: '-40 ~ 125℃',
  needle_weight: '29 克',
  needle_connect_way: '连接方式',
  needle_connect: '3.5 毫米 AUX',
  needle_protect: 'IP68',
  needle_material_left: '产品材质',
  needle_material: '食品级 316L 不锈钢',
  sensor_detect: '传感器探头',
  sensor_body: '传感器线身',
  needle_body: '食品级硅胶',
  needle_paste: '硅胶',
  needle_with: 'PET',
  needle_package_bottom: '吸盘式{br}固定支架',
  needle_package_with: '胶带{br}（用于固定产品）',
  battery_type: '电池类型',
  battery_type_1: '锂铁 5 号电池',
  battery_type_2: '普通 5 号电池',

  //关于我们
  about_title: '关于我们',
  about_one:
    '青萍科技（北京）有限公司成立于 2015 年，是全球领先的温湿度和空气质量检测产品和服务提供商',
  about_two:
    '青萍致力于环境类 IoT 产品的研发和设计，为消费者和行业客户提供监测和控制解决方案，产品覆盖世界多个国家和地区，多次荣获红点、iF等国际设计奖项。',
  about_three:
    '在消费市场上，青萍产品在主流电商平台拥有行业领先的销量和好评率，并与多家知名电子消费品牌建立了传感器类产品的合作。',
  about_four:
    '在行业市场上，青萍公司在建筑、食品、供热、养殖、种植等传感器应用领域拥有丰富的客户资源和技术积累。',
  about_five: '公司总部位于北京，设有深圳制造中心和苏州客服中心；自有工厂已通过 ISO9001 认证。',
  about_six: ' ',
  //Dove app download
  clock_text_1: '青萍蓝牙闹钟支持下列手机 app：',
  clock_text_2:
    '通过它们连接青萍蓝牙闹钟，您可以调整青萍蓝牙闹钟的闹铃时间、铃声、背光、屏幕显示语言等。每次成功连接后，都会自动对时。',
  mijia: '米家',
  mijia_desc: '小米智能家居 app。',
  app_plus: '青萍+',
  app_plus_desc: '青萍智能设备管理 app。',
  clock_download_tip: '或在软件商店搜索下载。',

  //pheasant-co2
  ph_co2_realtime: '实时',
  ph_co2_size_long: '77 毫米',
  ph_co2_size_height: '28 毫米',
  ph_co2_size_value: '77 × 77 × 28 毫米',
  ph_co2: 'CO₂ 量程',
  ph_co2_value: '400 ~ 9999 ppm',
  ph_co2_temp_value: '-20 ~ 50°C',
  ph_co2_hum_value: '0 ~ 99%RH（无凝露环境）',

  ph_co2_name_value: '青萍二氧化碳和温湿度检测仪',
  ph_co2_screen_value: '61 × 49 毫米',
  ph_co2_wifi_value: 'Wi-Fi 或 NB-IoT 或 LoRa',
  ph_co2_battery_value: '2600mAh',

  ph_co2_first_title: '青萍二氧化碳和温湿度检测仪',
  ph_co2_first_desc: '高精度传感器 · 无线连接 · 远程监测 · 超限及时通知',
  ph_co2_second_title: 'CO₂ 是衡量室内空气质量的重要指标',
  ph_co2_second_desc:
    'CO₂ 即二氧化碳，是空气中常见的化合物。室内 CO₂ 主要来自于人的呼吸，在密闭的房间里，人的呼吸会导致 CO₂ 浓度不断上升、氧气含量降低，进而使人困倦。{br}',
  ph_co2_second_desc_2:
    '当室内 CO₂ 浓度过高时，通常表明通风不良，可能导致空气中其他污染物（如挥发性有机物）的积累，通过空气传播的病毒的感染风险也会提高。',
  ph_co2_third_title: '监测室内 CO₂，适时采取改善措施',
  ph_co2_third_desc:
    '可广泛应用于家庭、学校、办公楼、医院、餐厅、商场、工厂、酒店、实验室等场景。',
  ph_co2_third_table_color: '颜色',
  ph_co2_third_table_range: '范围',
  ph_co2_third_table_rate: '评价',
  ph_co2_third_range_normal: '400 ~ 1000 ppm',
  ph_co2_third_rate_normal: '正常',
  ph_co2_third_range_high: '1000 ~ 1400 ppm',
  ph_co2_third_rate_high: '浓度略高',
  ph_co2_third_range_pretty_high: '1400+ ppm',
  ph_co2_third_rate_pretty_high: '浓度较高',
  ph_co2_third_memo: '* 通过颜色分级指示，快速了解 CO₂ 浓度水平。',
  ph_co2_four_desc1:
    '当检测到办公室、学校教室的 CO₂ 浓度过高时，适时通风，提升工作、学习效率。',
  ph_co2_four_desc2:
    '检测商场和餐厅等公共场所的 CO₂，可用于评价室内通风水平和人员聚集情况。CO₂ 浓度过高时，适时采取通风、开启新风系统等改善措施，减少空气中有害物质的聚集和传播。',
  ph_co2_five_title: '温湿度监测',
  ph_co2_five_desc: '大量程，多场景专业应用。',
  ph_co2_six_title: '高精度传感器',
  ph_co2_six_table_title: '二氧化碳传感器',
  ph_co2_six_table_subtitle: '采用非色散红外（NDIR）光谱分析原理',
  ph_co2_six_table_title_2: '高精度温湿度传感器',
  ph_co2_range: '量程',
  ph_co2_precision: '典型精确度',
  ph_co2_precision_value: '测量值 ±15%',
  ph_co2_temp_value2: '±0.5℃',
  ph_co2_hum_value2: '±5%RH',
  ph_co2_temp_value2_2: '@0 ~ 50℃',
  ph_co2_hum_value2_2: '@10% ~ 90%RH',
  ph_co2_seven_title: '大容量可充电锂电池，USB-C 接口',
  ph_co2_seven_desc: '支持长期连接 USB 电源。',
  ph_co2_eight_interval: 'CO₂ 检测间隔',
  ph_co2_nine_title: '长距离无线通信方案，三选一',
  ph_co2_nine_desc: ' ',
  ph_co2_nine_lora: '需配备 LoRaWAN 网关或基站。',
  ph_co2_nine_nb: '需当地中国移动提供相关网络服务*。',
  ph_co2_nine_memo:
    '* 设备出厂内置 SIM 卡，含 6 年中国移动 NB-IoT 流量资费（限 50 MB/年）。不同批次所包含的资费可能不同。',
  ph_co2_eleven_title: '读数上报、记录间隔，可自由设置',
  ph_co2_eleven_desc: '自定义记录间隔',
  ph_co2_eleven_memo:
    '读数上报间隔： 最短 10 分钟、最长 24 小时；{br} 读数记录间隔：最短 1 分钟、最长 60 分钟。',
  ph_co2_twelve_title: '警报功能，多重安全保障',
  ph_co2_twelve_desc:
    '当 CO₂ 、温湿度读数达到设置的阈值，或设备离线、电池电量过低时，发送警报通知。',
  ph_co2_twelve_icon1: '蜂鸣警报*',
  ph_co2_twelve_icon1_desc:
    '* 产品内置蜂鸣器，您可通过青萍物联设置读数超限时发出蜂鸣警报。',
  ph_co2_twelve_icon2: 'App 推送',
  ph_co2_twelve_icon3: '电话',
  ph_co2_twelve_icon4: '短信',
  ph_co2_twelve_icon5: '邮件',
  ph_co2_thirteen_title: '意外断网，数据不丢失',
  ph_co2_thirteen_subtitle:
    '设备自身可存储 2,880 组* 数据，意外断网也无需担心数据丢失，网络恢复后可自动同步数据至云端。',
  ph_co2_thirteen_desc:
    '* 按 15 分钟记录一组数据计算，设备可在本地存储 30 天的数据。',
  ph_co2_fourteen_title: '完善的第三方开发文档和 API，支持二次开发',
  ph_co2_fifteen_title: '方便且美观的悬挂方案',

  //pheasant-co2 mobile
  ph_co2_first_desc_m_1: '高精度传感器 · 无线连接',
  ph_co2_first_desc_m_2: '远程监测 · 超限及时通知',
  ph_co2_fourteen_title_m: '完善的第三方开发文档和 API{br}支持二次开发',

  //Gecko
  gecko_title: '青萍墙壁插座式温度计',
  gecko_name: '青萍墙壁插座式温度计',
  gecko_desc: '墙壁接线盒安装 · NB-IoT 联网 · 精准测量温度',
  gecko_desc_m1: '墙壁接线盒安装 · NB-IoT 联网',
  gecko_desc_m2: '精准测量温度',
  gecko_desc_m3: '',
  gecko_second_title: '多场景专业应用',
  gecko_second_desc: '可持续监测、采集和上报环境温度，应用场景广泛。',
  gecko_second_house: '住宅',
  gecko_second_mall: '商场',
  gecko_second_hotel: '酒店',
  gecko_second_hospital: '医院',
  gecko_second_warehouse: '仓库',
  gecko_second_factory: '工厂',
  gecko_third_title: '安装便捷，稳定可靠',
  gecko_third_desc:
    '墙壁接线盒安装，可直接替代已有 86 盒{br}型墙壁插座，不影响室内人员日常使用电源。位置固定，不用担心被移动。',
  gecko_fourth_title: '市电供电，无需电池',
  gecko_fourth_desc: '通过墙壁接线盒直接取电，不用操心更换电池问题。',
  gecko_fifth_title: '双传感器设计，真实反应环境温度',
  gecko_fifth_desc:
    '内置两枚瑞士 Sensirion 高精度温度传感器，通过算法处理，可有效减少负载引起的产品内部发热对环境温度检测准确性的影响。',

  gecko_table_title: '青萍墙壁插座式温度计',
  gecko_table_a: '典型精确度  ',
  gecko_table_b: '有负载 ±0.5℃ {br}无负载 ±0.3℃ {br}（@0 ~ 50°C）',
  gecko_table_c: '分辨力',
  gecko_table_d: '0.1℃',
  gecko_table_e: '量程',
  gecko_table_f: '-20 ~ 50℃ ',
  gecko_sixth_title: 'NB-IoT 联网，无需配置 Wi-Fi',
  gecko_sixth_desc: '内置中国移动 NB-IoT SIM 卡{br}，信号稳定、低功耗。',
  gecko_seventh_title: '远程监控，高效管理',
  gecko_seventh_desc: '通过“青萍物联”，可进行设备管理、数据收集、数据查询。',
  gecko_seventh_app_a: '可通过 “青萍物联” ',
  gecko_seventh_app_b: 'app',
  gecko_seventh_app_c: ' 或 ',
  gecko_seventh_app_d: '网站',
  gecko_seventh_app_e: '远程监控',
  gecko_eighth_title: '警报功能，多重安全保障',
  gecko_eighth_desc: '当温度读数达到设置的阈值或设备离线时，发送警报通知。',
  gecko_eighth_t1: 'App 推送',
  gecko_eighth_t2: '电话',
  gecko_eighth_t3: '短信',
  gecko_eighth_t4: '邮件',
  gecko_nineth_title: '读数上报、记录间隔，可自由设置',
  gecko_nineth_desc:
    '读数上报间隔：最短 1 小时，最长 24 小时。{br}读数记录间隔：最短 1 分钟，最长 60 分钟。',
  gecko_tenth_title: '数据曲线，清晰可见',
  gecko_tenth_desc: '可查看 24 小时、30 天历史数据曲线，时间范围可选。',
  gecko_eleventh_title: '可导出历史数据文档',
  gecko_eleventh_desc: '通过电子邮件，发送 Excel 文件。',
  gecko_twelfth_title: '意外断网，数据不丢失',
  gecko_twelfth_desc:
    '设备自身可储存 2,880 组{br}数据，意外断网也无需担心数据丢失，网络恢复后可自动同步数据至云端。',
  gecko_thirtyth_title: '完善的开发者文档和 API，支持二次开发',
  gecko_thirtyth_desc: '青萍开发者平台：{link}',
  gecko_fourteenth_title: '双插座，五孔大间距',
  gecko_fourteenth_desc:
    '两孔和三孔插座之间的距离为 21 毫米，满足不同尺寸插头使用。',
  gecko_fifteenth_title: '阻燃 PC 材料，安全耐用',
  gecko_fifteenth_desc: '插座材料具有出色的阻燃性、绝缘性，不易变形、褪色。',

  gecko_size: '86 × 86 × 38.5 毫米（墙外 11.5 毫米）',
  gecko_color: '白色',
  g_distanse: '安装孔距',
  gecko_distanse: '64 毫米',
  gecko_temp_range: '-20 ~ 50℃',
  gecko_invironment:
    ' 本产品无防水功能。请勿长时间在湿度超过 90%RH 的环境下使用。',
  gecko_model: 'CGGK1',
  gecko_weight: '126.8 克',
  gecke_screen_type: '屏幕规格',
  gecko_screen_size: '段码 LCD 屏，30 × 19 毫米',
  gecko_rated_input: '额定电压',
  gecko_rated_value: '250V 交流电',
  gecko_i_input: '额定电流',
  gecko_i_value: '最大 10A',
  gecko_network_type: '中国移动 NB-IoT',
  gecko_material: '材质',
  gecko_material_type: '阻燃 PC',

  gecko_explain_01: '86 盒是一种接线盒规格，尺寸为 86 × 86 毫米。',
  gecko_explain_02:
    '环境温度为 0 ~ 50°C 时，在有负载情况下，传感器测量精确度为 ±0.8°C；在无负载情况下，传感器测量精确度为 +0.5°C。',
  gecko_explain_03:
    '需当地中国移动提供相关网络服务，应用环境信号覆盖良好。包含 6 年流量费（50MB/年）。不同批次附赠的流量可能不同。',
  gecko_explain_04:
    '按 15 分钟采集一组数据计算，设备可在本地储存 30 天的数据。',

  // snow2
  sw2: '青萍空气检测仪 2',
  sw2_first_title: '青萍空气检测仪 2',
  sw2_first_desc_1: '7 项读数 · 可更换 PM 传感器 · 智能联动 · 超清触摸屏 · Wi-Fi 连接',
  sw2_first_desc_mob: '7 项读数 · 可更换 PM 传感器 {br} 智能联动 · 超清触摸屏 · Wi-Fi 连接',
  sw2_second_title: '检测更全面',
  sw2_second_desc: '精准检测 7 项数据',
  sw2_second_temp: '温湿度',
  sw2_second_co2: 'CO2',
  sw2_second_pm25: 'PM2.5 {br}和 PM10',
  sw2_second_tvoc: 'eTVOC{sup}',
  sw2_second_noise: '噪音',
  sw2_second_memo1:
    '温湿度不仅事关舒适性，也和健康息息相关。处于温湿度不佳的环境，可能引发睡眠问题，甚至过敏反应或呼吸道疾病等。',
  sw2_second_memo2:
    '室内 CO2 浓度过高，容易使人困倦，也意味着通风不良、空气中其他污染物的积累。',
  sw2_second_memo3:
    '粒径小、活性强，易附着有害物质，被人吸入后，可能对呼吸系统和心血管系统产生不良影响。',
  sw2_second_memo4:
    '包含上千种化合物，其中部分物质可能有刺激性气味、部分物质可能对⼈体健康有害。',
  sw2_second_memo5:
    '噪音水平对人的睡眠质量和身体健康有影响。持续的噪音可能导致失眠、焦虑和压力增加。长时间暴露在噪音过高的环境会引起听力问题，甚至耳聋。',
  sw2_third_title: '关注室内环境，适时采取改善措施',
  sw2_third_desc_1:
    '根据 CO2 和 eTVOC 浓度适时开窗或打开新风机，提升学习和工作效率。',
  sw2_third_desc_2: '根据 PM 浓度智能联动空气净化设备，减少颗粒物污染。',
  sw2_third_desc_3:
    '根据温湿度联动空调、暖气、加湿器、除湿机等设备，营造健康舒适环境。',
  sw2_fourth_title: '监测数据，一目了然',
  sw2_fourth_desc_1: '实时数据查看',
  sw2_fourth_desc_1_memo: '7 项读数每秒更新。',
  sw2_fourth_desc_2: '颜色分级指示',
  sw2_fourth_desc_2_memo: '通过屏幕上的颜色分级指示，快速了解室内环境情况。',
  sw2_fourth_desc_3: '历史数据图表',
  sw2_fourth_desc_3_memo: '点击屏幕可查看 24 小时、30 天的历史数据。',
  sw2_fourth_desc_4: '室外空气情况',
  sw2_fourth_desc_4_memo:
    '天气预报 · 室外空气质量（AQI）· 紫外线指数 · 限行车牌号',
  sw2_fifth_title: '铝镁合金机身，质感超群',
  sw2_fifth_desc: '黑色 {spot} 白色',
  sw2_sixth_title: '4 英寸“视网膜级”超清 IPS 屏',
  sw2_sixth_desc:
    '细腻度媲美高端手机，可视角度大，对比度高。18° 仰角设计，查看、操作更方便。',
  sw2_sixth_spec_1: '254 ppi {spot} 电容式触摸屏 {br} 亮度自动调节',
  // sw2_sixth_spec_2: '电容式触摸屏',
  // sw2_sixth_spec_3: '亮度自动调节',
  sw2_seventh_title: '手机式触摸操作',
  sw2_seventh_desc: '支持点触和滑动，操作很直观。',
  sw2_eight_title: '时钟 · 闹钟',
  sw2_eight_desc_1: '自动对时',
  sw2_eight_desc_2: '多组闹钟',
  sw2_eight_desc_3: '稍后提醒',
  sw2_eight_desc_4: '自定义铃声',
  sw2_ninth_title: '监测精准灵敏',
  sw2_ninth_desc: '响应快，测量准，长期使用不漂移。',
  sw2_ninth_memo1: '四核 A53 处理器',
  sw2_ninth_memo2: '多模式补偿算法',
  sw2_ninth_memo3: '多种高精度传感器',
  sw2_tenth_title: '弹出式 PM 传感器，可轻松更换',
  sw2_tenth_desc:
    '采用高品质光学器件，搭配磁悬浮风扇，准确性、可靠性俱佳。PM 传感器与机身通过触点连接，后盖与机身通过磁吸固定{sup}，便于更换。',
  sw2_eleventh_title: '创新的通风孔设计{comma}{br}传感器工作更高效',
  sw2_eleventh_desc: '多面开孔 {spot} 风道更科学 {spot} 测量更准确 {br} 工作更安静',
  sw2_eleventh_memo: '让多个传感器充分接触空气，{br}日常使用中不易被遮挡。',
  sw2_twelveth_title: '联动米家{sup}智能设备',
  sw2_twelveth_desc:
    '远程查看数据，设置与米家智能设备的联动。如 PM2.5 超过 50，{br}打开空气净化器；湿度低于 30%，打开加湿器。',
  sw2_thirteenth_title: '支持导出历史数据',
  sw2_thirteenth_desc:
    '通过青萍+ app 可随时随地查看各项读数、行动建议。{br}支持导出历史数据的 Excel 文件。',
  sw2_fourteenth_title: 'USB-C 口，可充电锂电池',
  sw2_fourteenth_desc:
    '支持长期连接 USB 电源。内置 1800mAh 锂电池，续航时间达 4 小时。',
  sw2_fifteenth_title: '功能可不断升级演进',
  sw2_fifteenth_desc:
    '和智能手机一样，可通过 OTA 升级来解决 bug、{br}优化功能，甚至增加新功能。',
  sw2_sixteenth_memo_1:
    '本产品采用的 TVOC 传感器会根据过去一段时间的读数水平自动调整测量基线，故本产品显示的是 eTVOC （即“TVOC 等效值”），并非准确的 TVOC 绝对读数，仅供用户观察环境 TVOC 浓度的相对变化趋势。',
  sw2_sixteenth_memo_2:
    '天气预报、室外空气质量（AQI）、紫外线指数、限行车牌号等信息仅限部分城市提供。',
  sw2_sixteenth_memo_3: 'Wi-Fi 连接，自动同步网络时间。',
  sw2_sixteenth_memo_4:
    '可设置多组闹钟，应对日常事项提醒。每组闹钟可单独设置稍后提醒功能和重复规则。',
  sw2_sixteenth_memo_5:
    '闹铃响起后，点击屏幕上的“稍后提醒”或点击屏幕顶部按键，进入稍后提醒模式，10 分钟后将再次响起；点击屏幕上的“停止”即可取消。',
  sw2_sixteenth_memo_6:
    '青萍空气检测仪 2 自带 8 种铃声。通过 ',
  sw2_sixteenth_memo_6_1: '青萍+ app ',
  sw2_sixteenth_memo_6_2: '可上传其他铃声（此功能暂未上线）。',
  sw2_sixteenth_memo_7: '青萍空气检测仪 2 内置磁铁，后盖内置铁片。',
  sw2_sixteenth_memo_8: '本产品支持米家的中国大陆服务器。',

  sw2_pm25_range: '0 ~ 999 μg/m³',
  sw2_temp_range: '-10 ~ 60℃',
  sw2_hum_range: '0 ~ 99%RH（无凝露环境）',
  sw2_tvoc_range: '0.005 ~ 9.999 mg/m³',
  sw2_co2_range: '400 ~ 9999 ppm',
  sw2_etvoc_range: 'VOC 指数 0 ～ 500，或 0.005 ～ 9.999 mg/m³',
  sw2_noise_range: '36 ~ 95 dB',
  sw2_size: '尺寸',
  sw2_color: '白色、黑色',
  sw2_size_value_long: '85 毫米',
  sw2_size_value_short: '104 毫米',
  sw2_size_value_height: '74 毫米',
  sw2_size_value: '74 × 85 × 104 毫米',
  sw2_weight: '重量',
  sw2_weight_value: '250 克',
  sw2_screen_size_value: '4 英寸',
  sw2_screen_resolution: '屏幕分辨率',
  sw2_screen_resolution_value: '720 × 720',
  sw2_wifi_value: 'IEEE 802.11a/b/g/n/ac/ax',
  sw2_battery_type_value: '锂离子电池',
  sw2_battery_size: '电池容量',
  sw2_battery_size_value: '1800mAh/3.7V',
  sw2_usb_port: '电源接口',
  sw2_usb_port_value: 'USB-C',
  sw2_rated_input: '额定输入',
  sw2_rated_input_value: '5V⎓1A',
  sw2_usb_charge: 'USB-C 充电线',


  //snow2
  snow2_exterior: '外观',
  snow2_range: '量程',
  snow2_spec: '规格',
  snow2: '青萍空气检测仪 2',
  temp_range_snow2: '-10 ~ 60°C',
  hum_range_snow2: '0 ~ 99%RH（无凝露环境）',
  co2_range_snow2: '400 ~ 9999 ppm',
  pm25_pm10: 'PM2.5/PM10',
  pm25_pm10_range_snow2: '0 ~ 999 μg/m³',
  etvoc: 'eTVOC',
  etvoc_range_snow2: 'VOC 指数 0 ~ 500，或 0.005 ~ 9.999 mg/m³',
  noise: '噪音',
  noise_range_snow2: '36 ~ 95 dB',


}

export default {
  ...zhCN,
  ...supportMi
}
