import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import s from './Gparam.module.scss'

class Gparam extends React.PureComponent {
  render() {
    const { locale } = this.props
    return (
      <div className={s.box}>
        <div className={s.Gparam}>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="exterior" />
            </div>
            <div className={s.cell}>
              <div className={s.size}>
                <div className={s.item}>
                  <div className={s.size_front}></div>
                  <div className={s.num}>
                    <FormattedMessage id="p_size_long" />
                  </div>
                </div>
                <div style={{ width: 75, height: 10 }}></div>
                <div className={s.item}>
                  <div className={s.size_side}></div>
                  <div className={s.num}>
                    <FormattedMessage id="p_size_short" />
                  </div>
                </div>
              </div>
              {/* <div className={s.sizeImg} /> */}
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_size" />
                  </span>
                  <FormattedMessage id="p_size_value" />
                </li>
                <li style={{ marginBottom: 0 }}>
                  <span className={s.type}>
                    <FormattedMessage id="p_color" />
                  </span>
                  <FormattedMessage id="p_color_value" />
                </li>
              </ul>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="range" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_temp" />
                  </span>
                  <FormattedMessage id="p_temp_value" />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_hum" />
                  </span>
                  <FormattedMessage id="p_hum_value" />
                </li>
              </ul>
              <p>
                <FormattedMessage id="under_ninty" />
              </p>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="p_specifications" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="screen_size" />
                  </span>
                  <FormattedMessage id="screen_size_value" />
                </li>
                <li style={{ marginBottom: 0 }}>
                  <span className={s.type}>
                    <FormattedMessage id="p_battery" />
                  </span>
                  <FormattedMessage id="p_battery_value" />
                </li>
              </ul>
            </div>
          </div>
          <div className={s.pack}>
            <div className={s.name}>
              <FormattedMessage id="p_pack" />
            </div>
            <div className={s.cell}>
              <div className={s.item}>
                <div className={s.pack_1}></div>
                <div className={s.desc}>
                  <FormattedMessage id="temp_rh" />
                </div>
              </div>
              <div className={s.item}>
                <div className={s.pack_2}></div>
                <div className={s.desc}>
                  <FormattedMessage id="wall" />
                </div>
              </div>
              <div className={s.item}>
                <div className={s.pack_3}></div>
                <div className={s.desc}>
                  <FormattedMessage id="p_cr" />
                  <br />
                  <FormattedMessage id="p_battery_1" />
                </div>
              </div>
              <div className={s.item}>
                <div className={s.pack_4}></div>
                <div className={s.desc}>
                  <FormattedMessage id="p_instructions" />
                </div>
              </div>
              {/* <div className={s.packImg} /> */}
            </div>
          </div>

          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="t_version" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_name" />
                  </span>
                  <FormattedMessage id="temp_rh_t_full" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_model" />
                  </span>
                  <FormattedMessage id="p_model_value_t" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_weight" />
                  </span>
                  <FormattedMessage id="p_weight_value_t" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_wireless" />
                  </span>
                  <FormattedMessage id="p_wireless_value_t" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_support_platform" />
                  </span>
                  <div className={s.homekit}></div>
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="support_operation" />
                  </span>
                  <FormattedMessage id="iOS" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="support_app" />
                  </span>
                  <div className={s.support}>
                    <div className={s.item}>
                      <div className={s.home_logo}></div>
                      <div className={s.logo_title}>
                        <FormattedMessage id="family" />
                      </div>
                    </div>
                    <Link
                      to="/plus"
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      <div className={s.item}>
                        <div className={s.plus_logo}></div>
                        <div className={s.logo_title}>
                          <FormattedMessage id="cg_app" />
                        </div>
                      </div>
                    </Link>
                  </div>
                </li>
                <li style={{ marginBottom: 0, alignItems: 'flex-start' }}>
                  <span className={s.type_new}>
                    <FormattedMessage id="remote_access" values={{ br: '' }} />
                  </span>
                  <span style={{ width: 470 }}>
                    <FormattedMessage
                      id="need_homepod_tv"
                      values={{ br: '' }}
                    />
                    <br />
                    <span style={{ marginTop: 8, display: 'block' }}>
                      <FormattedMessage
                        id="need_thread"
                        values={{ br: <br /> }}
                      />
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="h_version" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_name" />
                  </span>
                  <FormattedMessage id="temp_rh_h_full" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_model" />
                  </span>
                  <FormattedMessage id="p_model_value_h" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_weight" />
                  </span>
                  <FormattedMessage id="p_weight_value" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_wireless" />
                  </span>
                  <FormattedMessage id="only_bluetooth" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_support_platform" />
                  </span>
                  <div className={s.homekit}></div>
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="support_operation" />
                  </span>
                  <FormattedMessage id="iOS" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="support_app" />
                  </span>
                  <div className={s.support}>
                    <div className={s.item}>
                      <div className={s.home_logo}></div>
                      <div className={s.logo_title}>
                        <FormattedMessage id="family" />
                      </div>
                    </div>
                    <Link
                      to="/plus"
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      <div className={s.item}>
                        <div className={s.plus_logo}></div>
                        <div className={s.logo_title}>
                          <FormattedMessage id="cg_app" />
                        </div>
                      </div>
                    </Link>
                  </div>
                </li>
                <li style={{ marginBottom: 0 }}>
                  <span className={s.type_new}>
                    <FormattedMessage id="remote_h" />
                  </span>
                  <FormattedMessage id="need_homepod_tv" values={{ br: '' }} />
                </li>
              </ul>
            </div>
          </div>

          <div className={s.row}>
            <div className={s.name} style={{ borderBottom: 'none' }}>
              <FormattedMessage id="m_version" />
            </div>
            <div className={s.cell} style={{ borderBottom: 'none' }}>
              <ul>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_name" />
                  </span>
                  <FormattedMessage id="temp_rh_m_full" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_model" />
                  </span>
                  <FormattedMessage id="p_model_value_m" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_weight" />
                  </span>
                  <FormattedMessage id="p_weight_value" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_wireless" />
                  </span>
                  <FormattedMessage id="only_bluetooth" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="p_support_platform" />
                  </span>
                  <div
                    className={
                      locale.indexOf('US') > -1 ? s.mihome_en : s.mihome
                    }
                  ></div>
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="support_operation" />
                  </span>
                  <FormattedMessage id="iOS_Android" />
                </li>
                <li>
                  <span className={s.type_new}>
                    <FormattedMessage id="support_app" />
                  </span>
                  <div className={s.support}>
                    <div className={s.item}>
                      <div className={s.mi_logo}></div>
                      <div className={s.logo_title}>
                        <FormattedMessage id="mi_home" />
                      </div>
                    </div>
                    <Link
                      to="/plus"
                      style={{
                        color: '#000',
                        textDecoration: 'none',
                      }}
                    >
                      <div className={s.item}>
                        <div className={s.plus_logo}></div>
                        <div className={s.logo_title}>
                          <FormattedMessage id="cg_app" />
                        </div>
                      </div>
                    </Link>
                  </div>
                </li>
                <li style={{ alignItems: 'flex-start', marginBottom: 0 }}>
                  <span className={s.type_new}>
                    <FormattedMessage id="remote_access" values={{ br: '' }} />
                  </span>
                  <span>
                    <FormattedMessage
                      id="mi_app_need_1"
                      values={{ br: <br /> }}
                    />
                    <Link
                      to="/bluetooth-gateway"
                      style={{ color: '#000', textDecoration: 'underline' }}
                    >
                      <FormattedMessage id="sparrow" />
                    </Link>
                    <FormattedMessage id="end" />
                    <br />
                    <span style={{ marginTop: 8, display: 'block' }}>
                      <FormattedMessage id="mi_app_need_2" />
                      <Link
                        to="/plus"
                        style={{ color: '#000', textDecoration: 'underline' }}
                      >
                        <FormattedMessage id="cg_app" />
                      </Link>
                      <FormattedMessage
                        id="mi_app_need_3"
                        values={{ br: <br /> }}
                      />
                      <Link
                        to="/bluetooth-gateway"
                        style={{ color: '#000', textDecoration: 'underline' }}
                      >
                        <FormattedMessage id="sparrow" />
                      </Link>
                      <FormattedMessage id="end" />
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let GParam = connect(mapStateToProps)(Gparam)

export default GParam
