import supportMi from "./zh-TW/support-mi"
const zhTW = {
  qingping: '青萍',
  temp_rh: '溫濕度計',
  temp_rh_press: '溫濕度氣壓計',
  clock: '時鐘',
  air_name: '空氣檢測儀',
  gateway: '網關',
  cg_app: '青萍+',
  qing_ping_iot: '青萍物聯',
  qing_ping_iot_m: '青萍物聯 App',
  developer: '開發者平台',
  index_developer: '開發者平台',
  store: '商店',
  download: '下載',

  magpie: '李吉他藍牙溫濕度計',
  magpie_simple: '李吉他溫濕度',
  magpie_enter: '李吉他{br}藍牙溫濕度計',
  grasp_guitar: '巧妙吸附在吉他上',
  cute_smart: '可愛又好用',
  mp_battery_value: 'CR2032 紐扣電池',

  cg_magpie: '李吉他溫濕度',
  magpie_prd: '配合李吉他藍芽溫濕度計使用',

  more: '了解更多',

  goose: '青萍藍牙溫濕度計',
  goose_lite: '青萍溫濕度計',
  h_version: 'H 版',
  m_version: 'M 版',
  t_version: 'T 版',
  t_version_new: 'T 版',

  pheasant: '青萍商用溫濕度氣壓計',
  pheasant_s: '青萍商用溫濕度氣壓計 S',
  s_first_title: '呼吸，事關重大',

  goose_screen: '電子墨水屏',
  support: '桌面支架',
  wall: '牆貼',
  user: '機身存儲30天歷史數據',

  duck2_support: '支持小米米家',
  hodor_monitor: '監控門窗開合動作',

  frog_support_1: 'IP55 級防護',
  frog_support_2: '超長待機',
  frog_support_3: '可選外置探頭',
  frog_support_3_1: '外置探頭',
  frog_support_4: 'Wi-Fi/NB-IoT/LoRa',

  l_support: '支持 Wi-Fi 或 NB-IoT 或 LoRa',
  l_battery: '可充電鋰電池',
  l_user: '超長待機',

  dove: '青萍藍牙鬧鐘',
  button_less: '「無按鍵」設計',
  temp_rh_mon: '溫濕度監測',
  soft_backlight: '柔和背光',
  sixteen_ala: '16組鬧鐘',

  snow: '青萍空氣檢測儀',
  // snow2: '青萍空氣檢測儀 2',
  pm25: 'PM2.5',
  pm10: 'PM10',
  co2: 'CO₂',
  tvoc_s: 'tVOC',
  temp: '溫度',
  temp_m: '温度',
  temperature: '溫度',
  hum: '濕度',
  // etvoc: 'eTVOC',
  // noise: '噪音',
  snow_screen: '超清觸控螢幕',
  shell: '鋁合金外殼',
  snow2_sensor: '可更換 PM 傳感器',

  dany: '青萍空氣檢測儀 Lite',
  touchbar: '觸摸條',
  support_all: '支持 Apple Home{br} 和小米米家',
  support_all_pc: '支持 Apple Home{br} 和小米米家',

  sparrow: '青萍藍牙網關',
  connect_mi: '讓米家藍牙網關子設備自動聯網',
  enable_remote: '遠程查看數據',
  automation: '智能聯動',

  grasp_guitar_sip: '巧妙磁吸',
  e_link: '電子墨水屏',
  record_history_data: '自動記錄歷史數據',

  pheasant_co2_name: '青萍二氧化碳和溫濕度檢測儀',
  pheasant_co2_index_desc: '無線通訊，超長待機',

  pheasant_co2_title: '青萍二氧化碳和溫濕度檢測儀',
  pheasant_co2_des1: '高精度感測器 • 超長待機',
  pheasant_co2_des2: 'Wi-Fi/NB-loT/LoRa',

  chicken_title: '青萍藍牙小鐘',
  chicken_des1: '可愛小鬧鐘 • 溫濕度感測器',
  chicken_des2: '半透明荧幕',

  parrot_title: '青萍動作和環境光感測器',
  parrot_des1: '靈敏識人 • 精准感知光線變化',
  parrot_des2: '動作和照度結合聯動',

  device_management: '青萍設備管理',
  data_insights: '讀數解讀',
  historical_data: '歷史數據',
  access_remote: '遠程訪問',
  air_quality: '城市空氣品質',
  forecast: '天氣預報',

  team_management: '企業級設備管理',
  data_collection: '數據收集',
  device_sharing: '設備共享',
  alarm_notification: '實時報警服務',

  customize_device: '深度定製青萍產品',
  own_platform: '讓青萍硬體完美接入您的平台',
  own_platform_m_1: '讓青萍',
  own_platform_m_2: '硬體完美接入您的平台',

  cooperate: '商務合作',
  serve: '售後客服',
  company_name: '© 青萍',

  about_us: '關於我們',
  privacy: '隱私政策',
  contact_us: '聯系我們',

  specifications: '規格',
  buy: '購買',

  home_text: '冷暖乾濕，一覽分明',
  sensor: '瑞士傳感器',
  wall_model: '牆面模式',
  thirdth_title: '桌面模式',
  temp_rh_t: '青萍溫濕度計 T 版',
  temp_rh_h: '青萍藍牙溫濕度計 H 版',
  temp_rh_m: '青萍藍牙溫濕度計 M 版',
  temp_rh_t_full: '青萍溫濕度計 T 版',
  temp_rh_h_full: '青萍藍牙溫濕度計 H 版',
  temp_rh_m_full: '青萍藍牙溫濕度計 M 版',
  comfort_zone: '體感舒適區',
  goose_desc:
    '冬季，溫度在20℃~24℃、相對濕度在30%~60%時，人體感覺較為舒適；在夏季，溫度在23℃~26℃、相對濕度在25%~60%時，人體感覺較為舒適。',
  goose_desc_m:
    '冬季，溫度在20℃~24℃、相對濕度在30%~60%{br}時，人體感覺較為舒適；在夏季，溫度在23℃~26{br}℃、相對濕度在25%~60%時，人體感覺較為舒適。',
  thirdth_desc: '打開內置支架，就能穩穩地放在桌面上。',
  fourth_title: '牆面模式',
  fourth_desc: '上牆共分3步：',
  fourth_desc_1: '將牆貼粘在牆上；',
  fourth_desc_2: '將青萍溫濕度計吸附在牆貼上；',
  fourth_desc_3: '放歪也不用擔心，可隨意旋轉調整。',
  fourth_desc_4: '* 青萍溫濕度計內置磁鐵，牆貼內置鐵片。',
  fifth_title: '電子墨水屏，白「紙」黑字，清清楚楚',
  sixth_title: '超寬可視角度，幾近180°',
  sixth_desc: '更易看清房間溫濕度，哪怕角度刁鑽。',
  seventh_title: '瑞士 Sensirion 高精度工業級傳感器',
  seventh_title_1: '瑞士 Sensirion 高精度',
  seventh_title_2: '工業級傳感器',
  seventh_desc: '響應快{spot}測量準 {spot}不漂移',
  eighth_title: '1 顆紐扣電池，續航 8 ~ 12 個月',
  eighth_title_m: '1 顆紐扣電池{br}續航 8 ~ 12 個月',
  eighth_desc:
    '* 本產品採用 CR2430 電池，在常見室溫下可使用 8 ~ 12 個月。數據來自青萍實驗室，實際續航時間可能依環境和電池的差異而有所不同。',
  eighth_desc_1:
    '* 本產品採用 CR2430 電池，在常見室溫下可使用 8 ~ 12 個{br}月。數據來自青萍實驗室，實際續航時間可能依環境和電池的差異{br}而有所不同。',
  h_support: 'T 版和 H 版支持 Apple Home',
  t_support: 'T 版支持 Apple Home',
  check_anytime: '隨時隨地查看家中溫濕度，聯動其他設備',
  check_remote:
    '* 遠程查看和設置自動化需配備家居中樞設備，如 HomePod 或 Apple TV。',
  check_thread:
    '如要使用 Thread 連接，則需配備支持 Thread 的家居中樞設備，例如 HomePod（第二代）、{br}HomePod mini 或 Apple TV 4K（第二代或帶有網線接口的第三代），或第三方 Thread 邊界路由器。',
  check_remote_1:
    '* 遠程查看需配備 HomeKit 中樞{br}設備，如 HomePod 或 Apple TV。',
  check_thread_1:
    '如要使用 Thread 連接，則需配備支持 Thread{br} 的家居中樞設備，例如 HomePod（第二代） HomePod{br} mini 或 Apple TV 4K（第二代或帶有網線接口的第三代），或{br}第三方 Thread 邊界路由器。',
  check_remote_2: '需配置 HomeKit 中樞設備，如 HomePod 或 Apple TV',
  check_remote_3: '需配備 HomePod 或{br} Apple TV。',
  siri_support: '支持 Siri',
  hey_siri: '嘿 Siri, 現在家裡幾度？',
  home: '家庭',
  now_humi: '客廳當前濕度為 53%。',
  now_temp: '客廳现在 24.8˚。',
  detail: '詳細信息',
  common_around: '常用配件',
  meetting_room: '客廳',
  short_desc: '青萍藍牙溫...',
  two_sensor: '2 個感應器',
  m_support: 'M 版支持米家',
  with_gateway_1: '配備',
  with_gateway_2: '米家藍牙網關設備',
  with_gateway_3: '（如',
  with_gateway_4: '青萍藍牙網關',
  with_gateway_5:
    '）後，您可通過米家 app 遠程訪問青萍藍牙溫濕度計 M 版、設置與米家智能設備的聯動、從雲端獲取歷史數據。',
  humidifier: '加濕器',
  air_conditioner: '空調',
  mi_home: '米家',
  qingping_bluetooth_gateway: '青萍藍牙溫濕度計',
  electric_heater: '電暖氣',
  auto_record_thirty: '自動記錄溫濕度歷史數據，{br}長達 30 天',
  nineth_title_1: '自動記錄溫濕度歷史數據',
  nineth_title_2: '長達 30 天',
  need_plus: '* 需連接「青萍+」手機 app。',
  choose_version: '不同版本如何選擇?',
  wireless: '無線通信',
  thread: 'Thread / 藍牙',
  bluetooth: '藍牙',
  support_operation: '支持的作業系统',
  iOS: 'iOS',
  iOS_Android: 'iOS 和 Android',
  support_app: '支持的 App',
  family: '家庭',
  remote_h: '遠程查看',
  remote_access: '遠程查看或{br}智能聯動',
  need_thread:
    '如需使用 Thread 連接，則需配備支持 Thread 的家居中樞設備，{br}例如 HomePod（第二代）、HomePod mini 或 Apple TV 4K（第二代或帶有網線接口的第三代），或第三方 Thread 邊界路由器。',
  need_homepod_tv: '需配備 HomePod 或 Apple TV 作為家居中樞。',
  need_mi_qingping_gateway: '需配備{br}米家藍牙網關設備，如{br}',
  need_qingping_gateway: '需配備{br}',
  need_mi_qingping_gateway_m: '米家 app 需配備米家藍牙網關{br}設備，如',
  need_qingping_gateway_m: ' app 需配備',
  break: '，',
  end: '。',

  p_size_long: '74 毫米',
  p_size_short: '14 毫米',
  exterior: '產品外觀',
  p_size: '尺寸',
  p_size_value: '74 × 74 × 14 毫米',
  p_color: '颜色',
  p_color_value: '白色',
  range: '產品量程',
  p_temp: '溫度量程',
  p_temp_value: '0 ~ 50℃ ',
  p_hum: '濕度量程',
  p_hum_value: '0 ~ 99.9%RH（無凝露環境）',
  under_ninty: '* 请勿長時間在濕度超过 90% 的環境下使用。',
  p_specifications: '產品規格',
  other_specs: '其他規格',
  p_name: '產品名稱',
  p_name_value: '青萍藍牙溫濕度計',
  p_model: '產品型號',
  p_model_value_t: 'CGG1T',
  p_model_value_h: 'CGG1H',
  p_model_value_m: 'CGG1',
  p_support_platform: '支持的平台',
  p_weight: '產品重量',
  p_weight_value: '59 克',
  p_weight_value_t: '54 克',
  p_wireless: '無線通信',
  p_wireless_value_t: '藍牙、Thread',
  screen_size: '屏幕尺寸',
  screen_size_value: '55 x 55 毫米',
  p_wifi: '無線網絡',
  p_wifi_value: '藍牙 5.0',
  p_battery: '電池类型',
  p_battery_value: 'CR2430 紐扣電池',
  p_pack: '包裝清單',
  p_main: '主體',
  p_cr: 'CR2430',
  p_battery_1: '紐扣電池',
  p_instructions: '說明書',
  mi_app_need_1: '米家 app 需配備米家藍牙網關設備，如',
  mi_app_need_2: ' ',
  mi_app_need_3: ' app 需配備',

  ph_first_title: '青萍溫濕度氣壓計',
  ph_first_text:
    'LoRa/NB-IoT/Wi-Fi 長距離無線通信{br}大容量可充電锂電池 · 超低功耗超長待機',
  ph_first_text1: '青萍商用{br}溫濕度氣壓計 S',
  ph_first_text1_m: '青萍商用{br}溫濕度氣壓計 S',
  ph_first_text2: '青萍商用{br}溫濕度計 S',
  ph_first_text2_m: '青萍商用{br}溫濕度計 S',
  ph_first_text3: '多場景專業溫濕度監控方案',
  ph_first_text4: '針對嚴酷和日常環境，各有壹套。',
  ph_first_text5: '溫度量程',
  ph_first_text6: '氣壓量程',
  ph_first_text7: '電池容量',
  ph_first_text8: '-30 ~ 55℃',
  ph_first_text9: '-20 ~ 50℃',
  ph_first_text10: '30~125 kPa',
  ph_first_text11: '不支持',
  ph_first_text12: '2450mAh',
  ph_first_text13: '2600mAh',
  ph_first_text14: '查看完整規格',
  p_second_title: '長距離無線通信，多種可選方案',
  p_second_desc: '可在 3 種方案中選擇一種。',
  p_second_lora: '需配備 LoRaWAN 網關或基站。',
  p_second_nbiot: 'NB-IoT 版包含 6 年運營商資费。{br}（ 限 50MB 流量/年 ）',
  p_second_wifi: '支持定時上報數據、{br}条件触發上報數據，不保持長連接。',
  p_third_title: '多場景專業應用',
  p_third_factory: '工廠',
  p_third_warehouse: '倉庫',
  p_third_market: '商場',
  p_third_supermarket: '超市',
  p_third_hospital: '醫院',
  p_third_pharmacy: '藥店',
  p_third_winecellar: '酒窖',
  p_third_polytunnel: '大棚',
  p_fourth_title: '大容量可充電鋰電池，{br}USB-C 接口',
  p_fourth_text1: '電池待機時間超長，支持長期連接 USB 電源。',
  p_fourth_text2: '電池容量',
  p_fourth_text3: '電源接口',
  p_fourth_text4: '額定輸入',
  p_fourth_text5: 'USB-C',
  p_fourth_text6: '5V = 1A',
  p_fourth_desc_1: '容量高達 3350mAh',
  p_fourth_desc_2: 'USB-C 充電接口',
  p_fourth_desc_3: '支持 USB 電源持續供電',
  p_fifth_title: '超低功耗，超長待機',
  p_fifth_note:
    '* 以上數據基於青萍公司實驗结果。實際使用中，環境溫度和網絡信號強度、上傳频率、上傳數據量都將影響待機時長。',
  p_calculator_title: '待機時間計算器',
  p_calculator_subtitle: '待機有多久，算算更明了。',
  p_network_type: '網絡類型',
  p_network_signal: '網絡信號',
  p_local_interval: '本地記錄時間間隔',
  p_net_interval: '聯網上報時間間隔',
  p_environment: '環境溫度',
  p_around: '預估待機時長',
  p_about: '約',
  p_month: '個月',
  p_day: '天',
  p_tips: '* 預估待機時長可能與實際使用中有所不同。',
  p_minute: '分鐘',
  p_minutes: '分鐘',
  p_hour: '小時',
  p_hours: '小時',
  p_good: '优秀',
  p_normal: '正常',
  p_bad: '較差',

  wulian_desc:
    '青萍物聯是為青萍商用智能硬件產品提供設備管理、數據收集、數據查詢{br}以及實時報警服务的物聯網平台。',
  wulian_desc_m:
    '青萍物聯是為青萍商用智能硬件產品{br}提供設備管理、數據收集、數據查詢{br}以及實時報警服务的物聯網平台。',
  p_seventh_title: '靈活的上報和通知策略，可遠程配置',
  p_seventh_img_1: '自定義上報間隔',
  p_seventh_img_2: '自定義數據粒度',
  p_seventh_img_3: '自定義超限通知',
  p_eighth_title: '完善的第三方開發文檔和 API，支持二次開發',
  p_eighth_email: '開發者平台：{link}',
  p_nineth_title_1: 'Bosch 或 ROHM 氣壓傳感器',
  p_nineth_title_1_1: '* 僅適用於青萍商用溫濕度氣壓計 S。',
  p_nineth_title_1_m: '* 僅適用於青萍商用溫濕度氣壓計 S。',
  p_nineth_title_2: '瑞士 Sensirion 高精度溫濕度傳感器',
  resolution: '分辨力',
  space: ' ',
  p_nineth_temp: '溫度',
  p_nineth_humi: '濕度',
  typical_accuracy: '典型準確度',
  p_tenth_title: '方便、低成本的懸掛方案',
  p_tenth_desc_1: '粘貼式掛鉤',
  p_tenth_desc_2: '背部開孔',
  ph_size_long: '77.2 毫米',
  ph_size_height: '27.8 毫米',
  ph_size_value: '77.2 × 77.2 × 27.8 毫米',
  ph_color_value: '白色',
  ph_pressure: '氣壓量程',
  ph_pressure_value: '30 ~ 125 kPa',
  ph_pressure_value_2: 'N/A',
  ph_temp_value: '-30 ~ 55℃ ',
  ph_temp_value_2: '-20 ~ 50℃ ',
  ph_hum_value: '0 ~ 99.9%RH（無凝露環境）',
  ph_name_value: '青萍商用溫濕度氣壓計 S',
  ph_name_value_2: '青萍商用溫濕度計 S',
  ph_weight_value: '129 克',
  ph_screen_value: '61.21 × 48.72 毫米',
  ph_wifi_value: 'Wi-Fi 或 NB-IoT 或 LoRa',
  ph_bluetooth_value: '藍牙 5.0',
  ph_bluetooth: '藍牙类型',
  ph_battery_value: '锂離子電池',
  ph_battery_size_value: '2450mAh',
  ph_battery_size_value_2: '2600mAh',
  ph_usb_value: 'USB-C',
  ph_input_value: '5V⎓1A',
  ph_sticker: '粘貼式掛鉤',
  ph_product: '產品',

  coming: '即將上市',
  coming_soon: '即將推出',
  mp_package: '產品包裝',
  mp_measure: '精準測溫濕',
  mp_guard: '木質樂器守護者',
  mp_desc_1: '靈敏精準 · 便捷磁吸 · 智能 App',
  mp_desc_1_m: '靈敏精準 · 便捷磁吸 · 智能 App',
  mp_story: '產品故事',
  mp_story_1:
    '李吉他 LEE GUITARS 是李宗盛創立的吉他品牌。作為資深音樂人和吉他製琴师，李宗盛先生深知木吉他日常保管環境溫濕度的重要性。',
  mp_story_2:
    '李吉他藍牙溫濕度計由李宗盛先生主導、青萍藍牙溫濕度計團隊精心設計打造，可巧妙吸附在吉他音孔處，是樂手監測吉他保存環境溫濕度的理想選擇。',
  mp_why: '為什么木質樂器需要溫濕度計？',
  mp_reason:
    '修理面板開裂或變形的吉他或提琴代價昂贵，即使修好，音色也會發生變化。保存木質樂器的適宜溫度為 20 ~ 25°C，{br}濕度為 40% ~ 55%。盡量让木質樂器處於適宜的溫濕度環境，可预防樂器變形、開裂、開膠。',
  mp_reason_m:
    '修理面板開裂或變形的吉他或提琴代價昂贵，即使修好，音色也會發生變化。保存木質樂器的適宜溫度為 20 ~ 25°C，濕度為 40% ~ 55%。盡量让木質樂器處於適宜的溫濕度環境，可预防樂器變形、開裂、開膠。',
  mp_convient: '便捷磁吸',
  mp_goods_1:
    '傳感器端與顯示屏端都內置磁鐵，中間連接部位為 TPU 軟膠材質，可在彎著後夹住吉他面板{br}吸附固定，測量吉他內部溫濕度。',
  mp_screen: '顯示屏端',
  mp_magnet: '內置磁鐵',
  mp_attract: '方便吸附傳感器端',
  mp_button: '按钮',
  mp_switch: '華氏度、攝氏度切換',
  mp_cp: '長按藍牙配對',
  mp_tpu: '軟質 TPU',
  mp_bend: '輕鬆彎著',
  mp_sensor_end: '溫濕度傳感器端',
  mp_built_in: '內置磁鐵',
  mp_cavity: '可深入音腔',
  mp_e_link: '電子墨水屏',
  mp_clear: '白「紙」黑字，清清楚楚',
  mp_goods_2:
    '對環境光線要求很低，無論陽光明媚还是華燈初上，都能為您提供紙張般舒適的查看體驗，{br}視角寬廣、清晰易讀。',
  mp_superview: '超寬可視角度',
  mp_high_contrast: '超高對比度',
  only_bluetooth: '藍牙',
  mp_bluetooth: '內置藍牙',
  only_wifi: 'Wi-Fi',
  mp_wireless: '不僅僅是無線',
  mp_ble:
    '· 藍牙 5.0 傳输{br}· App 查看實時溫濕度、歷史數據{br}· 接收溫濕度超限提醒',
  mp_ble_1: '藍牙 5.0 傳输',
  mp_ble_2: 'App 查看實時溫濕度、歷史數據',
  mp_ble_3: '接收溫濕度超限提醒',
  mp_upload: '* 機身存储的歷史數據為每 15 分鐘一条，會在藍牙連接{br}',
  mp_upload_magpie: '“李吉他溫濕度”',
  mp_upload_or: '或 ',
  mp_upload_plus: '“青萍+”',
  mp_upload_end: 'app 後自動上傳。',
  mp_check: '* 使用手機 app 遠程查看數據或接收提醒，需配備',
  mp_check_bluetooth: '藍牙網關。',
  mp_create_automation:
    '* 通過米家 app 接收溫濕度超限提醒，需先手動創建“智能”。',
  mp_love_music: '熱愛音樂的你',
  mp_guardian: '需要的私人樂器管家',
  mp_suitable:
    '配合藍牙網關使用，可實现溫濕度遠程監測、{br}超限及時提醒等功能。無需打開琴箱，無論身{br}在何處都能及時了解樂器狀態。',
  mp_suitable_m:
    '配合藍牙網關使用，可實现溫濕度遠程監測、超限及時提醒等功能。無需打開琴箱，無論身在何處都能及時了解樂器狀態。',
  mp_purchase: '* 藍牙網關設備需單獨購買。',
  mp_sensor: '瑞士 Sensirion 傳感器',
  mp_sensitive: '精度高，反應靈敏',
  mp_art:
    '李吉他藍牙溫濕度計採用在工業和消费市場廣受{br}讚譽的瑞士 Sensirion 溫濕度傳感器，輔以精妙{br}的結構設計、ART 算法，可靈敏检測吉他音腔內{br}部或樂器存放環境的溫濕度。',
  mp_low_consumption: '超低功耗{br}續航長達 8 個月',
  mp_desc_2:
    '李吉他藍牙溫濕度計採用超低功耗傳感器和芯片，搭配{br} CR2032 型紐扣電池，在常见室溫環境下可持續{br}使用 8 個月。',
  mp_desc_2_m:
    '李吉他藍牙溫濕度計採用超低功耗傳感器和芯片，搭配 CR2032 型紐扣電池，在常见室溫環境下可持續使用 8 個月。',
  mp_desc_3: '* 續航時間會因實際使用環境不同而存在差异，僅供參考。',
  mp_little_thing: '一手掌握的精緻派',
  mp_weight: '重量僅 30 克，可固定在樂器上，或放置於琴盒、琴箱內，便攜輕巧。',
  mp_more: '更多細節',
  mp_thin: '超薄機身',
  mp_design: '圓潤而又層次分明的設計',
  mp_beauty: '美觀大方',
  mp_tpu_2: 'TPU 軟膠材質',
  mp_bend_time: '可彎折 5000 次以上',
  mp_rotary: '旋轉式電池艙蓋',
  mp_change_battery: '換電池更輕鬆',
  mp_size_long: '131毫米',
  mp_size_short: '37毫米',
  mp_size_border: '12.4毫米',
  mp_size: '131 ✕ 37 ✕ 12.4 毫米',
  mp_color: '白色',
  mp_model: 'CGM1',
  mp_real_weight: '30 克',
  mp_screen_size: '直徑 26 毫米',
  mp_screen_size_value: '26 × 26 毫米',
  mp_temp_range_label: '溫度量程',
  mp_humi_range_label: '濕度量程',
  mp_ble_type: '無線連接',

  do_slogan: '活力喚醒每一天',
  do_desc: '「無按鍵」設計 · 溫濕度監測 · 16 組鬧鐘',
  do_snooze: '貪睡功能',
  do_ringtones: '8 種鈴聲',
  do_backlight: '背光可調',
  do_simple: '無按鍵設計，簡約百搭',
  do_desc_2:
    '產品可被整體按下，操作簡便有趣；軟膠底座配合精巧的內部設計，{br}確保按壓手感舒適；簡約外觀，輕鬆融入各種家居風格。',
  do_customize: '定製你的專屬鬧鐘',
  do_desc_3:
    '每次藍牙連接手機 app，都會自動同步時間，輕鬆實現精準對時；可設置 16 組鬧鐘，每組可單獨設置「貪睡」功能；8 款鈴聲可選，愉悅喚醒每個清晨；鬧鈴音量可調。',
  do_sync_time_1: '* 對時和調整設置，需通過',
  do_sync_time_2: '或米家手機 app 進行。',
  do_shot_off: '* 長按產品 6 秒關閉所有鬧鈴，再次長按 6 秒恢復所有鬧鈴。',
  do_alarm: '鬧鐘',
  do_year: '年/月/日',
  do_week: '星期',
  do_temp_humi: '濕度/溫度',
  do_time: '時間',
  do_joy: '簡單, 好玩的交互方式, {br}唤醒美好清晨',
  do_desc_4:
    '鈴聲響起後，輕拍鬧鐘，即可進入貪睡模式，10 分鐘之後將再次響起。用輕緩的方式喚醒清晨，{br}元氣滿滿，告別起床氣。',
  do_one_more: '拍一下，多睡一會（10 分鐘之後再響）',
  do_two_step: '拍兩下，起床了（停止鬧鐘）',
  do_monitor: '實時掌握家中溫濕度',
  do_guard: '守護舒適生活',
  do_sensor_care:
    '採用瑞士 Sensirion 傳感器，靈敏感知溫濕度變化，細心呵護家人健康。',
  do_pat: '輕拍點亮背光，{br}柔光輕伴不摸黑',
  do_press:
    '按壓鬧鐘，背光亮起。慵懒的清晨或深夜，隨手輕拍查看時間，隨時给你安心陪伴。',
  do_adjust: '隨心調節背光',
  do_desc_5:
    '背光強弱分時段可調，例如晚 10 點至早 7 點亮度自動降為 50%；背光時長也可調，從完全不亮到持續點亮 30 秒。{br}夜晚不刺眼，白天看得清。',
  do_available: '* 此功能需通過',
  do_available_2: '或米家手機 app 進行。',
  do_smart: '房間舒適度的智能小管家',
  do_desc_6:
    '聯動其他米家設備，實现室內溫濕度的自動調節。例如：溫度低於 20℃ 時，自動打開電暖器。',
  do_desc_7: '* 需搭配',
  do_desc_7_2: '米家藍牙網關設備',
  do_desc_7_3: '（如',
  do_desc_7_4: '青萍藍牙網關',
  do_desc_7_5: '），通過米家手機 app 創建聯動。',
  do_concentrated: '小小鬧鐘，守護你的專注時光',
  do_desc_8:
    '適時放下手機，阅讀 10 页書、睡前冥想 5分鐘、高效工作 1 小時……從手機中分離出“鬧鐘”功能，{br}守護你的注意力，远離干擾。',
  do_routine: '重複規则自定義工作{br}休息自由切換',
  do_desc_9: '* 使用青萍+',
  do_desc_9_2:
    '或米家手機app，可設置鬧鈴周一至周日哪天重複。單雙休、倒休，都能輕鬆調整。',
  do_desc_10:
    '* 添加鬧鐘、修改鈴聲等操作，手機和青萍藍牙鬧鐘须在藍牙傳输距離範圍內。',
  do_kids: '孩子的起居學習小夥伴',
  do_habit: '養成良好時間管理習慣',
  do_silence: '静静陪伴不打擾',
  do_sleep: '夜晚完全静音，伴你好眠。',
  do_colorful: '多樣色彩',
  do_white: '米色',
  do_green: '綠色',
  do_blue: '蓝色',
  do_more: '更多細節',
  do_lcd_superview: 'LCD 顯示屏，覆偏光膜，{br}更大可視角度，顯示清晰。',
  do_plastic: '啞光塑膠外殼，{br}手感舒適',
  do_tpe: 'TPE 底座，{br}防滑穩固。',
  do_size: '80.3 × 41 × 83 毫米',
  do_size_long: '80.3 毫米',
  do_size_short: '83 毫米',
  do_size_height: '41 毫米',
  do_color: '米色、綠色、蓝色',
  do_temp_range: '-9.9℃ ~ 49.9℃',
  do_model: 'CGD1',
  do_weight: '99 克（不含電池）',
  do_screen_size: '61 × 61 毫米',
  do_battery_type: '2 節 5 號電池',

  sn_serious: '呼吸，事關重大',
  sn_automation: '全面監測室內空氣 · 高精度傳感器 · 超清觸控螢幕 · 智能聯動',
  s_thirdth_title: '對空氣，窮根究底',
  s_thirdth_desc_m:
    '室內環境下，很多因素影響著人们的體感舒適度和健康，除了我們熟知的溫度、濕度、PM2.5 之外，还有 tVOC 和 CO2。',
  s_thirdth_desc_1:
    '室內環境下，很多因素影響著人们的體感舒適度和健康，除了我們',
  s_thirdth_desc_2: '熟知的溫度、濕度、PM2.5 之外，还有 tVOC 和 CO2。',
  s_fourth_title: '小科普：',
  s_fourth_sub_title: 'tVOC 和 CO₂',
  s_fourth_desc_1:
    'tVOC 即總揮發性有機物，包含上千種化合物，其中部分物質可能有刺激性氣味、部分物質可能對⼈體健康有害。簡單地說，當 tVOC 較高時，建議開窗或開啟新風機通風。',
  s_fourth_desc_2:
    'CO₂ 即二氧化碳，是空氣中常見的化合物，在封閉的室內，人的呼吸會導致 CO₂ 濃度不斷上升，{br}當濃度達到一定程度時，人會感到睏倦。開窗通風或打開新風機可快速降低室內 CO₂ 濃度。',
  s_tenth_title: '室外空氣情况{br}也能一目了然',
  s_tenth_desc_1:
    '時間{spot}天氣預報{spot}室外空氣質量（AQI）{br}紫外線指數{spot}限行車牌號',
  s_tenth_desc_1_m:
    '時間{spot}天氣預報{spot}室外空氣質量（AQI）{br}紫外線指數{spot}限行車牌號',
  s_eleventh_title: '雙色設計{br}鋁鎂合金機身',
  s_eleventh_title_m: '雙色設計，鋁鎂合金機身',
  s_eleventh_sub_title: '白色{spot}黑色',
  s_eleventh_desc: '質感超群，百搭各種空間風格。',
  s_seventh_title: '“視網膜级”超清 IPS 屏',
  s_seventh_desc_1:
    '3.1 英寸{spot}PPI 328{spot}電容式觸控螢幕{spot}亮度自動調節',
  s_seventh_desc_1_m:
    '3.1 英寸{spot}PPI 328{spot}電容式觸控螢幕{spot}亮度自動調節',
  s_seventh_desc_2: '細腻度媲美高端手機，可視角度大，對比度高。',
  s_nineth_title: '手機式触摸操作',
  s_nineth_desc: '支持點觸和滑動，操作很直觀。',
  s_fifth_title: '性能超群',
  s_fifth_sub_title: '響應快{spot}測量準{spot}長期使用不漂移',
  s_fifth_icon_text_1: '1.2GHz 四核 A7 處理器',
  s_fifth_icon_text_2: '多模式補償算法',
  s_sixth_title: '創新的通風孔設計，傳感器工作更高效',
  s_sixth_desc_1: '風道更科學{spot}測量更準確{spot}工作更安静',
  s_sixth_desc_2: '充分接觸空氣, 日常使用不易遮擋。',
  s_twelfth_title_1: '支持“米家”',
  s_twelfth_title_2: '智能聯動空氣改善設備',
  s_twelfth_desc:
    '青萍空氣檢測儀是米家空氣改善類設備的上佳拍檔。通過米家 app，可設置青萍空氣檢測儀聯動多種新風機、空氣淨化器、空調、電風扇、加濕器，一旦溫度或濕度、PM2.5、tVOC 或 CO₂ 到達設定值，自動開啟或關閉相關設備。',
  sn_fresh_air: '新風機',
  sn_pulifier: '空氣净化器',
  sn_eighth_title: '支持「青萍+」App',
  s_eighth_desc: '隨時隨地地查看各項讀數、行動建議',
  s_thirteenth_title: '獨立的檢測儀才能客觀評價空氣情況',
  s_thirteenth_desc_1:
    '空調、空氣淨化器、新風機等設備自帶的傳感器只能檢測機身周圍小範圍內的情況，不盡客觀。',
  s_thirteenth_desc_2:
    '獨立的空氣檢測儀可放在您更關心的位置，給出更客觀的檢測結果。',
  s_fourteenth_title: '基於 Linux 開發，功能可不斷升級演進',
  s_fourteenth_desc:
    '和智能手機一樣，可通過 OTA 升级来解决 bug、优化功能，甚至增加新功能。',
  pm25_range: '0 ~ 999 μg/m³',
  temp_range: '-10 ~ 50℃',
  hum_range: '0 ~ 100%RH（無凝露環境）',
  tvoc_range: '0.005 ~ 9.999 mg/m³',
  co2_range: '400 ~ 9999 ppm',
  s_size: '尺寸',
  s_color: '白色、黑色',
  s_size_value_long: '68.4 毫米',
  s_size_value_short: '86.3 毫米',
  s_size_value_height: '85 毫米',
  s_size_value: '85 × 68.4 × 86.3 毫米',
  s_weight: '重量',
  s_weight_value: '217 克',
  screen_resolution: '屏幕分辨率',
  screen_resolution_value: '720 × 720',
  s_battery_size: '電池容量',
  s_battery_size_value: '1800mAh/3.7V',
  usb_port: '電源接口',
  usb_port_value: 'USB-C',
  rated_input: '额定输入',
  rated_input_value: '5V⎓1A',
  s_usb_charge: 'USB 充電線',

  sp_pure: '一枚純粹的米家藍牙網關',
  sp_automation: '让米家藍牙網關子設備自動聯網{br} · 遠程查看數據 · 智能聯動',
  sp_wifi: '讓你的智能門鎖 「連上」 Wi-Fi',
  sp_remote_1:
    '配備青萍藍牙網關後，您可通過米家 app 遠程接收智能門鎖的開{br}鎖通知、查看開鎖記錄等安全事件、查看剩餘電量。',
  sp_desc_1:
    '* 青萍藍牙網關須已正確插入電源，並和智能門鎖添加到同一米家 app 帳號下，且二者在藍牙傳輸距離範圍內。',
  sp_check: '隨時查看家中溫濕度',
  sp_desc_2:
    '支持米家藍牙溫濕度計、青萍藍牙溫濕度計 M 版等多種設備，{br}隨時遠程查看家中溫濕度是否宜人，還可查看歷史數據。',
  sp_desc_2_2: '',
  sp_desc_3:
    '* 青萍藍牙網關須已正確插入電源，並和藍牙溫濕度計添加到同一米家 app 帳號下，',
  sp_desc_3_2: '且二者在藍牙傳輸距離範圍內。',
  sp_desc_m:
    '* 青萍藍牙網關須已正確插入電源，並和藍牙溫濕度計添加到同一米家 app 帳號下，且二者在藍牙傳輸距離範圍內。',
  sp_smart: '智能聯動',
  sp_smart_2: '開啟更多新玩法',
  sp_smart_m: '智能聯動，開啟更多新玩法',
  sp_desc_4: '青萍藍牙網關持續接收',
  sp_desc_4_2: '米家藍牙網關子設備',
  sp_desc_4_3:
    '廣播的藍牙信號，並將收到的內容通過 Wi-Fi 上傳到米家伺服器，您可用米家app 遠程查看藍牙子設備的數據、配置它們與其他設備的智能聯動策略。',
  sp_temp_rh: '藍牙溫濕度計',
  sp_gateway: '青萍藍牙網關',
  sp_smart_lock: '智能门鎖',
  sp_mihome: '米家 app',
  sp_access: '遠程查看',
  sp_access_2: '配置智能聯動',
  sp_server: '米家伺服器',
  sp_aircondition: '空調',
  sp_humidifier: '加濕器',
  sp_heater: '電暖氣',
  sp_baby_room: '讓寶寶的房間更舒適',
  sp_desc_5:
    '配備青萍藍牙網關後，可聯動其他電器，如設置“溫度高於 28℃，打開空調”，自動改善家中舒適度，寶寶和老人舒適安心、寵物怡然自得。',
  sp_gateway_1: '網關',
  sp_desc_6: '* 青萍藍牙網關须已正確插入電源，並和藍牙溫濕度計、空調',
  sp_desc_6_2: '等設備添加到同一米家 app 帳號下。',
  sp_welcome_home: '開鎖亮燈 “欢迎回家”',
  sp_desc_7:
    '智能门鎖配合藍牙網關使用，可設置開门自動打開客廳燈、小愛同學播報「歡迎回家」等多種智能聯動策略，為生活增添小惊喜。',
  sp_desc_8: '* 青萍藍牙網關须已正確插入電源，并和智能门鎖添加到同一米家',
  sp_desc_8_2: 'app 帳號下，且二者在藍牙傳输距離範圍內。',
  sp_work_with: '米家藍牙網關子設備的好搭檔',
  sp_desc_9: '青萍藍牙網關支持所有的',
  sp_desc_9_2: '米家藍牙網關子設備',
  sp_desc_9_3: '。將它們添加至同',
  sp_desc_9_4: '一米家帳號下，只要處於藍牙信號傳输範圍內，就會自動連接，',
  sp_desc_9_5: '進而允许您從米家手機 app 端遠程查看相關藍牙子設備的狀態、历',
  sp_desc_9_6: '史數據或事件通知、設置與其他設備的智能聯動等作業。',
  sp_desc_9_m:
    '將它們添加至同一米家帳號下，只要處於藍牙信號傳输範圍內，就會自動連接，進而允许您從米家手機 app 端遠程查看相關藍牙子設備的狀態、历史數據或事件通知、設置與其他設備的智能聯動等作業。',
  sp_accessories: '* 在米家 app “產品百科”中可查看米家藍牙網關子設備',
  sp_accessories_2: '完整列表',
  sp_accessories_3: '。米家藍牙網關子設備需單独购买。',
  sp_small: '小巧、简單、纯粹',
  sp_plugged: '青萍藍牙網關體积近似手機電源適配器，隨處可插，部署方便。',
  sp_craftsmanship: '精工之作',
  sp_no_wasted: '一只当關，“五脏”俱全，{br}空間不浪费，性能不妥协。',
  sp_size_long: '62.16 毫米',
  sp_size_short: '42 毫米',
  sp_size_height: '31.5 毫米',
  sp_size: '62.16 × 42 × 31.5 毫米',
  sp_color: '白色',
  sp_temp_range: '-10 ~ 40℃',
  sp_humi_range: '0 ~ 90%RH，無冷凝',
  sp_weight: '46 克',
  sp_wifi_type: 'IEEE 802.11b/g/n 2.4GHz',
  sp_ble: '藍牙 4.2',
  sp_power: '100–240V~, 50/60Hz, 0.2A',
  sp_env: '工作環境',
  sp_temp: '工作溫度',
  sp_humi: '工作濕度',
  sp_power_in: '電源输入',

  plus_prd: '青萍家用產品小助手',
  plus_life: '生活更美好',
  plus_app: 'App Store 下載',
  plus_android: 'Android 下載',
  plus_plus: '青萍+',
  plus_home: '青萍家用產品小助手',
  plus_device: ' · 設備管理',
  plus_insights: ' · 讀數解讀',
  plus_historical: ' · 歷史數據',
  plus_sharing: ' · 設備共享',
  plus_remote: ' · 遠程訪問',
  plus_notification: ' · 消息通知',
  plus_support: '支持 Android 和 iOS',
  download_apk: '下載安裝文件 APK',

  language: '語言：',
  language_value_1: '简体中文',
  language_value_2: '繁體中文',
  language_value_3: 'English',

  mall_value_temporary: '天貓（中國）',
  mall_value_1: '亞馬遜（美國）',
  mall_value_2: '亞馬遜（歐洲）',
  mall_value_3: '天貓（中國）',
  mall_value_1_T: 'T 版 - 亞馬遜（美國）',
  mall_value_2_T: 'T 版 - 亞馬遜（歐洲）',
  mall_value_3_T: 'T 版 - 天貓（中國）',
  mall_value_1_H: 'H 版 - 亞馬遜（美國）',
  mall_value_2_H: 'H 版 - 亞馬遜（歐洲）',
  mall_value_3_H: 'H 版 - 天貓（中國）',
  mall_value_1_M: 'M 版 - 亞馬遜（美國）',
  mall_value_2_M: 'M 版 - 亞馬遜（歐洲）',
  mall_value_3_M: 'M 版 - 天貓（中國）',

  bran: '米家空氣检測仪',
  duck: '米家藍牙溫濕度計',
  lora: '青萍溫濕度計（無藍牙）',
  lipstick: 'FOXIO Lipstick 移動電源',
  overview: '概述',
  icp: '粤ICP備19156805號',
  co2_cn: '二氧化碳',
  tvoc: 'TVOC',
  bran_screen: '高清觸控螢幕',
  duck_sensor: '高靈敏度傳感器',
  duck_screen: '超低功耗 LCD',
  duck_wall: '磁吸牆貼',
  low: '超低功耗',
  thirdth_text_1: '支架未打開',
  thirdth_text_2: '支架已打開',
  fourth_text_1: '牆貼',
  fifth_desc_1: '電子墨水段码屏',
  fifth_desc_2: '普通低功耗段码屏',
  nineth_title: '自動记录溫濕度历史{br}數據，長達 30 天',
  ninth_desc: '* 需連接“青萍+”手機 app。',
  cleargass_app: '青萍+',
  p_param: '產品參數',
  s_first_desc_1: '全面監測室內空氣',
  s_first_desc_2: '高精度傳感器',
  s_first_desc_3: '超清觸控螢幕',
  s_first_desc_4: '智能聯動',
  s_fifth_desc: '溫濕度、tVOC 和 CO₂ 傳感器来自瑞士 Sensirion。',
  s_eighth_sub_title: '15° 仰角設計，查看更方便',
  s_tenth_desc_2:
    '* 本產品採用 CR2430 電池，在常见室溫下可使用 8 ~ 12 個月。{br}數據来自青萍實驗室，實際續航時間可能依環境和電池的差异而有所不同。',
  s_wifi_value: 'IEEE 802.11b/g/n 2.4GHz',
  s_battery_type_value: '锂離子電池',
  s_screen_size_value: '3.1 英寸',
  seven_battery: '7 號電池',
  p_fifth_title_wifi: 'Wi-Fi 版',
  p_fifth_title_nbiot: 'NB-IoT 版',
  p_fifth_title_lora: 'LoRa 版',
  p_fifth_sub_title: '待機時長',
  p_fifth_sub_desc_1: '（每 8 小時傳一次，數據粒度為 15 分鐘一组）',
  p_fifth_sub_desc_2: '（每小時傳一次，數據粒度為 15 分鐘一组）',
  p_fifth_temp: '{n}° 環境',
  p_fifth_day: '{n} 天',
  p_sixth_img_1: '遠程查看數據',
  p_sixth_img_2: '接收通知',
  p_sixth_img_3: '導出數據',
  p_nineth_resolution_value: '0.01 kPa',
  p_nineth_typical_accuracy_value: '±0.05 kPa',
  p_nineth_typical_accuracy_value_desc: '@-20~50℃, 30~125 kPa',
  p_nineth_temp_value_1: '0.1℃',
  p_nineth_temp_value_2: '±0.2℃',
  p_nineth_temp_value_2_desc: '@0~50℃',
  p_nineth_hum_value_1: '0.1%',
  p_nineth_hum_value_2: '±2%',
  p_nineth_hum_value_2_desc: '@10~90%',
  ph_name: '產品名稱',
  purchase: '购买',
  ph_home_title: '聯網多面手，待機小超人',

  download_imd: '立即下載',
  download_imd_ios: 'App Store',
  download_imd_android: '軟件商店',
  qrcode_text: '微信扫码关注{br}或搜索“青萍科技”',
  care: '关注我们',
  wechat: '微信',
  sina: '微博',
  facebook: 'Facebook',
  instagram: 'Instagram',

  dany_first_title: '一眼 “看清” 空氣',
  dany_first_desc_1: '五項檢測',
  dany_first_desc_2: '高精度傳感器',
  dany_first_desc_3: '藍牙網關',
  dany_first_desc_4: '小巧精致',
  dany_co2: 'CO₂',
  dany_pm25: 'PM2.5',
  dany_pm10: 'PM10',
  dany_temp: '溫度',
  dany_humi: '濕度',
  dany_second_title: '每天呼吸幾萬次{br}是否都是好空氣',
  dany_second_desc:
    '室內空氣的潔凈與新鮮程度需要高度關註。CO2 是衡量室內空氣新鮮程度的重要指標，濃度太高時會讓人昏昏欲睡。而空氣中的顆粒物，如 PM2.5 和 PM10，粒徑小、活性強，易附著有害物質，被人吸入後，可能影響呼吸系統，甚至進入血液循環，對兒童和老人的負面影響尤為顯著。',
  dany_three_title: '五合壹空氣檢測儀',
  dany_three_desc: '功能全面，提供 PM2.5、PM10、CO2、溫濕度共五項數據的檢測。',
  dany_three_item1_title: 'PM2.5、PM10',
  dany_three_item1_desc: '監測顆粒汙染物，及時開啟空氣凈化器',
  dany_three_item1_desc_m: '監測顆粒汙染物，{br}及時開啟空氣凈化器',
  dany_three_item2_title: 'CO₂',
  dany_three_item2_desc: '監測空氣新鮮度標誌性氣體 CO₂，適時通風',
  dany_three_item2_desc_m: '監測空氣新鮮度標誌性{br}氣體 CO₂，適時通風',
  dany_three_item3_title: '溫度、濕度',
  dany_three_item3_desc: '監測溫濕度，聯動加濕器、空調、電暖器等環境電器',
  dany_three_item3_desc_m: '監測溫濕度，聯動加濕器{br}、空調、電暖器等環境電器',
  dany_four_title: '讀懂空氣，健康呼吸',
  dany_four_item1_title: '檢測辦公室 CO₂，適時通風，提升工作效率',
  dany_four_item1_desc:
    '* 人的呼吸會導致室內 CO₂ 濃度不斷上升、氧氣含量降低，進而使人困倦。',
  dany_four_item2_title:
    '檢測嬰兒房溫濕度，智能聯動環境電器，{br}讓寶寶睡得舒適香甜',
  dany_five_title: '多彩指示燈，壹目了然',
  dany_five_desc: '通過顏色分級指示空氣質量和溫濕度水平，遠遠壹看就知道。',
  dany_five_example: '以 CO₂ 為例：',
  dany_five_table_title1: '顏色',
  dany_five_table_title2: '範圍',
  dany_five_table_title3: '評價',
  dany_five_table_range1: '400 ~ 1000 ppm',
  dany_five_table_range2: '1000 ~ 2000 ppm',
  dany_five_table_range3: '2000 ~ 3000 ppm',
  dany_five_table_range4: '3000+ ppm',
  dany_five_table_desc1: '正常',
  dany_five_table_desc2: '濃度略高',
  dany_five_table_desc3: '濃度較高',
  dany_five_table_desc4: '濃度超高',
  dany_five_memo: '* PM2.5、PM10、CO2、溫度、濕度的分級和顏色各不相同。',
  dany_six_title: '點擊/滑動操作，{br}簡單直觀',
  dany_six_desc: '頂部電容觸摸條，支持滑動、點擊切換讀數，{br}體驗媲美觸摸屏。',
  dany_seven_title: '高精度傳感器，靈敏檢測',
  dany_seven_desc: 'PM2.5、PM10、CO2、溫度、濕度 5 項數據檢測，精準可靠。',
  dany_seven_item1_num: '1',
  dany_seven_item1_time: '秒',
  dany_seven_item1_desc: '五項數據每秒更新',
  dany_seven_item2_num: '0.3',
  dany_seven_item2_time: '微秒',
  dany_seven_item2_desc: '可檢測最小顆粒物',
  dany_eight_title: '格柵設計，傳感器工作更高效',
  dany_eight_desc: '機身背面的格柵式通風孔設計，能讓各個傳感器都充分接觸空氣。',
  dany_nine_title: '支持兩大智能家居平臺',
  dany_nine_desc:
    '支持小米“米家”和蘋果“家庭” app，可設置與各種產品的聯動，定制你的智能家居場景。',
  dany_ten_title: 'App 遠程查看，隨時提醒',
  dany_ten_desc:
    '通過小米“米家*”、“青萍+ ”或蘋果“家庭” app* 查看青萍空氣檢測儀 Lite，隨時隨地，家中空氣情況壹目了然。',
  dany_ten_memo:
    '通過蘋果“家庭” app 遠程查看，需配備 HomeKit 中樞設備，如 HomePod 或 Apple TV。',
  dany_eleven_title: '它還是個米家藍牙網關',
  dany_eleven_desc: '能持續接收',
  dany_eleven_desc_2: '米家藍牙網關子設備',
  dany_eleven_desc_3:
    '廣播的藍牙信號，並將收到的內容通過 Wi-Fi 上傳到米家服務器。您可用小米“米家” app 遠程查看藍牙子設備的數據、配置它們與其他設備的智能聯動策略。',
  dany_twelve_title: '空氣差了，自動開啟凈化器',
  dany_twelve_desc:
    '可設置青萍空氣檢測儀 Lite 聯動新風機、空氣凈化器、空調、電風扇、加濕器等設備，當溫度或濕度、PM2.5 或 PM10、二氧化碳達到設定值，自動開啟或關閉相關設備。',
  dany_twelve_item1: 'PM2.5 超過 100，打開空氣凈化器*',
  dany_twelve_item2: '濕度低於 30%，打開加濕器*',
  dany_twelve_memo: '需在米家 app 設置聯動策略實現。',
  dany_thirteen: '支持 Siri / 小愛同學',
  dany_thirteen_desc: ' ',
  dany_thirteen_memo: ' ',
  dany_fourteen_title: 'USB-C 口，可充電鋰電池',
  dany_fourteen_desc:
    '支持 USB 持續供電。內置 2000mAh 鋰電池，續航時間長達 7 小時。',
  dany_fifteen_title: '掌心大小，精致小巧',
  dany_fifteen_desc: '僅重 143 克，與壹顆小蘋果相當。',
  dany_sixteen_title: '簡約外觀{br}優雅融入各種家居風格',
  dany_sixteen_desc: '極簡設計，白色外框搭配圓潤的屏幕，溫和百搭。',
  dany_seventeen_item1:
    '像素風 UI 設計，賦予產品更多個性和趣味；OLED 屏幕，顯示更清晰，可視角度大。',
  dany_seventeen_item2: '矽膠底座，穩固擺放。',
  dany_seventeen_item3: '背面墨綠色格柵，美觀優雅。',

  dany_long: '63.6',
  dany_short: '46',
  dany_height: '54.6',
  dany_size: '63.6 × 46 × 54.6',
  dany_unit: '毫米',
  dany_color: '白色',
  dany_temp_range: '0 ~ 40°C',
  dany_humi_range: '0 ~ 95%RH（無凝露環境）',
  dany_co2_range: '400 ~ 9999 ppm',
  dany_pm25_range: '0 ~ 500 μg/m³',
  dany_pm10_range: '0 ~ 500 μg/m³',
  dany_memo: '* 请勿長時間在濕度超过 90% 的環境下使用',
  dany_name: '青萍空氣檢測儀 Lite',
  dany_model: 'CGDN1',
  dany_weight: '143 克',
  dany_screen: '59.9 × 49.9',
  dany_ble: '藍牙 5.0',
  dany_wifi: 'IEEE 802.11b/g/n 2.4GHz',
  dany_battery: '2000mAh',

  duck2: '青萍藍牙溫濕度計 Lite',
  dk2_first_title: '青萍藍牙溫濕度計 Lite',
  dk2_first_desc_1: '高精度傳感器',
  dk2_first_desc_2: '高屏占比',
  dk2_first_desc_3: '三種擺放方式',
  dk2_first_desc_4: '小巧精致',
  dk2_second_title: '關註溫濕度變化{br}營造健康舒適環境',
  dk2_second_desc:
    '嬰兒在溫濕度適宜的環境中，睡得香，醒來次數少。青萍藍牙溫濕度計 Lite，冷暖幹濕壹目了然，守護家人健康。',
  dk2_three_title: '高屏占比，美觀易讀',
  dk2_three_desc: '屏占比高，簡約純粹，賞心悅目。',
  dk2_four_title: '瑞士 Sensirion 傳感器{br}精度高，反應靈敏',
  dk2_four_desc:
    '青萍藍牙溫濕度計 Lite 采用在工業和消費市場廣受贊譽的瑞士 Sensirion 溫濕度傳感器，{br}輔以精妙的結構設計、ART算法，準確性、響應速度俱佳。',
  dk2_five_title: '內置藍牙，支持米家',
  dk2_five_desc:
    '搭配米家藍牙網關設備，青萍藍牙溫濕度計 Lite 可聯動智能設備，改善室內溫濕度環境，讓家更舒適',
  dk2_five_left_title: '室內溫度高時，自動開啟空調',
  dk2_five_left_item1: '青萍藍牙溫濕度計 Lite',
  dk2_five_left_item2: '青萍藍牙網關',
  dk2_five_left_item3: '智能空調',
  dk2_five_right_title: '室內空氣幹燥時，自動開啟加濕器',
  dk2_five_right_item4: '加濕器',
  dk2_six_title: '超低功耗，續航長達 8 個月',
  dk2_six_desc:
    '青萍藍牙溫濕度計 Lite 采用超低功耗段碼 LCD 屏幕，搭配紐扣電池，在常見室溫環境下可持續使用 8 個月。',
  dk2_six_memo: '* 實際待機時間受環境和電池影響。',
  dk2_seven_title: '三種擺放方式{br}隨心選擇',
  dk2_seven_title_m: '三種擺放方式，{br}隨心選擇',
  dk2_seven_item1_title: '01.墻面模式',
  dk2_seven_item1_desc:
    '包裝內有墻貼，將它粘在墻上，青萍藍牙溫濕度計 Lite 就能吸附在墻面上了。',
  dk2_seven_item2_title: '02.桌面模式',
  dk2_seven_item2_desc:
    '包裝內有桌面支架，能讓青萍藍牙溫濕度計 Lite 秒變桌面擺件，巧妙又穩當',
  dk2_seven_item3_title: '03.磁吸模式',
  dk2_seven_item3_desc:
    '青萍藍牙溫濕度計 Lite 內置磁鐵，可吸附在冰箱或其他鐵制器物上',
  dk2_eight_title: '隨時隨地查看溫濕度、歷史數據',
  dk2_eight_desc:
    '產品內置存儲能力，可存儲每 15 分鐘壹條、長達 30 天的歷史數據。',
  dk2_nine_title: '小巧精致，輕盈又百搭',
  dk2_nine_desc: '直徑 60.5 毫米，厚度 13.7 毫米。',
  dk2_ten_desc1:
    '冬季，溫度在 20°C ~ 24°C、相對濕度在 30% ~ 60% 時，人體感覺較為舒適；在夏季，溫度在 23°C ~ 26°C、相對濕度在 25% ~ 60% 時，人體感覺較為舒適。',
  dk2_ten_desc2:
    '隨產品附贈 1 枚電池。電池耗盡時，用戶需自行購買替換的電池，各大電商和超市均有售。',
  dk2_ten_desc3:
    '您可通過青萍+ app 讀取青萍藍牙溫濕度計本地存儲的歷史數據，並可選擇導出  CSV 文件。',
  dk2_ten_list2:
    '、智能空調、加濕器設備均需另行購買。聯動動作和生效時間段需用戶自行設置。',
  dk2_ten_list3: '支持米家的智能空調產品。',
  dk2_ten_list4: '支持米家的加濕器產品。',

  dk2_long: '60.5 毫米',
  dk2_height: '13.7 毫米',
  dk2_size: '60.5 × 60.5 × 13.7 毫米',
  dk2_color: '白色',
  dk2_temp_range: '-9.9 ~ 50°C',
  dk2_humi_range: '0 ~ 99.9%（無凝露環境）',
  dk2_memo: '* 請勿長時間在濕度超過 90% 的環境下使用。',
  dk2_name: '青萍藍牙溫濕度計 Lite',
  dk2_model: 'CGDK2',
  dk2_weight: '38 克（不含支架、墻貼、電池）',
  dk2_screen: '直徑 49.9 毫米',
  dk2_ble: '藍牙 5.0',
  dk2_battery: 'CR2430 型紐扣電池',
  dk2_pack_wall: '墻貼',
  dk2_pack_desk: '桌面支架',
  dk2_pack_battery: 'CR2430 紐扣電池',

  hodor: '青萍門窗開合傳感器',
  hodor_first_title: '青萍門窗開合傳感器',
  hodor_first_desc_1: '感知門窗開關狀態',
  hodor_first_desc_2: '智能聯動',
  hodor_first_desc_3: '藍牙 5.0',
  hodor_second_title: '感應門窗開合',
  hodor_second_desc: '通過主體與磁鐵之間的距離感應，判斷門窗開合',
  hodor_second_magnet: '磁鐵',
  hodor_second_main: '主體',
  hodor_three_title: '遠程推送通知',
  hodor_three_desc: '檢測到門窗開合，app 遠程自動推送提醒，時刻守護家中安全。',
  hodor_four_title_1: '接入米家',
  hodor_four_title_2: '，智能聯動',
  hodor_four_desc: '搭配米家其他智能產品，設置聯動策略，實現多種智能場景。',
  hodor_five_left_title: '開窗後自動關閉空氣凈化{br}器，減少濾芯消耗。',
  hodor_five_item1: '青萍門窗開合{br}傳感器',
  hodor_five_item2: '青萍藍牙網關',
  hodor_five_item3: '空氣凈化器',
  hodor_five_right_title: '推開門，自動開燈，{br}無論多晚，總有壹盞為妳守候。',
  hodor_five_item4: '智能燈',
  hodor_six_title: '藍牙 5.0',
  hodor_six_desc: '采用低功耗藍牙 5.0，續航長達 8 個月',
  hodor_seven_title: '身材小巧，隨處可貼',
  hodor_seven_desc: '體積小，顏值高，適用場景豐富。即貼即用，無需安裝工具。',
  hodor_seven_item1: '衣櫃',
  hodor_seven_item2: '抽屜',
  hodor_seven_item3: '門窗',
  hodor_eight_title: '安裝說明',
  hodor_eight_desc:
    '* 安裝時請讓主體和磁鐵中線成頂部對齊，並確保它們的間距 < 15 毫米。',
  hodor_ten_desc1: '本產品需搭配具有米家藍牙網關功能的設備使用，如',
  hodor_ten_desc1_2: ' ',
  hodor_ten_desc2: '、空氣凈化器、智能燈等設備需另行購買。',
  hodor_ten_desc3: '聯動動作和生效時間段需用戶自行設置。',
  hodor_ten_list1: '需在米家 app 設置聯動策略實現。',
  hodor_ten_list2: '支持米家的空氣凈化器產品。',
  hodor_ten_list3: '支持米家的智能燈具產品。',
  hodor_ten_list4:
    '續航時長可達 8 個月，是在平均 1 小時開或關門  1 次情況下測得，實際續航時間可能依使用頻率不同而有所不同。',

  hodor_front_bottom: '21.5 毫米',
  hodor_front_right: '44 毫米',
  hodor_side_bottom: '13 毫米',
  magnet_front_bottom: '14 毫米',
  magnet_front_right: '29 毫米',
  magnet_side_bottom: '7 毫米',
  hodor_height: '13.7 毫米',
  hodor_size: '主體 44 × 21.5 × 13 毫米{br}磁鐵 29 × 14 × 7 毫米',
  hodor_color: '白色',
  hodor_name: '青萍門窗開合傳感器',
  hodor_model: 'CGH1',
  hodor_weight: '主體 6 克（不含電池），磁鐵 7 克',
  hodor_ble: '藍牙 5.0',
  hodor_battery: 'CR1632 型紐扣電池',
  hodor_magnet: '磁鐵',

  sensor_name: '傳感器',

  parrot: '青萍動作和環境光傳感器',
  parrot_first_title: '青萍動作和環境光傳感器',
  parrot_first_desc_1: '靈敏識人',
  parrot_first_desc_2: '精準感知光線變化',
  parrot_first_desc_3: '動作和照度結合聯動',
  parrot_second_title: '有人經過，靈敏感知',
  parrot_second_desc: '采用四元 PIR',
  parrot_second_desc_1:
    '，精準識別人的移動。擺放角度靈活可調，讓識別範圍更廣。您可按需設置「有人移動」或「無人移動」觸發的自動化。',
  parrot_three_title: '精準檢測光線，靈活設置聯動',
  parrot_three_title_1: '，而非籠統的 “光線強” 或 “光線弱”',
  parrot_three_desc: '天色變暗就自動開燈，還是入夜才開，隨您設置，精準把握。',
  parrot_three_range: '光照強度量程：',
  parrot_three_num: '0 ~ 83,000',
  parrot_three_sensor: '靈敏度可達：',
  parrot_three_value: '2',
  parrot_three_unit: 'lux',
  parrot_three_memo: '動作和光照強度結合，創建豐富的{br}智能聯動場景',
  parrot_four_title: '天色漸暗，自動點亮燈光',
  parrot_four_desc:
    '人們專註讀書的時候，往往忽略了室內光線正在變暗。可以設置光照強度低於 400 lux，自動開燈，讓閱讀更輕松，同時保護視力。',
  parrot_five_title: '夜裏起床，開啟輕柔夜燈',
  parrot_five_desc:
    '可設置當檢測到臥室有人起床，且光照強度低於 20 lux 時，自動開啟智能床頭燈，或讓洗手間的智能燈亮起，夜裏起床不摸黑。',
  parrot_six_title: '匆匆出門，它能幫你節能',
  parrot_six_desc:
    '可設置未檢測到人的移動，自動關燈、關閉空調或電暖器等家電，節能又安全。',
  parrot_seven_title: '異常闖入，即時提醒',
  parrot_seven_desc:
    '外出時，當家中檢測到有人移動，手機 app 會即時收到提醒，守護家中安全。',
  parrot_check_remote: '遠程查看數據，設置智能聯動。',
  parrot_eight_title:
    '隨時了解光照強度、查看歷史數據，設置與米家智能設備的聯動。',
  parrot_eight_desc: '遠程查看和設置智能聯動需配備米家藍牙網關設備，如',
  parrot_nineteen_desc:
    '自動記錄歷史數據：光照強度數據長達 30 天；動作感應數據多達 3000 條。',
  parrot_nineteen_memo:
    '使用青萍+ app 查看和下載歷史數據，T 版需配備家居中樞，如 HomePod 或 Apple TV；M 版需配備',
  parrot_nine_title: '自帶磁吸底座，靈活調整探測方向',
  parrot_nine_desc: '產品可在半球形磁吸底座上自由轉動，停在任意適合的角度。',
  parrot_ten_title: '三種擺放方式可選',
  parrot_ten_item1: '粘貼（墻面）',
  parrot_ten_item2: '平放（桌面）',
  parrot_ten_item3: '磁吸（鐵器表面）',
  parrot_eleven_title: '多區多段菲涅爾透鏡，靈敏度高',
  parrot_eleven_desc:
    '內置多區多段菲涅爾透鏡，充分利用每個光學單元，體積更小巧，反應更靈敏。',
  parrot_twelve_title: '用料考究，工藝精湛',
  parrot_twelve_desc:
    '采用 TI（德州儀器）精密光照傳感器，靈敏檢測光照強度。外殼采用抗 UV 材質，長期使用不易褪色。',
  parrot_thirteen_title: '續航長達 2 年',
  parrot_fourteen_title: '簡約設計，百搭各種家居風格',
  parrot_fifteen_title: '便攜安裝',
  parrot_fifteen_item1: '方法一：',
  parrot_fifteen_item1_desc:
    '撕下底座上的雙面膠覆膜，將底座粘在選好的位置，並將產品主體吸附到底座上。',
  parrot_fifteen_item2: '方法二：',
  parrot_fifteen_item2_desc:
    '撕下底座上的雙面膠覆膜，將底座粘在選好的位置，並將產品主體吸附到底座上。',
  parrot_fifteen_main: '主體',
  parrot_fifteen_base: '底座',
  parrot_fifteen_tape: '雙面膠',
  parrot_sixteen_title: '人體動作檢測區域',
  parrot_ten_list1:
    '要設置智能聯動，青萍動作和環境光傳感器 T 版需配備家居中樞，如 HomePod 或 Apple TV，使用 Apple Home app 設置；M 版需配備米家藍牙網關設備，如',
  parrot_ten_list1_1: '，使用米家 app 設置。',
  parrot_ten_list2:
    '在環境溫度為 25°C、平均每天探測到 120 次人體移動的條件下測得。',
  parrot_ten_list3:
    '檢測區域僅為示意圖，測試數據來自青萍合作實驗室，在常溫 (25°C) 環境、產品安裝在高 2.2 米處且向下傾斜 20° 的條件下測得。',

  parrot_bottom: '38 毫米',
  parrot_height: '35.9 毫米',
  parrot_bottom_bottom: '36 毫米',
  parrot_bottom_height: '13.7 毫米',
  parrot_size: '主體：38 × 38 × 35.9 毫米，{br}底座：36 × 36 × 13.7 毫米',
  parrot_size_sensor: '主體：38 × 38 × 35.9 毫米',
  parrot_size_base: '底座：36 × 36 × 13.7 毫米',
  parrot_color: '白色',
  detect_distance: '動作檢測距離',
  parrot_distance: '7 米',
  detect_angle: '動作檢測角度',
  parrot_angle: '15°（7 米）~ 120°（2 米以内）',
  light_range: '光照強度量程',
  parrot_range: '0 ~ 83,000 lux',
  parrot_name: '青萍動作和環境光傳感器',
  parrot_t_name: '青萍動作和環境光傳感器 T 版',
  parrot_m_name: '青萍動作和環境光傳感器 M 版',
  parrot_t_model: 'CGPR1T',
  parrot_m_model: 'CGPR1M',
  parrot_model: 'CGPR1',
  parrot_weight: '主體：33.8 克，底座：6.6 克',
  parrot_ble: '藍牙 5.0',
  parrot_work_temp: '工作溫度',
  parrot_temp: '-10 ~ 45°C',
  parrot_work_humi: '工作濕度',
  parrot_humi: '0 ~ 90%RH',
  parrot_battery: '2 顆 CR2450 紐扣電池',
  parrot_pack_body: '青萍動作和環境光傳感器主體',
  parrot_pack_bottom: '青萍動作和環境光傳感器底座',
  parrot_pack_stick: '雙面膠',

  //chicken
  chicken: '青萍藍牙小鐘',
  chicken_first_title: '有條不紊，用心生活',
  chicken_first_desc1: '循環計時',
  chicken_first_desc2: '16 組鬧鐘',
  chicken_first_desc3: '正計時',
  chicken_first_desc4: '溫濕度監測',
  chicken_second_title: '計時、定時都精通，時間管理小助手',
  chicken_second_desc: '6 種功能，一物多用。',
  chicken_second_fun1: '鬧鐘',
  chicken_second_fun2: '循環計時',
  chicken_second_fun3: '正計時',
  chicken_second_fun4: '時鐘',
  chicken_second_fun5: '溫度計',
  chicken_second_fun6: '濕度計',
  chicken_third_title: '開啟循環計時',
  chicken_third_title1: '，保持專註',
  chicken_third_title_en: 'Boost Your Productivity with the Tomato Timer',
  chicken_third_desc: '工作時段',
  chicken_third_desc1: '保持專註，休息時段',
  chicken_third_desc2: '盡情放松，張弛有度更高效。',
  chicken_third_desc_en:
    'Immerse yourself during working time, and have fun during breaks',
  chicken_fourth_title: '隨心設置 16 組鬧鐘{br}生活、學習更規律',
  chicken_fourth_desc:
    '可設置 16 組鬧鐘，應對日常事項提醒。每組鬧鐘可單獨設置稍後提醒',
  chicken_fourth_desc1: '功能和重復規則。',
  chicken_fifth_title: '優雅計時',
  chicken_fifth_title1: '，每天一點小堅持',
  chicken_fifth_desc: '運動、冥想、讀書…...你的專註時光，{br}有它安靜陪伴。',
  chicken_sixth_title: '精準測溫濕',
  chicken_sixth_title1: '3 種表情提示',
  chicken_sixth_desc:
    '內置瑞士 Sensirion 溫濕度傳感器，精度高，{br}反應靈敏。3 種表情符號，一眼看懂房間舒適度。',
  chicken_sixth_level1: '舒適',
  chicken_sixth_level2: '一般',
  chicken_sixth_level3: '不舒適',
  chicken_seventh_title: '遠程設置鬧鐘、循環計時',
  chicken_seventh_desc:
    '搭配青萍藍牙網關，您可通過青萍+ app 遠程查看和設置{br}鬧鐘、番茄鐘。',
  chicken_eighth_title: '智能聯動，讓房間更舒適',
  chicken_eighth_title1: ' ',
  chicken_eighth_desc: '聯動米家智能設備',
  chicken_eighth_desc1:
    '，自動改善家中舒適度。{br}例如：溫度高於 26°C 時，自動打開空調。',
  chicken_ninth_title: '輕松對時，保持精準',
  chicken_ninth_desc:
    '傳統時鐘，走時很容易出現誤差，對時麻煩。青萍藍牙小鐘會在連接手機{br} app 時與手機自動對時。如將青萍藍牙小鐘和青萍藍牙網關一起添加到青{br}萍+ app，青萍藍牙小鐘會通過網關每天自動對時，保持精準。',
  chicken_tenth_title1: '纖薄屏幕，微微透光',
  chicken_tenth_title2: '貼心傾角，查看更方便',
  chicken_eleventh_title: '風格百搭，放哪裏都好看',
  chicken_twelfth_title: '更多細節',
  chicken_twelfth_desc1: '5 毫米超薄機身',
  chicken_twelfth_desc2: '一體式按鈕',
  chicken_thirty_title: '兩色可選',
  chicken_thirty_desc1: '粉色',
  chicken_thirty_desc2: '米色',
  chicken_fourteen_desc1: '需在米家或',
  chicken_fourteen_desc1_1: ' app 中設置，雙擊底座上的按鍵開啟循環計時。',
  chicken_fourteen_desc2:
    '工作時段：默認 25 分鐘，可在 5 ~ 100 分鐘之間選擇; {br}休息時段：默認 5 分鐘，可在 1 ~ 100 分鐘之間選擇。',
  chicken_fourteen_desc3:
    '鬧鈴響起後，單擊底座上的按鍵，進入稍後提醒模式，10 分鐘之後將再次響起；雙擊可取消稍後提醒。',
  chicken_fourteen_desc4: '雙擊底座上的按鍵開啟正計時功能。',
  chicken_fourteen_desc5: ' ',
  chicken_fourteen_desc5_1:
    '使用米家 app ，搭配米家藍牙網關設備，可遠程查看溫濕度；使用',
  chicken_fourteen_desc5_2:
    ' app，搭配，還可查看歷史數據。網關設備需額外購買。',
  chicken_fourteen_desc6:
    '實現聯動場景或遠程查看溫濕度，需搭配米家藍牙網關設備，如',
  chicken_fourteen_desc6_1: '、小愛音箱等。米家智能設備需額外購買。',

  chicken_bottom: '90.27 毫米',
  chicken_height: '93.27 毫米',
  chicken_width: '40 毫米',
  chicken_size: '90.27 × 93.27 × 40 毫米',
  chicken_color: '米色、粉色',
  chicken_name: '青萍藍牙小鐘',
  chicken_model: 'CGC1',
  chicken_weight: '53.1 克（不含電池）',
  chicken_ble: '藍牙 5.0',
  work_temp: '溫度量程',
  chicken_temp: '-9°C ~ 50°C',
  work_humi: '濕度量程',
  chicken_humi: '0 ~ 99%RH（無凝露環境）',
  chicken_battery: 'CR2430 紐扣電池',

  frog: '青萍商用溫濕度計 E',
  frog_index_desc: 'IP55 級防護{br}超長待機',
  frog_needle: '青萍外接溫度探頭',
  frog_first_title: '防塵防水，專業溫濕度監控方案',
  frog_first_temp_range: '溫度量程',
  frog_first_humi_range: '濕度量程',
  frog_first_level: '防護等級',
  frog_first_level_title: '防護等級',
  frog_first_special: '特殊材料',
  frog_first_range1: '-30 ~ 60°C',
  frog_first_range2: '-40 ~ 125°C',
  frog_first_range3: '0 ~ 100%RH',
  frog_first_range4: '-',
  frog_first_range5: 'IP55',
  frog_first_range6: 'IP68',
  frog_first_range7: '-',
  frog_first_range8: '食品級材料',
  frog_first_complete: '查看完整規格',
  frog_second_title_1: '整機 IP55',
  frog_second_title_2: ' 級防護，防塵防水',
  frog_third_title: '支持外接溫度探頭',
  frog_third_desc: '可監測水溫、信號被隔絕的空間的溫度。能持續浸水使用。',
  frog_third_left_1: 'IP68',
  frog_third_left_2: ' 級防護',
  frog_third_right: '食品級材料',
  frog_fourth_title: '多種供電方式可選',
  frog_fourth_left: '方式一：5 號電池供電',
  frog_fourth_right: '方式二：Micro-USB 接口供電',
  frog_fourth_right_desc: '可長期連接 Micro-USB 電源。不支持充電。',
  frog_fifth_title: '待機時間計算器',
  frog_fifth_desc: '待機有多久，算算更明了。',
  frog_sixth_title: '長距離無線通信，多種可選方案',
  frog_sixth_desc: '可在 3 種方案中選擇一種。',
  frog_sixth_wifi: '使用環境需有穩定的 Wi-Fi 信號。',
  frog_sixth_lora: '需配備 LoRa 網關使用。',
  frog_sixth_nb: '需當地中國移動提供 NB-IoT',
  frog_sixth_nb_2: ' 網絡服務。',
  frog_seventh_title: '外置天線，信號更佳',
  frog_seventh_signal: '信號強',
  frog_seventh_cover: '覆蓋廣',
  frog_seventh_stable: '傳輸穩定',
  frog_eight_title: '高精度傳感器',
  frog_eight_left_1: '典型精確度',
  frog_eight_left_2: '分辨力',
  frog_eight_left_3: '量程',
  frog_eight_left_4: '±0.5°C{br}（@0 ~ 50°C）',
  frog_eight_left_5: '±5%RH{br}（@10% ~ 90%RH）',
  frog_eight_left_6: '0.1°C',
  frog_eight_left_7: '1%RH',
  frog_eight_left_8: '-30 ~ 60°C',
  frog_eight_left_9: '0 ~ 100%RH',
  frog_eight_right_1: '-40 ~ 125°C',
  frog_ninth_title: '青萍物聯',
  frog_ninth_desc:
    '青萍物聯是為青萍智能硬件產品提供設備管理、數據收集、數據查詢以及實時警報服務的物聯網平臺。',
  frog_ten_title: '完善的第三方開發文檔和 API，支持二次開發',
  frog_ten_developer: '開發者平臺',
  frog_ten_dev_add: 'developer.qingping.co',
  frog_eleven_title: '多場景專業應用',
  frog_eleven_desc:
    '如車間、倉庫、機房、冷庫、實驗室、藥店、酒窖、大棚、養殖場等。',
  frog_eleven_pharmacy: '藥店',
  frog_eleven_garbage: '倉庫',
  frog_eleven_farm: '養殖場',
  frog_eleven_greenhouse: '大棚',
  frog_twelfth_title: '多種安裝方式',
  frog_twelfth_left_1: 'DIN 導軌',
  frog_twelfth_left_2: ' 固定',
  frog_twelfth_right: '磁吸',
  frog_bottom_1:
    '青萍商用溫濕度計 E 可防塵防水。經實驗室測試，在 IEC60529 標準下，其防 護效果達到 IP55 級別（使用噴頭內徑為 6.3 毫米的噴嘴，從 2.5 ~ 3 米左右的距離以 12.5 升/分鐘的供水強度持續噴灑 3 分鐘）。 本品不可浸泡於水中或長期處在揚塵環境中，防水、防塵功能並非永久有效，防護性能可能會因日常使用而下降。',
  frog_bottom_2:
    '青萍外接溫度探頭可防塵，能持續浸水使用。經實驗室測試，在 IEC60529 標準下，其防護效果達到 IP68 級別（浸泡於 1.5 米深的水中，持續時間 1 小時）。防水、防塵功能並非永久有效，防護性能可能會因日常使用而下降。',
  frog_bottom_3:
    '經實驗室測試，在 GB4086 標準下，青萍外接溫度探頭的材料（包括不銹鋼探頭和矽膠線）符合食品級要求。',
  frog_bottom_4:
    '設備出廠內置 SIM 卡，含 8 年中國移動 NB-IoT 流量資費（限 50 MB/年）。不同批次所包含的資費可能不同。',
  frog_bottom_5:
    '本品可被安裝在 35 毫米 DIN 導軌上，DIN 導軌及固定夾需另行購買。',
  frog_spec_long: '87.6 毫米',
  frog_spec_short: '33 毫米',
  frog_spec_height: '156.8 毫米',
  frog_spec_size: '87.6 × 156.8 × 33 毫米（含天線）',
  frog_color: '深灰色',
  frog_temperature:
    '-30 ~ 60°C （使用鋰鐵 5 號電池）{br}-10 ~ 50°C （使用普通 5 號電池）{br}0 ~ 40°C （使用 USB 接電）',
  frog_humidity:
    '0 ~ 100%RH（使用鋰鐵 5 號電池或普通 5 號電池，無凝露環境）{br}0 ~ 90%RH （使用 USB 接電，無凝露環境）',
  frog_weight: '118 克',
  frog_screen_size: '64.6 × 44.6 毫米',
  frog_wifi: 'Wi-Fi 或 NB-IoT 或 LoRa',
  frog_ble: '藍牙 5.0',
  frog_power: 'Micro-USB',
  frog_power_in: '電源接口',
  frog_inout: '5V⎓1A',
  frog_battery_support: '電池供電',
  frog_battery: '可使用 2 節 5 號鋰鐵電池或普通電池',
  frog_protect: 'IP55',
  frog_package_battery: '2 節 5號{br}鋰鐵電池',
  needle_size:
    '傳感器探頭：直徑 6 毫米，長度 50 毫米{br}線身：直徑 3.5 毫米，長度 1500 毫米{br}吸盤式固定支架：直徑 35 毫米，高 20 毫米',
  needle_temperature: '-40 ~ 125℃',
  needle_weight: '29 克',
  needle_connect_way: '連接方式',
  needle_connect: '3.5 毫米 AUX',
  needle_protect: 'IP68',
  needle_material_left: '產品材質',
  needle_material: '食品級 316L 不銹鋼',
  sensor_detect: '傳感器探頭',
  sensor_body: '傳感器線身',
  needle_body: '食品級矽膠',
  needle_paste: '矽膠',
  needle_with: 'PET',
  needle_package_bottom: '吸盤式{br}固定支架',
  needle_package_with: '膠帶{br}（用於固定產品）',
  battery_type: '電池類型',
  battery_type_1: '鋰鐵 5 號電池',
  battery_type_2: '普通 5 號電池',

  //关于我们
  about_title: '關於我們',
  about_one:
    '青萍科技（北京）有限公司成立於 2015 年，是全球领先的温湿度和空气质量检测产品和服务提供商',
  about_two:
    '青萍致力於環境類 IoT 產品的研發和設計，為消費者和行業客戶提供監測和控製解決方案，產品覆蓋世界多個國家和地區，多次榮獲紅點、iF等國際設計獎項。',
  about_three:
    '在消費市場上，青萍產品在主流電商平臺擁有行業領先的銷量和好評率，並與多家知名電子消費品牌建立了傳感器類產品的合作。',
  about_four:
    '在行業市場上，青萍公司在建築、食品、供熱、養殖、種植等傳感器應用領域擁有豐富的客戶資源和技術積累。',
  about_five: '公司總部位於北京，設有深圳製造中心和蘇州客服中心；自有工廠已通過 ISO9001 認證。',
  about_six: ' ',
  //Dove app download
  clock_text_1: '青萍藍牙鬧鐘支持下列 app：',
  clock_text_2:
    '通過它們連接青萍藍牙鬧鐘，您可以調整青萍藍牙鬧鐘的鬧鈴時間、鈴聲、背光、屏幕顯示語言等。每次成功連接後，都會自動對時。',
  mijia: '米家',
  mijia_desc: '小米智能家居 app。',
  app_plus: '青萍+',
  app_plus_desc: '青萍智能設備管理 app。',
  clock_download_tip: '或在應用商店搜索下載。',

  //pheasant-co2
  ph_co2_realtime: '實時',
  ph_co2_size_long: '77 毫米',
  ph_co2_size_height: '28 毫米',
  ph_co2_size_value: '77 × 77 × 28 毫米',
  ph_co2: 'CO₂量程',
  ph_co2_value: '400 ~ 9999 ppm',
  ph_co2_temp_value: '-20 ~ 50°C',
  ph_co2_hum_value: '0 ~ 99%RH（無凝露環境）',

  ph_co2_name_value: '青萍二氧化碳和溫濕度檢測儀',
  ph_co2_screen_value: '61 × 49 毫米',
  ph_co2_wifi_value: 'Wi-Fi 或 NB-IoT 或 LoRa',
  ph_co2_battery_value: '2600mAh',

  ph_co2_first_title: '青萍二氧化碳和溫濕度檢測儀',
  ph_co2_first_desc: '高精度傳感器 · 無線連接 · 遠程監測 · 超限及時通知',
  ph_co2_second_title: 'CO₂ 是衡量室內空氣質量的重要指標',
  ph_co2_second_desc:
    'CO₂ 即二氧化碳，是空氣中常見的化合物。室內 CO₂ 主要來自於人的呼吸，在密閉的房間裏，人的呼吸會導致 CO₂ 濃度不斷上升、氧氣含量降低，進而使人困倦。{br}',
  ph_co2_second_desc_2:
    '當室內 CO₂ 濃度過高時，通常表明通風不良，可能導致空氣中其他汙染物（如揮發性有機物）的積累，通過空氣傳播的病毒的感染風險也會提高。',
  ph_co2_third_title: '監測室內 CO₂，適時采取改善措施',
  ph_co2_third_desc:
    '可廣泛應用於家庭、學校、辦公樓、醫院、餐廳、商場、工廠、酒店、實驗室等場景。',
  ph_co2_third_table_color: '顏色',
  ph_co2_third_table_range: '範圍',
  ph_co2_third_table_rate: '評價',
  ph_co2_third_range_normal: '400 ~ 1000 ppm',
  ph_co2_third_rate_normal: '正常',
  ph_co2_third_range_high: '1000 ~ 1400 ppm',
  ph_co2_third_rate_high: '濃度略高',
  ph_co2_third_range_pretty_high: '1400+ ppm',
  ph_co2_third_rate_pretty_high: '濃度較高',
  ph_co2_third_memo: '* 通過顏色分級指示，快速了解 CO₂ 濃度水平。',
  ph_co2_four_desc1:
    '當檢測到辦公室、學校教室的 CO₂ 濃度過高時，適時通風，提升工作、學習效率。',
  ph_co2_four_desc2:
    '檢測商場和餐廳等公共場所的 CO₂，可用於評價室內通風水平和人員聚集情況。CO2 濃度過高時，適時采取通風、開啟新風系統等改善措施，減少空氣中有害物質的聚集和傳播。',
  ph_co2_five_title: '溫濕度監測',
  ph_co2_five_desc: '大量程，多場景專業應用。',
  ph_co2_six_title: '高精度傳感器',
  ph_co2_six_table_title: '二氧化碳傳感器',
  ph_co2_six_table_subtitle: '采用非色散紅外（NDIR）光譜分析原理',
  ph_co2_six_table_title_2: '高精度溫濕度傳感器',
  ph_co2_range: '量程',
  ph_co2_precision: '典型精確度',
  ph_co2_precision_value: '測量值 ±15%',
  ph_co2_temp_value2: '±0.5℃',
  ph_co2_hum_value2: '±5%RH',
  ph_co2_temp_value2_2: '@0 ~ 50℃',
  ph_co2_hum_value2_2: '@10% ~ 90%RH',
  ph_co2_seven_title: '大容量可充電鋰電池，USB-C 接口',
  ph_co2_seven_desc: '支持長期連接 USB 電源。',
  ph_co2_eight_interval: 'CO₂ 檢測間隔',
  ph_co2_nine_title: '長距離無線通信方案，三選一',
  ph_co2_nine_desc: ' ',
  ph_co2_nine_lora: '需配備 LoRaWAN 網關或基站。',
  ph_co2_nine_nb: '需當地中國移動提供相關網絡服務*。',
  ph_co2_nine_memo:
    '* 設備出廠內置 SIM 卡，含 6 年中國移動 NB-IoT 流量資費（限 50 MB/年）。不同批次所包含的資費可能不同。',
  ph_co2_eleven_title: '讀數上報、記錄間隔，可自由設置',
  ph_co2_eleven_desc: '自定義記錄間隔',
  ph_co2_eleven_memo:
    '讀數上報間隔： 最短 10 分鐘、最長 24 小時；{br} 讀數記錄間隔：最短 1 分鐘、最長 60 分鐘。',
  ph_co2_twelve_title: '警報功能，多重安全保障',
  ph_co2_twelve_desc:
    '當 CO₂ 、溫濕度讀數達到設置的閾值，或設備離線、電池電量過低時，發送警報通知。',
  ph_co2_twelve_icon1: '蜂鳴警報*',
  ph_co2_twelve_icon1_desc:
    '* 產品內置蜂鳴器，您可通過青萍物聯設置讀數超限時發出蜂鳴警報。',
  ph_co2_twelve_icon2: 'App 推送',
  ph_co2_twelve_icon3: '電話',
  ph_co2_twelve_icon4: '短信',
  ph_co2_twelve_icon5: '郵件',
  ph_co2_thirteen_title: '意外斷網，數據不丟失',
  ph_co2_thirteen_subtitle:
    '設備自身可存儲 2,880 組* 數據，意外斷網也無需擔心數據丟失，網絡恢復後可自動同步數據至雲端。',
  ph_co2_thirteen_desc:
    '* 按 15 分鐘記錄一組數據計算，設備可在本地存儲 30 天的數據。',
  ph_co2_fourteen_title: '完善的第三方開發文檔和 API，支持二次開發',
  ph_co2_fifteen_title: '方便且美觀的懸掛方案',

  //pheasant-co2 mobile
  ph_co2_first_desc_m_1: '高精度傳感器 · 无线連接',
  ph_co2_first_desc_m_2: '遠程監測 · 超限及時通知',
  ph_co2_fourteen_title_m: '完善的第三方開發文檔和 API{br}支持二次開發',

  //Gecko
  gecko_title: '青萍牆壁插座式溫度計',
  gecko_name: '青萍牆壁插座式溫度計',
  gecko_desc: '牆壁接線盒安裝 · NB-IoT 聯網 · 精準測量溫度',
  gecko_desc_m1: '牆壁接線盒安裝 · NB-IoT 聯網',
  gecko_desc_m2: '精準測量溫度',
  gecko_desc_m3: '',
  gecko_second_title: '多場景專業應用',
  gecko_second_desc: '可持續監測、採集和上報環境溫度，{br}應用場景廣泛。 ',
  gecko_second_house: '住宅',
  gecko_second_mall: '商場',
  gecko_second_hotel: '酒店',
  gecko_second_hospital: '醫院',
  gecko_second_warehouse: '倉庫',
  gecko_second_factory: '工廠',
  gecko_third_title: '安裝便捷，穩定可靠',
  gecko_third_desc:
    '牆壁接線盒安裝，可直接替代已有 86 盒{br}型牆壁插座，不影響室內人員日常使用電源。位置固定，不用擔心被移動。 ',
  gecko_fourth_title: '市電供電，無需電池',
  gecko_fourth_desc: '通過牆壁接線盒直接取電，不用操心更換電池問題。 ',
  gecko_fifth_title: '雙傳感器設計，真實反應環境溫度',
  gecko_fifth_desc:
    '內置兩枚瑞士 Sensirion 高精度溫度傳感器，通過算法處理，可有效減少負載引起的產品內部發熱對環境溫度檢測準確性的影響。 ',

  gecko_table_title: '青萍牆壁插座式溫度計',
  gecko_table_a: '典型精確度  ',
  gecko_table_b: '有負載 ±0.5℃ {br}無負載 ±0.3℃ {br}（@0 ~ 50°C）',
  gecko_table_c: '分辨力',
  gecko_table_d: '0.1℃',
  gecko_table_e: '量程',
  gecko_table_f: '-20 ~ 50℃ ',
  gecko_sixth_title: 'NB-IoT 聯網，無需配置 Wi-Fi',
  gecko_sixth_desc: '內置中國移動 NB-IoT SIM 卡{br}，信號穩定、低功耗。 ',
  gecko_seventh_title: '遠程監控，高效管理',
  gecko_seventh_desc: '通過“青萍物聯”，可進行設備管理、數據收集、數據查詢。 ',
  gecko_seventh_app_a: '可通過 “青萍物聯” ',
  gecko_seventh_app_b: 'app',
  gecko_seventh_app_c: ' 或 ',
  gecko_seventh_app_d: '網站',
  gecko_seventh_app_e: '遠程監控',
  gecko_eighth_title: '警報功能，多重安全保障',
  gecko_eighth_desc: '當溫度讀數達到設置的閾值或設備離線時，發送警報通知。 ',
  gecko_eighth_t1: 'App 推送',
  gecko_eighth_t2: '電話',
  gecko_eighth_t3: '短信',
  gecko_eighth_t4: '郵件',
  gecko_nineth_title: '讀數上報、記錄間隔，可自由設置',
  gecko_nineth_desc:
    '讀數上報間隔：最短 1 小時，最長 24 小時。{br}讀數記錄間隔：最短 1 分鐘，最長 60 分鐘。 ',
  gecko_tenth_title: '數據曲線，清晰可見',
  gecko_tenth_desc: '可查看 24 小時、30 天歷史數據曲線，時間範圍可選。 ',
  gecko_eleventh_title: '可導出歷史數據文檔',
  gecko_eleventh_desc: '透過電子郵件，發送 Excel 文件。 ',
  gecko_twelfth_title: '意外斷網，數據不丟失',
  gecko_twelfth_desc:
    '設備自身可儲存 2,880 組{br}數據，意外斷網也無需擔心數據丟失，網絡恢復後可自動同步數據至雲端。 ',
  gecko_thirtyth_title: '完善的開發者文檔和 API，支持二次開發',
  gecko_thirtyth_desc: '青萍開發者平台：{link}',
  gecko_fourteenth_title: '雙插座，五孔大間距',
  gecko_fourteenth_desc:
    '兩孔和三孔插座之間的距離為 21 毫米，滿足不同尺寸插頭使用。 ',
  gecko_fifteenth_title: '阻燃 PC 材料，安全耐用',
  gecko_fifteenth_desc: '插座材料具有出色的阻燃性、絕緣性，不易變形、褪色。 ',

  gecko_size: '86 × 86 × 38.5 毫米（牆外 11.5 毫米）',
  gecko_color: '白色',
  g_distanse: '安裝孔距',
  gecko_distanse: '64 毫米',
  gecko_temp_range: '-20 ~ 50℃',
  gecko_invironment:
    ' 本產品無防水功能。請勿長時間在濕度超過 90%RH 的環境下使用。 ',
  gecko_model: 'CGGK1',
  gecko_weight: '126.8 克',
  gecke_screen_type: '屏幕規格',
  gecko_screen_size: '段碼 LCD 屏，30 × 19 毫米',
  gecko_rated_input: '額定電壓',
  gecko_rated_value: '250V 交流電',
  gecko_i_input: '額定電流',
  gecko_i_value: '最大 10A',
  gecko_network_type: '中國移動 NB-IoT',
  gecko_material: '材質',
  gecko_material_type: '阻燃 PC',

  gecko_explain_01: '86盒是一種接線盒規格，尺寸為 86 × 86 毫米。',
  gecko_explain_02:
    '環境溫度為 0 ~ 50°C時，在有負載情况下，感測器量測精確度為 ±0.8°C；在無負載情况下，感測器量測精確度為 +0.5°C。',
  gecko_explain_03:
    '需當地中國移動提供相關網路服務，應用環境訊號覆蓋良好。包含 6 年流量費（50MB/年）。不同批次附贈的流量可能不同。',
  gecko_explain_04:
    '按 15 分鐘採集一組數據計算，設備可在本地儲存 30 天的數據。',

  // snow2
  sw2: '青萍空氣檢測儀 2',
  sw2_first_title: '青萍空氣檢測儀 2',
  sw2_first_desc_1: '7 項讀數 · 可更換 PM 傳感器 · 智能聯動 · 超清觸摸屏 · Wi-Fi 連接',
  sw2_first_desc_mob: '7 項讀數 · 可更換 PM 傳感器 {br} 智能聯動 · 超清觸摸屏 · Wi-Fi 連接',
  sw2_second_title: '檢測更全面',
  sw2_second_desc: '精準檢測 7 項數據',
  sw2_second_temp: '溫濕度',
  sw2_second_co2: 'CO2',
  sw2_second_pm25: 'PM2.5 {br}和 PM10',
  sw2_second_tvoc: 'eTVOC{sup}',
  sw2_second_noise: '噪音',
  sw2_second_memo1:
    '溫濕度不僅事關舒適性，也和健康息息相關。處於溫濕度不佳的環境，可能引發睡眠問題，甚至過敏反應或呼吸道疾病等。',
  sw2_second_memo2:
    '室內 CO2 濃度過高，容易使人困倦，也意味著通風不良、空氣中其他汙染物的積累。',
  sw2_second_memo3:
    '粒徑小、活性強，易附著有害物質，被人吸入後，可能對呼吸系統和心血管系統產生不良影響。',
  sw2_second_memo4:
    '包含上千種化合物，其中部分物質可能有刺激性氣味、部分物質可能對⼈體健康有害。',
  sw2_second_memo5:
    '噪音水平對人的睡眠質量和身體健康有影響。持續的噪音可能導致失眠、焦慮和壓力增加。長時間暴露在噪音過高的環境會引起聽力問題，甚至耳聾。',
  sw2_third_title: '關註室內環境，適時采取改善措施',
  sw2_third_desc_1:
    '根據 CO2 和 eTVOC 濃度適時開窗或打開新風機，提升學習和工作效率。',
  sw2_third_desc_2: '根據 PM 濃度智能聯動空氣凈化設備，減少顆粒物汙染。',
  sw2_third_desc_3:
    '根據溫濕度聯動空調、暖氣、加濕器、除濕機等設備，營造健康舒適環境。',
  sw2_fourth_title: '監測數據，一目了然',
  sw2_fourth_desc_1: '實時數據查看',
  sw2_fourth_desc_1_memo: '7 項讀數每秒更新。',
  sw2_fourth_desc_2: '顏色分級指示',
  sw2_fourth_desc_2_memo: '通過屏幕上的顏色分級指示，快速了解室內環境情況。',
  sw2_fourth_desc_3: '歷史數據圖表',
  sw2_fourth_desc_3_memo: '點擊屏幕可查看 24 小時、30 天的歷史數據。',
  sw2_fourth_desc_4: '室外空氣情況',
  sw2_fourth_desc_4_memo:
    '天氣預報 · 室外空氣質量（AQI）· 紫外線指數 · 限行車牌號',
  sw2_fifth_title: '鋁鎂合金機身，質感超群',
  sw2_fifth_desc: '黑色 {spot} 白色',
  sw2_sixth_title: '4 英寸「視網膜級」超{br}清 IPS 屏',
  sw2_sixth_desc:
    '細膩度媲美高端手機，可視角度大，對比度高。18° 仰{br}角設計，查看、操作更方便。',
  sw2_sixth_spec_1: '254 ppi {spot} 電容式觸摸屏 {spot} 亮度自動調節',
  // sw2_sixth_spec_2: '電容式觸摸屏',
  // sw2_sixth_spec_3: '亮度自動調節',
  sw2_seventh_title: '手機式觸摸操作',
  sw2_seventh_desc: '支持點觸和滑動，操作很直觀。',
  sw2_eight_title: '時鐘 · 鬧鐘',
  sw2_eight_desc_1: '自動對時',
  sw2_eight_desc_2: '多組鬧鐘',
  sw2_eight_desc_3: '稍後提醒',
  sw2_eight_desc_4: '自定義鈴聲',
  sw2_ninth_title: '監測精準靈敏',
  sw2_ninth_desc: '響應快，測量準，長期使用不漂移。',
  sw2_ninth_memo1: '四核 A53 處理器',
  sw2_ninth_memo2: '多模式補償算法',
  sw2_ninth_memo3: '多種高精度傳感器',
  sw2_tenth_title: '彈出式 PM 傳感器，可輕松更換',
  sw2_tenth_desc:
    '采用高品質光學器件，搭配磁懸浮風扇，準確性、可靠性俱佳。PM 傳感器與機身通過觸點連接，後蓋與機身通過磁吸固定{sup}，便於更換。',
  sw2_eleventh_title: '創新的通風孔設計{comma}{br}傳感器工作更高效',
  sw2_eleventh_desc: '多面開孔 {spot} 風道更科學 {spot} 測量更準確 {br} 工作更安靜',

  sw2_eleventh_memo: '讓多個傳感器充分接觸空氣，{br}日常使用中不易被遮擋。',
  sw2_twelveth_title: '聯動米家{sup}智能設備',
  sw2_twelveth_desc:
    '遠程查看數據，設置與米家智能設備的聯動。如 PM2.5 超過 50，{br}打開空氣凈化器；濕度低於 30%，打開加濕器。',
  sw2_thirteenth_title: '支持導出歷史數據',
  sw2_thirteenth_desc:
    '通過青萍+ app 可隨時隨地查看各項讀數、行動建議。{br}支持導出歷史數據的 Excel 文件。',
  sw2_fourteenth_title: 'USB-C 口，可充電鋰電池',
  sw2_fourteenth_desc:
    '支持長期連接 USB 電源。內置 1800mAh 鋰電池，續航時間達 4 小時。',
  sw2_fifteenth_title: '功能可不斷升級演進',
  sw2_fifteenth_desc:
    '和智能手機一樣，可通過 OTA 升級來解決 bug、{br}優化功能，甚至增加新功能。',
  sw2_sixteenth_memo_1:
    '本產品采用的 TVOC 傳感器會根據過去一段時間的讀數水平自動調整測量基線，故本產品顯示的是 shi eTVOC （即「TVOC 等效值」），並非準確的 TVOC 絕對讀數，僅供用戶觀察環境 TVOC 濃度的相對變化趨勢。',
  sw2_sixteenth_memo_2:
    '天氣預報、室外空氣質量（AQI）、紫外線指數、限行車牌號等信息僅限部分城市提供。',
  sw2_sixteenth_memo_3: 'Wi-Fi 連接，自動同步網絡時間。',
  sw2_sixteenth_memo_4:
    '可設置多組鬧鐘，應對日常事項提醒。每組鬧鐘可單獨設置稍後提醒功能和重復規則。',
  sw2_sixteenth_memo_5:
    '鬧鈴響起後，點擊屏幕上的「稍後提醒」或點擊屏幕頂部按鍵，進入稍後提醒模式，10 分鐘後將再次響起；點擊屏幕上的「停止」即可取消。',
  sw2_sixteenth_memo_6:
    '青萍空氣檢測儀 2 自帶 8 种鈴聲。通過',
  sw2_sixteenth_memo_6_1: '青萍+ app ',
  sw2_sixteenth_memo_6_2: '可上傳其他鈴聲（此功能暫未上線）。',
  sw2_sixteenth_memo_7: '青萍空氣檢測儀 2 內置磁鐵，後蓋內置鐵片。',
  sw2_sixteenth_memo_8: '本產品支持米家的中國大陸服務器。',

  sw2_pm25_range: '0 ~ 999 μg/m³',
  sw2_temp_range: '-10 ~ 60℃',
  sw2_hum_range: '0 ~ 99%RH（無凝露環境）',
  sw2_tvoc_range: '0.005 ~ 9.999 mg/m³',
  sw2_co2_range: '400 ~ 9999 ppm',
  sw2_etvoc_range: 'VOC 指數 0 ～ 500，或 0.005 ～ 9.999 mg/m³',
  sw2_noise_range: '36 ~ 95 dB',
  sw2_size: '尺寸',
  sw2_color: '白色、黑色',
  sw2_size_value_long: '85 毫米',
  sw2_size_value_short: '104 毫米',
  sw2_size_value_height: '74 毫米',
  sw2_size_value: '74 × 85 × 104 毫米',
  sw2_weight: '重量',
  sw2_weight_value: '250 克',
  sw2_screen_size_value: '4 英寸',
  sw2_screen_resolution: '屏幕分辨率',
  sw2_screen_resolution_value: '720 × 720',
  sw2_wifi_value: 'IEEE 802.11a/b/g/n/ac/ax',
  sw2_battery_type_value: '鋰離子電池',
  sw2_battery_size: '電池容量',
  sw2_battery_size_value: '1800mAh/3.7V',
  sw2_usb_port: '電源接口',
  sw2_usb_port_value: 'USB-C',
  sw2_rated_input: '額定輸入',
  sw2_rated_input_value: '5V⎓1A',
  sw2_usb_charge: 'USB-C 充電線',


  //snow2
  snow2_exterior: '外觀',
  snow2_range: '量程',
  snow2_spec: '規格',
  snow2: '青萍空氣檢測儀 2',
  temp_range_snow2: '-10 ~ 60°C',
  hum_range_snow2: '0 ~ 99%RH（無凝露環境）',
  co2_range_snow2: '400 ~ 9999 ppm',
  pm25_pm10: 'PM2.5/PM10',
  pm25_pm10_range_snow2: '0 ~ 999 μg/m³',
  etvoc: 'eTVOC',
  etvoc_range_snow2: 'VOC 指數 0 ~ 500，或 0.005 ~ 9.999 mg/m ³',
  noise: '譟音',
  noise_range_snow2: '36 ~ 95 dB',

}

export default {
  ...zhTW,
  ...supportMi
}

