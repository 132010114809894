import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Psixth.module.scss'

class Psixth extends React.PureComponent {
  static handleClick() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
    // this.refs.Psixth.style.backgroundSize = '1920px';
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Psixth.getBoundingClientRect()
    if (this.refs.Psixth && clientHeight >= textRect.bottom - 100) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 0)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div className={s.Psixth} ref="Psixth">
        <div className={s.wrap}>
          <div className={s.alltext} ref="text">
            <Link to="/qingpingiot" onClick={() => Psixth.handleClick()}>
              <div className={s.text}>
                <i className={s.p_sixth_logo} />
                <span className={s.large}>
                  <FormattedMessage id="qing_ping_iot" />
                </span>
              </div>
            </Link>
            <div className={s.dec}>
              <p>
                <FormattedMessage id="wulian_desc" values={{ br: <br /> }} />
              </p>
            </div>
          </div>
          <div
            className={
              locale.indexOf('US') > -1
                ? s.imgList_en
                : locale.indexOf('TW') > -1
                ? s.imgList_tw
                : s.imgList
            }
            ref="big"
          ></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PSixth = connect(mapStateToProps)(Psixth)

export default PSixth
