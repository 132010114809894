import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import s from './Sseventh.module.scss'

class Sseventh extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    if (this.refs.video) {
      this.play()
    }
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Sseventh.getBoundingClientRect()
    if (this.refs.Sseventh && clientHeight >= textRect.bottom - 300) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }

  play() {
    const element = this.refs.video
    element.play()
  }

  render() {
    return (
      <div className={s.Sseventh} ref="Sseventh">
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <div className={s.title}>
              <FormattedMessage id="chicken_sixth_title" values={{ br: <br /> }} />
              <sup className={s.footnote}>
                  <a href="#footnote-5" aria-label="脚注5">
                    5
                  </a>
                </sup>
                <FormattedMessage id="break" />
              <br />
              <FormattedMessage id="chicken_sixth_title1" values={{ br: <br /> }} />
            </div>
            <div className={s.desc}>
              <FormattedMessage id="chicken_sixth_desc" values={{ br: <br /> }} />
            </div>
            <div className={s.level}>
              <div className={s.level_item}>
                <i className={s.item_icon1}></i>
                <div className={s.item_text}>
                  <FormattedMessage id="chicken_sixth_level1" />
                </div>
              </div>
              <div className={s.level_item}>
                <i className={s.item_icon2}></i>
                <div className={s.item_text}>
                  <FormattedMessage id="chicken_sixth_level2" />
                </div>
              </div>
              <div className={s.level_item}>
                <i className={s.item_icon3}></i>
                <div className={s.item_text}>
                  <FormattedMessage id="chicken_sixth_level3" />
                </div>
              </div>
            </div>
          </div>
          <div className={s.imgBox}>
            <div className={s.img}></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SSeventh = connect(mapStateToProps)(Sseventh)

export default SSeventh
