import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Gfirst.module.scss'

class Gfirst extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      video: null,
    }
  }
  componentDidMount() {
    // 添加文字显示动画，可添加任何样式 如：opacity, left
    if (this.refs.Dfirst) {
      const clientHeight = document.documentElement.clientHeight
      // const clientWidth = document.documentElement.clientWidth;
      this.refs.Dfirst.style.height = `${clientHeight - 96}px`
      // this.refs.bg.style.width = `${clientWidth}px`;
      // this.refs.bg.style.height = `${clientHeight}px`;
      // this.initVideo(clientWidth, clientHeight);
    }
  }
  initVideo(screenWidth, screenHeight) {
    const video = document.createElement('VIDEO')
    const sourceAgg = document.createElement('source')
    const sourceWebm = document.createElement('source')
    const sourceMp4 = document.createElement('source')
    sourceAgg.setAttribute(
      'src',
      'http://cdn.moji.com/websrc/video/autumn_20180914.ogg'
    )
    sourceAgg.setAttribute('type', 'video/ogg')
    sourceWebm.setAttribute(
      'src',
      'http://cdn.moji.com/websrc/video/autumn_20180914.webm'
    )
    sourceWebm.setAttribute('type', 'video/webm')
    sourceMp4.setAttribute(
      'src',
      'http://cdn.moji.com/websrc/video/autumn_20180914.mp4'
    )
    sourceMp4.setAttribute('type', 'video/mp4')
    video.setAttribute('width', screenWidth)
    video.setAttribute('height', screenHeight)
    // video.setAttribute('style', 'position: absolute;left:50%;margin-left:' + -(screenWidth / 2) + 'px;');
    // video.setAttribute('loop', 'loop');
    video.setAttribute('autoPlay', 'autoplay')
    // video.setAttribute('muted', 'muted');
    // $(video).css({ 'object-fit': 'fill' });
    video.appendChild(sourceMp4, sourceAgg, sourceWebm)
    this.setState({
      video: video,
    })
  }
  render() {
    const { locale } = this.props
    return (
      <div className={s.Dfirst} ref="Dfirst">
        <div className={s.text}>
          <div className={s.large}>
            <i className={s.videoIcon} />
            <span>
              <FormattedMessage id="home_text" values={{ br: <br /> }} />
            </span>
          </div>
          <div className={s.little}>
            <FormattedMessage id="goose_screen" />
            <span className={s.line}>·</span>
            <FormattedMessage id="sensor" />
            <span className={s.line}>·</span>
            <FormattedMessage id="wall_model" />
            <span className={s.line}>·</span>
            <FormattedMessage id="thirdth_title" />
          </div>
        </div>
        <div className={s.goose_bottom}>
          <div className={s.bottom_back}></div>
          <a className={s.icon_box} href="#homekit">
            <div className={s.icon_title}>
              <FormattedMessage id="t_version" /> /{' '}
              <FormattedMessage id="h_version" />
            </div>
            <div className={s.homekit_icon}></div>
          </a>
          <a className={s.icon_box} href="#mi">
            <div className={s.icon_title}>
              <FormattedMessage id="m_version" />
            </div>
            <div
              className={locale.indexOf('US') > -1 ? s.mi_icon_en : s.mi_icon}
            ></div>
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let GFirst = connect(mapStateToProps)(Gfirst)

export default GFirst
