import React from 'react';

import s from './Dfirst.module.scss';

const Dfirst = () =>
    <div className={s.Dfirst}>
        <div className={s.text}>
            <div className={s.large}>米家蓝牙温湿度计</div>
            <div className={s.little}>高灵敏度传感器<span className={s.line}>·</span>LCD 屏幕<br />磁吸墙贴<span className={s.line}>·</span>超低功耗</div>
            <div className={s.normal}>冷暖干湿，一目了然</div>
            <div className={s.pirce}>
                <a href="https://item.mi.com/product/7303.html" target="_blank" rel="noopener noreferrer">
                    <span>69</span>元
                </a>
            </div>
        </div>
    </div>;

export default Dfirst;

