import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Param.module.scss'

class Param extends React.PureComponent {
  render() {
    return (
      <div className={s.box}>
        <div className={s.param}>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="exterior" />
            </div>
            <div className={s.cell}>
              <div className={s.sizeImg}>
                <div className={s.top_side}>
                  <div className={s.left_left}>
                    <div className={s.left_img}></div>
                    {/* <div className={s.left_bottom}></div> */}
                    <div className={s.bottom_num}>
                      <FormattedMessage id="ph_co2_size_long" />
                    </div>
                  </div>
                </div>
                <div className={s.top_top}>
                  <div className={s.top_img}></div>
                  {/* <div className={s.top_bottom}></div> */}
                  <div className={s.bottom_num}>
                    <FormattedMessage id="ph_co2_size_height" />
                  </div>
                </div>
              </div>
              {/* <div className={s.P_sizeImg} /> */}
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_size" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_co2_size_value" />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_color" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_color_value" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="range" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="ph_co2" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_co2_value" />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_temp" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_co2_temp_value" />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_hum" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_co2_hum_value" />
                  </div>
                </li>
              </ul>
              <p>
                <FormattedMessage id="under_ninty" />
              </p>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="other_specs" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="ph_name" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage
                      id="ph_co2_name_value"
                      values={{ br: '' }}
                    />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_weight" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_weight_value" />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="screen_size" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_co2_screen_value" />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_wifi" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_co2_wifi_value" />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="only_bluetooth" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_bluetooth_value" />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_battery" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_battery_value" />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="s_battery_size" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_co2_battery_value" />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="usb_port" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_usb_value" />
                  </div>
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="rated_input" />
                  </span>
                  <div className={s.second_part}>
                    <FormattedMessage id="ph_input_value" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className={s.pack}>
            <div className={s.name}>
              <FormattedMessage id="p_pack" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <i className={s.pack_1} />
                  <FormattedMessage id="ph_product" />
                </li>
                <li>
                  <i className={s.pack_2} />
                  <FormattedMessage id="p_tenth_desc_1" />
                </li>
                <li>
                  <i className={s.pack_3} />
                  <FormattedMessage id="p_instructions" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SParam = connect(mapStateToProps)(Param)

export default SParam
