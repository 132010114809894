import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Ssixth.module.scss'

class Ssixth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Ssixth.getBoundingClientRect()
    if (this.refs.Ssixth && clientHeight >= textRect.bottom - 100) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div ref="Ssixth">
        <div className={s.Ssixth} id="topnote-1">
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              <div
                className={
                  locale.indexOf('US') > -1 || locale.indexOf('TW') > -1
                    ? s.mi_en
                    : s.mi
                }
              ></div>
              <div className={s.title}>
                <FormattedMessage id="dk2_five_title" values={{ br: <br /> }} />
                <sup className={s.footnote}>
                  <a href="#footnote-1" aria-label="脚注1">
                    1
                  </a>
                </sup>
              </div>
              <div className={s.desc}>
                <FormattedMessage id="dk2_five_desc" values={{ br: <br /> }} />
                <sup className={s.footnote}>
                  <a href="#footnote-2" aria-label="脚注2">
                    2
                  </a>
                </sup>
                <FormattedMessage id="end" />
              </div>
              <div className={s.pic}>
                <div className={s.left}>
                  <div className={s.left_title}>
                    <FormattedMessage id="dk2_five_left_title" />
                    <sup className={s.footnote}>
                      <a href="#footnote-3" aria-label="脚注3">
                        3
                      </a>
                    </sup>
                    <FormattedMessage id="end" />
                  </div>
                  <div className={s.left_icons}>
                    <div className={s.item1}>
                      <div className={s.item1_icon}></div>
                      <div className={s.item1_desc}>
                        <FormattedMessage id="dk2_five_left_item1" />
                      </div>
                    </div>
                    <div className={s.plus}></div>
                    <div className={s.item2}>
                      <div className={s.item2_icon}></div>
                      <div className={s.item2_desc}>
                        <FormattedMessage id="dk2_five_left_item2" />
                      </div>
                    </div>
                    <div className={s.plus}></div>
                    <div className={s.item3}>
                      <div className={s.item3_icon}></div>
                      <div className={s.item3_desc}>
                        <FormattedMessage id="dk2_five_left_item3" />
                        <sup className={s.footnote}>
                          <a href="#footnote-3" aria-label="脚注3">
                            3
                          </a>
                        </sup>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={s.right}>
                  <div className={s.left_title}>
                    <FormattedMessage id="dk2_five_right_title" />
                    <sup className={s.footnote}>
                      <a href="#footnote-4" aria-label="脚注4">
                        4
                      </a>
                    </sup>
                    <FormattedMessage id="end" />
                  </div>
                  <div className={s.right_icons}>
                    <div className={s.item1}>
                      <div className={s.item1_icon}></div>
                      <div className={s.item1_desc}>
                        <FormattedMessage id="dk2_five_left_item1" />
                      </div>
                    </div>
                    <div className={s.plus}></div>
                    <div className={s.item2}>
                      <div className={s.item2_icon}></div>
                      <div className={s.item2_desc}>
                        <FormattedMessage id="dk2_five_left_item2" />
                      </div>
                    </div>
                    <div className={s.plus}></div>
                    <div className={s.item3}>
                      <div className={s.item3_icon}></div>
                      <div className={s.item3_desc}>
                        <FormattedMessage id="dk2_five_right_item4" />
                        <sup className={s.footnote}>
                          <a href="#footnote-3" aria-label="脚注3">
                            3
                          </a>
                        </sup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SSixth = connect(mapStateToProps)(Ssixth)

export default SSixth
