import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import LeeGuitarsApp from './../components/pages/pc/index/lee'
import Index from './../components/pages/pc/index/index'
import Test from './../components/pages/pc/test/index'
import Dstyle from './../components/pages/pc/mi_temp_rh_monitor/Dstyle'
import Dspecifications from './../components/pages/pc/mi_temp_rh_monitor/Dspecifications'
import Gstyle from './../components/pages/pc/cg_temp_rh_monitor/Gstyle'
import Gspecifications from './../components/pages/pc/cg_temp_rh_monitor/Gspecifications'
import Lstyle from './../components/pages/pc/lee_temp_rh_monitor/Lstyle'
import Lspecifications from './../components/pages/pc/lee_temp_rh_monitor/Lspecifications'
import privacy from './../components/pages/pc/privacy'
import Bprivacy from './../components/pages/pc/Bprivacy'
import Air from './../components/pages/pc/air'
import QingpingPlus from './../components/pages/pc/qingping_plus'
import Pstyle from './../components/pages/pc/lora_temp_rh_monitor/Pstyle'
import Cstyle from './../components/pages/pc/co2_temp_rh_monitor/Pstyle'
import GeckoStyle from './../components/pages/pc/gecko_wall/overview'
import GeckoSpecifications from './../components/pages/pc/gecko_wall/specifications'

import Pspecifications from './../components/pages/pc/lora_temp_rh_monitor/Pspecifications'
import Cspecifications from './../components/pages/pc/co2_temp_rh_monitor/Pspecifications'
import Fstyle from './../components/pages/pc/frog/Pstyle'
import Fspecifications from './../components/pages/pc/frog/Pspecifications'
import Sstyle from './../components/pages/pc/air_monitor/Sstyle'
import Sspecifications from './../components/pages/pc/air_monitor/Sspecifications'
// import Snow2style from './../components/pages/pc/snow2/Sstyle'
// import Snow2specifications from './../components/pages/pc/snow2/Sspecifications'
import Snowprostyle from './../components/pages/pc/snowpro/overview'
import Snowprospecifications from './../components/pages/pc/snowpro/specifications'
import Danystyle from './../components/pages/pc/air_monitor_lite/Sstyle'
import Danyspecifications from './../components/pages/pc/air_monitor_lite/Sspecifications'
import Dk2style from './../components/pages/pc/temp_rh_monitor_lite/Sstyle'
import Dk2specifications from './../components/pages/pc/temp_rh_monitor_lite/Sspecifications'
import Hodorstyle from './../components/pages/pc/hodor/Sstyle'
import Hodorspecifications from './../components/pages/pc/hodor/Sspecifications'
import Parrotstyle from './../components/pages/pc/parrot/Sstyle'
import Parrotspecifications from './../components/pages/pc/parrot/Sspecifications'
import Chickenstyle from './../components/pages/pc/chicken/Sstyle'
import Chickenspecifications from './../components/pages/pc/chicken/Sspecifications'
import Bran from './../components/pages/pc/mi_air_monitor'
import Store from './../components/pages/pc/store'
import AllPrivacy from './../components/pages/pc/all_privacy'
import AboutUs from './../components/pages/pc/about_us'
import PresetApps from './../components/pages/pc/preset_apps'
import qingpingiot from './../components/pages/pc/qing_ping_iot'
import Spstyle from './../components/pages/pc/sparrow/Overview'
import Spspecifications from './../components/pages/pc/sparrow/Specifications'
import doveOverview from './../components/pages/pc/dove/overview'
import doveSpecifications from './../components/pages/pc/dove/specifications'
import Clock from './../components/pages/pc/bluetooth_alarm_clock'
import Share from './../components/pages/m2p/share'
import ContactUs from './../components/pages/m2p/contact'
import AirPrivacy from './../components/pages/mobile/air_privacy'
// import AirPrivacy from './../components/pages/m2p/air_privacy'
import LeeGuitarsPrivacy from './../components/pages/mobile/lee_guitars_privacy'
import iotPrivacy from './../components/pages/mobile/iot_privacy'
import AirAgreement from './../components/pages/m2p/air_agreement'
import GPrivacy from './../components/pages/m2p/Gprivacy'
import SPrivacy from './../components/pages/m2p/Sprivacy'
import HPrivacy from './../components/pages/m2p/Hprivacy'
import PPrivacy from './../components/pages/m2p/Pprivacy'
import DNprivacy from './../components/pages/m2p/DanyPrivacy'
import DPrivacy from './../components/pages/m2p/Dprivacy'
import DovePrivacy from './../components/pages/m2p/DovePrivacy'
import SnowPrivacy from './../components/pages/m2p/SnowPrivacy'
import ChickenPrivacy from './../components/pages/m2p/ChickenPrivacy'
import MiTranslator from './../components/pages/pc/mi_translator'
import EuDoc from './../components/pages/pc/eu_doc'

import MagpieEuDoc from './../components/pages/pc/eu_doc_pages/magpie'
import DanyEuDoc from './../components/pages/pc/eu_doc_pages/dany'
import ChickenEuDoc from './../components/pages/pc/eu_doc_pages/chicken'
import DoveEuDoc from './../components/pages/pc/eu_doc_pages/dove'
import SparrowEuDoc from './../components/pages/pc/eu_doc_pages/sparrow'
import HodorEuDoc from './../components/pages/pc/eu_doc_pages/hodor'
import ParrotEuDoc from './../components/pages/pc/eu_doc_pages/parrot'
import GooseHEuDoc from './../components/pages/pc/eu_doc_pages/goose_h'
import Duck2EuDoc from './../components/pages/pc/eu_doc_pages/duck2'
import Duck2CEuDoc from './../components/pages/pc/eu_doc_pages/duck2_c'
import GooseMEuDoc from './../components/pages/pc/eu_doc_pages/goose_m'
import FrogEuDoc from './../components/pages/pc/eu_doc_pages/frog'
import SnowEuDoc from './../components/pages/pc/eu_doc_pages/snow'

const Routers = () => (
  <Switch>
    <Route path="/lee-guitars/app" exact component={LeeGuitarsApp} />
    <Route path="/" exact component={Index} />
    <Route path="/Test" exact component={Test} />
    <Route
      path="/mi-temp-rh-monitor"
      exact
      render={() => <Redirect to="/mi-temp-rh-monitor/overview" />}
    ></Route>
    <Route path="/mi-temp-rh-monitor/overview" exact component={Dstyle} />
    <Route
      path="/mi-temp-rh-monitor/specifications"
      exact
      component={Dspecifications}
    />
    <Route
      path="/temp-rh-monitor"
      exact
      render={() => <Redirect to="/temp-rh-monitor/overview" />}
    ></Route>
    <Route path="/temp-rh-monitor/overview" exact component={Gstyle} />
    <Route
      path="/temp-rh-monitor/specifications"
      exact
      component={Gspecifications}
    />
    <Route
      path="/lee-guitars-thermo-hygrometer"
      exact
      render={() => <Redirect to="/lee-guitars-thermo-hygrometer/overview" />}
    ></Route>
    <Route
      path="/lee-guitars-thermo-hygrometer/overview"
      exact
      component={Lstyle}
    />
    <Route
      path="/lee-guitars-thermo-hygrometer/specifications"
      exact
      component={Lspecifications}
    />
    <Route path="/mi-temp-rh-monitor/privacy" exact component={privacy} />
    <Route
      path="/mi-multifunction-air-monitor"
      exact
      render={() => <Redirect to="/mi-multifunction-air-monitor/privacy" />}
    ></Route>
    <Route
      path="/mi-multifunction-air-monitor/privacy"
      exact
      component={Bprivacy}
    />
    <Route path="/plus" exact component={Air} />
    <Route
      path="/apps"
      exact
      render={() => <Redirect to="/apps/qingpingplus" />}
    ></Route>
    <Route path="/apps/qingpingplus" exact component={QingpingPlus} />
    <Route path="/air" exact component={Air} />
    <Route
      path="/temp-rh-monitor-pro-s"
      exact
      render={() => <Redirect to="/temp-rh-monitor-pro-s/overview" />}
    ></Route>
    <Route path="/temp-rh-monitor-pro-s/overview" exact component={Pstyle} />
    <Route path="/co2-temp-rh-monitor/overview" exact component={Cstyle} />
    <Route
      path="/wall-socket-thermometer/overview"
      exact
      component={GeckoStyle}
    />
    <Route
      path="/wall-socket-thermometer/specifications"
      exact
      component={GeckoSpecifications}
    />
    <Route
      path="/temp-rh-monitor-pro-s/specifications"
      exact
      component={Pspecifications}
    />
    <Route
      path="/co2-temp-rh-monitor/specifications"
      exact
      component={Cspecifications}
    />
    <Route
      path="/temp-rh-monitor-pro-e"
      exact
      render={() => <Redirect to="/temp-rh-monitor-pro-e/overview" />}
    ></Route>
    <Route path="/temp-rh-monitor-pro-e/overview" exact component={Fstyle} />
    <Route
      path="/temp-rh-monitor-pro-e/specifications"
      exact
      component={Fspecifications}
    />
    <Route
      path="/air-monitor"
      exact
      render={() => <Redirect to="/air-monitor/overview" />}
    ></Route>
    <Route path="/air-monitor/overview" exact component={Sstyle} />
    <Route
      path="/air-monitor/specifications"
      exact
      component={Sspecifications}
    />
    <Route path="/air-monitor-2/overview" exact component={Snowprostyle} />
    <Route
      path="/air-monitor-2/specifications"
      exact
      component={Snowprospecifications}
    />
    {/* <Route path="/air-monitor-g3/overview" exact component={Snow2style} />
    <Route
      path="/air-monitor-g3/specifications"
      exact
      component={Snow2specifications}
    /> */}
    <Route
      path="/air-monitor-lite"
      exact
      render={() => <Redirect to="/air-monitor-lite/overview" />}
    ></Route>
    <Route path="/air-monitor-lite/overview" exact component={Danystyle} />
    <Route
      path="/air-monitor-lite/specifications"
      exact
      component={Danyspecifications}
    />
    <Route path="/temp-rh-monitor-lite/overview" exact component={Dk2style} />
    <Route
      path="/temp-rh-monitor-lite/specifications"
      exact
      component={Dk2specifications}
    />
    <Route path="/door-window-sensor/overview" exact component={Hodorstyle} />
    <Route
      path="/door-window-sensor/specifications"
      exact
      component={Hodorspecifications}
    />
    <Route path="/motion-light-sensor/overview" exact component={Parrotstyle} />
    <Route
      path="/motion-light-sensor/specifications"
      exact
      component={Parrotspecifications}
    />
    <Route path="/bluetooth-clock/overview" exact component={Chickenstyle} />
    <Route
      path="/bluetooth-clock/specifications"
      exact
      component={Chickenspecifications}
    />
    <Route path="/mi-air-monitor" exact component={Bran} />
    <Route path="/store" exact component={Store} />
    <Route path="/privacy" exact component={AllPrivacy} />
    <Route path="/about-us" exact component={AboutUs}></Route>
    <Route path="/iot" exact component={qingpingiot}></Route>
    <Route path="/qingpingiot" exact component={qingpingiot} />
    <Route
      path="/mi-translator"
      exact
      render={() => <Redirect to="/mi-translator/preset-apps" />}
    ></Route>
    <Route path="/mi-translator/preset-apps" exact component={PresetApps} />
    <Route
      path="/bluetooth-gateway"
      exact
      render={() => <Redirect to="/bluetooth-gateway/overview" />}
    ></Route>
    <Route path="/bluetooth-gateway/overview" exact component={Spstyle} />
    <Route
      path="/bluetooth-gateway/specifications"
      exact
      component={Spspecifications}
    />
    <Route
      path="/bluetooth-alarm-clock"
      exact
      render={() => <Redirect to="/bluetooth-alarm-clock/overview" />}
    ></Route>
    <Route
      path="/bluetooth-alarm-clock/overview"
      exact
      component={doveOverview}
    />
    <Route
      path="/bluetooth-alarm-clock/specifications"
      exact
      component={doveSpecifications}
    />
    <Route path="/bluetooth-alarm-clock/apps" component={Clock} exact />
    <Route path="/air-app-and-temp-rh-monitor" exact component={Share} />
    <Route path="/air-app-and-temp-rh-monitor" exact component={Share} />
    <Route path="/air-app-and-temp-rh-barometer" exact component={Share} />
    <Route path="/contact" exact component={ContactUs} />
    <Route path="/air/privacy" exact component={AirPrivacy} />
    <Route path="/lee-guitars/privacy" exact component={LeeGuitarsPrivacy} />
    <Route path="/iot/privacy" exact component={iotPrivacy} />
    <Route path="/plus/privacy" exact component={AirPrivacy} />
    <Route path="/air/agreement" exact component={AirAgreement} />
    <Route path="/temp-rh-monitor/privacy" exact component={GPrivacy} />
    <Route path="/bluetooth-gateway/privacy" exact component={SPrivacy} />
    <Route path="/door-window-sensor/privacy" exact component={HPrivacy} />
    <Route path="/motion-light-sensor/privacy" exact component={PPrivacy} />
    <Route path="/air-monitor-lite/privacy" exact component={DNprivacy} />
    <Route path="/temp-rh-monitor-lite/privacy" exact component={DPrivacy} />
    <Route path="/alarm-clock/privacy" exact component={DovePrivacy} />
    <Route path="/bluetooth-clock/privacy" exact component={ChickenPrivacy} />
    <Route path="/air-monitor/privacy" exact component={SnowPrivacy} />
    <Route path="/mi_translator/preset_apps" exact component={MiTranslator} />
    <Route path="/eu-declarations" exact component={EuDoc}></Route>

    <Route
      path="/lee-guitars-thermo-hygrometer/eu-declaration"
      exact
      component={MagpieEuDoc}
    ></Route>
    <Route
      path="/air-monitor-lite/eu-declaration"
      exact
      component={DanyEuDoc}
    ></Route>
    <Route
      path="/bluetooth-clock/eu-declaration"
      exact
      component={ChickenEuDoc}
    ></Route>
    <Route
      path="/bluetooth-alarm-clock/eu-declaration"
      exact
      component={DoveEuDoc}
    ></Route>
    <Route
      path="/bluetooth-gateway/eu-declaration"
      exact
      component={SparrowEuDoc}
    ></Route>
    <Route
      path="/door-window-sensor/eu-declaration"
      exact
      component={HodorEuDoc}
    ></Route>
    <Route
      path="/motion-light-sensor/eu-declaration"
      exact
      component={ParrotEuDoc}
    ></Route>
    <Route
      path="/temp-rh-monitor-h/eu-declaration"
      exact
      component={GooseHEuDoc}
    ></Route>
    <Route
      path="/temp-rh-monitor-lite/eu-declaration"
      exact
      component={Duck2EuDoc}
    ></Route>
    <Route
      path="/temp-rh-monitor-lite-c/eu-declaration"
      exact
      component={Duck2CEuDoc}
    ></Route>
    <Route
      path="/temp-rh-monitor/eu-declaration"
      exact
      component={GooseMEuDoc}
    ></Route>
    <Route
      path="/temp-rh-monitor-pro-e/eu-declaration"
      exact
      component={FrogEuDoc}
    ></Route>
    <Route
      path="/air-monitor/eu-declaration"
      exact
      component={SnowEuDoc}
    ></Route>
  </Switch>
)

export default Routers
