import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './../../header';
import Footer from './../../footer';
import Pfirst from './../Pfirst';
import Psecond from './../Psecond';
import Pthird from './../Pthird';
import Pfourth from './../Pfourth';
// import Pfifth from './../Pfifth';
import Psixth from './../Psixth';
import Pseventh from './../Pseventh';
import Peighth from './../Peighth';
import Pnineth from './../Pnineth';
import Ptenth from './../Ptenth';
import Ptimecalculator from './../Ptimecalculator'

import s from './Pstyle.module.scss';
import common from '../../../../../locale/common'
class Pstyle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      position: false,
      hideGooseChoice: true,
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['pheasant_' + local]
    
    let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US';
        break;
      case 'zh':
        browserLanguage = 'zh-CN';
        break;
      default:
        browserLanguage = 'en-US';
    }
    this.setState({
      language: browserLanguage
    });
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  handleScroll () {
    const textRect = this.refs.box.getBoundingClientRect();
    if (textRect.top < -50 && this.refs.box) {
      if(this.state.position) return
        this.setState({
          position: true
        });
      
    } else {
      if(!this.state.position) return
        this.setState({
          position: false
        });
      
    }
  }

  //隐藏购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if(isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }


  render () {
    const { hideGooseChoice } = this.state
    const { location } = this.props;
    const path = this.props.match.path;
    return (
      <div 
      className={s.box} 
      ref="box"
      onMouseOver={(e) => {
        e.persist()
        this.hideGooseChoiceFun(true, e)
      }}
      >
        <div className={s.Pstyle}>
          <Header 
          location={location} 
          language={this.state.language} 
          path={path} 
          navPosition={this.state.position} 
          hideGooseChoice={hideGooseChoice}
          hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
          />
          <Pfirst />
          <Psecond />
          <Pthird />
          <Pfourth />
          {/* <Pfifth /> */}
          <Ptimecalculator />
          <Psixth />
          <Pseventh />
          <Peighth />
          <Pnineth />
          <Ptenth />
          <Footer location={location} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let PStyle = connect(mapStateToProps)(Pstyle);

export default PStyle;
