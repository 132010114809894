import React, { Component } from 'react';

import s from './airPrivacy.module.scss'

class AirPrivacy extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // init top hide
            display: false
        };
    }
    componentDidMount() {
        document.getElementsByTagName('html')[0].style.fontSize = 41.4 + 'px'
    }
    render() {
        return (
            <div className={s.box}>
                <div className={s.Bprivacy}>
                    <p><b className={s.pt18}>PRIVACY POLICY</b></p>
                    <p><b className={s.pt12}>Updated! </b></p>
                    <p>Our privacy policy was updated on November 30, 2021. From this date on, this privacy policy can provide privacy details about how we manage the personal information you disclose when using LEE GUITARS products and services. Please take some time to familiarize yourself with our privacy practices. If you have any questions, please let us know.</p>
                    {/*<p>Please take a moment to familiarize yourself with our privacy practices and let us know if you have any questions. </p>*/}
                    <p><b  className={s.pt135}>We promise:</b></p>
                    <p>This privacy policy stipulates how Qingping Technology (Beijing) Co., Ltd. and its affiliated companies (hereinafter referred to as "Qingping" or "we") collect, use, disclose, process and protect the information you provide to us when using our products and services; If we ask you to provide some information to verify your identity when using Qingping products and services, we will strictly abide by this privacy policy and/or our user terms and conditions to use the information.</p>
                    <p>This privacy policy is formulated with your needs in mind; It is very important for you to fully understand our personal data collection and use practices and be sure that you can finally control the personal information provided to Qingping.</p>
                    <p>In this privacy policy, "personal information" refers to the information itself or data that can identify a specific individual after being linked with other information of individuals that Qingping can access.</p>
                    <p>By using LEE GUITARS products and services, you mean that you have read, approved and accepted all the terms stated in this privacy policy, including any changes we make at any time. We promise to protect the privacy, confidentiality and security of your personal information in accordance with applicable laws (including the data protection laws of your location). At the same time, we promise all our employees and agents to fulfill these obligations.</p>
                    <p>Finally, what we want is to bring the best experience to our users. If you have any questions about the data processing practices outlined in this privacy policy, please contact our data protection officer through <a
                        href="mailto:privacy@qingping.co">privacy@qingping.co</a> so that we can handle your needs.</p>
                    <p className={s.pt135}>What information is collected and how will it be used? </p>
                    <p className={s.pt12}>Types of information collected</p>
                    <p>To provide our services to you, we will ask you to provide necessary personal information. If you do not provide personal information, we may not be able to provide you with our products or services. We collect the following types of information (whether it is personal or not):</p>
                    <ul>
                        <li><b>Information you provided to us or uploaded</b>(including your contact information): We may collect any or all personal information you provided to us, such as mobile phone number, IMEI, Android ID, software installation list, MAC address, email address, third-party login information, feedback and any other information you provided to us.</li>
                        <li><b>Device information: </b>We may collect information about your device, including the name, firmware version and MAC address of your device.</li>
                        <li><b>Location information </b>(only applicable to specific services/functions): Geographic location information of your device.</li>
                        <li><b>Third-party SDK (Xiaomi Push, Huawei Push, OPPO Push, Tencent Bugly, Facebook SDK, WeChat SDK, etc. ):</b> to send status information of the device from the app.</li>
                        <li><b>Login information: </b>Information related to your use of certain functions, applications and websites. E.g., cookie and other anonymous identifier technologies, IP addresses, temporary history, standard system logs, etc.</li>
                        <li><b>Feedback provided: </b>When you use feedback feature, we may collect the error logs, and the contact details and feedback you provide.</li>
                    </ul>
                    <p className={s.pt12}>How will this personal information be used</p>
                    <p>Personal information is collected in order to provide you with products and/or services as well as to ensure legal compliance on our part with applicable laws. You hereby consent that we may process and disclose personal information to our affiliated companies (which are in the communications, social media, technology, and cloud businesses), Third Party Service Providers (defined below) for the purposes stated in this Privacy Policy. </p>
                    <p>We may use your personal information for the following purposes: </p>
                    <ul>
                        <li>Provide, process, maintain, improve and develop our products and/or services to you, including after-sale service and customer support, as well as services provided through devices or websites.</li>
                        <li>Communicate with you regarding your device, services, or any other common queries (e.g., updates, customer support, information related to our activities, and notifications). </li>
                    </ul>
                    <p>More details about how we use your information (which may include personal information) are below:</p>
                    <ul>
                        <li><b>Show device information. </b>After adding equipment, the reading information of related equipment is displayed in "Lee Guitars" app.</li>
                        <li><b>Provide push service. </b>"Lee Guitars" app may send you notifications of your Qingping products. You can turn off the notifications at any time by changing the settings.</li>
                        <li><b>Collect user feedback. </b>The feedback you choose to provide is extremely valuable to help Qingping improve our service. In order to track the feedback you choose to provide, Qingping will use the personal information you provide to contact you and keep records.</li>
                        <li><b>Carry out promotional activities. </b>If you participate in the lucky draw, contest or similar promotion through Qingping's official website or other social media platforms, we may use the personal information provided by you to manage these projects. </li>
                    </ul>
                    <p className={s.pt12}>Direct promotion</p>
                    <ul>
                        <li>We may use your email address and mobile phone number to provide you promotional materials related to the products and services of Qingping or its partners. We strictly abide by the data protection laws and regulations in your region and require independent and explicit permission, so we will only use your personal information with your consent or when you express no objection. You have the right to terminate our use of personal data planned for direct promotion. If you no longer want to receive certain types of emails, you can unsubscribe from the unsubscribe link at the bottom of each email. We will not send your personal information to our business partners for direct promotion.</li>
                    </ul>
                    <p className={s.pt135}>Cookie and other technologies (only when browsing Qingping website)</p>
                    <p className={s.pt12}>What information is collected and how it will be used? </p>
                    <p>Qingping and third-party service providers use cookies, tags and scripts. These technologies are used to analyze trends, manage websites, track users' activities on the websites and collect demographic information about the entire user base. We will receive separate and summary reports from the providers based on the use of the above technologies.</p>
                    <ul>
                        <li><b>Log file: </b>Like most websites, we collect specific information and store it in the log files. This information may include Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data. We do not link this automatically collected data to other information we gather about you. </li>
                        <li><b>Mobile analysis: </b>Within some of our mobile applications we use mobile analytics software to allow us to better understand the functionality of our Mobile Software on your phone. This software may record information such as how often you use the application, the events that occur within the application, aggregated usage, performance data, and where crashes occur within the application. We do not link the information we store within the analytics software to any personal information you submit within the mobile application. </li>
                        <li><b>Local storage - HTML5/Flash: </b>We use local storage objects (LSOs), such as HTML5 or Flash to store content and preferences. Third parties with whom we partner to provide certain features on our Sites or to display advertising based upon your web browsing activity also use HTML5 or Flash cookies to collect and store information. Browsers normally provide management tools to delete HTML5 locally stored objects. </li>
                    </ul>
                    <p className={s.pt135}>Who do we share your information with? </p>
                    <p>We will not sell any personal information to third parties. </p>
                    <p>We may disclose your personal information on occasion to third parties (as described below) in order to provide the products or services that you have requested. </p>
                    <p>We may disclose your personal information to third-party service providers and affiliated companies described below. In each case described in this section, you can be assured that Qingping Technology will only share your personal information in accordance with your consent. You should know that when Qingping Technology shares your personal information with a Third Party Service Provider under any circumstance described in this section, Qingping Technology will contractually specify that the third party is subject to practices and obligations to comply with applicable local data protection laws. Qingping Technology will contractually ensure compliance by any Third Party Service Providers with the privacy standards that apply to them in your home jurisdiction.</p>
                    {/*<p className={s.pt12}>SHARING WITH OUR GROUP AND THIRD PARTY SERVICE PROVIDERS</p>*/}
                    <ul>
                        <li><b>Sharing information with Qingping affiliates and third-party service providers: </b>
                            successfully engage in business operations and provide you with all functions of products and services, we may provide information to affiliated companies or third-party service providers (such as online stores, delivery service providers, telecommunications companies, data centers, data storage facilities, customer service providers, advertising and promotion service providers, agents representing Qingping, collectively referred to as "third-party service providers"), they may handle your personal information on behalf of Qingping or for one or more of the above purposes. If you no longer want to share this information, please send an email to <a
                                href="mailto:privacy@qingping.co">privacy@qingping.co</a> to contact us.
                        </li>
                        <li>
                            <b>Sharing information with others: </b>
                            When required by law, Qingping may disclose your personal information without further consent.
                        </li>
                    </ul>
                    <p className={s.pt135}>Information that does not require consent</p>
                    <ul>
                        <li>We may share anonymized information and statistics in aggregate form with third parties for business purposes, for example with advertisers on our website, we may share them trends about the general use of our services, such as the number of customers in certain demographic groups who purchased certain products or who carried out certain transactions. </li>
                        <li>In order to avoid any suspicion, Qingping Technology may collect, use, or disclose your personal information without your consent when expressly permitted by local data protection laws (to, for example, comply with a subpoena). We may also disclose your information without your consent if we believe in good faith that it must be disclosed in order to protect our rights, your safety, or the safety of others; investigate fraud; or respond to government requests. </li>
                    </ul>
                    <p className={s.pt135}>Safety protection</p>
                    <p>We promise to ensure the safety of your personal information. To prevent unauthorized access, disclosure or other similar risks, we have implemented reasonable physical, electronic and management measures to protect the data we collect from your products and Qingping websites or software. We will take all appropriate measures to protect your personal information.</p>
                    <p>All your personal information is stored on secure servers and protected in controlled facilities. We classify your data according to its importance and sensitivity, and ensure that your personal information has the highest security level. We guarantee that our employees and third-party service providers who provide products and services to you by accessing this information have strict contractual confidentiality obligations. If they fail to fulfill these obligations, they will be subject to disciplinary action or be terminated from cooperation. To sum up, we regularly review information collection, storage and processing practices, including physical security measures, to prevent unauthorized access and use.</p>
                    <p>We will take all feasible measures to protect your personal information. However, you should be aware that the use of the Internet is not always safe, so we cannot guarantee the security or integrity of any personal information during the two-way transmission through the Internet.</p>
                    <p className={s.pt12}>What can you do? </p>
                    <ul>
                        <li>You can play your part in safeguarding your personal information by not disclosing your login password or account information to anybody unless such person is duly authorized by you. </li>
                        <li>Qingping Technology cannot be held responsible for lapses in security caused by third party accesses to your personal information as a result of your failure to keep your personal information private. Notwithstanding the foregoing, you must notify us immediately if there is any unauthorized use of your account by any other Internet user or any other breach of security. </li>
                        <li>Your assistance will help us protect the privacy of your personal information.</li>
                    </ul>
                    <p className={s.pt135}>Policy of keeping personal information</p>
                    <p>When it is necessary to keep personal information to provide products or services or comply with regulatory requirements, we will keep your personal information. When it is no longer necessary, we will no longer keep your personal information, or eliminate the ways to associate personal information with specific individuals.</p>
                    <p className={s.pt135}>Access other features on your device</p>
                    <p>Our applications may access some features on your devices, such as Wi-Fi network status, and other features. Such information is used to allow running of those applications on your devices and your interactions with our applications. You can turn off such applications at the device level or contact us at privacy@cleargrass.com to revoke permissions at any time.</p>
                    <p className={s.pt135}>You can control your information!</p>
                    <p className={s.pt12}>Control your settings</p>
                    <p>Qingping Technology recognizes that privacy concerns differ from person to person. Therefore, we provide examples of ways Qingping Technology makes available for you to choose to restrict the collection, use, disclosure or processing of your personal information and control your privacy settings:</p>
                    <ul>
                        <li>Log in or out of Qingping account; </li>
                        <li>Add or delete Qingping devices. </li>
                    </ul>
                    <p>If you have previously agreed to use your personal information for the above purposes, you can contact us in writing or by sending an email to <a
                        href="privacy@qingping.co">privacy@qingping.co</a> at any time to change your decision. </p>
                    <p className={s.pt12}>Access, update or correct your personal information</p>
                    <ul>
                        <li>You have the right to request access to and/or correction of any other personal information that we hold about you. When you update your personal information, you will be asked to verify your identity before your request is processed. Once we have a sufficient amount of information, we will be able to process your request to either access or make corrections to personal information. We will respond to your request within the period specified by applicable data protection laws.</li>
                        <li>We usually provide such services for free but reserve the right to charge a reasonable fee for your data access request.</li>
                        <li>If you wish to request access to the personal data we hold or think that any information we hold about you is incorrect or incomplete, please send an email to <a
                            href="mailto:privacy@qingping.co">privacy@qingping.co</a>.</li>
                    </ul>
                    <p className={s.pt12}>Withdraw consent</p>
                    <ul>
                        <li>You may withdraw your consent for the collection, use and/or disclosure of your personal information in our possession or control by submitting a request. You can send us an email at <a href="mailto:privacy@qingping.co">privacy@qingping.co</a>. We will process your request within a reasonable time frame from the time the request was made, and thereafter not collect, use and/or disclose your personal information as per your request. </li>
                        <li>Please recognize that your withdrawal of consent could result in certain legal consequences. Depending on the extent of your withdrawal of consent for us to process your personal information, it may mean that you will not be able to enjoy Qingping Technology's products and services. </li>
                    </ul>
                    <p className={s.pt12}>Transfer of personal information outside of your jurisdiction</p>
                    <p>If we need to transfer personal information outside your jurisdiction, whether it is to our affiliated companies or third-party service providers, we will do so following applicable laws. By implementing appropriate security measures, we will ensure that all such transfers meet the requirements of applicable local data protection laws. </p>
                    <p className={s.pt135}>Other provisions</p>
                    <p className={s.pt12}>Juveniles</p>
                    <ul>
                        <li>We believe that it is the responsibility of parents to supervise their children when using our products and services. Nevertheless, it is our policy not to require personal information from minors or offer to send any promotional materials to persons in that category.</li>
                        <li>Qingping Technology does not seek or intend to seek to receive any personal information from minors. Should a parent or guardian have reasons to believe that a minor has provided Qingping Technology with personal information without their prior consent, please contact us to ensure that the personal information is removed and the minor unsubscribes from any of the applicable Qingping Technology services.</li>
                    </ul>
                    <p className={s.pt12}>Order of priority</p>
                    <p>If you have agreed to our applicable User Agreements, in the event of inconsistency between such User Agreements and this Privacy Policy, such User Agreements shall prevail.</p>
                    <p className={s.pt12}>Update of privacy policy</p>
                    <p>We keep our Privacy Policy under regular review and may update this privacy policy to reflect changes to our information practices. If we make material changes to our Privacy Policy, we will notify you by email (sent to the email address specified in your account) or post the changes on all the Qingping Technology websites or notify you through your mobile device, so that you may be aware of the information we collect and how we use it. Such changes to our Privacy Policy shall apply from the effective date as set out in the notice or on the website. We encourage you to periodically review this page for the latest information on our privacy practices. Your continued use of products and services on the websites, mobile phones and/or any other device will be taken as acceptance of the updated Privacy Policy. We will seek consent from you again before we collect more personal information from you or when we wish to use or disclose your personal information for new purposes. </p>
                    <p className={s.pt12}>Do you have to agree to any third-party terms or conditions? </p>
                    <p>Our Privacy Policy does not apply to products and services offered by a third party. Qingping Technology products and services may include third parties' products, services and links to third parties' websites. When you use such products or services, they may collect your information too. For this reason, we strongly suggest that you read the third party's privacy policy just as you have taken the time to read ours. We are not responsible for and cannot control how third parties use personal information that they collect from you. Our Privacy Policy does not apply to other websites that are linked via our services.</p>
                    <br />
                    <p>Qingping Technology (Beijing) Co., Ltd.</p>
                    <p>Address: 309, Block C, Yeqing Plaza, No.9 Wangjing North Street, Chaoyang District, Beijing</p>
                    <p>Postal Code: 100102</p>
                    <p>Email: <a href="mailto:privacy@qingping.co">privacy@qingping.co</a></p>

                    <p>Thank you for taking the time to familiarize yourself with our Privacy Policy! </p>
                </div>
            </div>
        );
    }
}

export default AirPrivacy;
