import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import s from './Ssixth.module.scss'

class Ssixth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Ssixth.getBoundingClientRect()
    if (this.refs.Ssixth && clientHeight >= textRect.bottom - 100) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    return (
      <div ref="Ssixth">
        <div className={s.Ssixth}>
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              <div className={s.title}>
                <FormattedMessage id="dany_five_title" values={{br: <br />}} />
              </div>
              <div className={s.desc}>
                <FormattedMessage id="dany_five_desc" values={{br: <br />}} />
              </div>
              <div className={s.colors}></div>
              <div className={s.subtitle}>
                <FormattedMessage id="dany_five_example" />
              </div>
              <div className={s.table}>
                <div className={s.column1}>
                  <div className={s.row1}>
                    <FormattedMessage id="dany_five_table_title1" />
                  </div>
                  <div className={s.row2}>
                    <div className={s.circle1}></div>
                  </div>
                  <div className={s.row3}>
                    <div className={s.circle2}></div>
                  </div>
                  <div className={s.row4}>
                    <div className={s.circle3}></div>
                  </div>
                  <div className={s.row5}>
                    <div className={s.circle4}></div>
                  </div>
                </div>
                <div className={s.column2}>
                  <div className={s.row1}>
                    <FormattedMessage id="dany_five_table_title2" />
                  </div>
                  <div className={s.row2}>
                    <FormattedMessage id="dany_five_table_range1" />
                  </div>
                  <div className={s.row3}>
                    <FormattedMessage id="dany_five_table_range2" />
                  </div>
                  <div className={s.row4}>
                    <FormattedMessage id="dany_five_table_range3" />
                  </div>
                  <div className={s.row5}>
                    <FormattedMessage id="dany_five_table_range4" />
                  </div>
                </div>
                <div className={s.column3}>
                  <div className={s.row1}>
                    <FormattedMessage id="dany_five_table_title3" />
                  </div>
                  <div className={s.row2}>
                    <FormattedMessage id="dany_five_table_desc1" />
                  </div>
                  <div className={s.row3}>
                    <FormattedMessage id="dany_five_table_desc2" />
                  </div>
                  <div className={s.row4}>
                    <FormattedMessage id="dany_five_table_desc3" />
                  </div>
                  <div className={s.row5}>
                    <FormattedMessage id="dany_five_table_desc4" />
                  </div>
                </div>
              </div>
              <div className={s.memo}>
                <FormattedMessage id="dany_five_memo" values={{br: <br />}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Ssixth
