import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LazyLoad from 'react-lazyload';

import s from './Gfourth.module.scss'

const issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
class Gfourth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Dfourth.getBoundingClientRect();
        if ((clientHeight - 200) > textRect.top && this.refs.video) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '42px';
                this.refs.video.style.opacity = 1;
                this.refs.video.style.bottom = '100px';
            }, 500);
        }
    }
    render() {
        return (
            <div ref="Dfourth">
                <LazyLoad height={700}>
                    <div className={s.Gfourth}>
                        <div className={s.wrap}>
                            <div className={s.text} ref="text">
                                <div className={s.title}>
                                    <FormattedMessage id="fourth_title" />
                                </div>
                                <div className={s.subTitle}>
                                    <FormattedMessage id="fourth_desc" />
                                </div>
                                <ol className={issafariBrowser ? s.ol_safari : s.ol}>
                                    <li>
                                        <FormattedMessage id="fourth_desc_1" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="fourth_desc_2" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="fourth_desc_3" />
                                    </li>
                                </ol>
                                <div className={s.small}>
                                    <FormattedMessage id="fourth_desc_4" values={{br: <br />}} />
                                </div>
                            </div>
                            <div className={s.video} ref="video">
                                <div className={s.fourth_video} />
                            </div>
                        </div>
                    </div>
                </LazyLoad>
            </div>
        );
    }
}

export default Gfourth;

