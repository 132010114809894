import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Header from './../../mobile/header';
import Footer from './../../mobile/footer'
import s from './contact.module.scss'

class contact extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }
    handleShowQrcode() {
        this.setState({
            show: !this.state.show
        });
    }
    componentDidMount() {
        document.getElementsByTagName('html')[0].style.fontSize = 41.4 + 'px'
    }
    render() {
        const { location, locale } = this.props;
        const path = this.props.match.path;
        let qrcodeName = s.wechat_hide;
        if (this.state.show) {
            qrcodeName = s.show;
        }
        return (
            <div className={s.box} ref="box">
                <Header location={location} language={this.state.language} path={path} />
                <div className={s.contact}>
                    <div className={s.list}>
                        <div className={s.contact_title}>
                            <FormattedMessage id="care" />
                        </div>
                        <div className={s.care_icon}>
                            <ul>
                                <li>
                                    <span onClick={() => this.handleShowQrcode()}><i className={s.wechat} /><FormattedMessage id="wechat" /></span>
                                    <div className={qrcodeName}>
                                        <i />
                                        <FormattedMessage id="qrcode_text" values={{ br: <br /> }} />
                                    </div>
                                </li>
                                <li>
                                    <a href="https://weibo.com/qingpingkeji" target="_blank" rel="noopener noreferrer">
                                        <i className={s.sina} /><FormattedMessage id="sina" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={s.list}>
                        <div className={s.contact_title}>
                            <FormattedMessage id="cooperate" />
                        </div>
                        <div className={s.tel_email}>
                            <ul>
                                {(locale.indexOf('US') > -1 || locale.indexOf('TW') > -1) ? null : <li>
                                    <i className={s.tel} />
                                    <a href="tel:01084766899">
                                        <FormattedMessage id="cooperate_tel" />
                                    </a>
                                </li>}
                                <li>
                                    <i className={s.email} />
                                    <a href="mailto:bd@qingping.co" rel="noopener noreferrer">
                                    bd@qingping.co
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={s.lastList}>
                        <div className={s.contact_title}>
                            <FormattedMessage id="serve" />
                        </div>
                        <div className={s.tel_email}>
                            <ul>
                                {(locale.indexOf('US') > -1 || locale.indexOf('TW') > -1) ? null : <li><i className={s.tel} />
                                    <a href="tel:4006972778">
                                        <FormattedMessage id="serve_tel" />
                                    </a>
                                </li>}
                                { locale.indexOf('US') > -1 ? <li>
                                    <i className={s.email} />
                                    <a href="mailto:support@qingping.co" rel="noopener noreferrer">
                                    support@qingping.co
                                    </a>
                                </li> : <li>
                                    <i className={s.email} />
                                    <a href="mailto:kefu@qingping.co" rel="noopener noreferrer">
                                        kefu@qingping.co
                                    </a>
                                </li>}
                            </ul>
                        </div>
                    </div>
                </div>
                <Footer location={location} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  let Contact = connect(mapStateToProps)(contact);

export default Contact;

