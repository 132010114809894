import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import s from './Ssixth.module.scss'

class Ssixth extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { // 初始state
      index: 0,
      opacity: 1
    };
    this._handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount () {
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll);
  }
  handleScroll () {
    const clientHeight = document.documentElement.clientHeight;
    const textRect = this.refs.Ssixth.getBoundingClientRect();
    if (this.refs.Ssixth && clientHeight >= textRect.bottom - 200) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1;
        this.refs.text.style.top = '0';
      }, 300);
    }
  }
  render () {
    const { locale } = this.props
    return (
      <div ref="Ssixth">
        <div className={s.Ssixth}>
          <div className={s.wrap}>
            <div className={s.phone}>
              <div className={locale.indexOf('US') > -1 ? s.img_en : (locale.indexOf('TW') > -1 ? s.img_tw : s.img)}></div>
            </div>
            <div className={s.text} ref="text">
              <div className={s.title}>
                <FormattedMessage id="mp_bluetooth" />
                <br/>
                <FormattedMessage id="mp_wireless" />
              </div>
              <div className={s.desc_1}>
                <FormattedMessage id="mp_ble" values={{br: <br />}} />
              </div>
              <div className={s.title_2}>
                <FormattedMessage id="support_app" />
              </div>
              <ul className={s.support}>
                <li className={s.item} style={{marginRight: 34}}>
                  <Link to="/lee-guitars/app" className={s.magpie}>
                    <i></i>
                  </Link>
                  <div className={s.item_name}>
                    <a href="/lee-guitars/app" style={{color: '#000'}}>
                    <FormattedMessage id="magpie_simple" />
                    </a>
                  </div>
                </li>
                <li className={s.item} style={{marginRight: 50}}>
                  <Link to="/plus" className={s.plus}>
                    <i></i>
                  </Link>
                  <div className={s.item_name}>
                  <FormattedMessage id="cg_app" />
                  </div>
                </li>
                <li className={s.item}>
                  <i className={s.mi_home}></i>
                  <div className={s.item_name}>
                  <FormattedMessage id="mi_home" />
                  </div>
                </li>
              </ul>
              <div className={s.desc_2}>
                <FormattedMessage id="mp_upload" values={{br: <br />}} />
                &nbsp;
                <Link to="/lee-guitars/app" className={s.magpie}>
                  <FormattedMessage id="mp_upload_magpie" />
                </Link>
                &nbsp;
                <FormattedMessage id="mp_upload_or" />
                &nbsp;
                <Link to="/plus" className={s.plus}>
                  <FormattedMessage id="mp_upload_plus" />
                </Link>
                &nbsp;
                <FormattedMessage id="mp_upload_end" />
                <br/>
                <FormattedMessage id="mp_check" values={{br: ''}} />
                <Link to="/bluetooth-gateway" className={s.bluetooth}>
                  <FormattedMessage id="mp_check_bluetooth" />
                </Link>
                <br/>
                <FormattedMessage id="mp_create_automation" values={{br: ''}} />
                <br/>
                * <FormattedMessage id="support_mi_A" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language
});

let SSixth = connect(mapStateToProps)(Ssixth);

export default SSixth;

