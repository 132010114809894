import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
// import { Link } from 'react-router-dom'

import s from './Seighth.module.scss'

class Seighth extends React.PureComponent {
  static handleClick() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Seighth.getBoundingClientRect()
    if (this.refs.Seighth && clientHeight >= textRect.bottom - 200) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    return (
      <div className={s.Seighth} ref="Seighth">
        <div className={s.wrap} ref="text">
          <div className={s.item}>
            <div className={s.item_title}>
              <FormattedMessage id="parrot_seven_title" />
            </div>
            <div className={s.item_desc}>
              <FormattedMessage id="parrot_seven_desc" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SEighth = connect(mapStateToProps)(Seighth)

export default SEighth
