const common = {
  qingping_cn: '青萍',
  qingping_tw: '青萍',
  qingping_us: 'Qingping',

  specifications_cn: '规格',
  specifications_tw: '規格',
  specifications_us: 'Specs',

  apps: 'Apps',

  about_us_cn: '关于我们',
  about_us_tw: '關於我們',
  about_us_us: 'About Us',

  cg_app_cn: '青萍+',
  cg_app_tw: '青萍+',
  cg_app_us: 'Qingping+',

  privacy_cn: '隐私政策',
  privacy_tw: '隱私政策',
  privacy_us: 'Privacy Policy',

  goose_cn: '青萍蓝牙温湿度计',
  goose_tw: '青萍藍牙溫濕度計',
  goose_us: 'Qingping Temp & RH Monitor',

  duck2_cn: '青萍蓝牙温湿度计 Lite',
  duck2_tw: '青萍藍牙溫濕度計 Lite',
  duck2_us: 'Qingping Temp & RH Monitor Lite',

  hodor_cn: '青萍门窗开合传感器',
  hodor_tw: '青萍門窗開合傳感器',
  hodor_us: 'Qingping Door/Window Contact Sensor',

  parrot_cn: '青萍动作和环境光传感器',
  parrot_tw: '青萍動作和環境光傳感器',
  parrot_us: 'Qingping Motion & Light Sensor',

  chicken_cn: '青萍蓝牙小钟',
  chicken_tw: '青萍藍牙小鐘',
  chicken_us: 'Qingping Bluetooth Clock',

  gecko_cn: '青萍墙壁插座式温度计',
  gecko_tw: '青萍牆壁插座式溫度計',
  gecko_us: 'Qingping Wall Socket Thermometer',

  dove_cn: '青萍蓝牙闹钟',
  dove_tw: '青萍藍牙鬧鐘',
  dove_us: 'Qingping Bluetooth Alarm Clock',

  qing_ping_iot_cn: '青萍物联',
  qing_ping_iot_tw: '青萍物聯',
  qing_ping_iot_us: 'Qingping IoT',

  pheasant_cn: '青萍商用温湿度（气压）计',
  pheasant_tw: '青萍商用溫濕度（氣壓）計',
  pheasant_us: 'Qingping Temp & RH Monitor (Barometer) Pro S',

  pheasant_co2_cn: '青萍二氧化碳和温湿度检测仪',
  pheasant_co2_tw: '青萍二氧化碳和溫濕度檢測儀',
  pheasant_co2_us: 'Qingping CO₂ & Temp & RH Monitor',

  frog_cn: '青萍商用温湿度计 E',
  frog_tw: '青萍商用溫濕度計 E',
  frog_us: 'Qingping Temp & RH Monitor E',

  snow_cn: '青萍空气检测仪',
  snow_tw: '青萍空氣檢測儀',
  snow_us: 'Qingping Air Monitor',

  snow2_cn: '青萍空气检测仪 2',
  snow2_tw: '青萍空氣檢測儀 2',
  snow2_us: 'Qingping Air Monitor 2',

  dany_cn: '青萍空气检测仪 Lite',
  dany_tw: '青萍空氣檢測儀 Lite',
  dany_us: 'Qingping Air Monitor Lite',

  sparrow_cn: '青萍蓝牙网关',
  sparrow_tw: '青萍藍牙網關',
  sparrow_us: 'Qingping Bluetooth Gateway',

  magpie_cn: '李吉他温湿度',
  magpie_tw: '李吉他溫濕度',
  magpie_us: 'LEE GUITARS',
}

export default common
