import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import s from './Sfirst.module.scss'

class Sfirst extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
      isPlay: false,
    }
  }
  componentDidMount() {
    if (this.refs.text) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  componentWillUnmount() {}
  render() {
    const { locale } = this.props
    return (
      <div
        className={locale.indexOf('US') > -1 ? s.Sfirst_en : s.Sfirst}
        ref="Sfirst"
      >
        <div className={s.text} ref="text">
          <div className={s.large}>
            <span>
              <FormattedMessage
                id="parrot_first_title"
                values={{ br: <br /> }}
              />
            </span>
          </div>
          <div className={s.normal}>
            <FormattedMessage id="parrot_first_desc_1" />
            <span className={s.line}>·</span>
            <FormattedMessage id="parrot_first_desc_2" />
            <span className={s.line}>·</span>
            <FormattedMessage id="parrot_first_desc_3" />
          </div>
        </div>
        <div className={s.img} ref="img" />
        <div className={s.closeBox}>
          <div className={s.close} ref="close" onClick={() => this.close()} />
        </div>
        <div className={s.goose_bottom}>
          <div className={s.bottom_back}></div>
          <a className={s.icon_box} href="#homekit">
            <div className={s.icon_title}>
              <FormattedMessage id="t_version" />
            </div>
            <div className={s.homekit_icon}></div>
          </a>
          <a className={s.icon_box} href="#mi">
            <div className={s.icon_title}>
              <FormattedMessage id="m_version" />
            </div>
            <div
              className={locale.indexOf('US') > -1 ? s.mi_icon_en : s.mi_icon}
            ></div>
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SFirst = connect(mapStateToProps)(Sfirst)

export default SFirst
