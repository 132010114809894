import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Sthird.module.scss'

class Sthird extends React.PureComponent {
  render() {
    const { locale } = this.props
    return (
      <div ref="Sthird" id="topnote-1">
        <div className={s.Sthird}>
          <div className={s.wrap}>
            <div
              className={locale.indexOf('US') > -1 ? s.text_en : s.text}
              ref="text"
            >
              <div className={s.large}>
                <FormattedMessage
                  id="parrot_second_title"
                  values={{ br: <br /> }}
                />
              </div>
              <div className={s.normal}>
                <FormattedMessage id="parrot_second_desc" />
                {/* <sup className={s.footnote}>
                  <a href="#footnote-1" aria-label="脚注1">
                    1
                  </a>
                </sup> */}
                <FormattedMessage id="parrot_second_desc_1" />
              </div>
            </div>
            <div className={s.rightPic}></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SThird = connect(mapStateToProps)(Sthird)

export default SThird
