import React, { Component } from 'react'
import Util from '../../../../common/util'
import { FormattedMessage } from 'react-intl'

import s from './Ptimecalculator.module.scss'

import { Select, Radio } from 'antd'

const { Option } = Select

class Ptimecalculator extends React.PureComponent {
  static handleClick() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
      product: 1,
      netType: 'wifi',
      signal: 'normal',
      intervals: '1',
      temperature: '20',
      standby: Util.calculateTimeNew(1, 'wifi', '1', '20', 'normal'),
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Ptimecalculator.getBoundingClientRect()
    if (this.refs.Ptimecalculator && clientHeight >= textRect.bottom - 100) {
      setTimeout(() => {
        this.refs.text.style.opacity = 0.9
        this.refs.text.style.top = '0'
      }, 0)
    }
  }

  handleChange(value, chooseItem) {
    const { product, netType, signal, intervals, temperature } = this.state
    console.log(
      product,
      netType,
      signal,
      intervals,
      temperature,
      value,
      `selected ${value}`
    )

    if (chooseItem === 'product') {
      if (value === 1 || value === 2) {
        this.setState({
          product: value,
          standby: Util.calculateTimeNew(
            value,
            netType,
            intervals,
            temperature,
            signal
          ),
        })
      } else {
        this.setState({
          product: value.target.value,
          standby: Util.calculateTimeNew(
            value.target.value,
            netType,
            intervals,
            temperature,
            signal
          ),
        })
      }
    } else if (chooseItem === 'netType') {
      console.log(value, 'value of netType')
      let newInterval = intervals
      if (value === 'nbiot') {
        newInterval = '1'
      }
      this.setState({
        netType: value,
        intervals: newInterval,
        standby: Util.calculateTimeNew(
          product,
          value,
          newInterval,
          temperature,
          signal
        ),
      })
    } else if (chooseItem === 'intervals') {
      this.setState({
        intervals: value,
        standby: Util.calculateTimeNew(
          product,
          netType,
          value,
          temperature,
          signal
        ),
      })
    } else if (chooseItem === 'temperature') {
      this.setState({
        temperature: value,
        standby: Util.calculateTimeNew(
          product,
          netType,
          intervals,
          value,
          signal
        ),
      })
    } else if (chooseItem === 'signal') {
      this.setState({
        signal: value,
        standby: Util.calculateTimeNew(
          product,
          netType,
          intervals,
          temperature,
          value
        ),
      })
    }
  }

  render() {
    const { product, netType, signal, intervals, temperature, standby } =
      this.state
      console.log('render');
    return (
      <div className={s.Ptimecalculator} ref="Ptimecalculator">
        <div className={s.title} ref="text">
          <FormattedMessage id="p_calculator_title" />
          <div className={s.subtitle}>
            <FormattedMessage id="p_calculator_subtitle" />
          </div>
        </div>
        <div className={s.chooseType}>
          <div className={s.product}>
            <div
              className={product === 1 ? s.with_air_selected : s.with_air}
              onClick={(e) => this.handleChange(1, 'product')}
            ></div>
            <div
              className={product === 2 ? s.selected1 : s.item1}
              onClick={(e) => this.handleChange(1, 'product')}
            >
              <FormattedMessage id="ph_first_text1" values={{ br: '' }} />
            </div>
            <div
              className={product === 2 ? s.selected2 : s.item2}
              onClick={(e) => this.handleChange(1, 'product')}
            >
              <FormattedMessage id="ph_first_text12" />
            </div>
          </div>
          <div className={s.product}>
            <div
              className={product === 2 ? s.no_air_selected : s.no_air}
              onClick={(e) => this.handleChange(2, 'product')}
            ></div>
            <div
              className={product === 1 ? s.selected1 : s.item1}
              onClick={(e) => this.handleChange(2, 'product')}
            >
              <FormattedMessage id="ph_first_text2" values={{ br: '' }} />
            </div>
            <div
              className={product === 1 ? s.selected2 : s.item2}
              onClick={(e) => this.handleChange(2, 'product')}
            >
              <FormattedMessage id="ph_first_text13" />
            </div>
          </div>
        </div>

        <div className={s.radio}>
          <Radio.Group
            className={s.group}
            onChange={(value) => this.handleChange(value, 'product')}
            value={product}
          >
            <Radio value={1}></Radio>
            <Radio value={2}></Radio>
          </Radio.Group>
        </div>

        <div className={s.content}>
          <div className={s.items}>
            <div className={s.item}>
              <div className={s.item_title}>
                <FormattedMessage id="p_network_type" />
              </div>
              <Select
                defaultValue={netType}
                onChange={(value) => this.handleChange(value, 'netType')}
              >
                <Option value="wifi">Wi-Fi</Option>
                <Option value="nbiot">NB-IoT</Option>
                <Option value="lora">LoRa</Option>
              </Select>
            </div>

            <div className={s.item}>
              <div className={s.item_title}>
                <FormattedMessage id="p_network_signal" />
              </div>
              <Select
                defaultValue={signal}
                onChange={(value) => this.handleChange(value, 'signal')}
              >
                {/* <Option value="good">
                  <FormattedMessage id="p_good" />
                </Option> */}
                <Option value="normal">
                  <FormattedMessage id="p_normal" />
                </Option>
                <Option value="bad">
                  <FormattedMessage id="p_bad" />
                </Option>
              </Select>
            </div>

            {/* <div className={s.item}>
              <div className={s.item_title}>
                <FormattedMessage id="p_local_interval" />
              </div>
              <Select defaultValue="15">
                <Option value="1">
                  1 <FormattedMessage id="p_minute" />
                </Option>
                <Option value="5">
                  5 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="10">
                  10 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="15">
                  15 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="20">
                  20 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="25">
                  25 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="30">
                  30 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="35">
                  35 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="40">
                  40 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="45">
                  45 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="50">
                  50 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="55">
                  55 <FormattedMessage id="p_minutes" />
                </Option>
                <Option value="60">
                  60 <FormattedMessage id="p_minutes" />
                </Option>
              </Select>
            </div> */}

            <div className={s.item}>
              <div className={s.item_title}>
                <FormattedMessage id="p_net_interval" />
              </div>
              <Select
                defaultValue={intervals}
                value={intervals}
                onChange={(value) => this.handleChange(value, 'intervals')}
              >
                {netType !== 'nbiot' ? (
                  <Option value="0.166">
                    10 <FormattedMessage id="p_minutes" />
                  </Option>
                ) : null}
                {netType !== 'nbiot' ? (
                  <Option value="0.33">
                    20 <FormattedMessage id="p_minutes" />
                  </Option>
                ) : null}
                {netType !== 'nbiot' ? (
                  <Option value="0.5">
                    30 <FormattedMessage id="p_minutes" />
                  </Option>
                ) : null}
                <Option value="1">
                  1 <FormattedMessage id="p_hour" />
                </Option>
                <Option value="2">
                  2 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="3">
                  3 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="4">
                  4 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="5">
                  5 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="6">
                  6 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="7">
                  7 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="8">
                  8 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="9">
                  9 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="10">
                  10 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="11">
                  11 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="12">
                  12 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="13">
                  13 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="14">
                  14 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="15">
                  15 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="16">
                  16 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="17">
                  17 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="18">
                  18 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="19">
                  19 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="20">
                  20 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="21">
                  21 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="22">
                  22 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="23">
                  23 <FormattedMessage id="p_hours" />
                </Option>
                <Option value="24">
                  24 <FormattedMessage id="p_hours" />
                </Option>
              </Select>
            </div>

            <div className={s.item}>
              <div className={s.item_title}>
                <FormattedMessage id="p_environment" />
              </div>
              <Select
                defaultValue={temperature}
                onChange={(value) => this.handleChange(value, 'temperature')}
              >
                <Option value="-30">-30°C</Option>
                <Option value="-20">-20°C</Option>
                <Option value="-10">-10°C</Option>
                <Option value="0">0°C</Option>
                <Option value="10">10°C</Option>
                <Option value="20">20°C</Option>
                <Option value="30">30°C</Option>
                <Option value="40">40°C</Option>
                <Option value="50">50°C</Option>
              </Select>
            </div>
          </div>

          <div className={s.cal_content}>
            <div className={s.cal_title}>
              <FormattedMessage id="p_around" />
            </div>
            <div className={s.cal_result}>
              <FormattedMessage id="p_about" />
              <span className={s.cal_num}> {standby} </span>
              <FormattedMessage id="p_day" />
            </div>
            <div className={s.desc}>
              <FormattedMessage id="p_tips" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Ptimecalculator
