import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LazyLoad from 'react-lazyload';

import s from './Gfifth.module.scss'

class Gfifth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Gfifth.getBoundingClientRect();
        if ((clientHeight - 200) > textRect.top && this.refs.Gfifth) {
            setTimeout(() => {
                this.refs.title.style.opacity = 1;
                this.refs.title.style.top = '100px';
            }, 500);
        }
    }
    render() {
        return (
            <div className={s.Gfifth} ref="Gfifth">
                <div className={s.title} ref="title">
                    <FormattedMessage id="fifth_title" />
                </div>
                <div className={s.img}>
                    <ul>
                        <li>
                            <LazyLoad height={350}>
                                <i className={s.goose} />
                            </LazyLoad>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Gfifth;

