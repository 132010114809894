import React, { Component } from 'react'

import Header from './../header'
import Footer from './../footer'
import s from './index.module.scss'

class Store extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      language: null,
      position: false,
    }
  }

  render() {
    const { location } = this.props
    const path = this.props.match.path
    return (
      <div className={s.box} ref="box">
        <Header
          location={location}
          language={this.state.language}
          position={this.state.position}
          path={path}
        />
        <div className={s.store}>
          <div className={s.title}>EU Declaration of Conformity</div>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/snow_eu_doc.pdf"
              >
                <div className={s.snow}></div>
                <div className={s.desc} style={{ lineHeight: 2 }}>
                  Qingping Air Monitor
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/dany_eu_doc.pdf"
              >
                <div className={s.dany}></div>
                <div className={s.desc}>
                  Qingping Air Monitor
                  <br /> Lite
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/pheasant_co2_eu_doc.pdf"
              >
                <div className={s.pheasant}></div>
                <div className={s.desc}>
                  Qingping CO₂ & Temp <br />& RH Monitor
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/goose_h_eu_doc.pdf"
              >
                <div className={s.goose}></div>
                <div className={s.desc}>
                  Qingping Temp & RH
                  <br /> Monitor H Version
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/goose_m_eu_doc.pdf"
              >
                <div className={s.goose}></div>
                <div className={s.desc}>
                  Qingping Temp & RH
                  <br /> Monitor M Version
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/goose_t_eu_doc.pdf"
              >
                <div className={s.goose}></div>
                <div className={s.desc}>
                  Qingping Temp & RH
                  <br /> Monitor T Version
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/duck2_eu_doc.pdf"
              >
                <div className={s.duck2}></div>
                <div className={s.desc}>
                  Qingping Temp & RH
                  <br /> Monitor Lite
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/duck2_c_eu_doc.pdf"
              >
                <div className={s.duck2}></div>
                <div className={s.desc}>
                  Qingping Temp & RH
                  <br /> Monitor Lite C
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/pheasant_eu_doc.pdf"
              >
                <div className={s.pheasant}></div>
                <div className={s.desc}>
                  Qingping Temp & RH <br />
                  Barometer Pro S
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/frog_eu_doc.pdf"
              >
                <div className={s.frog}></div>
                <div className={s.desc}>
                  Qingping Temp & RH
                  <br /> Monitor Pro E
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/magpie_eu_doc.pdf"
              >
                <div className={s.magpie}></div>
                <div className={s.desc}>
                  LEE GUITARS Bluetooth <br />
                  Thermo-Hygrometer
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/hodor_eu_doc.pdf"
              >
                <div className={s.hodor}></div>
                <div className={s.desc}>
                  Qingping Door/Windows <br />
                  Contact Sensor
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/parrot_eu_doc.pdf"
              >
                <div className={s.parrot}></div>
                <div className={s.desc}>
                  Qingping Motion <br />& Light Sensor
                </div>
              </a>
            </li>

            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/dove_eu_doc.pdf"
              >
                <div className={s.dove}></div>
                <div className={s.desc}>
                  Qingping Bluetooth <br />
                  Clock
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/chicken_eu_doc.pdf"
              >
                <div className={s.chicken}></div>
                <div className={s.desc}>
                  Qingping Bluetooth
                  <br /> Alarm Clock
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://qingping.co/static/media/sparrow_eu_doc.pdf"
              >
                <div className={s.sparrow}></div>
                <div className={s.desc}>
                  Qingping Bluetooth
                  <br /> Gateway
                </div>
              </a>
            </li>
          </ul>
        </div>
        <Footer location={location} />
      </div>
    )
  }
}

export default Store
