import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import s from './Sfifth.module.scss'

class Sfifth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Sfifth.getBoundingClientRect();
        if (this.refs.Sfifth && clientHeight >= textRect.bottom - 100) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 500);
        }
    }
    render() {
        const { locale } = this.props
        return (
            <div className={s.Sfifth} ref="Sfifth">
                <div className={s.wrap}>
                    <div className={s.text} ref="text">
                        <div className={s.title}>
                            <FormattedMessage id="s_fifth_title" />
                        </div>
                        <div className={locale.indexOf('US') > -1 ? s.subTitle_en : s.subTitle}>
                            <FormattedMessage id="s_fifth_sub_title" values={{br: <br />,  spot: <span className={s.line}>·</span> }} />
                        </div>
                        <div className={s.Sfourth_desc}>
                            {/* <FormattedMessage id="s_fifth_desc" /> */}
                        </div>
                        <div className={s.iconList}>
                            <ul>
                                <li>
                                    <i className={s.SfifthA7} />
                                    <FormattedMessage id="s_fifth_icon_text_1" />
                                </li>
                                <li>
                                    <i className={s.SfifthCalculate} />
                                    <FormattedMessage id="s_fifth_icon_text_2" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={s.img} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language,
  });
  
  let SFifth = connect(mapStateToProps)(Sfifth);

export default SFifth;

