import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import s from './style.module.scss'
import Header from './../../header'
import Footer from './../../footer'
import pic_specific_two from './spec1.png'
import pic_specific_four from './../images/spec_02.png'
import common from '../../../../../locale/common'
class GeckoSpecifications extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
    }
  }

  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title =
      common['gecko_' + local] + ' - ' + common['specifications_' + local]
  }
  //隐藏Goose购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }
  render() {
    const { hideGooseChoice } = this.state
    const { location, locale } = this.props
    const path = this.props.match.path
    return (
      <div
        className={s.box}
        onMouseOver={(e) => {
          e.persist()
          this.hideGooseChoiceFun(true, e)
        }}
      >
        <Header
          location={location}
          language={this.state.language}
          path={path}
          navPosition={this.state.position}
          hideGooseChoice={hideGooseChoice}
          hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
        />
        <div className={s.specifications}>
          <div className={s.Gparam}>
            <div className={s.row}>
              <div className={s.name}>
                <FormattedMessage id="exterior" />
              </div>
              <div className={s.cell}>
                <div className={s.sizeImg}>
                  <div className={s.top_side}>
                    <div className={s.left_left}>
                      <div
                        className={
                          locale.indexOf('US') > -1 ? s.left_img_en : s.left_img
                        }
                      ></div>
                    </div>
                  </div>
                  <div className={s.top_top}>
                    <div
                      className={
                        locale.indexOf('US') > -1 ? s.top_img_en : s.top_img
                      }
                    ></div>
                  </div>
                </div>
                <ul>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_size" />
                    </span>
                    <FormattedMessage id="gecko_size" values={{ br: '' }} />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="g_distanse" />
                    </span>
                    <FormattedMessage id="gecko_distanse" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_color" />
                    </span>
                    <FormattedMessage id="gecko_color" />
                  </li>
                </ul>
              </div>
            </div>
            <div className={s.row}>
              <div className={s.name}>
                <FormattedMessage id="range" />
              </div>
              <div className={s.cell}>
                <ul>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="mp_temp_range_label" />
                    </span>
                    <FormattedMessage id="gecko_temp_range" />
                  </li>
                </ul>
                <p>
                  <FormattedMessage id="gecko_invironment" />
                </p>
              </div>
            </div>
            <div className={s.row}>
              <div className={s.name}>
                <FormattedMessage id="other_specs" />
              </div>
              <div className={s.cell}>
                <ul>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="ph_name" />
                    </span>
                    <FormattedMessage id="gecko_name" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_model" />
                    </span>
                    <FormattedMessage id="gecko_model" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_weight" />
                    </span>
                    <FormattedMessage id="gecko_weight" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="screen_size" />
                    </span>
                    <FormattedMessage id="gecko_screen_size" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="gecko_rated_input" />
                    </span>
                    <FormattedMessage id="gecko_rated_value" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="gecko_i_input" />
                    </span>
                    <FormattedMessage id="gecko_i_value" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="p_wifi" />
                    </span>
                    <FormattedMessage id="gecko_network_type" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="gecko_material" />
                    </span>
                    <FormattedMessage id="gecko_material_type" />
                  </li>
                </ul>
              </div>
            </div>
            <div className={s.pack}>
              <div className={s.name}>
                <FormattedMessage id="p_pack" />
              </div>
              <div className={s.cell}>
                <div className={s.packImg}>
                  <img src={pic_specific_two} width="110" height="110" alt="" />
                  <p>
                    <FormattedMessage id="gecko_name" />
                  </p>
                </div>
                <div className={s.packImg}>
                  <img
                    src={pic_specific_four}
                    width="111"
                    height="110"
                    alt=""
                  />
                  <p>
                    <FormattedMessage id="p_instructions" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer location={location} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const GeckoSpecification = connect(mapStateToProps)(GeckoSpecifications)

export default GeckoSpecification
