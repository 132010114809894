import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './Ssecond.module.scss'

class Ssecond extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Ssecond.getBoundingClientRect();
        if (this.refs.Ssecond && clientHeight >= textRect.bottom) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 500);
        }
    }
    render() {
        return (
            <div className={s.Ssecond} ref="Ssecond">
                <div className={s.wrap}>
                    <div className={s.text} ref="text">
                        <div className={s.item}>
                            <div className={s.icon1}></div>
                            <FormattedMessage id="dany_co2" />
                        </div>
                        <div className={s.item}>
                            <div className={s.icon2}></div>
                            <FormattedMessage id="dany_pm25" />
                        </div>
                        <div className={s.item}>
                            <div className={s.icon3}></div>
                            <FormattedMessage id="dany_pm10" />
                        </div>
                        <div className={s.item}>
                            <div className={s.icon4}></div>
                            <FormattedMessage id="dany_temp" />
                        </div>
                        <div className={s.item}>
                            <div className={s.icon5}></div>
                            <FormattedMessage id="dany_humi" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });

  const SSecond = connect(mapStateToProps)(Ssecond)

export default SSecond;

