import React from 'react';

import s from './Dsixth.module.scss';

const Dsixth = () =>
    <div className={s.Dsixth}>
        <div className={s.leftCell}>
            <i />
            <div className={s.desc}>天气闷热，自动开启空调</div>
            <div className={s.text}>在米家 app 中设置达到预设温度时自动开启或关闭空调。</div>
        </div>
        <div className={s.rightCell}>
            <i />
            <div className={s.desc}>室内干燥，自动开启加湿器</div>
            <div className={s.text}>在米家 app 中设置达到预设湿度时自动开启或关闭加湿器。</div>
        </div>
    </div>;

export default Dsixth;

