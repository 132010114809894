import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LazyLoad from 'react-lazyload';

import s from './Gthird.module.scss'

class Gthird extends React.PureComponent {
    constructor(props) {
        super(props);
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Gthird.getBoundingClientRect();
        if ((clientHeight - 200) > textRect.top && this.refs.video) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '156px';
                this.refs.video.style.opacity = 1;
                this.refs.video.style.bottom = '150px';
            }, 500);
        }
    }
    render() {
        return (
            <div ref="Gthird">
                <LazyLoad height={700}>
                    <div className={s.Gthird}>
                        <div className={s.wrap}>
                            <div className={s.text} ref="text">
                                <span className={s.large}>
                                    <FormattedMessage id="thirdth_title" />
                                </span>
                                <span className={s.normal}>
                                    <FormattedMessage id="thirdth_desc" />
                                </span>
                            </div>
                            <div className={s.video} ref="video">
                                <div className={s.third_video} />
                            </div>
                        </div>
                    </div>
                </LazyLoad>
            </div>
        );
    }
}

export default Gthird;
