import React, { Component } from 'react';

import s from './Dfourth.module.scss';

class Dfourth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const img = this.refs.img;
        const top = this.refs.top;
        const topText1 = this.refs.topText_1;
        const topText2 = this.refs.topText_2;
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Dfourth.getBoundingClientRect();
        if (clientHeight >= textRect.bottom && img) {
            img.style.backgroundPosition = 'bottom';
            top.style.opacity = 1;
            setTimeout(() => {
                topText1.style.opacity = 0;
                topText2.style.opacity = 1;
            }, 2000);
        }
    }
    render() {
        return (
            <div className={s.Dfourth} ref="Dfourth">
                <div className={s.warp}>
                    <div className={s.phone}>
                        <div className={s.imgBox}>
                            <div className={s.bar} />
                            <div className={s.top} ref="top" />
                            <div className={s.topText_1} ref="topText_1">米家蓝牙温湿度计</div>
                            <div className={s.topText_2} ref="topText_2" />
                            <div className={s.img} ref="img" />
                        </div>
                    </div>
                    <div className={s.textBox}>
                        <div className={s.title}>悉心解读温湿度数据</div>
                        <div className={s.desc}>随时随地查看家中温湿度，营造舒适环境</div>
                        <div className={s.text}>异常变化时会有通知，让您及时了解，从容应对。</div>
                        <div className={s.small}>
                            <p>* 需房间内有米家蓝牙网关设备，才能远程查看温湿度读数。</p>
                            <p>目前带有蓝牙网关功能的米家产品有：米家床头灯、米家智能摄像机 1080P 等。</p>
                            <p>更多支持米家蓝牙网关功能的设备，请咨询官方客服。</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dfourth;

