import React, { Component } from 'react';

import s from './Privacy.module.scss';

class PlGprivacy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { // init top hide
      display: false
    };
  }
  render () {

    return (
      <div className={s.box}>
        <div className={s.privacy}>
        <p><b style={{display: 'block', textAlign: 'center', margin: 20}}>DATENSCHUTZ-BESTIMMUNGEN</b></p>
    <p><b>Aktualisiert!</b></p>

    <p>Unsere Datenschutzrichtlinie wurde am 20. November 2020 aktualisiert. Wir haben die Datenschutzrichtlinie vorne
        und hinten so überarbeitet
        Ab diesem Datum kann diese Datenschutzrichtlinie Datenschutzdetails zur Verwaltung Ihrer persönlichen Daten
        enthalten
        Informationen zur Software und den Diensten "Qingping Door/Window Contact Sensor" von Qingping Technology (Peking)
        Co., Ltd. (im Folgenden als "Qingping" bezeichnet)</p>

    <p>Bitte nehmen Sie sich einen Moment Zeit, um sich mit unseren Datenschutzpraktiken vertraut zu machen und uns
        mitzuteilen, ob Sie welche haben
        Fragen.</p>

    <p>UNSER ENGAGEMENT FÜR SIE</p>

    <p>Diese Datenschutzrichtlinie legt fest, wie Qingping und seine verbundenen Unternehmen ("wir", "unser" oder "uns")
        sammeln, verwenden,
        Offenlegung, Verarbeitung und Schutz von Informationen, die Sie uns bei Verwendung von "Qingping Door/Window Contact Sensor" geben
        Software und Dienstleistungen. Sollten wir Sie bitten, bestimmte Informationen anzugeben, anhand derer Sie
        identifiziert werden können, wann
        Wenn Sie die Software und Dienste "Qingping Door/Window Contact Sensor" verwenden, wird diese nur in Übereinstimmung mit
        diesem Datenschutz verwendet
        Richtlinien und / oder unsere Allgemeinen Geschäftsbedingungen für Benutzer.</p>

    <p>Die Datenschutzrichtlinie wurde speziell für Sie entwickelt und es ist wichtig, dass Sie ein umfassendes
        Verständnis haben
        unserer persönlichen Datenerfassungs- und Verwendungspraktiken sowie des vollen Vertrauens, das Sie letztendlich
        haben
        Kontrolle aller persönlichen Informationen, die Qingping zur Verfügung gestellt werden.</p>

    <p>In dieser Datenschutzrichtlinie bedeutet "persönliche Informationen" Informationen, die zur Identifizierung einer
        Person verwendet werden können.
        entweder allein aus diesen Informationen oder aus diesen Informationen in Kombination mit anderen Informationen,
        auf die Qingping Zugriff hat
        über diese Person.</p>

    <p>Durch die Verwendung der Software und Dienste "Qingping Door/Window Contact Sensor" wird davon ausgegangen, dass Sie
        gelesen und bestätigt haben
        akzeptierte alle hier in der Datenschutzrichtlinie aufgeführten Bestimmungen, einschließlich aller Änderungen,
        die wir von Zeit zu Zeit vornehmen
        Zeit. Zur Einhaltung geltender Gesetze, einschließlich lokaler Datenschutzgesetze (z. B. allgemeine Daten)
        Schutzverordnung in der Europäischen Union) werden wir ausdrücklich die vorherige ausdrückliche Zustimmung zu
        dem jeweiligen einholen
        Verarbeitung (z. B. automatisierte individuelle Entscheidungsfindung) spezieller Kategorien personenbezogener
        Daten. Wir sind verpflichtet
        zum Schutz der Privatsphäre, Vertraulichkeit und Sicherheit Ihrer persönlichen Daten durch Einhaltung
        geltende Gesetze, einschließlich Ihrer lokalen Datenschutzgesetze. Wir setzen uns gleichermaßen dafür ein, dass
        alle
        Unsere Mitarbeiter und Vertreter halten diese Verpflichtungen ein.</p>

    <p>Letztendlich wollen wir das Beste für alle unsere Benutzer. Sollten Sie Bedenken hinsichtlich unserer
        Datenverarbeitung haben
        Wenden Sie sich wie in dieser Datenschutzrichtlinie zusammengefasst an unseren Datenschutzbeauftragten unter <a
            href="mailto: privacy@qingping.co"> privacy@qingping.co </a>, um auf Ihre spezifischen Bedenken einzugehen.
        Wir werden sein
        Gerne sprechen wir sie direkt an.</p>

    <p> WELCHE INFORMATIONEN WERDEN ERFASST UND WIE VERWENDEN WIR SIE? </p>
    <p> ERFASSTE INFORMATIONSTYPEN </p>

    <p> Um Ihnen unsere Dienste anbieten zu können, bitten wir Sie, die erforderlichen persönlichen Daten anzugeben
        bieten Ihnen diese Dienste an. Wenn Sie Ihre persönlichen Daten nicht angeben, können wir diese möglicherweise
        nicht bereitstellen
        Sie mit unseren Produkten oder Dienstleistungen. </p>

    <p> Wir sammeln nur die Informationen, die für die angegebenen, expliziten und legitimen Zwecke erforderlich sind
        nicht in einer Weise weiterverarbeitet werden, die mit diesen Zwecken nicht vereinbar ist. Wir können die
        folgenden Typen sammeln
        von Informationen (die persönliche Informationen sein können oder nicht): </p>

    <ul>
        <li style={{listStyleType: 'desc'}}> <b> Kontoinformationen </b>: Wir sammeln möglicherweise Informationen über
            Ihr Mi-Konto.
            einschließlich Mi-ID, Spitzname und oben genannten Informationen die Mi-Konten, mit denen das Gerät geteilt
            wird. </li>

        <li style={{listStyleType: 'desc'}}> <b> Geräteinformationen </b>: Möglicherweise erfassen wir Informationen über
            Ihr Gerät.
            einschließlich Name, Modell, ID, ID, Seriennummer, Firmware-Versionsnummer, Hardware-Versionsnummer,
            Installationsort (z. B. Wohnzimmer), Firmware-Version und MAC-Adresse Ihres Geräts. </li>

        <li style={{listStyleType: 'desc'}}> <b> Informationen zur Gerätenutzung </b>: Möglicherweise erfassen wir
            Bewegung und Umgebungslicht
            vom Sensor erkannt und Ein / Aus-Status des Geräts. </li>

        <li style={{listStyleType: 'desc'}}> <b> Netzwerkinformationen </b>: Möglicherweise sammeln wir
                netzwerkbezogene Informationen.
                einschließlich Signalstärke von BLE, MAC-Adresse, aktuellem und vorherigem Status des Geräts im
                Netzwerk.
        </li>
        <li style={{listStyleType: 'desc'}}> <b> Informationen zu Bluetooth-Geräten </b>: Informationen zu anderen
                Geräten
                (z. B. Qingping Temp & RH Monitor Lite), die über das Gerät mit dem Netzwerk verbunden sind,
                einschließlich
                der
                Modell, DID, Name, Status, Bluetooth-Signalstärke, MAC-Adresse von Bluetooth-Geräten. </li>

        <li style={{listStyleType: 'desc'}}> <b> Feedback-bezogene Informationen </b>: Wenn Sie die Feedback-Funktion
            verwenden, werden wir
            Möglicherweise werden das Fehlerprotokoll sowie die von Ihnen angegebenen Kontaktdetails und
            Feedbackbeschreibungen erfasst. </li>
    </ul>

    <p> WIE DIE PERSÖNLICHEN INFORMATIONEN VERWENDET WERDEN </p>

    <p> Personenbezogene Daten werden für die Bereitstellung von Diensten und / oder Produkten für Sie sowie für die
        Einhaltung gesetzlicher Bestimmungen gesammelt
        Teil nach geltendem Recht. Sie erklären sich hiermit einverstanden, dass wir personenbezogene Daten
        verarbeiten und an unsere weitergeben dürfen
        verbundene Unternehmen (die in den Bereichen Kommunikation, soziale Medien, Technologie und Cloud tätig
        sind), Dritter
        Party Service Provider (unten definiert) für die in dieser Datenschutzrichtlinie angegebenen Zwecke. </p>
    <p> Wir können Ihre persönlichen Daten für folgende Zwecke verwenden: </p>
    <ul>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> Bereitstellen, Verarbeiten, Verwalten, Verbessern und
            Entwicklung unserer Waren und / oder Dienstleistungen für Sie, einschließlich Kundendienst und
            Kundenbetreuung sowie für Dienstleistungen am
            Ihr Gerät oder über unsere Websites. </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> Kommunikation mit Ihnen bezüglich Ihres Geräts, Ihrer
            Dienste oder
            sonstige häufig gestellte Fragen (z. B. Aktualisierungen, Kundensupport, Informationen zu unseren
            Aktivitäten und
            Benachrichtigungen). </li>

        <li style={{marginLeft: 15}}> Hier finden Sie weitere Informationen zur Verwendung Ihrer Daten
            (einschließlich persönlicher Daten)
            Informationen): </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> <b> So greifen Sie auf das Gateway zu </b>: Wenn das
            Bluetooth-Gateway aktiviert ist
            Wenn das Mi Beacon-Protokoll aktiviert ist, können in der Nähe befindliche Mi Bluetooth-Geräte in diesem
            Konto eine Verbindung zum Internet herstellen
            über das Gerät. Zu diesem Zweck sammeln wir Informationen über Ihr Mi-Konto und Bluetooth-Geräte.
            Um Bluetooth-Geräte mit dem Internet zu verbinden, müssen Sie zuerst Ihr Gateway-Gerät mit dem Internet
            verbinden.
            Zu diesem Zweck sammeln wir Informationen über Ihr Gerät und Ihr Netzwerk. </li>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> <b> So aktualisieren Sie die Firmware </b>: Sie
            können Ihr Gerät aktualisieren
            Firmware auf die neueste Version, um verschiedene neue Funktionen zu genießen. Zu diesem Zweck werden
            wir Ihre abholen
            Firmware-Versionsnummer des Geräts </li>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> <b> So sammeln Sie Benutzer-Feedback </b>: Das von
            Ihnen bereitgestellte Feedback
            ist äußerst wertvoll, um unsere Dienstleistungen zu verbessern. Um Ihr Feedback zu verfolgen, Qingping
            Die Technologie kann die von Ihnen angegebenen persönlichen Informationen verwenden, um Sie zu
            kontaktieren und Aufzeichnungen zu führen. </li>
    </ul>

    <p> DIREKTES MARKETING </p>
    <p> Wir können Ihre E-Mail-Adresse und Ihre Mi-Konto-ID verwenden, um Ihnen Marketingmaterialien zu Waren und Waren
        zur Verfügung zu stellen
        Dienstleistungen von Qingping und unseren Geschäftspartnern, die Netzwerk-, Mobilanwendungen und Cloud-Produkte
        anbieten
        und
        Dienstleistungen. Wir werden Ihre personenbezogenen Daten erst dann verwenden, wenn wir Ihre Einwilligung
        erhalten haben und eine eindeutige
        positive Handlung oder Hinweis auf keine Einwände in Übereinstimmung mit den örtlichen Datenschutzgesetzen, die
        möglicherweise
        erfordern eine gesonderte ausdrückliche Zustimmung. Sie haben das Recht, unsere vorgeschlagene Verwendung Ihrer
        persönlichen Daten abzulehnen
        zum
        Direktmarketing. Wenn Sie bestimmte Arten von E-Mail-Mitteilungen nicht mehr erhalten möchten, können Sie
        E-Mails senden
        zu
        <a href="mailto:privacy@qingping.co"> privacy@qingping.co </a>. Wir werden Ihre personenbezogenen Daten nicht an
        weiterleiten
        unsere
        Geschäftspartner zur Verwendung durch unsere Geschäftspartner im Direktmarketing.
    </p>

    <p> COOKIES UND ANDERE TECHNOLOGIEN (NUR BEIM BROWSING HTTPS://QINGPING.CO/) </p>
    <ul>
        <li style={{listStyleType: 'desc'}}> <b> Welche Art von Informationen werden gesammelt und wie
                sind sie?
                gebraucht? </b>Technologien wie Cookies, Tags und Skripte werden von Qingping Technology und unserem Dritten
                verwendet
                Party Service Provider. Diese Technologien werden zur Analyse von Trends, zur Verwaltung der Website,
                Verfolgen Sie die Bewegungen der Benutzer auf der Website und sammeln Sie demografische Informationen
                über unsere Benutzer
                Base
                als Ganzes. Möglicherweise erhalten wir Berichte über den Einsatz dieser Technologien durch diese
                Unternehmen
                individuelle sowie aggregierte Basis. </li>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> <b> Protokolldateien </b>: Wie die meisten Websites
            sammeln
            wir
            Spezifisch
            Informationen und speichern Sie es in den Protokolldateien. Diese Informationen können Internetprotokoll
            (IP)
            enthalten.
            Adressen,
            Browsertyp, Internetdienstanbieter (ISP), Verweis- / Beendigungsseiten, Betriebssystem, Datums- /
            Zeitstempel,
            und / oder Clickstream-Daten. Wir verknüpfen diese automatisch gesammelten Daten nicht mit anderen
            Informationen, die wir sammeln
            über dich. </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> <b> Werbung </b>: Wir arbeiten mit unserem Dritten
            zusammen
            Dienstleister, um entweder Werbung auf unserer Website anzuzeigen oder unsere Werbung auf anderen zu
            verwalten
            Websites.
            Unser Drittanbieter verwendet möglicherweise Technologien wie Cookies, um Informationen über Ihre Daten zu
            sammeln
            Aktivitäten auf dieser Website und anderen Websites, um Ihnen Werbung basierend auf Ihrem Surfen
            bereitzustellen
            Aktivitäten und Interessen. Wenn Sie möchten, dass diese Informationen nicht zum Zwecke der Bedienung
            verwendet
            werden
            Bei interessenbezogenen Anzeigen können Sie Nachrichten an privacy@qingping.co senden, um diese
            abzubestellen.
        </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> <b> Mobile Analytics </b>: In einigen unserer Handys
            Anwendungen, bei denen wir mobile Analysesoftware verwenden, um die Funktionalität unserer Software besser
            verstehen zu können
            Mobile Software auf Ihrem Telefon. Diese Software zeichnet möglicherweise Informationen auf, z. B. wie oft
            Sie
            die verwenden
            Anwendung, die Ereignisse, die innerhalb der Anwendung auftreten, aggregierte Nutzung, Leistungsdaten und wo
            Abstürze treten innerhalb der Anwendung auf. Wir verknüpfen die Informationen, die wir in der Analyse
            speichern,
            nicht
            Software
            auf alle persönlichen Informationen, die Sie in der mobilen Anwendung übermitteln. </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> <b> Lokaler Speicher - HTML5 / Flash </b>: Wir verwenden
            Local
            Lager
            Objekte (LSOs) wie HTML5 oder Flash zum Speichern von Inhalten und Einstellungen. Dritte, mit denen wir
            Partner
            um bestimmte Funktionen auf unseren Websites bereitzustellen oder Werbung basierend auf Ihrer
            Webbrowsing-Aktivität anzuzeigen
            Verwenden Sie auch HTML5- oder Flash-Cookies, um Informationen zu sammeln und zu speichern. Verschiedene
            Browser
            bieten möglicherweise ihre eigenen an
            Verwaltungstool zum Entfernen von HTML5-LSOs. </li>
    </ul>

    <p> MIT WEM WIR IHRE INFORMATIONEN TEILEN </p>
    <p> Wir verkaufen keine persönlichen Daten an Dritte. </p>
    <p> Wir können Ihre persönlichen Daten gelegentlich an Dritte weitergeben (wie unten beschrieben), um dies zu tun
        zur Verfügung stellen
        die von Ihnen angeforderten Produkte oder Dienstleistungen. </p>
    <p> Die Weitergabe an Drittanbieter und verbundene Unternehmen, die in diesem Abschnitt aufgeführt sind, kann
        erfolgen
        unten. Im
        In jedem in diesem Abschnitt beschriebenen Fall können Sie sicher sein, dass Qingping nur Ihre persönlichen
        Daten weitergibt
        Information
        in Übereinstimmung mit Ihrer Zustimmung. Mit Ihrer Zustimmung zu Qingping werden Subprozessoren für die
        Verarbeitung von beauftragt
        Ihre
        persönliche Informationen. Sie sollten dies wissen, wenn Qingping Ihre persönlichen Daten an Dritte weitergibt
        Der Dienstanbieter wird dies unter allen in diesem Abschnitt beschriebenen Umständen von Qingping vertraglich
        festlegen
        das
        Dritte unterliegen Praktiken und Verpflichtungen zur Einhaltung der geltenden lokalen Datenschutzgesetze.
        Qingping stellt vertraglich sicher, dass Drittanbieter die Privatsphäre einhalten
        Standards
        die für sie in Ihrem Heimatland gelten. </p>

    <p> TEILEN MIT UNSERER GRUPPE UND DIENSTLEISTER VON DRITTANBIETERN </p>
    <p> Um einen reibungslosen Geschäftsbetrieb zu gewährleisten und Ihnen die vollen Funktionen unserer Produkte zur
        Verfügung zu stellen
        und
        Dienstleistungen können wir Ihre persönlichen Daten von Zeit zu Zeit an andere Qingping-Partner weitergeben
        Unternehmen oder
        unsere Drittanbieter, die unsere Posthäuser, Lieferservice-Anbieter,
        Telekommunikation
        Unternehmen, Rechenzentren, Datenspeicher, Kundendienstanbieter, Werbung und Marketing
        Bedienung
        Anbieter und Vertreter von Qingping. Solche Drittanbieter würden Ihre Daten verarbeiten
        persönlich
        Informationen im Namen von Qingping oder für einen oder mehrere der oben aufgeführten Zwecke. Wenn Sie nicht
        mehr möchten
        ermöglichen
        Um diese Informationen weiterzugeben, kontaktieren Sie uns bitte unter <a href="mailto:privacy@qingping.co">
            privacy@qingping.co </a>.
    </p>

    <p> TEILEN MIT ANDEREN </p>
    <p> Qingping kann Ihre persönlichen Daten ohne weitere Zustimmung offenlegen, wenn dies nach geltendem Recht
        erforderlich ist.
    </p>

    <p> INFORMATIONEN, FÜR DIE KEINE ZUSTIMMUNG ERFORDERLICH IST </p>
    <ul>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> Wir können anonymisierte Informationen und Statistiken in
            teilen
            aggregiertes Formular mit Dritten für geschäftliche Zwecke, zum Beispiel mit Werbetreibenden auf unserer
            Website, wir
            kann
            Teilen Sie ihnen Trends über die allgemeine Nutzung unserer Dienste mit, z. B. die Anzahl der Kunden in
            bestimmten
            Bevölkerungsgruppen, die bestimmte Produkte gekauft oder bestimmte Transaktionen durchgeführt haben. </li>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> Um jeden Verdacht zu vermeiden, kann Qingping Folgendes
            sammeln:
            Verwenden oder geben Sie Ihre persönlichen Daten ohne Ihre Zustimmung weiter, wenn dies durch lokale Daten
            ausdrücklich gestattet ist
            Schutzgesetze (um beispielsweise einer Vorladung nachzukommen). Wir können Ihre Informationen auch ohne
            offenlegen
            Ihre Zustimmung, wenn wir nach Treu und Glauben glauben, dass dies offengelegt werden muss, um unsere Rechte
            zu schützen, Ihre
            Sicherheit oder die Sicherheit anderer; Betrug untersuchen; oder auf Anfragen der Regierung antworten. </li>
    </ul>

    <p> SICHERHEITSSICHERHEITEN </p>
    <p> QINGPING-SICHERHEITSMASSNAHMEN </p>
    <p> Wir setzen uns dafür ein, dass Ihre persönlichen Daten sicher sind. Um unbefugte zu verhindern
        Zugriff,
        Offenlegung oder ähnliche Risiken haben wir angemessen physisch, elektronisch und verwaltungstechnisch
        eingerichtet
        Verfahren zum Schutz und zur Sicherung der Informationen, die wir aus Ihrer Verwendung von Qingping-Produkten
        sammeln und
        Dienstleistungen
        und auf Qingping-Websites. Wir werden alle zumutbaren Anstrengungen unternehmen, um Ihre persönlichen Daten zu
        schützen. </p>

    <p> Alle Ihre persönlichen Daten werden auf sicheren Servern gespeichert, die in kontrollierten Einrichtungen
        geschützt sind. Wir
        Klassifizieren Sie Ihre Daten nach Wichtigkeit und Sensibilität und stellen Sie sicher, dass Ihre persönlichen
        Daten die
        höchste Sicherheitsstufe. Wir stellen sicher, dass unsere Mitarbeiter und Drittanbieter, die auf die zugreifen
        Informationen, die Sie bei der Bereitstellung unserer Produkte und Dienstleistungen unterstützen, unterliegen
        strengen vertraglichen Bestimmungen
        Vertraulichkeit
        Verpflichtungen und können diszipliniert oder gekündigt werden, wenn sie diesen Verpflichtungen nicht
        nachkommen. Wir haben spezielle
        Zugriff
        Steuerelemente für die Cloud-basierte Datenspeicherung. Alles in allem überprüfen wir regelmäßig unsere
        Informationssammlung,
        Lagerungs- und Verarbeitungspraktiken, einschließlich physischer Sicherheitsmaßnahmen, zum Schutz vor unbefugten
        Personen
        Zugriff
        und verwenden. </p>

    <p> Wir werden alle praktikablen Schritte unternehmen, um Ihre persönlichen Daten zu schützen. Sie sollten sich
        jedoch dessen bewusst sein
        das
        Die Nutzung des Internets ist nicht ganz sicher. Aus diesem Grund können wir die Sicherheit nicht garantieren
        Integrität von
        alle persönlichen Informationen, die von Ihnen oder über das Internet an Sie übertragen werden. </p>
    <p> WAS SIE TUN KÖNNEN </p>
    <ul>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> Sie können Ihren Beitrag zum Schutz Ihrer persönlichen
            Daten leisten
            Informationen, indem Sie Ihr Login-Passwort oder Ihre Kontoinformationen nur an diese Person weitergeben
            ist
            ordnungsgemäß von Ihnen autorisiert. Wann immer Sie sich als Mi Account-Benutzer auf Qingping-Websites
            anmelden, insbesondere auf
            Wenn Sie den Computer eines anderen Benutzers oder ein öffentliches Internet-Terminal verwenden, sollten Sie
            sich immer am Ende Ihres Terminals abmelden
            Sitzung. </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> Qingping kann nicht für Fehler in verantwortlich gemacht
            werden
            Sicherheit
            verursacht durch Zugriffe Dritter auf Ihre persönlichen Daten, weil Sie Ihre nicht gespeichert haben
            persönliche Informationen privat. Ungeachtet des Vorstehenden müssen Sie uns unverzüglich benachrichtigen,
            wenn dies der Fall ist
            irgendein
            unbefugte Nutzung Ihres Kontos durch einen anderen Internetnutzer oder sonstige Sicherheitsverletzung. </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> Ihre Unterstützung hilft uns, die Privatsphäre von zu
            schützen
            Ihre
            persönliche Informationen. </li>
    </ul>
    <p> RETENTIONSPOLITIK </p>
    <p> Wir speichern personenbezogene Daten (falls erforderlich), um den Zweck zu erfüllen, für den sie gesammelt
        wurden, oder
        zum
        Einhaltung der geltenden gesetzlichen Anforderungen oder Genehmigungen. Solange wir vernünftigerweise schließen
        können, dass a
        Retention
        von persönlichen Informationen kann den Zweck, für den sie gesammelt wurden, nicht erfüllen, und wir müssen dies
        nicht mehr tun
        behalten
        die Informationen aus rechtlichen Gründen, zur Beilegung von Streitigkeiten, zur Durchsetzung unserer
        Vereinbarungen oder aus anderen Gründen
        Aus geschäftlichen Gründen werden wir diese personenbezogenen Daten nicht mehr aufbewahren oder alle
        Möglichkeiten ausschließen
        im
        welche solchen persönlichen Informationen mit bestimmten Personen verbunden sein können. </p>

    <p> Alle persönlichen Daten auf dem Gerät werden gelöscht, wenn Sie Ihr Gerät auf die Werkseinstellungen
        zurücksetzen
        die Einstellungen.
        Um eine Hard-Reset-Funktion zu implementieren, halten Sie nach dem Einschalten des Geräts die Taste gedrückt,
        bis die
        Orange
        Licht blinkt, dh der Reset ist erfolgreich. Beachten Sie, dass ein Hard-Reset nicht rückgängig gemacht werden
        kann
        sicher
        dass Sie die Informationen nicht mehr benötigen. </p>

    <p> ZUGRIFF AUF ANDERE FUNKTIONEN AUF IHREM GERÄT </p>
    <p> Unsere Anwendungen benötigen möglicherweise Zugriff auf bestimmte Funktionen Ihres Geräts, z. B. den
        Wi-Fi-Netzwerkstatus. Diese
        Informationen werden verwendet, damit die Anwendungen auf Ihrem Gerät ausgeführt werden können und Sie mit dem
        interagieren können
        Anwendungen. Sie können Ihre Berechtigungen jederzeit widerrufen, indem Sie diese auf Geräteebene oder
        deaktivieren
        Kontaktaufnahme
        uns unter <a href="mailto:privacy@qingping.co"> privacy@qingping.co </a> </p>

    <p> SIE HABEN DIE KONTROLLE ÜBER IHRE INFORMATIONEN! </p>
    <p> STEUERUNG DER EINSTELLUNGEN </p>
    <p> Qingping erkennt an, dass Datenschutzbedenken von Person zu Person unterschiedlich sind. Daher bieten wir
        Beispiele für
        Wege
        Qingping bietet Ihnen die Möglichkeit, die Erfassung, Verwendung, Offenlegung oder Verarbeitung Ihrer Daten
        einzuschränken
        persönliche Informationen und Kontrolle Ihrer Datenschutzeinstellungen: </p>
    <ul>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> Geräte binden / entbinden </li>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> Anmelden oder Abmelden vom MI-Konto </li>
    </ul>

    <p> Wenn Sie uns zuvor zugestimmt haben, Ihre persönlichen Daten für die oben genannten Zwecke zu verwenden, können
        Sie dies tun
        Sie können Ihre Meinung jederzeit ändern, indem Sie uns schreiben oder eine E-Mail an <a
            href="mailto: privacy@qingping.co"> privacy@qingping.co </a>. </p>

    <p> ZUGRIFF, AKTUALISIERUNG, KORREKTUR ODER LÖSCHUNG IHRER PERSÖNLICHEN INFORMATIONEN </p>
    <ul>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> Sie haben das Recht, den Zugriff auf und / oder
            anzufordern
            Korrektur
            von anderen persönlichen Informationen, die wir über Sie haben. Wenn Sie Ihre persönlichen Daten
            aktualisieren, werden Sie
            Sie werden gebeten, Ihre Identität zu überprüfen, bevor wir mit Ihrer Anfrage fortfahren. Sobald wir
            ausreichend erhalten
            Informationen, um Ihre Anfrage nach Zugriff auf oder Korrektur Ihrer persönlichen Daten zu erfüllen, wir
            soll
            Beantworten Sie Ihre Anfrage innerhalb eines Zeitraums, der unter Ihrem geltenden Datenschutz festgelegt ist
            Rechtsvorschriften.
        </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> Eine Kopie Ihrer persönlichen Daten, die von gesammelt
            und verarbeitet wurden
            uns
            wird Ihnen auf Ihre Anfrage kostenlos zur Verfügung gestellt. Für jede zusätzliche Anfrage der gleichen
            Informationen,
            wir
            kann eine angemessene Gebühr erheben, die auf den tatsächlichen Verwaltungskosten gemäß den geltenden
            Gesetzen basiert. </ li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> Wenn Sie den Zugriff auf Ihre persönlichen Daten
            anfordern möchten
            Daten
            von uns gehalten oder wenn Sie glauben, dass Informationen, die wir über Sie haben, falsch oder
            unvollständig sind, bitte
            schreiben
            an oder senden Sie uns so schnell wie möglich eine E-Mail an die unten angegebene E-Mail-Adresse. E-Mail: <a
                href="mailto: privacy@qingping.co"> privacy@qingping.co </a>. </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> Wenn Sie ein Benutzer der Europäischen Union unter
            Allgemeine Daten sind
            Schutz
            Gemäß der DSGVO haben Sie das Recht, von uns die Löschung Ihrer persönlichen Daten zu erhalten. Wir
            soll
            Berücksichtigen Sie die Gründe für Ihre Löschanforderung und ergreifen Sie angemessene, auch technische
            Maßnahmen
            Maße,
            wenn die Gründe für die DSGVO gelten. </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> Wenn Sie ein Benutzer der Europäischen Union unter GDPR
            sind, haben Sie die
            Recht
            um von uns die Einschränkung der Verarbeitung Ihrer persönlichen Daten zu erhalten. Wir werden die Gründe
            betrachten
            bezüglich Ihrer Restriktionsanfrage. Wenn die Gründe für die DSGVO gelten, werden wir nur Ihre persönlichen
            bearbeiten
            Informationen unter anwendbaren Umständen in der DSGVO und informieren Sie vor der Einschränkung der
            Verarbeitung
            ist
            angehoben. </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> Wenn Sie ein Benutzer der Europäischen Union unter GDPR
            sind, haben Sie die
            Recht
            nicht einer Entscheidung unterliegen, die ausschließlich auf der automatisierten Verarbeitung,
            einschließlich der Profilerstellung, beruht
            produziert
            rechtliche Auswirkungen auf Sie oder in ähnlicher Weise erheblich auf Sie. </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> Wenn Sie ein Benutzer der Europäischen Union unter GDPR
            sind, haben Sie die
            Recht
            um Ihre persönlichen Informationen in einem strukturierten, häufig verwendeten Format zu erhalten und die
            Informationen zu übertragen
            zu
            ein anderer Datencontroller. </li>
    </ul>

    <p> Widerruf der Zustimmung </p>
    <ul>
        <li style={{listStyleType: 'desc', marginLeft: 15}}> Sie können Ihre Einwilligung zur Sammlung und Verwendung
            widerrufen
            und / oder Offenlegung Ihrer persönlichen Daten in unserem Besitz oder unter unserer Kontrolle durch
            Übermittlung einer Anfrage.
            Diese
            Dies kann durch Senden einer Anfrage an <a href="mailto:privacy@qingping.co"> privacy@qingping.co </a>
            erfolgen. Wir werden
            Bearbeiten Sie Ihre Anfrage innerhalb einer angemessenen Zeit ab dem Zeitpunkt, an dem die Anfrage gestellt
            wurde, und danach nicht mehr
            sammeln,
            Verwendung und / oder Offenlegung Ihrer persönlichen Daten gemäß Ihrer Anfrage. </li>

        <li style={{listStyleType: 'desc', marginLeft: 15}}> Bitte beachten Sie, dass Ihr Widerruf der Einwilligung
            möglich ist
            zu bestimmten rechtlichen Konsequenzen führen. Abhängig vom Umfang Ihres Widerrufs der Einwilligung für uns
            Wenn Sie Ihre persönlichen Daten verarbeiten, kann dies bedeuten, dass Sie "Qingping Door/Window Contact Sensor" nicht
            genießen können Software und Dienste. </li>
    </ul>
    <p> ÜBERTRAGUNG VON PERSÖNLICHEN INFORMATIONEN AUSSERHALB IHRES GERICHTSSTANDES </p>
    <p> In dem Maße, in dem wir möglicherweise personenbezogene Daten außerhalb Ihrer Gerichtsbarkeit übertragen müssen,
        ob an unsere
        verbundene Unternehmen oder Drittanbieter, wir werden dies in Übereinstimmung mit den geltenden tun
        Rechtsvorschriften. Im
        Insbesondere stellen wir sicher, dass alle Überweisungen den Anforderungen Ihrer jeweiligen entsprechen
        lokale Datenschutzgesetze durch Einführung angemessener Schutzmaßnahmen. Sie werden das Recht haben zu sein
        informiert über
        die entsprechenden Sicherheitsvorkehrungen, die Qingping für diese Übertragung Ihrer persönlichen Daten
        getroffen hat </p>

    <p> Qingping kann Einrichtungen in Übersee verwenden, die von Qingping betrieben und kontrolliert werden, um Ihre
        persönlichen Daten zu verarbeiten oder zu sichern
        Information. Derzeit verfügt Qingping über Rechenzentren in Peking, den USA, Singapur und Deutschland. Wenn du
        sind
        Benutzer der Europäischen Union unter DSGVO, Ihre persönlichen Daten werden auf dem deutschen Server
        gespeichert. Diese in Übersee
        Gerichtsbarkeiten können Datenschutzgesetze haben oder nicht, die denen in im Wesentlichen ähnlich sind
        Ihre
        Heimatgerichtsbarkeit. Wir können Ihre persönlichen Daten an unsere Einrichtungen in Übersee übertragen und dort
        speichern.
        Jedoch,
        Dies ändert nichts an unseren Verpflichtungen, Ihre persönlichen Daten entsprechend zu schützen
        Datenschutzerklärung. </p>

    <p> VERSCHIEDENE MINDERJÄHRIGE </p>
    <p> Wir betrachten es als die Verantwortung der Eltern, die Nutzung unserer Produkte und Dienstleistungen durch ihre
        Kinder zu überwachen.
        Es ist jedoch unsere Politik, keine personenbezogenen Daten von Minderjährigen zu verlangen oder das Senden von
        Daten anzubieten
        Werbung
        Materialien für Personen in dieser Kategorie. </p>
    <p> Qingping sucht oder beabsichtigt nicht, persönliche Informationen von Minderjährigen zu erhalten. Sollte ein
        Elternteil oder
        Vormund hat Grund zu der Annahme, dass ein Minderjähriger Qingping ohne ihre persönlichen Daten zur Verfügung
        gestellt hat
        Bitte kontaktieren Sie uns, um sicherzustellen, dass die persönlichen Daten entfernt werden und der
        Minderjährige
        Abmeldungen
        von einem der anwendbaren Qingping-Dienste. </p>

        <p> Rangfolge </p>
            <p> Wenn Sie unseren geltenden Benutzervereinbarungen zugestimmt haben, im Falle von Inkonsistenzen zwischen diesen Benutzern
                Vereinbarungen
                und dieser Datenschutzrichtlinie haben solche Nutzungsvereinbarungen Vorrang. </p>
        
            <p> AKTUALISIERUNGEN DER DATENSCHUTZRICHTLINIE </p>
            <p> Wir überprüfen unsere Datenschutzrichtlinie regelmäßig und können diese Datenschutzrichtlinie aktualisieren, um Änderungen an unserer zu berücksichtigen
                Informationspraktiken. Wenn wir wesentliche Änderungen an unserer Datenschutzrichtlinie vornehmen, werden wir Sie per E-Mail benachrichtigen (gesendet)
                zu
                die in Ihrem Konto angegebene E-Mail-Adresse) oder veröffentlichen Sie die Änderungen auf allen Qingping-Websites oder über
                unsere
                Software, damit Sie über die von uns gesammelten Informationen und deren Verwendung informiert sind. Solche Änderungen an unserer
                Privatsphäre
                Die Richtlinien gelten ab dem Datum des Inkrafttretens, wie in der Bekanntmachung oder auf der Website angegeben. Wir ermutigen Sie dazu
                Überprüfen Sie diese Seite regelmäßig auf die neuesten Informationen zu unseren Datenschutzpraktiken. Ihre fortgesetzte Verwendung von
                Produkte und Dienstleistungen auf Websites, Mobiltelefonen und / oder anderen Geräten gelten als akzeptiert
                das
                aktualisierte Datenschutzrichtlinie. Wir werden Ihre neue Zustimmung einholen, bevor wir weitere persönliche Informationen von Ihnen sammeln
                oder
                wenn wir Ihre persönlichen Daten für neue Zwecke verwenden oder offenlegen möchten. </p>
        
            <p> MUSS ICH ALLGEMEINEN GESCHÄFTSBEDINGUNGEN VON DRITTANBIETERN ZUSTIMMEN? </p>
            <p> Unsere Datenschutzrichtlinie gilt nicht für Produkte und Dienstleistungen, die von Dritten angeboten werden. Qingping Produkte und
                Dienstleistungen können Produkte, Dienstleistungen und Links von Dritten zu Websites Dritter umfassen. Wenn Sie verwenden
                eine solche
                Produkte oder Dienstleistungen, können sie auch Ihre Informationen sammeln. Aus diesem Grund empfehlen wir Ihnen dringend
                lesen
                die Datenschutzbestimmungen des Drittanbieters, da Sie sich Zeit genommen haben, unsere zu lesen. Wir sind nicht verantwortlich und können nicht
                Kontrollieren Sie, wie Dritte personenbezogene Daten verwenden, die sie von Ihnen sammeln. Unsere Datenschutzerklärung gilt nicht
                gelten für andere Websites, die über unsere Dienste verlinkt sind. </p>
        
            <p> <b> UNSER ANSATZ ZUR VERWALTUNG IHRER PERSÖNLICHEN INFORMATIONEN NACH DSGVO </b> </p>
            <p> Wenn Sie ein Benutzer der Europäischen Union unter der DSGVO sind, bietet Qingping einen systematischen Ansatz für die Verwaltung personenbezogener Daten
                tief
                Einbindung unserer Mitarbeiter, Managementprozesse und Informationssysteme durch Anwendung einer Risikomanagementmethode.
                Nach Angaben der DSGVO hat beispielsweise (1) Qingping einen Datenschutzbeauftragten (DPO) eingerichtet, der für die Daten verantwortlich ist
                Schutz und der Kontakt des Datenschutzbeauftragten ist <a href="mailto:dpo@qingping.co"> dpo@qingping.co </a>; (2) Qingping
                einen Vertreter in Europa benennen, und der Ansprechpartner des Vertreters ist <a
                    href = "mailto: Representative@qingping.co"> Representative@qingping.co </a>; （3） Verfahren wie Daten
                Schutz
                Folgenabschätzung (DPIA). </p>
        
            <p> KONTAKTIEREN SIE UNS </p>
            <p> Wenn Sie Kommentare oder Fragen zu dieser Datenschutzrichtlinie oder Fragen zu Qingping haben
                Wenn Sie Ihre persönlichen Daten erfassen, verwenden oder weitergeben, wenden Sie sich bitte an unseren Datenschutzbeauftragten unter
                das
                Adresse unten unter Bezugnahme auf "Datenschutzrichtlinie": </p>
            <p> Qingping Technology (Peking) Co., Ltd. <br />
                Raum 2006, Block B, Grönland-Zentrum, Wangjing, Distrikt Chaoyang, Peking 100102 <br />
                China <br />
                E-Mail: <a href="mailto:privacy@qingping.co"> privacy@qingping.co </a> <br />
            </p>
            <p> Vielen Dank, dass Sie sich die Zeit genommen haben, unsere Datenschutzbestimmungen zu verstehen! </p>
        </div>
      </div>
    );
  }
}

export default PlGprivacy;
