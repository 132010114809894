import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Peighth.module.scss';

class Peighth extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { // 初始state
            index: 0,
            opacity: 1
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
        // this.refs.Peighth.style.backgroundSize = '1920px';
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const clientHeight = document.documentElement.clientHeight;
        const textRect = this.refs.Peighth.getBoundingClientRect();
        if (this.refs.Peighth && clientHeight >= textRect.bottom - 300) {
            setTimeout(() => {
                this.refs.text.style.opacity = 1;
                this.refs.text.style.top = '0';
            }, 0);
        }
    }
    render() {
        return (
            <div className={s.Peighth} ref="Peighth">
                <div className={s.wrap}>
                    <div className={s.text} ref="text">
                        <span className={s.large}>
                            <FormattedMessage id="p_eighth_title" />
                        </span>
                    </div>
                    <div className={s.p_eighth_img} />
                    <div className={s.p_eighth_email}>
                        <FormattedMessage id="p_eighth_email" values={{ link: <a href="https://developer.qingping.co" target="_blank" rel="noopener noreferrer">developer.qingping.co</a> }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Peighth;

