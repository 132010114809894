import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import s from './Sparam.module.scss'

class Sparam extends React.PureComponent {
  render() {
    return (
      <div className={s.box}>
        <div className={s.Sparam}>
          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="exterior" />
            </div>
            <div className={s.cell}>
              <div className={s.sizeImg}>
                <div className={s.top_side}>
                  <div className={s.left_left}>
                    <div className={s.left_img}></div>
                    <div className={s.left_bottom}></div>
                    <div className={s.bottom_num}>
                      <FormattedMessage id="chicken_bottom" />
                    </div>
                  </div>
                  <div className={s.left_right}></div>
                  <div className={s.right_num}>
                    <FormattedMessage id="chicken_height" />
                  </div>
                </div>
                <div className={s.top_top}>
                  <div className={s.left_left}>
                    <div className={s.top_img}></div>
                    <div className={s.top_bottom}></div>
                    <div className={s.bottom_num}>
                      <FormattedMessage id="chicken_width" />
                    </div>
                  </div>
                  {/* <div className={s.left_right}></div>
                  <div className={s.right_num}>
                    <FormattedMessage id="chicken_bottom_height" />
                  </div> */}
                </div>
              </div>
              <ul>
                <li className={s.special_li}>
                  <span className={s.type}>
                    <FormattedMessage id="p_size" />
                  </span>
                  <FormattedMessage id="chicken_size" values={{ br: ' ' }} />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_color" />
                  </span>
                  <FormattedMessage id="chicken_color" />
                </li>
              </ul>
            </div>
          </div>

          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="range" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                    <span className={s.type}>
                      <FormattedMessage id="work_temp" />
                    </span>
                    <FormattedMessage id="chicken_temp" />
                  </li>
                  <li>
                    <span className={s.type}>
                      <FormattedMessage id="work_humi" />
                    </span>
                    <FormattedMessage id="chicken_humi" />
                  </li>
              </ul>
              <p>
                <FormattedMessage id="under_ninty" />
              </p>
            </div>
          </div>

          <div className={s.row}>
            <div className={s.name}>
              <FormattedMessage id="p_specifications" />
            </div>
            <div className={s.cell}>
              <ul>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_name" />
                  </span>
                  <FormattedMessage id="chicken_name" values={{ br: '' }} />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_model" />
                  </span>
                  <FormattedMessage id="chicken_model" />
                </li>
                <li className={s.special_li}>
                  <span className={s.type}>
                    <FormattedMessage id="p_weight" />
                  </span>
                  <FormattedMessage
                    id="chicken_weight"
                    values={{ br: ' ' }}
                  />
                </li>
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="mp_ble_type" />
                  </span>
                  <FormattedMessage id="chicken_ble" />
                </li>
                
                <li>
                  <span className={s.type}>
                    <FormattedMessage id="p_battery" />
                  </span>
                  <FormattedMessage id="chicken_battery" />
                </li>
              </ul>
            </div>
          </div>

          <div className={s.pack}>
            <div className={s.name}>
              <FormattedMessage id="p_pack" />
            </div>
            <div className={s.cell}>
              <ul className={s.packWrap}>
                <li>
                  <i className={s.pack_1} />
                  <FormattedMessage id="chicken_name" values={{ br: <br /> }} />
                </li>
                <li>
                  <i className={s.pack_2} />
                  <FormattedMessage id="chicken_battery" />
                </li>
                <li>
                  <i className={s.pack_3} />
                  <FormattedMessage id="p_instructions" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let SParam = connect(mapStateToProps)(Sparam)

export default SParam
