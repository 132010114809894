import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

import s from './Pfourth.module.scss'

class Pfourth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
    }
  }
  componentDidMount() {}
  componentWillUnmount() {}

  render() {
    const { locale } = this.props
    const column0 = locale.indexOf('US') > -1 ? s.column0_en : s.column0
    const column1 = locale.indexOf('US') > -1 ? s.column1_en : s.column1
    const column2 = locale.indexOf('US') > -1 ? s.column2_en : s.column2
    return (
      <div className={s.Pfourth} ref="Pfourth">
        <div className={s.left}>
          <div className={s.leftImg}></div>
        </div>
        <div className={s.text}>
          <div className={s.multiple}>
            <FormattedMessage id="p_fourth_title" values={{ br: <br /> }} />
          </div>
          <div className={s.every}>
            <FormattedMessage id="p_fourth_text1" />
          </div>
          <div className={s.table}>
            <div className={s.table_row}>
              <div style={{ opacity: 0 }} className={column0}>
                <FormattedMessage id="ph_first_text5" />
              </div>
              <div
                className={column1}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage
                  id="ph_first_text1"
                  values={{ br: '' }}
                />
              </div>
              <div
                className={column2}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage
                  id="ph_first_text2"
                  values={{ br: '' }}
                />
              </div>
            </div>
            <div className={s.table_row}>
              <div
                className={column0}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="p_fourth_text2" />
              </div>
              <div className={column1}>
                <FormattedMessage id="ph_battery_size_value" />
              </div>
              <div className={column2}>
                <FormattedMessage id="ph_battery_size_value_2" />
              </div>
            </div>
            <div className={s.table_row}>
              <div
                className={column0}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="p_fourth_text3" />
              </div>
              <div className={column1}>
                <FormattedMessage id="ph_usb_value" />
              </div>
              <div className={column2}>
                <FormattedMessage id="ph_usb_value" />
              </div>
            </div>
            <div className={s.table_row}>
              <div
                className={column0}
                style={{ opacity: 1, fontWeight: 'bold' }}
              >
                <FormattedMessage id="p_fourth_text4" />
              </div>
              <div className={column1}>
                <FormattedMessage id="ph_input_value" />
              </div>
              <div className={column2}>
                <FormattedMessage id="ph_input_value" />
              </div>
            </div>
          </div>
          <Link to="/temp-rh-monitor-pro-s/specifications">
            <div className={s.spec}>
              <FormattedMessage id="ph_first_text14" />
              <div className={s.triangle}></div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PFourth = connect(mapStateToProps)(Pfourth)

export default PFourth
