import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import s from './Sseventh.module.scss'

class Sseventh extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    if (this.refs.video) {
      this.play()
    }
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Sseventh.getBoundingClientRect()
    if (this.refs.Sseventh && clientHeight >= textRect.bottom - 300) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }

  play() {
    const element = this.refs.video
    element.play()
  }

  render() {
    const { locale } = this.props
    return (
      <div
        className={locale.indexOf('US') > -1 ? s.Sseventh_en : s.Sseventh}
        ref="Sseventh"
      >
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <div className={s.title}>
              <FormattedMessage id="dk2_six_title" values={{ br: <br /> }} />
            </div>
            <div className={s.desc}>
              <FormattedMessage id="dk2_six_desc" values={{ br: <br /> }} />
            </div>
            <div className={s.memo}>
              <FormattedMessage id="dk2_six_memo" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SSeventh = connect(mapStateToProps)(Sseventh)

export default SSeventh
