import React from 'react';

import s from './Deighth.module.scss';

const Deighth = () =>
    <div className={s.Deighth}>
        <div className={s.title}>设计独特的墙贴</div>
        <div className={s.desc}>能让米家蓝牙温湿度计轻松“上墙”</div>
        <div className={s.text}>墙贴内置磁铁，背部带有胶条，可粘在墙壁上。米家蓝牙温湿度计可以直接地吸附于墙贴上。</div>
    </div>;

export default Deighth;

