import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import s from './Sthird.module.scss'

class Sthird extends React.PureComponent {
    constructor(props) {
        super(props);
        this.setState({
            init: null
        });
    }
    render() {
        const { locale } = this.props
        return (
            <div ref="Sthird">
                <div className={s.Sthird}>
                    <div className={s.wrap}>
                        <div className={locale.indexOf('US') > -1 ? s.text_en : s.text} ref="text">
                            <span className={s.large}>
                                <FormattedMessage id="dk2_second_title" values={{br: <br />}} />
                            </span>
                            <span className={s.normal}>
                                <FormattedMessage id="dk2_second_desc" />
                            </span>
                        </div>
                        <div className={s.rightPic}>
                            <div className={locale.indexOf('US') > -1 ? s.co2_en : s.co2}></div>
                            <div className={locale.indexOf('US') > -1 ? s.pm25_en : s.pm25}></div>
                            <div className={locale.indexOf('US') > -1 ? s.pm10_en : s.pm10}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locale: state.language
  });
  
  let SThird = connect(mapStateToProps)(Sthird);

export default SThird;
