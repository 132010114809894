import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import s from './Pnineth.module.scss'

class Pnineth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
  }
  componentDidMount() {}
  componentWillUnmount() {}

  render() {
    return (
      <div className={s.Pnineth} ref="Pnineth">
        <div className={s.wrap}>
          <div className={s.large}>
            <FormattedMessage id="ph_co2_six_title" />
          </div>
          {/* <div className={s.line_top} ref="line_top" /> */}
          {/* <div className={s.line_down} ref="line_down" /> */}
          <div className={s.p_nineth_text_top} ref="p_nineth_text_top">
            <div className={s.p_nineth_title}>
              <FormattedMessage id="ph_co2_six_table_title" />
              <br />
            </div>
            <ul>
              <li className={s.nineth_subtitle}>
                <div className={s.subText}>
                  <FormattedMessage id="ph_co2_six_table_subtitle" />
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div className={s.column1}>
                  <FormattedMessage id="ph_co2_range" />
                </div>
                <div className={s.column2}>
                  <FormattedMessage id="ph_co2_value" />
                </div>
              </li>
              <li>
                <div className={s.column1}>
                  <FormattedMessage
                    id="ph_co2_precision"
                    values={{ br: ' ' }}
                  />
                </div>
                <div className={s.column2}>
                  <FormattedMessage id="ph_co2_precision_value" />
                </div>
              </li>
            </ul>
          </div>
          <div className={s.p_nineth_text_down} ref="p_nineth_text_down">
            <div className={s.p_nineth_title}>
              <FormattedMessage id="ph_co2_six_table_title_2" />
            </div>
            <ul>
              <li>
                <div className={s.column3}>
                  <FormattedMessage id="space" />
                </div>
                <div className={s.column4}>
                  <FormattedMessage id="p_nineth_temp" />
                </div>
                <div className={s.column5}>
                  <FormattedMessage id="p_nineth_humi" />
                </div>
              </li>
              <li>
                <div className={s.column3}>
                  <FormattedMessage id="ph_co2_range" />
                </div>
                <div className={s.column4}>
                  <FormattedMessage id="ph_co2_temp_value" />
                </div>
                <div className={s.column5}>
                  <FormattedMessage id="ph_co2_hum_value" />
                </div>
              </li>
              <li>
                <div className={s.column3}>
                  <FormattedMessage id="resolution" />
                </div>
                <div className={s.column4}>
                  <FormattedMessage id="p_nineth_temp_value_1" />
                </div>
                <div className={s.column5}>1%</div>
              </li>
              <li>
                <div className={s.column3}>
                  <FormattedMessage
                    id="ph_co2_precision"
                    values={{ br: <br /> }}
                  />
                </div>
                <div className={s.column6}>
                  <FormattedMessage
                    id="ph_co2_temp_value2"
                    values={{ br: <br /> }}
                  />
                  <span className={s.sub_desc}>
                    <FormattedMessage
                      id="ph_co2_temp_value2_2"
                      values={{ br: <br /> }}
                    />
                  </span>
                </div>
                <div className={s.column6}>
                  <FormattedMessage
                    id="ph_co2_hum_value2"
                    values={{ br: <br /> }}
                  />
                  <span className={s.sub_desc}>
                    <FormattedMessage
                      id="ph_co2_hum_value2_2"
                      values={{ br: <br /> }}
                    />
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Pnineth
