import React, { Component } from 'react';

import Header from './../../header';
import Param from './../param';
import Footer from './../../footer';

import s from './Dspecifications.module.scss';

class Dspecifications extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            language: null,
            position: false
        };
        this._handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        let browserLanguage = navigator.browserLanguage ? navigator.browserLanguage : navigator.language;
        switch (browserLanguage) {
            case 'en':
                browserLanguage = 'en-US';
                break;
            case 'zh':
                browserLanguage = 'zh-CN';
                break;
            default:
                browserLanguage = 'en-US';
        }
        this.setState({
            language: browserLanguage
        });
        window.addEventListener('scroll', this._handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }
    handleScroll() {
        const textRect = this.refs.Dspecifications.getBoundingClientRect();
        console.log(textRect.top);
        if (textRect.top < -50 && this.refs.Dspecifications) {
            this.setState({
                position: true
            });
        } else {
            this.setState({
                position: false
            });
        }
    }
    render() {
        const { location } = this.props;
        const path = this.props.match.path;
        return (
            <div className={s.Dspecifications} ref="Dspecifications">
                <Header location={location} language={this.state.language} path={path} navPosition={this.state.position} />
                <Param />
                <Footer location={location} />
            </div>
        );
    }
}

export default Dspecifications;
