import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import LazyLoad from 'react-lazyload'

import s from './Gsixth.module.scss'

class Gsixth extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Gsixth.getBoundingClientRect()
    if (clientHeight - 200 > textRect.top && this.refs.Gsixth) {
      setTimeout(() => {
        this.refs.title && (this.refs.title.style.opacity = 1)
        this.refs.title && (this.refs.title.style.top = '228px')
      }, 500)
    }
  }
  render() {
    return (
      <div ref="Gsixth">
        <LazyLoad height={500}>
          <div className={s.Gsixth}>
            <div className={s.warp}>
              <div className={s.img} ref="img" />
              <div className={s.title} ref="title">
                <FormattedMessage id="sixth_title" />
                <span className={s.normal} style={{ marginTop: 10 }}>
                  <FormattedMessage id="sixth_desc" />
                </span>
              </div>
            </div>
          </div>
        </LazyLoad>
      </div>
    )
  }
}

export default Gsixth
