import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import s from './style.module.scss'
import Header from './../../header'
import Footer from './../../footer'
import { Link } from 'react-router-dom'
import common from '../../../../../locale/common'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './swiper.css'
// import Selection from './components/section'
import BetterAir from './components/better-air'
// import index from '../../index/index'

import snow2_9 from '../../../../../static/video/snow2_9.mp4'
import snow2_10 from '../../../../../static/video/snow2_10.mp4'
import icon1 from '../imagesnew/fourth/icon1.png'
import icon2 from '../imagesnew/fourth/icon2.png'
import icon3 from '../imagesnew/fourth/icon3.png'
import icon4 from '../imagesnew/fourth/icon4.png'
// import banner from '../imagesnew/first/bg.jpg'

// import locale from 'antd/lib/date-picker/locale/en_US'


const pages = [
  'firstPage',
  'secondPage',
  'thirdPage',
  'fourthPage',
  'fifthPage',
  'sixthPage',
  'seventhPage',
  'eighthPage',
  'ninethPage',
  'tenthPage',
  'eleventhPage',
  'twelfthPage',
  'thirtythPage',
  'fourteenthPage',
  'fifteenthPage',
  'moreInfo',
]
const issafariBrowser =
  /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
//监测数据
const glances = [
  { id: 1, descId: 'sw2_fourth_desc_1', icon: icon1, memoId: 'sw2_fourth_desc_1_memo' },
  { id: 2, descId: 'sw2_fourth_desc_2', icon: icon2, memoId: 'sw2_fourth_desc_2_memo' },
  { id: 3, descId: 'sw2_fourth_desc_3', icon: icon3, memoId: 'sw2_fourth_desc_3_memo' },
  { id: 4, descId: 'sw2_fourth_desc_4', icon: icon4, memoId: 'sw2_fourth_desc_4_memo' },
];
class DoveOverview extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // init top hide
      sleepVideoStatus: false,
      replayStatus: false,
      position: false,
      hideGooseChoice: true,
      sensorActive: 3,
      fourthActive: 1,
      imgActive: 1,
      tab: null,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['snow2_' + local]

    window.addEventListener('scroll', this._handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }

  // 绑定定时器，10秒播放一次
  setIntervalTab() {
    const tab = setInterval(() => {
      this.handleTab(this.state.num)
    }, 3000)
    this.setState({
      tab: tab,
    })
  }

  videoRef9 = React.createRef();
  videoRef10 = React.createRef();
  opacityImg = React.createRef()

  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    // const scrollTop = document.documentElement.scrollTop;
    for (let i = 0; i < pages.length; i++) {
      const page = this.refs[pages[i] + ''].getBoundingClientRect()
      if (page.top < clientHeight - 200) {
        this.setState({ [pages[i]]: true })
      } else {
        this.setState({ [pages[i]]: false })
      }
    }

    // 滚到 page 9 时该页面的视频开始播放
    const { ninethPage, tenthPage } = this.state;
    if (ninethPage) {
      this.videoRef9.current.play();
    } else {
      this.videoRef9.current.pause();
    }
    if (tenthPage) {
      this.videoRef10.current.play();
    } else {
      this.videoRef10.current.pause();
    }
  }

  //隐藏购买链接弹窗
  hideGooseChoiceFun(isHide, e) {
    if (isHide === this.state.hideGooseChoice) return
    this.setState({
      hideGooseChoice: isHide,
    })
  }

  videoRef1 = React.createRef();
  videoRef2 = React.createRef();
  videoRef3 = React.createRef();
  videoRef4 = React.createRef();
  videoRef5 = React.createRef();

  handleTriangleVideo(index) {

    const videoRefs = [this.videoRef1, this.videoRef2, this.videoRef3, this.videoRef4, this.videoRef5];

    videoRefs.forEach((ref, i) => {

      if (i + 1 === index) {
        ref.current.play();
      } else {
        ref.current.pause();
      }
    });

    this.setState({ sensorActive: index })
  }
  handleMouseEnter = (sensor) => {
    this.hoverTimer = setTimeout(() => {
      clearTimeout(this.hoverTimer);
      this.handleTriangleVideo(sensor);
    }, 0);
  };

  handleMouseLeave = () => {
    if (this.hoverTimer) {
      clearTimeout(this.hoverTimer);
    }
  };

  //
  handleTriangleFourth(num) {
    //配合动画
    this.opacityImg.current.style.opacity = '0'
    setTimeout(() => {
      this.setState({ imgActive: num })
      this.opacityImg.current.style.opacity = '1'
    }, 200)
    this.setState({ fourthActive: num })
  }

  scrollTopPage() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  render() {
    const {
      firstPage,
      secondPage,
      thirdPage,
      fourthPage,
      fifthPage,
      sixthPage,
      seventhPage,
      eighthPage,
      ninethPage,
      tenthPage,
      eleventhPage,
      twelfthPage,
      thirtythPage,
      fourteenthPage,
      fifteenthPage,
      moreInfo,
      hideGooseChoice,
      sensorActive,
      fourthActive,
      imgActive
    } = this.state
    // const { sleepVideoStatus, replayStatus } = this.state
    const { location, locale } = this.props
    const language = locale.indexOf('US') > -1 ? 'US' : (locale.indexOf('TW') > -1 ? 'TW' : 'CN')


    return (
     
      <div
        className={s.snow2Box}
        ref="box"
        onMouseOver={(e) => {
          e.persist()
          this.hideGooseChoiceFun(true, e)
        }}
      >
        
        <Header
          location={location}
          language={this.state.language}
          path={this.state.path}
          navPosition={this.state.position}
          hideGooseChoice={hideGooseChoice}
          hideGooseChoiceFun={(isHide) => this.hideGooseChoiceFun(isHide)}
        />
        {/* <img src={banner} className={s.bannerImg} alt="" /> */}
        <div className={s.snow2Overview}>

          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.firstPage + ' ' + (firstPage ? s.active : '')
            }
            ref="firstPage"
          >
            <div
              className={
                locale.indexOf('US') > -1 ? s.firstWrap : s.firstWrap
              }
            >
              <div className={s.text} ref="text">
                <h2>
                  <FormattedMessage id="sw2_first_title" />
                </h2>
                <div className={s.desc}>
                  <FormattedMessage id="sw2_first_desc_1" />
                </div>
              </div>
              <div className={s.intoMi} />
            </div>

          </div>
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.secondPage + ' ' + (secondPage ? s.active : '')
            }
            ref="secondPage"
          >
            <div className={sensorActive === 1 ? s.video + ' ' + s.active : s.video}>
              <video
                ref={this.videoRef1}
                height="605"
                src="https://qingping.cleargrass.com/static/media/snow2_temp.mp4?var=314"
                preload='auto'
                playsInline
                loop
                muted>
              </video>
            </div>
            <div className={sensorActive === 2 ? s.video + ' ' + s.active : s.video}>
              <video
                ref={this.videoRef2}
                height="605"
                src="https://qingping.cleargrass.com/static/media/co2.mp4"
                preload='auto'
                playsInline
                loop
                muted>
              </video>
            </div>
            <div className={sensorActive === 3 ? s.video + ' ' + s.active : s.video}>
              <video
                ref={this.videoRef3}
                height="605"
                src="https://qingping.cleargrass.com/static/media/pm2.5.mp4"
                preload='auto'
                autoPlay
                playsInline
                loop
                muted>
              </video>

            </div>
            <div className={sensorActive === 4 ? s.video + ' ' + s.active : s.video}>
              <video
                ref={this.videoRef4}
                height="605"
                src="https://qingping.cleargrass.com/static/media/tvoc.mp4"
                preload='auto'
                playsInline
                loop
                muted>
              </video>
            </div>
            <div className={sensorActive === 5 ? s.video + ' ' + s.active : s.video}>
              <video
                ref={this.videoRef5}
                height="605"
                src="https://qingping.cleargrass.com/static/media/noise.mp4"
                preload='auto'
                playsInline
                loop
                muted>
              </video>
            </div>
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <h2 className={s.large}>
                  <FormattedMessage id="sw2_second_title" />
                </h2>
                <h3 className={s.normal}>
                  <FormattedMessage id="sw2_second_desc" />
                </h3>
                <div className={s.iconList}>
                  <ul>
                    <li onClick={() => this.handleTriangleVideo(1)} onMouseEnter={() => this.handleMouseEnter(1)}
                      onMouseLeave={this.handleMouseLeave} className={sensorActive === 1 ? s.active : ''}>
                      <div className={s.imgWrap} ref="triangle1">
                        <i className={s.SsecondTemp} ref="img1" />
                      </div>
                      <FormattedMessage id="sw2_second_temp" />
                    </li>
                    <li onClick={() => this.handleTriangleVideo(2)} onMouseEnter={() => this.handleMouseEnter(2)}
                      onMouseLeave={this.handleMouseLeave} className={sensorActive === 2 ? s.active : ''}>
                      <div className={s.imgWrap} ref="triangle2">
                        <i className={s.SsecondCo2} ref="img2" />
                      </div>
                      <FormattedMessage id="sw2_second_co2" />
                    </li>
                    <li onClick={() => this.handleTriangleVideo(3)} onMouseEnter={() => this.handleMouseEnter(3)}
                      onMouseLeave={this.handleMouseLeave} className={sensorActive === 3 ? s.active : ''}>
                      <div
                        className={s.imgWrap}
                        ref="triangle3"
                      >
                        <i className={s.SsecondPm25} ref="img3" />
                      </div>
                      <FormattedMessage id="sw2_second_pm25" values={{ br: '' }} />
                    </li>
                    <li onClick={() => this.handleTriangleVideo(4)} onMouseEnter={() => this.handleMouseEnter(4)}
                      onMouseLeave={this.handleMouseLeave} className={sensorActive === 4 ? s.active : ''}>
                      <div className={s.imgWrap} ref="triangle4">
                        <i className={s.SsecondTvoc} ref="img4" />
                      </div>
                      <FormattedMessage
                        id="sw2_second_tvoc"
                        values={{
                          sup: (
                            <sup style={{verticalAlign:"baseline"}}>
                              <a href="#footnote-1" aria-label="脚注1">1</a>
                            </sup>
                          )
                        }}
                      />
                    </li>
                    <li onClick={() => this.handleTriangleVideo(5)} onMouseEnter={() => this.handleMouseEnter(5)}
                      onMouseLeave={this.handleMouseLeave} className={sensorActive === 5 ? s.active : ''}>
                      <div className={s.imgWrap} ref="triangle5">
                        <i className={s.SsecondNoise} ref="img5" />
                      </div>
                      <FormattedMessage id="sw2_second_noise" />
                    </li>
                  </ul>
                </div>
                <div className={s.memo}>
                  <div style={sensorActive === 1 ? { display: 'block' } : { display: 'none' }}><FormattedMessage id="sw2_second_memo1" /></div>
                  <div style={sensorActive === 2 ? { display: 'block' } : { display: 'none' }}><FormattedMessage id="sw2_second_memo2" /></div>
                  <div style={sensorActive === 3 ? { display: 'block' } : { display: 'none' }}><FormattedMessage id="sw2_second_memo3" /></div>
                  <div style={sensorActive === 4 ? { display: 'block' } : { display: 'none' }}>
                    <FormattedMessage id="sw2_second_memo4" />
                  </div>
                  <div style={sensorActive === 5 ? { display: 'block' } : { display: 'none' }}><FormattedMessage id="sw2_second_memo5" /></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.thirdPage + ' ' + (thirdPage ? s.active : '')
            }
            ref="thirdPage"
          >

            <div className={s.wrap}>
              <div className={s.title}>
                <FormattedMessage id="sw2_third_title" />
              </div>
              {/* 轮播图版本 */}
              {/* <Selection locale={locale}></Selection> */}
              <BetterAir locale={locale}></BetterAir>


            </div>
          </div>
          {/* 监测数据 */}
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.fourthPage + ' ' + (fourthPage ? s.active : '')

            }
            ref="fourthPage"
          >
            <div className={s.wrap}>
              <div className={s.title}>
                <FormattedMessage id="sw2_fourth_title" values={{ br: <br /> }} />
              </div>
              <div className={s.contentBox} ref="text">
                <div className={s.text}>
                  {glances.map((item, index) => (
                    <div className={fourthActive === item.id ? `${s.textItem} ${s.textItemActive}` : s.textItem} onMouseEnter={() => this.handleTriangleFourth(item.id)} key={item.id}>
                      <div className={s.itemTop}>
                        <img src={item.icon} alt="" />
                        <div className={s.topText}>
                          <FormattedMessage id={item.descId} />
                        </div>
                      </div>
                      <div className={fourthActive === item.id ? `${s.itemDesc} ${s.active}` : s.itemDesc} ref={`memo${item.id}`}>
                        <FormattedMessage id={item.memoId} />
                        {item.id === 4 && (
                          <sup className={s.footnote}>
                            <a href="#footnote-2" aria-label="脚注2">
                              2
                            </a>
                          </sup>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div ref={this.opacityImg} className={s.opacityImg}>
                  <div className={
                    locale.indexOf('US') > -1 ?
                      s['img' + imgActive] + ' ' + s.en : (locale.indexOf('TW') > -1 ? s['img' + imgActive] + ' ' + s.tw : s['img' + imgActive])
                  } ref={"img" + imgActive} style={{ display: 'block' }} />
                </div>

              </div>
            </div>
          </div>

          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.fifthPage + ' ' + (fifthPage ? s.active : '')

            }
            ref="fifthPage"
          >
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <h2 className={s.title}>
                  <FormattedMessage id="sw2_fifth_title" values={{ br: '' }} />
                </h2>
                <h3 className={s.Sfourth_desc}>
                  <FormattedMessage
                    id="sw2_fifth_desc"
                    values={{ spot: <span className={s.line}>·</span> }}
                  />
                </h3>
              </div>
            </div>
          </div>
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.sixthPage + ' ' + (sixthPage ? s.active : '')
            }
            ref="sixthPage"
          >
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <h2 className={s.title}>
                  <FormattedMessage id="sw2_sixth_title" values={{ br: <br /> }} />
                </h2>
                <h3 className={s.subTitle}>
                  <FormattedMessage
                    id="sw2_sixth_spec_1"
                    values={{ spot: '·', br: '·' }}
                  />
                </h3>
                <h4>
                  <FormattedMessage id="sw2_sixth_desc" values={{ br: <br /> }} />
                </h4>
              </div>
            </div>
          </div>

          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.seventhPage + ' ' + (seventhPage ? s.active : '')

            }
            ref="seventhPage"
          >
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <h2 className={s.title}>
                  <FormattedMessage id="sw2_seventh_title" />
                </h2>
                <h3 className={s.desc}>
                  <FormattedMessage id="sw2_seventh_desc" />
                </h3>
              </div>
            </div>
          </div>
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.eighthPage + ' ' + (eighthPage ? s.active : '')

            }
            ref="eighthPage"
          >
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <h2 className={s.title}>
                  <FormattedMessage id="sw2_eight_title" />
                </h2>
                <div className={s.iconList}>
                  <ul>
                    <li id="topnote-3">
                      <i className={s.time} />
                      <FormattedMessage id="sw2_eight_desc_1" values={{ br: '' }} />
                      <sup className={s.footnote}>
                        <a href="#footnote-1" aria-label="脚注3">
                          3
                        </a>
                      </sup>
                    </li>
                    <li id="topnote-4">
                      <i className={s.clock} />
                      <FormattedMessage id="sw2_eight_desc_2" values={{ br: '' }} />
                      <sup className={s.footnote}>
                        <a href="#footnote-1" aria-label="脚注4">
                          4
                        </a>
                      </sup>
                    </li>
                    <li id="topnote-5">
                      <i className={s.remind} />
                      <FormattedMessage id="sw2_eight_desc_3" />
                      <sup className={s.footnote}>
                        <a href="#footnote-1" aria-label="脚注5">
                          5
                        </a>
                      </sup>
                    </li>
                    <li id="topnote-6">
                      <i className={s.define} />
                      <FormattedMessage id="sw2_eight_desc_4" />
                      <sup className={s.footnote}>
                        <a href="#footnote-1" aria-label="脚注6">
                          6
                        </a>
                      </sup>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={s.img}></div>
            </div>
          </div>
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.ninethPage + ' ' + (ninethPage ? s.active : '')

            }
            ref="ninethPage"
          >
            <div className={s.wrap}>
              <div className={s.img} ref="img">
                <video
                  ref={this.videoRef9}
                  width="100%"
                  height="670"
                  src={snow2_10}
                  loop
                  muted
                />
              </div>
              <div className={s.text} ref="text">
                <h2 className={s.title}>
                  <FormattedMessage id="sw2_ninth_title" />
                </h2>
                <div className={s.subTitle}>
                  <FormattedMessage id="sw2_ninth_desc" values={{ br: '·' }} />
                </div>
                <div className={s.iconList}>
                  <ul>
                    <li>
                      <i className={s.SfifthA7} />
                      <FormattedMessage id="sw2_ninth_memo1" />
                    </li>
                    <li>
                      <i className={s.SfifthCalculate} />
                      <FormattedMessage id="sw2_ninth_memo2" />
                    </li>
                    <li>
                      <i className={s.sensor} />
                      <FormattedMessage id="sw2_ninth_memo3" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.tenthPage + ' ' + (tenthPage ? s.active : '')

            }
            ref="tenthPage"
          >
            <div className={s.wrap}>
              <div className={s.img} ref="img">
                <video
                  ref={this.videoRef10}
                  // width="100%"
                  height="810"
                  src={snow2_9}
                  // autoPlay
                  loop
                  muted
                />
              </div>
              <div className={s.text} ref="text">
                <h2 className={s.title}>
                  <FormattedMessage id="sw2_tenth_title" />
                </h2>
                <h3 className={s.desc_1}>
                  <FormattedMessage
                    id="sw2_tenth_desc"
                    values={{
                      br: <br />,
                      sup: (
                        <sup className={s.footnote}>
                          <a href="#footnote-7" aria-label="脚注7">
                            7
                          </a>
                        </sup>
                      ),
                    }}
                  />
                </h3>
              </div>
            </div>
          </div>
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.twelfthPage + ' ' + (twelfthPage ? s.active : '')

            }
            ref="twelfthPage"
          >
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <div className={s.title}>
                  <FormattedMessage id="sw2_eleventh_title" values={{ br: '', comma: <span>，</span> }} />
                </div>
                <div className={s.desc_1}>
                  <FormattedMessage
                    id="sw2_eleventh_desc"
                    values={{
                      spot: <span className={s.line}>·</span>,
                      br: <span className={s.line}>·</span>
                    }}
                  />
                </div>
                <div className={s.desc_2}>
                  <FormattedMessage id="sw2_eleventh_memo" values={{ br: '' }} />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.eleventhPage + ' ' + (eleventhPage ? s.active : '')

            }
            ref="eleventhPage"
          >
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <div className={s.title}>
                  <FormattedMessage
                    id="sw2_twelveth_title"
                    values={{
                      sup: (
                        <sup className={s.footnote}>
                          <a href="#footnote-8" aria-label="脚注8" >
                            8
                          </a>
                        </sup>
                      ),
                    }}
                  />
                </div>
                <div className={s.desc}>
                  <FormattedMessage
                    id="sw2_twelveth_desc"
                    values={{ br: <br /> }}
                  />
                </div>
                <div
                  className={
                    locale.indexOf('US') > -1 || locale.indexOf('TW') > -1
                      ? s.mi_en
                      : s.mi
                  }
                ></div>
              </div>
            </div>
          </div>

          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.thirtythPage + ' ' + (thirtythPage ? s.active : '')

            }
            ref="thirtythPage"
          >
            <Link to="/air" target="_blank" onClick={() => this.scrollTopPage()}>
              <div className={s.wrap}>
                <div className={s.text} ref="text">
                  <div className={s.QingpingLogo}>
                    <i />
                  </div>
                  <div className={s.title}>
                    <FormattedMessage id="sw2_thirteenth_title" />
                  </div>
                  <div className={s.desc}>
                    <FormattedMessage
                      id="sw2_thirteenth_desc"
                      values={{ br: <br /> }}
                    />
                  </div>
                </div>
                <div className={locale.indexOf('US') > -1 ? s.img + ' ' + s.en : s.img}></div>
              </div>
            </Link>
          </div>
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.fourteenthPage + ' ' + (fourteenthPage ? s.active : '')

            }
            ref="fourteenthPage"
          >
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <div className={s.title}>
                  <FormattedMessage
                    id="sw2_fourteenth_title"
                    values={{
                      br: (
                        <br />
                      )
                    }}
                  />
                </div>
                <div className={s.Sfourth_desc}>
                  <FormattedMessage
                    id="sw2_fourteenth_desc"
                    values={{
                      br: (
                        <br />
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              (language === 'US' ? s.en : (language === 'TW' ? s.tw : '')) + ' ' + s.fifteenthPage + ' ' + (fifteenthPage ? s.active : '')

            }
            ref="fifteenthPage"
          >
            <div className={s.wrap}>
              <div className={s.text} ref="text">
                <div className={s.title}>
                  <FormattedMessage id="sw2_fifteenth_title" />
                </div>
                <div className={s.desc}>
                  <FormattedMessage
                    id="sw2_fifteenth_desc"
                    values={{ br: <br /> }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={moreInfo ? s.moreInfo + ' ' + s.active : s.moreInfo}
            ref="moreInfo"
          >
            <div className={s.wrap}>
              <div
                className={locale.indexOf('US') > -1 ? s.text_en : s.text}
                ref="text"
              >
                <ul className={issafariBrowser ? s.ol_safari : s.ol}>
                  <li id="footnote-1">
                    <FormattedMessage id="sw2_sixteenth_memo_1" />
                  </li>
                  <li id="footnote-2">
                    <FormattedMessage id="sw2_sixteenth_memo_2" />
                  </li>
                  <li id="footnote-3">
                    <FormattedMessage id="sw2_sixteenth_memo_3" />
                  </li>
                  <li id="footnote-4">
                    <FormattedMessage id="sw2_sixteenth_memo_4" />
                  </li>
                  <li id="footnote-5">
                    <FormattedMessage id="sw2_sixteenth_memo_5" />
                  </li>
                  <li id="footnote-6">
                    <FormattedMessage id="sw2_sixteenth_memo_6" />
                    <Link to="/plus"><FormattedMessage id="sw2_sixteenth_memo_6_1" /></Link>
                    <FormattedMessage id="sw2_sixteenth_memo_6_2" />
                  </li>
                  <li id="footnote-7">
                    <FormattedMessage id="sw2_sixteenth_memo_7" />
                  </li>
                  <li id="footnote-8">
                    <FormattedMessage id="sw2_sixteenth_memo_8" values={{ br: '"' }} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer location={location} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const DoveOverView = connect(mapStateToProps)(DoveOverview)

export default DoveOverView
