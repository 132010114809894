import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
// import { Link } from 'react-router-dom'

import s from './Seighth.module.scss'

class Seighth extends React.PureComponent {
  static handleClick() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Seighth.getBoundingClientRect()
    if (this.refs.Seighth && clientHeight >= textRect.bottom - 200) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 500)
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div className={locale.indexOf('US') > -1 ? s.Seighth_en : s.Seighth} ref="Seighth">
          <div className={s.wrap}>
            <div className={s.text} ref="text">
              <div className={s.title}  style={{textAlign:'center'}}>
                <FormattedMessage id="dany_seven_title" values={{br: <br />}} />
              </div>
              <div className={s.desc}>
                <FormattedMessage
                  id="dany_seven_desc"
                  values={{ br: <br /> }}
                />
              </div>
              <div className={s.data}>
                <div className={s.item}>
                  <div className={s.second_title}>
                    <div className={s.numer}>
                      <FormattedMessage id="dany_seven_item1_num" />
                    </div>
                    &nbsp;&nbsp;
                    <div className={s.unit}>
                      <FormattedMessage id="dany_seven_item1_time" />
                    </div>
                  </div>
                  <div className={s.second_desc}>
                    <FormattedMessage id="dany_seven_item1_desc" values={{br: <br />}} />
                  </div>
                </div>
                <div className={s.line}></div>
                <div className={s.item}>
                  <div className={s.meter_title}>
                    <div className={s.numer}>
                      <FormattedMessage id="dany_seven_item2_num" />
                    </div>
                    &nbsp;&nbsp;
                    <div className={s.unit}>
                      <FormattedMessage id="dany_seven_item2_time" />
                    </div>
                  </div>
                  <div className={s.meter_desc}>
                    <FormattedMessage id="dany_seven_item2_desc" values={{br: <br />}} />
                  </div>
                </div>
              </div>
            </div>
            <div className={s.img} ref="img" />
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

const SEighth = connect(mapStateToProps)(Seighth)

export default SEighth
