import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import s from './Pseventh.module.scss'

class Pseventh extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      index: 0,
      opacity: 1,
    }
    this._handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
    // this.refs.Pseventh.style.backgroundSize = '1920px';
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }
  handleScroll() {
    const clientHeight = document.documentElement.clientHeight
    const textRect = this.refs.Pseventh.getBoundingClientRect()
    if (this.refs.Pseventh && clientHeight >= textRect.bottom - 300) {
      setTimeout(() => {
        this.refs.text.style.opacity = 1
        this.refs.text.style.top = '0'
      }, 0)
    }
    // if (this.refs.Pseventh && clientHeight >= textRect.bottom) {
    //     setTimeout(() => {
    //         this.refs.imgList.style.opacity = 1;
    //         this.refs.imgList.style.top = '0';
    //     }, 500);
    // }
  }
  render() {
    const { locale } = this.props
    return (
      <div className={s.Pseventh} ref="Pseventh">
        <div className={s.wrap}>
          <div className={s.text} ref="text">
            <i className={s.p_sixth_logo} />
            <span className={s.large}>
              <FormattedMessage id="ph_co2_eleven_title" values={{ br: '' }} />
            </span>
          </div>
          <div
            className={s.imgList}
            style={{ marginTop: locale.indexOf('US') > -1 ? '30px' : '70px' }}
            ref="imgList"
          >
            <ul>
              <li>
                <FormattedMessage id="p_seventh_img_1" />
                <i
                  className={
                    locale.indexOf('US') > -1
                      ? s.p_seventh_img_1_en
                      : locale.indexOf('TW') > -1
                      ? s.p_seventh_img_1_tw
                      : s.p_seventh_img_1
                  }
                />
              </li>
              <li>
                <FormattedMessage id="ph_co2_eleven_desc" />
                <i
                  className={
                    locale.indexOf('US') > -1
                      ? s.p_seventh_img_2_en
                      : locale.indexOf('TW') > -1
                      ? s.p_seventh_img_2_tw
                      : s.p_seventh_img_2
                  }
                />
              </li>
            </ul>
          </div>
          <div className={locale.indexOf('US') > -1 ? s.normal_en : s.normal}>
            <FormattedMessage id="ph_co2_eleven_memo" values={{ br: '' }} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let PSeventh = connect(mapStateToProps)(Pseventh)

export default PSeventh
