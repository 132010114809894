import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import s from './Design.module.scss'

let picNumber = 4
class Design extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      num: 1, // 自动播放默认第二个切换
      arr: [
        // init spot
        <li
          className={s.cur}
          onClick={() => this.handleTab(1, true)}
          key="1"
        />,
        <li
          className={s.spot}
          onClick={() => this.handleTab(2, true)}
          key="2"
        />,
        <li
          className={s.spot}
          onClick={() => this.handleTab(3, true)}
          key="3"
        />,
        <li
          className={s.spot}
          onClick={() => this.handleTab(4, true)}
          key="4"
        />,
      ],
      tab: null,
      startX: null,
      endX: null,
    }
    this._handleResize = this.handleResize.bind(this)
  }
  componentDidMount() {
    // 添加文字显示动画，可添加任何样式 如：opacity, left
    this.setIntervalTab()
    window.addEventListener('resize', this._handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize)
  }
  onTouchStart(event) {
    // console.log(event, 'event')
    const startX = event.changedTouches[0].pageX
    this.setState({
      startX: startX,
    })
  }
  onTouchEnd(event) {
    const startX = this.state.startX
    const endX = event.changedTouches[0].pageX
    let num = this.state.num
    if (num !== 1 && startX < endX) {
      num--
    } else if (num !== picNumber && startX > endX) {
      num++
    }
    this.setState(
      {
        endX: endX,
        num: num,
      },
      () => this.handleTab(num, true)
    )
  }
  // 绑定定时器，10秒播放一次
  setIntervalTab() {
    const tab = setInterval(() => {
      this.handleTab()
    }, 100000)
    this.setState({
      tab: tab,
    })
  }
  // 绑定切换，支持自动播放及点击切换 clear：ture为点击后
  handleTab(n, clear) {
    let Bwidth = null
    if (this.refs.img) {
      Bwidth = this.refs.img.offsetWidth
    }
    let num = n || this.state.num
    const temp = []
    if (!clear) {
      // picNumber为图片个数，自动循环播放
      if (num === picNumber) {
        num = 1
      } else {
        num++
      }
    }
    // 更新按钮，i减1为按钮个数
    for (let i = 0; i <= picNumber - 1; i++) {
      if (i === num - 1) {
        temp.push(
          <li
            className={s.cur}
            onClick={() => this.handleTab(i + 1, true)}
            key={i + 1}
          />
        )
      } else {
        temp.push(
          <li
            className={s.spot}
            onClick={() => this.handleTab(i + 1, true)}
            key={i + 1}
          />
        )
      }
    }
    if (this.refs.bannerItem) {
      this.refs.bannerItem.style.left = `${-(num - 1) * Bwidth}px`
    }
    this.setState({
      arr: temp,
      num: num,
    })
    // touch后重新切换计时 clear：ture为点击后
    if (clear) {
      clearInterval(this.state.tab)
      this.setIntervalTab()
    }
  }
  handleResize() {
    if (this.state.num === 1) {
      const bannerItem = this.refs.bannerItem
      const Bwidth = this.refs.img.offsetWidth
      bannerItem.style.left = `${-this.state.num * Bwidth}px`
    }
  }
  render() {
    const { locale } = this.props
    return (
      <div className={s.design} ref="design">
        <div className={s.img} ref="img">
          <ul
            ref="bannerItem"
            onTouchStart={(e) => this.onTouchStart(e)}
            onTouchEnd={(e) => this.onTouchEnd(e)}
          >
            <li
              className={
                locale.indexOf('US') > -1
                  ? s.two_img_en
                  : locale.indexOf('TW') > -1
                  ? s.two_img_tw
                  : s.two_img
              }
            >
              <Link to="/air-monitor-2/overview">
                <div className={s.text_snow}>
                  <span className={s.small}>
                    <FormattedMessage id="snow2" />
                  </span>
                  <span className={s.large_two}>
                    <FormattedMessage id="s_first_title" />
                  </span>
                  <span
                    to="/air-monitor-2/overview"
                    className={locale.indexOf('US') > -1 ? s.more_en : s.more}
                  >
                    <FormattedMessage id="more" />
                  </span>
                </div>
                {/* <div className={s.hotdot} />
                <div className={s.IF} /> */}
              </Link>
            </li>
            <li className={s.one_img}>
              <Link to="/temp-rh-monitor/overview">
                <div className={s.text_goose}>
                  <span className={s.small}>
                    <FormattedMessage id="goose_lite" />
                  </span>
                  <span className={s.large} style={{ marginBottom: 5 }}>
                    <span>
                      <FormattedMessage id="t_version_new" />
                    </span>
                    &nbsp;/&nbsp;
                    <span>
                      <FormattedMessage id="h_version" />
                    </span>
                    <span className={s.homekit}></span>
                  </span>
                  <span className={s.large} style={{ marginTop: 0 }}>
                    <span>
                      <FormattedMessage id="m_version" />
                    </span>
                    <span
                      className={
                        locale.indexOf('US') > -1 || locale.indexOf('TW') > -1
                          ? s.mi_en
                          : s.mi
                      }
                    ></span>
                  </span>
                  <span
                    to="/temp-rh-monitor/overview"
                    className={locale.indexOf('US') > -1 ? s.more_en : s.more}
                  >
                    <FormattedMessage id="more" />
                  </span>
                </div>
                {/* <div className={s.goose_logo}></div> */}
              </Link>
            </li>
            
            <li className={s.frog_img}>
              <Link to="/co2-temp-rh-monitor/overview">
                <div className={s.magpie_text_en}>
                  <span className={s.small}>
                    <span>
                      <FormattedMessage id="pheasant_co2_name" />
                    </span>
                  </span>
                  <span className={s.large} style={{ lineHeight: 1.3 }}>
                    <FormattedMessage
                      id="pheasant_co2_index_desc"
                      values={{ br: <br /> }}
                    />
                  </span>
                  <span
                    to="/co2-temp-rh-monitor/overview"
                    className={locale.indexOf('US') > -1 ? s.more_en : s.more}
                    // style={{ width: locale.indexOf('US') > -1 ? 91 : 72 }}
                  >
                    <FormattedMessage id="more" />
                  </span>
                </div>
              </Link>
            </li>
            <li className={s.four_img}>
              <Link to="/lee-guitars-thermo-hygrometer/overview">
                <div
                  className={
                    locale.indexOf('US') > -1 ? s.magpie_text_en : s.magpie_text
                  }
                >
                  <span className={s.small}>
                    <span>
                      <FormattedMessage id="magpie" values={{ br: '' }} />
                    </span>
                  </span>
                  <span className={s.large} style={{ lineHeight: 1.3 }}>
                    <span>
                      <FormattedMessage id="grasp_guitar" />
                      {/* <FormattedMessage id="cute_smart" /> */}
                    </span>
                  </span>
                  <span
                    to="/lee-guitars-thermo-hygrometer/overview"
                    className={locale.indexOf('US') > -1 ? s.more_en : s.more}
                    // style={{ width: locale.indexOf('US') > -1 ? 91 : 72 }}
                  >
                    <FormattedMessage id="more" />
                  </span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className={s.btn}>
          <ul className={s.li_wrap}>{this.state.arr}</ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let design = connect(mapStateToProps)(Design)

export default design
