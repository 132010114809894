import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from './../../header'
import Footer from './../../footer'
import Design from './../design'
import Product from './../product'
import s from './Home.module.scss'
import common from '../../../../../locale/common'
class Index extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      language: null,
      position: false,
      hideModal: true,
      hideSecondSub: true,
    }
  }
  componentDidMount() {
    const { locale } = this.props
    const local = locale.slice(3).toLocaleLowerCase()
    document.title = common['qingping_' + local]

    let browserLanguage = navigator.browserLanguage
      ? navigator.browserLanguage
      : navigator.language
    switch (browserLanguage) {
      case 'en':
        browserLanguage = 'en-US'
        break
      case 'zh':
        browserLanguage = 'zh-CN'
        break
      default:
        browserLanguage = 'en-US'
    }
    this.setState({
      language: browserLanguage,
    })
  }

  hideModalFunc(isHide, e, isTouch) {
    if (
      e.target.className === 'language_choice' ||
      (isTouch &&
        (e.target.innerText === 'English' ||
          e.target.innerText === '简体中文' ||
          e.target.innerText === '繁體中文'))
    ) {
      return
    }
    this.setState({
      hideModal: isHide,
    })
  }
  hideSecondSubFunc(isHide, e, isTouch) {
    if (
      isTouch &&
      ((e.target.attributes.name &&
        e.target.attributes.name.value === 'secondSub') ||
        (e.target.parentNode.attributes.name &&
          e.target.parentNode.attributes.name.value === 'secondSub'))
    ) {
      return
    }
    this.setState({
      hideSecondSub: isHide,
    })
  }
  render() {
    const { hideModal, hideSecondSub } = this.state
    const { location } = this.props
    const path = this.props.match.path
    return (
      <div
        className={s.box}
        ref="box"
        onClick={(e) => {
          e.persist()
          this.hideModalFunc(true, e)
          this.hideSecondSubFunc(true, e)
        }}
        onTouchStart={(e) => {
          e.persist()
          this.hideModalFunc(true, e, true)
          this.hideSecondSubFunc(true, e, true)
        }}
      >
        <Header
          location={location}
          language={this.state.language}
          path={path}
          hideSecondSub={hideSecondSub}
          hideSecondSubFunc={(isHide, e) => {
            this.hideSecondSubFunc(isHide, e)
          }}
        />
        <Design />
        <Product />
        <Footer
          location={location}
          hideModal={hideModal}
          hideModalFunc={(isShow, e) => {
            this.hideModalFunc(isShow, e)
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})

let index = connect(mapStateToProps)(Index)

export default index
