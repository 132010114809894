import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import actions from '../../../../store/actions'
import Utils from '../../../common/util'
import { Link } from 'react-router-dom'

import s from './Footer.module.scss'

class Footer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // 初始state
      language: null,
      uid: undefined,
    }
  }
  componentDidMount() {
    this.initUidAndDrawDot()
    let browserLanguage = navigator.browserLanguage
      ? navigator.browserLanguage
      : navigator.language
    if (browserLanguage.indexOf('zh') !== -1) {
      browserLanguage = 'zh-CN'
    } else {
      browserLanguage = 'en-US'
    }
    localStorage.setItem('language', browserLanguage)
    this.setState({
      language: browserLanguage,
    })
  }

  initUidAndDrawDot() {
    let uid = localStorage.getItem('uid') || undefined
    if (!uid) {
      uid = Math.random()
      localStorage.setItem('uid', uid)
    }
    this.setState({
      uid: uid,
    })
  }

  chooseLanguage(e) {
    e.stopPropagation()
    e.persist()
    this.props.hideModal
      ? this.props.hideModalFunc(false, e)
      : this.props.hideModalFunc(true, e)
  }

  changeLanguage(lang) {
    let locale
    switch (lang) {
      case '简体中文':
        locale = 'zh-CN'
        break
      case '繁體中文':
        locale = 'zh-TW'
        break
      case 'English':
        locale = 'en-US'
        break
      default:
        locale = 'en-US'
        break
    }
    this.props.changeLanguage(locale)
  }

  render() {
    const { uid } = this.state
    const { locale, hideModal } = this.props

    const language_value = 'language_value_' + Utils.getIndexOfLanguage(locale)
    const language_show = Utils.getNameOfLanguage(locale)
    const allLanguages = ['简体中文', '繁體中文', 'English']
    allLanguages.splice(allLanguages.indexOf(language_show), 1)

    const pathname = this.props.location.pathname
    let privacyName = s.hide
    let allPrivacyName = s.hide

    if (
      pathname !== '/mi-temp-rh-monitor/overview' &&
      pathname !== '/mi-temp-rh-monitor/specifications'
    ) {
      allPrivacyName = null
    }

    return (
      <div className={s.footer}>
        <Link
          className={privacyName}
          to="/mi-temp-rh-monitor/privacy?locale=en-US"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        {/* 需注释部分 */}
        <p className={allPrivacyName}>
          <span
            style={{ position: 'relative' }}
            onClick={(e) => this.chooseLanguage(e)}
          >
            Language:&nbsp;
            <FormattedMessage id={language_value} />
            <span
              className={!hideModal ? s.top_arrows_selected : s.top_arrows}
            ></span>
            {!hideModal ? (
              <div className={s.wrapBuy}>
                <div className={s.buyBox}>
                  {allLanguages.map((item) => {
                    return (
                      <div
                        className={s.buyItem}
                        key={item}
                        onClick={() => this.changeLanguage(item)}
                      >
                        <span className="language_choice">{item}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : null}
          </span>
        </p>
        <p className={allPrivacyName}>
          <span>
            <a target="_blank" rel="noopener noreferrer" href="/about-us">
              <FormattedMessage id="about_us" />
            </a>
          </span>
        </p>
        <p className={allPrivacyName}>
          <a target="_blank" rel="noopener noreferrer" href="/privacy">
            <FormattedMessage id="privacy" />
          </a>
        </p>
        {locale.indexOf('US') > -1 ? (
          <p className={allPrivacyName}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="/eu-declarations"
            >
              EU DoC
            </a>
          </p>
        ) : null}
        {locale.indexOf('US') > -1 || locale.indexOf('TW') > -1 ? null : (
          <p>
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://beian.miit.gov.cn/"
              >
                粤ICP备19156805号-1
              </a>
            </span>
          </p>
        )}
        {locale.indexOf('US') > -1 || locale.indexOf('TW') > -1 ? null : (
          <p>
            <span className={s.records_p}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502004977"
                className={s.records_a}
              >
                粤公网安备44030502004977号
              </a>
            </span>
          </p>
        )}
        <p>
          <FormattedMessage id="company_name" values={{ br: <br /> }} />
        </p>
        {uid ? (
          <img
            src={
              `https://qing.cleargrass.com/common/bi?client=mobile&uid=` + uid
            }
            alt=""
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.language,
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLanguage: (val) => dispatch(actions.changeLanguage(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
