function getIndexOfLanguage(val) {
  switch (val) {
    case "zh-CN":
      return 1;
    case "zh-TW":
      return 2;
    case "en-US":
      return 3;
    default:
      return 3;
  }
}

function getNameOfLanguage(val) {
  switch (val) {
    case "zh-CN":
      return "简体中文";
    case "zh-TW":
      return "繁體中文";
    case "en-US":
      return "English";
    default:
      return 3;
  }
}

function displayIntervals(value) {
  const array = {
    0.1: "10 分钟",
    0.2: "20 分钟",
    0.3: "30 分钟",
    1: "1 小时",
    2: "2 小时",
    3: "3 小时",
    4: "4 小时",
    5: "5 小时",
    6: "6 小时",
    7: "7 小时",
    8: "8 小时",
    9: "9 小时",
    10: "10 小时",
    11: "11 小时",
    12: "12 小时",
    13: "13 小时",
    14: "14 小时",
    15: "15 小时",
    16: "16 小时",
    17: "17 小时",
    18: "18 小时",
    19: "19 小时",
    20: "20 小时",
    21: "21 小时",
    22: "22 小时",
    23: "23 小时",
    24: "24 小时",
  };

  return array[value];
}

function isInteger(obj) {
  return obj % 1 === 0;
}

function calculateTime(type, intervals) {
  console.log(type, intervals, "type and intervals");

  const amount_power = 3250;
  const usual_consumption = 0.06;
  const real_intervals = intervals < 1 ? intervals * 6000 : intervals * 3600;

  const consumptions = [
    {
      type: "nbiot",
      duration: 30,
      work_consumption: 35,
    },
    {
      type: "wifi",
      duration: 11,
      work_consumption: 25.8,
    },
    {
      type: "lora",
      duration: 20,
      work_consumption: 10,
    },
  ];

  let single_duration, single_work_consumption;

  consumptions.forEach((item) => {
    if (item.type === type) {
      single_duration = item.duration;
      single_work_consumption = item.work_consumption;
    }
  });

  let single_amount_consumption =
    (usual_consumption * (real_intervals - single_duration) +
      single_work_consumption * single_duration) /
    real_intervals;

  let standby = (
    (amount_power * 3600 * 0.7) /
    ((3600 / real_intervals) * single_amount_consumption) /
    3600 /
    24 /
    30
  ).toFixed(1);

  console.log(standby, "standby first");

  if (typeof standby === "string" && standby[2] === "0") {
    standby = Math.round(standby);
  }

  console.log(
    real_intervals,
    single_duration,
    single_work_consumption,
    standby,
    "standby in Util"
  );
  return standby;
}

function calculateTimeNew(
  product,
  netType,
  reportInterval,
  temperature,
  signal
) {
  console.log(
    product,
    netType,
    signal,
    reportInterval,
    temperature,
    "calculateTimeNew"
  );
  const fixedParams = [
    {
      product: 1,
      netType: "wifi",
      signal: "good",
      standby: 0.05,
      reportTime: 30,
      reportCast: 30.2,
      battery: 2500,
    },
    {
      product: 1,
      netType: "wifi",
      signal: "normal",
      standby: 0.05,
      reportTime: 30,
      reportCast: 30.2,
      battery: 2500,
    },
    {
      product: 1,
      netType: "wifi",
      signal: "bad",
      standby: 0.05,
      reportTime: 65,
      reportCast: 70,
      battery: 2500,
    },
    {
      product: 1,
      netType: "nbiot",
      signal: "good",
      standby: 0.06,
      reportTime: 45,
      reportCast: 21.3,
      battery: 2500,
    },
    {
      product: 1,
      netType: "nbiot",
      signal: "normal",
      standby: 0.06,
      reportTime: 45,
      reportCast: 21.3,
      battery: 2500,
    },
    {
      product: 1,
      netType: "nbiot",
      signal: "bad",
      standby: 0.06,
      reportTime: 75,
      reportCast: 35.6,
      battery: 2500,
    },
    {
      product: 1,
      netType: "lora",
      signal: "good",
      standby: 0.05,
      reportTime: 65,
      reportCast: 4.029,
      battery: 2500,
    },
    {
      product: 1,
      netType: "lora",
      signal: "normal",
      standby: 0.05,
      reportTime: 65,
      reportCast: 4.029,
      battery: 2500,
    },
    {
      product: 1,
      netType: "lora",
      signal: "bad",
      standby: 0.05,
      reportTime: 80,
      reportCast: 4.5,
      battery: 2500,
    },
    {
      product: 2,
      netType: "wifi",
      signal: "good",
      standby: 0.04,
      reportTime: 30,
      reportCast: 29.6,
      battery: 2600,
    },
    {
      product: 2,
      netType: "wifi",
      signal: "normal",
      standby: 0.04,
      reportTime: 30,
      reportCast: 29.6,
      battery: 2600,
    },
    {
      product: 2,
      netType: "wifi",
      signal: "bad",
      standby: 0.04,
      reportTime: 65,
      reportCast: 70,
      battery: 2600,
    },
    {
      product: 2,
      netType: "nbiot",
      signal: "good",
      standby: 0.05,
      reportTime: 45,
      reportCast: 21.3,
      battery: 2600,
    },
    {
      product: 2,
      netType: "nbiot",
      signal: "normal",
      standby: 0.05,
      reportTime: 45,
      reportCast: 21.3,
      battery: 2600,
    },
    {
      product: 2,
      netType: "nbiot",
      signal: "bad",
      standby: 0.06,
      reportTime: 75,
      reportCast: 35.6,
      battery: 2600,
    },
    {
      product: 2,
      netType: "lora",
      signal: "good",
      standby: 0.05,
      reportTime: 65,
      reportCast: 4.029,
      battery: 2600,
    },
    {
      product: 2,
      netType: "lora",
      signal: "normal",
      standby: 0.05,
      reportTime: 65,
      reportCast: 4.029,
      battery: 2600,
    },
    {
      product: 2,
      netType: "lora",
      signal: "bad",
      standby: 0.05,
      reportTime: 80,
      reportCast: 4.5,
      battery: 2600,
    },
  ];
  let intervals = Number(reportInterval) * 3600;
  let batterPercent;
  let batterySelfDecreasePercent;

  if (Number(temperature) < 0 || Number(temperature) > 40) {
    batterySelfDecreasePercent = 0.05;
    if (product === 1) {
      // if (netType === 'wifi' || netType === 'lora') {
      //   batterPercent = 0.384
      // } else if (netType === 'nbiot') {
      //   batterPercent = 0.5127
      // }
      batterPercent = 0.52;
    } else {
      if (netType === "wifi" || netType === "nbiot") {
        batterPercent = 0.45;
      } else {
        batterPercent = 0.68;
      }
    }
  } else if (Number(temperature) > 20 && Number(temperature) <= 40) {
    batterySelfDecreasePercent = 0.2;
    if (product === 1) {
      if (netType === "wifi") {
        batterPercent = 0.702;
      } else if (netType === "nbiot") {
        batterPercent = 0.7192;
      } else {
        batterPercent = 0.65;
      }
    } else {
      if (netType === "wifi") {
        batterPercent = 0.77;
      } else if (netType === "lora") {
        batterPercent = 0.65;
      } else {
        batterPercent = 0.702;
      }
    }
  } else {
    batterySelfDecreasePercent = 0.1;
    if (product === 1) {
      if (netType === "wifi") {
        batterPercent = 0.81;
      } else if (netType === "nbiot") {
        batterPercent = 0.78;
      } else {
        batterPercent = 0.68;
      }
    } else {
      if (netType === "wifi" || netType === "nbiot") {
        batterPercent = 0.81;
      } else {
        batterPercent = 0.45;
      }
    }
  }

  // if (Number(temperature) > 40) {
  //   return 30
  // }

  let allStandbyTime;

  fixedParams.forEach((item, index) => {
    console.log(
      product,
      netType,
      signal,
      batterPercent,
      batterySelfDecreasePercent,
      "ssssss"
    );
    if (
      item.product === product &&
      item.netType === netType &&
      item.signal === signal
    ) {
      console.log(item, "item in fixed");
      const oneHourCost =
        (item.standby * (3600 - (item.reportTime * 3600) / intervals) +
          (item.reportCast * item.reportTime * 3600) / intervals) /
          3600 +
        (item.battery * batterPercent * batterySelfDecreasePercent) / 24 / 365;

      allStandbyTime = (
        (item.battery * batterPercent) /
        oneHourCost /
        24
      ).toFixed(0);
    }
  });

  if (allStandbyTime > 900) {
    return 900;
  }

  console.log(allStandbyTime, "allStandbyTime");
  return allStandbyTime;
}

function calculateTimePhCo2(
  netType,
  reportInterval,
  temperature,
  signal,
  co2Collect
) {
  console.log(
    netType,
    signal,
    reportInterval,
    temperature,
    co2Collect,
    "calculateTimePhCo2"
  );

  const netTypeAndsignalToReportTimeAndCost = {
    wifi: {
      normal: {
        reportTime: 30,
        reportCost: 33,
      },
      bad: {
        reportTime: 65,
        reportCost: 70,
      },
    },
    nbiot: {
      normal: {
        reportTime: 45,
        reportCost: 21.3,
      },
      bad: {
        reportTime: 75,
        reportCost: 35.6,
      },
    },
    lora: {
      normal: {
        reportTime: 60,
        reportCost: 4.029,
      },
      bad: {
        reportTime: 80,
        reportCost: 4.5,
      },
    },
  };

  const tempToBatteryAndSelfDecrease = function (temperature) {
    if (temperature < 0) {
      return {
        batterPercent: 0.45,
        batterySelfDecrease: 0.0066780822,
      };
    } else if (temperature > 20) {
      return {
        batterPercent: 0.77,
        batterySelfDecrease: 0.0457077626,
      };
    } else {
      return {
        batterPercent: 0.81,
        batterySelfDecrease: 0.0240410959,
      };
    }
  };

  const reportCost =
    netTypeAndsignalToReportTimeAndCost[netType][signal]["reportCost"];
  const reportTime =
    (netTypeAndsignalToReportTimeAndCost[netType][signal]["reportTime"] *
      3600) /
    (reportInterval * 3600);
  const scd40Cost = 39.8;
  const scd40Time = (13 * 1.3 * 3600) / Math.max(co2Collect * 60, 60);
  const scd40PowerCost = 3.6;
  const scd40PowerTime =
    13 * (4.6 - 1.3) * (3600 / Math.max(co2Collect * 60, 60));
  const onlyStandbyCost = 0.132;
  const onlyStandbyTime = Math.max(
    3600 - reportTime - scd40Time - scd40PowerTime,
    0
  );

  const oneHourCost =
    (onlyStandbyTime * onlyStandbyCost +
      scd40PowerTime * scd40PowerCost +
      scd40Time * scd40Cost +
      reportTime * reportCost) /
    3600;

  let allStandbyTime = Math.round(
    (2600 * tempToBatteryAndSelfDecrease(temperature)["batterPercent"]) /
      (oneHourCost +
        tempToBatteryAndSelfDecrease(temperature)["batterySelfDecrease"]) /
      24
  );

  console.log(allStandbyTime, "allStandbyTime");
  return allStandbyTime;
}

export default {
  getIndexOfLanguage,
  getNameOfLanguage,
  displayIntervals,
  isInteger,
  calculateTime,
  calculateTimeNew,
  calculateTimePhCo2,
};
