import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import s from './share.module.scss';

class share extends React.PureComponent {
  static GetQueryString (name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    const r = window.location.search.substr(1).match(reg);
    if (r !== null) return unescape(r[2]);
    return null;
  }
  constructor(props) {
    super(props);
    this.state = { // init top hide
      url: null,
      system: null,
      show: s.hide
    };
  }
  componentDidMount () {
    document.getElementsByTagName('html')[0].style.fontSize = 41.4 + 'px'
    const ua = navigator.userAgent;
    const appVersion = navigator.appVersion;
    if (/Android/.test(appVersion)) {
      if (/MicroMessenger/gi.test(ua)) {
        this.setState({
          text: '“浏览器中打开”',
          downloadHtml: <div className={s.link_download} onClick={() => this.handleClick()}>下载</div>
        });
        return;
      }
      this.setState({
        downloadHtml: <a className={s.download} href="https://qing.cleargrass.com/download/plus" rel="noopener noreferrer"><span>下载</span></a>
      });
    } else {
      this.setState({
        downloadHtml: <a className={s.link_download} href="https://itunes.apple.com/cn/app/%E9%9D%92%E8%90%8D%E7%A9%BA%E6%B0%94/id1344636968?mt=8" target="_blank" rel="noopener noreferrer">下载</a>
      });
    }
  }

  handleClick () {
    this.setState({
      show: s.androidShow
    });
  }
  render () {
    const path = this.props.match.path;
    let img = null;
    let text = null;
    let name = null;
    let deviceUrl = null;
    if (path === '/air-app-and-temp-rh-monitor') {
      deviceUrl = '/temp-rh-monitor/overview';
      img = s.goose_img;
      name = '青萍蓝牙温湿度计';
      text = <FormattedMessage id="goose_share_text" values={{ br: <br />, spot: <span className={s.line}>·</span> }} />;
    } else if (path === '/air-app-and-air-monitor') {
      deviceUrl = '/air-monitor/overview';
      img = s.snow_img;
      name = '青萍空气检测仪';
      text = <FormattedMessage id="snow_share_text" values={{ br: <br />, spot: <span className={s.line}>·</span> }} />;
    } else if (path === '/air-app-and-temp-rh-barometer') {
      deviceUrl = '/temp-rh-monitor-pro-s/overview';
      img = s.lora_img;
      name = '青萍温湿度气压计';
      text = <FormattedMessage id="lora_share_text" values={{ br: <br />, spot: <span className={s.line}>·</span> }} />;
    }
    return (
      <div className={s.download}>
        <div className={s.head}>
          <div className={s.logo}>
            <a href="/">
              <i />
            </a>
          </div>
        </div>
        <div className={s.content}>
          <div className={s.desc}>
            <div className={s.big_logo} />
            <div className={s.text}>
              <div className={s.name}>青萍空气</div>
              <div className={s.slogan}>看见空气质量</div>
            </div>
          </div>
          <div className={s.item}>
            <div className={s.aqi_img} />
            <div className={s.text}>
              <div className={s.title}>空气质量</div>
              <div className={s.detail}>实时状态 · 12 小时预报 · 7 天预报 </div>
            </div>
          </div>
          <div className={s.item}>
            <div className={s.weather_img} />
            <div className={s.text}>
              <div className={s.title}>天气预报</div>
              <div className={s.detail}>实时状态 · 24 小时预报 · 15 天预报 </div>
            </div>
          </div>
          <div className={s.link}>
            {this.state.downloadHtml}
            <Link
              className={s.link_more}
              to="/air"
              target="_blank"
              rel="noopener noreferrer"
            >
              了解更多
                        </Link>
          </div>
        </div>
        <div className={s.content}>
          <div className={s.device}>
            <div className={img} />
            <div className={s.name}>{name}</div>
            <div className={s.desc}>
              {text}
            </div>
            <div className={s.link}>
              <Link
                className={s.link_more}
                to={deviceUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                了解更多
                            </Link>
            </div>
          </div>

        </div>
        <div className={s.foot}>© 青萍</div>
        <div className={this.state.show}>
          <div className={s.arrow} />
          <div className={s.hideText}>
            <ul>
              <li>1.点击右上角</li>
              <li>2.在菜单中选择<span>{this.state.text}</span></li>
            </ul>
          </div>
          <div className={s.phone} />
        </div>
      </div>
    );
  }
}

export default share;
